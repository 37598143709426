import { FunctionComponent, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import FeatureState from '../../../../context/state/FeatureState';
import Portfolio from './InformationMemorandum/Portfolio';
import Single from './InformationMemorandum/Single';

const InformationMemorandum: FunctionComponent = () => {
    const { propertySummary } = useContext(FeatureState);

    return (
        <div className={isMobile ? '' : 'mx-50'}>
            {propertySummary.isPortfolio ? <Portfolio /> : <Single propertyAssetId={`${propertySummary.id}`} />}
        </div>
    );
};
export default InformationMemorandum;
