import api from '../index';

const fetchAssetCashFlow = (id: string) => {
    return api.get(`/propertyassetcashflow/${id}`);
};

const updateAssetCashFlowRow = (id: string, data: any) => {
    return api.post(`/propertyassetcashflow/${id}/updaterow`, data);
};

const addRecord = (id: string, data: any) => {
    return api.post(`/propertyassetcashflow/${id}`, data);
};

const exportCashFlow = (id: string) => {
    return api.get(`/propertyassetcashflow/excel/${id}`, { responseType: 'blob' });
};

const removeCashflowById = (propertyAssetId: string, cashflowId: string) => {
    return api.delete(`/propertyassetcashflow/${propertyAssetId}/${cashflowId}`);
};

const CashFlowApi = {
    fetchAssetCashFlow,
    addRecord,
    updateAssetCashFlowRow,
    exportCashFlow,
    removeCashflowById,
};
export default CashFlowApi;
