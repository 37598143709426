import { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const capitalValue = [2660,
  2675,
  2675,
  2675,
  2675,
  2675,
  2656,
  2589,
  2537,
  2499,
  2463,
  2416,
  2386,
  2440,
  2511,
  2611,
  2656,
  2710,
  2762,
  2889,
  2975,
  3022,
  3032,
  3017,
  3010,
  3010,
  3010,
  3015,
  3050,
  3170,
  3290];

  const dateLabel = ["2014 1Q",
  "2014 2Q",
  "2014 3Q",
  "2014 4Q",
  "2015 1Q",
  "2015 2Q",
  "2015 3Q",
  "2015 4Q",
  "2016 1Q",
  "2016 2Q",
  "2016 3Q",
  "2016 4Q",
  "2017 1Q",
  "2017 2Q",
  "2017 3Q",
  "2017 4Q",
  "2018 1Q",
  "2018 2Q",
  "2018 3Q",
  "2018 4Q",
  "2019 1Q",
  "2019 2Q",
  "2019 3Q",
  "2019 4Q",
  "2020 1Q",
  "2020 2Q",
  "2020 4Q",
  "2021 Forecast",
  "2022 Forecast",
  "2023 Forecast",
  "2024 Forecast"];

  const chartData = [{
    "date": dateLabel[0],
    "value": capitalValue[0]
  }, {
    "date": dateLabel[1],
    "value": capitalValue[1]
  }, {
    "date": dateLabel[2],
    "value": capitalValue[2]
  }, {
    "date": dateLabel[3],
    "value": capitalValue[3]
  },
  {
    "date": dateLabel[4],
    "value": capitalValue[4]
  }, {
    "date": dateLabel[5],
    "value": capitalValue[5]
  }, {
    "date": dateLabel[6],
    "value": capitalValue[6]
  }, {
    "date": dateLabel[7],
    "value": capitalValue[7]
  },
  {
    "date": dateLabel[8],
    "value": capitalValue[8]
  }, {
    "date": dateLabel[9],
    "value": capitalValue[9]
  }, {
    "date": dateLabel[10],
    "value": capitalValue[10]
  }, {
    "date": dateLabel[11],
    "value": capitalValue[11]
  },{
    "date": dateLabel[12],
    "value": capitalValue[12]
  }, {
    "date": dateLabel[13],
    "value": capitalValue[13]
  }, {
    "date": dateLabel[14],
    "value": capitalValue[14]
  }, {
    "date": dateLabel[15],
    "value": capitalValue[15]
  },
  {
    "date": dateLabel[16],
    "value": capitalValue[16]
  }, {
    "date": dateLabel[17],
    "value": capitalValue[17]
  }, {
    "date": dateLabel[18],
    "value": capitalValue[18]
  }, {
    "date": dateLabel[19],
    "value": capitalValue[19]
  },
  {
    "date": dateLabel[20],
    "value": capitalValue[20]
  }, {
    "date": dateLabel[21],
    "value": capitalValue[21]
  }, {
    "date": dateLabel[22],
    "value": capitalValue[22]
  }, {
    "date": dateLabel[23],
    "value": capitalValue[23]
  },{
    "date": dateLabel[24],
    "value": capitalValue[24]
  }, {
    "date": dateLabel[25],
    "value": capitalValue[25]
  }, {
    "date": dateLabel[26],
    "value": capitalValue[26]
  }, {
    "date": dateLabel[27],
    "value": capitalValue[27]
  },
  {
    "date": dateLabel[28],
    "value": capitalValue[28]
  }, {
    "date": dateLabel[29],
    "value": capitalValue[29]
  }, {
    "date": dateLabel[30],
    "value": capitalValue[30]
  }, {
    "date": dateLabel[31],
    "value": capitalValue[31]
  }];



am4core.useTheme(am4themes_animated);
const CapitalValueChart = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);
    useLayoutEffect(() => {
        let chart = am4core.create("capitalValueBucketDataChartDiv", am4charts.XYChart3D);

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.labels.template.rotation = 270;
        categoryAxis.renderer.labels.template.hideOversized = false;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";

        if(categoryAxis.tooltip) {
          categoryAxis.tooltip.label.rotation = 270;
          categoryAxis.tooltip.label.horizontalCenter = "right";
          categoryAxis.tooltip.label.verticalCenter = "middle";
        }
   
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Capital Value (SGD/ sq.ft)";
        valueAxis.title.fontWeight = "bold";

        var series = chart.series.push(new am4charts.ColumnSeries3D());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "date";
        series.name = "Capital Value";
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;
        series.columns.template.width = am4core.percent(30);

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.stroke = am4core.color("#FFFFFF");

        columnTemplate.adapter.add("fill", function(fill, target) {
          
          return target?.dataItem ? chart.colors.getIndex(target.dataItem.index) :undefined;
        })
        
        columnTemplate.adapter.add("stroke", function(stroke, target) {
          return target?.dataItem ? chart.colors.getIndex(target.dataItem.index) :undefined;
        })

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.strokeOpacity = 0;
        chart.cursor.lineY.strokeOpacity = 0;

        chart.data = chartData;
      
        chartRef.current = chart;

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div id="capitalValueBucketDataChartDiv" style={{ width: "100%", height: "500px" }}></div>
    );
}

export default CapitalValueChart;
