import { useCallback, useEffect, useState, FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectTeam from '../../../../services/ProjectTeam';
import { toast } from 'react-toastify';
import UserDataTable from './components/UserDataTable';
import {
    ProjectTeamMemberUser,
    defaultSelectedCompany,
    ProjetTeamTypes,
} from '../../../../interfaces/ProjectTeam/ProjectTeamMeber';
import CompanyButtonTemplate from '../../../../interfaces/property/CompanyButtonTemplate';
import _ from 'lodash';
import MainLoader from '../../../../components/shared/MainLoader';
import AddUserModal from './components/AddUserModal';
import { Actions } from '../../../../interfaces/general/general';
import { initalValuesUser } from '../../../../interfaces/ProjectTeam/ProjectTeamMeber';
import FeatureState from '../../../../context/state/FeatureState';
import { TeamsModeViewEnum } from '../ProjectTeamTab';

interface ProjectTeamComponentProps {
    companySelected: string;
    setCompanySelected: (prev: string) => void;
    isProjectTeamComponentModalOpen: boolean;
    isProjectTeamComponentmModalOpenActions: Actions;
    modeView?: TeamsModeViewEnum
}

const ProjectTeamComponent: FunctionComponent<ProjectTeamComponentProps> = ({
    companySelected,
    setCompanySelected,
    isProjectTeamComponentModalOpen,
    isProjectTeamComponentmModalOpenActions,
    modeView = TeamsModeViewEnum.LIST
}: ProjectTeamComponentProps) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [isLoading, setIsLoading] = useState<boolean>(false),
        [companies, setCompanies] = useState<Array<CompanyButtonTemplate>>([]),
        [usersArray, setUsersArray] = useState<Array<ProjectTeamMemberUser>>([]),
        [usersArrayCopy, setUsersArrayCopy] = useState<Array<ProjectTeamMemberUser>>([]);

    const fetchProjectTeam = useCallback(async () => {
        setIsLoading(false);
        try {
            const data = await ProjectTeam.fetchProjectTeam(`${propertyAssetId}`);
            const myTeam = await ProjectTeam.fetchMyteam(`${propertyAssetId}`);
            const fullData = data.concat(myTeam);

            const mapped = fullData.map((elem) => {
                return { ...elem.user };
            });

            setUsersArray(mapped);
            setUsersArrayCopy(mapped);
            setIsLoading(true);
            getCompanies(fullData);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const getCompanies = (users: Array<any>) => {
        let resp = _.uniqBy(users, function (el) {
            return el.user.company;
        });

        let companies = resp.map((el, index) => {
            return {
                name: el.user.company,
            };
        });

        setCompanies([{ name: defaultSelectedCompany.ALL }, ...companies]);
    };

    useEffect(() => {
        switch (companySelected) {
            case defaultSelectedCompany.ALL:
                setUsersArray(usersArrayCopy);
                break;

            default:
                const filtered = usersArrayCopy.filter((elem) => elem.company === companySelected);
                setUsersArray(filtered);
                break;
        }
    }, [companySelected, usersArrayCopy]);

    useEffect(() => {
        fetchProjectTeam();
    }, [fetchProjectTeam]);

    return (
        <>
            {isLoading ? (
                <UserDataTable
                    usersArray={usersArray}
                    companies={companies}
                    companySelected={companySelected}
                    setCompanySelected={setCompanySelected}
                    refetch={fetchProjectTeam}
                    type={ProjetTeamTypes.TEAMMEMBER}
                    modeView={modeView}
                />
            ) : (
                <MainLoader type="regular" />
            )}

            <AddUserModal
                isModalOpened={isProjectTeamComponentModalOpen}
                setIsModalOpened={isProjectTeamComponentmModalOpenActions}
                type={ProjetTeamTypes.TEAMMEMBER}
                refetch={fetchProjectTeam}
                isEditMode={false}
                editUser={initalValuesUser}
            />
        </>
    );
};

export default ProjectTeamComponent;
