import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@material-ui/data-grid';
import {
    OptionsUser,
    NodePermission,
    UserPermissionItem,
} from '../../../../../interfaces/dataRoom/usersWithPermissions';
import TreeDotsDropDown from './TreeDotsDropDown';

interface UserTableProps {
    usersList: Array<OptionsUser>;
    permissions: Array<NodePermission>;
    usersWithPermissions: Array<UserPermissionItem>;
    handleClick: (addOrDelete: boolean, id: string, permission: number) => void;
}

const UserTable: FunctionComponent<UserTableProps> = ({
    usersList,
    permissions,
    usersWithPermissions,
    handleClick,
}: UserTableProps) => {
    const { t } = useTranslation(['f', 'b']);

    const checkIsUserWithPermission = (id: string) => {
        let text: string | undefined = t(`f:dataRoom.permissions.2`);

        usersWithPermissions.forEach((elem) => {
            if (elem.userId === id) {
                text = getPermissionForUser(elem);
            }
        });

        return text;
    };

    const getPermissionForUser = (user: UserPermissionItem) => {
        if (user.permissions && user.permissions.length > 0) {
            return getPermission(user.permissions[user.permissions.length - 1]);
        }
    };

    const getPermission = (perm: NodePermission) => {
        return t(`f:dataRoom.permissions.${perm}`);
    };

    const usersLayout = [
        {
            field: 'name',
            headerName: t('f:share-with-modal.name'),
            flex: 1,
            renderCell: (props) => props.row.name,
        },
        {
            field: 'companyName',
            headerName: t('f:share-with-modal.company'),
            flex: 1,
            renderCell: (props) => props.row.companyName,
        },
        {
            field: 'role',
            headerName: t('f:share-with-modal.permission'),
            flex: 1,
            renderCell: (props) => checkIsUserWithPermission(props.row.id),
        },

        {
            field: '',
            headerName: '',
            width: 50,
            disableClickEventBubbling: true,
            renderCell: (props) => (
                <TreeDotsDropDown handleClick={handleClick} permissions={permissions} id={props.row.id} />
            ),
        },
    ];

    return (
        <div style={{ height: '300px', width: '100%' }} className="mb-100">
            <DataGrid rows={usersList} columns={usersLayout} pageSize={20} disableSelectionOnClick />
        </div>
    );
};

export default UserTable;
