import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropertyAssetPageTemplate from '../../../../services/PropertyAssetPageTemplate';
import BaseComponent from './propertyTemplateComponents/BaseComponent';
import MapTemplate from './propertyTemplateComponents/MapTemplate';
import TableTemplate from './propertyTemplateComponents/TableTemplate';
import MultipleTextImageTemplate from './propertyTemplateComponents/MultipleTextImageTemplate';
import MultipleTextImageResearchTemplate from './propertyTemplateComponents/MultipleTextImageResarchTemplate';
import GalleryTemplate from './propertyTemplateComponents/GalleryTemplate';
import _ from 'lodash';
import PropertyAssetDropdown from '../../../../components/shared/PropertyAssetDropdown';
import { toast } from 'react-toastify';
import FeatureState from '../../../../context/state/FeatureState';
import MainLoader from '../../../../components/shared/MainLoader';
import CreateDataForEmptySection from '../../../../components/shared/CreateDataForEmptySection';

interface TemplatesProps {
    pageType: string;
}

const Templates: FunctionComponent<TemplatesProps> = ({ pageType }: TemplatesProps) => {
    const [templateData, setTemplateData] = useState<any>([]),
        [isLoading, setIsLoading] = useState<boolean>(false),
        history = useHistory(),
        { getFeature, propertySummary } = useContext(FeatureState),
        { externalId, id: propertyAssetId } = propertySummary;

    const { t } = useTranslation(['f', 'b']);

    const fetchPropertyTabTemplates = useCallback(
        async (id: string) => {
            try {
                const data = await PropertyAssetPageTemplate.fetchPropertyTabTemplates(pageType, id);

                setTemplateData(_.sortBy(data, ['sortOrder']));
                setIsLoading(true);
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [t, pageType]
    );

    const handleDropDownSelect = async (event: string) => {
        const data = await PropertyAssetPageTemplate.fetchPropertyTabTemplates(pageType, event);
        setTemplateData(data);
    };

    const addTemplate = () => {
        history.push(`/property/${externalId}/template/${pageType}/select-layout/`);
    };

    const onRemoveTemplate = (value: string) => {
        try {
            PropertyAssetPageTemplate.removeTemplate(pageType, `${propertyAssetId}`, value);
            let newData = _.reject(templateData, ['id', value]);
            setTemplateData(newData);
            localStorage.removeItem('content');
            localStorage.removeItem('imageContent');
            localStorage.removeItem('titleContent');
        } catch (err: any) {
            toast(t(`b:${err.response.date.errorMessage}`));
        }
    };

    const checkWitchTemplateIsDisplayed = (template: any) => {
        switch (template.pageSectionType) {
            case 'multiple-text-image':
                return (
                    <MultipleTextImageTemplate
                        template={template}
                        isEditMode={getFeature('PageSummaryEdit')}
                        pageType={pageType}
                        onRemove={onRemoveTemplate}
                    />
                );
            case 'geo-map':
                return (
                    <MapTemplate
                        template={template}
                        isEditMode={getFeature('PageSummaryEdit')}
                        pageType={pageType}
                        onRemove={onRemoveTemplate}
                    />
                );
            case 'table':
                return (
                    <TableTemplate
                        template={template}
                        isEditMode={getFeature('PageSummaryEdit')}
                        pageType={pageType}
                        onRemove={onRemoveTemplate}
                    />
                );
            case 'gallery':
                return (
                    <GalleryTemplate
                        template={template}
                        isEditMode={getFeature('PageSummaryEdit')}
                        pageType={pageType}
                        onRemove={onRemoveTemplate}
                    />
                );
            case 'icon-text':
                return (
                    <MultipleTextImageResearchTemplate
                        template={template}
                        isEditMode={getFeature('PageSummaryEdit')}
                        pageType={pageType}
                        onRemove={onRemoveTemplate}
                    />
                );
            default:
                return (
                    <BaseComponent
                        template={template}
                        isEditMode={getFeature('PageSummaryEdit')}
                        pageType={pageType}
                        onRemove={onRemoveTemplate}
                    />
                );
        }
    };

    const checkIfThereIsTemplateData = () => {
        return templateData && templateData.length > 0;
    };

    useEffect(() => {
        fetchPropertyTabTemplates(`${propertyAssetId}`);
    }, [fetchPropertyTabTemplates, propertyAssetId]);

    return (
        <>
            <div className="col-xs-12-clear roperty-tab__container">
                {propertySummary.isPortfolio && pageType !== 'propertyassetresearch' && (
                    <div className="col-xs-6">
                        <PropertyAssetDropdown
                            id={`${propertyAssetId}`}
                            pageType={pageType}
                            classForDrop="col-xs-12-clear col-md-4-clear"
                            handleDropDownSelect={handleDropDownSelect}
                        />
                    </div>
                )}
            </div>
            <div className="col-xs-12 property-tab__container">
                {isLoading ? (
                    <>
                        {checkIfThereIsTemplateData() &&
                            templateData?.map((template: any) => {
                                return (
                                    <div key={template.id} className="property-tab__summary-display-template">
                                        {checkWitchTemplateIsDisplayed(template)}
                                    </div>
                                );
                            })}
                        <div className="col-xs-12-clear">
                            {getFeature('PageSummaryEdit') && (
                                <CreateDataForEmptySection
                                    addClassToContainer={`${
                                        checkIfThereIsTemplateData() ? 'empty-section--small' : ''
                                    }`}
                                    handler={addTemplate}
                                    text={t('f:button.chooseTemplate')}
                                    mainText={t('f:property.chooseTemplateText')}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <MainLoader type="regular" />
                )}
            </div>
        </>
    );
};

export default Templates;
