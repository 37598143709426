import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainTextInput from '../../../components/shared/MainTextInput';
import { PropertyDetailsI, initUser } from '../../../interfaces/user/AccountSettings';
import UploadPhoto from '../partials/UploadPhoto';
import PhoneNumber from './partials/PhoneNumber';
import { toast } from 'react-toastify';
import UserService from '../../../services/User';
import useBoolean from '../../../hooks/use-boolean';
import FormikForm from '../partials/FormikForm';
import { useHistory } from 'react-router-dom';
import ModalImportLoading from '../../../components/shared/ModalImportLoading';

const PersonaDetails = () => {
    const { t } = useTranslation('f'),
        history = useHistory(),
        [userId, setUserId] = useState<string>(''),
        [isFinished, isFinishedActions] = useBoolean(),
        [isLoaded, setIsLoadedActions] = useBoolean();

    const [initalUserDetails, setInitalUserDetails] = useState<PropertyDetailsI>(initUser);

    const checkType = (
        elem: string,
        values: PropertyDetailsI,
        errors: any,
        touched: any,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    ) => {
        switch (elem) {
            case 'imageUrl':
                return (
                    <UploadPhoto
                        type="personalDetails"
                        imageUrl={values.imageUrl}
                        setFieldValue={setFieldValue}
                        name="imageUrl"
                    />
                );

            case 'phoneNumber':
                return (
                    <PhoneNumber
                        setInitalUserDetails={setInitalUserDetails}
                        values={values}
                        name="phoneNumber"
                        setFieldValue={setFieldValue}
                    />
                );
            default:
                return (
                    <MainTextInput
                        name={elem}
                        placeholder={t(`f:account-settings.personalDetailsPlaceholder.${elem}`)}
                        label={t(`f:account-settings.personalDetails.${elem}`)}
                        errors={errors}
                        touched={touched}
                        disabled={elem === 'email'}
                    />
                );
        }
    };

    const displayFields = (
        values: PropertyDetailsI,
        errors: any,
        touched: any,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    ) => {
        return Object.keys(initalUserDetails).map((elem) => {
            return (
                <div className="col-xs-8 col-md-8 col-lg-3  form-group" key={elem}>
                    {checkType(elem, values, errors, touched, setFieldValue)}
                </div>
            );
        });
    };

    const handleSubmitMyDetails = useCallback(
        async (values: PropertyDetailsI) => {
            isFinishedActions.setTrue();
            try {
                await UserService.updateMe(values);
                await UserService.userPoriflePicture(userId, values.imageUrl);

                setInitalUserDetails(values);

                toast.success(t('f:account-settings.successfullyUpdate'));
                history.push('/');
                isFinishedActions.setFalse();
            } catch (error: any) {
                toast(t(`b:${error.response.data.error.errorMessage}`));
                isFinishedActions.setFalse();
            }
        },
        [userId, t, history]
    );

    const fetchMyInfo = useCallback(async () => {
        try {
            const myInfo = await UserService.getMyUserInfo();
            setUserId(myInfo.id);

            setInitalUserDetails({
                imageUrl: myInfo.imageUrl,
                firstName: myInfo.firstName,
                lastName: myInfo.lastName,
                phoneNumber: myInfo.phoneNumber,
                address: myInfo.address,
                department: myInfo.department,
                email: myInfo.email,
                position: myInfo.position,
            });
            setIsLoadedActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.error.errorMessage}`));
        }
    }, [t]);

    useEffect(() => {
        fetchMyInfo();
    }, [fetchMyInfo]);

    return (
        <div>
            <FormikForm
                handleSubmit={handleSubmitMyDetails}
                isLoaded={isLoaded}
                displayFields={displayFields}
                initVal={initalUserDetails}
            />
            <ModalImportLoading text={t(`f:loaders.uploading`)} isModalOpened={isFinished} />
        </div>
    );
};

export default PersonaDetails;
