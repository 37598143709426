import { PropertyInvestor } from '../../interfaces/ProjectTeam/Investor';
import api from '../index';

const fetchInvestors = (propertyAssetId: string) => {
    return api.get<Array<PropertyInvestor>>(`/propertyassetprojectteam/${propertyAssetId}/investors`);
};

const addInvestors = (propertyAssetId: string, companyId: string) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}/investors`, {
        companyId: companyId,
    });
};
const blockInvestors = (propertyAssetId: string, companyId: string) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}/investors`, {
        companyId: companyId,
    });
};
const unBlockInvestors = (propertyAssetId: string, companyId: string) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}/investors`, {
        companyId: companyId,
    });
};

const addComment = (propertyAssetId: string, companyId: string, value: string) => {
    return api.put(`/propertyassetprojectteam/${propertyAssetId}/investors/${companyId}`, { comments: value });
};

const fetchTeamRoles = () => {
    return api.get(`/propertyassetprojectteam/enum/roles`);
};
const fetchProjectTeamRoles = () => {
    return api.get(`/propertyassetprojectteam/enum/projectteamroles`);
};

const inviteUser = (propertyAssetId: string, body: any) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}/invites`, body);
};

const inviteUserToProjectTeam = (propertyAssetId: string, body: any) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}`, body);
};

const fetchMyteam = (propertyAssetId: string) => {
    return api.get(`/propertyassetprojectteam/${propertyAssetId}/myteam`);
};

const fetchProjectTeam = (propertyAssetId: string) => {
    return api.get(`/propertyassetprojectteam/${propertyAssetId}/projectteam`);
};
/* Add/update team member for property (ProjectTeamEdit) */
const addProjectTeamMember = (propertyAssetId: string, body: any) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}`, body);
};

const addOrUpdateTeamMember = (propertyAssetId: string, body: any) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}/company`, body);
};

const hideProjectTeam = (propertyAssetId: string, userId: string, isHidden: any) => {
    return api.put(`/propertyassetprojectteam/${propertyAssetId}/${userId}/projectteam/hide?hide=${isHidden}`);
};

const isProjectTeamHidden = (propertyAssetId: string) => {
    return api.get(`/propertyassetprojectteam/${propertyAssetId}/projectteam/hide`);
};
/*Delete team member from property (ProjectTeamEdit) */
const deleteTeamMember = (propertyAssetId: string, userId: string) => {
    return api.delete(`/propertyassetprojectteam/${propertyAssetId}/${userId}`);
};

const fetchCompanies = (propertyAssetId: string) => {
    return api.get(`/propertyassetprojectteam/${propertyAssetId}/companies`);
};

const featchIsHiddenFromUser = (propertyAssetId: string, userId: string) => {
    return api.get(`/propertyassetprojectteam/${propertyAssetId}/${userId}/projectteam/hide`);
};

const editIsHiddenForUser = (propertyAssetId: string, userId: string, hide: boolean) => {
    return api.put(`/propertyassetprojectteam/${propertyAssetId}/${userId}/projectteam/hide?hide=${hide}`);
};

const createInvForCompany = (propertyAssetId: string, body: any) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}/company`, body);
};

const hideNoSeller = (propertyAssetId: string, status: boolean) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}/projectteam/hide/no-seller?hide=${status}`);
};

const fetchAllCompaniesInProjectTeam = (propertyAssetId: string) => {
    return api.get(`/propertyassetprojectteam/${propertyAssetId}/companies`);
};

const fetchAvailableCompanies = (propertyAssetId: string) => {
    return api.get(`propertyassetprojectteam/${propertyAssetId}/availablecompanies`);
};

const inviteInvestor = (propertyAssetId: string, body) => {
    return api.post(`/propertyassetprojectteam/${propertyAssetId}/investors`, body);
};

const projectTeamApi = {
    fetchInvestors,
    addComment,
    inviteInvestor,
    addInvestors,
    unBlockInvestors,
    blockInvestors,
    hideNoSeller,
    fetchTeamRoles,
    fetchAvailableCompanies,
    inviteUserToProjectTeam,
    createInvForCompany,
    inviteUser,
    fetchAllCompaniesInProjectTeam,
    fetchProjectTeamRoles,
    featchIsHiddenFromUser,
    editIsHiddenForUser,
    fetchMyteam,
    fetchCompanies,
    fetchProjectTeam,
    addOrUpdateTeamMember,
    hideProjectTeam,
    isProjectTeamHidden,
    deleteTeamMember,
    addProjectTeamMember,
};

export default projectTeamApi;
