import CountryApi from '../api/routes/CountryApi';

const fetchCountries = async () => {
    const { data } = await CountryApi.fetchCountries();
    return data;
};

const CountrySer = {
    fetchCountries,
};
export default CountrySer;
