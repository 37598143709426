import BaseModel from './BaseModel';

export default class PropertyLookupModel extends BaseModel {
	id: number;
	name: string;

	constructor(data:any) {
	super()

	this.id = data.Id
	this.name = data.Name
	}
}
