import * as React from 'react';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import Company from '../../services/Company';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import RouteParams from '../../interfaces/general/Route';

interface Company {
    id: number;
    name: string;
    type: string;
    industry: string;
    speciality: string;
    url: string;
}

interface Props {
    company: Company;
}

const CompanyCard: React.FC<Props> = ({ company, children }) => {
    const [numberOfUsers, setNumberOfUsers] = useState<Array<any>>([]),
        { t } = useTranslation(['f']),
        { edit } = useParams<RouteParams>();

    useEffect(() => {
        if (!edit) {
            Company.fetchCompanyUsers(company.id.toString()).then((resp) => {
                setNumberOfUsers(resp);
            });
        }
    }, []);

    return (
        <article className="marketing__company d-flex">
            <div className="marketing__company-logo d-flex">
                {company.url ? (
                    <img src={company.url} alt="company" className="marketing__company-logo-img" />
                ) : (
                    <FontAwesomeIcon icon={faBuilding} className="marketing__company-logo-img--icon mr-10" />
                )}
                <div className="marketing__company-logo-info d-flex">
                    <span className="marketing__company-title marketing__company-title--name">{company.name}</span>
                    <span className="marketing__company-title marketing__company-title--industry">
                        {t(`f:companyCard.propertyIndustry`)}
                    </span>
                </div>
            </div>

            <div className="marketing__company-group marketing__company-group--marg d-flex">
                <span className="marketing__company-title">{t(`f:companyCard.website`)}</span>
                <span className="marketing__company-title marketing__company-title--sub">{company.url}</span>
            </div>

            <div className="marketing__company-group marketing__company-group--marg d-flex">
                <span className="marketing__company-title">{t(`f:companyCard.employees`)}</span>
                <span className="marketing__company-title marketing__company-title--sub">{numberOfUsers.length}</span>
            </div>

            <div className="marketing__company-group marketing__company-group--marg d-flex">
                <span className="marketing__company-title">{t(`f:companyCard.Specialities`)}</span>
                <span className="marketing__company-title marketing__company-title--sub">{company.speciality}</span>
            </div>

            <div className="marketing__company-group d-flex">
                <span className="marketing__company-title">{t(`f:companyCard.industry`)}</span>
                <span className="marketing__company-title marketing__company-title--sub">{company.industry}</span>
            </div>
            {children}
        </article>
    );
};
export default CompanyCard;
