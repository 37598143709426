import api from '../index';

const fetchListingType = () => {
    return api.get(`/listopportunity/propertyassetstatus`);
};
const fetchNdaType = () => {
    return api.get(`/listopportunity/enum/ndatype`);
};
const fetchApprovaltype = () => {
    return api.get(`/listopportunity/enum/approvaltype`);
};

const createListingType = (propertyAssetId: string, body: any) => {
    return api.post(`/listopportunity/${propertyAssetId}/listingtype`, body);
};

const fetchMarketingTimeline = (propertyAssetId: string) => {
    return api.get(`/listopportunity/${propertyAssetId}/marketingtimeline`);
};

const createMarketingTimeline = (propertyAssetId: string, body: any) => {
    return api.post(`/listopportunity/${propertyAssetId}/marketingtimeline`, body);
};

const bulkUpdateMarketingTimeline = (propertyAssetId: string, body: any) => {
    return api.put(`/listopportunity/${propertyAssetId}/marketingtimeline`, body);
}

const fetchBidingStructure = (propertyAssetId: string) => {
    return api.get(`/listopportunity/${propertyAssetId}/bidingstructure`);
};

const fetchRoles = () => {
    return api.get(`/listopportunity/enum/role`);
};

const createBidingStructure = (propertyAssetId: string, body: any) => {
    return api.post(`/listopportunity/${propertyAssetId}/bidingstructure`, body);
};

const fetchListOpportunityStage = () => {
    return api.get(`/listopportunity/enum/listopportunitystage`);
};

const fetchListOverview = (propertyAssetId: string) => {
    return api.get(`/listopportunity/${propertyAssetId}/overview`);
};

const fetchListingTypeByPoppertyId = (propertyAssetId: string) => {
    return api.get(`/listopportunity/${propertyAssetId}/listingtype`);
};

const fetchProperyInvestor = (propertyAssetId: string) => {
    return api.get(`/listopportunity/${propertyAssetId}/investors`);
};

const createInviteForInvestor = (propertyAssetId: string, companyId: string, body: any) => {
    return api.post(`/listopportunity/${propertyAssetId}/investors/${companyId}`, body);
};

const createInviteForUser = (propertyAssetId: string, body: any) => {
    return api.post(`/listopportunity/${propertyAssetId}/investors/invite`, body);
};

const createCustomNda = (propertyAssetId: string, body: any) => {
    return api.post(`/listopportunity/${propertyAssetId}/customnda`, body);
};

const createInviteForUserForm = (propertyAssetId: string, user: any) => {
    return api.post(`/listopportunity/${propertyAssetId}/investors/invite`, user);
};

const fetchApprovals = (propertyAssetId: string) => {
    return api.get(`/listopportunity/${propertyAssetId}/approvals`);
};

const removeInvestor = (propertyAssetId: string, companyId: string) => {
    return api.delete(`/listopportunity/${propertyAssetId}/investors/${companyId}`);
};

const removeStage = (propertyAssetId: string, stageId: string) => {
    return api.delete(`/listopportunity/${propertyAssetId}/marketingtimeline/${stageId}`);
};

const updateStage = (propertyAssetId: string, stageId: string, body: any) => {
    return api.put(`/listopportunity/${propertyAssetId}/marketingtimeline/${stageId}`, body);
};

const createListOpportunityFinish = (propertyAssetId: string, data: any) => {
    return api.post(`/listopportunity/${propertyAssetId}/listoportunity`, data);
};

const unlistOpportunity = (propertyAssetId: string) => {
    return api.post(`/listopportunity/${propertyAssetId}/unlist`);
}

const sendMailToUserWhoCanApprove = (propertyAssetId: string, userId: string) => {
    return api.post(`/listopportunity/${propertyAssetId}/${userId}/approve`);
};

const ListOpportunityApi = {
    fetchListingType,
    createListingType,
    fetchMarketingTimeline,
    createMarketingTimeline,
    bulkUpdateMarketingTimeline,
    removeInvestor,
    updateStage,
    fetchBidingStructure,
    createInviteForInvestor,
    createListOpportunityFinish,
    createInviteForUserForm,
    fetchApprovals,
    removeStage,
    createInviteForUser,
    createBidingStructure,
    fetchListOverview,
    createCustomNda,
    fetchListingTypeByPoppertyId,
    fetchProperyInvestor,
    fetchListOpportunityStage,
    fetchRoles,
    fetchNdaType,
    sendMailToUserWhoCanApprove,
    fetchApprovaltype,
    unlistOpportunity
};
export default ListOpportunityApi;
