import { FunctionComponent } from 'react';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Stages } from '../../../../interfaces/property/Stages';
import BidService from '../../../../services/BidService';
import useBoolean from '../../../../hooks/use-boolean';
import MainLoader from '../../../../components/shared/MainLoader';
import MainButton from '../../../../components/shared/MainButton';
import { DealLineStepNames } from '../../../../constants/wizardSteps';
import FeatureState from '../../../../context/state/FeatureState';
import DealTimelineModal from './DealTimelineModal';
import {isMobile} from 'react-device-detect';

const HeroDealTimeline: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [isOpenDealTimelineModal, isOpenDealTimelineModalActions] = useBoolean(),
        [isLoaded, IsLoadedActions] = useBoolean(),
        { getFeature } = useContext(FeatureState),
        history = useHistory(),
        [listOfStages, setListOfStages] = useState<Array<Stages>>([]),
        [currentStateIndex, setCurrentStateIndex] = useState<number | null>(null),
        { Step } = Steps;

    const createDealTimeLine = () => {
        history.push(`/property/${propertySummary.externalId}/deal-line/${DealLineStepNames.MARKETING_TIMELINE}`);
    };

    const customDot = (dot, { status, index }) => (
        <div className="relative">
            <div
                className={`property-hero__timeline-dot ${
                    currentStateIndex === index ? 'property-hero__timeline-dot--active' : ''
                }`}
            />
        </div>
    );

    const DesktopTimeline = () => {
        return isLoaded ? (
            <>
                {listOfStages.length ? (
                    <div onClick={handleOpenModal} className="pointer">
                        <Steps responsive={true} current={0} progressDot={customDot}>
                            {listOfStages.map((el, i) => (
                                <Step key={i} title={currentStateIndex === i ? el.text : ''} />
                            ))}
                        </Steps>
                    </div>
                ) : (
                    <>
                        {getFeature('ListOportunity') && (
                            <MainButton
                                layout="primary"
                                size="main"
                                buttonHandler={createDealTimeLine}
                                buttonText={t('f:button.addTimeline')}
                            />
                        )}
                    </>
                )}
            </>
        ) : (
            // <MainLoader type="regular" size={40} />
            <></>
        )
    }

    const featchMarketingTimeline = useCallback(async () => {
        try {
            const stages = await BidService.fetchStages(`${propertyAssetId}`);
            setListOfStages(stages);
            if (stages.length) {
                const today = new Date().getTime();
                let isCurrentStage = false;
                stages.forEach((element, i) => {
                    if (
                        new Date(element.startDate).getTime() <= today &&
                        new Date(element.closeDate).getTime() >= today
                    ) {
                        isCurrentStage = true;
                        setCurrentStateIndex(i);
                    }
                });

                if (!isCurrentStage) {
                    const next = stages.find((element, i) => {
                        if (new Date(element.startDate).getTime() > today) {
                            setCurrentStateIndex(i);
                        }
                        return new Date(element.startDate).getTime() > today;
                    });
                    if (!next) setCurrentStateIndex(stages.length - 1);
                }
            }

            IsLoadedActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const handleOpenModal = useCallback(() => {
        isOpenDealTimelineModalActions.setTrue();
    }, []);

    useEffect(() => {
        featchMarketingTimeline();
    }, [featchMarketingTimeline]);

    return (
        <div className="">
           {
             isMobile? <div>  <button
             className="button-main button-primary mb-10"
             onClick={() => handleOpenModal()}
         >
             {t(`f:button.timeline`)}
         </button></div> : <DesktopTimeline/>
           } 
            <DealTimelineModal
                isModalOpened={isOpenDealTimelineModal}
                setIsModalOpened={isOpenDealTimelineModalActions}
                stages={listOfStages}
            />
        </div>
    );
};
export default HeroDealTimeline;
