import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import UserService from '../../../services/User';
import { toast } from 'react-toastify';

interface Props {
    cancelEverything: () => void;
    returnToPreviousPage: () => void;
    goToNextPage: () => void;
}

export const Preferences: FunctionComponent<Props> = ({ cancelEverything, returnToPreviousPage, goToNextPage }) => {
    const { t } = useTranslation(['f', 'b']);
    return (
        <div className="marketing wizard-container-steps">
            <p className="listing-type__help">
                Having trouble? <span className="listing-type__help-link">{t('f:userWizard.userDetails.getHelp')}</span>
            </p>

            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                <p
                    className="previous-page"
                    onClick={() => {
                        returnToPreviousPage();
                    }}
                >
                    {t('f:button.previousStep')}
                </p>
                <button
                    className="button-main button-secondary d-flex justify-content-center align-items-center"
                    onClick={() => {
                        cancelEverything();
                    }}
                    type="submit"
                >
                    {t('f:button.cancel')}
                </button>

                <button
                    className="button-main button-primary mr-25"
                    onClick={() => {
                        goToNextPage();
                    }}
                >
                    {t('f:button.finish')}
                </button>
            </div>
        </div>
    );
};
