import { FunctionComponent } from 'react';

interface BurgerProps {
    handleOnClick: () => void;
    classNameMain?: string;
    classNameFirstLine?: string;
    classNameSecondLine?: string;
}

const Burger: FunctionComponent<BurgerProps> = ({
    handleOnClick,
    classNameMain,
    classNameFirstLine,
    classNameSecondLine,
}: BurgerProps) => (
    <div className={`burger ${classNameMain}`} onClick={handleOnClick}>
        <div className={`burger-line burger-line--first ${classNameFirstLine}`}></div>
        <div className={`burger-line burger-line--second mt-5 ${classNameSecondLine}`}></div>
    </div>
);
export default Burger;
