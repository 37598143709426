import { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const overall = [
    9.6, 10.0, 10.3, 10.5, 10.6, 10.1, 9.6, 9.5, 9.1, 8.8, 8.6, 8.5, 8.4, 8.5, 8.8, 9.2, 9.4, 9.7, 9.9, 10.3, 10.6,
    10.8, 10.8, 10.8, 10.8, 10.5, 9.5, 9.3, 10.1, 11.3, 12.2,
];

const overallSeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: overall[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: overall[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: overall[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: overall[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: overall[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: overall[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: overall[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: overall[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: overall[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: overall[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: overall[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: overall[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: overall[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: overall[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: overall[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: overall[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: overall[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: overall[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: overall[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: overall[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: overall[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: overall[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: overall[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: overall[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: overall[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: overall[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: overall[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: overall[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: overall[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: overall[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: overall[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: overall[31],
    },
];

const raffles: Array<any> = [
    9.8, 10.2, 10.5, 10.7, 10.9, 10.5, 10.1, 9.8, 9.5, 9.2, 8.9, 8.8, 8.6, 8.7, 8.9, 9.2, 9.5, 9.7, 9.8, 10.2, 10.4,
    10.5, 10.6, 10.5, 10.5, 10.1, 9.2, 9.1, 9.8, 10.8, 11.6,
];

const raffleSeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: raffles[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: raffles[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: raffles[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: raffles[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: raffles[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: raffles[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: raffles[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: raffles[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: raffles[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: raffles[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: raffles[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: raffles[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: raffles[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: raffles[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: raffles[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: raffles[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: raffles[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: raffles[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: raffles[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: raffles[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: raffles[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: raffles[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: raffles[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: raffles[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: raffles[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: raffles[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: raffles[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: raffles[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: raffles[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: raffles[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: raffles[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: raffles[31],
    },
];

const shentonWay: Array<any> = [
    7.8, 8.0, 8.1, 8.1, 8.1, 8.0, 7.8, 8.0, 7.7, 7.6, 7.4, 7.4, 7.3, 7.3, 7.7, 8.0, 8.2, 8.4, 8.5, 8.8, 9.0, 9.4, 9.4,
    9.4, 9.4, 9.2, 8.3, 8.2, 8.8, 9.9, 10.7,
];

const shentonSeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: shentonWay[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: shentonWay[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: shentonWay[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: shentonWay[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: shentonWay[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: shentonWay[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: shentonWay[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: shentonWay[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: shentonWay[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: shentonWay[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: shentonWay[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: shentonWay[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: shentonWay[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: shentonWay[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: shentonWay[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: shentonWay[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: shentonWay[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: shentonWay[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: shentonWay[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: shentonWay[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: shentonWay[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: shentonWay[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: shentonWay[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: shentonWay[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: shentonWay[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: shentonWay[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: shentonWay[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: shentonWay[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: shentonWay[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: shentonWay[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: shentonWay[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: shentonWay[31],
    },
];

const marina = [
    9.2, 9.6, 9.9, 10.3, 10.6, 9.9, 9.4, 9.3, 9.2, 8.9, 8.8, 8.7, 8.5, 8.6, 8.9, 9.2, 9.5, 9.8, 9.8, 10.0, 10.4, 10.7,
    10.7, 10.8, 10.8, 10.4, 9.5, 9.3, 10.0, 11.0, 11.9,
];

const marinaData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: marina[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: marina[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: marina[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: marina[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: marina[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: marina[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: marina[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: marina[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: marina[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: marina[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: marina[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: marina[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: marina[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: marina[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: marina[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: marina[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: marina[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: marina[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: marina[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: marina[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: marina[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: marina[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: marina[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: marina[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: marina[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: marina[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: marina[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: marina[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: marina[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: marina[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: marina[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: marina[31],
    },
];

const marinaBay = [
    11.5, 12.2, 12.8, 12.9, 12.9, 12.0, 11.3, 10.9, 10.0, 9.7, 9.5, 9.5, 9.4, 9.5, 10.0, 10.5, 10.8, 11.3, 11.5, 12.0,
    12.5, 12.6, 12.6, 12.6, 12.6, 12.2, 11.1, 10.9, 11.8, 13.2, 14.2,
];

const marinaBayData = [
    {
        date: new Date(2014, 1, 1),
        value: marinaBay[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: marinaBay[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: marinaBay[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: marinaBay[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: marinaBay[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: marinaBay[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: marinaBay[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: marinaBay[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: marinaBay[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: marinaBay[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: marinaBay[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: marinaBay[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: marinaBay[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: marinaBay[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: marinaBay[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: marinaBay[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: marinaBay[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: marinaBay[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: marinaBay[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: marinaBay[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: marinaBay[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: marinaBay[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: marinaBay[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: marinaBay[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: marinaBay[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: marinaBay[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: marinaBay[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: marinaBay[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: marinaBay[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: marinaBay[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: marinaBay[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: marinaBay[31],
    },
];

am4core.useTheme(am4themes_animated);
const GrossRentChart = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);

    function processOver(hoveredSeries, chart) {
        hoveredSeries.toFront();

        hoveredSeries.segments.each(function (segment) {
            segment.setState('hover');
        });

        hoveredSeries.legendDataItem.marker.setState('default');
        hoveredSeries.legendDataItem.label.setState('default');
        if (chart) {
            chart.series.each(function (series) {
                if (series != hoveredSeries) {
                    series.segments.each(function (segment) {
                        segment.setState('dimmed');
                    });
                    series.bulletsContainer.setState('dimmed');
                    series.legendDataItem.marker.setState('dimmed');
                    series.legendDataItem.label.setState('dimmed');
                }
            });
        }
    }

    function processOut(chart) {
        chart.series.each(function (series) {
            series.segments.each(function (segment) {
                segment.setState('default');
            });

            series.bulletsContainer.setState('default');
            series.legendDataItem.marker.setState('default');
            series.legendDataItem.label.setState('default');
        });
    }

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);

        var chart = am4core.create('grossRentDiv', am4charts.XYChart);

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // chart.dateFormatter.dateFormat = 'yyyy-q';

        // var overallSeries = chart.series.push(new am4charts.LineSeries());
        // overallSeries.dataFields.valueY = 'value';
        // overallSeries.dataFields.dateX = 'date';
        // overallSeries.name = 'Overall';
        // overallSeries.strokeWidth = 3;
        // overallSeries.tensionX = 0.8;
        // overallSeries.bullets.push(new am4charts.CircleBullet());
        // overallSeries.data = overallSeriesData;

        var marinaData = chart.series.push(new am4charts.LineSeries());
        marinaData.dataFields.valueY = 'value';
        marinaData.dataFields.dateX = 'date';
        marinaData.name = 'Overall';
        marinaData.strokeWidth = 3;
        marinaData.bullets.push(new am4charts.CircleBullet());
        marinaData.fill = am4core.color('#fff');
        marinaData.tooltipText = '{value}';
        marinaData.strokeDasharray = '5,4';
        marinaData.data = overallSeriesData;

        var rafflesSeries = chart.series.push(new am4charts.LineSeries());
        rafflesSeries.dataFields.valueY = 'value';
        rafflesSeries.dataFields.dateX = 'date';
        rafflesSeries.name = 'Raffles';
        rafflesSeries.bullets.push(new am4charts.CircleBullet());
        rafflesSeries.fill = am4core.color('#fff');
        rafflesSeries.tooltipText = '{value}';
        rafflesSeries.strokeWidth = 3;
        rafflesSeries.strokeDasharray = '5,4';
        rafflesSeries.data = raffleSeriesData;

        var shentonWaySeries = chart.series.push(new am4charts.LineSeries());
        shentonWaySeries.dataFields.valueY = 'value';
        shentonWaySeries.dataFields.dateX = 'date';
        shentonWaySeries.name = 'Shenton Way';
        shentonWaySeries.bullets.push(new am4charts.CircleBullet());
        shentonWaySeries.fill = am4core.color('#fff');
        shentonWaySeries.tooltipText = '{value}';
        shentonWaySeries.strokeWidth = 3;
        shentonWaySeries.strokeDasharray = '5,4';
        shentonWaySeries.data = shentonSeriesData;

        var marinaSeries = chart.series.push(new am4charts.LineSeries());
        marinaSeries.dataFields.valueY = 'value';
        marinaSeries.dataFields.dateX = 'date';
        marinaSeries.name = 'Marina Bay';
        marinaSeries.bullets.push(new am4charts.CircleBullet());
        marinaSeries.fill = am4core.color('#fff');
        marinaSeries.tooltipText = '{value}';
        marinaSeries.strokeWidth = 3;
        marinaSeries.strokeDasharray = '5,4';
        marinaSeries.data = marinaBayData;

        var marinaBaySeries = chart.series.push(new am4charts.LineSeries());
        marinaBaySeries.dataFields.valueY = 'value';
        marinaBaySeries.dataFields.dateX = 'date';
        marinaBaySeries.name = 'Marina Bay';
        marinaBaySeries.strokeWidth = 3;
        marinaBaySeries.stroke = chart.colors.getIndex(8);
        marinaBaySeries.bullets.push(new am4charts.CircleBullet());
        marinaBaySeries.fill = am4core.color('#fff');
        marinaBaySeries.tooltipText = '{value}';
        marinaBaySeries.strokeDasharray = '5,4';
        marinaBaySeries.data = marinaBayData;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.fullWidthLineX = true;
        chart.cursor.xAxis = dateAxis;
        chart.cursor.lineX.strokeWidth = 0;
        chart.cursor.lineX.fill = am4core.color('#000');
        chart.cursor.lineX.fillOpacity = 0.1;

        return () => {
            chart.dispose();
        };
    }, []);

    return <div id="grossRentDiv" style={{ width: '100%', height: '500px' }}></div>;
};

export default GrossRentChart;
