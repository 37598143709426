import BuildingDirectoryApi from '../api/routes/BuildingDirectoryApi';
import BuildingDirectory from '../pages/BuildingDirectory/models/buildingDirectory';
import BuildingDirectoryInfo from '../pages/BuildingDirectory/models/buildingDirectoryInfo';

const fetchBuildingDirectories = async (address?: string, country?: string, lon?: number, lat?: number): Promise<BuildingDirectoryInfo[]> => {
    const response = await BuildingDirectoryApi.fetchBuildingDirectories(address, country, lon, lat);

    return response.data.map(x => new BuildingDirectoryInfo(x));
};

const fetchBuildingDirectory = async (id: string): Promise<BuildingDirectory> => {
    const response = await BuildingDirectoryApi.fetchBuildingDirectory(id);

    return response.data as any;
};

const fetchCountries = async (): Promise<string[]> => {
    const response = await BuildingDirectoryApi.fetchCountries();

    return response.data;
};

const BuildingDirectoryService = {
    fetchBuildingDirectories,
    fetchBuildingDirectory,
    fetchCountries,
};
export default BuildingDirectoryService;
