import { Moment } from "moment";
import api from ".."

const fetchUsersByCountry = (startDate: Moment, endDate: Moment) => {
    const params = {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD")
    };

    return api.get(`/googleAnalytics/users`, { params });
};

const fetchTopUsersOfProperty = (propertyExternalId: string) => {
    return api.get(`/googleAnalytics/top-users/${propertyExternalId}`);
}

const fetchTopCountriesAccessProperty = (propertyExternalId: string) => {
    return api.get(`/googleAnalytics/top-countries/${propertyExternalId}`);
}

const fetchActiveUsersOfProperty = (propertyExternalId: string) => {
    return api.get(`/googleAnalytics/active-users/${propertyExternalId}`);
}

const fetchActiveUsersInDays = (propertyExternalId?: string) => {
    const url = propertyExternalId ?
        `/googleAnalytics/active-users-in-days/${propertyExternalId}` :
        `/googleAnalytics/active-users-in-days`;

    return api.get(url);
}

const fetchMostViewsProperties = (startDate: Moment, endDate: Moment) => {
    const params = {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD")
    };

    return api.get(`/googleAnalytics/most-views-properties`, { params });
}

const fetchMostViewTabsOfProperty = (propertyId: string) => {
    return api.get(`/googleAnalytics/most-views-tabs/${propertyId}`);
}

const GoogleAnalyticsApi = {
    fetchUsersByCountry,
    fetchTopUsersOfProperty,
    fetchTopCountriesAccessProperty,
    fetchActiveUsersOfProperty,
    fetchActiveUsersInDays,
    fetchMostViewsProperties,
    fetchMostViewTabsOfProperty
};

export default GoogleAnalyticsApi;