import * as React from 'react';
import { PieChartInterface, LineChartInterfaceShort, BarInitInterface } from '../../interfaces/chart/ChartInterface';

export type TemplateContextStateType = {
    chartData: Array<LineChartInterfaceShort> | BarInitInterface | Array<PieChartInterface>;
    newChartData: Array<LineChartInterfaceShort> | BarInitInterface | Array<PieChartInterface>;
    isPreviewShow: boolean;
    handlePieFinish: (data: Array<PieChartInterface>) => void;
    handleBarFinish: (data: BarInitInterface) => void;
    handleLineFinish: (date: Array<LineChartInterfaceShort>) => void;
    chartTitle: string;
    newChartTitle: string;
    setChartTitle: (prev: string) => void;
    setNewChartTitle: (prev: string) => void;
    handleOnClickAddDate: () => void;
    handleOnClickDeleteDate: () => void;
    dateType: number;
    setDateType: (prev: number) => void;
    setIsPreviewShow: (prev: boolean) => void;
    orderBy: Array<number>;
    lines: Array<string>;
    chartType: number | undefined;
    setChartType: (prev: number | undefined) => void;
    chartSource: string;
    newChartSource: string;
    isForEdit: boolean;
    isForEditActions: any;
    setChartSource: (prev: string) => void;
    setNewChartSource: (prev: string) => void;
    setChart: (prev: Array<LineChartInterfaceShort> | BarInitInterface | Array<PieChartInterface>) => void;
    setNewChart: (prev: Array<LineChartInterfaceShort> | BarInitInterface | Array<PieChartInterface>) => void;
    chartDataRdy: boolean;
    setChartDataActions: any;
};

export default React.createContext<TemplateContextStateType>({
    chartData: [],
    newChartData: [],
    isPreviewShow: false,
    isForEdit: false,
    isForEditActions: () => {},
    handlePieFinish: (data: Array<PieChartInterface>) => {},
    handleBarFinish: (data: BarInitInterface) => {},
    handleLineFinish: (date: Array<LineChartInterfaceShort>) => {},
    chartTitle: '',
    newChartTitle: '',
    setChartTitle: (prev: string) => {},
    setNewChartTitle: (prev: string) => {},
    handleOnClickAddDate: () => {},
    handleOnClickDeleteDate: () => {},
    dateType: 1,
    setDateType: (prev: number) => {},
    setIsPreviewShow: (prev: boolean) => {},
    orderBy: [0],
    lines: [],
    chartSource: '',
    newChartSource: '',
    setChartSource: (prev: string) => {},
    setNewChartSource: (prev: string) => {},
    chartType: undefined,
    setChartType: () => {},
    setChart: (prev: Array<LineChartInterfaceShort> | BarInitInterface | Array<PieChartInterface>) => {},
    setNewChart: (prev: Array<LineChartInterfaceShort> | BarInitInterface | Array<PieChartInterface>) => {},
    chartDataRdy: false,
    setChartDataActions: () => {},
});
