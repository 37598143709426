import { FunctionComponent, useEffect, useState, useContext, useCallback } from 'react';
import Form from 'antd/lib/form/Form';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Select, Button } from 'antd';
import { useParams } from 'react-router';
import NavigateState from '../../../context/state/NavigateState';
import BidService from '../../../services/BidService';
import { BidInfomationsValidation } from '../../../validations/bidWizzard/BidInfomationsValidation';
import { toast } from 'react-toastify';
import BidInformationInterface from '../../../interfaces/property/BidInformations';
import Company from '../../../interfaces/property/Company';
import LookUpContext from '../../../context/state/LookUpState';
import { numberWithCommas } from '../../../utils/numberWithCommas';
import MainHeading from '../../../components/shared/MainHeading';
import MainLoader from '../../../components/shared/MainLoader';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';

interface Props {
    goToFinanceDetails: (biddingId: string) => void;
}

interface RouteParams {
    id: string;
    biddingId: string;
}

const BidInformations: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']),
        { Option } = Select,
        [offerType, setOfferType] = useState<Array<any>>([]),
        [currency, setCurrency] = useState<Array<any>>([]),
        { getContent } = useContext(LookUpContext),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        { biddingId } = useParams<RouteParams>(),
        [companies, setCompanies] = useState<Company>(),
        { returnToPreviousPage, cancelEverything } = useContext(NavigateState),
        [TransactionType, setTransactionType] = useState<Array<any>>([]),
        [toEditBidInfo, setToEditBidInfo] = useState<BidInformationInterface>(),
        [isLoading, setIsLoading] = useState<boolean>(true),
        [acquisitionPurpose, setAcquisitionPurpose] = useState<Array<any>>([]);

    const fetchCompanies = useCallback(async () => {
        try {
            const resp = await BidService.fetchMyCompany();
            setCompanies(resp);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t]);

    const fetchBidInfo = useCallback(async () => {
        try {
            setIsLoading(true);
            const resp = await BidService.fetchBiddingInformations(`${propertyAssetId}`, biddingId);
            setIsLoading(false);
            setToEditBidInfo(resp);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
        setIsLoading(false);
    }, [propertyAssetId, biddingId, t]);

    const checkUp = (bid, type) => {
        return bid ? (!bid[type] ? '' : '' + bid[type]) : '';
    };

    const checkUpForDropdowns = (bid, type) => {
        return bid ? (!bid[type] ? undefined : bid[type]) : undefined;
    };

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    useEffect(() => {
        setOfferType(getContent('OfferType'));
        setCurrency(getContent('Currency'));
        setTransactionType(getContent('TransactionType'));
        setAcquisitionPurpose(getContent('AcquisitionType'));

        fetchCompanies();

        if (biddingId) {
            fetchBidInfo();
        }
    }, [getContent, biddingId, fetchBidInfo, fetchCompanies]);

    return (
        <div className="bid-info-page">
            <MainHeading text={companies ? companies.name : ''} />

            {!isLoading ? (
                <Formik
                    initialValues={{
                        offerTypeId: toEditBidInfo ? '' + toEditBidInfo.offerTypeId : '',
                        bidAmount: toEditBidInfo
                            ? toEditBidInfo.bidAmount === 0
                                ? ''
                                : `${numberWithCommas('' + toEditBidInfo.bidAmount)}`
                            : '',
                        initialDeposit: `${numberWithCommas(checkUp(toEditBidInfo, 'initialDeposit'))}`,
                        currencyId: toEditBidInfo ? '' + toEditBidInfo.currencyId : '',
                        transactionTypeId: toEditBidInfo ? '' + toEditBidInfo.transactionTypeId : '',
                        noi: `${numberWithCommas(checkUp(toEditBidInfo, 'noi'))}`,
                        buyerAssumedCapRateInitialYield: `${numberWithCommas(
                            checkUp(toEditBidInfo, 'buyerAssumedCapRateInitialYield')
                        )}`,
                        totalNetArea: `${numberWithCommas(checkUp(toEditBidInfo, 'totalNetArea'))}`,
                        acquisitionTypeId: toEditBidInfo ? '' + toEditBidInfo.acquisitionTypeId : '',
                        acquisitionStructure: `${checkUp(toEditBidInfo, 'acquisitionStructure')}`,
                        additionalDeposit: `${numberWithCommas(checkUp(toEditBidInfo, 'additionalDeposit'))}`,
                        closingNumberOfDays: `${checkUp(toEditBidInfo, 'closingNumberOfDays')}`,
                        dueDilligenceNumberOfDays: `${checkUp(toEditBidInfo, 'dueDilligenceNumberOfDays')}`,
                        agreementNumberOfDays: `${checkUp(toEditBidInfo, 'agreementNumberOfDays')}`,
                    }}
                    enableReinitialize={true}
                    validationSchema={BidInfomationsValidation}
                    onSubmit={async (values: any, { setSubmitting }: any) => {
                        try {
                            await BidService.editBidInformation(`${propertyAssetId}`, biddingId, values);
                            props.goToFinanceDetails(biddingId);
                        } catch (error: any) {
                            toast(t(`f:errors.back.${error.response.data.errorMessage}`));
                        }
                    }}
                >
                    {({ values, errors, setFieldValue, resetForm, submitForm, touched }) => (
                        <Form className="bid-info-page__form">
                            <div className="row-clear">
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.offerType')}</label>

                                    {toEditBidInfo && (
                                        <Select
                                            onChange={(e) => setFieldValue('offerTypeId', e)}
                                            defaultValue={checkUpForDropdowns(toEditBidInfo, 'offerTypeId')}
                                            placeholder="Select"
                                            getPopupContainer={handleContainer}
                                        >
                                            {offerType?.map((stage: any) => (
                                                <Option
                                                    value={stage.id}
                                                    label={stage.name}
                                                    className="d-flex"
                                                    key={stage.id}
                                                >
                                                    {stage.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                    {errors.offerTypeId && touched.offerTypeId ? (
                                        <p className="bid-info-page__error-mess">
                                            {t(errors.offerTypeId, {
                                                name: t('f:property.opportunityName'),
                                            })}
                                        </p>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.bidAmount')}</label>

                                    <Field
                                        id="text"
                                        name="bidAmount"
                                        onChange={(e: any) => {
                                            var n = parseInt(e.target.value.replace(/\D/g, ''), 10);
                                            setFieldValue('bidAmount', n.toLocaleString());
                                        }}
                                        placeholder={t('f:bid.bidInformations.bidAmount')}
                                    />
                                    {errors.bidAmount && touched.bidAmount ? (
                                        <p className="bid-info-page__error-mess">
                                            {t(errors.bidAmount, {
                                                name: t('f:bid.bidInformations.bidAmount'),
                                            })}
                                        </p>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.currency')}</label>
                                    {toEditBidInfo && (
                                        <Select
                                            placeholder="Select"
                                            onChange={(e) => setFieldValue('currencyId', e)}
                                            defaultValue={checkUpForDropdowns(toEditBidInfo, 'currencyId')}
                                            getPopupContainer={handleContainer}
                                        >
                                            {currency?.map((stage: any) => (
                                                <Option
                                                    value={stage.id}
                                                    label={stage.name}
                                                    className="d-flex"
                                                    key={stage.id}
                                                >
                                                    {stage.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                    {errors.currencyId && touched.currencyId ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.currencyId, {
                                                name: t('f:bid.bidInformations.currency'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.transactionType')}</label>
                                    {toEditBidInfo && (
                                        <Select
                                            placeholder="Select"
                                            onChange={(e) => setFieldValue('transactionTypeId', e)}
                                            defaultValue={checkUpForDropdowns(toEditBidInfo, 'transactionTypeId')}
                                            getPopupContainer={handleContainer}
                                        >
                                            {TransactionType?.map((stage: any) => (
                                                <Option
                                                    value={stage.id}
                                                    label={stage.name}
                                                    className="d-flex"
                                                    key={stage.id}
                                                >
                                                    {stage.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                    {errors.transactionTypeId && touched.transactionTypeId ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.transactionTypeId, {
                                                name: t('f:bid.bidInformations.transactionType'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.nOINumber')}</label>

                                    <Field
                                        id="text"
                                        name="noi"
                                        placeholder={t('f:bid.bidInformations.noi')}
                                        onChange={(e: any) => {
                                            var n = parseInt(e.target.value.replace(/\D/g, ''), 10);
                                            setFieldValue('noi', n.toLocaleString());
                                        }}
                                    />
                                    {errors.noi && touched.noi ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.noi, {
                                                name: t('f:bid.bidInformations.nOINumber'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.baciy')}</label>
                                    <Field
                                        id="text"
                                        name="buyerAssumedCapRateInitialYield"
                                        placeholder={t('f:bid.bidInformations.buyerAssumedCapRateInitialYield')}
                                        onChange={(e: any) => {
                                            var n = parseInt(e.target.value.replace(/\D/g, ''), 10);
                                            setFieldValue('buyerAssumedCapRateInitialYield', n.toLocaleString());
                                        }}
                                    />

                                    {errors.buyerAssumedCapRateInitialYield &&
                                    touched.buyerAssumedCapRateInitialYield ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.buyerAssumedCapRateInitialYield, {
                                                name: t('f:bid.bidInformations.baciyShort'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group  form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.totalNetRentableArea')}</label>
                                    <Field
                                        id="text"
                                        name="totalNetArea"
                                        placeholder={t('f:bid.bidInformations.totalNetArea')}
                                        onChange={(e: any) => {
                                            var n = parseInt(e.target.value.replace(/\D/g, ''), 10);
                                            setFieldValue('totalNetArea', n.toLocaleString());
                                        }}
                                    />

                                    {errors.totalNetArea && touched.totalNetArea ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.totalNetArea, {
                                                name: t('f:bid.bidInformations.totalNetRentableArea'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group  form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.acquisitionPurpose')}</label>
                                    {toEditBidInfo && (
                                        <Select
                                            placeholder="Select"
                                            onChange={(e) => setFieldValue('acquisitionTypeId', e)}
                                            defaultValue={checkUpForDropdowns(toEditBidInfo, 'acquisitionTypeId')}
                                            getPopupContainer={handleContainer}
                                        >
                                            {acquisitionPurpose?.map((stage: any) => (
                                                <Option
                                                    value={stage.id}
                                                    label={stage.name}
                                                    className="d-flex"
                                                    key={stage.id}
                                                >
                                                    {stage.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    )}
                                    {errors.acquisitionTypeId && touched.acquisitionTypeId ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.acquisitionTypeId, {
                                                name: t('f:bid.bidInformations.acquisitionPurpose'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.acquisitionStructure')}</label>
                                    <Field
                                        id="text"
                                        name="acquisitionStructure"
                                        placeholder={t('f:bid.bidInformations.acquisitionStructure')}
                                    />
                                    {errors.acquisitionStructure && touched.acquisitionStructure ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.acquisitionStructure, {
                                                name: t('f:bid.bidInformations.acquisitionStructure'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.initialDeposit')}</label>

                                    <Field
                                        id="text"
                                        name="initialDeposit"
                                        placeholder={t('f:bid.bidInformations.initialDeposit')}
                                        onChange={(e: any) => {
                                            var n = parseInt(e.target.value.replace(/\D/g, ''), 10);
                                            setFieldValue('initialDeposit', n.toLocaleString());
                                        }}
                                    />
                                    {errors.initialDeposit && touched.initialDeposit ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.initialDeposit, {
                                                name: t('f:bid.bidInformations.initialDeposit'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.additionalDeposit')}</label>

                                    <Field
                                        id="text"
                                        name="additionalDeposit"
                                        placeholder={t('f:bid.bidInformations.additionalDeposit')}
                                        onChange={(e: any) => {
                                            var n = parseInt(e.target.value.replace(/\D/g, ''), 10);
                                            setFieldValue('additionalDeposit', n.toLocaleString());
                                        }}
                                    />

                                    {errors.additionalDeposit && touched.additionalDeposit ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.additionalDeposit, {
                                                name: t('f:bid.bidInformations.additionalDeposit'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.closingAfterDD')}</label>
                                    <Field
                                        id="text"
                                        name="closingNumberOfDays"
                                        placeholder={t('f:bid.bidInformations.closingAfterDD')}
                                    />
                                    {errors.closingNumberOfDays && touched.closingNumberOfDays ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.closingNumberOfDays, {
                                                name: t('f:bid.bidInformations.closingAfterDD'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40">
                                    <label htmlFor="text">{t('f:bid.bidInformations.dueDilligence')}</label>
                                    <Field
                                        id="text"
                                        name="dueDilligenceNumberOfDays"
                                        placeholder={t('f:bid.bidInformations.dueDilligence')}
                                    />

                                    {errors.dueDilligenceNumberOfDays && touched.dueDilligenceNumberOfDays ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.dueDilligenceNumberOfDays, {
                                                name: t('f:bid.bidInformations.dueDilligence'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group bid-info-page__form-cont mt-40 mb-120">
                                    <label htmlFor="text">{t('f:bid.bidInformations.saleAndPurchaseAgreement')}</label>
                                    <Field
                                        id="text"
                                        name="agreementNumberOfDays"
                                        placeholder={t('f:bid.bidInformations.agreementNumberOfDays')}
                                        onChange={(e: any) => {
                                            var n = parseInt(e.target.value.replace(/\D/g, ''), 10);
                                            setFieldValue('agreementNumberOfDays', n.toLocaleString());
                                        }}
                                    />

                                    {errors.agreementNumberOfDays && touched.agreementNumberOfDays ? (
                                        <span className="bid-info-page__error-mess">
                                            {t(errors.agreementNumberOfDays, {
                                                name: t('f:bid.bidInformations.saleAndPurchaseAgreement'),
                                            })}
                                        </span>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                                <p className="previous-page" onClick={() => returnToPreviousPage()}>
                                    {t('f:button.previousStep')}
                                </p>
                                <Button
                                    onClick={() => {
                                        cancelEverything(externalId, PROPERTY_TABS.TRANSACTION.name);
                                    }}
                                    className="button-main button-secondary d-flex justify-content-center align-items-center"
                                >
                                    {t('f:button.cancel')}
                                </Button>
                                <Button className="button-main button-primary mr-25" onClick={submitForm}>
                                    {t('f:button.next')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <MainLoader type="regular" />
            )}
        </div>
    );
};
export default BidInformations;
