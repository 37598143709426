export enum NodePermission {
    Read = 0,
    ReadAndDownload = 1,
    NoAccess = 2,
}

export enum NavigationStrings {
    user = 'USER',
    COMPANY = 'COMPANY',
}

export interface UserPermissionItem {
    userId: string;
    permissions: Array<NodePermission>;
}

export interface UsersWithPermissions {
    directPermissions: Array<UserPermissionItem>;
    inheritedPermissions: Array<UserPermissionItem>;
}

export const defaultPermissions = [NodePermission.Read, NodePermission.ReadAndDownload, NodePermission.NoAccess];

export interface OptionsUser {
    id: number | string;
    name: string;
    icon: string;
    email: string;
    permission: string;
    role: number;
    companyName: string;
}
