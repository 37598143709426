import { FunctionComponent } from 'react';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../auth/authConfig';

interface Props {
    title: string;
    subtitle: string;
}

const Header: FunctionComponent<Props> = ({ title, subtitle }) => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    };
    return (
        <div className="dlt-header-page">
            <div className="mx-50">
                <div className="dlt-header-page__main">
                    <h1 className="dlt-header-page__title mt-100">{title}</h1>
                    <p className="dlt-header-page__subtitle mt-40 mb-100">{subtitle}</p>
                </div>
                <UnauthenticatedTemplate>
                    <div className="d-flex justify-content-center">
                        <button className="home-page-header__btn" onClick={handleLogin}>
                            Login
                        </button>
                    </div>
                </UnauthenticatedTemplate>
            </div>
        </div>
    );
};

export default Header;
