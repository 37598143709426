import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../wrapper/ModalWrapper';
import { Actions } from '../../interfaces/general/general';

interface ModalConfirmDeleteProps {
    isModalOpened: boolean;
    setIsModalOpenedActions: Actions;
    handleFinish: () => void;
    text: string;
}

const ModalConfirmDelete: FunctionComponent<ModalConfirmDeleteProps> = ({
    isModalOpened,
    setIsModalOpenedActions,
    handleFinish,
    text,
}) => {
    const { t } = useTranslation(['f', 'b']);

    const handleClose = useCallback(() => {
        setIsModalOpenedActions.setFalse();
    }, [setIsModalOpenedActions]);

    return (
        <ModalWrapper
            isCloseModalShown={true}
            closeModalHandler={handleClose}
            className="confirm-delete"
            title={text}
            isModalOpened={isModalOpened}
            primaryButtonHandler={handleFinish}
            primaryButtonText={t('f:button.imSure')}
            secondaryButtonHandler={handleClose}
            secondaryButtonText={t('f:button.cancel')}
            isFooterShown={true}
        />
    );
};
export default ModalConfirmDelete;
