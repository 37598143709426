import BiddingApi from '../api/routes/BiddingApi';

const fetchPropertyBiddings = async (propertyAssetId: string) => {
    try {
        let response = await BiddingApi.fetchPropertyBiddings(propertyAssetId);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const approveBid = async (propertyAssetId: string, biddingId: any) => {
    let response = await BiddingApi.approveBid(propertyAssetId, biddingId);
    return response.data;
};

const rejectBid = async (propertyAssetId: string, biddingId: any) => {
    try {
        let response = await BiddingApi.rejectBid(propertyAssetId, biddingId);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const fetchApprovals = async (propertyAssetId: string, biddingId: any) => {
    try {
        let response = await BiddingApi.fetchApprovals(propertyAssetId, biddingId);
        return response.data;
    } catch (err) {
        Promise.reject();
    }
};

const fetchMyPropertyBiddings = async (propertyAssetId: string) => {
    const { data } = await BiddingApi.fetchMyPropertyBiddings(propertyAssetId);
    return data;
};

const BiddingService = {
    fetchPropertyBiddings,
    fetchMyPropertyBiddings,
    approveBid,
    fetchApprovals,
    rejectBid,
};

export default BiddingService;
