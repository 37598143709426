import { FunctionComponent } from 'react';
import { Collapse } from 'antd';
import Topic from '../../components/faqComponents/Topic';
import MainLoader from '../../../../components/shared/MainLoader';
import { isMobile } from 'react-device-detect';

const { Panel } = Collapse;

interface FaqPublicProps {
    tabs: Array<any>;
    questionComponents: Array<any>;
    setTopicId: (prev: string) => void;
    isLoad: boolean;
    topicName: string;
    deleteTopicHandler: (topicId: string) => void;
    setIsModalOpened: (isOpened: boolean) => any;
    openEditModalHandler: (prev: string) => void;
    topicId: string;
}
const FaqPublic: FunctionComponent<FaqPublicProps> = ({
    tabs,
    setTopicId,
    isLoad,
    topicName,
    deleteTopicHandler,
    openEditModalHandler,
    questionComponents,
    topicId
}: FaqPublicProps) => {
    const getQuestionComponentByTopicId = (topicId: number) => {
        const questionComponent = questionComponents.find(item => item.topicId == topicId);
        return questionComponent ? questionComponent.component : null;
    }

    const onChangeTopic = (value: string | string[]) => {
        if (!Array.isArray(value)) {
            setTopicId(value)
        }
    };

    const getTopicHeader = (tab: any) => (<Topic
        topicName={topicName}
        tab={tab}
        deleteTopicHandler={deleteTopicHandler}
        openEditableModal={openEditModalHandler}
    />);

    const containerClassName = isMobile ? "faq__main" : "faq__main mx-50";

    return (
        <div className={containerClassName}>
            {isLoad ? (
                <>
                    {tabs && tabs.length > 0 && (
                        <div className="col-xs-12 faq__questions">
                            <Collapse
                                accordion
                                bordered={false}
                                activeKey={topicId}
                                expandIconPosition={'right'}
                                onChange={onChangeTopic}
                            >
                                {tabs.map((tab: any) => {
                                    return (
                                        <Panel header={getTopicHeader(tab)} key={tab.id}>
                                            {getQuestionComponentByTopicId(tab.id)}
                                        </Panel>
                                    )
                                })}
                            </Collapse>
                        </div>
                    )}
                </>
            ) : (
                <div className="col-xs-12">
                    <MainLoader type="regular" />
                </div>
            )}
        </div>
    );
};
export default FaqPublic;
