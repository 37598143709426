import { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const vacancy = [0.06,
    0.05,
    0.05,
    0.05,
    0.05,
    0.03,
    0.03,
    0.22,
    0.19,
    0.19,
    0.12,
    0.11,
    0.09,
    0.08,
    0.08,
    0.05,
    0.04,
    0.04,
    0.03,
    0.03,
    0.03,
    0.03,
    0.03];

    const dateLabel = [
        "2016 1Q",
        "2016 2Q",
        "2016 3Q",
        "2016 4Q",
        "2017 1Q",
        "2017 2Q",
        "2017 3Q",
        "2017 4Q",
        "2018 1Q",
        "2018 2Q",
        "2018 3Q",
        "2018 4Q",
        "2019 1Q",
        "2019 2Q",
        "2019 3Q",
        "2019 4Q",
        "2020 1Q",
        "2020 2Q",
        "2020 4Q",
        "2021 Forecast",
        "2022 Forecast",
        "2023 Forecast",
        "2024 Forecast"];

        const chartData:Array<any> = [{
            "date": dateLabel[0],
            "value": vacancy[0]
          }, {
            "date": dateLabel[1],
            "value": vacancy[1]
          }, {
            "date": dateLabel[2],
            "value": vacancy[2]
          }, {
            "date": dateLabel[3],
            "value": vacancy[3]
          },
          {
            "date": dateLabel[4],
            "value": vacancy[4]
          }, {
            "date": dateLabel[5],
            "value": vacancy[5]
          }, {
            "date": dateLabel[6],
            "value": vacancy[6]
          }, {
            "date": dateLabel[7],
            "value": vacancy[7]
          },
          {
            "date": dateLabel[8],
            "value": vacancy[8]
          }, {
            "date": dateLabel[9],
            "value": vacancy[9]
          }, {
            "date": dateLabel[10],
            "value": vacancy[10]
          }, {
            "date": dateLabel[11],
            "value": vacancy[11]
          },{
            "date": dateLabel[12],
            "value": vacancy[12]
          }, {
            "date": dateLabel[13],
            "value": vacancy[13]
          }, {
            "date": dateLabel[14],
            "value": vacancy[14]
          }, {
            "date": dateLabel[15],
            "value": vacancy[15]
          },
          {
            "date": dateLabel[16],
            "value": vacancy[16]
          }, {
            "date": dateLabel[17],
            "value": vacancy[17]
          }, {
            "date": dateLabel[18],
            "value": vacancy[18]
          }, {
            "date": dateLabel[19],
            "value": vacancy[19]
          },
          {
            "date": dateLabel[20],
            "value": vacancy[20]
          }, {
            "date": dateLabel[21],
            "value": vacancy[21]
          }, {
            "date": dateLabel[22],
            "value": vacancy[22]
          }, {
            "date": dateLabel[23],
            "value": vacancy[23]
          }];
          

am4core.useTheme(am4themes_animated);
const VacancyChart = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);

    useLayoutEffect(() => {
        let chart = am4core.create("VacancyChartBucketDataDiv", am4charts.XYChart3D); 

        chart.numberFormatter.numberFormat = "#.##%";

        let data: Array<any> = [];

        for (let i = 0; i < dateLabel.length; i++) {
            data.push({date: dateLabel[i], value: vacancy[i]});
          }

        chart.data = chartData;

        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "date";
        categoryAxis.renderer.labels.template.rotation = 270;
        categoryAxis.renderer.labels.template.hideOversized = false;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";

        if(categoryAxis.tooltip) {
            categoryAxis.tooltip.label.rotation = 270;
            categoryAxis.tooltip.label.horizontalCenter = "right";
            categoryAxis.tooltip.label.verticalCenter = "middle";
        }
       
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Vacancy";
        valueAxis.title.fontWeight = "bold";
        
        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries3D());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "date";
        series.name = "Vacancy";
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;
        series.columns.template.width = am4core.percent(30);
        
        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.stroke = am4core.color("#FFFFFF");
      
        columnTemplate.adapter.add("fill", function(fill, target) {
          
            return target?.dataItem ? chart.colors.getIndex(target.dataItem.index) :undefined;
          })
          
          columnTemplate.adapter.add("stroke", function(stroke, target) {
            return target?.dataItem ? chart.colors.getIndex(target.dataItem.index) :undefined;
          })
        
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineX.strokeOpacity = 0;
        chart.cursor.lineY.strokeOpacity = 0;
   
        chartRef.current = chart;

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div id="VacancyChartBucketDataDiv" style={{ width: "100%", height: "500px" }}></div>
    );
}

export default VacancyChart;
