import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tabs from '../../components/shared/Tabs';
import BuildingInfo from './components/BuildingInfo';
import NavBar from '../../interfaces/property/NavTabs';
import MainLoader from '../../components/shared/MainLoader';

import { BUILDING_DIRECTORY_TABS } from '../../constants/buildingDirectoryTabs';
import BuildingDirectoryState from '../../context/state/BuildingDirectoryState';
import RouteParams from '../../interfaces/general/Route';

const idsAndNames = [
    { id: BUILDING_DIRECTORY_TABS.BUILDING_INFO.id, name: BUILDING_DIRECTORY_TABS.BUILDING_INFO.name },
];

const BuildingDirectoryTabs: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        location = useLocation(),
        { id } = useParams<RouteParams>(),
        { buildingDirectory, isLoading,
            fetchBuildingDirectory, setBuildingDirectory } = useContext(BuildingDirectoryState);

    const findActivTab = (val: number | string, nameRepresentation: boolean) => {
        const tab = idsAndNames.find((el) => el[`${nameRepresentation ? 'id' : 'name'}`] == val);
        return tab ? (nameRepresentation ? tab.name : `${tab.id}`) : nameRepresentation ? 'buildingDirectory' : '10';
    };

    let activTab = location.hash ? findActivTab(location.hash.replace('#', ''), false) : '10';

    const [tabs, setTabs] = useState<Array<NavBar>>([]);

    const setData = useCallback(() => {
        const defaultTabs = [
            {
                id: BUILDING_DIRECTORY_TABS.BUILDING_INFO.id,
                icon: '',
                name: t(`f:${BUILDING_DIRECTORY_TABS.BUILDING_INFO.name}`),
                component: <BuildingInfo />,
            },
            // { // TODO
            //     id: PROPERTY_TABS.PROJECT_TEAM.id,
            //     icon: '',
            //     name: t(`f:property.${PROPERTY_TABS.PROJECT_TEAM.name}`),
            //     component: <ProjectTeamTabs />,
            // },
        ];

        // TODO
        // let tabs = filterPropertyAssetFeature(defaultTabs, myFeature);
        const tabs = defaultTabs;
        setTabs(tabs);
    }, [t]);

    const tabSelected = (value: any) => {
        history.push({ hash: findActivTab(value, true) });
    };

    useEffect(() => {
        setData();
    }, [setData]);

    useEffect(() => {
        if (id) {
            fetchBuildingDirectory(id);
        }
        else {
            setBuildingDirectory(null);
        }
    }, [id]);

    if (!buildingDirectory) {
        return null;
    }

    if (isLoading) {
        return (
            <div className="container mb-100">
                <MainLoader type="regular" mb="mb-100" mt="mt-100" />
            </div>
        );
    }

    return (
        <div className="container__data">
            <div className="mt-40 property-tabs__styled">
                <Tabs tabs={tabs} onTabSelected={tabSelected} defaultActiveKey={activTab} />
            </div>
        </div>
    );
};
export default BuildingDirectoryTabs;
