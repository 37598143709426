import { FunctionComponent, useCallback, useEffect, useState } from "react";
import MainHeading from "../../../../../components/shared/MainHeading";
import { InvestorCompany, InvestorCompanyInfo } from "../../../../../interfaces/investor/CompanyWithInvestor";
import { InvestorCompanyNote } from "../../../../../interfaces/investor/InvestorCompanyNote";
import { InvestorUser } from "../../../../../interfaces/investor/InvestorUser";
import InvestorService from "../../../../../services/InvestorsService";
import ProjectTeamService from "../../../../../services/ProjectTeam";
import PropertyAssetCompanyService from "../../../../../services/PropertyAssetCompanyService";
import CompanyNoteEditor from "../partials/CompanyNoteEditor";
import CompanyInfo from "./CompanyInfo";
import CompanyNoteList from "./CompanyNoteList";

interface CompanyOverviewProps {
    propertyAssetId: string | number;
    company: InvestorCompanyInfo;
    users: Array<InvestorUser>;
}

const CompanyOverview: FunctionComponent<CompanyOverviewProps> = ({
    propertyAssetId,
    users = [],
    company
}) => {
    const [projectTeam, setProjectTeam] = useState<Array<any>>([]);
    const [notes, setNotes] = useState<Array<InvestorCompanyNote>>([]);

    const onPost = useCallback(async (note: string) => {
        if (note) {
            await PropertyAssetCompanyService.postCompanyNote(propertyAssetId, company.id, note);
            fetchAllNotes();
        }
    }, []);

    const fetchAllNotes = useCallback(async () => {
        const data = await PropertyAssetCompanyService.fetchAllCompanyNotes(propertyAssetId, company.id);
        setNotes(data);
    }, []);

    const fetchProjectTeam = useCallback(async () => {
        const projectTeam = await ProjectTeamService.fetchProjectTeam(propertyAssetId.toString());
        const myTeam = await ProjectTeamService.fetchMyteam(propertyAssetId.toString());
        setProjectTeam([...projectTeam, ...myTeam]);
    }, []);

    useEffect(() => {
        fetchAllNotes();
        fetchProjectTeam();
    }, []);

    return (
        <>
            <CompanyInfo
                users={users}
                projectTeam={projectTeam}
                propertyAssetId={propertyAssetId}
                company={company}
            />
            <MainHeading text="Notes" />
            <div className="mt-30 mr-50 ml-50">
                <CompanyNoteEditor onPost={onPost} />
                <CompanyNoteList data={notes} />
            </div>
        </>
    )
}

export default CompanyOverview;