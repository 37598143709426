import { FunctionComponent } from 'react';
import DeallineWizardContext from '../../context/DeallineWizardContext';
import CheckAuthorization from '../StatusPages/CheckAuthorization';
import DeadLineSteps from './DeadLineSteps';

export const DealLineWizard: FunctionComponent<any> = () => {
    return (
        <CheckAuthorization>
            <DeallineWizardContext>
                <DeadLineSteps />
            </DeallineWizardContext>
        </CheckAuthorization>
    );
};
export default DealLineWizard;
