import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProjectTeam from '../../../../services/ProjectTeam';
import { toast } from 'react-toastify';
import UserDataTable from './components/UserDataTable';
import MainLoader from '../../../../components/shared/MainLoader';
import AddUserModal from './components/AddUserModal';
import { Actions } from '../../../../interfaces/general/general';
import { initalValuesUser, ProjetTeamTypes } from '../../../../interfaces/ProjectTeam/ProjectTeamMeber';
import FeatureState from '../../../../context/state/FeatureState';
import { isMobile } from 'react-device-detect';

interface MyTeamProps {
    companySelected: string;
    setCompanySelected: (prev: string) => void;
    isMyTeamModalOpenActions: Actions;
    isMyTeamModalOpen: boolean;
}

const MyTeam = ({ setCompanySelected, companySelected, isMyTeamModalOpenActions, isMyTeamModalOpen }: MyTeamProps) => {
    const [myTeam, setMyTeam] = useState<any>(),
        { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [loading, setLoading] = useState<boolean>(false);

    const fetchMyTeam = useCallback(async () => {
        try {
            const myTeam = await ProjectTeam.fetchMyteam(`${propertyAssetId}`);
            const mapped = myTeam.map((elem) => {
                return { ...elem.user, role: elem.roleInProject };
            });
            setMyTeam(mapped);
            setLoading(true);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    useEffect(() => {
        fetchMyTeam();
    }, [fetchMyTeam]);

    return (
        <div className={isMobile ? 'my-team mx-20': 'my-team mx-50'}>
            {loading ? (
                <UserDataTable
                    companySelected={companySelected}
                    companies={[]}
                    setCompanySelected={setCompanySelected}
                    usersArray={myTeam}
                    refetch={fetchMyTeam}
                    type={ProjetTeamTypes.USER}
                />
            ) : (
                <MainLoader type="regular" />
            )}

            <AddUserModal
                isModalOpened={isMyTeamModalOpen}
                setIsModalOpened={isMyTeamModalOpenActions}
                type={ProjetTeamTypes.USER}
                refetch={fetchMyTeam}
                isEditMode={false}
                editUser={initalValuesUser}
            />
        </div>
    );
};

export default MyTeam;
