import * as Yup from 'yup';

export const EditPropertyShema = Yup.object().shape({
    propertyName: Yup.string().min(1, 'Too Short!').max(255, 'Too Long!').required('f:validation.required'),
    numberOfBuildings: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer'),
    numberOfFloors: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer'),
    numberOfUnitsRooms: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer'),
    currencyId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer')
        .required('f:validation.required'),
    saleTypeId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer')
        .required('f:validation.required'),
    mainSectorId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer')
        .required('f:validation.required'),
    yearOfCompletion: Yup.number().typeError('f:validation.number'),
    yearRenovated: Yup.number().typeError('f:validation.number'),
    landArea: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    passingRent: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    netOperatingIncome: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    capitalisationRate: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    netLeasableArea: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    grossLeasableArea: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer'),
    carparkLots: Yup.number().typeError('f:validation.number'),
    map: Yup.object().shape({
        address: Yup.string().required('f:validation.required'),
        long: Yup.number().typeError('f:validation.number'),
        lat: Yup.number().typeError('f:validation.number'),
    }),
    owners: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required('Name is required'),
                share: Yup.number()
                    .required('Please enter size')
                    .min(1, 'Must be greater or equal to 1')
                    .max(100, 'Must be lower or equal to 100'),
            })
        )
        .min(1, 'Must be greater or equal to 1'),
});
