import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabsContentNavigation from '../../../components/shared/TabsContentNavigation';
import FeatureState from '../../../context/state/FeatureState';
import Investors from './Permissions/Investors';
import ManageButtons from './Permissions/partials/ManageButtons';
import useBoolean from '../../../hooks/use-boolean';
import api from '../../../api/index';
import { toast } from 'react-toastify';
import InvestorService from '../../../services/InvestorsService';
import { InvestorUser } from '../../../interfaces/investor/InvestorUser';
import { CompanyWithInvestorPagination } from '../../../interfaces/investor/CompanyWithInvestor';
import ModalImportLoading from '../../../components/shared/ModalImportLoading';
import AnalysisTab from './Permissions/Analysis';

enum NavEnum {
    LIST = 'LIST',
    ANALYSIS = 'ANALYSIS',
}

const InvestorTab: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        [activComponent, setActivComponent] = useState<string>(NavEnum.LIST),
        [isAddInvestrModalOpened, setIsAddInvestorModalOpenedActions] = useBoolean(),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [investorUsers, setInvestorUsers] = useState<Array<InvestorUser>>([]),
        [totalInvestorUsers, setTotalInvestorUsers] = useState<number>(0),
        [isUsersLoaded, setIsUsersLoadedActions] = useBoolean(),
        [companyPagination, setCompanyPagination] = useState<CompanyWithInvestorPagination>({
            items: [],
            totalCount: 0,
        }),
        [isCompaniesLoaded, setIsCompaniesLoadedActions] = useBoolean(),
        [isUploading, setIsUploadingActions] = useBoolean(),
        [investorCompaniesPage, setInvestorCompaniesPage] = useState<number>(0),
        [investorUsersPage, setInvestorUsersPage] = useState<number>(0),
        { getFeature } = useContext(FeatureState);
    const handleChangeActivComponent = (value: string) => setActivComponent(value);

    let radioBtnsDate = [
        {
            handler: handleChangeActivComponent,
            text: t(`f:cashFlow.list`),
            value: NavEnum.LIST,
        }
    ];

    if (getFeature('PermissionAnalysis')) {
        radioBtnsDate.push(        {
            handler: handleChangeActivComponent,
            text: t(`f:cashFlow.analysis`),
            value: NavEnum.ANALYSIS,
        });
    }

    const displayContent = () => {
        switch (activComponent) {
            case NavEnum.ANALYSIS:
                return (
                    <div className="col-xs-12">
                        <AnalysisTab />
                    </div>
                );

            default:
                return (
                    <div className="teams-table__wrap">
                        <Investors
                            isAddInvestrModalOpened={isAddInvestrModalOpened}
                            setIsAddInvestorModalOpenedActions={setIsAddInvestorModalOpenedActions}
                            isUsersLoaded={isUsersLoaded}
                            investorUsers={investorUsers}
                            totalInvestorUsers={totalInvestorUsers}
                            companyPagination={companyPagination}
                            isCompaniesLoaded={isCompaniesLoaded}
                            investorsPage={investorCompaniesPage}
                            setInvestorCompaniesPage={setInvestorCompaniesPage}
                            investorUsersPage={investorUsersPage}
                            setInvestorUsersPage={setInvestorUsersPage}
                            refetch={refetch}
                        />
                    </div>
                );
        }
    };

    const customRequest = (options: any) => {
        setIsUploadingActions.setTrue();
        const data = new FormData();
        data.append('File', options.file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: '*/*',
            },
        };
        api.post(options.action, data, config)
            .then((res: any) => {
                options.onSuccess(res.data, options.file);
                refetch();
                setIsUploadingActions.setFalse();
            })
            .catch((error: any) => {
                toast(t(`b:${error.response.data.errorMessage}`));
            });
    };

    const fetchInvestorUsers = useCallback(async () => {
        try {
            const data = await InvestorService.getInvestorUsers(`${propertyAssetId}`, investorUsersPage);
            setInvestorUsers(data.items);
            setTotalInvestorUsers(data.totalCount);
            setIsUsersLoadedActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, investorUsersPage]);

    const fetchCompaniesWithInvestor = useCallback(async () => {
        try {
            const data = await InvestorService.getAllCompaniesWithInvestor(`${propertyAssetId}`, investorCompaniesPage, 50);

            setCompanyPagination(data);
            setIsCompaniesLoadedActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, investorCompaniesPage]);

    const refetch = useCallback(() => {
        fetchInvestorUsers();
        fetchCompaniesWithInvestor();
    }, [fetchInvestorUsers, fetchCompaniesWithInvestor]);

    useEffect(() => {
        fetchInvestorUsers();
    }, [fetchInvestorUsers]);

    useEffect(() => {
        fetchCompaniesWithInvestor();
    }, [fetchCompaniesWithInvestor]);

    return (
        <div className="investor-page">
            <TabsContentNavigation
                radioBtns={radioBtnsDate}
                activComponent={activComponent}
                element={
                    getFeature('ProjectTeamEdit') && (
                        <ManageButtons
                            setIsModalOpened={setIsAddInvestorModalOpenedActions}
                            customRequest={customRequest}
                        />
                    )
                }
            />
            {displayContent()}
            <ModalImportLoading text={t(`f:loaders.uploading`)} isModalOpened={isUploading} />
        </div>
    );
};
export default InvestorTab;
