import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import SearchService from '../../services/Search';
import SearchPropertyResult from './SearchResult';
import { useTranslation } from 'react-i18next';
import PropertyAssetMap from '../../components/shared/PropertyAssetMap';
import { Pagination } from 'antd';
import MultiSelect from '../../components/shared/MultiSelect';
import { Select } from 'antd';
import _ from 'lodash';
import LookUpState from '../../context/state/LookUpState';
import CountryModel from '../../api/models/CountryModel';

// const testLocations = [

// {"type":"Feature","geometry":{"type":"Point","coordinates":[-84.28295,30.46454]},"properties":{"Name":"1 Fresh Stirfry","Address":"1820 Monroe St","City":"Tallahassee","State":"Florida","ZipCode":"32303","Country":"USA","EntityType":"Restaurant"}},
// {"type":"Feature","geometry":{"type":"Point","coordinates":[-118.68013,34.27172]},"properties":{"Name":"118 Cafe","Address":"5726 Los Angeles Ave","City":"Simi Valley","State":"California","ZipCode":"93063","Country":"USA","EntityType":"Restaurant"}},
// {"type":"Feature","geometry":{"type":"Point","coordinates":[-77.04702,38.91087]},"properties":{"Name":"2100 Prime","Address":"2100 Massachusetts Ave","City":"Washington","State":"District of Columbia","ZipCode":"20008","Country":"USA","EntityType":"Restaurant"}},
// {"type":"Feature","geometry":{"type":"Point","coordinates":[-73.90918,40.87734]},"properties":{"Name":"228th Meat Market","Address":"151 228th St","City":"New York","State":"New York","ZipCode":"10463","Country":"USA","EntityType":"Grocery Store"}},
// {"type":"Feature","geometry":{"type":"Point","coordinates":[-74.0235,40.63473]},"properties":{"Name":"24 Hour Store","Address":"6824 4th Ave","City":"New York","State":"New York","ZipCode":"11220","Country":"USA","EntityType":"Grocery Store"}},
// {"type":"Feature","geometry":{"type":"Point","coordinates":[-73.91947,40.72901]},"properties":{"Name":"48th Street Deli","Address":"55-02 48th St","City":"New York","State":"New York","ZipCode":"11378","Country":"USA","EntityType":"Grocery Store"}},
// {"type":"Feature","geometry":{"type":"Point","coordinates":[-74.00916,40.72568]},"properties":{"Name":"508","Address":"508 Greenwich St","City":"New York","State":"New York","ZipCode":"10013","Country":"USA","EntityType":"Restaurant"}},
// {"type":"Feature","geometry":{"type":"Point","coordinates":[-74.05991,40.75018]},"properties":{"Name":"555 Asian Supermarket","Address":"555 Tonnele Ave","City":"Jersey City","State":"New Jersey","ZipCode":"07307","Country":"USA","EntityType":"Grocery Store"}},
// {"type":"Feature","geometry":{"type":"Point","coordinates":[-105.2506,40.01458]},"properties":{"Name":"A Cup Peace","Address":"3216 Arapahoe Ave","City":"Boulder","State":"Colorado","ZipCode":"80303","Country":"USA","EntityType":"Restaurant"}},
// {"type":"Feature","geometry":{"type":"Point","coordinates":[-117.04481,33.1407]},"properties":{"Name":"A Place of Our Own CDC","Address":"2355 Valley Pky","City":"Escondido","State":"California","ZipCode":"92027","Country":"USA","EntityType":"School"}}
// ];

const SearchProperty: FunctionComponent<any> = () => {
    const { t } = useTranslation(['f', 'b']);
    const [searchResults, setSearchResults] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [countryIdType, setCountryIdType] = useState<Array<number>>([]);
    const [propertyTypeId, setPropertyTypeIds] = useState<Array<number>>([]);
    const [countryOptions, setCountryOptions] = useState<Array<any>>([]);
    const [pageSize, setPageSize] = useState<number>(12),
        [optionsSectors, setOptionsSectors] = useState<Array<any>>([]),
        { getContent, countries } = useContext(LookUpState),
        { Option } = Select;
    const [searchText, setSearchText] = useState<string>('');
    const [searchLocations, setSearchLocations] = useState<any>([]);

    const sendSearch = async (searchInput: string, propertyTypeId: Array<any>, countryIdType: Array<any>) => {
        let searchResult = await SearchService.fetchSearchProperty(
            searchInput,
            0,
            pageSize,
            propertyTypeId,
            countryIdType
        );
        if (searchResult) {
            setSearchResults(searchResult);
            prepareSearchLocations(searchResult.items);
        }
        setCurrentPage(1);
    };

    // const prepareTestSearchLocations = () => {
    //     setSearchLocations(() => []);
    //     setSearchLocations(
    //         _.map(testLocations, (result) => {
    //             return {
    //                 propertyName: result.properties.Name,
    //                 address: result.properties.Address,
    //                 long: result.geometry.coordinates[0],
    //                 lat: result.geometry.coordinates[1]
    //             };
    //         })
    //     );
    // };

    // useEffect(() => {
    //    prepareTestSearchLocations();
    // }, []);

    const prepareSearchLocations = (searchResults: any) => {
        setSearchLocations(() => []);
        setSearchLocations(
            _.map(searchResults, (result) => {
                return {
                    propertyName: result.propertyName,
                    address: result.map.address,
                    long: result.map.long,
                    lat: result.map.lat,
                };
            })
        );
    };

    const handlePageChange = (page: number, pageSize: any) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        fetchNewPage(page, pageSize);
    };

    const fetchNewPage = async (page: number, pageSize: number) => {
        let searchResult = await SearchService.fetchSearchProperty(
            searchText,
            page - 1,
            pageSize,
            propertyTypeId,
            countryIdType
        );
        if (searchResult) {
            setSearchResults(searchResult);
            prepareSearchLocations(searchResult.items);
        }
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        sendSearch(searchText, propertyTypeId, countryIdType);
        setOptionsSectors(getContent('Sector'));
        setCountryOptions(countries);
    }, []);

    return (
        <div className="container-search">
            <div>
                <div className="container-search__relative-cont">
                    <Formik
                        initialValues={{ searchInput: '', countryIds: [], propertyTypeIds: [] }}
                        onSubmit={(values) => {
                            setSearchText(values.searchInput);
                            setCountryIdType(values.countryIds);
                            setPropertyTypeIds(values.propertyTypeIds);
                            sendSearch(values.searchInput, values.propertyTypeIds, values.countryIds);
                        }}
                    >
                        {(props) => (
                            <Form className="container-search__form d-flex justify-content-center">
                                <div className="container-search__inputs-container d-flex">
                                    <div className="container-search__group container-search__group--first container-search__group--border-right d-flex flex-direction-column">
                                        <label>{t('f:search.searchAndFind')}</label>
                                        <input
                                            type="text"
                                            className="container-search__input"
                                            placeholder={t('f:search.titleKeyAddress')}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            name="searchInput"
                                        />
                                    </div>
                                    <div className="container-search__group container-search__group--border-right d-flex flex-direction-column">
                                        {countryOptions.length && (
                                            <MultiSelect
                                                label={t('f:search.location')}
                                                placeholder={t('f:search.cityStateCountry')}
                                                options={countryOptions}
                                                onSelected={(value: any) =>
                                                    props.setFieldValue('countryIds', [
                                                        ...props.values.countryIds,
                                                        value,
                                                    ])
                                                }
                                                onDeleted={(value: any) => {
                                                    let removeElem = props.values.countryIds.filter(
                                                        (elem) => elem !== value
                                                    );
                                                    props.setFieldValue('countryIds', removeElem);
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div
                                        className="container-search__group"
                                        style={{ paddingTop: '5px', paddingLeft: '20px', paddingRight: '10px' }}
                                    >
                                        {optionsSectors.length && (
                                            <MultiSelect
                                                label={t('f:search.type')}
                                                placeholder={t('f:search.select')}
                                                options={optionsSectors}
                                                onSelected={(value: any) =>
                                                    props.setFieldValue('propertyTypeIds', [
                                                        ...props.values.propertyTypeIds,
                                                        value,
                                                    ])
                                                }
                                                onDeleted={(value: any) => {
                                                    let removeElem = props.values.propertyTypeIds.filter(
                                                        (elem) => elem !== value
                                                    );
                                                    props.setFieldValue('propertyTypeIds', removeElem);
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <button
                                    className="container-search__btn button-main button-primary"
                                    type="submit"
                                    style={{ height: 'auto', width: '110px' }}
                                >
                                    <FontAwesomeIcon icon={['fas', 'search']} size="lg" />
                                </button>
                            </Form>
                        )}
                    </Formik>
                    <PropertyAssetMap
                        locations={[]}
                        searchLocations={searchLocations}
                        shouldCluster={false}
                        showPopup={true}
                        showOnlyAddress={true}
                        allowInteraction={true}
                    ></PropertyAssetMap>
                </div>

                <SearchPropertyResult className="mx-50" searchResults={searchResults.items} />
                <div className="pagination-search">
                    <Pagination
                        defaultCurrent={1}
                        total={searchResults.totalCount}
                        onChange={(page, pageSize) => {
                            handlePageChange(page, pageSize);
                        }}
                        current={currentPage}
                        pageSize={pageSize}
                        pageSizeOptions={['12']}
                        className="mt-30"
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchProperty;
