import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useBoolean from '../../../../hooks/use-boolean';
import InvestorsTable from '../../../ViewProperty/components/Permissions/components/InvestorsTable';
import { CompanyWithInvestorPagination } from '../../../../interfaces/investor/CompanyWithInvestor';
import InvestorService from '../../../../services/InvestorsService';
import { TableTypes } from '../../../../constants/wizardSteps';
import FeatureState from '../../../../context/state/FeatureState';
const pageSize = 50;

const InvestorsView: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [companyPagination, setCompanyPagination] = useState<CompanyWithInvestorPagination>({
            items: [],
            totalCount: 0,
        }),
        [investorsPage, setInvestorsPage] = useState<number>(0),
        [isCompaniesLoaded, setIsCompaniesLoadedActions] = useBoolean();

    const fetchCompaniesWithInvestor = useCallback(async () => {
        try {
            const data = await InvestorService.getAllCompaniesWithInvestor(
                `${propertyAssetId}`,
                investorsPage,
                pageSize
            );

            setCompanyPagination(data);
            setIsCompaniesLoadedActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, setIsCompaniesLoadedActions, t, investorsPage]);

    useEffect(() => {
        fetchCompaniesWithInvestor();
    }, [fetchCompaniesWithInvestor]);
    return (
        <div className="col-xs-12 px-50 mt-30">
            <InvestorsTable
                companyPagination={companyPagination}
                isCompaniesLoaded={isCompaniesLoaded}
                setInvestorsPage={setInvestorsPage}
                investorsPage={investorsPage}
                type={TableTypes.LIST_OPPORTUNITY}
                pageSize={50}
                refetch={fetchCompaniesWithInvestor}
            />
        </div>
    );
};
export default InvestorsView;
