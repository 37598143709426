import PropertyLookupApi from '../api/routes/PropertyLookupApi';

const getLookup = async (lookup: string): Promise<any> => {
    try {
        const response = await PropertyLookupApi.getLookup(lookup);

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const getAllLookupTypes = async (): Promise<any> => {
    try {
        const response = await PropertyLookupApi.getAllLookups();

        return response.data;
    } catch (err) {
        console.log(err);
    }
};
const PropertyLookupService = {
    getLookup,
    getAllLookupTypes,
};
export default PropertyLookupService;
