import PagePermission from '../../api/routes/Admin/PagePermissionApi';

const fetchCompanyCountries = async (companyId: number) => {
    try {
        const { data } = await PagePermission.fetchCompanyCountries(companyId);
        return data;
    } catch (err) {
        Promise.reject();
    }
}

const fetchCompanyPermissions = async (companyId: number) => {
    try {
        const { data } = await PagePermission.fetchCompanyPermission(companyId);
        return data;
    } catch (err) {
        Promise.reject();
    }
}

const fetchUserPermissions = async (userId: string) => {
    try {
        const { data } = await PagePermission.fetchUserPermission(userId);
        return data;
    } catch (err) {
        Promise.reject();
    }
}

const updateUserPermissions = async (userId: string, pageTypeId: number, allow: boolean, countryIds: Array<any>) => {
    try {
        await PagePermission.updateUserPermission({ UserId: userId, PageTypeId: pageTypeId, Allow: allow, CountryIds: countryIds });
    } catch (err) {
        Promise.reject();
    }
}

const updateCompanyPermissions = async (companyId: number, pageTypeId: number, allow: boolean, countryIds: Array<any>) => {
    try {
        await PagePermission.updateCompanyPermission({ CompanyId: companyId, PageTypeId: pageTypeId, Allow: allow, CountryIds: countryIds });
    } catch (err) {
        Promise.reject();
    }
}

export default {
    fetchCompanyCountries,
    fetchCompanyPermissions,
    fetchUserPermissions,
    updateUserPermissions,
    updateCompanyPermissions
}