import { FunctionComponent } from 'react';
import { Upload } from 'antd';
import documentApiUrl from '../../../../api/documentApiUrl';
import { useTranslation } from 'react-i18next';
import ModalImportLoading from '../../../../components/shared/ModalImportLoading';

const { Dragger } = Upload;

interface DraggerDropFileProps {
    handleChange: (value) => void;
    fileList: Array<any>;
    customRequest: (options: any) => void;
    uploadedFiles: Array<any>;
    dragEffect: boolean;
    isUploading: boolean;
    failedUploads: Array<any>;
    imageUrl: string;
    folderNodes: Array<any>;
}
const DraggerDropFile: FunctionComponent<DraggerDropFileProps> = ({
    handleChange,
    fileList,
    customRequest,
    failedUploads,
    dragEffect,
    isUploading,
    imageUrl,
    uploadedFiles,
    folderNodes,
}: DraggerDropFileProps) => {
    const { t } = useTranslation(['f', 'b']);

    return (
        <div className="row-flex-clear justify-content-center align-items-center data-room-upload col-xs-12">
            <Dragger
                name="data"
                id="file-uploader"
                className={`data-room__main-component${
                    dragEffect ? ' data-room__main-component--visible' : ' data-room__main-component--hidden'
                }`}
                accept=".pdf,.cad,.txt,.doc,.docx,.xls,.xlsx,.jpeg,.png,.gif application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                showUploadList={false}
                onChange={handleChange}
                fileList={fileList}
                action={documentApiUrl + `/Document/`}
                customRequest={(option) => {
                    customRequest(option);
                }}
                multiple
            >
                {!isUploading ? (
                    <div>
                        {folderNodes.length ? (
                            <div className="d-flex justify-content-center">
                                <img src={imageUrl} alt="drag-drop" className="data-room__no-nodes" />
                            </div>
                        ) : null}

                        <p className="ant-upload-text">{t(`f:dataRoom.pleaseDragFilesHere`)}</p>
                    </div>
                ) : (
                    <ModalImportLoading text={t(`f:loaders.uploading`)} isModalOpened={isUploading} />
                )}
            </Dragger>
        </div>
    );
};
export default DraggerDropFile;
