export interface OverviewSummary {
    portfolios: number;
    divestments: number;
    acquisitions: number;
}

export const defaultOverviewSummary: OverviewSummary = {
    portfolios: 0,
    divestments: 0,
    acquisitions: 0
}