import { FunctionComponent } from 'react';

interface MainHeadingProps {
    text: string;
    handler?: () => void;
    addClasses?: string;
}

const MainHeading: FunctionComponent<MainHeadingProps> = ({ text, handler, addClasses }: MainHeadingProps) => (
    <h1 className={`wizard-heading ${addClasses}`} onClick={handler}>
        {text}
    </h1>
);

export default MainHeading;
