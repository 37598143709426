import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import FeatureState from './state/FeatureState';
import { toast } from 'react-toastify';
import PropertyAssetFeature from '../services/PropertyAssetFeature';
import RouteParams from '../interfaces/general/Route';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import MainLoader from '../components/shared/MainLoader';
import { PropertyAssetSummary } from '../interfaces/property/PropertyAsserts';
import { deafultPropertyHero } from '../constants/propertyHero';
import useBoolean from '../hooks/use-boolean';
import PermissionsService from '../services/Permissions';
import { PublicUserId } from '../constants/users';
import TagManager from 'react-gtm-module';
import * as GAEvents from '../constants/googleAnalyticEvents';
import { PropertyAssetFeatures } from '../constants/propertyAssetFeatures';

interface Props {
    children: React.ReactNode;
}

const FeatureContext: FunctionComponent<Props> = ({ children }) => {
    const [allFeatures, setAllFeatures] = useState<Object>({}),
        { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        [propertySummary, setPropertySummary] = useState<PropertyAssetSummary>(deafultPropertyHero),
        [myFeature, setMyFeature] = useState<Array<number>>([]),
        { id: propertyAssetId } = useParams<RouteParams>(),
        [isLoading, IsLoadingActions] = useBoolean();

    const getFeature = (name: string) => {
        return allFeatures[name];
    };

    const checkDealRoomFeature = (feature: PropertyAssetFeatures): boolean => {
        return !propertySummary.dealRoomFeatures || propertySummary.dealRoomFeatures.includes(feature);
    }

    const fetchAllFeatures = useCallback(
        async (propertyAssetId: string) => {
            try {
                const _allFeatures = await PropertyAssetFeature.fetchAllFeatures();
                const myFeatures = await PropertyAssetFeature.fetchAssetFeature(propertyAssetId);
                setMyFeature(myFeatures);

                let obj = allFeatures;

                for (const key in _allFeatures) {
                    obj[_allFeatures[key]] = false;
                }

                myFeatures.forEach((elem) => {
                    obj[_allFeatures[elem]] = true;
                });

                setAllFeatures(obj);
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [t, allFeatures]
    );

    const fetchPropertySummary = useCallback(async () => {
        try {
            const summary = await PropertyAssetFeature.fetchAssetHeroGUI(propertyAssetId);
            await fetchAllFeatures(summary.id);

            setPropertySummary(summary);
        } catch (error: any) {
            history.push('/unauthorized');
        }
    }, [history, propertyAssetId]);

    const fetch = useCallback(async () => {
        await fetchPropertySummary();
        IsLoadingActions.setTrue();
    }, [fetchPropertySummary]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    useEffect(() => {
        if (process.env.REACT_APP_GTM_KEY) {
            const loggedUserId = PermissionsService.getUserId();
            const trackingUserId = loggedUserId ? loggedUserId : PublicUserId;

            const dataLayerArgs = {
                dataLayer: {
                    userId: trackingUserId,
                    propertyId: propertyAssetId,
                    event: GAEvents.ViewProperty,
                },
            };

            TagManager.dataLayer(dataLayerArgs);
        }
    }, []);

    return (
        <>
            {isLoading ? (
                <FeatureState.Provider
                    value={{
                        getFeature,
                        myFeature,
                        propertySummary,
                        fetchPropertySummary,
                        checkDealRoomFeature
                    }}
                >
                    {children}
                </FeatureState.Provider>
            ) : (
                <MainLoader type="main" />
            )}
        </>
    );
};

export default FeatureContext;
