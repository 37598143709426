import api from '../index';

const createTemplate = (url: string, data: any) => {
    return api.post(url, data);
};

const fetchTemplate = (url: string, propertyAssetId: string, id: string) => {
    return api.get(`/${url}/${propertyAssetId}/${id}`);
};

const fetchPropertyTabTemplates = (url: string, id: string) => {
    return api.get(`/${url}/${id}`);
};

const updateTemplate = (url: string, data: any) => {
    return api.put(`/${url}`, data);
};

const fetchPageInfo = (url: string, propertyAssetId: string) => {
    return api.get(`/${url}/${propertyAssetId}/pageinfo`);
};

const removeTemplate = (url: string, propertyAssetId: string, id: string) => {
    return api.delete(`/${url}/${propertyAssetId}/${id}`);
};

const PropertyAssetPageTemplateApi = {
    removeTemplate,
    createTemplate,
    fetchTemplate,
    fetchPropertyTabTemplates,
    updateTemplate,
    fetchPageInfo,
};
export default PropertyAssetPageTemplateApi;
