import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Markdown from 'markdown-to-jsx';
import TreeDotsComponent from './TreeDotsComponent';
import useBoolean from '../../../../../hooks/use-boolean';
import ModalConfirmDelete from '../../../../../components/shared/ModalConfirmDelete';
import FeatureState from '../../../../../context/state/FeatureState';

const MultipleTextImageResearchTemplate = (props: any) => {
    const { t } = useTranslation(['f', 'b']);
    const history = useHistory(),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        [isThreeDotClicked, setIsThreeDotClicked] = useState<boolean>(false),
        [isModalOpened, setIsModalOpenedActions] = useBoolean(false);
    console.log(props.template.content);

    const data = JSON.parse(props.template.content);
    const title = data?.title;
    const content = data?.content;

    const templateEditHandler = () => {
        history.push(
            `/property/${externalId}/template/${props.pageType}/add-content/${props.template.pageSectionType}/${props.template.id}`
        );
    };

    const deleteHandler = useCallback(() => {
        props.onRemove(props.template.id);
        setIsModalOpenedActions.setFalse();
    }, [setIsModalOpenedActions, props]);

    return (
        <div className="row my-50 research-multiple-text-image__wrap">
            {props.isEditMode && (
                <div className="col-xs-12">
                    <TreeDotsComponent
                        isThreeDotClicked={isThreeDotClicked}
                        setIsThreeDotClicked={setIsThreeDotClicked}
                        setIsModalOpened={setIsModalOpenedActions}
                        templateEditHandler={templateEditHandler}
                    />
                    <ModalConfirmDelete
                        text={t('f:property.confirmSectionDelete')}
                        isModalOpened={isModalOpened}
                        setIsModalOpenedActions={setIsModalOpenedActions}
                        handleFinish={deleteHandler}
                    />
                </div>
            )}
            {title && title.length > 0 && <h1 className="heading mxl-40">{title}</h1>}
            <div className="row-flex justify-content-center research-multiple-text-image__list">
                {content &&
                    content.length > 0 &&
                    content.map((item: string, index: number) => {
                        return (
                            <div className="col-md-2-clear main-text research-multiple-text-image__single" key={index}>
                                <Markdown options={{ wrapper: 'div' }}>{item}</Markdown>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default MultipleTextImageResearchTemplate;
