import { FunctionComponent, useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import NavigateState from '../../../context/state/NavigateState';
import Bidding from '../../../services/Bidding';
import BidService from '../../../services/BidService';
import ProjectTeam from '../../../services/ProjectTeam';
import { Button } from 'antd';
import MainHeading from '../../../components/shared/MainHeading';
import MainLoader from '../../../components/shared/MainLoader';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';

interface RouteParams {
    id: string;
    biddingId: string;
}

const Approval: FunctionComponent<any> = () => {
    const { t } = useTranslation(['f']),
        { cancelEverything, returnToPreviousPage } = useContext(NavigateState),
        history = useHistory(),
        [isLoading, setIsLoading] = useState<boolean>(false),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        { biddingId } = useParams<RouteParams>(),
        [approval, setApprovals] = useState<Array<any>>([]);

    const onClickHandleFinish = async () => {
        try {
            await BidService.createPlaceBid(`${propertyAssetId}`, biddingId);
            history.push(`/property/${externalId}#${PROPERTY_TABS.TRANSACTION.name}`);
        } catch (error: any) {
            toast(error.response.data.errorMessage);
        }
    };

    const fetchApprovals = useCallback(async () => {
        const approvals = await Bidding.fetchApprovals(`${propertyAssetId}`, biddingId);
        const _roles = await ProjectTeam.fetchTeamRoles();

        const reformatArray: Array<any> = [];
        approvals.forEach((elem: any) => {
            reformatArray.push({
                user: {
                    id: elem.user.id,
                    username: elem.user.username,
                    imageUrl: elem.user.imageUrl,
                    name: elem.user.fullName,
                    address: elem.user.address,
                    position: `${_roles[elem.role]}`,
                    email: elem.user.email,
                    company: elem.user.company,
                    phone: elem.user.phoneNumber,
                    alternativePhone: elem.user.alternativePhoneNumber,
                },
                approved: elem.approved,
            });
        });
        setApprovals(reformatArray);
        setIsLoading(true);
    }, [biddingId, propertyAssetId]);

    useEffect(() => {
        fetchApprovals();
    }, [fetchApprovals]);

    return (
        <div className="approval">
            <MainHeading text={t('f:listOpportunity.approvalRequests')} />

            <div className="approval__main">
                {isLoading ? (
                    <div className="marketing__companes d-flex">
                        {approval.map((elem, i) => {
                            return <div className="marketing__user">TABLE WILL COME HERE</div>;
                        })}
                    </div>
                ) : (
                    <MainLoader type="regular" />
                )}
            </div>
            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                <p
                    className="previous-page"
                    onClick={() => {
                        returnToPreviousPage();
                    }}
                >
                    {t('f:button.previousStep')}
                </p>
                <Button
                    onClick={() => {
                        cancelEverything(externalId, PROPERTY_TABS.TRANSACTION.name);
                    }}
                    className="button-main button-secondary d-flex justify-content-center align-items-center"
                >
                    {t('f:button.cancel')}
                </Button>
                <Button
                    className="button-main button-primary mr-25"
                    onClick={() => {
                        onClickHandleFinish();
                    }}
                >
                    {t('f:button.finish')}
                </Button>
            </div>
        </div>
    );
};
export default Approval;
