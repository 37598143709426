export const wizardSteps = Object.freeze({ first: 1, second: 2, third: 3, fourth: 4, fifth: 5, sixth: 6 });

export enum LoWizardStepNames {
    PRIVACY = 'privacy',
    DEAL_TIMELINE_AND_INVESTORS = 'deal-timeline&investors',
    LEGAL_AKNOWLEDGEMENT = 'legal-aknowledgement',
    PAYMENT_ACKNOWLEDGEMENT = 'payment-acknowledgement',
    PUBLISH = 'publish',
    APPROVALS = 'approvals',
}
export enum ACWizardStepNames {
    COMPANY_DETAILS = 'company-details',
    PERSONAL_DETAILS = 'personal-details',
    SECURITY = 'security',
    PRIVACY_AND_CONCENT = 'privacy&concent',
}

export enum TableTypes {
    INVESTORS_TAB = 'investors',
    LIST_OPPORTUNITY = 'list-opportunity',
}

export enum BiddingStructureStepNames {
    SETTINGS = 'settings',
}

export enum DealLineStepNames {
    MARKETING_TIMELINE = 'marketing-timeline',
}

export enum BidWizardStepNames {
    COMPANY_DETAILS = 'company-details',
    JOINT_VENTURE_COMPANIES = 'joint-venture-companies',
    BID_INFORMATIONS = 'bid-informations',
    FINANCE_DETAILS = 'finance-details',
    OVERVIEW = 'overview',
    APPROVAL_REQUESTS = 'approval-requests',
}
