import Header from './components/Header';
import headerVideo from '../../assets/images/header-video.mp4';

export const Home = () => (
    <>
        <div className="home-page-header d-flex flex-direction-center justify-content-center align-items-center">
            <Header />
            <video src={headerVideo} autoPlay muted loop className="home-page-header__video"></video>
        </div>
    </>
);
