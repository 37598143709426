import { FunctionComponent } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Unauthorized } from './Unauthorized';

const CheckAuthorization: FunctionComponent = ({ children }) => (
    <>
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Unauthorized />
        </UnauthenticatedTemplate>
    </>
);

export default CheckAuthorization;
