import { FunctionComponent, useCallback, useContext } from 'react';
import { Actions } from '../../../../interfaces/general/general';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';
import Deadline from '../../../../components/shared/Deadline';
import FeatureState from '../../../../context/state/FeatureState';
import { Stages } from '../../../../interfaces/property/Stages';

interface ModalDealTimelineProps {
    isModalOpened: boolean;
    setIsModalOpened: Actions;
    stages?: Array<Stages>;
}

const ModalDealTimeline: FunctionComponent<ModalDealTimelineProps> = ({
    isModalOpened,

    setIsModalOpened,
    stages
}: ModalDealTimelineProps) => {
    const { getFeature } = useContext(FeatureState);

    const onCancelHanlder = useCallback(() => {
        setIsModalOpened.setFalse();
    }, [setIsModalOpened]);

    return (
        <ModalWrapper
            isCloseModalShown={true}
            isModalOpened={isModalOpened}
            closeModalHandler={onCancelHanlder}
            isFooterShown={false}
            className="property-info__deal-line-modal"
        >
            <div className="d-flex flex-direction-column justify-content-center">
                <Deadline
                    showChartScale={false}
                    showTreeDotsMenu={getFeature('EditBiddingStructure')}
                    stages={stages}
                />
            </div>
        </ModalWrapper>
    );
};

export default ModalDealTimeline;
