import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../../components/wrapper/ModalWrapper';

interface Props {
    isModalOpened: boolean;
    setIsModalOpened: (boolean: boolean) => void;
    submit: (biddingId: string) => void;
    biddingId: string;
}

const ConfirmSubmit: FunctionComponent<Props> = ({ isModalOpened, setIsModalOpened, submit, biddingId }) => {
    const { t } = useTranslation(['f', 'b']);

    const handleCancel = useCallback(() => {
        setIsModalOpened(false);
    }, [setIsModalOpened]);

    const handleSubmit = useCallback(() => {
        submit(biddingId);
        setIsModalOpened(false);
    }, [biddingId, setIsModalOpened, submit]);
    return (
        <ModalWrapper
            isModalOpened={isModalOpened}
            isCloseModalShown={isModalOpened}
            title={t('f:bid.confirmSubmit')}
            closeModalHandler={handleCancel}
            isFooterShown={true}
            secondaryButtonHandler={handleCancel}
            primaryButtonText={t('f:button.cancel')}
            primaryButtonHandler={handleSubmit}
            secondaryButtonText={t('f:button.cancel')}
        >
            <div className="col-md-12 d-flex justify-content-center mt-50">{t('f:bid.confirmSubmitDescription')}</div>
        </ModalWrapper>
    );
};
export default ConfirmSubmit;
