import { FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DEFAULTTHEME } from '../../constants/theme';
import ThemeState from '../../context/state/ThemeState';

interface MainLoaderProps {
    type: 'main' | 'regular';
    size?: number;
    mb?: 'mb-10' | 'mb-20' | 'mb-30' | 'mb-40' | 'mb-50' | 'mb-60' | 'mb-70' | 'mb-80' | 'mb-90' | 'mb-100' | 'mt-200';
    mt?: 'mt-10' | 'mt-20' | 'mt-30' | 'mt-40' | 'mt-50' | 'mt-60' | 'mt-70' | 'mt-80' | 'mt-90' | 'mt-100' | 'mt-200';
}

const MainLoader: FunctionComponent<MainLoaderProps> = ({ size, type, mb, mt }: MainLoaderProps) => {
    const videoRef = useRef<HTMLVideoElement | null>(null),
        [loaderUrl, setLoaderUrl] = useState<string>(DEFAULTTHEME.loader),
        [loaderType, setLoaderType] = useState('');
    const { currentTheme } = useContext(ThemeState);

    useEffect(() => {
        const parts = currentTheme.loader.split('.');
        setLoaderType(parts.length ? (parts[parts.length - 1] ? parts[parts.length - 1] : 'mp4') : 'mp4');
        setLoaderUrl(
            parts.length ? (currentTheme.loader ? currentTheme.loader : DEFAULTTHEME.loader) : DEFAULTTHEME.loader
        );
    }, [currentTheme]);

    const checkType = useCallback(() => {
        switch (type) {
            case 'regular':
                return 'd-flex align-items-center justify-content-center';
            default:
                return 'main-loader';
        }
    }, [type]);

    useEffect(() => {
        if (videoRef && videoRef.current) videoRef.current.playbackRate = 1.0;
    }, [videoRef]);

    return (
        <div className={`${checkType()} ${mt} ${mb}`}>
            {loaderType === 'mp4' ? (
                <video
                    ref={videoRef}
                    src={loaderUrl}
                    autoPlay
                    playsInline
                    loop
                    muted
                    style={{ height: size ? `${size}px` : '200px', width: size ? `${size}px` : '200px' }}
                />
            ) : (
                <img
                    src={loaderUrl}
                    style={{ height: size ? `${size}px` : '200px', width: size ? `${size}px` : '200px' }}
                    alt="Loader"
                />
            )}
        </div>
    );
};
export default MainLoader;
