import { FunctionComponent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface Props {
    url: string;
}
const TreeDotsForEDIT: FunctionComponent<Props> = ({ url }) => {
    const { t } = useTranslation(['f', 'b']),
        [isThreeDotClicked, setIsThreeDotClicked] = useState<boolean>(false),
        history = useHistory();

    const content = (
        <div
            className="d-flex flex-direction-column"
            onMouseLeave={() => {
                setIsThreeDotClicked(false);
            }}
        >
            <span
                style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                onClick={() => {
                    history.push(url);
                    setIsThreeDotClicked(false);
                }}
            >
                {t('f:button.edit')}
            </span>
        </div>
    );

    return (
        <div className="mr-20">
            <FontAwesomeIcon
                className="mxl-10"
                style={{ color: '#a1abbd', cursor: 'pointer' }}
                icon={faEllipsisH}
                size="lg"
                onClick={() => {
                    setIsThreeDotClicked(!isThreeDotClicked);
                }}
            ></FontAwesomeIcon>
            <div style={{ marginLeft: '20px' }}>
                <Popover content={content} placement="bottom" trigger="click" visible={isThreeDotClicked}></Popover>
            </div>
        </div>
    );
};

export default TreeDotsForEDIT;
