import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { Select } from 'antd';
import ProjectTeam from '../../../../../services/ProjectTeam';
import { AddTeamUserSchema } from '../../../../../validations/ProjectTeamAddUser';
import { toast } from 'react-toastify';
import useBoolean from '../../../../../hooks/use-boolean';
import MainLoader from '../../../../../components/shared/MainLoader';
import MainCheckbox from '../../../../../components/shared/MainCheckbox';
import { Actions } from '../../../../../interfaces/general/general';
import { UserModal, initalValuesUser, ProjetTeamTypes } from '../../../../../interfaces/ProjectTeam/ProjectTeamMeber';
import ModalWrapper from '../../../../../components/wrapper/ModalWrapper';
import FeatureState from '../../../../../context/state/FeatureState';

interface Props {
    isModalOpened: boolean;
    type: ProjetTeamTypes.TEAMMEMBER | ProjetTeamTypes.USER;
    setIsModalOpened: Actions;
    refetch: () => void;
    isEditMode: boolean;
    editUser: UserModal;
}
const AddUserModal: FunctionComponent<Props> = ({
    isModalOpened,
    type,
    setIsModalOpened,
    refetch,
    isEditMode,
    editUser,
}) => {
    const [roles, setRoles] = useState<Object>({}),
        { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [isRolesLoad, isRolesLoadActions] = useBoolean(),
        [disableMultyClick, setDisableMultyClick] = useBoolean(),
        { Option } = Select;

    const fetchTeamRoles = useCallback(async () => {
        try {
            const roles = await ProjectTeam.fetchTeamRoles();
            setRoles(roles);

            isRolesLoadActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data}`));
        }
    }, []);

    const displayRoles = () => {
        return Object.keys(roles).map((key) => {
            return key === '10' || key === '11' || key === '12' ? (
                <></>
            ) : (
                <Option key={key} value={key}>
                    {roles[key]}
                </Option>
            );
        });
    };

    const closeModalHandler = useCallback(() => {
        setIsModalOpened.setFalse();
    }, [setIsModalOpened]);

    const hasErrors = (errors: any, name: string, touched: any) => {
        if (errors && errors[name] && touched[name]) {
            return <span className="error-text-absolute">{t(`${errors[name]}`, { name: t(`f:teams.${name}`) })}</span>;
        }
    };

    const mapFieldsOfUser = (formFunctions) => {
        return Object.keys(initalValuesUser).map((key: string, index: number) => {
            return (
                <div className="col-xs-6 form-group" key={key}>
                    {singleField(key, index, formFunctions)}
                </div>
            );
        });
    };

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    const singleField = (name: string, index: number, { values, setFieldValue, errors, touched }) => {
        switch (name) {
            case 'roleType':
                return (
                    <>
                        <label htmlFor="roles">{t('f:teams.roleType')}</label>
                        <Select
                            placeholder="Select"
                            onSelect={(value: any) => setFieldValue('roleType', value)}
                            allowClear
                            value={values.roleType || 'Select Role'}
                            getPopupContainer={handleContainer}
                        >
                            {displayRoles()}
                        </Select>
                        {hasErrors(errors, name, touched)}
                    </>
                );
            case 'editor':
                return (
                    <>
                        <label htmlFor="text">{t(`f:teams.userModalDetails.${name}`)}</label>
                        <MainCheckbox
                            checked={values[name]}
                            onClick={() => {
                                setFieldValue(name, !values[name]);
                            }}
                        />
                        {hasErrors(errors, name, touched)}
                    </>
                );

            default:
                return (
                    <>
                        <label htmlFor="text">{t(`f:teams.userModalDetails.${name}`)}</label>
                        <Field
                            type="text"
                            id={`${name}-${index}`}
                            name={name}
                            disabled={name === 'email' ? isEditMode : false}
                            placeholder={t(`f:teams.userModalDetailsEnter.${name}`)}
                        />
                        {hasErrors(errors, name, touched)}
                    </>
                );
        }
    };

    const handleSubmitAddUserModal = useCallback(
        async (values: any, { setFieldValue, resetForm }) => {
            setDisableMultyClick.setFalse();
            const { editor, ...user } = values;
            user.roleType = editor
                ? user.roleType === '101'
                    ? Number(user.roleType)
                    : Number(user.roleType) + 10
                : Number(user.roleType);

            await ProjectTeam.inviteUser(`${propertyAssetId}`, user);
            refetch();
            closeModalHandler();
            resetForm();
        },
        [closeModalHandler, setDisableMultyClick, propertyAssetId, refetch]
    );

    useEffect(() => {
        fetchTeamRoles();
    }, [fetchTeamRoles]);

    return (
        <Formik
            initialValues={isEditMode ? editUser : initalValuesUser}
            validationSchema={AddTeamUserSchema}
            enableReinitialize
            onSubmit={handleSubmitAddUserModal}
        >
            {({ setFieldValue, resetForm, errors, touched, validateForm, values, submitForm }) => (
                <ModalWrapper
                    isModalOpened={isModalOpened}
                    title={t(`f:teams.invite${type}`)}
                    closeModalHandler={() => {
                        closeModalHandler();
                        resetForm();
                    }}
                    isCloseModalShown={true}
                    isFooterShown={false}
                >
                    <Form className="d-flex flex-direction-column align-items-center">
                        <div>
                            {isRolesLoad ? (
                                <>{mapFieldsOfUser({ values, setFieldValue, errors, touched })}</>
                            ) : (
                                <MainLoader type="regular" />
                            )}
                            <div className="col-md-12 m-auto mt-50">
                                <button
                                    className="button-main button-primary d-block m-auto"
                                    type="submit"
                                    disabled={disableMultyClick}
                                >
                                    {isEditMode ? t('f:button.edit') : t('f:button.invite')}
                                </button>
                            </div>
                        </div>
                    </Form>
                </ModalWrapper>
            )}
        </Formik>
    );
};

export default AddUserModal;
