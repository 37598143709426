import { FunctionComponent } from 'react';
import SearchPropertyCard from '../../components/shared/SearchPropertyCard';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const SearchPropertyResult: FunctionComponent<any> = (props) => {
    const { t } = useTranslation(['f', 'b']);
    const history = useHistory();

    const redirectToProperty = (property: any) => {
        history.push(`/property/${property.id}`);
    };

    return (
        <div className="d-flex">
            <div className="search-assets">
                {props?.searchResults?.map((property: any) => {
                    return (
                        <div
                            onClick={() => {
                                redirectToProperty(property);
                            }}
                        >
                            <SearchPropertyCard elements={property} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SearchPropertyResult;
