import { FunctionComponent, MouseEventHandler } from 'react';

interface MainButtonProps {
    layout: 'primary' | 'secondary';
    buttonHandler?: MouseEventHandler<HTMLButtonElement>;
    buttonText: string | JSX.Element;
    classAdditions?: string;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    size: 'main' | 'small';
}

const MainButton: FunctionComponent<MainButtonProps> = ({
    layout,
    classAdditions,
    buttonHandler,
    buttonText,
    type,
    disabled,
    size,
}: MainButtonProps) => (
    <button
        className={`button-${size} button-${layout} d-block ${classAdditions ?? ''}`}
        onClick={buttonHandler}
        type={type}
        disabled={disabled}
    >
        {buttonText}
    </button>
);

export default MainButton;
