import { FunctionComponent, useCallback, useContext } from 'react';
import MainCheckbox from '../../../../components/shared/MainCheckbox';
import LOWizardState from '../../../../context/state/LOWizardState';

interface NDATypeSingleProps {
    type: string;
    title: string;
    size: string;
}

const NDATypeSingle: FunctionComponent<NDATypeSingleProps> = ({ title, type, size }: NDATypeSingleProps) => {
    const { bidingStructure, setBidingStructure, setIsRequiredActions } = useContext(LOWizardState);

    const handleOnClick = useCallback(() => {
        if (type === '0') setIsRequiredActions.setFalse();
        setBidingStructure(bidingStructure ? { ...bidingStructure, ndaType: Number(type) } : null);
    }, [bidingStructure, type, setBidingStructure, setIsRequiredActions]);

    return (
        <label htmlFor="require" className="d-flex align-items-center mt-30 mxl-40 mb-20 checkbox" key={type}>
            <MainCheckbox checked={type === `${bidingStructure?.ndaType}`} onClick={handleOnClick} />
            <span className={`text-rubik-${size}`}>{title}</span>
        </label>
    );
};
export default NDATypeSingle;
