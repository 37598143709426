import { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import FeatureState from "../../../../context/state/FeatureState";
import { IActiveUsersInDays } from "../../../../interfaces/googleAnalytics/ActiveUsersInDays";
import { ICountryAccessProperty } from "../../../../interfaces/googleAnalytics/CountryAccessProperty";
import { IUserAccessProperty } from "../../../../interfaces/googleAnalytics/UserAccessProperty";
import { AnalysisStats } from "../../../../interfaces/investor/AnalysisStats";
import GoogleAnalyticsService from "../../../../services/GoogleAnalyticsService";
import InvestorService from "../../../../services/InvestorsService";
import MostViewTabsOfPropertyChart from "./components/MostViewTabsOfPropertyChart";
import PropertyActiveUsersInDaysChart from "./components/PropertyActiveUsersInDaysChart";
import Statistics from "./components/Statistics";
import TopCountriesChart from "./components/TopCountriesChart";
import TopUsersChart from './components/TopUsersChart';

const AnalysisTab: FunctionComponent = () => {
    const { propertySummary } = useContext(FeatureState),
        { externalId, id: propertyAssetId } = propertySummary,
        [topUsers, setTopUsers] = useState<Array<IUserAccessProperty>>([]),
        [topCountries, setTopCountries] = useState<Array<ICountryAccessProperty>>([]),
        [activeUsers, setActiveUsers] = useState<number>(0),
        [activeUsersInDaysData, setActiveUsersInDaysData] = useState<Array<IActiveUsersInDays>>([]),
        [analysisStats, setAnalysisStats] = useState<AnalysisStats>(),
        { t } = useTranslation(['f', 'b']);

    const fetchTopUsers = useCallback(async () => {
        try {
            const data = await GoogleAnalyticsService.fetchTopUsersOfProperty(externalId);
            setTopUsers(data);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    const fetchTopCountriesAccess = useCallback(async () => {
        try {
            const data = await GoogleAnalyticsService.fetchTopCountriesAccessProperty(externalId);
            setTopCountries(data);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    const fetchActiveUsers = useCallback(async () => {
        try {
            const data = await GoogleAnalyticsService.fetchActiveUsersOfProperty(externalId);
            setActiveUsers(data);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    const fetchActiveUsersInDays = useCallback(async () => {
        try {
            const data = await GoogleAnalyticsService.fetchActiveUsersInDays(externalId);
            setActiveUsersInDaysData(data);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    const fetchAnalysisSummaryStat = useCallback(async () => {
        const data = await InvestorService.getAnalysisSummaryStat(`${propertyAssetId}`);
        setAnalysisStats(data);
    }, [])

    useEffect(() => {
        fetchTopUsers();
        fetchTopCountriesAccess();
        fetchActiveUsers();
        fetchActiveUsersInDays();
        fetchAnalysisSummaryStat();
    }, [fetchTopUsers, fetchTopCountriesAccess, fetchActiveUsers, fetchActiveUsersInDays, fetchAnalysisSummaryStat]);

    return (
        <div className="mx-50">
            <Statistics activeUsers={activeUsers} analysisStats={analysisStats} />
            <TopUsersChart dataSource={topUsers} />
            <TopCountriesChart dataSource={topCountries} />
            <PropertyActiveUsersInDaysChart data={activeUsersInDaysData} />
            <MostViewTabsOfPropertyChart />
        </div>
    )
}

export default AnalysisTab;