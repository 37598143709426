import { FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';
import AssetData from './assetData';
import BucketData from './bucketData/bucketData';
import CapitalMarkets from './capitalMarkets';
import propertyData from './data/capitalMarkets';
import DumbellChart from './dumbellChart';
import LeasingComparablesTable from './leasingComparablesTable';
import Maps from './maps';
import ModelEstimates from './ModelEstimates';
import PropertyShowcase from './propertyShowcase';
import SubmarketData from './submarketData';
import TradeProbabilityScoreCard from './tradeProbabilityScoreCard';

const DashBoardWrapper: FunctionComponent = () => {
    const className = isMobile ? 'dashboard-body' : 'dashboard-body mx-50';

    return (
        <div className={className}>
            <PropertyShowcase details={propertyData.showcase} />
            <LeasingComparablesTable />
            <CapitalMarkets />
            <DumbellChart />
            <BucketData />
            <Maps />
            <AssetData />
            <SubmarketData />
            <ModelEstimates />
            <TradeProbabilityScoreCard />
        </div>
    );
}

export default DashBoardWrapper;
