import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import MainButton from '../../../../components/shared/MainButton';
import { toggleBtnStatus } from '../../../../constants/propertyHero';
import { PropertyToggleStatus } from '../../../../interfaces/property/PropertyAsserts';
import PropertyAssetFeatureService from '../../../../services/PropertyAssetFeature';
import FeatureState from '../../../../context/state/FeatureState';
import ShareUrlModal from './ShareUrlModal';
import useBoolean from '../../../../hooks/use-boolean';

interface ListOpportunityBtnProps {
    isListed: boolean;
    isPublic: boolean;
    isNdaRequired: boolean;
}

const ListOpportunityBtn: FunctionComponent<ListOpportunityBtnProps> = ({ isListed, isNdaRequired, isPublic }) => {
    const { t } = useTranslation(['f', 'b']),
        { getFeature, propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [isShereModalOpen, isShereModalOpenActions] = useBoolean(),
        [status, setStatus] = useState<PropertyToggleStatus>(toggleBtnStatus);

    const handleCopyModalOpen = useCallback(() => {
        isShereModalOpenActions.setTrue();
    }, [isShereModalOpenActions]);

    const handleToggle = useCallback(
        async (name: string, value: boolean) => {
            const prevState = { ...status };
            try {
                const newS = { ...prevState };
                newS[name].value = value;
                setStatus(newS);
                await PropertyAssetFeatureService.toggleStatus(`${propertyAssetId}`, newS[name]);
            } catch (error: any) {
                setStatus(prevState);
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [status, propertyAssetId, t]
    );

    useEffect(() => {
        setStatus({
            listed: { ...status.listed, value: isListed },
            status: { ...status.status, value: isPublic },
            NDA: { ...status.NDA, value: isNdaRequired },
        });
    }, []);

    return (
        <div className="property-info__share-container">
            {getFeature('ShareWithoutListing') && (
                <div className="d-flex flex-direction-column gap-10">
                    <div className="property-info__share d-flex justify-content-center">
                        <MainButton
                            layout="primary"
                            size="small"
                            buttonHandler={handleCopyModalOpen}
                            buttonText={
                                <span className="d-flex gap-10 align-items-center px-10">
                                    <FontAwesomeIcon icon={faShareSquare} />
                                    {t('f:button.shareOpportunity')}
                                </span>
                            }
                        />
                    </div>
                </div>
            )}
            <ShareUrlModal
                status={status}
                handleToggle={handleToggle}
                isModalOpen={isShereModalOpen}
                isModalOpenActions={isShereModalOpenActions}
            />
        </div>
    );
};
export default ListOpportunityBtn;
