import Company from '../../api/routes/Admin/CompanyApi';

const fetchCompanies = async (pageIdx: number, itemsPerPage: number) => {
    try {
        const { data } = await Company.fetchCompanies(pageIdx, itemsPerPage);
        return data;
    } catch (err) {
        console.log(err);
    }
};

const fetchCompanyUsers = async (companyId: string, pageIdx: number, itemsPerPage: number) => {
    try {
        const { data } = await Company.fetchCompanyUsers(companyId, pageIdx, itemsPerPage);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const fetchDetailCompany = async (companyId: string) => {
    try {
        const { data } = await Company.fetchDetailCompany(companyId);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const activateCompany = async (companyId: string) => {
    try {
        const { data } = await Company.activateCompany(companyId);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const deactivateCompany = async (companyId: string) => {
    try {
        const { data } = await Company.deactivateCompany(companyId);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const verifyCompany = async (companyId: string) => {
    try {
        const { data } = await Company.verifyCompany(companyId);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const unverifyCompany = async (companyId: string) => {
    try {
        const { data } = await Company.unverifyCompany(companyId);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const createCompany = async (company: any) => {
    try {
        const { data } = await Company.createCompany(company);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const updateCompany = async (company: any) => {
    try {
        const { data } = await Company.updateCompany(company);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const removeUsersFromCompany = async (companyId, userIds) => {
    try {
        const { data } = await Company.removeUsersFromCompany(companyId, userIds);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const addUsersToCompany = async (companyId, userIds) => {
    try {
        const data = await Company.addUsersToCompany(companyId, userIds);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const searchUsersNotInCompany = async (companyId, keyword) => {
    try {
        const { data } = await Company.searchUsersNotInCompany(companyId, keyword);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const assignRoleToUserInCompany = async (companyId, userId, role) => {
    try {
        const data = await Company.assignRoleToUserInCompany(companyId, userId, role);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const addUserToCompany = async (companyId, user) => {
    try {
        const data = await Company.addUserToCompany(companyId, user);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const addImageOfCompany = async (options: any, body: any) => {
    const { data } = await Company.addImageOfCompany(options, body);
    return data;
};

const fetchAllUsersFromCompany = async (companyId: string) => {
    const { data } = await Company.fetchAllUsersFromCompany(companyId);
    return data;
};

const CompanyService = {
    fetchCompanies,
    fetchCompanyUsers,
    fetchDetailCompany,
    activateCompany,
    deactivateCompany,
    verifyCompany,
    unverifyCompany,
    createCompany,
    updateCompany,
    removeUsersFromCompany,
    addUsersToCompany,
    searchUsersNotInCompany,
    addImageOfCompany,
    assignRoleToUserInCompany,
    addUserToCompany,
    fetchAllUsersFromCompany,
};
export default CompanyService;
