import { FunctionComponent } from "react"
import { AnalysisStats } from "../../../../../interfaces/investor/AnalysisStats";
import styles from '../styles/Analysis.module.scss';

interface IStatisticProps {
    activeUsers: number;
    analysisStats: AnalysisStats | undefined;
}

const Statistics: FunctionComponent<IStatisticProps> = ({
    activeUsers = 0,
    analysisStats
}) => {
    return (
        <div className={styles.analysisStats}>
            <div className={styles.circle}>
                <p className={styles.widgetValue}>{activeUsers}</p>
                <p className={styles.widgetLabel}>Active Users</p>
            </div>
            <div className={styles.circle}>
                <p className={styles.widgetValue}>{analysisStats?.numberUsers ?? 0}</p>
                <p className={styles.widgetLabel}>Users</p>
            </div>
            <div className={styles.circle}>
                <p className={styles.widgetValue}>{analysisStats?.userAccessRate ?? 0} %</p>
                <p className={styles.widgetLabel}>Users Access Rate</p>
            </div>
            <div className={styles.circle}>
                <p className={styles.widgetValue}>{analysisStats?.usersSignedNda ?? 0}</p>
                <p className={styles.widgetLabel}>Users Signed NDA</p>
            </div>
            <div className={styles.circle}>
                <p className={styles.widgetValue}>{analysisStats?.usersBidSubmitted ?? 0}</p>
                <p className={styles.widgetLabel}>Users Submitted Bids</p>
            </div>
        </div>
    )
}

export default Statistics;