import { FunctionComponent } from 'react';
import TradeProbabilityInfo from '../models/tradeProbabilityInfo';

interface Props {
    tradeProbability: TradeProbabilityInfo;
}

const TradeProbabilityCard: FunctionComponent<Props> = ({ tradeProbability }) => {
    return (
        <div className="building-card">
            <div className="name">{tradeProbability.propertyName}</div>
            <div className="address">{tradeProbability.propertyAddress}</div>
        </div>
    );
};

export default TradeProbabilityCard;
