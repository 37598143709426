import * as Yup from 'yup';

export const CashFlowRecordSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Too Short!').max(255, 'Too Long!').required('f:validation.required'),
    customReviewType: Yup.string().min(1, 'Too Short!').max(255, 'Too Long!'),
    leaseType: Yup.string().min(1, 'Too Short!').max(255, 'Too Long!'),
    unitReference: Yup.string().min(1, 'Too Short!').max(255, 'Too Long!'),
    monthsRentFree: Yup.number().positive('f:validation.positive'),
    customLeaseCategory: Yup.string().min(1, 'Too Short!').max(255, 'Too Long!').required('f:validation.required'),
    unitStatus: Yup.string().min(1, 'Too Short!').max(255, 'Too Long!'),
    area: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .required('f:validation.required'),
    leaseStartDate: Yup.date(),
    leaseEndDate: Yup.date(),
    passingRent: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    amount: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    recoveries: Yup.number().typeError('f:validation.number').integer('f:validation.integer'),
    tenantIncentive: Yup.number().typeError('f:validation.number').integer('f:validation.integer'),
    floor: Yup.number()
        .typeError('f:validation.number')
        .integer('f:validation.integer')
        .positive('f:validation.positive')
        .required('f:validation.required'),
});
