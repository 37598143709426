import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useContext, useState } from 'react';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { GalleryState } from '../../../../../TemplatesWizard/components/templates/Gallery';
import MainButton from '../../../../../../components/shared/MainButton';
import ModalWrapper from '../../../../../../components/wrapper/ModalWrapper';

const imgWithClick = { cursor: 'pointer' };

const Photo = ({ index, onClick, photo, margin, direction, top, left, numOfPhotos }) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false),
        { t } = useTranslation(['f', 'b']),
        [doneCropping, setDoneCropping] = useState<boolean>(false),
        [cancel, setCancel] = useState<boolean>(false),
        [croppedPhoto, setCroppedPhoto] = useState<any>(),
        [cropper, setCropper] = useState<any>();

    let imgStyle: any = { margin: margin };

    if (direction === 'column') {
        imgStyle.position = 'absolute';
        imgStyle.left = left;
        imgStyle.top = top;
    }

    const { removeImage, editImage } = useContext(GalleryState);

    const handleOnCancel = useCallback(() => {
        setIsModalOpen(false);
    }, [setIsModalOpen]);

    const getCropData = () => {
        setDoneCropping(true);
        if (typeof cropper !== 'undefined') {
            setCroppedPhoto(cropper.getCroppedCanvas().toDataURL());
            setDoneCropping(false);
            setCancel(true);
        }
    };

    const className = numOfPhotos && numOfPhotos > 1 ? 'col-md-4 main-text mb-20 relative' : 'main-text mb-20 relative';

    return (
        <div className={className} style={{ zIndex: 999, cursor: 'pointer' }}>
            <div style={{ visibility: 'visible' }}>
                <MainButton
                    size="small"
                    layout="primary"
                    classAdditions="photo__pencil photo__pencil--gallery-trash"
                    buttonText={
                        <div className="d-flex gap-10 align-items-center">
                            <span className="photo__pencil-text">{t('f:button.delete')}</span>
                            <FontAwesomeIcon icon={faTrash} />
                        </div>
                    }
                    buttonHandler={() => removeImage(photo.id)}
                />
                <MainButton
                    size="small"
                    layout="primary"
                    classAdditions="photo__pencil photo__pencil--gallery"
                    buttonText={
                        <div className="d-flex gap-10 align-items-center">
                            <span className="photo__pencil-text">{t('f:button.edit')}</span>
                            <FontAwesomeIcon icon={faPencilAlt} />
                        </div>
                    }
                    buttonHandler={() => {
                        setIsModalOpen(true);
                    }}
                />
            </div>

            <img style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle} src={photo.src} alt="error-loading" />
            <ModalWrapper
                isFooterShown={false}
                isCloseModalShown={true}
                closeModalHandler={handleOnCancel}
                isModalOpened={isModalOpen}
            >
                <div className="row-flex-clear">
                    <div className="col-xs-12-clear gallery-modal__main mt-50">
                        <Cropper
                            style={{ height: 450, width: 470 }}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={photo.src}
                            viewMode={1}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />
                    </div>
                    <div className="col-xs-12 d-flex justify-content-center">
                        {cancel ? (
                            <>
                                <button
                                    className="col-xs-3 button-main button-primary mr-10"
                                    onClick={(e: any) => {
                                        editImage(croppedPhoto, photo);
                                    }}
                                >
                                    {t('f:button.upload')}
                                </button>
                                <button
                                    className="col-xs-3 button-main button-secondary"
                                    disabled={doneCropping}
                                    onClick={() => {
                                        setCancel(true);
                                    }}
                                >
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <button
                                className="col-xs-3 button-main button-primary"
                                disabled={doneCropping}
                                onClick={getCropData}
                            >
                                {doneCropping ? 'Cropping...' : 'Crop Image'}
                            </button>
                        )}
                    </div>
                </div>
            </ModalWrapper>
        </div>
    );
};

export default Photo;
