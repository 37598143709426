import { AxiosResponse } from 'axios';

const getDownloadFileName = (response: AxiosResponse<any>, defaultFileName: string = ''): string => {
    const contentDisposition = response.headers['content-disposition'];
    const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
    const filename = match[1];

    return filename ?? defaultFileName;
};

export const downloadFile = (response: AxiosResponse<any>, defaultFileName: string = ''): void => {
    const blob = new Blob([response.data]);
    const downloadURL = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = downloadURL;
    link.download = getDownloadFileName(response, defaultFileName);
    link.click();
}