import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainCheckbox from '../../../components/shared/MainCheckbox';
import { DEFAULT_DEALROOM_FEATURES } from '../../../constants/viewProperty';
import FeatureState from '../../../context/state/FeatureState';
import useBoolean from '../../../hooks/use-boolean';
import PropertyAssetFeatureService from '../../../services/PropertyAssetFeature';
interface TreeDotsTabsSelectionProps { }

const TreeDotsTabsSelection: FunctionComponent<TreeDotsTabsSelectionProps> = () => {
    const { t } = useTranslation(['f', 'b']),
        [arrayOfSelectedTabs, setArrayOfSelectedTabs] = useState<Array<number>>([]),
        { propertySummary } = useContext(FeatureState),
        [isThreeDotClicked, setIsThreeDotClicked] = useBoolean();

    const handleAddToSelected = useCallback(
        async (id: number) => {
            let newArrayOfSelectedTabs = arrayOfSelectedTabs.includes(id) ?
                arrayOfSelectedTabs.filter((el) => el !== id) :
                [...arrayOfSelectedTabs, id];
            newArrayOfSelectedTabs.sort((a, b) => a - b);

            setArrayOfSelectedTabs(newArrayOfSelectedTabs);
            await PropertyAssetFeatureService.updateDealRoomFeatures(propertySummary.id, newArrayOfSelectedTabs);
        },
        [arrayOfSelectedTabs]
    );

    const content = (
        <div
            className="d-flex flex-direction-column"
            onMouseLeave={() => {
                setIsThreeDotClicked.setFalse();
            }}
        >
            {DEFAULT_DEALROOM_FEATURES.map((el) => (
                <div className="d-flex" key={el.id}>
                    <MainCheckbox
                        onClick={() => handleAddToSelected(el.id)}
                        checked={arrayOfSelectedTabs.includes(el.id)}
                    />
                    <p className="property-info__share-txt d-flex align-items-center">
                        <span>{t(el.name)}</span>
                    </p>
                </div>
            ))}
        </div>
    );

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    const fetchDealRoomFeatures = useCallback(async () => {
        const features = await PropertyAssetFeatureService.fetchDealRoomFeatures(propertySummary.id);
        if (!features) {
            const allFeatures = DEFAULT_DEALROOM_FEATURES.map(item => item.id);
            setArrayOfSelectedTabs(allFeatures);
        } else if (Array.isArray(features)) {
            setArrayOfSelectedTabs(features);
        }
    }, [])

    useEffect(() => {
        fetchDealRoomFeatures();
    }, [fetchDealRoomFeatures]);

    return (
        <div className="">
            <FontAwesomeIcon
                className="mxl-10"
                style={{ color: '#a1abbd', cursor: 'pointer' }}
                icon={faEllipsisH}
                size="lg"
                onClick={() => {
                    setIsThreeDotClicked.setTrue();
                }}
            />
            <div style={{ marginLeft: '20px' }}>
                <Popover
                    getPopupContainer={handleContainer}
                    content={content}
                    placement="bottom"
                    trigger="click"
                    visible={isThreeDotClicked}
                />
            </div>
        </div>
    );
};

export default TreeDotsTabsSelection;
