import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from './TextInput';
import ChartCategory from './ChartCategory';
import TemplateContextState from '../../../context/state/TemplateContextState';
import { chartTypes, dateTypes } from '../../../constants/chartTypes';
import SingleSelect from '../../../components/shared/SingleSelect';
import ChartPreview from './ChartPreview';

const ChartContent: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        {
            isPreviewShow,
            setChartType,
            chartType,
            dateType,
            setDateType,
            setIsPreviewShow,
            chartSource,
            setChartSource,
            setNewChartSource,
        } = useContext(TemplateContextState);

    const handleChangeChartType = (value: any) => {
        setIsPreviewShow(false);
        setChartType(value);
    };
    const handleChangeDateType = (value: any) => {
        setIsPreviewShow(false);
        setDateType(value);
    };

    return (
        <div className="col-xs-12">
            <div className="col-xs-12-clear mb-20">
                <div className="col-xs-3-clear">
                    <SingleSelect
                        label={t('f:chart.chartType')}
                        placeholder={t('f:chart.selectChart')}
                        options={chartTypes}
                        onSelected={handleChangeChartType}
                        selected={chartType}
                    />
                </div>
                {chartType === 1 && (
                    <div className="col-xs-3-clear ml-20">
                        <SingleSelect
                            label={t('f:chart.date')}
                            placeholder={t('f:chart.selectDate')}
                            options={dateTypes}
                            onSelected={handleChangeDateType}
                            selected={dateType}
                        />
                    </div>
                )}
            </div>
            {chartType && (
                <>
                    <ChartCategory category={chartType} />

                    {isPreviewShow && <ChartPreview category={chartType} showMainData={false} mainData={null} />}

                    <TextInput
                        content={chartSource}
                        setContent={(val) => {
                            setChartSource(val);
                            setNewChartSource(val);
                        }}
                        placeholder={t('f:propertySummary.enterTitle')}
                        label={t('f:propertySummary.source')}
                    />
                </>
            )}
        </div>
    );
};
export default ChartContent;
