import * as React from 'react';
import { Actions } from '../../interfaces/general/general';
import {
    IBidingStructure,
    IUsersApproval,
    initalListingType,
    IListingType,
    IOverviewLO,
} from '../../interfaces/listOpporunity/ListOpportunity';

export type DeallineWizardStateType = {
    currentListingType: IListingType;
    setCurrentListingType: (prev: IListingType) => void;
    isNdasTypesLoaded: boolean;
    isListingTypeLoaded: boolean;
    ndaTypes: Object;
    approvaltype: Object;
    bidingStructure: IBidingStructure | null;
    setBidingStructure: (prev: IBidingStructure | null) => void;
    isRequired: boolean;
    setIsRequiredActions: Actions;
    handleIsRequiredSwitch: () => void;
    handleBidingStructurAndNextStep: () => void;
    changeDestination: (val: string) => void;
    usersWhoCanApprove: Array<IUsersApproval>;
    overviewLO: IOverviewLO | null;
    goToApprovalsOrFinish: () => void;
    isStepChanged: boolean;
    setIsStepChangedActions: Actions;
    sendEmail: (id: string) => void;
    isAgreePayment: boolean;
    isAgreePaymentActions: Actions;
    isSendInvitations: boolean;
    IsSendInvitationsActions: Actions;
    selectedUsersId: Array<string>;
    setSelectedUsersId: (prev: Array<string>) => void;
    isAllUsersSelected: boolean;
    isListOpportunityWizardFinished: boolean;
    isAllUsersSelectedActions: Actions;
};
const action = {
    setTrue: () => {},
    setFalse: () => {},
    toggle: (value?: boolean | undefined) => {},
};
export default React.createContext<DeallineWizardStateType>({
    currentListingType: initalListingType,
    setCurrentListingType: (prev: IListingType) => {},
    isNdasTypesLoaded: false,
    isListingTypeLoaded: false,
    ndaTypes: {},
    approvaltype: {},
    bidingStructure: null,
    setBidingStructure: (prev: IBidingStructure | null) => {},
    isRequired: false,
    setIsRequiredActions: action,
    handleIsRequiredSwitch: () => {},
    handleBidingStructurAndNextStep: () => {},
    changeDestination: (val: string) => {},
    usersWhoCanApprove: [],
    overviewLO: null,
    goToApprovalsOrFinish: () => {},
    isStepChanged: false,
    setIsStepChangedActions: action,
    sendEmail: (id: string) => {},

    isAgreePayment: false,
    isAgreePaymentActions: action,
    isSendInvitations: false,
    IsSendInvitationsActions: action,
    selectedUsersId: [],
    setSelectedUsersId: (prev: Array<string>) => {},
    isAllUsersSelected: false,
    isListOpportunityWizardFinished: false,
    isAllUsersSelectedActions: action,
});
