import { FunctionComponent } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote';
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading';
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image';
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ListPlugin from '@ckeditor/ckeditor5-list/src/list';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import FontFamilyPlugin from '@ckeditor/ckeditor5-font/src/fontfamily';
import FontSizePlugin from '@ckeditor/ckeditor5-font/src/fontsize';
import FontColorPlugin from '@ckeditor/ckeditor5-font/src/fontcolor';
import ImageInsertPlugin from '@ckeditor/ckeditor5-image/src/imageinsert';
import TablePlugin from '@ckeditor/ckeditor5-table/src/table';
import TableToolbarPlugin from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';

import api from '../../api';
import { getEditorImageConfiguration } from '../../utils/editorConfiguration';

interface Props {
    onChange: any;
    propertyAssetId: string;
    templateKey: string;
    pageType: string;
    contentState: {
        state: string;
        setter: React.Dispatch<React.SetStateAction<string>>;
    };
}

class CustomUploadAdapter {
    loader: any;
    propertyAssetId: string;
    pageType: string;
    constructor(loader: any, propertyAssetId: string, pageType: string) {
        // The file loader instance to use during the upload.
        this.loader = loader;
        this.propertyAssetId = propertyAssetId;
        this.pageType = pageType;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file.then(
            (file: any) =>
                new Promise((resolve, reject) => {
                    const data = new FormData();
                    data.append('Files', file);
                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Accept: 'text/plain',
                        },
                    };
                    api.post(`/${this.pageType}/${this.propertyAssetId}/files`, data, config)
                        .then((res: any) => {
                            resolve({ default: res.data[0] });
                        })
                        .catch((err: Error) => {
                            reject(err);
                        });
                })
        );
    }
}

const Editor: FunctionComponent<Props> = (props) => {
    const tools = [
        'heading',
        'bold', 
        'italic',
        'alignment:left',
        'alignment:right',
        'alignment:center',
        'alignment:justify',
        'link', 
        'fontFamily',
        'fontSize',
        'fontColor',
        'insertImage',
        'insertTable',
        'bulletedList', 
        'numberedList', 
        'blockQuote',
        'undo', 
        'redo', 
        'htmlEmbed'
    ];

    if (props.templateKey === 'multiple-text-image' || props.templateKey === 'icon-text') {
        tools.push('uploadImage');
    }

    return (
        <div className="App">
            <CKEditor
                editor={ClassicEditor}
                config={{
                    plugins: [
                        EssentialsPlugin,
                        AutoformatPlugin,
                        BoldPlugin,
                        ItalicPlugin,
                        BlockQuotePlugin,
                        HeadingPlugin,
                        LinkPlugin,
                        ListPlugin,
                        ImagePlugin,
                        ImageUploadPlugin,
                        ImageToolbar,
                        ImageCaption,
                        ImageStyle,
                        ParagraphPlugin,
                        HtmlEmbed,
                        FontFamilyPlugin,
                        FontSizePlugin,
                        FontColorPlugin,
                        ImageInsertPlugin,
                        TablePlugin,
                        TableToolbarPlugin,
                        AlignmentPlugin
                    ],
                    toolbar: tools,
                    image: getEditorImageConfiguration(props.templateKey),
                }}
                data={props.contentState.state}
                onReady={(editor: any) => {
                    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
                        // Configure the URL to the upload script in your back-end here!
                        return new CustomUploadAdapter(loader, props.propertyAssetId, props.pageType);
                    };
                }}
                onChange={(event: any, editor: any) => {
                    const data = editor.getData();
                    props.onChange(data);
                }}
            />
        </div>
    );
};

export default Editor;
