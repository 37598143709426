import PowerBiApi from '../api/routes/PowerBi';

const getReportConfig = async (reportId: string) => {
    const { data } = await PowerBiApi.fetchReportConfig(reportId);
    return data;
};

const ReportService = {
    getReportConfig,
};

export default ReportService;