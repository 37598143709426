import { number } from "yup";
import IPropertyInfo from "../../../components/shared/PropertiesMap/iPropertyInfo";

export class TransactionDataInfo implements IPropertyInfo {
    setData(data: any, nullString?: string | undefined): void {
        for (const key in this) {
            this[key] = nullString && data[key] === nullString ? null : data[key];
        }
    }

    id: string = '';
    propertyKeyId: string = '';
    dealId: string = '';
    propertyId: string = '';

    // Property Details
    propertyName: string = '';
    address: string = '';
    postcode?: number;
    country: string = '';
    city: string = '';
    state: string = '';
    cbd?: string;
    mainSector: string = '';
    tenancy: string = '';
    yearBuilt?: number;
    numberBuildings?: number;
    numberFloors?: string;
    
    // Transaction Details
    transactionType?: string;
    transactionSubType?: string;
    buyerObjective?: string;
    excessLand?: boolean;
    portfolio: string = '';
    priorSalesDate?: string;
    numberOfProperties?: string;
    status: string = '';
    saleDate: string = '';
    occupancy?: number;
    assetValue?: number;
    saleValue?: number;
    costPerSqFt?: number;
    yield?: number;
    sqFt?: number;
    sqMeters?: number;

    // Buy Details
    buyerName1?: string;
    buyerGroup1?: string;
    buyerType1?: string;
    buyerCountry1?: string;
    buyerJV1?: string;
    buyerName2?: string;
    buyerGroup2?: string;
    buyerType2?: string;
    buyerCountry2?: string;
    buyerJV2?: string;
    buyerName3?: string;
    buyerGroup3?: string;
    buyerType3?: string;
    buyerCountry3?: string;
    buyerJV3?: string;
    buyerName4?: string;
    buyerGroup4?: string;
    buyerType4?: string;
    buyerCountry4?: string;

    // Seller Details
    sellerName1?: string;
    sellerGroup1?: string;
    sellerType1?: string;
    sellerCountry1?: string;
    sellerJV1?: string;
    sellerName2?: string;
    sellerGroup2?: string;
    sellerType2?: string;
    sellerCountry2?: string;
    sellerJV2?: string;
    sellerName3?: string;
    sellerGroup3?: string;
    sellerType3?: string;
    sellerCountry3?: string;
    sellerJV3?: string;
    sellerName4?: string;
    sellerGroup4?: string;
    sellerType4?: string;
    sellerCountry4?: string;

    lat: number = 0;
    lon: number = 0;
    subSector: string = '';

    constructor(data?: any) {
        if (data) {
            this.setData(data);
        }
    }
}