import { useLocation } from 'react-router';
import ComingSoon from '../../../components/shared/ComingSoon';

const Economic = () => {
    const location = useLocation();
    let activTab = location.hash ? location.hash.replace('#', '') : '';

    return <ComingSoon />;
};

export default Economic;
