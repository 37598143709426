import { FunctionComponent, useCallback } from 'react';
import IdleTimer from 'react-idle-timer';
import { useMsal } from '@azure/msal-react';
import { resetToken } from '../../auth/UserService';
import useBoolean from '../../hooks/use-boolean';
import SessionExpireModal from '../partials/SessionExpireModal';
import { useHistory } from 'react-router-dom';

const UserIdle: FunctionComponent = () => {
    const { instance } = useMsal(),
        history = useHistory(),
        [isSessionExpireModalOpen, isSessionExpireModalOpenActions] = useBoolean();

    const handleSignout = useCallback(() => {
        instance.logout();
        resetToken();
        history.push('/');
        isSessionExpireModalOpenActions.setTrue();
    }, []);

    return (
        <div className="idle-timer">
            <IdleTimer timeout={6 * 60 * 60 * 1000} onIdle={handleSignout} />

            <SessionExpireModal
                isModalOpen={isSessionExpireModalOpen}
                onFinish={isSessionExpireModalOpenActions.setFalse}
            />
        </div>
    );
};
export default UserIdle;
