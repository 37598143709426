import { FunctionComponent, useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload } from 'antd';
import _ from 'lodash';
import SortableImageGallery from '../../../ViewProperty/components/PropertySummary/propertyTemplateComponents/sortableGallery/SortableGallery';
import React from 'react';
import PropertySummary from '../../../../services/PropertySummary';
import { toast } from 'react-toastify';
import FileUpload from '../../../../services/FileUploadService';
import Cropper from 'react-cropper';
import MainHeading from '../../../../components/shared/MainHeading';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';
import FeatureState from '../../../../context/state/FeatureState';

interface Props {
    setContent: any;
    propertyAssetId: string;
    contentState: {
        state: string;
        setter: React.Dispatch<React.SetStateAction<string>>;
    };
}

interface Image {
    uid: string;
    name: string;
    url: string;
    size: number;
    type: string;
}

export const GalleryState = React.createContext({
    removeImage: (imageId: string) => {},
    editImage: async (options: any, photo: any) => {},
});

export const Gallery: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']);
    const [fileList, setFileList] = useState<Image[]>([]),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary;
    const [title, setTitle] = useState('');
    const [images, setImages] = useState<any>();
    const [isOrderChanged, setIsOrderChanged] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [doneCropping, setDoneCropping] = useState<boolean>(false);
    const [cropper, setCropper] = useState<any>();
    const [cancel, setCancel] = useState<boolean>(false);
    const [croppedPhoto, setCroppedPhoto] = useState<any>();
    const [imageUrl, setImageUrl] = useState<any>();
    const [uploadedImagesUrl, setUploadedImagesUrl] = useState<String[]>([]);
    const [imageActiveIndex, setImageActiveIndex] = useState<number>(0);

    useEffect(() => {
        const data = props.contentState.state.length
            ? JSON.parse(props.contentState.state.toString())
            : { title: '', images: [] };
        setFileList(data.images);
        setImages(
            data.images.map((image: any) => {
                return { src: image.url, width: 1, height: 1, id: image.uid };
            })
        );

        setTitle(data.title);
        props.setContent(JSON.stringify({ title: data.title, images: data.images }).toString());
        setIsOrderChanged(false);
    }, [props.contentState.state]);

    useEffect(() => {
        if (isOrderChanged) {
            let newFileList: any = [];
            _.forEach(images, (image: any) => {
                newFileList.push(_.find(fileList, { uid: image.id }));
            });
            setFileList(newFileList);
            props.setContent(JSON.stringify({ title: title, images: newFileList }).toString());
            setIsOrderChanged(false);
        }
    }, [isOrderChanged]);

    const editImage = async (url: any, photo: any) => {
        const data = new FormData();
        data.append('Files', dataURLtoFile(url, photo.id));
        try {
            const newUrl = await PropertySummary.uploadFile(`${propertyAssetId}`, data);
            const opt: any = _.find(fileList, { uid: photo.id });
            opt.url = newUrl;
            props.setContent(JSON.stringify({ title: title, images: fileList }).toString());
        } catch (error: any) {
            toast(error);
        }
    };

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };

    const removeImage = (imageId: string) => {
        let newFileList = _.reject(fileList, { uid: imageId });
        setFileList(newFileList);
        props.setContent(JSON.stringify({ title: title, images: newFileList }).toString());
    };

    const getCropData = () => {
        setDoneCropping(true);
        if (typeof cropper !== 'undefined') {
            setCroppedPhoto(cropper.getCroppedCanvas().toDataURL());
            setDoneCropping(false);
            setCancel(true);
        }
    };

    const customRequest = async (options: any) => {
        const data = new FormData();
        data.append('Files', options.file);
        try {
            const imageUrl = await FileUpload.uploadFile(options, data);
            uploadedImagesUrl.push(imageUrl[0]);
            setUploadedImagesUrl(uploadedImagesUrl);

            let image = {
                url: imageUrl[0],
                uid: options.file.uid,
                name: options.file.name,
                size: options.file.size,
                type: options.file.type,
            };
            fileList.push(image);
            setFileList(fileList);
            let newImage = { src: image.url, width: 1, height: 1, id: image.uid };

            let newImageArray: Array<any> = images;
            newImageArray.push(newImage);
            setImages(newImageArray);

            const newImageActiveIndex =
                fileList.length - uploadedImagesUrl.length >= 0 ? fileList.length - uploadedImagesUrl.length : 0;
            setImageActiveIndex(newImageActiveIndex);
            setImageUrl(uploadedImagesUrl[0]);

            setIsModalOpen(true);

            props.setContent(JSON.stringify({ title: title, images: fileList }).toString());
        } catch (error: any) {
            toast(t(`f:${error.response.data.errorMessage}`));
        }
    };

    const getImageForCrop = () => {
        if (!imageUrl) {
            setImageUrl(uploadedImagesUrl[0]);
        } else if (imageActiveIndex + 1 === fileList.length) {
            setIsModalOpen(false);
            setUploadedImagesUrl([]);
            setImageActiveIndex(0);
            setImageUrl('');
        } else {
            const imageUrlIndex = imageActiveIndex - (fileList.length - uploadedImagesUrl.length) + 1;
            setImageUrl(uploadedImagesUrl[imageUrlIndex]);
            setImageActiveIndex(imageActiveIndex + 1);
        }
    };

    const finishCropping = async () => {
        let currentCroppedPhoto;
        if (typeof cropper !== 'undefined') {
            currentCroppedPhoto = cropper.getCroppedCanvas().toDataURL();
            setCroppedPhoto(currentCroppedPhoto);
        }

        if (!currentCroppedPhoto) {
            return;
        }

        try {
            const data = new FormData();
            data.append('Image', dataURLtoFile(currentCroppedPhoto, 'Image'));

            const imageUrl = await FileUpload.uploadFile(
                { action: process.env.REACT_APP_API_URL + `/propertyasset/image` },
                data
            );
            fileList[imageActiveIndex].url = imageUrl;
            setFileList(fileList);
            props.setContent(JSON.stringify({ title: title, images: fileList }).toString());

            getImageForCrop();
            setCancel(false);
        } catch (error: any) {
            toast(t(`f:${error.response.data.errorMessage}`));
        }
    };

    const closeHandler = useCallback(() => {
        setIsModalOpen(false);
    }, [setIsModalOpen]);

    return (
        <div className="row-flex-clear rich-text-editor-container">
            <div className="col-xs-12">
                <MainHeading text={t('f:propertySummary.content')} />
            </div>
            <div className="col-xs-12 form-group mb-20">
                <label>{t('f:property.title')}</label>
                <input
                    name="heading"
                    value={title}
                    placeholder={t('f:property.title')}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="row-clear gallery-template__main mt-20">
                {images?.length > 0 && (
                    <GalleryState.Provider value={{ removeImage: removeImage, editImage: editImage }}>
                        <SortableImageGallery
                            images={images}
                            setIsOrderChanged={setIsOrderChanged}
                            setImages={setImages}
                        />
                    </GalleryState.Provider>
                )}
            </div>
            <div className="col-xs-12">
                <div className="row">
                    <Upload
                        className="add-property-image-builder__upload add-property-image-builder__upload--template"
                        name="Files"
                        progress={{ strokeWidth: 2, showInfo: false }}
                        action={
                            process.env.REACT_APP_API_URL + `/propertyassetpagesummary/${props.propertyAssetId}/files`
                        }
                        customRequest={customRequest}
                        multiple
                    >
                        <button className="teams-export__icon border-dashed py-10 px-100 transparent">
                            {t('f:button.upload')}
                        </button>
                    </Upload>
                </div>
            </div>
            <ModalWrapper
                title={t('f:property.cropImage')}
                isCloseModalShown={true}
                closeModalHandler={closeHandler}
                isFooterShown={false}
                isModalOpened={isModalOpen}
            >
                <div className="row-flex-clear">
                    <div className="col-xs-12-clear gallery-modal__main mt-50">
                        <Cropper
                            style={{ height: 480, width: 500 }}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={imageUrl}
                            viewMode={1}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />
                    </div>

                    <div
                        className={`gallery-modal__progress-bar-container col-xs-12 mb-10 ${
                            fileList.length == 0 || fileList.length == 1
                                ? 'gallery-modal__progress-bar--start'
                                : `${
                                      fileList.length == 2
                                          ? 'gallery-modal__progress-bar--half'
                                          : 'gallery-modal__progress-bar--full'
                                  }`
                        }`}
                    >
                        <div className={`gallery-modal__progress-bar `}></div>
                        <div className={`d-flex justify-content-space-between `}>
                            {fileList.map((elem: Image, i: number) => {
                                return (
                                    <div
                                        className={`gallery-modal__progress-dot${
                                            imageActiveIndex === i ? ' gallery-modal__progress-dot--green' : ''
                                        }`}
                                    ></div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-xs-12 d-flex justify-content-center">
                        <button
                            className="col-xs-3 button-main button-primary mr-10"
                            onClick={(e: any) => {
                                finishCropping();
                            }}
                        >
                            {t('f:button.upload')}
                        </button>
                        {imageActiveIndex < uploadedImagesUrl.length && (
                            <button
                                className="col-xs-3 button-main button-primary"
                                disabled={doneCropping}
                                onClick={getImageForCrop}
                            >
                                {imageActiveIndex + 1 === uploadedImagesUrl.length ? 'Finish' : 'Next Image'}
                            </button>
                        )}
                    </div>
                </div>
            </ModalWrapper>
        </div>
    );
};
