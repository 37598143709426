import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory();

    const properties = [
        { name: 'allProperties', url: '' },
        { name: 'office', url: '' },
        { name: 'multiFamily', url: '' },
        { name: 'hotel', url: '' },
        { name: 'retail', url: '' },
        { name: 'industrial', url: '' },
        { name: 'land', url: '' },
        { name: 'other', url: '' },
    ];

    const categories2 = [
        { name: 'aboutUs', url: '/coming-soon' },
        { name: 'pricing', url: '/pricing' },
    ];

    return (
        <footer>
            <div className="row-flex-clear footer-main-container ">
                <div className="col-md-9">
                    <div className="row d-flex flex-direction-column justify-content-space-between">
                        <div className="col-xs-12">
                            <div className="row">
                                <h5>{t(`f:footer.property`)}</h5>
                                <ul className="d-flex footer__list-of-services">
                                    {properties.map((properties, index) => (
                                        <li key={index} className="mr-30">
                                            <button
                                                className="footer__href"
                                                onClick={() => {
                                                    history.push('/coming-soon');
                                                }}
                                            >
                                                {t(`f:footer.${properties.name}`)}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-xs-12">
                            <div className="row">
                                <p className="heading"></p>
                                <ul className="d-flex footer__list-of-services--secondary">
                                    {categories2.map((category, index) => (
                                        <li key={index} className="mr-30">
                                            <button
                                                onClick={() => {
                                                    history.push(`${category.url}`);
                                                }}
                                                className="footer__href"
                                            >
                                                {t(`f:footer.${category.name}`)}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__social col-md-3-clear d-flex flex-direction-column justify-content-space-between">
                    <div>
                        <h5>{t(`f:footer.contactUs`)}</h5>
                        <p>{t(`f:footer.helpCentre`)}</p>
                        <p>{t(`f:footer.phoneNumber`)}</p>
                        <p>{t(`f:footer.woringTime`)}</p>
                    </div>
                </div>
            </div>
            <div className="row-flex-clear footer-copyright-container">
                <div className="col-sm-6">
                    <p>
                        {t(`f:footer.copyrightVirgate`)} {new Date().getFullYear()}
                    </p>
                </div>
                <div className="footer__terms-list col-sm-6 justify-content-end">
                    <p className="footer-copyright-text">
                        <Link to={'/terms-of-use'}>{t(`f:footer.termsOfUse`)}</Link>
                    </p>
                    <p className="footer-copyright-text">
                        <Link to={'/privacy-policy'}>{t(`f:footer.privacyPolicy`)}</Link>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
