import { FunctionComponent, useState } from 'react';
import RadioList from '../../../../components/shared/RadioList';
import GrossRentChart from './grossRentChart';
import CapitalValueChart from './capitalValueChart';
import OverviewChart from './overviewChart';
import MarketYieldChart from './MarketYieldChart';

const grossRentKey: string = 'GrossRent';
const capitalValueKey: string = 'CapitalValue';
const marketYieldKey: string = 'MarketYield';
const totalNewSupplyKey: string = 'TotalNewSupply';

const charts = [
    {
        key: grossRentKey,
        value: 'Gross Rent',
        description: 'Heritage Trees',
    },
    {
        key: capitalValueKey,
        value: 'Capital Value',
        description: 'Capital Value',
    },
    {
        key: marketYieldKey,
        value: 'Market Yield',
        description: 'Market Yield',
    },
    {
        key: totalNewSupplyKey,
        value: 'Total New Supply, Net Absoption Vacancy Rate',
        description: 'Total New Supply, Net Absoption Vacancy Rate',
    },
];

const SubmarketData: FunctionComponent<any> = (props) => {
    const [selected, setSelected] = useState<any>(charts[0]);

    const itemSelected = (value: any) => {
        let selectedItem = charts.filter((x) => x.key === value)[0];
        setSelected(selectedItem);
    };

    return (
        <section className="module-sub-market">
            <div className="content-text">
                <h1 className="heading">Sub Market Data</h1>
            </div>
            <div className="mt-40">
                <RadioList onSelected={itemSelected} selected={selected} items={charts} isHorizontal={true} />
            </div>
            {selected?.key === grossRentKey && <GrossRentChart />}
            {selected?.key === capitalValueKey && <CapitalValueChart />}
            {selected?.key === marketYieldKey && <MarketYieldChart />}
            {selected?.key === totalNewSupplyKey && <OverviewChart />}
        </section>
    );
};

export default SubmarketData;
