import { FunctionComponent } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import MyNumberInput from '../../../../AddEditProperty/components/MyNumberInput';

interface AddEditRecordModalTextInputProps {
    name: string;
    errors: any;
    touched: any;
    values: any;
    setFieldValue: (name: string, val: any) => void;
}

const AddEditRecordModalTextInput: FunctionComponent<AddEditRecordModalTextInputProps> = ({
    name,
    touched,
    errors,
    setFieldValue,
    values,
}: AddEditRecordModalTextInputProps) => {
    const { t } = useTranslation(['f', 'b']);

    const switchForFormat = () => {
        switch (name) {
            case 'amount':
                return (
                    <MyNumberInput
                        placeholder={t(`f:property.${name}`)}
                        value={values[name]}
                        onValueChange={(val) => setFieldValue(name, val.floatValue)}
                        disabled={true}
                    />
                );
            case 'area':
                return (
                    <MyNumberInput
                        placeholder={t(`f:property.${name}`)}
                        value={values[name]}
                        onValueChange={(val) => setFieldValue(name, val.floatValue)}
                    />
                );

            default:
                return (
                    <Field id={name} name={name} placeholder={t(`f:property.${name}`)} disabled={name === 'amount'} />
                );
        }
    };

    return (
        <>
            <label htmlFor={name}>{t(`f:cashFlow.${name}`)}</label>
            {switchForFormat()}
            {errors[name] && touched[name] ? (
                <span className="error-text-absolute">{t(errors[name], { name: t(`f:cashFlow.${name}`) })}</span>
            ) : null}
        </>
    );
};
export default AddEditRecordModalTextInput;
