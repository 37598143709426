import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MainHeading from '../../../../components/shared/MainHeading';
import LOWizardState from '../../../../context/state/LOWizardState';
import ListOpportunityFooterBtns from '../partials/ListOpportunityFooterBtns';
import PublisInfoSingleItem from '../partials/PublisInfoSingleItem';
import PublisInfoTitle from '../partials/PublisInfoTitle';
import { LoWizardStepNames } from '../../../../constants/wizardSteps';
import moment from 'moment';
import Deadline from '../../../../components/shared/Deadline';
import InvestorsView from '../partials/InvestorsView';
import { PROPERTY_TABS } from '../../../../constants/propertyTab';

import ModalImportLoading from '../../../../components/shared/ModalImportLoading';
import FeatureState from '../../../../context/state/FeatureState';

const Publish: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        { overviewLO, changeDestination, usersWhoCanApprove, goToApprovalsOrFinish, isListOpportunityWizardFinished } =
            useContext(LOWizardState);

    const returnToPrev = useCallback(() => {
        changeDestination(LoWizardStepNames.PAYMENT_ACKNOWLEDGEMENT);
    }, [changeDestination]);

    return (
        <div className="wizard-container-steps">
            <MainHeading text={t('f:listOpportunity.publish')} />
            {overviewLO ? (
                <div className="col-xs-12 mb-120 mt-40">
                    <PublisInfoTitle
                        isTop={true}
                        isEditable={false}
                        title={t(`f:listOpportunity.overviewInfo.listingInformation`)}
                    />

                    <div className="overview__informations-main d-flex">
                        <PublisInfoSingleItem
                            title={t(`f:listOpportunity.overviewInfo.listingType`)}
                            text={t(
                                `f:listOpportunity.enumPropertyAssetStatus.${overviewLO.listingType.listingTypeStatus}`
                            )}
                        />
                        <PublisInfoSingleItem
                            title={t(`f:listOpportunity.overviewInfo.biddingStartDate`)}
                            text={
                                overviewLO.marketingStatuses.length > 0
                                    ? moment(new Date(overviewLO.marketingStatuses[0].startDate)).format('DD-MM-YYYY')
                                    : '-'
                            }
                        />
                        <PublisInfoSingleItem
                            title={t(`f:listOpportunity.overviewInfo.NDARequired`)}
                            text={
                                overviewLO.bidingStructure.ndaType === 0
                                    ? t(`f:listOpportunity.no`)
                                    : t(`f:listOpportunity.yes`)
                            }
                        />

                        <PublisInfoSingleItem
                            title={t(`f:listOpportunity.overviewInfo.biddingStages`)}
                            text={
                                overviewLO.marketingStatuses.length > 0
                                    ? t(`f:listOpportunity.enumBiddingStage.${overviewLO.marketingStatuses[0].stage}`)
                                    : '-'
                            }
                        />
                        <PublisInfoSingleItem
                            title={t(`f:listOpportunity.overviewInfo.biddingCloseDate`)}
                            text={
                                overviewLO.marketingStatuses.length > 0
                                    ? moment(new Date(overviewLO.marketingStatuses[0].closeDate)).format('DD-MM-YYYY')
                                    : '-'
                            }
                        />
                        <PublisInfoSingleItem
                            title={t(`f:listOpportunity.overviewInfo.NDAType`)}
                            text={t(`f:listOpportunity.enumNdaType.${overviewLO.bidingStructure.ndaType}`)}
                        />
                    </div>
                    <div className="overview__main">
                        <PublisInfoTitle
                            isTop={false}
                            isEditable={false}
                            title={t(`f:listOpportunity.marketingTimeline`)}
                        />

                        <Deadline showChartScale={false} showTreeDotsMenu={false} />
                    </div>
                    <div className="overview__main">
                        <PublisInfoTitle
                            isTop={false}
                            isEditable={false}
                            title={t(`f:listOpportunity.overviewInfo.investorsList`)}
                        />

                        <InvestorsView />
                    </div>
                </div>
            ) : null}
            <ListOpportunityFooterBtns
                cancelUrl={`/property/${externalId}#${PROPERTY_TABS.PROPERTY_SUMMARY.name}`}
                isNotFirst={true}
                isNotFinish={!usersWhoCanApprove.length}
                onNextHandler={goToApprovalsOrFinish}
                returnToPreviousPage={returnToPrev}
            />
            <ModalImportLoading text={t(`f:loaders.publish`)} isModalOpened={isListOpportunityWizardFinished} />
        </div>
    );
};
export default Publish;
