import * as amcharts5 from '@amcharts/amcharts5';
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import * as am5map from '@amcharts/amcharts5/map';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { FunctionComponent, useLayoutEffect } from "react";
import { ICountryMapProps } from './CountryMap';

const HighlightCountryMap: FunctionComponent<ICountryMapProps> = ({
    id = 'mapChartDiv',
    height = '100%',
    data = []
}) => {
    useLayoutEffect(() => {
        if (data.length) {
            let root = amcharts5.Root.new(id);
            // Set themes
            root.setThemes([
                am5themes_Animated.new(root)
            ]);
            let chart = root.container.children.push(
                am5map.MapChart.new(root, {
                    panX: "none",
                    panY: "translateY",
                    projection: am5map.geoMercator(),
                })
            );

            let polygonSeries = chart.series.push(
                am5map.MapPolygonSeries.new(root, {
                    geoJSON: am5geodata_worldLow,
                    exclude: ["AQ"],
                    fill: amcharts5.color(0x1C404F)
                })
            );

            let pointSeries = chart.series.push(am5map.MapPointSeries.new(root, {}));
            let colorset = amcharts5.ColorSet.new(root, {});

            pointSeries.bullets.push(function () {
                let container = amcharts5.Container.new(root, {});

                let circle = container.children.push(
                    amcharts5.Circle.new(root, {
                        radius: 4,
                        tooltipY: 0,
                        fill: colorset.next(),
                        strokeOpacity: 0,
                        tooltipText: "{title} {value}"
                    })
                );

                container.children.push(
                    amcharts5.Circle.new(root, {
                        radius: 4,
                        tooltipY: 0,
                        fill: colorset.next(),
                        strokeOpacity: 0,
                        tooltipText: "{title} {value}"
                    })
                );

                circle.animate({
                    key: "scale",
                    from: 1,
                    to: 5,
                    duration: 600,
                    easing: amcharts5.ease.out(amcharts5.ease.cubic),
                    loops: Infinity
                });
                circle.animate({
                    key: "opacity",
                    from: 1,
                    to: 0,
                    duration: 600,
                    easing: amcharts5.ease.out(amcharts5.ease.cubic),
                    loops: Infinity
                });

                return amcharts5.Bullet.new(root, {
                    sprite: container
                });
            });

            data.forEach(item => {
                pointSeries.data.push({
                    geometry: { type: "Point", coordinates: [item.longitude, item.latitude] },
                    title: item.name,
                    value: item.value
                })
            })

            chart.appear(1000, 100);
            return () => root.dispose();
        }
    }, [data])

    return <div id={id} style={{ width: '100%', height }}></div>;
}

export default HighlightCountryMap;