import { CaretRightOutlined } from '@ant-design/icons';
import { Checkbox, Collapse, DatePicker, Select, Slider } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { numberWithCommas } from '../../../utils/numberWithCommas';
import { BUYER_CAP_INV_COMP } from '../constants/buyerCapInvComp';
import { BUYER_OBJECTIVES } from '../constants/buyerObjective';
import { COUNTIES_WITH_CITIES } from '../constants/country';
import { SECTORS } from '../constants/sector';
import { SearchTransactionDataModel } from '../models/search-transaction-data.model';

const { Option } = Select;

interface Props {
    filter: (params: SearchTransactionDataModel) => void
}

const FilterMenuPanel: FunctionComponent<Props> = ({ filter }) => {
    const [sectors, setSectors] = useState<string[]>([]),
        [country, setCountry] = useState<string>(),
        [city, setCity] = useState<string>(),
        [cityList, setCityList] = useState<string[]>([]),
        [dateFrom, setDateFrom] = useState<moment.Moment>(),
        [dateTo, setDateTo] = useState<moment.Moment>(),
        [startYearBuilt, setStartYearBuilt] = useState<number>(),
        [endYearBuilt, setEndYearBuilt] = useState<number>(),
        [buyerObjective, setBuyerObjective] = useState<string>(),
        [buyerCapInvComp, setBuyerCapInvComp] = useState<string>();

    const [priceMin, setPriceMin] = useState<number>(),
        [priceMax, setPriceMax] = useState<number>();

    const countryList = _.keys(COUNTIES_WITH_CITIES);
    const isFirstRun = useRef(true);

    const sectorOptions = SECTORS.map(item => ({
        label: item,
        value: item
    }));

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    const onSectorsChange = useCallback((checkedValue: Array<CheckboxValueType>) => {
        setSectors(checkedValue as string[]);
    }, []);

    const onCountryChange = useCallback((value: string) => {
        setCountry(value);
        setCity(undefined);
    }, []);

    const onYearBuiltChange = useCallback((value: any) => {
        if (value) {
            const startYear = (value[0] as Moment).year();
            const endYear = (value[1] as Moment).year();
            setStartYearBuilt(startYear);
            setEndYearBuilt(endYear);
        } else {
            setStartYearBuilt(undefined);
            setEndYearBuilt(undefined);
        }
    }, []);

    const onPriceChange = value => {
        setPriceMin(value[0]);
        setPriceMax(value[1]);
    };

    useEffect(() => {
        if (!country) {
            setCityList(_.flatten(_.values(COUNTIES_WITH_CITIES)));
        } else {
            const cities = COUNTIES_WITH_CITIES[country];
            setCityList(cities ? cities : []);
        }
    }, [country]);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }

        let filterParams: SearchTransactionDataModel = {
            sectors,
            country,
            city,
            buyerObjective,
            buyerCapInvComp,
            priceMin,
            priceMax
        }

        if (dateFrom && dateTo) {
            filterParams.dateFrom = dateFrom.toISOString();
            filterParams.dateTo = dateTo.toISOString();
        }

        if (startYearBuilt && endYearBuilt) {
            filterParams.startYearBuilt = startYearBuilt;
            filterParams.endYearBuilt = endYearBuilt;
        }

        filter(filterParams);
    }, [sectors, country, city, dateFrom, dateTo, buyerObjective, startYearBuilt, endYearBuilt, priceMin, priceMax, buyerCapInvComp]);

    return (
        <>
            <div className='search-name'>Filter our Transaction Data</div>
            <div className='search-address'>For market information click on the "market" tab</div>
            <Collapse
                bordered={false}
                defaultActiveKey={['1', '2', '3', '4']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="filter-collapse"
            >
                <Collapse.Panel header="Properties by sector" key="1">
                    <Checkbox.Group className='checkbox-group' options={sectorOptions} onChange={onSectorsChange} />
                </Collapse.Panel>
                <Collapse.Panel header="Properties by country, city" key="2">
                    <div className='d-flex flex-direction-row gap-20 pt-10'>
                        <Select
                            showSearch
                            allowClear
                            placeholder={'Country'}
                            optionLabelProp="label"
                            showArrow
                            className='search-countries'
                            getPopupContainer={handleContainer}
                            value={country}
                            onChange={onCountryChange}
                        >
                            {countryList.map((option, index) => (
                                <Option
                                    value={option}
                                    title={option}
                                    label={option}
                                    className="d-flex"
                                    key={index}
                                    placeholder={option}
                                >
                                    <div className="select-checkbox"></div>
                                    {option}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            showSearch
                            allowClear
                            placeholder={'City'}
                            optionLabelProp="label"
                            showArrow
                            className='search-cities'
                            getPopupContainer={handleContainer}
                            value={city}
                            onChange={(value: string) => setCity(value)}
                        >
                            {cityList.map((option, index) => (
                                <Option
                                    value={option}
                                    title={option}
                                    label={option}
                                    className="d-flex"
                                    key={index}
                                    placeholder={option}
                                >
                                    <div className="select-checkbox"></div>
                                    {option}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </Collapse.Panel>
                <Collapse.Panel header="Properties by date" key="3">
                    <div className='d-flex flex-direction-row gap-20 pt-10'>
                        <DatePicker
                            className='search-date'
                            placeholder='Search date from'
                            value={dateFrom}
                            onChange={(value: any) => setDateFrom(value)}
                        />
                        <DatePicker
                            className='search-date'
                            placeholder='Search date to'
                            defaultValue={dateTo}
                            onChange={(value: any) => setDateTo(value)}
                        />
                    </div>
                </Collapse.Panel>
                <Collapse.Panel header="Properties by others" key="4">
                    <div className='d-flex flex-direction-row gap-20 pt-10'>
                        <DatePicker.RangePicker
                            picker="year"
                            className='search-date'
                            placeholder={['Start year built', 'End year built']}
                            onChange={onYearBuiltChange}
                            defaultPickerValue={[moment().year(2000), moment()]}
                        />
                        <Select
                            showSearch
                            allowClear
                            placeholder={'Buyer Objective'}
                            optionLabelProp="label"
                            showArrow
                            className='search-list'
                            getPopupContainer={handleContainer}
                            value={buyerObjective}
                            onChange={(value: string) => setBuyerObjective(value)}
                        >
                            {BUYER_OBJECTIVES.map((option, index) => (
                                <Option
                                    value={option}
                                    title={option}
                                    label={option}
                                    className="d-flex"
                                    key={index}
                                    placeholder={option}
                                >
                                    <div className="select-checkbox"></div>
                                    {option}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className='d-flex flex-direction-column gap-20 pt-20'>
                        <Select
                            showSearch
                            allowClear
                            placeholder={'Buyer Cap Inv Comp'}
                            optionLabelProp="label"
                            showArrow
                            className='search-list'
                            getPopupContainer={handleContainer}
                            value={buyerCapInvComp}
                            onChange={(value: string) => setBuyerCapInvComp(value)}
                        >
                            {BUYER_CAP_INV_COMP.map((option, index) => (
                                <Option
                                    value={option}
                                    title={option}
                                    label={option}
                                    className="d-flex"
                                    key={index}
                                    placeholder={option}
                                >
                                    <div className="select-checkbox"></div>
                                    {option}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className='d-flex flex-direction-column gap-20'>
                        <div className='price-title'>Price range</div>
                        <Slider
                            className='price-slider'
                            onAfterChange={onPriceChange}
                            range
                            defaultValue={[0, 0]}
                            min={0}
                            max={5000000000}
                            tipFormatter={(value: any) => numberWithCommas(value)}
                        />
                        <div className='price-value'>
                            <span>{numberWithCommas(priceMin || 0)} psm</span>
                            <span className='max-price'>{numberWithCommas(priceMax || 0)} psm</span>
                        </div>
                    </div>
                </Collapse.Panel>
            </Collapse>
        </>
    );
};

export default FilterMenuPanel;
