import { FunctionComponent } from 'react';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../auth/authConfig';
import { useTranslation } from 'react-i18next';
import MainHeading from '../shared/MainHeading';
import ModalWrapper from '../wrapper/ModalWrapper';

interface SessionExpireModalProps {
    isModalOpen: boolean;
    onFinish: () => void;
}

const SessionExpireModal: FunctionComponent<SessionExpireModalProps> = ({
    isModalOpen,
    onFinish,
}: SessionExpireModalProps) => {
    const { instance } = useMsal(),
        { t } = useTranslation(['f', 'b']);

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    };

    return (
        <ModalWrapper
            className="idle-timer__modal"
            isCloseModalShown={false}
            closeModalHandler={onFinish}
            isFooterShown={false}
            isModalOpened={isModalOpen}
        >
            <div className="col-xs-12 idle-timer__modal-container">
                <div className="col-xs-12 d-flex justify-content-center mt-40">
                    <FontAwesomeIcon icon={faSync} className="idle-timer__icon" />
                </div>
                <div className="col-xs-12 d-flex flex-direction-column align-items-center">
                    <MainHeading addClasses="idle-timer__text-color mt-40" text={t('f:idle-timer.title')} />
                    <p className="idle-timer__text-color mt-2">{t('f:idle-timer.message')}</p>
                    <div>
                        <button className="home-page-header__btn mt-50 mb-50" onClick={handleLogin}>
                            {t('f:button.login')}
                        </button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};
export default SessionExpireModal;
