import Routes from '../../routes/routes';
import Header from './Header';
import Footer from './Footer';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { FunctionComponent } from 'react';

const Layout: FunctionComponent<any> = () => {
    const pagesWithoutFooter = ['', '/', '/property', '/coming-soon', '/doc-sign'];

    const pageWithoutHeader = ['/doc-sign', '/building-directory', '/transaction-data'];

    let location = useLocation();

    function hideFooter(): Boolean {
        return pagesWithoutFooter.some((page: any) => {
            return location.pathname === page || location.pathname.includes(page);
        });
    }

    function hideHeader(): Boolean {
        return pageWithoutHeader.some((page: any) => {
            return location.pathname === page || location.pathname.includes(page);
        });
    }

    return (
        <>
            {!hideHeader() && <Header />}
            <ToastContainer />
            <Routes />
            {!hideFooter() && <Footer />}
        </>
    );
};

export default Layout;
