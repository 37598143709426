import api from '../index';

const uploadFile = (options: any, data: any) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'text/plain',
        },
    };
    return api.post(options.action, data, config);
};
const FileUploadApi = {
    uploadFile,
};
export default FileUploadApi;
