import { Formik, Form, Field } from 'formik';
import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';
import FeatureState from '../../../../context/state/FeatureState';
import ResearchService from '../../../../services/ResearchService';
import { ResearchContactFormValidationSchema } from '../../../../validations/ResearchContactForm';

interface ContactFormModalProps {
    isModalOpened: boolean;
    setIsModalOpened: (boolean: boolean) => void;
}

const ContactFormModal: FunctionComponent<ContactFormModalProps> = ({
    isModalOpened,
    setIsModalOpened,
}: ContactFormModalProps) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState);
    const { id: propertyAssetId } = propertySummary;

    const hasErrors = (errors: any, name: string, touched: any) => {
        if (errors && errors[name] && touched[name]) {
            return <span className="error-text">{t(`${errors[name]}`, { name: t(`f:research.${name}`) })}</span>;
        }
    };

    const handleOnCancel = useCallback(() => {
        setIsModalOpened(false);
    }, [setIsModalOpened]);

    return (
        <ModalWrapper
            title={t('f:research.contactForAdvisory')}
            isModalOpened={isModalOpened}
            isFooterShown={false}
            closeModalHandler={handleOnCancel}
            isCloseModalShown={true}
        >
            <Formik
                initialValues={{
                    email: '',
                    subject: '',
                    message: '',
                }}
                validationSchema={ResearchContactFormValidationSchema}
                enableReinitialize
                onSubmit={async (values: any, { resetForm }) => {
                    await ResearchService.sendResearchContactForm(`${propertyAssetId}`, values);
                    resetForm();
                    setIsModalOpened(false);
                }}
            >
                {({ resetForm, errors, touched, validateForm, values }) => (
                    <Form>
                        <div className="col-md-8 form-group">
                            <label htmlFor="email">{t('f:research.email')}</label>
                            <Field id="email" name="email" placeholder={t('f:research.emailPlaceholder')} />
                            {hasErrors(errors, 'email', touched)}
                        </div>
                        <div className="col-md-8 form-group">
                            <label htmlFor="subject">{t('f:research.subject')}</label>
                            <Field id="subject" name="subject" placeholder={t('f:research.subject')} />
                            {hasErrors(errors, 'subject', touched)}
                        </div>
                        <div className="col-md-12 form-group">
                            <label htmlFor="message">{t('f:research.message')}</label>
                            <Field as="textarea" id="message" name="message" placeholder={t('f:research.message')} />
                            {hasErrors(errors, 'message', touched)}
                        </div>
                        <div className="col-md-12 m-auto mt-50">
                            <button
                                className="button-main button-primary d-block m-auto"
                                type="submit"
                                onClick={validateForm}
                            >
                                {t('f:button.send')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    );
};

export default ContactFormModal;
