import api from '../../index';

const fetchCompanies = (pageIdx: number, itemsPerPage: number) => {
    return api.get(`admin/company?pageIdx=${pageIdx}&itemsPerPage=${itemsPerPage}`);
};

const fetchCompanyUsers = (companyId: string, pageIdx: number, itemsPerPage: number) => {
    return api.get(`/admin/company/${companyId}/users?pageIdx=${pageIdx}&itemsPerPage=${itemsPerPage}`);
};

const fetchDetailCompany = (companyId: string) => {
    return api.get(`/admin/company/${companyId}`);
};

const fetchAllUsersFromCompany = (companyId: string) => {
    return api.get(`/company/${companyId}/users`);
};

const activateCompany = (companyId: string) => {
    return api.post(`/admin/company/${companyId}/activate`);
};

const deactivateCompany = (companyId: string) => {
    return api.post(`/admin/company/${companyId}/deactivate`);
};

const verifyCompany = (companyId: string) => {
    return api.post(`/admin/company/${companyId}/verify`);
};

const unverifyCompany = (companyId: string) => {
    return api.post(`/admin/company/${companyId}/unverify`);
};

const createCompany = (company: any) => {
    return api.post(`/admin/company`, company);
};

const updateCompany = (company: any) => {
    return api.put(`/admin/company`, company);
};

const removeUsersFromCompany = (companyId: string, userIds: []) => {
    return api.post(`/admin/company/${companyId}/remove-users`, [...userIds]);
};

const addUsersToCompany = (companyId: string, userIds: []) => {
    return api.post(`/admin/company/${companyId}/users`, [...userIds]);
};

const searchUsersNotInCompany = (companyId: string, keyword: string) => {
    return api.get(`/admin/company/${companyId}/users-removed-from-company?keyword=${keyword}`);
};

const assignRoleToUserInCompany = (companyId, userId, role) => {
    return api.post(`/admin/company/${companyId}/users/${userId}/roles/${role}`);
};

const addUserToCompany = (companyId, user) => {
    return api.post(`/admin/company/adduser/${companyId}`, user);
};

const addImageOfCompany = (options: any, data: any) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'text/plain',
        },
    };
    return api.post(options.action, data, config);
};

const CompanyApi = {
    fetchCompanies,
    fetchCompanyUsers,
    fetchDetailCompany,
    activateCompany,
    deactivateCompany,
    verifyCompany,
    unverifyCompany,
    createCompany,
    addImageOfCompany,
    updateCompany,
    removeUsersFromCompany,
    searchUsersNotInCompany,
    addUsersToCompany,
    assignRoleToUserInCompany,
    addUserToCompany,
    fetchAllUsersFromCompany,
};
export default CompanyApi;
