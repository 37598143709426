import { FunctionComponent, useCallback, useContext } from 'react';
import ListOpportunityFooterBtns from '../partials/ListOpportunityFooterBtns';
import InvestorsView from '../partials/InvestorsView';
import Deadline from '../../../../components/shared/Deadline';
import { useTranslation } from 'react-i18next';
import MainHeading from '../../../../components/shared/MainHeading';
import LOWizardState from '../../../../context/state/LOWizardState';
import { LoWizardStepNames } from '../../../../constants/wizardSteps';
import { PROPERTY_TABS } from '../../../../constants/propertyTab';
import FeatureState from '../../../../context/state/FeatureState';

const DealTimelineAndInvestors: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        { changeDestination } = useContext(LOWizardState);

    const nextHandler = useCallback(() => {
        changeDestination(LoWizardStepNames.LEGAL_AKNOWLEDGEMENT);
    }, [changeDestination]);

    const returnToPrev = useCallback(() => {
        changeDestination(LoWizardStepNames.PRIVACY);
    }, [changeDestination]);

    return (
        <div className="wizard-container-steps">
            <MainHeading text={t(`f:transaction.dealTimeline`)} />
            <Deadline showChartScale={false} showTreeDotsMenu={false} />
            <MainHeading text={t(`f:teams.investorList`)} addClasses="mt-50" />
            <div className="col-xs-12-clear mb-120">
                <InvestorsView />
            </div>

            <ListOpportunityFooterBtns
                cancelUrl={`/property/${externalId}#${PROPERTY_TABS.PROPERTY_SUMMARY.name}`}
                isNotFirst={true}
                isNotFinish={false}
                onNextHandler={nextHandler}
                returnToPreviousPage={returnToPrev}
            />
        </div>
    );
};
export default DealTimelineAndInvestors;
