import { faPen } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { ChangeEventHandler, useCallback, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CreateDataForEmptySection from '../../../components/shared/CreateDataForEmptySection';
import TabsContentNavigation from '../../../components/shared/TabsContentNavigation';
import FeatureState from '../../../context/state/FeatureState';
import useBoolean from '../../../hooks/use-boolean';
import { QuestionAndAnswer } from '../../../interfaces/faq/Question';
import PropertyFaq from '../../../services/PropertyFaq';
import AddEditQuestions from '../components/faqComponents/AddEditQuestions';
import AddEditTopic from '../components/faqComponents/AddEditTopic';
import FaqPublic from '../components/faqComponents/FaqPublic';
import AddEditQuestionToExistingTopic from './faqComponents/AddEditQuestionToExistingTopic';
import FaqQuestions from './faqComponents/FaqQuestions';
import FaqSearchInput from './faqComponents/partials/FaqSearchInput';

enum Navigation {
    public = 'PUBLIC',
    private = 'PRIVATE',
}

const Faq = (props: any) => {
    const [tabs, setTabs] = useState<Array<any>>([]);
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
    const [isModalQuestionOpened, setIsModalQuestionOpened] = useState<boolean>(false);
    const [isAddEditTOExistingTopicModalOpened, setIsAddEditTOExistingTopicModalOpenedActions] = useBoolean();
    const [isEditModalOpened, setIsEditModalOpened] = useState<boolean>(false);
    const [topicId, setTopicId] = useState<string>('');
    const [allQuestionComponents, setAllQuestionComponents] = useState<Array<any>>([]);
    const [topicName, setTopicName] = useState<string>('');
    const [editableQuestion, setEditableQuestion] = useState<any>({});
    const [allQuestionForResearch, setAllQuestionForResearch] = useState<Array<QuestionAndAnswer>>([]),
        [isLoad, setIsLoad] = useBoolean(false),
        { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        { getFeature } = useContext(FeatureState),
        [activComponent, setActivComponent] = useState<string>(Navigation.public);

    const handleChangeActivComponent = (value: string) => setActivComponent(value);

    const radioBtnsDate = [
        {
            handler: handleChangeActivComponent,
            text: t(`f:faq.navigation.public`),
            value: Navigation.public,
        },

        // {
        //     handler: handleChangeActivComponent,
        //     text: t(`f:faq.navigation.private`),
        //     value: Navigation.private,
        // },
    ];

    const openEditModalHandler = (topicName: string) => {
        setTopicName(topicName);
        setIsEditModalOpened(true);
    };

    const fetchAllTopics = useCallback(async () => {
        try {
            const topics = await PropertyFaq.fetchFaqTopics(`${propertyAssetId}`);
            setTabs(
                topics.map((item) => ({
                    id: item.id,
                    icon: faPen,
                    name: item.name,
                }))
            );
            setTopicId(topics.length ? topics[0].id : null);
            setIsLoad.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId]);

    const fetchAllQuestionsForResearch = useCallback(async () => {
        if (tabs.length) {
            try {
                const questionsForResearch = await PropertyFaq.fetchAllTopicsQuestions(`${propertyAssetId}`, '');
                setAllQuestionForResearch(questionsForResearch);
                setQuestionsForTopicView(questionsForResearch);
                setIsLoad.setTrue();
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        }
    }, [propertyAssetId, tabs]);

    const setQuestionsForTopicView = useCallback(
        (array: Array<QuestionAndAnswer>) => {
            const questionPerTab = _.groupBy(array, 'topic.id');
            const questionComponentPerTopic = tabs.map((faq: any) => ({
                topicId: faq.id,
                component: (
                    <FaqQuestions
                        topicId={faq.id}
                        faqName={faq.name}
                        editableQuestion={editableQuestion}
                        setIsModalOpened={setIsModalOpened}
                        isModalOpened={isModalOpened}
                        setIsModalQuestionOpened={setIsModalQuestionOpened}
                        setEditableQuestion={setEditableQuestion}
                        fetchAssetFaq={fetchAllQuestionsForResearch}
                        questionForView={questionPerTab[faq.id] || []}
                        setAddQuestionModalOpened={setIsAddEditTOExistingTopicModalOpenedActions}
                    />
                ),
            }));

            setAllQuestionComponents(questionComponentPerTopic);
        },
        [tabs]
    );

    const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            const filteredQuestions = allQuestionForResearch.filter((question) => {
                return question.question.toUpperCase().includes(event.currentTarget.value.toUpperCase()) ||
                    question.answer.toUpperCase().includes(event.currentTarget.value.toUpperCase())
                    ? question
                    : '';
            });

            setQuestionsForTopicView(filteredQuestions);
        },
        [allQuestionForResearch, setQuestionsForTopicView]
    );
    const deleteTopicHandler = useCallback(async (topicId: string) => {
        if (topicId) {
            setIsEditModalOpened(false);
            await PropertyFaq.deleteTopic(`${propertyAssetId}`, topicId);
            fetchAllTopics();
        }
    }, [propertyAssetId]);

    const displayContent = useCallback(() => {
        switch (activComponent) {
            case Navigation.private:
                return <></>;

            default:
                return (
                    <FaqPublic
                        tabs={tabs}
                        questionComponents={allQuestionComponents}
                        setTopicId={setTopicId}
                        isLoad={isLoad}
                        setIsModalOpened={setIsModalOpened}
                        topicName={topicName}
                        deleteTopicHandler={deleteTopicHandler}
                        openEditModalHandler={openEditModalHandler}
                        topicId={topicId}
                    />
                );
        }
    }, [activComponent, deleteTopicHandler, isLoad, tabs, topicName, allQuestionComponents, topicId]);

    const createTopicWhenEmpty = () => {
        setIsModalOpened(true);
    };

    useEffect(() => {
        fetchAllTopics();
    }, [fetchAllTopics]);

    useEffect(() => {
        fetchAllQuestionsForResearch();
    }, [fetchAllQuestionsForResearch]);

    return (
        <div className="faq">
            <div className="col-xs-12 mb-50">
                <TabsContentNavigation
                    radioBtns={radioBtnsDate}
                    activComponent={activComponent}
                    element={
                        <div className="d-flex gap-20 align-items-center justify-content-end mt-5 pr-20">
                            <div className={`search-form-screen--sm ${!isMobile ? "pr-50" : ""}`}>
                                <FaqSearchInput handleSearchInputChange={handleSearchInputChange} />
                            </div>
                        </div>
                    }
                />
            </div>

            {displayContent()}
            {isLoad && (
                <div className="col-xs-12 mt-20">
                    {getFeature('FaqEdit') && (
                        <CreateDataForEmptySection
                            addClassToContainer={isMobile ? "topic-empty-section" : "mx-50 topic-empty-section"}
                            handler={createTopicWhenEmpty}
                            text={t(`f:button.btnCreateFaq`)}
                            mainText={''}
                        />
                    )}
                </div>
            )}

            <AddEditQuestions
                propertyAssetId={`${propertyAssetId}`}
                fetchAssetFaq={fetchAllTopics}
                setIsModalOpened={setIsModalOpened}
                isModalOpened={isModalOpened}
                editableQuestion={editableQuestion}
                setEditableQuestion={setEditableQuestion}
                topicId={topicId}
            />

            <AddEditTopic
                isEditModalOpened={isEditModalOpened}
                setIsEditModalOpened={setIsEditModalOpened}
                topicName={topicName}
                topicId={topicId}
                fetchTopics={fetchAllTopics}
                deleteTopicHandler={deleteTopicHandler}
            />

            <AddEditQuestionToExistingTopic
                fetchAssetFaq={fetchAllQuestionsForResearch}
                propertyAssetId={`${propertyAssetId}`}
                isModalQuestionOpened={isAddEditTOExistingTopicModalOpened}
                setIsAddEditTOExistingTopicModalOpenedActions={setIsAddEditTOExistingTopicModalOpenedActions}
                editableQuestion={editableQuestion}
                topicId={topicId}
            />
        </div>
    );
};

export default Faq;
