import SchedulerApi from '../api/routes/ScheduleApi';

const createScheduleType = async (propertyAssetId: string, data: any) => {
    try {
        let response = await SchedulerApi.createScheduleType(propertyAssetId, data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const fetchScheduleType = async (propertyAssetId: string, eventTypeId: string) => {
    try {
        let response = await SchedulerApi.fetchScheduleType(propertyAssetId, eventTypeId);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const updateScheduleType = async (propertyAssetId: string, eventTypeId: string, data: any) => {
    try {
        let response = await SchedulerApi.updateScheduleType(propertyAssetId, eventTypeId, data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const fetchAllTeamUsers = async (propertyAssetId: string) => {
    try {
        let response = await SchedulerApi.fetchAllTeamUsers(propertyAssetId);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const fetchAllEventTypes = async (propertyAssetId: string) => {
    try {
        let response = await SchedulerApi.fetchAllEventTypes(propertyAssetId);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const deleteScheduleEvent = async (propertyAssetId: string, eventTypeId: string) => {
    try {
        let response = await SchedulerApi.deleteScheduleEvent(propertyAssetId, eventTypeId);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const makeAppointment = async (propertyAssetId: string, body: any) => {
    try {
        let response = await SchedulerApi.makeAppointment(propertyAssetId, body);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const fetchAllEvents = async (propertyAssetId: string) => {
    try {
        let response = await SchedulerApi.fetchAllEvents(propertyAssetId);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};
const SchedulerService = {
    createScheduleType,
    fetchScheduleType,
    fetchAllEventTypes,
    makeAppointment,
    fetchAllEvents,
    deleteScheduleEvent,
    fetchAllTeamUsers,
    updateScheduleType,
};
export default SchedulerService;
