import * as Yup from 'yup';

export const ValidateBarChartSchema = Yup.object().shape({
    category: Yup.array().of(Yup.string().required('Categoty is Required')),
    bars: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required('Please value name'),

                lineValues: Yup.array().of(
                    Yup.object().shape({
                        value: Yup.number().typeError('Must be a number').required('Please value size'),
                    })
                ),
            })
        )
        .min(1, 'categores is >= 1'),
});
