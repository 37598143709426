import { useState } from 'react';
import NumberFormat from 'react-number-format';

const MyNumberInput = (props) => {
    const [value, setValue] = useState<string>('');

    return (
        <NumberFormat
            placeholder={props.placeholder}
            isNumericString={true}
            name={props.name}
            thousandSeparator={true}
            formNoValidate={true}
            value={value}
            onClick={props.validateField}
            onValueChange={(vals) => setValue(vals.formattedValue)}
            {...props}
        />
    );
};

export default MyNumberInput;
