import { Form } from 'antd';
import { Formik } from 'formik';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../components/shared/MainButton';
import MainLoader from '../../../components/shared/MainLoader';
import { CompanyDetailsI, PropertyDetailsI } from '../../../interfaces/user/AccountSettings';
import CheckIsDirty from './CheckIsDirty';

interface FormikFormProps {
    isLoaded: boolean;
    initVal: CompanyDetailsI | PropertyDetailsI;
    handleSubmit: (val: any) => void;
    displayFields: (
        values: any,
        errors: any,
        touched: any,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    ) => JSX.Element[];
}

const FormikForm: FunctionComponent<FormikFormProps> = ({ initVal, isLoaded, handleSubmit, displayFields }) => {
    const { t } = useTranslation('f');

    return (
        <>
            {isLoaded ? (
                <Formik initialValues={initVal} enableReinitialize validateOnMount onSubmit={handleSubmit}>
                    {({ values, setFieldValue, errors, touched, dirty, submitForm }) => (
                        <Form>
                            <div className="d-flex flex-direction-column">
                                {displayFields(values, errors, touched, setFieldValue)}
                            </div>
                            <div className="col-xs-12 mt-30 mb-50">
                                <div className="col-xs-3">
                                    <MainButton
                                        type="button"
                                        layout="primary"
                                        size="main"
                                        buttonText={t('f:button.save&Update')}
                                        buttonHandler={submitForm}
                                    />
                                    <CheckIsDirty isDirty={dirty} text={t('f:button.save')} />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <MainLoader type="regular" />
            )}
        </>
    );
};
export default FormikForm;
