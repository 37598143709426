import { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import Markdown from 'markdown-to-jsx';
import TreeDotsComponent from './TreeDotsComponent';
import ModalConfirmDelete from '../../../../../components/shared/ModalConfirmDelete';
import useBoolean from '../../../../../hooks/use-boolean';
import { useTranslation } from 'react-i18next';
import FeatureState from '../../../../../context/state/FeatureState';

const MultipleTextImageTemplate = (props: any) => {
    const { t } = useTranslation(['f', 'b']),
        [isThreeDotClicked, setIsThreeDotClicked] = useState<boolean>(false),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        [isModalOpened, setIsModalOpenedActions] = useBoolean(false);
    const history = useHistory();
    const templateContent = props.template?.content;
    const data = templateContent ? JSON.parse(templateContent) : null;
    const title = data?.title;
    const content = data?.content;

    const templateEditHandler = () => {
        history.push(
            `/property/${externalId}/template/${props.pageType}/add-content/${props.template.pageSectionType}/${props.template.id}`
        );
    };

    const deleteHandler = useCallback(() => {
        props.onRemove(props.template.id);
        setIsModalOpenedActions.setFalse();
    }, [setIsModalOpenedActions, props]);

    return title && content ? (
        <div className="row-clear my-50 property-summary-multiple-text-image__wrap mb-100">
            {props.isEditMode && (
                <div className="col-xs-12-clear">
                    <TreeDotsComponent
                        isThreeDotClicked={isThreeDotClicked}
                        setIsThreeDotClicked={setIsThreeDotClicked}
                        setIsModalOpened={setIsModalOpenedActions}
                        templateEditHandler={templateEditHandler}
                    />
                    <ModalConfirmDelete
                        text={t('f:property.confirmSectionDelete')}
                        isModalOpened={isModalOpened}
                        setIsModalOpenedActions={setIsModalOpenedActions}
                        handleFinish={deleteHandler}
                    />
                </div>
            )}
            {title && title.length > 0 && <h1 className="heading ml-20">{title}</h1>}
            <div className="property-summary-multiple-text-image__list d-flex">
                {content.length > 0 &&
                    content.map((item: string, index: number) => {
                        return (
                            <div className="col-xs-12 col-md-6 col-lg-4 col-ld-4" key={index}>
                                <div className="main-text property-summary-multiple-text-image__single">
                                    <Markdown options={{ wrapper: 'div' }}>{item}</Markdown>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    ) : null;
};

export default MultipleTextImageTemplate;
