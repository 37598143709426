import PropertyAssetCashFlow from '../api/routes/PropertyAssetCashFlow';
import { downloadFile } from '../utils/file'

const fetchAssetCashFlow = async (id: string) => {
    const { data } = await PropertyAssetCashFlow.fetchAssetCashFlow(id);
    return data;
};

const updateAssetCashFlowRow = async (id: string, data: any) => {
    const response = await PropertyAssetCashFlow.updateAssetCashFlowRow(id, data);
    return response;
};
const removeCashflowById = async (propertyAssetId: string, cashflowId: string) => {
    const response = await PropertyAssetCashFlow.removeCashflowById(propertyAssetId, cashflowId);
    return response;
};

const addRecord = async (id: string, data: any) => {
    const response = await PropertyAssetCashFlow.addRecord(id, data);
    return response;
};

const exportCashflow = async (id: string) => {
    const response = await PropertyAssetCashFlow.exportCashFlow(id);
    downloadFile(response, 'Rent Roll.xlsx');
};

const templateUrl = (): string => {
    return process.env.REACT_APP_API_URL + `/propertyassetcashflow/template`;
};

const ChasFlowService = {
    fetchAssetCashFlow,
    updateAssetCashFlowRow,
    addRecord,
    exportCashflow,
    templateUrl,
    removeCashflowById,
};
export default ChasFlowService;
