import { FunctionComponent } from 'react';

interface MainTableHeadCellProps {
    text: string;
    isActions?: boolean;
    isFirst?: boolean;
}

const MainTableHeadCell: FunctionComponent<MainTableHeadCellProps> = ({
    text,
    isActions,
    isFirst,
}: MainTableHeadCellProps) => (
    <div
        className={`d-flex MuiDataGrid-columnHeaderTitle investors-table__row-single ${
            isActions ? 'investors-table__row-single--actions-header' : ''
        }`}
    >
        <div className={isFirst ? 'investors-table__separate-17' : 'investors-table__h-line'} />
        {text}
    </div>
);
export default MainTableHeadCell;
