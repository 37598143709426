import { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Tabs, Checkbox, Popconfirm } from 'antd';
import PagePermission from '../../../services/Admin/PagePermission';
import ModalWrapper from '../../../components/wrapper/ModalWrapper';

const { TabPane } = Tabs;

interface Props {
    onCancel: () => void;
    companyId: number;
    userId: string;
    isModalOpen: boolean;
}

const AddUserPermission: FunctionComponent<Props> = (props) => {
    const [userPermissions, setUserPermissions] = useState<Array<any>>([]);

    const Cleanup = () => {
        props.onCancel();
    };

    async function fetchUserPermission() {
        if (props.companyId <= 0 || !props.isModalOpen || props.userId == null) {
            return;
        }
        setUserPermissions(await PagePermission.fetchUserPermissions(props.userId));
    }

    async function selectAllCountriesInPageType(page) {
        let isCheckboxChecked: boolean = page.countryPermissions.filter((x) => x.allow == false).length == 0;

        let countryIds = page.countryPermissions.map((x) => x.countryId);

        await PagePermission.updateUserPermissions(props.userId, page.pageTypeId, !isCheckboxChecked, countryIds);

        setUserPermissions(await PagePermission.fetchUserPermissions(props.userId));
    }

    async function selectOneCountryInPageType(page, countryId) {
        let isCheckboxChecked: boolean = page.countryPermissions.filter((x) => x.countryId == countryId)[0].allow;

        await PagePermission.updateUserPermissions(props.userId, page.pageTypeId, !isCheckboxChecked, [countryId]);

        setUserPermissions(await PagePermission.fetchUserPermissions(props.userId));
    }

    useEffect(() => {
        fetchUserPermission();
    }, [props.isModalOpen]);

    return (
        <ModalWrapper
            isCloseModalShown={true}
            isModalOpened={props.isModalOpen}
            destroyOnClose={true}
            closeModalHandler={() => {
                Cleanup();
            }}
            isFooterShown={false}
            title="User permissions"
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="Virgate" key="1">
                    <Row>
                        <Col span={20}></Col>
                        <Col span={4}>
                            <Checkbox disabled={true} checked={true} style={{ textTransform: 'none' }}>
                                Full Access
                            </Checkbox>
                        </Col>
                    </Row>
                    {userPermissions.map((page) => (
                        <Row key={`pageType_${page.pageTypeId}`}>
                            <Col span={24}>{page.pageTypeName}</Col>
                            <Col span={24}></Col>
                            <Col span={24}></Col>
                            <Col span={24}></Col>
                            <Col span={24}></Col>
                            <Col span={24}>
                                <Popconfirm title="Are you sure ?" onConfirm={() => selectAllCountriesInPageType(page)}>
                                    <Checkbox
                                        checked={page.countryPermissions.filter((x) => x.allow == false).length == 0}
                                    >
                                        Select All
                                    </Checkbox>
                                </Popconfirm>
                            </Col>
                            {page.countryPermissions.map((country) => (
                                <Col key={`country_${page.pageTypeId}_${country.countryId}`} span={6}>
                                    <Popconfirm
                                        title="Are you sure ?"
                                        onConfirm={() => selectOneCountryInPageType(page, country.countryId)}
                                    >
                                        <Checkbox style={{ textTransform: 'none' }} checked={country.allow}>
                                            {country.countryName}
                                        </Checkbox>
                                    </Popconfirm>
                                </Col>
                            ))}
                            <Col span={24}></Col>
                            <Col span={24}></Col>
                            <Col span={24}></Col>
                            <Col span={24}></Col>
                        </Row>
                    ))}
                </TabPane>
            </Tabs>
        </ModalWrapper>
    );
};

export default AddUserPermission;
