import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import querystring from 'querystring';
import ExecuteNdaService from '../../services/ExecuteNdaService';
import { useTranslation } from 'react-i18next';

const DocSignFrame: FunctionComponent = (props) => {
    const { t } = useTranslation(['f', 'b']),
        location = useLocation();

    const finish = async (params: string | string[]) => {
        try {
            const id = await ExecuteNdaService.finishPowerForm({ envelopeId: params });
            toast(t(`f:docSignFrame.success`));
            window.top.location.href = `${window.top.location.origin}/property/${id}`;
        } catch (error) {
            toast(error.response.data.errorMessage);
        }
    };

    useEffect(() => {
        let paramsObj = querystring.parse(location.search);

        if (paramsObj && paramsObj.hasOwnProperty('?env')) {
            finish(paramsObj['?env']);
        } else {
            toast(t(`f:docSignFrame.errorParams`));
        }
    }, []);

    return <></>;
};
export default DocSignFrame;
