import ProjectTeamApi from '../api/routes/ProjectTeamApi';

const fetchInvestors = async (propertyAssetId: string) => {
    const { data } = await ProjectTeamApi.fetchInvestors(propertyAssetId);
    return data;
};

const inviteInvestor = async (propertyAssetId: string, body: any) => {
    const { data } = await ProjectTeamApi.inviteInvestor(propertyAssetId, body);
    return data;
};

const addInvestors = async (propertyAssetId: string, companyId: string) => {
    const { data } = await ProjectTeamApi.addInvestors(propertyAssetId, companyId);
    return data;
};

const addComment = async (propertyAssetId: string, companyId: string, value: string) => {
    const { data } = await ProjectTeamApi.addComment(propertyAssetId, companyId, value);
    return data;
};

const fetchTeamRoles = async () => {
    const { data } = await ProjectTeamApi.fetchTeamRoles();
    return data;
};
const fetchProjectTeamRoles = async () => {
    const { data } = await ProjectTeamApi.fetchProjectTeamRoles();
    return data;
};

const inviteUser = async (propertyAssetId: string, body: any) => {
    const { data } = await ProjectTeamApi.inviteUser(propertyAssetId, body);
    return data;
};

const inviteUserToProjectTeam = async (propertyAssetId: string, body: any) => {
    const { data } = await ProjectTeamApi.inviteUserToProjectTeam(propertyAssetId, body);
    return data;
};

const fetchMyteam = async (propertyAssetId: string) => {
    const { data } = await ProjectTeamApi.fetchMyteam(propertyAssetId);
    return data;
};

const fetchProjectTeam = async (propertyAssetId: string) => {
    const { data } = await ProjectTeamApi.fetchProjectTeam(propertyAssetId);
    return data;
};

const addProjectTeamMember = async (propertyAssetId: string, body: any) => {
    const { data } = await ProjectTeamApi.addProjectTeamMember(propertyAssetId, body);
    return data;
};

const addOrUpdateTeamMember = async (propertyAssetId: string, body: any) => {
    const { data } = await ProjectTeamApi.addOrUpdateTeamMember(propertyAssetId, body);
    return data;
};

const hideProjectTeam = async (propertyAssetId: string, userId: string, isHidden: any) => {
    const { data } = await ProjectTeamApi.hideProjectTeam(propertyAssetId, userId, isHidden);
    return data;
};

const isProjectTeamHidden = async (propertyAssetId: string) => {
    const { data } = await ProjectTeamApi.isProjectTeamHidden(propertyAssetId);
    return data;
};

const deleteTeamMember = async (propertyAssetId: string, userId: string) => {
    const { data } = await ProjectTeamApi.deleteTeamMember(propertyAssetId, userId);
    return data;
};

const fetchCompanies = async (propertyAssetId: string) => {
    const { data } = await ProjectTeamApi.fetchCompanies(propertyAssetId);
    return data;
};

const createInvForCompany = async (propertyAssetId: string, body: any) => {
    const { data } = await ProjectTeamApi.createInvForCompany(propertyAssetId, body);
    return data;
};

const featchIsHiddenFromUser = async (propertyAssetId: string, userId: string) => {
    const { data } = await ProjectTeamApi.featchIsHiddenFromUser(propertyAssetId, userId);
    return data;
};

const editIsHiddenForUser = async (propertyAssetId: string, userId: string, hide: boolean) => {
    const { data } = await ProjectTeamApi.editIsHiddenForUser(propertyAssetId, userId, hide);
    return data;
};

const hideNoSeller = async (propertyAssetId: string, status: boolean) => {
    const { data } = await ProjectTeamApi.hideNoSeller(propertyAssetId, status);
    return data;
};

const fetchAllCompaniesInProjectTeam = async (propertyAssetId: string) => {
    const { data } = await ProjectTeamApi.fetchAllCompaniesInProjectTeam(propertyAssetId);
    return data;
};

const fetchAvailableCompanies = async (propertyAssetId: string) => {
    const { data } = await ProjectTeamApi.fetchAvailableCompanies(propertyAssetId);
    return data;
};

const ProjectTeamService = {
    fetchInvestors,
    fetchAvailableCompanies,
    hideNoSeller,
    addComment,
    fetchTeamRoles,
    featchIsHiddenFromUser,
    fetchAllCompaniesInProjectTeam,
    fetchProjectTeamRoles,
    inviteUser,
    editIsHiddenForUser,
    createInvForCompany,
    inviteInvestor,
    fetchCompanies,
    addInvestors,
    fetchMyteam,
    inviteUserToProjectTeam,
    fetchProjectTeam,
    addOrUpdateTeamMember,
    hideProjectTeam,
    isProjectTeamHidden,
    deleteTeamMember,
    addProjectTeamMember,
};
export default ProjectTeamService;
