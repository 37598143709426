import * as Yup from 'yup';

export const validateMarketingTimeline = Yup.object().shape({
    marketingStages: Yup.array()
        .of(
            Yup.object().shape({
                stage: Yup.number().required('Name is stage'),
                text: Yup.string().required('Please enter text'),
                startDate: Yup.string().required('Please enter start date'),
                closeDate: Yup.string().required('Please enter close date'),
            })
        )
        .min(1, 'marketingStages is >= 1'),
});
