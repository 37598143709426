import { FunctionComponent, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DirtyWizardState from '../../../context/state/DirtyWizardState';

interface CheckIsDirtyProps {
    isDirty: boolean;
    text: string;
}
const CheckIsDirty: FunctionComponent<CheckIsDirtyProps> = ({ isDirty, text }) => {
    const { t } = useTranslation('f'),
        { isDirtyActions } = useContext(DirtyWizardState);

    useEffect(() => {
        isDirty ? isDirtyActions.setTrue() : isDirtyActions.setFalse();
    }, [isDirty]);
    return <>{isDirty ? <span className="error-text-absolute">{text}</span> : null}</>;
};
export default CheckIsDirty;
