import { FunctionComponent, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MainLoader from '../../components/shared/MainLoader';
import RouteParams from '../../interfaces/general/Route';
import TradeProbabilityState from '../../context/state/TradeProbabilityState';
import TradeProbabilityTable from './components/TradeProbabilityTable';

const TradeProbabilityDetails: FunctionComponent = () => {
    const { id } = useParams<RouteParams>(),
        { tradeProbability, isLoading,
            fetchTradeProbability, setTradeProbability } = useContext(TradeProbabilityState);

    useEffect(() => {
        if (id) {
            fetchTradeProbability(id);
        }
        else {
            setTradeProbability(null);
        }
    }, [id]);

    if (!tradeProbability) {
        return null;
    }

    if (isLoading) {
        return (
            <div className="container mb-100">
                <MainLoader type="regular" mb="mb-100" mt="mt-100" />
            </div>
        );
    }

    return (
        <div className="container__data">
            <div className="mt-40 property-tabs__styled">
                <div className="research dashboard-body mx-50">
                    <TradeProbabilityTable />
                </div>
            </div>
        </div>
    );
};
export default TradeProbabilityDetails;
