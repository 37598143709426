import MapApi from '../api/routes/MapApi';

const fetchLocations = async (keyword: string, countryCodes: Array<string>, pageIdx: number, itemsPerPage: number) => {
    try {
        const { data } = await MapApi.fetchLocations(keyword, countryCodes, pageIdx, itemsPerPage);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const reverse = async (lat: number, lon: number) => {
    const { data } = await MapApi.reverse(lat, lon);
    return data;
}

const MapService = {
    fetchLocations,
    reverse
};
export default MapService;
