export interface CashFlowI {
    cashFlowId: string;
    unitReference: string;
    name: string;
    unitStatus: string;
    area: number;
    leaseStartDate: string;
    leaseEndDate: string;
    passingRent: number;
    amount: number;
    leaseType: string;
    customLeaseCategory: string;
    nextReviewDate: string;
    netMarketRent: number;
    recoveries: number;
    customReviewType: string;
    reviewType: string;
    percentOfTurnover: number;
    turnover: number;
    breakPoint: number;
    monthsRentFree: string;
    inventives: string;
    renewalProbability: number;
    marketLeaseLengthInYears: number;
    downtimeInMonths: number;
    rentFreeMonths: number;
    tenantIncentive: number;
    leasingCommission: number;
    floor: number;
}

export const CashFlowInit: CashFlowI = {
    cashFlowId: '',
    unitReference: '',
    name: '',
    unitStatus: '',
    area: 0,
    leaseStartDate: '',
    leaseEndDate: '',
    passingRent: 0,
    amount: 0,
    leaseType: '',
    customLeaseCategory: '',
    nextReviewDate: '',
    netMarketRent: 0,
    recoveries: 0,
    customReviewType: '',
    reviewType: '',
    percentOfTurnover: 0,
    turnover: 0,
    breakPoint: 0,
    monthsRentFree: '',
    inventives: '',
    renewalProbability: 0,
    marketLeaseLengthInYears: 0,
    downtimeInMonths: 0,
    rentFreeMonths: 0,
    tenantIncentive: 0,
    leasingCommission: 0,
    floor: 0,
};

export const fieldsToDisplay = [
    'name',
    'area',
    'leaseStartDate',
    'leaseEndDate',
    'passingRent',
    'amount',
    'floor',
    'customReviewType',
    'leaseType',
    'monthsRentFree',
    'unitReference',
    'customLeaseCategory',
    'unitStatus',
    'recoveries',
    'tenantIncentive',
];
