import { FunctionComponent } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

interface MainTextInputProps {
    errors: any;
    touched: any;
    label?: string;
    name: string;
    placeholder: string;
    errorFieldName?: string;
    disabled?: boolean;
}

const MainTextInput: FunctionComponent<MainTextInputProps> = ({
    label,
    errors,
    touched,
    placeholder,
    name,
    errorFieldName,
    disabled,
}: MainTextInputProps) => {
    const { t } = useTranslation(['f', 'b']);

    return (
        <div className="col-xs-12 form-group">
            {label && (
                <label style={{ fontSize: '10px' }} htmlFor="listName">
                    {label}
                </label>
            )}
            <Field id={name} name={name} placeholder={placeholder} disabled={disabled} />
            {errors[name] && touched[name] ? (
                <span className="error-text-absolute">{t(errors[name], { name: errorFieldName })}</span>
            ) : null}
        </div>
    );
};

export default MainTextInput;
