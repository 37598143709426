import { FunctionComponent } from 'react';
import BuildingInfoGallery from './BuildingInfoGallery';
import BuildingInfoTable from './BuildingInfoTable';

const BuildingInfo: FunctionComponent = () => {
    return (
        <div className="research dashboard-body mx-50">
            <BuildingInfoTable />
            <BuildingInfoGallery />
        </div>
    );
};

export default BuildingInfo;
