import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../api/index';
import PropertyAssetCashFlow from '../../../services/PropertyAssetCashFlow';
import PropertyAssetStackingPlan from '../../../services/PropertyAssetStackingPlanService';
import CashFlowTable from './cashFlowComponents/CashFlowTable';
import StackingPlanChart from './cashFlowComponents/StackingPlanChart';
import { toast } from 'react-toastify';
import Analiysis from './cashFlowComponents/Analiysis';
import { ChashFlowListItemI } from '../../../interfaces/cashFlow/ChashFlowListItem';
import useBoolean from '../../../hooks/use-boolean';
import AddEditRecordModal from './cashFlowComponents/AddEditRecordModal';
import TabsContentNavigation from '../../../components/shared/TabsContentNavigation';
import FeatureState from '../../../context/state/FeatureState';
import ManageDataButtons from './cashFlowComponents/partials/ManageDataButtons';
import MainLoader from '../../../components/shared/MainLoader';
import CreateDataForEmptySection from '../../../components/shared/CreateDataForEmptySection';
import ModalImportLoading from '../../../components/shared/ModalImportLoading';
import { PropertyAssetFeatures } from '../../../constants/propertyAssetFeatures';

enum NavEnums {
    RENT = 'RENT',
    STACKING = 'STACKING',
    ANALYSIS = 'ANALYSIS',
}

const CashFlow: FunctionComponent = () => {
    const [cashFlowItems, setCashFlowItems] = useState<Array<ChashFlowListItemI>>([]),
        [stackingPlan, setStackingPlan] = useState<any>(),
        [isUploading, setIsUploading] = useState(false),
        { t } = useTranslation(['f', 'b']),
        [isModalOpened, setIsModalOpenedActions] = useBoolean(),
        [activComponent, setActivComponent] = useState<string>(NavEnums.RENT),
        { propertySummary, getFeature, checkDealRoomFeature } = useContext(FeatureState),
        { id: propertyAssetid } = propertySummary,
        [isLoading, setIsLoadingActions] = useBoolean();

    const customRequest = (options: any) => {
        const data = new FormData();
        data.append('File', options.file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: '*/*',
            },
        };
        setIsUploading(true);
        api.post(options.action, data, config)
            .then((res: any) => {
                options.onSuccess(res.data, options.file);
                setIsUploading(false);
                fetchAssetCashFlow();
                fetchStackingPlan();
            })
            .catch((error: any) => {
                setIsUploading(false);
                toast(t(`b:${error.response.data.errorMessage}`));
            });
    };

    const fetchAssetCashFlow = useCallback(async () => {
        try {
            const response = await PropertyAssetCashFlow.fetchAssetCashFlow(`${propertyAssetid}`);

            setCashFlowItems(response);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetid, t]);

    const fetchStackingPlan = useCallback(async () => {
        try {
            const response = await PropertyAssetStackingPlan.fetchStackingPlan(`${propertyAssetid}`);
            setStackingPlan(response);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetid, t]);

    const handleDropDownSelect = async (event: any) => {
        try {
            const response = await PropertyAssetCashFlow.fetchAssetCashFlow(event);
            const temp_arr = cashFlowItems;
            setCashFlowItems(temp_arr.concat(response));
        } catch (error: any) {
            toast.error(`${error.response.date.errorMessage}`);
        }
    };

    const handleChangeActivComponent = (value: string) => setActivComponent(value);

    let radioBtnsDate = [
        {
            handler: handleChangeActivComponent,
            text: t(`f:cashFlow.rentRoll`),
            value: NavEnums.RENT,
        }
    ];

    if (getFeature("CashFlowStackingPlan") && checkDealRoomFeature(PropertyAssetFeatures.CashFlowStackingPlan)) {
        radioBtnsDate.push({
            handler: handleChangeActivComponent,
            text: t(`f:cashFlow.stackingPlan`),
            value: NavEnums.STACKING,
        });
    }

    if (getFeature("CashFlowAnalysis") && checkDealRoomFeature(PropertyAssetFeatures.CashFlowAnalysis)) {
        radioBtnsDate.push({
            handler: handleChangeActivComponent,
            text: t(`f:cashFlow.analysis`),
            value: NavEnums.ANALYSIS,
        });
    }

    const refechAfterFinish = useCallback(async () => {
        await fetchAssetCashFlow();
        await fetchStackingPlan();
        setIsLoadingActions.setTrue();
    }, [fetchStackingPlan, fetchAssetCashFlow, setIsLoadingActions]);

    const displayComponent = () => {
        switch (activComponent) {
            case NavEnums.STACKING:
                return <StackingPlanChart className="" data={stackingPlan} />;

            case NavEnums.ANALYSIS:
                return <Analiysis data={cashFlowItems} />;

            default:
                return (
                    <CashFlowTable
                        cashFlowItems={cashFlowItems}
                        refetch={refechAfterFinish}
                        handleDropDownSelect={handleDropDownSelect}
                    />
                );
        }
    };

    const handleOnClickOpenModal = useCallback(() => {
        setIsModalOpenedActions.setTrue();
    }, [setIsModalOpenedActions]);

    useEffect(() => {
        refechAfterFinish();
    }, [refechAfterFinish]);

    return (
        <>
            {isLoading ? (
                <>
                    <TabsContentNavigation
                        radioBtns={radioBtnsDate}
                        activComponent={activComponent}
                        element={
                            <ManageDataButtons
                                customRequest={customRequest}
                                isUploading={isUploading}
                                handleOnClickOpenModal={handleOnClickOpenModal}
                            />
                        }
                    />
                    {cashFlowItems.length ? (
                        <div className="cash-flow__table col-sm-12 property-tab__container">{displayComponent()}</div>
                    ) : (
                        <div className="col-xs-12">
                            <CreateDataForEmptySection
                                addClassToContainer="mx-50"
                                handler={handleOnClickOpenModal}
                                text={t(`f:button.btnCreateCashFlow`)}
                                mainText={t(`f:button.createCashFlow`)}
                            />
                        </div>
                    )}
                    <AddEditRecordModal
                        setIsModalOpened={setIsModalOpenedActions}
                        type="add"
                        isModalOpened={isModalOpened}
                        refetch={fetchAssetCashFlow}
                    />
                    <ModalImportLoading text={t(`f:loaders.uploading`)} isModalOpened={isUploading} />
                </>
            ) : (
                <MainLoader mt="mt-100" type="regular" />
            )}
        </>
    );
};

export default CashFlow;
