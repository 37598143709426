import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { ChashFlowListItemI } from '../../../../interfaces/cashFlow/ChashFlowListItem';
import { groupBy } from '../../../../utils/groupBy';
import AnaliysisBarChartView from './AnaliysisBarChartView';
import AnaliysisPieChartView from './AnaliysisPieChartView';
import useBoolean from '../../../../hooks/use-boolean';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
interface AnaliysisProps {
    data: Array<ChashFlowListItemI>;
}
interface BarData {
    country: string;
    visits: number;
}

const Analiysis: FunctionComponent<AnaliysisProps> = ({ data }: AnaliysisProps) => {
    const [areaData, setAreaData] = useState<Array<BarData>>([]),
        { t } = useTranslation(['f', 'b']),
        [incomeData, setAIncomeData] = useState<Array<BarData>>([]),
        [tenantsData, setTenantsData] = useState<Array<BarData>>([]),
        [tenantsCategoryByAreaData, setTenantsCategoryByAreaData] = useState<Array<any>>([]),
        [tenantsCategoryByIncomeData, settenantsCategoryByIncomeData] = useState<Array<any>>([]),
        [tenantCategoryByAreaLoad, setTenantCategoryByAreaLoadActions] = useBoolean(),
        [tenantCategoryByIncomeLoad, setTenantCategoryByIncomeLoadActions] = useBoolean(),
        [areaLoad, setAreaLoadActions] = useBoolean(),
        [areaTenants, setTenantsLoadActions] = useBoolean(),
        [incomeLoad, setIncomeLoadActions] = useBoolean();

    const prepareTenantCategoryDataByAreaAndIncome = useCallback(() => {
        const completeData = data.filter((elem) => {
            if (elem.leaseEndDate !== null) return elem;
        });
        const grouped = groupBy(
            completeData.map((elem) => {
                elem.leaseEndDate = `${new Date(elem.leaseEndDate).getFullYear()}`;
                return elem;
            }),
            'customLeaseCategory'
        );

        const new_data_by_area = [...tenantsCategoryByAreaData];
        const new_data_by_Income = [...tenantsCategoryByIncomeData];

        for (let [key, val] of Object.entries(grouped)) {
            let data: any = val;
            let sum = 0;
            data.map((elem) => {
                sum += elem.area;
            });

            new_data_by_area.push({ sector: key, size: sum });
        }

        for (let [key, val] of Object.entries(grouped)) {
            let data: any = val;
            let sum = 0;
            data.map((elem) => {
                sum += elem.area * elem.passingRent;
            });

            new_data_by_Income.push({ sector: key, size: sum });
        }

        console.log();

        setTenantsCategoryByAreaData(new_data_by_area);
        settenantsCategoryByIncomeData(new_data_by_Income);
        setTenantCategoryByAreaLoadActions.setTrue();
        setTenantCategoryByIncomeLoadActions.setTrue();
    }, [
        data,
        setTenantCategoryByAreaLoadActions,
        tenantsCategoryByIncomeData,
        setTenantCategoryByIncomeLoadActions,
        tenantsCategoryByAreaData,
    ]);

    const prepareTopTenants = useCallback(() => {
        const grouped = groupBy(data, 'name');
        const new_area_data: Array<BarData> = [];

        // const sorted = _.sortBy(grouped, ['visits'], 'desc');
        // console.log('sort', sorted);
        for (let [key, val] of Object.entries(grouped)) {
            let data: any = val;
            let sum = 0;

            data.map((elem) => {
                sum += elem.area;
            });

            new_area_data.push({ country: key, visits: sum });
        }

        new_area_data.sort(function (a, b) {
            return b.visits - a.visits;
        });

        setTenantsData(new_area_data.slice(0, 10));
        setTenantsLoadActions.setTrue();
    }, [data, setTenantsLoadActions]);

    const prepareIncomAndAreaData = useCallback(() => {
        const completeData = data.filter((elem) => {
            if (elem.leaseEndDate !== null) return elem;
        });

        const grouped = groupBy(
            completeData.map((elem) => {
                elem.leaseEndDate = `${new Date(elem.leaseEndDate).getFullYear()}`;
                return elem;
            }),
            'leaseEndDate'
        );

        const new_income_data = [...incomeData];
        const new_area_data = [...areaData];

        for (let [key, val] of Object.entries(grouped)) {
            let data: any = val;
            let sum = 0;
            data.map((elem) => {
                sum += elem.area * elem.passingRent;
            });

            new_income_data.push({ country: key, visits: sum });
        }

        for (let [key, val] of Object.entries(grouped)) {
            let data: any = val;
            let sum = 0;
            data.map((elem) => {
                sum += elem.area;
            });

            new_area_data.push({ country: key, visits: sum });
        }

        setAIncomeData(new_income_data);
        setAreaData(new_area_data);

        setIncomeLoadActions.setTrue();
        setAreaLoadActions.setTrue();
    }, [incomeData, data, areaData, setAreaLoadActions, setIncomeLoadActions]);

    useEffect(() => {
        prepareIncomAndAreaData();
        prepareTopTenants();
        prepareTenantCategoryDataByAreaAndIncome();
    }, []);

    return (
        <div className="stacking-plan__wrap">
            {areaLoad && <AnaliysisBarChartView text={t('f:cashFlow.byArea')} data={areaData} />}
            {incomeLoad && <AnaliysisBarChartView text={t('f:cashFlow.byIrea')} data={incomeData} />}
            {areaTenants && <AnaliysisBarChartView text={t('f:cashFlow.byTenants')} data={tenantsData} />}
            {tenantCategoryByAreaLoad && (
                <AnaliysisPieChartView text={t('f:cashFlow.byTenantsByArea')} data={tenantsCategoryByAreaData} />
            )}
            {tenantCategoryByIncomeLoad && (
                <AnaliysisPieChartView text={t('f:cashFlow.byTenantsByIncome')} data={tenantsCategoryByIncomeData} />
            )}
        </div>
    );
};
export default Analiysis;
