import api from '../index';
import { defaultTransformers } from '../index';
import CountryModel from '../models/CountryModel';
import CountryTransformer from '../transformers/CountryTransformer';

const fetchCountries = () => {
    return api.get<Array<CountryModel>>(`/country`, {
        transformResponse: [...defaultTransformers(), CountryTransformer.transform],
    });
};

const CountryApi = { fetchCountries };

export default CountryApi;
