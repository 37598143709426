import { BaseTransformer } from './BaseTransformer'
import CountryModel from '../models/CountryModel'

class CountryTransformer extends BaseTransformer {
    constructor() {
        super();
        this.transformSingle = this.transformSingle.bind(this);
        this.transformMultiple = this.transformMultiple.bind(this);
	}
    
	transformSingle(data = {}) {
		const country = new CountryModel(data);
		return country;
	}

	transformMultiple(data = []) {
		const countries = data?.map(item => new CountryModel(item));
		return countries;
	}
}

export default new CountryTransformer()