const ArcGisComponent = ({ url, height, width, title }) => {

    return (
        <div className="data-analytic__arcgis">
            <div className="embed-container">
                <iframe width={width} height={height}
                    frameBorder="0" scrolling="yes"
                    title={title} src={url} ></iframe>
            </div>
        </div>
    );
};

export default ArcGisComponent;
