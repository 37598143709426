import * as amcharts5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from '@amcharts/amcharts5/xy';
import { Col, Row } from "antd";
import moment from 'moment';
import { FunctionComponent, useLayoutEffect } from "react";
import { IActiveUsersInDays } from '../../interfaces/googleAnalytics/ActiveUsersInDays';
import styles from './ActiveUserInDaysChart.module.scss';

interface ActiveUserInDaysChartProps {
    data: Array<IActiveUsersInDays>;
}

const ActiveUsersInDaysChart: FunctionComponent<ActiveUserInDaysChartProps> = ({
    data = []
}) => {
    const id = "activeUsersInDaysChart";

    useLayoutEffect(() => {
        if (data.length) {
            let root = amcharts5.Root.new(id);
            // Set themes
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                pinchZoomX: false
            }));

            chart!.get("colors")!.set("step", 5);
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);

            let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                maxDeviation: 0.3,
                baseInterval: {
                    timeUnit: "day",
                    count: 1
                },
                renderer: am5xy.AxisRendererX.new(root, {}) as any,
                tooltip: amcharts5.Tooltip.new(root, {})
            }));

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(root, {}) as any
            }));

            let series = chart.series.push(am5xy.LineSeries.new(root, {
                name: "Active 28-Days Users",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "active28DaysUsers",
                valueXField: "date",
                stroke: amcharts5.color('#1a73e8'),
                tooltip: amcharts5.Tooltip.new(root, {
                    labelText: "{valueY}"
                })
            }));
            series.strokes.template.setAll({
                strokeWidth: 2
            });
            series!.get("tooltip")!.get("background")!.set("fillOpacity", 0.5);

            let series2 = chart.series.push(am5xy.LineSeries.new(root, {
                name: "Active 7-Days Users",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "active7DaysUsers",
                valueXField: "date",
                stroke: amcharts5.color('#C767DC'),
                tooltip: amcharts5.Tooltip.new(root, {
                    labelText: "{valueY}"
                })
            }));
            series2.strokes.template.setAll({
                strokeWidth: 2
            });

            let series3 = chart.series.push(am5xy.LineSeries.new(root, {
                name: "Active 1-Day Users",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "active1DayUsers",
                valueXField: "date",
                stroke: amcharts5.color('#DC8C67'),
                tooltip: amcharts5.Tooltip.new(root, {
                    labelText: "{valueY}"
                })
            }));
            series3.strokes.template.setAll({
                strokeWidth: 2
            });
            root.dateFormatter.setAll({
                dateFormat: "yyyy-MM-dd",
                dateFields: ["valueX"]
            });

            let chartData: Array<any> = [];
            data.forEach(item => {
                chartData.push({
                    date: moment().subtract(28 - item.nthDay, 'days').toDate().getTime(),
                    active28DaysUsers: item.active28DaysUsers,
                    active7DaysUsers: item.active7DaysUsers,
                    active1DayUsers: item.active1DayUsers
                });
            });

            let legend = chart.children.push(amcharts5.Legend.new(root, {}));
            legend.data.setAll(chart.series.values);

            series.data.setAll(chartData);
            series2.data.setAll(chartData);
            series3.data.setAll(chartData);
            chart.appear(1000, 100);
            return () => root.dispose();
        }
    }, [data])

    return data.length ?
        (
            <div className='mt-50'>
                <p className='heading ml-20'>User activity over time</p>
                <Row>
                    <Col span={20}>
                        <div id={id} style={{ width: '100%', height: '500px' }}></div>
                    </Col>
                    <Col span={4}>
                        <div className='d-flex flex-direction-column'>
                            <div className={styles.info}>
                                <div className='info-title'>
                                    <div className={styles.infoIcon} style={{ backgroundColor: "#1a73e8" }}></div>
                                    <span>28 days</span>
                                </div>
                                <p className={styles.infoValue}>{data[data.length - 1].active28DaysUsers}</p>
                            </div>
                            <div className={`${styles.info} mt-80`}>
                                <div className='info-title'>
                                    <div className={styles.infoIcon} style={{ backgroundColor: "#C767DC" }}></div>
                                    <span>7 days</span>
                                </div>
                                <p className={styles.infoValue}>{data[data.length - 1].active7DaysUsers}</p>
                            </div>
                            <div className={`${styles.info} mt-80`}>
                                <div className='info-title'>
                                    <div className={styles.infoIcon} style={{ backgroundColor: "#DC8C67" }}></div>
                                    <span>1 days</span>
                                </div>
                                <p className={styles.infoValue}>{data[data.length - 1].active1DayUsers}</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        ) : <></>;
}

export default ActiveUsersInDaysChart;