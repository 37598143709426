import { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const overall = [
    2432, 2445, 2445, 2445, 2445, 2445, 2412, 2373, 2316, 2281, 2253, 2227, 2206, 2258, 2358, 2463, 2511, 2585, 2674,
    2792, 2903, 2950, 2965, 2967, 2969, 2982, 2981, 2985, 3046, 3215, 3365,
];

const overallSeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: overall[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: overall[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: overall[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: overall[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: overall[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: overall[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: overall[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: overall[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: overall[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: overall[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: overall[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: overall[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: overall[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: overall[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: overall[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: overall[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: overall[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: overall[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: overall[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: overall[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: overall[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: overall[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: overall[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: overall[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: overall[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: overall[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: overall[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: overall[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: overall[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: overall[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: overall[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: overall[31],
    },
];

const raffles: Array<any> = [
    2660, 2675, 2675, 2675, 2675, 2675, 2656, 2589, 2537, 2499, 2463, 2416, 2386, 2440, 2511, 2611, 2656, 2710, 2762,
    2889, 2975, 3022, 3032, 3017, 3010, 3010, 3010, 3015, 3050, 3170, 3290,
];

const raffleSeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: raffles[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: raffles[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: raffles[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: raffles[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: raffles[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: raffles[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: raffles[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: raffles[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: raffles[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: raffles[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: raffles[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: raffles[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: raffles[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: raffles[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: raffles[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: raffles[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: raffles[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: raffles[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: raffles[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: raffles[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: raffles[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: raffles[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: raffles[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: raffles[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: raffles[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: raffles[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: raffles[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: raffles[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: raffles[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: raffles[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: raffles[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: raffles[31],
    },
];

const shentonWay: Array<any> = [
    1965, 1975, 1975, 1975, 1975, 1975, 1958, 1932, 1874, 1845, 1813, 1793, 1781, 1809, 1927, 2015, 2047, 2118, 2163,
    2238, 2311, 2411, 2436, 2438, 2444, 2466, 2466, 2475, 2540, 2670, 2800,
];

const shentonSeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: shentonWay[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: shentonWay[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: shentonWay[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: shentonWay[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: shentonWay[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: shentonWay[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: shentonWay[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: shentonWay[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: shentonWay[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: shentonWay[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: shentonWay[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: shentonWay[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: shentonWay[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: shentonWay[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: shentonWay[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: shentonWay[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: shentonWay[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: shentonWay[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: shentonWay[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: shentonWay[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: shentonWay[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: shentonWay[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: shentonWay[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: shentonWay[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: shentonWay[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: shentonWay[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: shentonWay[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: shentonWay[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: shentonWay[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: shentonWay[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: shentonWay[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: shentonWay[31],
    },
];

const marina = [
    2365, 2375, 2375, 2375, 2375, 2375, 2356, 2291, 2257, 2190, 2176, 2156, 2128, 2182, 2258, 2357, 2400, 2469, 2485,
    2568, 2679, 2764, 2771, 2797, 2820, 2820, 2820, 2830, 2900, 3000, 3100,
];

const marinaData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: marina[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: marina[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: marina[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: marina[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: marina[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: marina[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: marina[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: marina[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: marina[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: marina[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: marina[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: marina[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: marina[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: marina[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: marina[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: marina[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: marina[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: marina[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: marina[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: marina[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: marina[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: marina[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: marina[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: marina[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: marina[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: marina[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: marina[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: marina[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: marina[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: marina[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: marina[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: marina[31],
    },
];

const marinaBay = [
    2715, 2730, 2730, 2730, 2730, 2730, 2715, 2687, 2609, 2591, 2573, 2560, 2542, 2624, 2781, 2918, 2993, 3117, 3200,
    3371, 3545, 3560, 3573, 3577, 3579, 3579, 3579, 3590, 3650, 3850, 4050,
];

const marinaBayData = [
    {
        date: new Date(2014, 1, 1),
        value: marinaBay[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: marinaBay[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: marinaBay[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: marinaBay[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: marinaBay[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: marinaBay[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: marinaBay[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: marinaBay[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: marinaBay[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: marinaBay[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: marinaBay[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: marinaBay[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: marinaBay[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: marinaBay[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: marinaBay[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: marinaBay[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: marinaBay[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: marinaBay[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: marinaBay[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: marinaBay[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: marinaBay[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: marinaBay[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: marinaBay[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: marinaBay[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: marinaBay[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: marinaBay[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: marinaBay[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: marinaBay[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: marinaBay[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: marinaBay[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: marinaBay[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: marinaBay[31],
    },
];

am4core.useTheme(am4themes_animated);
const CapitalValueChart = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);

    function processOver(hoveredSeries, chart) {
        hoveredSeries.toFront();

        hoveredSeries.segments.each(function (segment) {
            segment.setState('hover');
        });

        hoveredSeries.legendDataItem.marker.setState('default');
        hoveredSeries.legendDataItem.label.setState('default');
        if (chart) {
            chart.series.each(function (series) {
                if (series != hoveredSeries) {
                    series.segments.each(function (segment) {
                        segment.setState('dimmed');
                    });
                    series.bulletsContainer.setState('dimmed');
                    series.legendDataItem.marker.setState('dimmed');
                    series.legendDataItem.label.setState('dimmed');
                }
            });
        }
    }

    function processOut(chart) {
        chart.series.each(function (series) {
            series.segments.each(function (segment) {
                segment.setState('default');
            });

            series.bulletsContainer.setState('default');
            series.legendDataItem.marker.setState('default');
            series.legendDataItem.label.setState('default');
        });
    }

    useLayoutEffect(() => {
        let chart = am4core.create('capitalValueChartDiv', am4charts.XYChart);

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        chart.dateFormatter.dateFormat = 'yyyy-q';

        var overallSeries = chart.series.push(new am4charts.LineSeries());
        overallSeries.dataFields.valueY = 'value';
        overallSeries.dataFields.dateX = 'date';
        overallSeries.name = 'Overall';
        overallSeries.tooltipText = 'close: {valueY.value}';
        overallSeries.strokeWidth = 3;
        overallSeries.tensionX = 0.8;
        overallSeries.bullets.push(new am4charts.CircleBullet());

        overallSeries.data = overallSeriesData;

        var rafflesSeries = chart.series.push(new am4charts.LineSeries());
        rafflesSeries.dataFields.valueY = 'value';
        rafflesSeries.dataFields.dateX = 'date';
        rafflesSeries.name = 'Raffles';
        rafflesSeries.tooltipText = 'close: {valueY.value}';
        rafflesSeries.strokeWidth = 3;
        rafflesSeries.tensionX = 0.8;
        rafflesSeries.bullets.push(new am4charts.CircleBullet());
        rafflesSeries.data = raffleSeriesData;

        var shentonWaySeries = chart.series.push(new am4charts.LineSeries());
        shentonWaySeries.dataFields.valueY = 'value';
        shentonWaySeries.dataFields.dateX = 'date';
        shentonWaySeries.name = 'Shenton Way';
        shentonWaySeries.tooltipText = 'close: {valueY.value}';
        shentonWaySeries.strokeWidth = 3;
        shentonWaySeries.tensionX = 0.8;
        shentonWaySeries.bullets.push(new am4charts.CircleBullet());
        shentonWaySeries.data = shentonSeriesData;

        var marinaSeries = chart.series.push(new am4charts.LineSeries());
        marinaSeries.dataFields.valueY = 'value';
        marinaSeries.dataFields.dateX = 'date';
        marinaSeries.name = 'Marina';
        marinaSeries.strokeWidth = 3;
        marinaSeries.tensionX = 0.8;
        marinaSeries.tooltipText = 'close: {valueY.value}';
        marinaSeries.bullets.push(new am4charts.CircleBullet());
        marinaSeries.data = marinaData;

        var marinaBaySeries = chart.series.push(new am4charts.LineSeries());
        marinaBaySeries.dataFields.valueY = 'value';
        marinaBaySeries.dataFields.dateX = 'date';
        marinaBaySeries.name = 'Marina Bay';
        marinaBaySeries.tooltipText = 'close: {valueY.value}';
        marinaBaySeries.strokeWidth = 3;
        marinaBaySeries.tensionX = 0.8;
        marinaBaySeries.bullets.push(new am4charts.CircleBullet());
        marinaBaySeries.data = marinaBayData;

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'right';
        chart.legend.scrollable = true;

        // setTimeout(function() {
        //   chart.legend.markers.getIndex(0).opacity = 0.3;
        // }, 3000)
        chart.legend.markers.template.states.create('dimmed').properties.opacity = 0.3;
        chart.legend.labels.template.states.create('dimmed').properties.opacity = 0.3;

        chart.legend.itemContainers.template.events.on('over', function (event) {
            if (event && event?.target && event?.target?.dataItem)
                processOver(event.target.dataItem.dataContext, chart);
        });

        chart.legend.itemContainers.template.events.on('out', function (event) {
            processOut(chart);
        });

        chart.cursor = new am4charts.XYCursor();

        chartRef.current = chart;

        return () => {
            chart.dispose();
        };
    }, []);

    return <div id="capitalValueChartDiv" style={{ width: '100%', height: '500px' }}></div>;
};

export default CapitalValueChart;
