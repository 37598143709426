import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MainCheckbox from '../../../../components/shared/MainCheckbox';
import MainHeading from '../../../../components/shared/MainHeading';
import { PROPERTY_TABS } from '../../../../constants/propertyTab';
import { LoWizardStepNames } from '../../../../constants/wizardSteps';
import FeatureState from '../../../../context/state/FeatureState';
import LOWizardState from '../../../../context/state/LOWizardState';
import ListOpportunityFooterBtns from '../partials/ListOpportunityFooterBtns';

const Aknowledgement: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        { changeDestination, isAgreePayment, isAgreePaymentActions } = useContext(LOWizardState);

    const returnToPrev = useCallback(() => {
        changeDestination(LoWizardStepNames.DEAL_TIMELINE_AND_INVESTORS);
    }, [changeDestination]);

    const nextHandler = useCallback(() => {
        changeDestination(LoWizardStepNames.PUBLISH);
    }, [changeDestination]);
    return (
        <div className="wizard-container-steps">
            <MainHeading text={t(`f:listOpportunity.${LoWizardStepNames.PAYMENT_ACKNOWLEDGEMENT}`)} />
            <div className="aknowledgement__content mb-100">
                <p className="mt-20">
                    Virgate values your privacy and cares about the way in which your personal information is treated.
                </p>
                <p className="mt-10">This policy describes:</p>
                <ul className="terms-of-use__list mt-10">
                    <li>
                        <p>what personal information we collect about you</p>
                    </li>
                    <li>
                        <p>how we obtain your personal information</p>
                    </li>
                    <li>
                        <p>how we use your personal information</p>
                    </li>
                    <li>
                        <p>on what basis we use your personal information</p>
                    </li>
                    <li>
                        <p>how long we keep your personal information </p>
                    </li>
                    <li>
                        <p>who we share your personal information with </p>
                    </li>
                    <li>
                        <p>how we protect your personal information </p>
                    </li>
                    <li>
                        <p>which countries we transfer your personal information to</p>
                    </li>
                    <li>
                        <p>your rights regarding your personal information</p>
                    </li>
                </ul>
                <p className="mt-10">
                    Virgate refers to Virgate Pte. Ltd., a Singapore incorporated technology company. 
                </p>
                <p className="mt-10">
                    If you need further information or have any questions about our privacy policy or practices, please
                    contact us using the details set out at the end of the policy.
                </p>
                <h3 className="terms-of-use__sub-header mt-10">What personal information do we collect about you?</h3>
                <p className="mt-10">
                    As a B2B platform, we collect very limited personal information. However, we may collect certain
                    personal information from you in the course of our business, including through your use of our
                    platform, when you contact or request information from us, when you engage our services or as a
                    result of your relationship with one or more of our staff, real estate service providers, as well as
                    prospective buyers and sellers using our platform.  
                </p>
                <p className="mt-10">The personal information that we process includes:</p>
                <ul className="terms-of-use__list mt-10">
                    <li>
                        <p>
                            Basic information, such as your name (including name prefix or title), the company you work
                            for, your title or position and your relationship to a person
                        </p>
                    </li>
                    <li>
                        <p>Contact information, such as your postal address, email address and phone number(s)</p>
                    </li>
                    <li>
                        <p>Professional licence details that may be required in your operational jurisdiction</p>
                    </li>
                    <li>
                        <p>
                            Technical information, such as information from your visits to our website or applications
                            or in relation to materials and communications we send to you electronically 
                        </p>
                    </li>
                    <li>
                        <p>
                            Any other information relating to you which you may provide to us, including information of
                            third parties that you submit through the platform.
                        </p>
                    </li>
                </ul>
                <h3 className="terms-of-use__sub-header mt-10">How we obtain your personal information</h3>
                <ul className="terms-of-use__list mt-10">
                    <li>
                        <p>
                            We collect information from you as part of our user interface on our platform, including
                            account creation and questionnaire details as necessary in the course of providing our
                            business services
                        </p>
                    </li>
                    <li>
                        <p>
                            We collect your personal information while monitoring our technology tools and services,
                            including our websites and email communications sent to and from Virgate
                        </p>
                    </li>
                    <li>
                        <p>
                            We gather information about you when you provide it to us, or interact with us directly, for
                            instance engaging with our staff
                        </p>
                    </li>
                    <li>
                        <p>
                            We may collect or receive information about you from other sources, such as keeping the
                            contact details we already hold for you accurate and up to date through either publicly
                            available sources or other Member(s).
                        </p>
                    </li>
                </ul>
                <h3 className="terms-of-use__sub-header mt-10">How we use your personal information</h3>
                <div className="col-xs-6 form-group">
                    <label htmlFor="text">I agree</label>
                    <MainCheckbox
                        checked={isAgreePayment}
                        onClick={() => {
                            isAgreePaymentActions.toggle();
                        }}
                    />
                </div>
            </div>
            <ListOpportunityFooterBtns
                cancelUrl={`/property/${externalId}#${PROPERTY_TABS.PROPERTY_SUMMARY.name}`}
                isNotFirst={true}
                isNotFinish={false}
                onNextHandler={nextHandler}
                returnToPreviousPage={returnToPrev}
            />
        </div>
    );
};

export default Aknowledgement;
