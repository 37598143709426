import { FunctionComponent } from 'react';
import LoadingText from './LoadingText';
import MainLoader from './MainLoader';
import ModalWrapper from '../wrapper/ModalWrapper';

interface ModalImportLoadingProps {
    isModalOpened: boolean;
    text: string;
}
const ModalImportLoading: FunctionComponent<ModalImportLoadingProps> = ({
    isModalOpened,
    text,
}: ModalImportLoadingProps) => (
    <ModalWrapper
        title=""
        className=""
        isModalOpened={isModalOpened}
        isCloseModalShown={false}
        isFooterShown={false}
        closeModalHandler={() => {}}
    >
        <MainLoader type="regular" />
        <LoadingText text={text} />
    </ModalWrapper>
);

export default ModalImportLoading;
