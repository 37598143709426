import { FunctionComponent, useContext } from 'react';
import { RichTextEditor } from './templates/RichTextEditor';
import { Gallery } from './templates/Gallery';
import { MultipleTextImage } from './templates/MultipleTextImage';
import { TableTemplate } from './templates/TableTemplate';
import RouteParams from '../../../interfaces/property/CreatePropertySummaryRouteParams';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { layouts } from '../../../constants/layouts';
import _ from 'lodash';
import MainHeading from '../../../components/shared/MainHeading';
import ListOpportunityFooterBtns from '../../ListOpportunityWizard/components/partials/ListOpportunityFooterBtns';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';

interface Props {
    setContent: any;
    contentAdded: any;
    setImageUrl: (prev: string) => void;
    title: string;
    setTitle: (prev: string) => void;
    imageUrl: string;
    contentState: {
        state: string;
        setter: React.Dispatch<React.SetStateAction<string>>;
    };
}

export const CreateEditTemplate: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary;
    const params = useParams<RouteParams>();
    const layout: any = _.find(layouts, { key: params.templateKey });
    const imageUrl = layout.imageUrl;
    const history = useHistory();

    const returnToPreviousPage = () => {
        localStorage.removeItem('content');
        localStorage.removeItem('imageContent');
        localStorage.removeItem('titleContent');
        history.goBack();
    };

    const renderTemplate = () => {
        switch (params.templateKey) {
            case 'multiple-text-image':
                return (
                    <MultipleTextImage
                        setContent={props.setContent}
                        propertyAssetId={`${propertyAssetId}`}
                        templateKey={params.templateKey}
                        pageType={params.pageType}
                        contentState={{ state: props.contentState.state, setter: props.contentState.setter }}
                        length={6}
                    />
                );
            case 'icon-text':
                return (
                    <MultipleTextImage
                        setContent={props.setContent}
                        propertyAssetId={`${propertyAssetId}`}
                        templateKey={params.templateKey}
                        pageType={params.pageType}
                        contentState={{ state: props.contentState.state, setter: props.contentState.setter }}
                        length={8}
                    />
                );
            case 'gallery':
                return (
                    <Gallery
                        setContent={props.setContent}
                        propertyAssetId={`${propertyAssetId}`}
                        contentState={{ state: props.contentState.state, setter: props.contentState.setter }}
                    />
                );
            case 'table':
                return (
                    <TableTemplate
                        setContent={props.setContent}
                        pageType={params.pageType}
                        propertyAssetId={`${propertyAssetId}`}
                        contentState={{ state: props.contentState.state, setter: props.contentState.setter }}
                    />
                );
            default:
                return (
                    <RichTextEditor
                        title={props.title}
                        setTitle={props.setTitle}
                        setContent={props.setContent}
                        propertyAssetId={`${propertyAssetId}`}
                        setImageUrl={props.setImageUrl}
                        imageUrl={props.imageUrl}
                        pageType={params.pageType}
                        templateKey={params.templateKey}
                        contentState={{ state: props.contentState.state, setter: props.contentState.setter }}
                    />
                );
        }
    };

    return (
        <div className="wizard-container-steps">
            <div className="col-xs-12">
                <MainHeading text={t('f:propertySummary.chosenLayout')} />
            </div>
            <figure className="col-xs-6-clear">
                <img src={imageUrl} alt="Template" />
            </figure>
            <div className="col-xs-12">{renderTemplate()}</div>
            <ListOpportunityFooterBtns
                cancelUrl={`/property/${externalId}#${PROPERTY_TABS.PROPERTY_SUMMARY.name}`}
                isNotFirst={true}
                isNotFinish={false}
                onNextHandler={props.contentAdded}
                returnToPreviousPage={() => {
                    localStorage.removeItem('content');
                    localStorage.removeItem('imageContent');
                    localStorage.removeItem('titleContent');
                    history.push(`/property/${externalId}`);
                }}
            />
        </div>
    );
};
