import { FunctionComponent, useContext, useState } from 'react';
import PropertyFaq from '../../../../services/PropertyFaq';
import Question from '../faqComponents/Question';
import { QuestionAndAnswer } from '../../../../interfaces/faq/Question';
import FeatureState from '../../../../context/state/FeatureState';
import CreateDataForEmptySection from '../../../../components/shared/CreateDataForEmptySection';
import { useTranslation } from 'react-i18next';
import { Actions } from '../../../../interfaces/general/general';

interface Props {
    setIsModalOpened: (isOpened: boolean) => any;
    isModalOpened: boolean;
    setIsModalQuestionOpened: (boolean: boolean) => any;
    topicId: string;
    faqName: string;
    editableQuestion: any;
    setEditableQuestion: (question: any) => any;
    fetchAssetFaq: () => void;
    questionForView: Array<QuestionAndAnswer>;
    setAddQuestionModalOpened: Actions;
}

const FaqQuestions: FunctionComponent<Props> = (props) => {
    const [isAnswerDropdownOpened, setIsAnswerDropdownOpened] = useState<any>(false),
        { propertySummary, getFeature } = useContext(FeatureState),
        { t } = useTranslation(['f', 'b']),
        { id: propertyAssetId } = propertySummary;

    const removeQuestionHandler = async (question: any) => {
        await PropertyFaq.deleteQuestion(`${propertyAssetId}`, props.topicId, question.id);
        props.fetchAssetFaq();
    };

    const toggle = (index: any) => {
        if (isAnswerDropdownOpened === index) {
            return setIsAnswerDropdownOpened(null);
        }
        setIsAnswerDropdownOpened(index);
    };

    const openEditableModal = (question: any) => {
        props.setEditableQuestion(question);
        props.setIsModalOpened(true);
    };

    return (
        <div className="questions">
            <div className="questions-container">
                {props.questionForView.map((question: any, index: any) => {
                    return (
                        <Question
                            question={question}
                            index={index}
                            toggle={toggle}
                            isAnswerDropdownOpened={isAnswerDropdownOpened}
                            openEditableModal={openEditableModal}
                            removeQuestionHandler={removeQuestionHandler}
                        />
                    );
                })}

                {getFeature('FaqEdit') && 
                    <CreateDataForEmptySection 
                    addClassToContainer="question-empty-section"
                    handler={() => {props.setAddQuestionModalOpened.setTrue();}}
                    text={t(`f:button.addFaqQuestion`)}
                    mainText={''}
                />
                }
            </div>
        </div>
    );
};

export default FaqQuestions;
