export interface OverviewPropertyAssetSearch {
    countryId?: number;
    sectorId?: number;
    subStatus?: number;
    pageSize: number;
    page: number;
    type: number;
}

export interface OverviewPropertyAssetsTotal {
    totalCount: number;
    items: Array<OverviewPropertyAsset>;
}
export interface OverviewPropertyAsset {
    id: string;
    propertyName: string;
    description: string;
    assetValue: number;
    occupancy: string;
    currencyId: number;
    numberOfBuildings: number;
    numberOfFloors: number;
    numberOfUnitsRooms: number;
    numberOfStories: null;
    numberOfTenants: null;
    buildingClassId: string;
    buildingStatusId: string;
    yearOfCompletion: string;
    yearRenovated: string;
    yearBuilt: string;
    locationTypes: Array<number>;
    excessLand: boolean;
    greenRatings: Array<number>;
    propertyMarketId: string;
    propertySubMarketId: string;
    assetTypes: Array<number>;
    assetSubTypeId: string;
    landTenureId: string;
    leaseholdStartDate: string;
    leaseholdExpirationDate: string;
    leasehold: string;
    anchorTenants: string;
    buildingGradeId: string;
    saleTypeId: string;
    landArea: number;
    landAreaUnitOfMeasureId: string;
    unitOfMeasurementId: string;
    grossFloorArea: string;
    grossFloorAreaUnitOfMeasureId: string;
    sectors: Array<number>;
    sectorNames: Array<string>;
    netLeasableArea: number;
    netLeasableAreaUnitOfMeasureId: null;
    grossLeasableArea: null;
    grossLeasableAreaUnitOfMeasureId: null;
    carparkLots: string;
    majorTenants: string;
    passingRent: number;
    passingRentCurrencyId: null;
    passingRentUnitOfMeasureId: null;
    netOperatingIncome: number;
    capitalisationRate: number;
    ownershipTypeId: string;
    currentOwner: string;
    saleInterest: boolean;
    partialInterest: boolean;
    keepFinancialInformationConfidential: boolean;
    map: { long: number; lat: number; address: string; countryId: number };
    imageUrl: null;
    isPortfolio: boolean;
    allowSubsetBidding: boolean;
    createdDate: string;
    updatedDate: string;
    createdById: string;
    status: string;
    currencyPrefix: string;
    owners: Array<number>;
    mainStatus: string;
    subStatus: string;
}

export enum OverviewTopCards {
    'PORTFOLIO' = 1,
    'DIVESTMENTS' = 2,
    'ACQUISITIONS' = 3,
}
export enum OverviewViewTypes {
    CARDS = 'CARDS',
    TABLE = 'TABLE',
}
