import { FunctionComponent, useContext } from 'react';
import { Formik, Field, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { layouts } from '../../../constants/layouts';
import { notification } from 'antd';
import _ from 'lodash';

import MainHeading from '../../../components/shared/MainHeading';
import { useHistory } from 'react-router-dom';
import ListOpportunityFooterBtns from '../../ListOpportunityWizard/components/partials/ListOpportunityFooterBtns';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';
interface Props {
    onSelect: any;
    pageType: string;
}

const ChooseLayout: FunctionComponent<any> = (props: Props) => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary;
    const openNotificationWithIcon = () => {
        notification.error({
            message: 'Error',
            description: 'Please select one of the provided layouts.',
            duration: 2,
        });
    };

    const wizardLayouts = () => {
        if (props.pageType === 'propertyassetpagesummary') {
            return _.reject(layouts, { key: 'icon-text' });
        }
        return layouts;
    };

    return (
        <div className="wizard-container-steps">
            <MainHeading text={t('f:propertySummary.chooseLayout')} />

            <Formik
                initialValues={{
                    selectedLayout: '',
                }}
                onSubmit={async (value) => {
                    if (!value.selectedLayout) {
                        openNotificationWithIcon();
                        return;
                    }
                    props.onSelect(value.selectedLayout);
                }}
            >
                {({ values, submitForm }) => (
                    <Form className="">
                        {wizardLayouts().map((layout, index) => (
                            <label key={index} className="col-md-5 property__radio choose-layout__radio d-flex">
                                <Field type="radio" name="selectedLayout" value={layout.key} />
                                <div className="property__radio-option">
                                    <div className="property__radio-select"></div>
                                </div>
                                <figure>
                                    <img src={layout.imageUrl} alt="Template" />
                                </figure>
                            </label>
                        ))}
                        <ListOpportunityFooterBtns
                            isNotFirst={false}
                            isNotFinish={false}
                            cancelUrl={`/property/${externalId}#${PROPERTY_TABS.PROPERTY_SUMMARY.name}`}
                            onNextHandler={submitForm}
                            returnToPreviousPage={() => {
                                history.push(`/property/${externalId}`);
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ChooseLayout;
