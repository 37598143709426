import React, { useCallback, useContext, useEffect, useState } from 'react';
import Layout from './components/partials/Layout';
import { BrowserRouter } from 'react-router-dom';
import { EventType, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faFacebookF, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './translations/config';
import { loginRequest, msalConfig } from './auth/authConfig';
import { Helmet } from 'react-helmet';
import UserIdle from './components/shared/UserIdle';
import MainLoader from './components/shared/MainLoader';
import ThemeState from './context/state/ThemeState';
import './assets/styles/index.scss';
import { setToken } from './auth/UserService';
import { DEFAULTCOLORS } from './constants/theme';
import useBoolean from './hooks/use-boolean';
import TagManager from 'react-gtm-module';

library.add(faLinkedinIn, faFacebookF, faInstagram, faTwitter, faYoutube, faSearch);
const instance = new PublicClientApplication(msalConfig);
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_KEY
}

if (process.env.REACT_APP_GTM_KEY) {
    TagManager.initialize(tagManagerArgs)
}

const App = () => {
    const [isLoading, setIsLoadingActions] = useBoolean(),
        theme = useContext(ThemeState);

    const handleRedirect = useCallback(async () => {
        const result = await instance.handleRedirectPromise();
        if (result && result.accessToken) {
            setToken(result.accessToken);
            theme.fetchActivTheme();
        }
    }, []);

    useEffect(() => {
        handleRedirect();
    }, [handleRedirect]);

    useEffect(() => {
        const callbackId = instance.addEventCallback((event: any) => {
            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.indexOf('AADB2C90118') > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        instance.loginRedirect(loginRequest);
                    } else if (event.interactionType === InteractionType.Popup) {
                        instance.loginPopup(loginRequest).catch((e) => {
                            console.error(e);
                            return;
                        });
                    }
                }

                if (event.error && event.error.errorMessage.indexOf('AADB2C90091') > -1) {
                    instance.loginRedirect(loginRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const account = event.payload.account;
                instance.setActiveAccount(account);
            }
        });
        setIsLoadingActions.setTrue();

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, []);

    return (
        <div
            id="theme"
            className="app"
            style={{ ...({ ...theme.currentTheme.colors, ...DEFAULTCOLORS } as React.CSSProperties) }}
        >
            {isLoading ? (
                <BrowserRouter>
                    <MsalProvider instance={instance}>
                        <Helmet defaultTitle="Virgate">
                            <link rel="preconnect" href="https://fonts.gstatic.com" />
                            <link
                                href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap"
                                rel="stylesheet"
                            />
                        </Helmet>
                        <Layout />
                        <UserIdle />
                    </MsalProvider>
                </BrowserRouter>
            ) : (
                <MainLoader type="main" />
            )}
        </div>
    );
};

export default App;
