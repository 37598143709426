import { Result } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

export const NotFound = () => {
    const { t } = useTranslation(['f', 'b']);

    useEffect(() => {
        const header = document.getElementById('header');
        if (header) header.style.visibility = 'hidden';
        return () => {
            if (header) header.style.visibility = 'visible';
        };
    }, []);
    return (
        <div className="not-found-and-unauthorized d-flex align-items-center justify-content-center">
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Link to="/">{t('f:button.goHome')}</Link>}
            />{' '}
        </div>
    );
};
