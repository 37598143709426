import { Upload } from 'antd';
import { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import MainCheckbox from '../../../../components/shared/MainCheckbox';
import MainHeading from '../../../../components/shared/MainHeading';
import MainLoader from '../../../../components/shared/MainLoader';
import LOWizardState from '../../../../context/state/LOWizardState';
import ListOpportunityFooterBtns from '../partials/ListOpportunityFooterBtns';
import ListingTypeSingle from '../partials/ListingTypeSingle';
import NDATypeSingle from '../partials/NDATypeSingle';
import { PROPERTY_TABS } from '../../../../constants/propertyTab';
import FeatureState from '../../../../context/state/FeatureState';

const listingTypes = {
    '11': 'private',
    '12': 'offMarket',
};
const isRequiredMap = {
    '0': 'no',
    skip: 'yes',
};

const Privacy: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        {
            isListingTypeLoaded,
            ndaTypes,
            isRequired,
            bidingStructure,
            setBidingStructure,
            handleIsRequiredSwitch,
            handleBidingStructurAndNextStep,
        } = useContext(LOWizardState);

    const ndaTypeViewContent = (key: string, val: any) => {
        switch (key) {
            case '0':
                return;
            default:
                return (
                    <NDATypeSingle
                        size="12"
                        title={t(`f:listOpportunity.enumPropertyAssetStatus.${val}`)}
                        type={key}
                        key={key}
                    />
                );
        }
    };

    const isndaRequiredViewContent = (key: string, val: any) => {
        switch (key) {
            case 'skip':
                return (
                    <label
                        htmlFor="require"
                        className="d-flex align-items-center mt-30 mxl-40 mb-20 checkbox"
                        key={key}
                    >
                        <MainCheckbox checked={isRequired} onClick={handleIsRequiredSwitch} />
                        <span className="text-rubik-12">{t(`f:listOpportunity.${val}`)}</span>
                    </label>
                );

            default:
                return <NDATypeSingle size="12" title={t(`f:listOpportunity.${val}`)} type={key} key={key} />;
        }
    };

    const customRequest = async (options: any) => {
        const data = new FormData();
        data.append('File', options.file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'text/plain',
            },
        };
        const url: any = await api.post(options.action, data, config);

        setBidingStructure(bidingStructure ? { ...bidingStructure, customNdaUrl: url.data } : null);
    };

    return (
        <div className="wizard-container-steps">
            <div className="col-xs-12 mb-100">
                <MainHeading text={t('f:listOpportunity.privacy')} />
                {isListingTypeLoaded ? (
                    <div className="col-xs-12 mb-50">
                        {Object.entries(listingTypes).map(([key, val]) => (
                            <ListingTypeSingle
                                title={t(`f:listOpportunity.enumNdaType.${val}`)}
                                type={key}
                                info={t(`f:listOpportunity.enumNdaType.${val}Info`)}
                                key={key}
                            />
                        ))}
                    </div>
                ) : (
                    <MainLoader type="regular" mt="mt-50" mb="mb-100" />
                )}

                <div className="col-xs-12 mt-15 mr-50">
                    <p className="text-airbnb-14">{t('f:listOpportunity.requiredNda')}</p>
                    <div>{Object.entries(isRequiredMap).map(([key, val]) => isndaRequiredViewContent(key, val))}</div>
                </div>

                {isRequired && (
                    <div className="col-xs-12 mt-15 mr-50">
                        <p className="text-airbnb-14">{t('f:listOpportunity.ndaType')}</p>
                        <div>{Object.entries(ndaTypes).map(([key, val]) => ndaTypeViewContent(key, val))}</div>
                    </div>
                )}
                {bidingStructure?.ndaType === 2 && (
                    <div className="biding biding--padding-0 ">
                        <div className="col-xs-12 mb-120">
                            <div className="mt-20 col-xs-12-clear mb-20">
                                <MainHeading text={t('f:listOpportunity.uploadCustomNda')} addClasses="mb-20" />

                                <Upload
                                    name="Files"
                                    action={process.env.REACT_APP_API_URL + `/listopportunity/${externalId}/customnda`}
                                    customRequest={customRequest}
                                    listType="text"
                                    showUploadList={false}
                                >
                                    <p className="col-xs-12-clear listing-type__help-link">{t('f:button.upload')}</p>
                                </Upload>
                            </div>
                            {bidingStructure?.customNdaUrl && <p>{bidingStructure.customNdaUrl}</p>}
                        </div>
                    </div>
                )}
            </div>
            <ListOpportunityFooterBtns
                cancelUrl={`/property/${externalId}#${PROPERTY_TABS.PROPERTY_SUMMARY.name}`}
                isNotFirst={false}
                isNotFinish={false}
                onNextHandler={handleBidingStructurAndNextStep}
            />
        </div>
    );
};
export default Privacy;
