import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import CashFlowFaq from '../../../../interfaces/cashFlow/cashFlowFaq';
import PropertyFaq from '../../../../services/PropertyFaq';
import ModalCloseButton from '../../../../components/shared/ModalCloseButton';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';

interface Props {
    propertyAssetId: string;
    isModalOpened: boolean;
    setIsModalOpened: (isOpened: boolean) => any;
    fetchAssetFaq: () => any;
    editableQuestion?: any;
    setEditableQuestion: (object: any) => any;
    topicId: any;
}

const AddEditQuestions: FunctionComponent<Props> = (props) => {
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);
    const [addedQuestions, setAddedQuestions] = useState<any>([]);
    const [resetInputFile, setResetInputFile] = useState<any>();
    const [isFileRemoved, setIsFileRemoved] = useState<boolean>(false);
    const { t } = useTranslation(['f', 'b']);

    const uploadFileHandler = (event: any) => {
        setUploadedFiles([
            ...uploadedFiles,
            {
                questionIndex: uploadedFiles.length,
                file: event.target.files[0],
            },
        ]);
    };

    const addQuestionHandler = (values: any, setFieldValue: any) => {
        setFieldValue('question', '');
        setFieldValue('answer', '');
        setResetInputFile(Date.now());
        setAddedQuestions([...addedQuestions, { ...values }]);
    };

    const removeQuestionHandler = (question: any) => {
        const removeQuestion = addedQuestions.filter((q: string) => q != question);
        setAddedQuestions(removeQuestion);
    };

    const closeModalHandler = (resetForm: any) => {
        props.setIsModalOpened(false);
        resetForm();
        setAddedQuestions([]);
        setResetInputFile(Date.now());
        props.setEditableQuestion({});
    };

    const onCancelHanlder = () => {
        props.setIsModalOpened(false);
        props.setEditableQuestion({});
        setResetInputFile(Date.now());
    };

    const checkIfIsCreatingModal = () => {
        return Object.keys(props.editableQuestion).length == 0;
    };

    return (
        <ModalWrapper
            isCloseModalShown={false}
            className="cf-modal"
            isModalOpened={props.isModalOpened}
            title={checkIfIsCreatingModal() ? t('f:faq.addTopic') : t('f:faq.editQuestion')}
            isFooterShown={false}
            closeModalHandler={onCancelHanlder}
        >
            <Formik
                initialValues={{
                    topic: '',
                    question: props.editableQuestion.question ? props.editableQuestion.question : '',
                    answer: props.editableQuestion.answer ? props.editableQuestion.answer : '',
                }}
                enableReinitialize
                onSubmit={async (values: CashFlowFaq, { resetForm }) => {
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                        },
                    };
                    if (checkIfIsCreatingModal()) {
                        const topicId = await PropertyFaq.createFaqTopic(props.propertyAssetId, {
                            name: values.topic,
                        });

                        await Promise.all(
                            addedQuestions.map((q: any, index: number) => {
                                let formData = new FormData();
                                const fileForQuestion = uploadedFiles.find((u: any) => u.questionIndex === index);
                                if (fileForQuestion) {
                                    formData.append('File', fileForQuestion.file);
                                }
                                formData.append('Question', q.question);
                                formData.append('Answer', q.answer);
                                return PropertyFaq.createFaqQuestion(props.propertyAssetId, topicId, formData, config);
                            }, props.fetchAssetFaq())
                        );
                    } else {
                        let formData = new FormData();
                        formData.append('QuestionId', props.editableQuestion.id);
                        formData.append('IsFileDeleted', JSON.stringify(isFileRemoved));
                        formData.append('Question', values.question);
                        formData.append('Answer', values.answer);
                        if (uploadedFiles.length > 0) {
                            formData.append('File', uploadedFiles[uploadedFiles.length - 1].file);
                        }

                        await PropertyFaq.updateQuestion(props.propertyAssetId, props.topicId, formData, config);
                    }

                    props.setIsModalOpened(false);
                    props.setEditableQuestion({});
                    setAddedQuestions([]);
                    setUploadedFiles([]);
                    resetForm();
                }}
            >
                {({ values, setFieldValue, resetForm }) => (
                    <Form>
                        <ModalCloseButton handler={() => closeModalHandler(resetForm)} />

                        {checkIfIsCreatingModal() ? (
                            <div className="form-group">
                                <label htmlFor="topic">{t('f:cashFlow.topic')}</label>
                                <Field id="topic" name="topic" placeholder={t('f:cashFlow.topicName')} />
                            </div>
                        ) : null}
                        <div className="form-group">
                            <label htmlFor="question">{t('f:cashFlow.question')}</label>
                            <Field id="question" name="question" placeholder={t('f:cashFlow.topicQuestion')} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="answer">{t('f:cashFlow.answer')}</label>
                            <Field
                                as="textarea"
                                id="answer"
                                name="answer"
                                placeholder={t('f:cashFlow.answerQuestion')}
                            />
                        </div>

                        <input
                            key={resetInputFile}
                            style={{ paddingLeft: '0' }}
                            className="mb-10"
                            type="file"
                            name="file"
                            onChange={(event: any) => uploadFileHandler(event)}
                        />

                        {checkIfIsCreatingModal() ? null : isFileRemoved ? null : (
                            <label onClick={() => setIsFileRemoved(true)}>{props.editableQuestion.fileName}</label>
                        )}

                        <div className="cf-add">
                            {checkIfIsCreatingModal() && (
                                <div className="d-flex">
                                    <p>{t('f:cashFlow.addQuestion')}</p>
                                    <span
                                        className="cf-plus d-block"
                                        onClick={() => addQuestionHandler(values, setFieldValue)}
                                    >
                                        +
                                    </span>
                                </div>
                            )}
                            <div></div>
                            {addedQuestions && addedQuestions.length > 0
                                ? addedQuestions.map((question: any) => {
                                      return (
                                          <div className="d-flex align-items-center">
                                              <p key={question.question}>{question.question}</p>
                                              <span
                                                  className="cf-minus d-block"
                                                  onClick={() => removeQuestionHandler(question)}
                                              >
                                                  -
                                              </span>
                                          </div>
                                      );
                                  })
                                : null}
                        </div>

                        <div className="cf-modal__buttons m-auto">
                            <button
                                className="button-main button-secondary"
                                type="reset"
                                onClick={() => props.setIsModalOpened(false)}
                            >
                                {checkIfIsCreatingModal() ? t('f:button.remove') : t('f:button.cancel')}
                            </button>
                            <button className="button-main button-primary mxl-30" type="submit">
                                {t('f:button.save')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    );
};

export default AddEditQuestions;
