import jwtDecode, { JwtPayload } from 'jwt-decode';
import { getToken } from '../auth/UserService';
import { RolesEnum } from '../constants/roles';

interface JwtPayloadUser extends JwtPayload {
    role: string;
    companyExternalId: string;
    sub: string; // UserId
}

const needsApprovalForBidSubmission = () => {
    var token = getToken();
    if (token) {
        const decodedToken = jwtDecode<JwtPayloadUser>(token);
        var role = decodedToken['role'];
        if (role) {
            return role === RolesEnum.Member.toString();
        }
    }
    return true;
};

const getCurrentRole = () => {
    var token = getToken();
    if (token) {
        const decodedToken = jwtDecode<JwtPayloadUser>(token);
        return decodedToken['role'];
    } else return '';
};

const getUserCompanyExternalId = () => {
    var token = getToken();
    if (token) {
        const decodedToken = jwtDecode<JwtPayloadUser>(token);
        return decodedToken.companyExternalId;
    } else {
        return '';
    }
}

const getUserId = () => {
    var token = getToken();
    if (token) {
        const decodedToken = jwtDecode<JwtPayloadUser>(token);
        return decodedToken.sub;
    } else {
        return '';
    }
}

const canViewAdminPanelMenu = () => {
    const role = getCurrentRole();
    return role === RolesEnum.Admin.toString() || role === RolesEnum.SuperAdmin.toString();
};

const canAddNewCompany = () => {
    const role = getCurrentRole();
    return role === RolesEnum.SuperAdmin.toString();
};

const PermissionsService = {
    needsApprovalForBidSubmission,
    getCurrentRole,
    getUserCompanyExternalId,
    canViewAdminPanelMenu,
    canAddNewCompany,
    getUserId
};
export default PermissionsService;
