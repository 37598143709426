import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { WizardTab } from '../../interfaces/wizards';
import DirtyWizardContext from '../../context/DirtyWizardContext';
import DirtyWizardState from '../../context/state/DirtyWizardState';
import RouteParams from '../../interfaces/property/CreatePropertySummaryRouteParams';

interface BaseWizardLayoutProps {
    stepName: string;
    tabs: Array<WizardTab>;
}
interface LayoutProps {
    stepName: string;
    steps: Array<string>;
    component: JSX.Element | null;
}

const BaseWizardLayout: FunctionComponent<BaseWizardLayoutProps> = ({ stepName, tabs }) => {
    const history = useHistory();

    const redirectToNotFound = useCallback(() => {
        history.push('/not-found');
        return null;
    }, [history]);

    const findComponent = useCallback(() => {
        const component = tabs.find((el) => el.step === stepName);
        return component ? component.component : redirectToNotFound();
    }, [stepName, tabs, redirectToNotFound]);

    return (
        <DirtyWizardContext>
            <Layout stepName={stepName} steps={tabs.map((el) => el.step)} component={findComponent()} />
        </DirtyWizardContext>
    );
};

const Layout: FunctionComponent<LayoutProps> = ({ stepName, steps, component }) => {
    const { t } = useTranslation(['f', 'b']),
        { path } = useRouteMatch(),
        { id: propertyAssetId } = useParams<RouteParams>(),
        { isDirty } = useContext(DirtyWizardState),
        history = useHistory();

    const notifyUser = useCallback(() => {
        toast.error('Save changes!!!');
    }, []);

    const createRoute = useCallback(
        (step: string) => {
            const params = path.split('/');
            const newRoute = params.map((el) => {
                if (el === ':stepName') {
                    el = step;
                }
                if (el === ':id') {
                    el = propertyAssetId;
                }
                return el;
            });
            return newRoute.map((el) => el).join('/');
        },
        [path, propertyAssetId]
    );

    return (
        <div className="base-wizard-layout d-flex">
            <div className="base-wizard-layout__left">
                <div className="d-flex flex-direction-column gap-10">
                    {steps.map((step) => (
                        <p
                            onClick={() => {
                                isDirty ? notifyUser() : history.push(createRoute(step));
                            }}
                            className="base-wizard-layout__left-txt"
                        >
                            <span className={step === stepName ? 'base-wizard-layout__left-txt--active' : ''}>
                                {t(`f:wizard-steps.steps.${step}`)}
                            </span>
                        </p>
                    ))}
                </div>
            </div>
            <div className="base-wizard-layout__right">{component}</div>
        </div>
    );
};

export default BaseWizardLayout;
