import Form from 'antd/lib/form/Form';
import { Formik } from 'formik';
import { Select } from 'antd';
import { FunctionComponent, useEffect, useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ExecuteNdaService from '../../../../services/ExecuteNdaService';
import { ExecuteNDARequest } from '../../../../validations/ExecuteNDARequest';
import NdaOptions from '../../../../components/shared/NdaOptions';
import MainHeading from '../../../../components/shared/MainHeading';
import { Actions } from '../../../../interfaces/general/general';
import FeatureState from '../../../../context/state/FeatureState';

interface SignNDAModalContentProps {
    setIsModalOpen: Actions;
    showMainContent: boolean;
    setShowMainContent: Actions;
}

const SignNDAModalContent: FunctionComponent<SignNDAModalContentProps> = ({
    setIsModalOpen,
    setShowMainContent,
    showMainContent,
}) => {
    const { t } = useTranslation(['f', 'b']),
        [isLoading, setIsloading] = useState<boolean>(true),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [listOfUsers, setListOfUsers] = useState<Array<any>>([]),
        { Option } = Select,
        [canSign, setCanSign] = useState<boolean>(true);

    const fetchMyStatus = useCallback(async () => {
        try {
            const status = await ExecuteNdaService.fetchCanSign();
            setCanSign(status);
            setIsloading(false);
        } catch (error: any) {
            toast(error.response.data.error.errorMessage);
        }
    }, []);

    const fetchUsers = useCallback(async () => {
        try {
            const users = await ExecuteNdaService.fetchUsersWhoCanSign();
            setListOfUsers(users);
        } catch (error: any) {
            toast(error.response.data.error.errorMessage);
        }
    }, []);

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    useEffect(() => {
        fetchMyStatus();
        fetchUsers();
    }, [fetchUsers, fetchMyStatus]);

    if (isLoading) return <></>;

    return (
        <>
            <div className="signNDAModal">
                {canSign ? (
                    <NdaOptions
                        setIsModalOpen={setIsModalOpen}
                        setShowMainContent={setShowMainContent}
                        showMainContent={showMainContent}
                    />
                ) : (
                    <>
                        <MainHeading text={t('f:signNDA.approvalRequired')} />

                        <p className="signNDAModal__subtitle signNDAModal__subtitle--center mt-50">
                            {t('f:signNDA.approvalSubtitle')}
                        </p>
                        <Formik
                            initialValues={{
                                userId: '',
                            }}
                            enableReinitialize={true}
                            validationSchema={ExecuteNDARequest}
                            onSubmit={async (values) => {
                                try {
                                    await ExecuteNdaService.requestSignIn(`${propertyAssetId}`, values.userId);
                                    setIsModalOpen.setFalse();
                                    toast(`${t('f:signNDA.successfulySended')}`);
                                } catch (error) {
                                    toast(`${t('f:signNDA.errorWhenSelecting')}`);
                                }
                            }}
                        >
                            {({ values, errors, touched, validateForm, setFieldValue, submitForm }) => (
                                <Form className="row-flex-clear justify-content-space-between mt-40">
                                    <Select
                                        className="col-xs-12-clear"
                                        showSearch
                                        placeholder={t('f:signNDA.selectManager')}
                                        optionLabelProp="label"
                                        showArrow
                                        onSelect={(value: any) => {
                                            setFieldValue('userId', value);
                                        }}
                                        getPopupContainer={handleContainer}
                                    >
                                        {listOfUsers.map((option, index) => (
                                            <Option
                                                label={option.fullName}
                                                value={option.id}
                                                className="d-flex"
                                                key={index}
                                            >
                                                <div className="select-checkbox"></div>
                                                {option.fullName}
                                            </Option>
                                        ))}
                                        {errors.userId && touched.userId ? (
                                            <span className="error-text">
                                                {t(errors.userId, {
                                                    name: t('f:signNDA.userId'),
                                                })}
                                            </span>
                                        ) : null}
                                    </Select>
                                    <div className="col-xs-12 d-flex justify-content-space-around mt-100">
                                        <button
                                            className="button-main button-secondary"
                                            type="button"
                                            onClick={() => {
                                                setIsModalOpen.setFalse();
                                            }}
                                        >
                                            {t('f:button.cancel')}
                                        </button>
                                        <button
                                            className="button-main button-primary"
                                            type="submit"
                                            onClick={() => {
                                                submitForm();
                                            }}
                                        >
                                            {t('f:signNDA.sendRequest')}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>
                )}
            </div>
        </>
    );
};
export default SignNDAModalContent;
