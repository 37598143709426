import { Popover } from 'antd';
import { FunctionComponent, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import FeatureState from '../../../../context/state/FeatureState';
import { isMobile } from 'react-device-detect';

interface Props {
    topicName: string;
    openEditableModal: Function;
    deleteTopicHandler: Function;
    isThreeDotClicked: boolean;
    setIsThreeDotClicked: (state: boolean) => void;
    topicId: string;
}

const FaqThreeDotMenuTopic: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']);
    const { getFeature } = useContext(FeatureState);
    const content = (
        <div>
            <span
                style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                onClick={() => {
                    props.setIsThreeDotClicked(false);
                    props.openEditableModal(props.topicName);
                }}
            >
                {t('f:button.edit')}
            </span>
            <br></br>
            <span
                style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                onClick={(e) => {
                    props.setIsThreeDotClicked(false);
                    props.deleteTopicHandler(props.topicId);
                }}
            >
                {t('f:button.remove')}
            </span>
        </div>
    );

    return (
        <div>
            {getFeature('FaqEdit') && !isMobile && (
                <>
                    <div style={{ marginLeft: '20px' }}>
                        <Popover
                            content={content}
                            placement="bottom"
                            trigger="click"
                            visible={props.isThreeDotClicked}
                            onVisibleChange={(visible) => props.setIsThreeDotClicked(visible)}
                        >
                            <FontAwesomeIcon
                                className="mxl-10"
                                style={{ color: '#a1abbd', cursor: 'pointer' }}
                                icon={faEllipsisH}
                                size="lg"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.isThreeDotClicked
                                        ? props.setIsThreeDotClicked(false)
                                        : props.setIsThreeDotClicked(true);
                                }}
                    ></FontAwesomeIcon>
                        </Popover>
                    </div>
                </>
            )}
        </div>
    );
};

export default FaqThreeDotMenuTopic;
