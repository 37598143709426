import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainButton from '../../../../components/shared/MainButton';

interface Props {
    isNotFirst: boolean;
    onNextHandler: () => void;
    onNextHandlerDisabled?: boolean;
    isNotFinish: boolean;
    returnToPreviousPage?: () => void;
    cancelUrl: string;
}
const ListOpportunityFooterBtns: FunctionComponent<Props> = ({
    onNextHandler,
    isNotFirst,
    isNotFinish,
    onNextHandlerDisabled,
    returnToPreviousPage,
    cancelUrl,
}) => {
    const { t } = useTranslation('f'),
        history = useHistory();

    const handleOnCancelClick = useCallback(() => {
        history.push(cancelUrl);
    }, [history, cancelUrl]);

    return (
        <div className="d-flex justify-content-end align-items-center button-wrap">
            {isNotFirst && (
                <p className="previous-page ml-20" onClick={returnToPreviousPage}>
                    {t('f:button.previousStep')}
                </p>
            )}

            <MainButton
                classAdditions="mr-25"
                layout="secondary"
                size="main"
                buttonHandler={handleOnCancelClick}
                buttonText={t('f:button.cancel')}
            />
            <MainButton
                classAdditions="mr-25"
                layout="primary"
                size="main"
                buttonHandler={onNextHandler}
                disabled={onNextHandlerDisabled}
                buttonText={isNotFinish ? t('f:button.finish') : t('f:button.next')}
            />
        </div>
    );
};

export default ListOpportunityFooterBtns;
