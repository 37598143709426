export const getEditorImageConfiguration = (templateKey:string) => {
    switch(templateKey) {
        case 'left-heading-editor':
            return {
                toolbar: [
                        'imageStyle:alignRight'
                    ],
                styles: [
                    'alignRight',
                ],
                upload: {
                    types: [ 'png', 'jpeg' ]
                }
                }
        case 'right-heading-editor':
            return {
                toolbar: [
                        'imageStyle:alignLeft'
                    ],
                styles: [
                    'alignLeft',
                ],
                upload: {
                    types: [ 'png', 'jpeg' ]
                }
            }      
        default:
            return {
                toolbar: [
                        'imageStyle:alignCenter'
                    ],
                styles: [],
                upload: {
                    types: [ 'png', 'jpeg' ]
                }
            }     
    }

}