import * as Yup from 'yup';

export const BidInfomationsValidation = Yup.object().shape({
    offerTypeId: Yup.number().required('f:validation.required'),
    bidAmount: Yup.number()
        .transform((o, v) => parseInt(v.replace(/,/g, '')))
        .min(1)
        .typeError('f:validation.number')
        .required('f:validation.required'),
    initialDeposit: Yup.number()
        .transform((o, v) => parseInt(v.replace(/,/g, '')))
        .typeError('f:validation.number')
        .required('f:validation.required'),
    currencyId: Yup.number().required('f:validation.required'),
    transactionTypeId: Yup.number().required('f:validation.required'),
    noi: Yup.string().typeError('f:validation.string').required('f:validation.required'),
    buyerAssumedCapRateInitialYield: Yup.number()
        .transform((o, v) => parseInt(v.replace(/,/g, '')))
        .typeError('f:validation.number')
        .required('f:validation.required'),
    totalNetArea: Yup.number()
        .transform((o, v) => parseInt(v.replace(/,/g, '')))
        .typeError('f:validation.number')
        .required('f:validation.required'),
    acquisitionTypeId: Yup.number().required('f:validation.required'),
    acquisitionStructure: Yup.string().typeError('f:validation.string').required('f:validation.required'),
    additionalDeposit: Yup.number()
        .transform((o, v) => parseInt(v.replace(/,/g, '')))
        .transform((o, v) => parseInt(v.replace(/,/g, '')))
        .typeError('f:validation.number')
        .required('f:validation.required'),
    closingNumberOfDays: Yup.number()
        .transform((o, v) => parseInt(v.replace(/,/g, '')))
        .typeError('f:validation.number')
        .required('f:validation.required'),
    dueDilligenceNumberOfDays: Yup.number()
        .transform((o, v) => parseInt(v.replace(/,/g, '')))
        .typeError('f:validation.number')
        .required('f:validation.required'),
    agreementNumberOfDays: Yup.number()
        .transform((o, v) => parseInt(v.replace(/,/g, '')))
        .typeError('f:validation.number')
        .required('f:validation.required'),
});
