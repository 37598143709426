import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmptyComponent from '../../components/shared/ComingSoon';
import MainLoader from '../../components/shared/MainLoader';
import Tabs from '../../components/shared/Tabs';
import { defaultTabs } from '../../constants/ExploreDataNav';
import TemplateContext from '../../context/TemplateContext';
import useBoolean from '../../hooks/use-boolean';
import { PagesCont } from '../../interfaces/DataAnalytics/Pages';
import Tab from '../../interfaces/general/Tab';
import DataAnalyticPageBuilderService from '../../services/DataAnalyticPageBuilderService';
import ArcGisComponent from './ArcGis';
import PowerBiReport from './Power-Bi';

const ViewExploreData = () => {
    const history = useHistory(),
        { t } = useTranslation(['f', 'b']),
        [allPages, setAllPages] = useState<Array<Tab>>([]),
        [templateData] = useState<Array<any>>([]),
        [isPageLoad, setIsPageLoadActions] = useBoolean(),
        location = useLocation();
    let activTab = location.hash ? location.hash.replace('#', '') : '1';

    const tabSelected = (value: any) => {
        history.push({ hash: value });
    };

    const fetchPages = useCallback(async () => {
        try {
            const _pages: PagesCont = await DataAnalyticPageBuilderService.fetchPages();
            const shownPages: Array<Tab> = [];
            _pages.items.forEach((elem) => {
                let tab = defaultTabs.find((tab) => tab.name === elem.name);

                if (tab) {
                    tab.id = elem.id;
                    shownPages.push(tab);
                }
            });
            shownPages.push(
                {
                    id: 1,
                    icon: '',
                    name: 'dataAnalytics.oxfordEconomics',
                    component: <PowerBiReport />,
                },
                {
                    id: 2,
                    icon: '',
                    name: 'dataAnalytics.buildingDirectory',
                    component: (
                        <ArcGisComponent
                            url="/building-directory"
                            width="100%"
                            height="100%"
                            title="Building Directory"
                        />
                    ),
                },
                {
                    id: 3,
                    icon: '',
                    name: 'dataAnalytics.transactionData',
                    component: (
                        <ArcGisComponent
                            url="/transaction-data"
                            width="100%"
                            height="100%"
                            title="Transaction Data"
                        />
                    ),
                },
                // {
                //     id: 3,
                //     icon: '',
                //     name: 'Building Directory',
                //     component: <EmptyComponent />,
                // },
                {
                    id: 4,
                    icon: '',
                    name: 'dataAnalytics.pricingInformation',
                    component: <EmptyComponent />,
                },
                {
                    id: 5,
                    icon: '',
                    name: 'dataAnalytics.incomeInformation',
                    component: <EmptyComponent />,
                },
                {
                    id: 6,
                    icon: '',
                    name: 'dataAnalytics.automatedValuations',
                    component: <EmptyComponent />,
                },
                {
                    id: 7,
                    icon: '',
                    name: 'dataAnalytics.predictability',
                    component: <EmptyComponent />,
                }
            );
            setAllPages(shownPages);
            setIsPageLoadActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t, setIsPageLoadActions]);

    useEffect(() => {
        fetchPages();
    }, [fetchPages]);

    return (
        <div className="explore-data-page d-flex pt-50">
            <div className="explore-data-page__main">
                {isPageLoad ? (
                    <div className="explore-data-page__main mt-20">
                        <TemplateContext>
                            <Tabs tabs={allPages} onTabSelected={tabSelected} defaultActiveKey={activTab} />
                        </TemplateContext>
                    </div>
                ) : (
                    <MainLoader type="regular" mb="mb-100" />
                )}
            </div>
        </div>
    );
};

export default ViewExploreData;
