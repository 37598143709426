import api from '../index';

const getAllCompaniesWithInvestor = (propertyAssetId: string, page: number, pageSize: number) => {
    const config = {
        params: {
            pageIdx: page,
            itemsPerPage: pageSize,
        },
    };

    return api.get(`/investor/company/property/${propertyAssetId}`, config);
};

const getInvestorUsers = (propertyAssetId: string, page: number, pageSize: number) => {
    const config = {
        params: {
            pageIdx: page,
            itemsPerPage: pageSize,
        },
    };

    return api.get(`/investor/user/property/${propertyAssetId}`, config);
};

const addInvestor = (propertyAssetId: string, body: any) => {
    return api.post(`/investor/${propertyAssetId}`, body);
};

const exportInvestors = (propertyAssetId: string) => {
    return api.get(`/investor/excel/${propertyAssetId}`, { responseType: 'blob' });
};

const rejectInvitation = (propertyAssetId: string) => {
    return api.put(`/investor/reject/${propertyAssetId}`);
};

const removeUserFromInvestor = (propertyAssetId: string, body: any) => {
    return api.post(`/investor/remove/${propertyAssetId}`, body);
};

const toggleNDAstatusUser = (propertyAssetId: string, investorId: string) => {
    return api.patch(`/investor/togglenda/${propertyAssetId}/user/${investorId}`);
};

const sendEmails = (propertyAssetId: string, body: any) => {
    return api.post(`/investor/${propertyAssetId}/email`, body);
};

const getAnalysisSummaryStat = (propertyAssetId: string) => {
    return api.get(`/investor/${propertyAssetId}/analysis-stat`);
}

const InvestorApi = {
    getAllCompaniesWithInvestor,
    getInvestorUsers,
    addInvestor,
    exportInvestors,
    rejectInvitation,
    removeUserFromInvestor,
    toggleNDAstatusUser,
    sendEmails,
    getAnalysisSummaryStat
};

export default InvestorApi;
