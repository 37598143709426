import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Formik, Field, Form, FieldProps } from 'formik';
import SingleSelect from '../../../components/shared/SingleSelect';
import Scheduler from '../../../services/Scheduler';
import { ScheduleShema } from '../../../validations/Schedule';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainHeading from '../../../components/shared/MainHeading';
import MainLoader from '../../../components/shared/MainLoader';
import FeatureState from '../../../context/state/FeatureState';

interface RouteParams {
    id: string;
    scheduleId?: string;
}

export const SchedulingComponent: FunctionComponent<any & FieldProps> = ({ props, setFieldValue }) => {
    const history = useHistory(),
        { t } = useTranslation(['f', 'b']),
        [allTeamUsers, setAllTeamUsers] = useState<Array<any>>([]),
        [scheduleType, setScheduleType] = useState<any>(null),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        [isLoading, setIsLoading] = useState<boolean>(true);
    let params = useParams<RouteParams>();

    useEffect(() => {
        const fetchUsersFromMyCompany = async () => {
            const resp = await Scheduler.fetchAllTeamUsers(`${propertyAssetId}`);
            const users = resp.map((elem: any) => {
                return elem.user;
            });
            setAllTeamUsers(users);
        };
        fetchUsersFromMyCompany();
        fetchScheduleType();
    }, []);

    const fetchScheduleType = async () => {
        if (params.scheduleId) {
            let eventType = await Scheduler.fetchScheduleType(`${propertyAssetId}`, params.scheduleId);
            setScheduleType(eventType);
        }
        setIsLoading(false);
    };
    return (
        <div className="wizard-main-wrap">
            {isLoading ? (
                <MainLoader type="regular" mt="mt-100" />
            ) : (
                <div>
                    <MainHeading text={t('f:schedule.scheduling')} />

                    <Formik
                        initialValues={{
                            name: scheduleType ? `${scheduleType.name}` : '',
                            eventTypeId: scheduleType ? `${scheduleType.eventTypeId}` : '',
                            userId: scheduleType ? `${scheduleType.user.id}` : '',
                        }}
                        enableReinitialize={true}
                        validationSchema={ScheduleShema}
                        validateOnMount
                        onSubmit={async (values: any) => {
                            try {
                                if (params.scheduleId) {
                                    await Scheduler.updateScheduleType(`${propertyAssetId}`, params.scheduleId, values);
                                } else {
                                    await Scheduler.createScheduleType(`${propertyAssetId}`, {
                                        userId: values.userId,
                                        name: values.name,
                                    });
                                }
                                history.push(`/property/${externalId}#schedule`);
                            } catch (error: any) {
                                toast(t(`b:${error.response.data.errorMessage}`));
                            }
                        }}
                    >
                        {({ errors, touched, values, isValidating, setFieldValue, validateForm }) => (
                            <Form className="row wizard-form create-edit-property-form">
                                <div className="col-md-7 form-group">
                                    <label htmlFor="name">{t('f:schedule.scheduleType')}</label>
                                    <Field id="name" name="name" placeholder={t('f:schedule.enterScheduleType')} />
                                    {errors && errors.name && touched.name ? (
                                        <span className="error-text">
                                            {t(errors.name, { name: t('f:schedule.scheduleType') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-md-6 form-group">
                                    <SingleSelect
                                        label={t('f:schedule.memberOfYourTeam')}
                                        placeholder={t('f:schedule.selectMemberOfYourTeam')}
                                        options={allTeamUsers}
                                        selected={values.userId ? values.userId : null}
                                        onSelected={(value: any) => setFieldValue('userId', value)}
                                    />
                                    {errors.userId && touched.userId ? (
                                        <span className="error-text">
                                            {t(errors.userId, { name: t('f:schedule.selectMemberOfYourTeam') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap">
                                    <button
                                        className="button-main button-secondary d-flex justify-content-center align-items-center"
                                        type="button"
                                        onClick={() => {
                                            history.push(`/property/${externalId}#schedule`);
                                        }}
                                    >
                                        {t('f:button.cancel')}
                                    </button>
                                    <button
                                        className="button-main button-primary mr-10"
                                        onClick={() => validateForm()}
                                        type="submit"
                                    >
                                        {t('f:button.finish')}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </div>
    );
};
