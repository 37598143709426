import { AccountInfo, SilentRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react"
import { Scopes } from "../auth/authConfig";

const useCheckUserLogged = () => {
    const { instance, accounts } = useMsal();
    const [isLogged, setIsLogged] = useState(false);

    const fetch = async () => {
        let account = accounts.length ? (accounts[0] as AccountInfo) : null;
        if (account) {
            let request: SilentRequest = {
                scopes: Scopes.api,
                account: account,
            };
            let authenticationResult = await instance.acquireTokenSilent(request);
            if (authenticationResult && authenticationResult.accessToken) {
                setIsLogged(true);
            } else {
                setIsLogged(false);
            }
        } else {
            setIsLogged(false);
        }
    }

    useEffect(() => {
        fetch();
    }, []);

    return isLogged;
}

export default useCheckUserLogged;