import { FunctionComponent, useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, Button } from 'antd';
import NavigateState from '../../../context/state/NavigateState';
import { useParams } from 'react-router';
import BidService from '../../../services/BidService';
import FileUpload from '../../../services/FileUploadService';
import { toast } from 'react-toastify';
import MainHeading from '../../../components/shared/MainHeading';
import MainLoader from '../../../components/shared/MainLoader';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';

interface Props {
    goToSelectBidOverview: (biddingId: string) => void;
}

interface RouteParams {
    id: string;
    biddingId: string;
}

const FinanceDetails: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f']),
        [isSecondaryFinancing, setSecondaryFinancing] = useState<any>(true),
        [docTypes, setDocTypes] = useState<Array<any>>([]),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        { biddingId } = useParams<RouteParams>(),
        [isSecondaryFinacingLoaded, setIsSecondaryFinacingLoaded] = useState<boolean>(false),
        { cancelEverything, returnToPreviousPage } = useContext(NavigateState),
        [isLoading, setIsLoading] = useState(true);

    const customRequest = async (options: any, biddingDocumentTypeId: any) => {
        try {
            const data = new FormData();
            data.append('biddingDocumentTypeId', biddingDocumentTypeId);
            data.append('File', options.file);
            await FileUpload.uploadFile(options, data);
            toast.success(t(`f:bid.uploadFile`));
        } catch (error) {
            toast(t(`f:bid.failedToUploadFile`));
        }
    };

    const secondaryFinancing = async () => {
        try {
            await BidService.createBiddingSecundary(`${propertyAssetId}`, biddingId, {
                secundaryFinancing: isSecondaryFinancing,
            });
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    const fetchBidInfo = useCallback(async () => {
        try {
            const resp = await BidService.fetchBiddingInformations(`${propertyAssetId}`, biddingId);

            setSecondaryFinancing(resp.secondaryFinancing);
            setIsSecondaryFinacingLoaded(true);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, biddingId, t]);

    const handleNext = () => {
        secondaryFinancing();
        props.goToSelectBidOverview(biddingId);
    };

    const fetchTypesOfDocuments = useCallback(async () => {
        try {
            const types = await BidService.fetchTypesOfDocuments(`${propertyAssetId}`);

            setDocTypes(types);
            setIsLoading(false);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t, propertyAssetId]);

    useEffect(() => {
        fetchBidInfo();
        fetchTypesOfDocuments();
    }, [fetchBidInfo, fetchTypesOfDocuments]);

    return (
        <div className="finance-details-page biding">
            <MainHeading text={t('f:bid.financeDetails')} addClasses="mb-40" />

            {!isLoading ? (
                <div>
                    <label htmlFor="hide" className="listing-type__form-label">
                        {t('f:bid.financeDetailsForm.secondaryFinancing')}
                    </label>
                    <div className="finance-details-page__form d-flex flex-direction-column">
                        {isSecondaryFinacingLoaded ? (
                            <div className="finance-details-page__group-sm d-flex">
                                <div className="d-flex listing-type__form align-items-center">
                                    <input
                                        type="radio"
                                        id="team"
                                        name="bid"
                                        checked={isSecondaryFinancing}
                                        value="true"
                                        className="mr-10 mt-3"
                                        onChange={(e: any) => {
                                            setSecondaryFinancing(true);
                                        }}
                                    />
                                    <div className="d-flex">
                                        <label htmlFor="hide" className="listing-type__form-label">
                                            {t('f:bid.financeDetailsForm.yes')}
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex listing-type__form align-items-center">
                                    <input
                                        type="radio"
                                        id="team"
                                        name="bid"
                                        checked={!isSecondaryFinancing}
                                        value="false"
                                        className="mr-10 mt-3"
                                        onChange={(e: any) => {
                                            setSecondaryFinancing(false);
                                        }}
                                    />
                                    <div className="d-flex flex-direction-column">
                                        <label htmlFor="hide" className="listing-type__form-label">
                                            {t('f:bid.financeDetailsForm.no')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <MainLoader type="regular" />
                        )}
                        {docTypes.map((type) => {
                            return (
                                <div>
                                    <p className="heading mb-30">{`${type.biddingDocumentName}`}</p>
                                    <Upload
                                        name="proofOfFunds"
                                        action={
                                            process.env.REACT_APP_API_URL +
                                            `/bidding/${propertyAssetId}/${biddingId}/documents`
                                        }
                                        customRequest={(option) => {
                                            customRequest(option, type.biddingDocumentTypeId);
                                        }}
                                        listType="text"
                                        showUploadList={false}
                                        multiple
                                    >
                                        <p className="col-xs-12-clear listing-type__help-link">
                                            {t('f:button.upload')}
                                        </p>
                                    </Upload>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <MainLoader type="regular" />
            )}
            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                <p className="previous-page" onClick={() => returnToPreviousPage()}>
                    {t('f:button.previousStep')}
                </p>
                <Button
                    onClick={() => {
                        cancelEverything(externalId, PROPERTY_TABS.TRANSACTION.name);
                    }}
                    className="button-main button-secondary d-flex justify-content-center align-items-center"
                >
                    {t('f:button.cancel')}
                </Button>
                <Button
                    className="button-main button-primary mr-25"
                    onClick={() => {
                        handleNext();
                    }}
                >
                    {t('f:button.next')}
                </Button>
            </div>
        </div>
    );
};
export default FinanceDetails;
