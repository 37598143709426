import { faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload } from 'antd';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import varibales from '../../../assets/styles/general/_variable.module.scss';
import ModalImportLoading from '../../../components/shared/ModalImportLoading';
import useBoolean from '../../../hooks/use-boolean';
import UserService from '../../../services/User';

interface UploadProfilePhotoProps {
    setFieldValue: (name: string, val: any) => void;
    imageUrl: string;
    name: string;
    type: 'personalDetails' | 'companyDetails';
}

const UploadProfilePhoto: FunctionComponent<UploadProfilePhotoProps> = ({ type, imageUrl, setFieldValue, name }) => {
    const { t } = useTranslation('f'),
        [isUploading, setIsUploading] = useBoolean();

    const customRequest = useCallback(
        async (options: any, setFieldValue) => {
            setIsUploading.setTrue();
            try {
                const data = new FormData();
                data.append('Image', options.file);
                const resp = await UserService.uploadImage(options, data);
                setFieldValue(name, resp);
                toast.success(t(`f:bid.uploadFile`));
                setIsUploading.setFalse();
            } catch (error) {
                toast.error(t(`f:bid.failedToUploadFile`));
                setIsUploading.setFalse();
            }
        },
        [t, name]
    );

    return (
        <div className="col-xs-12 form-group">
            <label style={{ fontSize: '10px' }} htmlFor={name}>
                {t(`f:account-settings.${type}.${name}`)}
            </label>
            <Upload
                action={process.env.REACT_APP_API_URL + `/user/image`}
                customRequest={(option) => {
                    customRequest(option, setFieldValue);
                }}
                listType="text"
                showUploadList={false}
            >
                <div className="d-flex align-items-center pointer">
                    <div className="profile-details__profile-picture-container">
                        {imageUrl ? (
                            <img src={imageUrl} alt="user" />
                        ) : (
                            <FontAwesomeIcon icon={faUser} className="profile-details__profile-picture-icon" />
                        )}
                    </div>

                    <FontAwesomeIcon icon={faPlus} size="2x" color={varibales.green} className="ml-20" />
                </div>
            </Upload>
            <ModalImportLoading text={t(`f:loaders.uploading`)} isModalOpened={isUploading} />
        </div>
    );
};
export default UploadProfilePhoto;
