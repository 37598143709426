import { FunctionComponent, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChashFlowListItemI } from '../../../../../interfaces/cashFlow/ChashFlowListItem';
import useBoolean from '../../../../../hooks/use-boolean';
import ModalConfirmDelete from '../../../../../components/shared/ModalConfirmDelete';

interface TreeDotsCashFlowProps {
    editHandler: (props: ChashFlowListItemI) => void;
    deleteHandler: (id: string) => void;
    record: ChashFlowListItemI;
}
const TreeDotsCashFlow: FunctionComponent<TreeDotsCashFlowProps> = ({ record, editHandler, deleteHandler }) => {
    const { t } = useTranslation(['f', 'b']),
        [isRemoveModalOpen, isRemoveModalOpenActions] = useBoolean(),
        [isThreeDotClicked, setIsThreeDotClickedActions] = useBoolean();

    const handleOnEditClick = useCallback(() => {
        editHandler(record);
        isRemoveModalOpenActions.setFalse();
    }, [editHandler, record, isRemoveModalOpenActions]);

    const handleOnDeleteClick = useCallback(() => {
        isRemoveModalOpenActions.setTrue();
    }, [isRemoveModalOpenActions]);

    const handleOnConfirmDeleteClick = useCallback(() => {
        deleteHandler(`${record.cashFlowId}`);
        isRemoveModalOpenActions.setFalse();
    }, [isRemoveModalOpenActions, deleteHandler, record.cashFlowId]);

    const handleTogleTreeDotsMenu = useCallback(() => {
        setIsThreeDotClickedActions.toggle();
    }, [setIsThreeDotClickedActions]);

    const handleMouseLeave = useCallback(() => {
        setIsThreeDotClickedActions.setFalse();
    }, [setIsThreeDotClickedActions]);

    const content = (
        <div className="d-flex flex-direction-column" onMouseLeave={handleMouseLeave}>
            <span style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }} onClick={handleOnEditClick}>
                {t('f:button.edit')}
            </span>
            <span style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }} onClick={handleOnDeleteClick}>
                {t('f:button.remove')}
            </span>
        </div>
    );

    return (
        <div className="">
            <FontAwesomeIcon
                className="mxl-10"
                style={{ color: '#a1abbd', cursor: 'pointer' }}
                icon={faEllipsisH}
                size="lg"
                onClick={handleTogleTreeDotsMenu}
            ></FontAwesomeIcon>
            <div style={{ marginLeft: '20px' }}>
                <Popover content={content} placement="bottom" trigger="click" visible={isThreeDotClicked}></Popover>
            </div>

            <ModalConfirmDelete
                isModalOpened={isRemoveModalOpen}
                setIsModalOpenedActions={isRemoveModalOpenActions}
                text={t('f:property.confirmSectionDelete')}
                handleFinish={handleOnConfirmDeleteClick}
            />
        </div>
    );
};

export default TreeDotsCashFlow;
