import FaqApi from '../api/routes/FaqApi';

const fetchFaqTopics = async (id: string) => {
    const { data } = await FaqApi.fetchFaqTopics(id);
    return data;
};

const fetchTopicQuestions = async (topicId: string, propertyAssetId: string) => {
    const { data } = await FaqApi.fetchTopicQuestions(topicId, propertyAssetId);
    return data;
};

const fetchAllTopicsQuestions = async (propertyAssetId: string, searchText: string) => {
    try {
        const { data } = await FaqApi.fetchAllTopicsQuestions(propertyAssetId, searchText);
        return data;
    } catch (err) {
        console.log(err);
    }
};

const createFaqQuestion = async (propertyAssetId: string, topicId: any, body: any, config: any) => {
    try {
        const { data } = await FaqApi.createFaqQuestion(propertyAssetId, topicId, body, config);

        return data;
    } catch (err) {
        console.log(err);
    }
};

const createFaqTopic = async (propertyAssetId: string, body: any) => {
    try {
        const { data } = await FaqApi.createFaqTopic(propertyAssetId, body);
        return data;
    } catch (err) {
        console.log(err);
    }
};

const deleteTopic = async (propertyAssetId: string, topicId: string) => {
    try {
        return await FaqApi.deleteTopic(propertyAssetId, topicId);
    } catch (err) {
        console.log(err);
    }
};

const updateTopic = async (propertyAssetId: string, body: any) => {
    try {
        return await FaqApi.updateTopic(propertyAssetId, body);
    } catch (err) {
        console.log(err);
    }
};

const deleteQuestion = async (propertyAssetId: string, topicId: string, questionId: string) => {
    try {
        return await FaqApi.deleteQuestion(propertyAssetId, topicId, questionId);
    } catch (err) {
        console.log(err);
    }
};

const updateQuestion = async (propertyAssetId: string, topicId: any, body: any, config: any) => {
    try {
        const { data } = await FaqApi.updateQuestion(propertyAssetId, topicId, body, config);
        return data;
    } catch (err) {
        console.log(err);
    }
};
const PropertyFaqService = {
    fetchFaqTopics,
    fetchTopicQuestions,
    createFaqQuestion,
    createFaqTopic,
    deleteTopic,
    updateTopic,
    deleteQuestion,
    updateQuestion,
    fetchAllTopicsQuestions,
};

export default PropertyFaqService;
