import { FunctionComponent } from "react";
import TransactionDataContext from "../../context/TransactionDataContext";
import CheckAuthorization from "../StatusPages/CheckAuthorization";
import SearchingMap from "./SearchingMap";
import TransactionDetails from "./TransactionDetails";

const TransactionData: FunctionComponent = () => {
    return (
        <CheckAuthorization>
            <div className="building-directory pb-0">
                <SearchingMap>
                    <TransactionDataContext>
                        <TransactionDetails />
                    </TransactionDataContext>
                </SearchingMap>
            </div>
        </CheckAuthorization>
    );
}

export default TransactionData;