import { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

var data = {
    '2015': {
        'item 1': -7267,
        'item 2': 45116,

        quantity: 5,
    },
    '2016': {
        'item 1': 82137,
        'item 2': 32944,
        quantity: 6,
    },
    '2017': {
        'item 1': 212926,
        'item 2': 77721,
        quantity: 10,
    },
    '2018': {
        'item 1': 61688,
        'item 2': 162872,
        quantity: 7,
    },
    '2019': {
        'item 1': -8607,
        'item 2': 80683,
        quantity: 4,
    },
    '2020': {
        'item 1': 60065,
        'item 2': 60000,
        quantity: 8,
    },
    '2021 F': {
        'item 1': 28993,
        'item 2': -50000,
        quantity: 7,
    },
    '2022 F': {
        'item 1': 146144,
        'item 2': 70000,
        quantity: 9,
    },
    '2023 F': {
        'item 1': -30000,
        'item 2': 90000,
        quantity: 5,
    },
    '2024 F': {
        'item 1': 66341,
        'item 2': 50000,
        quantity: 6,
    },
};
const OverviewChart = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end

        var chart = am4core.create('overviewChartDiv', am4charts.XYChart);

        // some extra padding for range labels
        chart.paddingBottom = 50;

        chart.cursor = new am4charts.XYCursor();
        // chart.scrollbarX = new am4core.Scrollbar();
        // chart.scrollbarY = new am4core.Scrollbar();

        // will use this to store colors of the same items
        var colors = {
            'item 1': chart.colors.getIndex(2),
            'item 2': chart.colors.getIndex(10),
        };

        var categoryAxis: any = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataItems.template.text = '{realName}';
        categoryAxis.adapter.add('tooltipText', function (tooltipText, target) {
            return categoryAxis.tooltipDataItem.dataContext.realName;
        });

        var valueAxis: any = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.min = -100000;

        // single column series for all data
        var columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.columns.template.width = am4core.percent(80);
        columnSeries.tooltipText = '{provider}: {valueY}';
        columnSeries.dataFields.categoryX = 'category';
        columnSeries.dataFields.valueY = 'value';

        // second value axis for quantity
        var valueAxis2: any = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.renderer.opposite = true;
        valueAxis2.syncWithAxis = valueAxis;
        valueAxis2.tooltip.disabled = true;

        // quantity line series
        var lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.tooltipText = '{valueY}';
        lineSeries.dataFields.categoryX = 'category';
        lineSeries.dataFields.valueY = 'quantity';
        lineSeries.yAxis = valueAxis2;
        lineSeries.bullets.push(new am4charts.CircleBullet());
        lineSeries.stroke = am4core.color('grey').lighten(0.5);
        lineSeries.fill = lineSeries.stroke;
        lineSeries.strokeWidth = 2;
        lineSeries.snapTooltip = true;

        // when data validated, adjust location of data item based on count
        lineSeries.events.on('datavalidated', function () {
            lineSeries.dataItems.each(function (dataItem: any) {
                // if count divides by two, location is 0 (on the grid)
                if (dataItem.dataContext.count / 2 == Math.round(dataItem.dataContext.count / 2)) {
                    dataItem.setLocation('categoryX', 0);
                }
                // otherwise location is 0.5 (middle)
                else {
                    dataItem.setLocation('categoryX', 0.5);
                }
            });
        });

        // fill adapter, here we save color value to colors object so that each time the item has the same name, the same color is used
        columnSeries.columns.template.adapter.add('fill', function (fill, target: any) {
            var name = target.dataItem.dataContext.category.split('_')[1];

            if (!colors[name]) {
                colors[name] = chart.colors.next();
            }
            target.stroke = colors[name];

            return colors[name];
        });

        var rangeTemplate = categoryAxis.axisRanges.template;
        rangeTemplate.tick.disabled = false;
        rangeTemplate.tick.location = 0;
        rangeTemplate.tick.strokeOpacity = 0.6;
        rangeTemplate.tick.length = 60;
        rangeTemplate.grid.strokeOpacity = 0.5;
        rangeTemplate.label.tooltip = new am4core.Tooltip();
        rangeTemplate.label.tooltip.dy = -10;
        rangeTemplate.label.cloneTooltip = false;

        ///// DATA
        var chartData: any = [];
        var lineSeriesData: any = [];

        // process data ant prepare it for the chart
        for (var providerName in data) {
            var providerData = data[providerName];

            // add data of one provider to temp array
            var tempArray: any = [];
            var count = 0;
            // add items
            for (var itemName in providerData) {
                if (itemName != 'quantity') {
                    count++;
                    // we generate unique category for each column (providerName + "_" + itemName) and store realName
                    tempArray.push({
                        category: providerName + '_' + itemName,
                        // realName: itemName,
                        value: providerData[itemName],
                        provider: providerName,
                    });
                }
            }
            // sort temp array
            // tempArray.sort(function (a, b) {
            //     if (a.value > b.value) {
            //         return 1;
            //     } else if (a.value < b.value) {
            //         return -1;
            //     } else {
            //         return 0;
            //     }
            // });

            // add quantity and count to middle data item (line series uses it)
            var lineSeriesDataIndex = Math.floor(count / 2);
            tempArray[lineSeriesDataIndex].quantity = providerData.quantity;
            tempArray[lineSeriesDataIndex].count = count;
            // push to the final data
            am4core.array.each(tempArray, function (item: any) {
                chartData.push(item);
            });

            // create range (the additional label at the bottom)
            var range = categoryAxis.axisRanges.create();
            range.category = tempArray[0].category;
            range.endCategory = tempArray[tempArray.length - 1].category;
            range.label.text = tempArray[0].provider;
            range.label.dy = 50;
            range.label.truncate = true;
            range.label.fontWeight = 'bold';
            range.label.tooltipText = tempArray[0].provider;

            range.label.adapter.add('maxWidth', function (maxWidth, target) {
                var range = target.dataItem;
                var startPosition = categoryAxis.categoryToPosition(range.category, 0);
                var endPosition = categoryAxis.categoryToPosition(range.endCategory, 1);
                var startX = categoryAxis.positionToCoordinate(startPosition);
                var endX = categoryAxis.positionToCoordinate(endPosition);

                return endX - startX;
            });
        }

        chart.data = chartData;

        // last tick
        var range = categoryAxis.axisRanges.create();
        range.category = chart.data[chart.data.length - 1].category;
        range.label.disabled = true;
        range.tick.location = 1;
        range.grid.location = 1;

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <>
            <div id="overviewChartDiv" style={{ width: '100%', height: '500px' }}></div>
            <div className="d-flex justify-content-center">
                <div className="d-flex">
                    <div className="marketYieldChartDiv__item d-flex mr-20 align-items-center">
                        <div className="marketYieldChartDiv__item-color marketYieldChartDiv__item-color--blue mr-10"></div>
                        <div className="marketYieldChartDiv__item-text">Net Increase</div>
                    </div>
                    <div className="marketYieldChartDiv__item d-flex mr-20 align-items-center">
                        <div className="marketYieldChartDiv__item-color marketYieldChartDiv__item-color--orange mr-10"></div>
                        <div className="marketYieldChartDiv__item-text">Net Absoption</div>
                    </div>
                    <div className="marketYieldChartDiv__item d-flex d-flex align-items-center">
                        <div className="marketYieldChartDiv__item-color marketYieldChartDiv__item-color--total mr-10"></div>
                        <div className="marketYieldChartDiv__item-text">Vacancy Rate %</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OverviewChart;
