import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getToken } from '../auth/UserService';
import MainLoader from '../components/shared/MainLoader';
import ModalImportLoading from '../components/shared/ModalImportLoading';
import { DEFAULTTHEME, THEME_COLORS } from '../constants/theme';
import useBoolean from '../hooks/use-boolean';
import { FormikTheme, MainThemeListSingleItem } from '../interfaces/theme';
import PropertyAssetFeatureService from '../services/PropertyAssetFeature';
import ThemeService from '../services/ThemeService';
import ThemeState from './state/ThemeState';

interface Props {
    children: React.ReactNode;
}

const ThemeContext: FunctionComponent<Props> = ({ children }) => {
    const [currentTheme, setCurrentTheme] = useState(DEFAULTTHEME),
        [previewTheme, setPreviewTheme] = useState({
            colors: THEME_COLORS,
            logo: DEFAULTTHEME.logo,
            lightLogo: DEFAULTTHEME.lightLogo,
            loader: DEFAULTTHEME.loader,
        }),
        [companyIdToFilterBy, setCompanyIdToFilterBy] = useState(''),
        [isEditMode, isEditModeActions] = useBoolean(),
        [isThemeListLoading, isThemeListLoadingActions] = useBoolean(),
        { t } = useTranslation(['f', 'b']),
        [themeList, setThemeList] = useState<Array<MainThemeListSingleItem>>([]),
        [selectedThemeForSaveAndEdit, setSelectedThemeForSaveAndEdit] = useState<string>(DEFAULTTHEME.id),
        [isThemePublishing, isThemePublishingActions] = useBoolean(),
        [applayThemeLoader, applayThemeLoaderActions] = useBoolean(),
        [mainThemeId, setMainThemeId] = useState<string>(DEFAULTTHEME.id);

    const fetchThemes = useCallback(async () => {
        isThemeListLoadingActions.setFalse();

        try {
            const data = await ThemeService.fetchThemes(companyIdToFilterBy);

            const main = data.find((elem) => elem.isMain);

            main ? setMainThemeId(main.id) : setMainThemeId(DEFAULTTHEME.id);
            main ? setSelectedThemeForSaveAndEdit(main.id) : setSelectedThemeForSaveAndEdit(DEFAULTTHEME.id);

            setThemeList([{ id: DEFAULTTHEME.id, name: DEFAULTTHEME.name }, ...data]);

            isThemeListLoadingActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t, companyIdToFilterBy]);

    const createOrUpdateNewTheme = useCallback(
        async (val: FormikTheme) => {
            isThemeListLoadingActions.setFalse();

            try {
                isEditMode
                    ? await ThemeService.updateTheme(selectedThemeForSaveAndEdit, val)
                    : await ThemeService.createTheme(companyIdToFilterBy, val);
                await fetchThemes();
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [t, fetchThemes, selectedThemeForSaveAndEdit, isEditMode, companyIdToFilterBy]
    );

    const publishTheme = useCallback(
        async (val: string) => {
            isThemePublishingActions.setTrue();
            try {
                val === DEFAULTTHEME.id
                    ? await ThemeService.restoreToDefault(companyIdToFilterBy)
                    : await ThemeService.publishTheme(val);
                await fetchThemes();
                await fetchActivTheme();
                isThemePublishingActions.setFalse();
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [t, companyIdToFilterBy]
    );

    const deleteTheme = useCallback(
        async (val: string) => {
            isThemeListLoadingActions.setFalse();
            try {
                await ThemeService.deleteTheme(val);
                fetchThemes();
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
                isThemeListLoadingActions.setTrue();
            }
        },
        [t, fetchThemes]
    );

    const fetchThemesById = useCallback(
        async (val: string) => {
            isThemeListLoadingActions.setFalse();

            try {
                if (val === DEFAULTTHEME.id) {
                    setPreviewTheme({
                        colors: THEME_COLORS,
                        logo: DEFAULTTHEME.logo,
                        lightLogo: DEFAULTTHEME.lightLogo,
                        loader: DEFAULTTHEME.loader,
                    });
                } else {
                    const route = await ThemeService.fetchThemeById(val);
                    if (route) {
                        const params = route.split('/');
                        const resp = await ThemeService.fetchBlobTheme(
                            params[params.length - 2],
                            params[params.length - 1]
                        );

                        setPreviewTheme(resp);
                    } else {
                        setPreviewTheme({
                            colors: THEME_COLORS,
                            logo: DEFAULTTHEME.logo,
                            lightLogo: DEFAULTTHEME.lightLogo,
                            loader: DEFAULTTHEME.loader,
                        });
                    }
                }
                isThemeListLoadingActions.setTrue();
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [t]
    );

    const fetchActivTheme = useCallback(async () => {
        try {
            const route = await ThemeService.fetchCompanyTheme();
            if (route) {
                const params = route.split('/');
                const resp = await ThemeService.fetchBlobTheme(params[params.length - 2], params[params.length - 1]);
                setCurrentTheme(resp);
            } else {
                setCurrentTheme(DEFAULTTHEME);
            }
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t]);

    const fetchPropertyTheme = useCallback(
        async (propertyId: string) => {
            applayThemeLoaderActions.setTrue();
            try {
                const summary = await PropertyAssetFeatureService.fetchAssetHero(propertyId);

                const data = await ThemeService.fetchThemes(summary.companyOwnerExternalId);

                const main = data.find((elem) => elem.isMain);

                if (main) {
                    const route = await ThemeService.fetchThemeById(main.id);
                    if (route) {
                        const params = route.split('/');
                        const resp = await ThemeService.fetchBlobTheme(
                            params[params.length - 2],
                            params[params.length - 1]
                        );
                        setCurrentTheme(resp);
                    } else {
                        setCurrentTheme(DEFAULTTHEME);
                    }
                } else {
                    setCurrentTheme(DEFAULTTHEME);
                }

                applayThemeLoaderActions.setFalse();
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [t]
    );

    useEffect(() => {
        if (companyIdToFilterBy) {
            fetchThemes();
        }
    }, [companyIdToFilterBy, fetchThemes]);

    useEffect(() => {
        selectedThemeForSaveAndEdit === DEFAULTTHEME.id
            ? setPreviewTheme({
                  colors: THEME_COLORS,
                  logo: DEFAULTTHEME.logo,
                  lightLogo: DEFAULTTHEME.lightLogo,
                  loader: DEFAULTTHEME.loader,
              })
            : fetchThemesById(selectedThemeForSaveAndEdit);
    }, [selectedThemeForSaveAndEdit, fetchThemesById]);

    return (
        <ThemeState.Provider
            value={{
                currentTheme,
                setCurrentTheme,
                previewTheme,
                createOrUpdateNewTheme,
                selectedThemeForSaveAndEdit,
                setSelectedThemeForSaveAndEdit,
                setMainThemeId,
                mainThemeId,
                themeList,
                publishTheme,
                isThemeListLoading,
                isThemeListLoadingActions,
                fetchActivTheme,
                deleteTheme,
                isEditMode,
                isEditModeActions,
                companyIdToFilterBy,
                setCompanyIdToFilterBy,
                fetchPropertyTheme,
            }}
        >
            {children}
            <ModalImportLoading text={t(`f:loaders.publish`)} isModalOpened={isThemePublishing} />
            {applayThemeLoader && <MainLoader type="main" />}
        </ThemeState.Provider>
    );
};

export default ThemeContext;
