import { FunctionComponent, useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import WizardWithSidebar from '../../components/shared/WizardWithSidebar';
import { useLocation } from 'react-router-dom';
import VentureCompanies from './components/VentureCompanies';
import CompanyDetails from './components/CompanyDetails';
import NavigateContext from '../../context/NavigateContext';
import BidInformations from './components/BidInformations';
import FinanceDetails from './components/FinanceDetails';
import BidOverview from './components/BidOverview';
import Approval from './components/Approval';
import { BidWizardStepNames, wizardSteps } from '../../constants/wizardSteps';
import SelectSingleAsset from './components/SelectSingleAsset';
import PermissionService from '../../services/Permissions';
import { PROPERTY_TABS } from '../../constants/propertyTab';
import CheckAuthorization from '../StatusPages/CheckAuthorization';
import FeatureState from '../../context/state/FeatureState';

interface RouteParams {
    stepName: string;
    id: string;
}

export const PortfolioBidWizard: FunctionComponent<any> = () => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        location = useLocation(),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        params = useParams<RouteParams>();
    let state: any = location.state;

    if (state === undefined) {
        state = {};
        const value = localStorage.getItem('toggleJointeVenture');
        if (value) {
            state.toggleJointeVenture = JSON.parse(value);
        } else {
            state.toggleJointeVenture = false;
        }
    }

    const goToVentureCompanies = (biddingId: string) => {
        if (state.toggleJointeVenture) {
            setActiveTab(wizardSteps.first);
            setActiveSubtab({
                step: BidWizardStepNames.JOINT_VENTURE_COMPANIES,
                name: t('f:bidding.jointVentureCompanies'),
                component: <VentureCompanies goToSelectBidInformations={goToSelectBidInformations} />,
            });

            history.push(`/portfolio-bid-wizard/${externalId}/joint-venture-companies/${biddingId}`);
        } else {
            goToSingleSelectAsset(biddingId);
        }
    };

    const goToSelectBidInformations = (biddingId: string) => {
        setActiveTab(wizardSteps.third);
        setActiveSubtab({});
        history.push(`/portfolio-bid-wizard/${externalId}/bid-informations/${biddingId}`);
    };

    const goToFinanceDetails = (biddingId: string) => {
        setActiveSubtab({
            step: BidWizardStepNames.FINANCE_DETAILS,
            name: t('f:bidding.financeDetails'),
            component: <FinanceDetails goToSelectBidOverview={goToSelectBidOverview} />,
        });
        history.push(`/portfolio-bid-wizard/${externalId}/finance-details/${biddingId}`);
    };

    const goToSelectBidOverview = (biddingId: string) => {
        setActiveTab(wizardSteps.fourth);
        setActiveSubtab({});
        history.push(`/portfolio-bid-wizard/${externalId}/overview/${biddingId}`);
    };

    const goToSelectBidApproval = (biddingId: string) => {
        setActiveTab(wizardSteps.fifth);
        setActiveSubtab({
            step: BidWizardStepNames.APPROVAL_REQUESTS,
            name: t('f:bidding.approvalRequests'),
            component: <Approval />,
        });
        history.push(`/portfolio-bid-wizard/${externalId}/approval-requests/${biddingId}`);
    };

    const onFinished = async () => {
        history.push(`/property/${externalId}#${PROPERTY_TABS.TRANSACTION.name}`);
    };

    const goToSingleSelectAsset = (biddingId: String) => {
        setActiveTab(wizardSteps.second);
        setActiveSubtab({});
        history.push(`/portfolio-bid-wizard/${externalId}/select-assets/${biddingId}`);
    };

    const approvalSubTab = {
        step: BidWizardStepNames.APPROVAL_REQUESTS,
        name: t('f:bidding.approvalRequests'),
        component: <Approval />,
    };

    const overviewSubTab = {
        step: BidWizardStepNames.OVERVIEW,
        name: t('f:bidding.overview'),
        component: (
            <BidOverview
                shouldSubmit={!PermissionService.needsApprovalForBidSubmission()}
                goToNext={PermissionService.needsApprovalForBidSubmission() ? goToSelectBidApproval : onFinished}
            />
        ),
    };

    const tabs = [
        {
            id: wizardSteps.first,
            name: t('f:bidding.companyDetails'),
            subtabs: [
                {
                    step: BidWizardStepNames.COMPANY_DETAILS,
                    name: t('f:bidding.companyDetails'),
                    component: <CompanyDetails goToVentureCompanies={goToVentureCompanies} />,
                },
                {
                    step: BidWizardStepNames.JOINT_VENTURE_COMPANIES,
                    name: t('f:bidding.jointVentureCompanies'),
                    toggleJointeVenture: state ? state.toggleJointeVenture : null,
                    component: <VentureCompanies goToSelectBidInformations={goToSingleSelectAsset} />,
                },
            ],
        },

        {
            id: wizardSteps.second,
            name: 'Portfolio Details',
            subtabs: [
                {
                    step: 'select-assets',
                    name: t('f:bidding.selectAssets'),
                    component: <SelectSingleAsset goToSelectBidInformations={goToSelectBidInformations} />,
                },
            ],
        },

        {
            id: wizardSteps.third,
            name: t('f:bidding.bidDetails'),
            subtabs: [
                {
                    step: BidWizardStepNames.BID_INFORMATIONS,
                    name: t('f:bidding.bidInformation'),
                    component: <BidInformations goToFinanceDetails={goToFinanceDetails} />,
                },
                {
                    step: BidWizardStepNames.FINANCE_DETAILS,
                    name: t('f:bidding.financeDetails'),
                    component: <FinanceDetails goToSelectBidOverview={goToSelectBidOverview} />,
                },
            ],
        },
        {
            id: wizardSteps.fourth,
            name: t('f:bidding.submission'),
            subtabs: PermissionService.needsApprovalForBidSubmission()
                ? [overviewSubTab, approvalSubTab]
                : [overviewSubTab],
        },
    ];

    if (state && !state.toggleJV) {
        tabs.map((elem: any) => {
            if (elem.id === wizardSteps.first) {
                elem.subtabs = _.reject(elem.subtabs, { toggleJointeVenture: false });
            }
        });
    }

    const findActiveTab = () => {
        let activeTab: any = _.find(tabs, function (tab) {
            if (tab.subtabs) {
                return _.some(tab.subtabs, { step: params.stepName });
            }
        });
        if (activeTab) {
            return activeTab.id.toString();
        }
        return wizardSteps.first;
    };
    const [activeTab, setActiveTab] = useState(findActiveTab());
    const findActiveSubTab = () => {
        let active: any = _.find(tabs, { id: parseInt(activeTab) });
        if (active && active.subtabs) {
            return _.find(active.subtabs, { step: params.stepName });
        }
        return null;
    };
    const [activeSubtab, setActiveSubtab] = useState<any>(findActiveSubTab());

    useEffect(() => {}, []);

    return (
        <CheckAuthorization>
            <NavigateContext>
                <WizardWithSidebar tabs={tabs} activeTab={activeTab} activeSubtab={activeSubtab} />
            </NavigateContext>
        </CheckAuthorization>
    );
};
export default PortfolioBidWizard;
