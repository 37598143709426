import { Result } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

export const Unauthorized = () => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory();

    useEffect(() => {
        const header = document.getElementById('header');
        if (header) header.style.visibility = 'hidden';
        return () => {
            if (header) header.style.visibility = 'visible';
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            history.push('/');
        }, 2500);
    }, [history]);

    return (
        <div className="not-found-and-unauthorized d-flex align-items-center justify-content-center">
            <Result
                status="error"
                title={t('f:error.title')}
                subTitle={t('f:error.subTitle')}
                extra={<Link to="/">{t('f:button.goHome')}</Link>}
            />
        </div>
    );
};
