import { OverviewTopCards, OverviewViewTypes } from '../interfaces/property/OverviewPropertyAssetSearch';

export enum SubStatusEnum {
    Empty = -1,
    Portfolio = 0,
    Listed = 1,
    BidsReceived = 2,
    Closed = 3,
    Invited = 4,
    NdaSigned = 5,
    BidSubmitted = 6,
    Unlisted = 7,
    Rejected = 8,
}
export const subStatuses = [
    {
        value: SubStatusEnum.Listed,
        text: 'f:sub-status.listed',
        topCardId: OverviewTopCards.DIVESTMENTS,
    },
    {
        value: SubStatusEnum.BidsReceived,
        text: 'f:sub-status.bidsReceived',
        topCardId: OverviewTopCards.DIVESTMENTS,
    },
    {
        value: SubStatusEnum.Closed,
        text: 'f:sub-status.closed',
        topCardId: OverviewTopCards.DIVESTMENTS,
    },
    {
        value: SubStatusEnum.Invited,
        text: 'f:sub-status.invited',
        topCardId: OverviewTopCards.ACQUISITIONS,
    },
    {
        value: SubStatusEnum.NdaSigned,
        text: 'f:sub-status.ndaSigned',
        topCardId: OverviewTopCards.ACQUISITIONS,
    },
    {
        value: SubStatusEnum.BidSubmitted,
        text: 'f:sub-status.bidSubmitted',
        topCardId: OverviewTopCards.ACQUISITIONS,
    },
    {
        value: SubStatusEnum.Rejected,
        text: 'f:sub-status.rejected',
        topCardId: OverviewTopCards.ACQUISITIONS,
    },
];
export const toggleViews = [
    {
        value: OverviewViewTypes.TABLE,
        text: 'f:sideMenu.overview.table',
    },

    {
        value: OverviewViewTypes.CARDS,
        text: 'f:sideMenu.overview.cards',
    },
];
