import { FunctionComponent, useContext } from 'react';
import FormattedAmount from '../../../components/shared/FormattedAmount';
import BuildingDirectoryState from '../../../context/state/BuildingDirectoryState';

const BuildingInfoTable: FunctionComponent = () => {
    const { buildingDirectory } = useContext(BuildingDirectoryState);
    const data = buildingDirectory!;

    return (
        <>
            <h1 className="heading mt-40 ml-40">{data.propertyName}</h1>
            <section className="module-intro">
                <div className="content-text">
                    <ul className="list">
                        <li>
                            <p>Alternative Name</p><span>{data.alternativeName}</span>
                        </li>
                        <li>
                            <p>Address</p><span>{data.propertyAddress}</span>
                        </li>
                        <li>
                            <p>Region</p><span>{data.region}</span>
                        </li>
                        <li>
                            <p>Country</p><span>{data.country}</span>
                        </li>
                        <li>
                            <p>City</p><span>{data.city}</span>
                        </li>
                        <li>
                            <p>Land Area</p><span>{data.landTenure}</span>
                        </li>
                        <li>
                            <p>Land Tenure</p><span>{data.landArea}</span>
                        </li>
                        <li>
                            <p>Leasehold Start Date</p><span>{data.leaseholdStartDate}</span>
                        </li>
                        <li>
                            <p>Sub-district</p><span>{data.subdistrict}</span>
                        </li>
                        <li>
                            <p>Postcode</p><span>{data.postcode}</span>
                        </li>
                        <li>
                            <p>Primary Sector</p><span>{data.primarySector}</span>
                        </li>
                        <li>
                            <p>Secondary Sector</p><span>{data.secondarySector}</span>
                        </li>
                        <li>
                            <p>Tenancy</p><span>{data.tenancy}</span>
                        </li>
                        <li>
                            <p>Year Built</p><span>{data.yearBuilt}</span>
                        </li>
                        <li>
                            <p>Year Renovated</p><span>{data.yearRenovated}</span>
                        </li>
                    </ul>
                    <div className="block-row-circle">
                        <div className="blk-circle big">
                            {/* TODO: need to change to real algorithm when we have it */}
                            <div className="circle">{Math.floor(Math.random() * 100)}</div>
                            <p>Building score</p>
                        </div>
                        <div className="blk-circle">
                            <div className="circle">{<FormattedAmount value={data.walkScore} />}</div>
                            <p>Walk Score</p>
                        </div>
                        <div className="blk-circle">
                            <div className="circle">{<FormattedAmount value={data.esg} />}</div>
                            <p>ESG</p>
                        </div>
                        <div className="blk-circle">
                            <div className="circle">{<FormattedAmount value={data.transportScore} />}</div>
                            <p>Transport</p>
                        </div>
                    </div>
                </div>
                <div className="content-text building-directory">
                    <ul className="list">
                        <li>
                            <p>Number of Buildings</p><span>{data.numberOfBuildings}</span>
                        </li>
                        <li>
                            <p>Number of Floors</p><span>{data.numberOfFloors}</span>
                        </li>
                        <li>
                            <p>Building Height</p><span>{data.buildingHeight}</span>
                        </li>
                        <li>
                            <p>Number of Lifts</p><span>{data.numberOfLifts}</span>
                        </li>
                        <li>
                            <p>NLA</p><span>{data.nla}</span>
                        </li>
                        <li>
                            <p>GFA</p><span>{data.gfa}</span>
                        </li>
                        <li>
                            <p>Units</p><span>{data.units}</span>
                        </li>
                        <li>
                            <p>Owner</p><span>{data.owner}</span>
                        </li>
                        <li>
                            <p>Owner 2</p><span>{data.owner2}</span>
                        </li>
                        <li>
                            <p>Fund</p><span>{data.fund}</span>
                        </li>
                        <li>
                            <p>High Resolution Images</p><span>{data.highResolutionImages}</span>
                        </li>
                        <li>
                            <p>Occupancy</p><span>{data.occupancy}</span>
                        </li>
                        <li>
                            <p>Facade Material</p><span>{data.facadeMaterial}</span>
                        </li>
                        <li>
                            <p>Parking Places</p><span>{data.parkingPlaces}</span>
                        </li>
                        <li>
                            <p>Building Grade</p><span>{data.buildingGrade}</span>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    );
};

export default BuildingInfoTable;
