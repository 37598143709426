import { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import dumbellData from "./data/dumbell";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
const DumbellChart = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);
      
    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);

        let data:  Array<any> = [];
        for (var i = 0; i < dumbellData.names.length; i++) {
          data.push({ category: dumbellData.names[i], open: dumbellData.minPercent[i], close: dumbellData.maxPercent[i] });
        }
        
        chart.data = data;
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.minGridDistance = 0.01;
        categoryAxis.renderer.grid.template.location = 0.5;
        categoryAxis.renderer.grid.template.strokeDasharray = "0.01,0.05";
        categoryAxis.renderer.labels.template.rotation = -90;
        categoryAxis.renderer.labels.template.horizontalCenter = "left";
        categoryAxis.renderer.labels.template.location = 0.5;
        categoryAxis.renderer.inside = false;
        
        categoryAxis.renderer.labels.template.adapter.add("dx", function(dx, target) {
            return -target.maxRight / 2;
        })
        
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        if(valueAxis.tooltip)
         valueAxis.tooltip.disabled = true;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.renderer.axisFills.template.disabled = true;
        
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "category";
        series.dataFields.openValueY = "open";
        series.dataFields.valueY = "close";
        series.tooltipText = "open: {openValueY.value} close: {valueY.value}";
        series.sequencedInterpolation = true;
        series.fillOpacity = 0;
        series.strokeOpacity = 1;
        series.columns.template.width = 0.01;
        if(series.tooltip)
            series.tooltip.pointerOrientation = "horizontal";
        
        let openBullet: any = series.bullets.create(am4charts.CircleBullet);
        openBullet.locationY = 1;
        openBullet.fill = chart.colors.getIndex(3);
        
        let closeBullet = series.bullets.create(am4charts.CircleBullet);
        
        closeBullet.fill = chart.colors.getIndex(4);
        closeBullet.stroke = closeBullet.fill;
        
        chart.cursor = new am4charts.XYCursor();
        
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarY = new am4core.Scrollbar();
    
        chartRef.current = chart;
    
        return () => {
            chart.dispose();
        };
      }, []);
    
      return (<section className="module-bucket-data mt-40">
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div></section>
      );
    }

export default DumbellChart;
