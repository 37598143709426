import axios, { CancelToken, CancelTokenSource } from 'axios';
import _ from 'lodash';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import buildingIcon from '../../assets/images/building-icon.png';
import lookupIcon from '../../assets/images/lookup-icon.png';
import IPropertyInfo from '../../components/shared/PropertiesMap/iPropertyInfo';
import PropertiesMap from '../../components/shared/PropertiesMap/PropertiesMap';
import RouteParams from '../../interfaces/general/Route';
import TransactionDataService from '../../services/TransactionDataService';
import FilterMenuPanel from './components/FilterMenuPanel';
import { SearchTransactionDataModel } from './models/search-transaction-data.model';
import { TransactionDataInfo } from './models/transaction-data-info.model';

const MapFullHeight = '100%';

const SearchingMap: FunctionComponent = ({ children }) => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        { id } = useParams<RouteParams>(),
        [selectedMenu, setSelectedMenu] = useState<number>(0),
        [mapHeight, setMapHeight] = useState<string>(MapFullHeight),
        [transactionDataList, setTransactionData] = useState<Array<TransactionDataInfo>>([]);
        
    let cancelTokenSource = axios.CancelToken.source();
    const fetchAllTransactionData = useCallback(async () => {
        try {
            const transactionData = await TransactionDataService.fecthAllTransactionData(cancelTokenSource.token);

            setTransactionData(transactionData);
        } catch (error: any) {
            if (axios.isCancel(error)) {
                setTransactionData([]);
                return;
            }

            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    const filterTransactionData = useCallback(
        _.debounce(async (params: SearchTransactionDataModel) => {
            cancelTokenSource.cancel("Cancel previous fetch Transaction Data");
            cancelTokenSource = axios.CancelToken.source();
            const transactionData = await TransactionDataService.fecthAllTransactionData(cancelTokenSource.token, params);
            setTransactionData(transactionData);
        }, 500), []
    )

    useEffect(() => {
        fetchAllTransactionData();
    }, []);

    useEffect(() => {
        setMapHeight(id ? 'calc(50vh - 30px)' : MapFullHeight);
    }, [id]);

    const handleTransactionClicked = (transaction: IPropertyInfo) => {
        history.push(`/transaction-data/${transaction.id}`);
    };

    return (
        <div className="property-hero searching-map">
            <figure>
                <div className="background-cover">
                    <div className='navigation-icon'>
                        <img src={buildingIcon} onClick={() => setSelectedMenu(0)} />
                        <hr />
                        <img src={lookupIcon} onClick={() => setSelectedMenu(1)} />
                    </div>
                    <div className='search-content'>
                        <div className='search-panel'>
                            <div style={{ display: selectedMenu === 0 ? 'block' : 'none' }}>
                                <FilterMenuPanel filter={filterTransactionData}/>
                            </div>
                        </div>
                        <div className="map-panel">
                            <PropertiesMap type={TransactionDataInfo} height={mapHeight} markerId={id} properties={transactionDataList} onMarkerClicked={handleTransactionClicked} />
                            {children}
                        </div>
                    </div>
                </div>
            </figure>
        </div>
    );
};

export default SearchingMap;
