import * as Yup from 'yup';

export const validateLineChartSchema = Yup.object().shape({
    dates: Yup.array().of(Yup.string().required('Date is Required')),
    lines: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required('Please value name'),

                dots: Yup.array().of(
                    Yup.object().shape({
                        value: Yup.number().typeError('Must be a number').required('Please value size'),
                    })
                ),
            })
        )
        .min(1, 'categores is >= 1'),
});
