import { Col, Row, Table } from "antd";
import { FunctionComponent, useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { IPropertyWithNumberOfViews } from "../../../interfaces/googleAnalytics/PropertyWithNumberOfViews";
import GoogleAnalyticsService from "../../../services/GoogleAnalyticsService";
import * as amcharts5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from '@amcharts/amcharts5/xy';
import { Moment } from "moment";

interface IMostViewsPropertiesChartProps {
    startDate: Moment;
    endDate: Moment
}
const MostViewsPropertiesChart: FunctionComponent<IMostViewsPropertiesChartProps> = ({
    startDate,
    endDate
}) => {
    const id = "mostViewsProperties";
    const [data, setData] = useState<Array<IPropertyWithNumberOfViews>>([]),
        { t } = useTranslation(['f', 'b']);

    const fetchData = useCallback(async () => {
        try {
            const result = await GoogleAnalyticsService.fetchMostViewsProperties(startDate, endDate);
            setData(result);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [startDate, endDate]);

    const columns = [
        {
            title: 'Property Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Views',
            dataIndex: 'numberOfViews',
            key: 'numberOfViews',
        },
    ];

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useLayoutEffect(() => {
        if (data.length) {
            let root = amcharts5.Root.new(id);
            // Set themes
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                pinchZoomX: false
            }));

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);

            let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
            xRenderer.labels.template.setAll({
                centerY: amcharts5.p50,
                centerX: amcharts5.p100,
                paddingRight: 15
            });
            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                maxDeviation: 0.3,
                categoryField: 'name',
                renderer: xRenderer as any,
                tooltip: amcharts5.Tooltip.new(root, {})
            }));

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(root, { minGridDistance: 30 }) as any,
                min: 10,
            }));

            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Most Viewed Properties",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "numberOfViews",
                sequencedInterpolation: true,
                categoryXField: "name",
                tooltip: amcharts5.Tooltip.new(root, {
                    labelText: "{valueY}"
                })
            }));

            series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
            series.columns.template.adapters.add("fill", function (fill, target) {
                return chart.get("colors")!.getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", function (stroke, target) {
                return chart.get("colors")!.getIndex(series.columns.indexOf(target));
            });

            xAxis.data.setAll(data);
            series.data.setAll(data);

            series.appear(1000);
            chart.appear(1000, 100);
            return () => root.dispose();
        }
    }, [data])

    return data.length ? (
        <div className="mt-40">
            <p className='heading ml-20'>Most viewed Properties</p>
            <Row className="ml-20">
                <Col span={8}>
                    <Table columns={columns} dataSource={data} pagination={false} />
                </Col>
                <Col span={16}>
                    <div id={id} style={{ width: '100%', height: '500px' }}></div>
                </Col>
            </Row>
        </div>
    ) : <></>;
}

export default MostViewsPropertiesChart;