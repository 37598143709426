import { memo } from 'react';
import folder from '../../../../assets/images/data-room/folder.svg';
import doc from '../../../../assets/images/data-room/doc.svg';
import gif from '../../../../assets/images/data-room/gif.svg';
import jpg from '../../../../assets/images/data-room/jpg.svg';
import pdf from '../../../../assets/images/data-room/pdf.svg';
import png from '../../../../assets/images/data-room/png.svg';
import ppt from '../../../../assets/images/data-room/ppt.svg';
import txt from '../../../../assets/images/data-room/txt.svg';
import xls from '../../../../assets/images/data-room/xls.svg';
import zip from '../../../../assets/images/data-room/zip.svg';

const getFileIcon = (extension) => {
    switch (extension?.toLowerCase()) {
        case 'pdf':
            return <img src={pdf} alt="folder" className="data-room__icon" />;
        case 'doc':
        case 'docx':
            return <img src={doc} alt="folder" className="data-room__icon" />;
        case 'xls':
        case 'xlsx':
            return <img src={xls} alt="folder" className="data-room__icon" />;
        case 'jpg':
        case 'jpeg':
            return <img src={jpg} alt="folder" className="data-room__icon" />;
        case 'zip':
        case 'rar':
            return <img src={zip} alt="folder" className="data-room__icon" />;
        case 'ppt':
        case 'pptx':
            return <img src={ppt} alt="folder" className="data-room__icon" />;
        case 'txt':
            return <img src={txt} alt="folder" className="data-room__icon" />;
        case 'gif':
            return <img src={gif} alt="folder" className="data-room__icon" />;
        case 'png':
            return <img src={png} alt="folder" className="data-room__icon" />;

        default:
            return <img src={txt} alt="folder" className="data-room__icon" />;
    }
};

function DataRoomIcon({ extension, isFolder }) {
    return (
        (isFolder && <img src={folder} alt="folder" className="data-room__icon" />) ||
        getFileIcon(extension) || <img src={txt} alt="folder" className="data-room__icon" />
    );
}

function arePropsEqual(prevProps, nextProps) {
    return prevProps.isFolder === nextProps.isFolder && prevProps.extension === nextProps.extension;
}

export default memo(DataRoomIcon, arePropsEqual);
