import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Popconfirm,
    Space,
    Table,
    Checkbox,
    Form,
    Input,
    AutoComplete,
    Select,
    Tag,
    Tooltip,
    Upload,
    Spin,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CompanyService from '../../../services/Admin/Company';
import PermissionService from '../../../services/Permissions';
import { RoleOptions } from '../../../constants/roles';
import { useTranslation } from 'react-i18next';
import AddCompanyPermission from './AddCompanyPermission';
import { toast } from 'react-toastify';
import ModalWrapper from '../../../components/wrapper/ModalWrapper';
import CompanyThemeName from '../partials/CompanyThemeName';
const { Option } = Select;

const CompanyList: FunctionComponent<any> = () => {
    const [companyDataSource, setCompanyDataSource] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [companyModal, setCompanyModal] = useState<any>({
            visible: false,
            company: {
                domains: [],
            },
        }),
        [usersModal, setUsersModal] = useState<any>({
            companyId: '',
            visible: false,
            currentPageNumber: 1,
            users: [],
        }),
        [totalCount, setTotalCount] = useState(0),
        { t } = useTranslation(['f']);
    const [permissionModal, setPermissionModal] = useState<any>({
        companyId: '',
        visible: false,
    });

    const history = useHistory();

    const [totalUserCount, setTotalUserCount] = useState(0);
    const [autocompleteUsers, setAutocompleteUsers] = useState<Array<any>>([]);
    const [roleModal, setRoleModal] = useState<any>({
            visible: false,
            confirmMessage: '',
            userId: 0,
            newRoleId: -1,
        }),
        [domains, setDomains] = useState<Array<string>>([]),
        [inputDomainVisible, setInputDomainVisible] = useState<boolean>(false),
        [inputDomainValue, setInputDomainValue] = useState<string>(''),
        editDomainInput = useRef<Input>(null),
        [adduserModalVisible, setAddUserModalVisible] = useState<boolean>(false);

    const userPageSize = 6;
    const pageSize = 10;

    function fetchCompanyData(pageIdx: number) {
        setLoading(true);
        CompanyService.fetchCompanies(pageIdx, pageSize).then((resp) => {
            setLoading(false);
            if (resp && resp.items) {
                let rows: Array<any> = resp.items.map((n) => ({
                    key: n.id,
                    id: n.id,
                    name: n.name,
                    email: n.email,
                    registrationNumber: n.registrationNumber,
                    phoneNumber: n.phoneNumber,
                    isActive: n.isActive,
                    isVerified: n.isVerified,
                    externalId: n.externalId,
                }));
                setTotalCount(resp.totalCount);
                setCompanyDataSource(rows);
            }
        });
    }

    function fetchUsersInCompany(companyId, pageIdx: number) {
        CompanyService.fetchCompanyUsers(companyId, pageIdx, userPageSize).then((resp) => {
            if (resp && resp.items) {
                setUsersModal({
                    companyId: companyId,
                    currentPageNumber: pageIdx + 1,
                    visible: true,
                    users: [...resp.items],
                });
                setTotalUserCount(resp.totalCount);
            }
        });
    }

    useEffect(() => {
        fetchCompanyData(0);
    }, []);

    async function handleActive(id: any) {
        let company = companyDataSource.find((n) => n.id == id);

        if (company) {
            if (company.isActive) {
                await CompanyService.deactivateCompany(id);
            } else await CompanyService.activateCompany(id);
            company.isActive = !company.isActive;
            setCompanyDataSource([...companyDataSource]);
        }
    }

    async function handleVerify(id: any) {
        let company = companyDataSource.find((n) => n.id == id);

        if (company) {
            if (company.isVerified) {
                await CompanyService.unverifyCompany(id);
            } else await CompanyService.verifyCompany(id);
            company.isVerified = !company.isVerified;
            setCompanyDataSource([...companyDataSource]);
        }
    }

    const userAdded = async () => {
        setAddUserModalVisible(false);
        fetchUsersInCompany(usersModal.companyId, 0);
    };

    function openAddCompanyModal() {
        let modal = {
            visible: true,
            company: {
                id: 0,
                email: '',
                name: '',
                registrationNumber: '',
                type: '',
                industry: '',
                speciality: '',
                address: '',
                city: '',
                postalCode: '',
                contactName: '',
                phoneNumber: '',
                countryId: null,
                countryName: '',
                domains: [],
                ownerEmail: '',
                imageUrl: '',
                ownerId: null,
            },
        };
        setCompanyModal(modal);
    }

    const openAddUserModal = () => {
        setAddUserModalVisible(true);
    };

    const cancelAddUserModal = () => {
        setAddUserModalVisible(false);
    };

    async function openEditCompanyModal(record) {
        const companyDetail = await CompanyService.fetchDetailCompany(record.id);
        console.log(companyDetail);

        let modal = {
            visible: true,
            company: {
                email: companyDetail.email,
                id: companyDetail.id,
                name: companyDetail.name,
                registrationNumber: companyDetail.registrationNumber,
                type: companyDetail.type,
                industry: companyDetail.industry,
                speciality: companyDetail.speciality,
                address: companyDetail.address,
                city: companyDetail.city,
                postalCode: companyDetail.postalCode,
                contactName: companyDetail.contactName,
                phoneNumber: companyDetail.phoneNumber,
                countryId: companyDetail.countryId,
                countryName: companyDetail.countryName,
                domains: companyDetail.domains,
                ownerEmail: companyDetail.ownerEmail,
                ownerId: companyDetail.ownerId,
                imageUrl: companyDetail.imageUrl,
            },
        };
        setDomains([...companyDetail.domains]);
        setCompanyModal(modal);
    }

    function openPermissionModal(record) {
        setPermissionModal({
            companyId: record.id,
            visible: true,
        });
    }

    function closeUserModal() {
        setUsersModal({
            companyId: '',
            currentPageNumber: 1,
            visible: false,
            users: [],
        });
    }

    function onSelectCountry(_, country) {}

    function handlePageChange(pageNumber, pageSize) {
        fetchCompanyData(pageNumber - 1);
    }

    function handleUsersPageChange(pageNumber, pageSize) {
        fetchUsersInCompany(usersModal.companyId, pageNumber - 1);
    }

    async function handleRemoveUser(userId) {
        await CompanyService.removeUsersFromCompany(usersModal.companyId, [userId]);
        fetchUsersInCompany(usersModal.companyId, usersModal.currentPageNumber - 1);
    }

    async function handleAddUser(userId) {
        if (userId == 0) return;
        await CompanyService.addUsersToCompany(usersModal.companyId, [userId]);
        setAutocompleteUsers([]);
        fetchUsersInCompany(usersModal.companyId, usersModal.currentPageNumber - 1);
    }

    async function handleAssignRoleToUser() {
        await CompanyService.assignRoleToUserInCompany(usersModal.companyId, roleModal.userId, roleModal.newRoleId);
        setRoleModal({
            userId: 0,
            newRoleId: -1,
            visible: false,
            confirmMessage: '',
        });
        fetchUsersInCompany(usersModal.companyId, usersModal.currentPageNumber - 1);
    }

    const customRequest = async (options: any) => {
        try {
            const data = new FormData();
            data.append('Image', options.file);
            const resp = await CompanyService.addImageOfCompany(options, data);
            const company = { ...companyModal };

            company.company.imageUrl = resp;
            setCompanyModal(company);

            toast.success(t(`f:bid.uploadFile`));
        } catch (error) {
            toast(t(`f:bid.failedToUploadFile`));
        }
    };

    async function searchUsers(e) {
        let keyword = e.target.value;

        if (keyword.length >= 3) {
            const users = await CompanyService.searchUsersNotInCompany(usersModal.companyId, keyword);
            const userOptions = users.map((u) => ({
                id: u.id,
                fullName: u.fullName,
                value: u.email,
                label: (
                    <div>
                        <Popconfirm title="Are you sure ?" onConfirm={() => handleAddUser(u.id)}>
                            <Button type="primary" style={{ marginRight: 15 }}>
                                Add
                            </Button>
                        </Popconfirm>
                        {u.email}
                    </div>
                ),
            }));

            if (userOptions.length == 0) {
                userOptions.push({
                    id: 0,
                    label: 'Not found users',
                    value: '',
                });
            }

            setAutocompleteUsers([...userOptions]);
        }
    }

    async function saveCompanyModal() {
        let model = companyModal.company;
        model.ownerId = '00000000-0000-0000-0000-000000000000';
        model.domains = domains;
        if (model.id > 0) {
            await CompanyService.updateCompany(model);
        } else {
            await CompanyService.createCompany(model);
        }
        setCompanyModal({ visible: false, company: {} });
        fetchCompanyData(0);
    }

    function getRoleName(roleId) {
        return roleId == 1 ? 'Admin' : roleId == 0 ? 'Member' : 'Manager';
    }

    function showNewDomainInput() {
        setInputDomainVisible(true);
    }

    function showEditDomainInput(domainToEdit: string) {
        const remainDomains = domains.filter((tag) => tag !== domainToEdit);
        if (remainDomains) {
            setDomains([...remainDomains]);
            setInputDomainVisible(true);
            setInputDomainValue(domainToEdit);
        }
    }

    function saveDomain(domainToSave: string) {
        if (inputDomainValue !== '') {
            editDomain(domainToSave);
        } else addNewDomain(domainToSave);
    }

    function editDomain(domainToEdit: string) {
        const trimDomainToEdit = domainToEdit.trim();
        if (trimDomainToEdit !== '') {
            domains.push(trimDomainToEdit);
        } else {
            domains.push(inputDomainValue);
        }

        setDomains([...domains]);
        setInputDomainVisible(false);
        setInputDomainValue('');
    }

    function addNewDomain(newDomain: string) {
        const trimNewDomain = newDomain.trim();

        if (trimNewDomain !== '') {
            domains.push(trimNewDomain);
            setDomains([...domains]);
        }

        setInputDomainVisible(false);
        setInputDomainValue('');
    }

    function removeDomain(domain: string) {
        const remainDomains = domains.filter((tag) => tag !== domain);
        if (remainDomains) {
            setDomains([...remainDomains]);
        }
    }

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    const userColumns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Role',
            key: 'role',
            render: (_, record) =>
                record.role != 3 ? (
                    <Space size="middle">
                        <Select
                            defaultValue={record.role}
                            style={{ width: 130 }}
                            value={record.role}
                            onSelect={(value) => {
                                if (value != record.role) {
                                    setRoleModal({
                                        visible: true,
                                        userId: record.id,
                                        newRoleId: value,
                                        confirmMessage: `Do you want to assign role from ${getRoleName(
                                            record.role
                                        )} to ${getRoleName(value)} for ${record.email} ?`,
                                    });
                                }
                            }}
                            getPopupContainer={handleContainer}
                        >
                            {Object.keys(RoleOptions)
                                .map(Number)
                                .map((key) => (
                                    <Option value={key}>{t(`f:userRoles.${key}`)}</Option>
                                ))}
                        </Select>
                    </Space>
                ) : (
                    <Space size="middle">
                        <Select
                            defaultValue={record.role}
                            style={{ width: 130 }}
                            disabled
                            getPopupContainer={handleContainer}
                        >
                            <Option value={3}>Super Admin</Option>
                        </Select>
                    </Space>
                ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm title="Are you sure ?" onConfirm={() => handleRemoveUser(record.id)}>
                        <Button danger={true}>Remove</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const companyColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Registration Number',
            dataIndex: 'registrationNumber',
            key: 'registrationNumber',
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) =>
                companyDataSource.length > 0 ? (
                    <Space size="middle">
                        <Popconfirm title="Are you sure ?" onConfirm={() => handleActive(record.id)}>
                            <Checkbox checked={record.isActive}></Checkbox>
                        </Popconfirm>
                    </Space>
                ) : null,
        },
        {
            title: 'Verified',
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (_, record) =>
                companyDataSource.length > 0 ? (
                    <Space size="middle">
                        <Popconfirm title="Are you sure ?" onConfirm={() => handleVerify(record.key)}>
                            <Checkbox checked={record.isVerified}></Checkbox>
                        </Popconfirm>
                    </Space>
                ) : null,
        },
        {
            title: 'Theme',
            dataIndex: 'theme',
            key: 'theme',
            render: (_, record) => (
                <Space size="middle">
                    <CompanyThemeName id={record.externalId} />
                    <Button
                        onClick={() => {
                            history.push(`/admin-panel/company/theme/${record.externalId}`);
                        }}
                    >
                        {t('f:button:edit')}
                    </Button>
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => openEditCompanyModal(record)}>Edit</Button>
                    <Button
                        onClick={() => {
                            history.push(`/admin-panel/users/${record.id}`);
                        }}
                    >
                        Users
                    </Button>
                    <Button onClick={() => openPermissionModal(record)}>Permissions</Button>
                </Space>
            ),
        },
    ];

    const optionCoutries = [
        {
            id: 1,
            label: 'Vietnam',
            value: 'Vietnam',
        },
        {
            id: 2,
            label: 'Singapore',
            value: 'Singapore',
        },
        {
            id: 3,
            label: 'China',
            value: 'China',
        },
    ];

    return (
        <div className="d-flex flex-direction-column pt-50">
            <div style={{ margin: '20px' }}>
                {PermissionService.canAddNewCompany() && (
                    <Button
                        onClick={openAddCompanyModal}
                        type="primary"
                        style={{
                            marginBottom: 16,
                        }}
                    >
                        Add new company
                    </Button>
                )}
                <Spin tip="Loading..." spinning={loading}>
                    <Table
                        rowClassName={() => 'editable-row'}
                        bordered
                        dataSource={companyDataSource}
                        columns={companyColumns}
                        pagination={{ onChange: handlePageChange, total: totalCount, pageSize: pageSize }}
                    />
                </Spin>
                <ModalWrapper
                    isFooterShown={true}
                    primaryButtonHandler={() => {
                        saveCompanyModal();
                    }}
                    primaryButtonText="save"
                    isCloseModalShown={true}
                    closeModalHandler={() => {
                        setCompanyModal({ visible: false, company: {} });
                    }}
                    isModalOpened={companyModal.visible}
                >
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            style={{ marginTop: 2 }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input company name.',
                                },
                            ]}
                        >
                            <Input
                                defaultValue={companyModal.company.name}
                                onChange={(e) => (companyModal.company.name = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input company email.',
                                },
                            ]}
                        >
                            <Input
                                defaultValue={companyModal.company.email}
                                onChange={(e) => (companyModal.company.email = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Owner" name="owner">
                            <AutoComplete
                                dropdownClassName="certain-category-search-dropdown"
                                dropdownMatchSelectWidth={250}
                                style={{
                                    width: 250,
                                }}
                            >
                                <Input.Search size="large" placeholder="owner email" />
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item label="Domains" name="domains">
                            {domains.map((domain, index) => {
                                const tagElem = (
                                    <Tag
                                        className="edit-tag"
                                        key={domain}
                                        closable={true}
                                        onClose={() => removeDomain(domain)}
                                    >
                                        <Tooltip title="Double click to edit" key={domain}>
                                            <span onDoubleClick={() => showEditDomainInput(domain)}>{domain}</span>
                                        </Tooltip>
                                    </Tag>
                                );
                                return tagElem;
                            })}
                            {inputDomainVisible && (
                                <Tooltip
                                    title="Press Enter to save"
                                    placement="left"
                                    trigger={['hover', 'focus', 'click']}
                                >
                                    <Input
                                        ref={editDomainInput}
                                        type="text"
                                        size="small"
                                        className="tag-input"
                                        defaultValue={inputDomainValue}
                                        onBlur={() => saveDomain(editDomainInput.current?.state.value)}
                                        onPressEnter={() => saveDomain(editDomainInput.current?.state.value)}
                                    />
                                </Tooltip>
                            )}
                            {!inputDomainVisible && (
                                <Tag className="site-tag-plus" onClick={showNewDomainInput}>
                                    <PlusOutlined /> New Domain
                                </Tag>
                            )}
                        </Form.Item>
                        <Form.Item label="Registration Number" name="registrationNumber">
                            <Input
                                defaultValue={companyModal.company.registrationNumber}
                                onChange={(e) => (companyModal.company.registrationNumber = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Country" name="country">
                            <AutoComplete
                                onSelect={onSelectCountry}
                                dropdownClassName="certain-category-search-dropdown"
                                dropdownMatchSelectWidth={250}
                                style={{
                                    width: 250,
                                }}
                                options={optionCoutries}
                            >
                                <Input.Search size="large" placeholder="country" />
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item label="City" name="city">
                            <Input
                                defaultValue={companyModal.company.city}
                                onChange={(e) => (companyModal.company.city = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Address" name="address">
                            <Input
                                defaultValue={companyModal.company.address}
                                onChange={(e) => (companyModal.company.address = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Contact Name" name="contactName">
                            <Input
                                defaultValue={companyModal.company.contactName}
                                onChange={(e) => (companyModal.company.Name = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Postal code" name="postalCode">
                            <Input
                                defaultValue={companyModal.company.postalCode}
                                onChange={(e) => (companyModal.company.postalCode = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Industry" name="industry">
                            <Input
                                defaultValue={companyModal.company.industry}
                                onChange={(e) => (companyModal.company.industry = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Speciality" name="speciality">
                            <Input
                                defaultValue={companyModal.company.speciality}
                                onChange={(e) => (companyModal.company.speciality = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Type" name="type">
                            <Input
                                defaultValue={companyModal.company.type}
                                onChange={(e) => (companyModal.company.type = e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Company Image" name="Image">
                            <div className="col-xs-12-clear">
                                {companyModal.company.imageUrl ? (
                                    <img
                                        src={companyModal.company.imageUrl}
                                        className="newsletter-builder__def-image"
                                        alt="property"
                                    />
                                ) : null}
                                <Upload
                                    name="proofOfFunds"
                                    action={process.env.REACT_APP_API_URL + `/admin/company/image`}
                                    customRequest={(option) => {
                                        customRequest(option);
                                    }}
                                    listType="text"
                                    className="add-property-image-builder__upload"
                                    showUploadList={false}
                                    multiple
                                >
                                    <Button className="mt-0 mb-20">
                                        <p className="col-xs-12-clear listing-type__help-link">
                                            {t('f:button.upload')}
                                        </p>
                                    </Button>
                                </Upload>
                            </div>
                        </Form.Item>
                    </Form>
                </ModalWrapper>

                <ModalWrapper
                    destroyOnClose={true}
                    isFooterShown={false}
                    isCloseModalShown={true}
                    isModalOpened={usersModal.visible}
                    closeModalHandler={closeUserModal}
                    title="User"
                >
                    <ModalWrapper
                        isCloseModalShown={true}
                        closeModalHandler={() =>
                            setRoleModal({ visible: false, confirmMessage: '', userId: 0, newRoleId: -1 })
                        }
                        isFooterShown={true}
                        primaryButtonHandler={handleAssignRoleToUser}
                        primaryButtonText="save"
                        isModalOpened={roleModal.visible}
                    >
                        {roleModal.confirmMessage}
                    </ModalWrapper>

                    <div className="d-flex justify-content-space-between align-items-center">
                        <AutoComplete
                            onBlur={() => setAutocompleteUsers([])}
                            open={autocompleteUsers.length > 0}
                            onKeyUp={searchUsers}
                            dropdownMatchSelectWidth={500}
                            style={{
                                width: 500,
                                marginBottom: 15,
                            }}
                            notFoundContent="Not Found Users"
                            options={autocompleteUsers}
                        >
                            <Input.Search
                                size="large"
                                placeholder="input at least 3 characters to find and re-add removed users"
                            />
                        </AutoComplete>
                        <Button
                            onClick={openAddUserModal}
                            type="primary"
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            Add user
                        </Button>
                    </div>

                    <Table
                        rowClassName={() => 'editable-row'}
                        bordered
                        dataSource={usersModal.users}
                        columns={userColumns}
                        pagination={{ onChange: handleUsersPageChange, total: totalUserCount, pageSize: userPageSize }}
                    />
                </ModalWrapper>

                <AddCompanyPermission
                    companyId={permissionModal.companyId}
                    isModalOpen={permissionModal.visible}
                    onCancel={() => setPermissionModal({ companyId: permissionModal.companyId, visible: false })}
                ></AddCompanyPermission>
            </div>
        </div>
    );
};

export default CompanyList;
