import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@material-ui/data-grid';

const Invitations: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']);

    const accept = (id: string) => {
        console.log(id);
    };
    const decline = (id: string) => {
        console.log(id);
    };

    const columns = [
        {
            field: 'logo',
            width: 110,
            renderCell: (props) => <img className="invitations-side-menu__image" src={props.row.url} />,
        },
        {
            field: 'propertyName',
            headerName: 'Name',
            width: 200,
            visible: false,
        },
        {
            field: 'seller',
            headerName: 'Seller',
            width: 200,
        },
        {
            field: 'inviteSent',
            headerName: 'Invite Sent',
            width: 200,
        },
        {
            field: '',
            headerName: 'Actions',
            width: 300,
            disableClickEventBubbling: true,
            renderCell: (props) => (
                <div className="d-flex">
                    <button
                        className="button-main button-primary--green button-small mr-10 d-flex justify-content-center align-items-center"
                        onClick={() => {
                            accept(props.row.id);
                        }}
                    >
                        {t('f:button.approve')}
                    </button>
                    <button
                        className="button-main button-primary button-small d-flex justify-content-center align-items-center"
                        onClick={() => {
                            decline(props.row.id);
                        }}
                    >
                        {t('f:button.decline')}
                    </button>
                </div>
            ),
        },
    ];

    const rows = [
        {
            id: 1,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Snow',
            seller: 'Jon',
            inviteSent: 'Lazar',
        },
        {
            id: 2,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Lannister',
            seller: 'Cersei',
            inviteSent: 'Tom',
        },
        {
            id: 3,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Lannister',
            seller: 'Jaime',
            inviteSent: 'Tom',
        },
        {
            id: 4,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Stark',
            seller: 'Arya',
            inviteSent: 'Sreya',
        },
        {
            id: 5,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Targaryen',
            seller: 'Daenerys',
            inviteSent: 'Tom',
        },
        {
            id: 6,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Melisandre',
            seller: null,
            inviteSent: 'Tom',
        },
        {
            id: 7,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Clifford',
            seller: 'Ferrara',
            inviteSent: 'Sreya',
        },
        {
            id: 8,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Frances',
            seller: 'Rossini',
            inviteSent: 'Sreya',
        },
        {
            id: 9,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Roxie',
            seller: 'Harvey',
            inviteSent: 'Tom',
        },
        {
            id: 10,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Snow',
            seller: 'Jon',
            inviteSent: 'Lazar',
        },
        {
            id: 11,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Lannister',
            seller: 'Cersei',
            inviteSent: 'Tom',
        },
        {
            id: 12,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Lannister',
            seller: 'Jaime',
            inviteSent: 'Tom',
        },
        {
            id: 13,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Stark',
            seller: 'Arya',
            inviteSent: 'Sreya',
        },
        {
            id: 14,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Targaryen',
            seller: 'Daenerys',
            inviteSent: 'Tom',
        },
        {
            id: 15,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Melisandre',
            seller: null,
            inviteSent: 'Tom',
        },
        {
            id: 16,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Clifford',
            seller: 'Ferrara',
            inviteSent: 'Sreya',
        },
        {
            id: 17,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Frances',
            seller: 'Rossini',
            inviteSent: 'Sreya',
        },
        {
            id: 18,
            url: 'https://virgatedev.blob.core.windows.net/property/axa-tower-674-160106maxwell-portal-treatment2_1628754642.jpeg',
            propertyName: 'Roxie',
            seller: 'Harvey',
            inviteSent: 'Tom',
        },
    ];

    useEffect(() => {}, []);
    return (
        <div className="invitations-side-menu">
            <div className="mx-50">
                <p className="heading">{t('f:sideMenu.invitations.invitations')}</p>
                <div style={{ height: 400, width: '100%' }} className="mb-100">
                    <DataGrid rows={rows} columns={columns} pageSize={10} checkboxSelection disableSelectionOnClick />
                </div>
            </div>
        </div>
    );
};
export default Invitations;
