import { Col, Row, Table } from "antd";
import { Moment } from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import HighlightCountryMap from "../../../components/shared/HighlightCountryMap";
import { IUsersByCountry } from "../../../interfaces/googleAnalytics/UsersByCountry";
import GoogleAnalyticsService from "../../../services/GoogleAnalyticsService";

interface IUsersByCountryChartProps {
    startDate: Moment;
    endDate: Moment;
}

const UsersByCountryChart: FunctionComponent<IUsersByCountryChartProps> = ({
    startDate,
    endDate
}) => {
    const [usersByCountryData, setUsersByCountryData] = useState<Array<IUsersByCountry>>([]),
        { t } = useTranslation(['f', 'b']);
    const fetchUsersByCountryData = useCallback(async () => {
        try {
            const data = await GoogleAnalyticsService.fetchUsersByCountry(startDate, endDate);
            setUsersByCountryData(data);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [startDate, endDate]);

    const columns = [
        {
            title: 'COUNTRY',
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: 'USERS',
            dataIndex: 'numOfUsers',
            key: 'numOfUsers',
        },
    ];

    useEffect(() => {
        fetchUsersByCountryData();
    }, [fetchUsersByCountryData]);

    const mapSeriesData = usersByCountryData.map(item => ({
        id: item.countryCode,
        name: item.country,
        value: item.numOfUsers,
        latitude: item.latitude,
        longitude: item.longitude
    }));

    return (
        <div className="mt-50">
            <p className='heading ml-20'>Users over country</p>
            <Row className="ml-20">
                <Col span={8}>
                    <Table columns={columns} dataSource={usersByCountryData} pagination={false} />
                </Col>
                <Col span={16}>
                    <HighlightCountryMap data={mapSeriesData} />
                </Col>
            </Row>
        </div>
    );
}

export default UsersByCountryChart;