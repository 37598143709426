import api from '../index';

const fetchCanSign = () => {
    return api.get(`/executenda/cansign`);
};

const fetchUsersWhoCanSign = () => {
    return api.get(`/executenda/users`);
};

const fetchCustomNDA = (propertyAssetId: string) => {
    return api.get(`/executenda/${propertyAssetId}/checkcustomnda`);
};

const createNdaForProperty = (propertyAssetId: string, body: any) => {
    return api.post(`/executenda/${propertyAssetId}/sign`, body);
};

const requestSignIn = (propertyAssetId: string, userId: string) => {
    return api.post(`executenda/${propertyAssetId}/${userId}/requestsignnda`);
};

const fetchDocuSign = (propertyAssetId: string) => {
    return api.get(`executenda/${propertyAssetId}/docusignpowerformurl`);
};

const finishPowerForm = (body: any) => {
    return api.post(`executenda/ndastatussigned/powerform`, body);
};

const fetchHistory = (propertyAssetId: string, companyId: string) => {
    return api.get(`executenda/${propertyAssetId}/ndasigning?CompanyId=${companyId}`);
};

const fetchStatusOfNDAPrices = (propertyAssetId: string) => {
    return api.get(`/executenda/${propertyAssetId}/issigninprocess`);
};

const uploadNDA = (options: any, data: any) => {
    console.log(options);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'text/plain',
        },
    };
    return api.post(options.action, data, config);
};

const ExecuteNdaApi = {
    createNdaForProperty,
    requestSignIn,
    finishPowerForm,
    fetchDocuSign,
    fetchStatusOfNDAPrices,
    fetchHistory,
    fetchCustomNDA,
    uploadNDA,
    fetchUsersWhoCanSign,
    fetchCanSign,
};
export default ExecuteNdaApi;
