import { useTranslation } from 'react-i18next';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import ListOpportunityWizard from '../pages/ListOpportunityWizard';
import { AddPropertyDetails } from '../pages/AddEditProperty/AddEditPropertyDetails';
import AddPropertyCheckAuth from '../pages/AddEditProperty/AddPropertyCheckAuth';
import { AddBasicInfo } from '../pages/AddEditProperty/components/AddBasicInfo';
import { EditBasicInfo } from '../pages/AddEditProperty/components/EditBasicInfo';
import Portfolio from '../pages/AddEditProperty/components/Portfolio';
import BiddingStructureWizard from '../pages/BiddingStructureWizard/BiddingStructureWizard';
import { BidWizard } from '../pages/BidWizard/BidWizard';
import LandingPageBidWizard from '../pages/BidWizard/LandingPageBidWizard';
import PortfolioBidWizard from '../pages/BidWizard/PortfolioBidWizard';
import DealLineWizard from '../pages/DealLineWizard';
import { ScheduleWizard } from '../pages/ScheduleWizard/ScheduleWizard';
import { TemplateWizard } from '../pages/TemplatesWizard/TemplateWizard';
import ViewProperty from '../pages/ViewProperty';
import { useContext, useEffect } from 'react';
import ThemeState from '../context/state/ThemeState';
import RouteParams from '../interfaces/general/Route';
import FeatureContext from '../context/FeatureContext';

const PropertyRoute = () => {
    const { path } = useRouteMatch(),
        { t } = useTranslation(['f']);

    return (
        <Switch>
            <Route exact path={`${path}/add`}>
                <AddPropertyCheckAuth />
            </Route>
            <Route exact path={`${path}/add-single`}>
                <AddPropertyDetails component={<AddBasicInfo />} text={t(`f:property.propertyDetails`)} />
            </Route>
            <Route exact path={`${path}/add-portfolio`}>
                <AddPropertyDetails component={<Portfolio />} text={t(`f:property.portfolio`)} />
            </Route>

            <Route path={`${path}/:id`}>
                <FeatureContext>
                    <PropertyRouteData />
                </FeatureContext>
            </Route>
        </Switch>
    );
};

const PropertyRouteData = () => {
    const { path } = useRouteMatch(),
        { id } = useParams<RouteParams>(),
        { fetchPropertyTheme } = useContext(ThemeState),
        { t } = useTranslation(['f']);

    useEffect(() => {
        //TODO temporary disable fetching property theme and always use company theme
        // fetchPropertyTheme(id);
    }, [id]);

    return (
        <Switch>
            <Route exact path={path}>
                <ViewProperty />
            </Route>
            <Route path={`${path}/edit/:portfolio?`}>
                <AddPropertyDetails component={<EditBasicInfo />} text={t(`f:property.propertyDetails`)} />
            </Route>
            <Route path={`${path}/list-opportunity/:stepName`}>
                <ListOpportunityWizard />
            </Route>
            <Route path={`${path}/bidding-structure/:stepName`}>
                <BiddingStructureWizard />
            </Route>
            <Route path={`${path}/deal-line/:stepName`}>
                <DealLineWizard />
            </Route>
            <Route path={`${path}/bid`}>
                <PropertyBidRouteData />
            </Route>
            <Route path={`${path}/schedule/:scheduleId?`}>
                <ScheduleWizard />
            </Route>
            <Route path={`${path}/template/:pageType/:stepName/:templateKey?/:propertyAssetPageSummaryId?`}>
                <TemplateWizard />
            </Route>
        </Switch>
    );
};

const PropertyBidRouteData = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <LandingPageBidWizard />
            </Route>
            <Route path={`${path}/:stepName/:biddingId?`}>
                <BidWizard />
            </Route>
            <Route path={`${path}/portfolio/:stepName/:biddingId?`}>
                <PortfolioBidWizard />
            </Route>
        </Switch>
    );
};

export default PropertyRoute;
