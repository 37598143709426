import { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosTransformer } from 'axios';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getToken } from '../auth/UserService';
import i18n from 'i18next';

const buildingDirectory = axios.create({
    baseURL: process.env.REACT_APP_BUILDING_DIRECTORY_API_URL,
    headers: {
        common: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    },
});

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = getToken();

    if (token && token !== '') {
        config.headers.common = {
            ...config.headers.common,
            Authorization: `Bearer ${token}`,
        };
    }

    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[response error] [${JSON.stringify(error)}]`);

    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    // Do something with response data
    if (response.status === 201) {
        // Do something
    }

    if (response.status === 202) {
        // Do something
    }

    return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    if (error.response && error.response.status === 400) {
        toast.error(i18n.t(`b:${error.response.data.errorMessage}`), { className: 'error-toast' });
    }

    if (error.response && error.response.status === 500) {
        // Do something
    }

    if (!axios.isCancel(error)) {
        toast.error(`[response error] [${JSON.stringify(error)}]`, { className: 'error-toast' });
    }

    return Promise.reject(error);
};

export const defaultTransformers = (): AxiosTransformer[] => {
    const { transformRequest } = axios.defaults;
    if (!transformRequest) {
        return [];
    } else if (transformRequest instanceof Array) {
        return transformRequest;
    } else {
        return [transformRequest];
    }
};

buildingDirectory.interceptors.response.use(onResponse, onResponseError);
buildingDirectory.interceptors.request.use(onRequest, onRequestError);

export default buildingDirectory;
