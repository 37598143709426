import { FunctionComponent } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import MainTextInput from '../../../components/shared/MainTextInput';
import useBoolean from '../../../hooks/use-boolean';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
interface ColorPickerProps {
    name: string;
    setFieldValue: (name: string, val: any) => void;
    values: any;
}

const ColorPicker: FunctionComponent<ColorPickerProps> = ({ name, setFieldValue, values }: ColorPickerProps) => {
    const { t } = useTranslation(['f', 'b']),
        [isPickerShown, isPickerShownActions] = useBoolean();

    const handleChangeComplete = (color) => {
        setFieldValue(name, color.hex);
    };

    const handleToggleOpen = () => {
        isPickerShownActions.toggle();
    };

    const handleMouseLeft = () => {
        isPickerShownActions.setFalse();
    };

    return (
        <div className="col-xs-12-clear d-flex pointer relative ml-15" onMouseLeave={handleMouseLeft} key={name}>
            <div className="col-xs-10-clear d-flex align-items-center">
                <MainTextInput
                    label={t(`f:theme-manager.${name}`)}
                    placeholder={t(`f:theme-manager.pick${name}`)}
                    name={name}
                    touched={{}}
                    errors={{}}
                    disabled={true}
                />
            </div>
            <div className="col-xs-2-clear d-flex gap-10 align-items-center">
                <FontAwesomeIcon className="theme-manager-page__palette" icon={faPalette} onClick={handleToggleOpen} />
                <div
                    className="theme-manager-page__color-preview pointer"
                    onClick={handleToggleOpen}
                    style={{ backgroundColor: values[name] }}
                ></div>
            </div>
            {isPickerShown && (
                <SketchPicker
                    color={values[name]}
                    onChangeComplete={handleChangeComplete}
                    className="theme-manager-page__color-picker"
                />
            )}
        </div>
    );
};
export default ColorPicker;
