import { FunctionComponent } from 'react';

interface UserDetailsInModalProps {
    title: string;
    subtitle: string;
}

const UserDetailsInModal: FunctionComponent<UserDetailsInModalProps> = ({
    title,
    subtitle,
}: UserDetailsInModalProps) => (
    <div className="overview__informations-single" key={title}>
        <p className="overview__informations-title">{title}</p>
        <p className="overview__informations-title--subtitle">{subtitle}</p>
    </div>
);
export default UserDetailsInModal;
