import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popover } from "antd";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { ViewPropertyTabsProps } from "./ViewPropertyTabs";

const ViewPropertyTabsMobile: FunctionComponent<ViewPropertyTabsProps> = ({
    tabs,
    tabSelected,
    activTab
}) => {
    const { t } = useTranslation(['f', 'b']);
    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);

    const content = (
        <div className="d-flex flex-direction-column">
            {tabs.map(tab => (
                <Button
                    key={tab.id}
                    type="text"
                    className={`button-main ${tab.id.toString() === activTab ? 'button-primary' : ''}`}
                    onClick={() => {
                        tabSelected(tab.id);
                        setIsShowMenu(false);
                    }}
                >
                    {t(`f:property.${tab.name}`)}
                </Button>))}
        </div>
    );

    const tabContent = () => {
        const tab = tabs.find(item => item.id.toString() === activTab);

        return tab ? tab.component : null;
    }

    return (
        <>
            <Popover
                content={content}
                placement="topRight"
                overlayClassName="property-tabs-mobile"
                getTooltipContainer={(_) => document.getElementById("theme") as HTMLElement}
                onVisibleChange={(visible) => setIsShowMenu(visible)}
                visible={isShowMenu}
            >
                <Button
                    shape="circle"
                    className="button-primary property-tab-mobile-menu"
                    onClick={() => setIsShowMenu(!isShowMenu)}
                >
                    <FontAwesomeIcon icon={faBars} size={"1x"} className="menu-icon"/>
                </Button>
            </Popover>
            <div className="main-container relative">
                <div className="container__data">
                    <div className="property-tabs property-tabs__styled">
                        {tabContent()}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewPropertyTabsMobile;