const properties = [
    {
        "id": "0",
        "transactionDate": "May-21",
        "property": "Asia Square Tower 1",
        "tenantType": "Professional Services",
        "size": 22000,
        "grossEffectiveRent": 13.1,
        "leaseTerm": 3,
        "transactionType": "Renewal"
    },
    {
        "id": "1",
        "transactionDate": "May-21",
        "property": "MBFC T2",
        "tenantType": "Technology",
        "size": 25000,
        "grossEffectiveRent": 13,
        "leaseTerm": 3,
        "transactionType": "New Lease"
    },
    {
        "id": "2",
        "transactionDate": "Apr-21",
        "property": "Asia Square Tower 1",
        "tenantType": "Energy \/ Natural Resources",
        "size": 1500,
        "grossEffectiveRent": 16,
        "leaseTerm": 2,
        "transactionType": "Renewal"
    },
    {
        "id": "3",
        "transactionDate": "Apr-21",
        "property": "Asia Square Tower 1",
        "tenantType": "Financial Services",
        "size": 9500,
        "grossEffectiveRent": 15.3,
        "leaseTerm": 3,
        "transactionType": "Renewal"
    },
    {
        "id": "4",
        "transactionDate": "Apr-21",
        "property": "Asia Square Tower 2",
        "tenantType": "Energy \/ Natural Resources",
        "size": 2500,
        "grossEffectiveRent": 14.4,
        "leaseTerm": 3,
        "transactionType": "New Lease"
    },
    {
        "id": "5",
        "transactionDate": "Apr-21",
        "property": "ORQ North",
        "tenantType": "Energy \/ Natural Resources",
        "size": 2500,
        "grossEffectiveRent": 12.5,
        "leaseTerm": 3,
        "transactionType": "Renewal"
    },
    {
        "id": "6",
        "transactionDate": "Mar-21",
        "property": "CapitaGreen",
        "tenantType": "Technology",
        "size": 22700,
        "grossEffectiveRent": 13.5,
        "leaseTerm": 3,
        "transactionType": "New Lease"
    },
    {
        "id": "7",
        "transactionDate": "Jan-21",
        "property": "Asia Square Tower 1",
        "tenantType": "Financial Services",
        "size": 2500,
        "grossEffectiveRent": 17,
        "leaseTerm": 2,
        "transactionType": "Renewal"
    },
    {
        "id": "8",
        "transactionDate": "Jan-21",
        "property": "Asia Square Tower 1",
        "tenantType": "Financial Services",
        "size": 1500,
        "grossEffectiveRent": 16.3,
        "leaseTerm": 5,
        "transactionType": "New Lease"
    },
    {
        "id": "9",
        "transactionDate": "Jan-21",
        "property": "Asia Square Tower 1",
        "tenantType": "Financial Services",
        "size": 14000,
        "grossEffectiveRent": 15,
        "leaseTerm": 3,
        "transactionType": "Rent Review"
    },
    {
        "id": "10",
        "transactionDate": "Jan-21",
        "property": "CapitaGreen",
        "tenantType": "Trading House",
        "size": 22700,
        "grossEffectiveRent": 12,
        "leaseTerm": 5,
        "transactionType": "New Lease"
    },
    {
        "id": "11",
        "transactionDate": "Jan-21",
        "property": "MBFC T1",
        "tenantType": "Professional Services",
        "size": 40000,
        "grossEffectiveRent": 13,
        "leaseTerm": 3,
        "transactionType": "Rent Review"
    },
    {
        "id": "12",
        "transactionDate": "Jan-21",
        "property": "QUE Bayfront",
        "tenantType": "Financial Services",
        "size": 4000,
        "grossEffectiveRent": 14,
        "leaseTerm": 4,
        "transactionType": "New Lease"
    },
    {
        "id": "13",
        "transactionDate": "Dec-20",
        "property": "Asia Square Tower 2",
        "tenantType": "Other",
        "size": 22000,
        "grossEffectiveRent": 11.5,
        "leaseTerm": 6,
        "transactionType": "New Lease"
    },
    {
        "id": "14",
        "transactionDate": "Dec-20",
        "property": "CapitaGreen",
        "tenantType": "Financial Services",
        "size": 6500,
        "grossEffectiveRent": 12,
        "leaseTerm": 3,
        "transactionType": "New Lease"
    },
    {
        "id": "15",
        "transactionDate": "Dec-20",
        "property": "CapitaGreen",
        "tenantType": "Technology",
        "size": 35000,
        "grossEffectiveRent": 11.8,
        "leaseTerm": 5,
        "transactionType": "New Lease"
    },
    {
        "id": "16",
        "transactionDate": "Dec-20",
        "property": "Ocean Financial Centre",
        "tenantType": "Financial Services",
        "size": 2000,
        "grossEffectiveRent": 13,
        "leaseTerm": 5,
        "transactionType": "New Lease"
    },
    {
        "id": "17",
        "transactionDate": "Dec-20",
        "property": "Ocean Financial Centre",
        "tenantType": "Technology",
        "size": 32000,
        "grossEffectiveRent": 12,
        "leaseTerm": 3,
        "transactionType": "Renewal"
    },
    {
        "id": "18",
        "transactionDate": "Dec-20",
        "property": "Ocean Financial Centre",
        "tenantType": "Financial Services",
        "size": 140000,
        "grossEffectiveRent": 11,
        "leaseTerm": 4,
        "transactionType": "Renewal"
    },
    {
        "id": "19",
        "transactionDate": "Nov-20",
        "property": "Asia Square Tower 2",
        "tenantType": "Energy \/ Natural Resources",
        "size": 2500,
        "grossEffectiveRent": 15.4,
        "leaseTerm": 3,
        "transactionType": "New Lease"
    },
    {
        "id": "20",
        "transactionDate": "Nov-20",
        "property": "Asia Square Tower 2",
        "tenantType": "Financial Services",
        "size": 4500,
        "grossEffectiveRent": 13.95,
        "leaseTerm": 3,
        "transactionType": "New Lease"
    },
    {
        "id": "21",
        "transactionDate": "Nov-20",
        "property": "Asia Square Tower 2",
        "tenantType": "Financial Services",
        "size": 6500,
        "grossEffectiveRent": 13.05,
        "leaseTerm": 3,
        "transactionType": "New Lease"
    },
    {
        "id": "22",
        "transactionDate": "Nov-20",
        "property": "Asia Square Tower 2",
        "tenantType": "Technology",
        "size": 17000,
        "grossEffectiveRent": 11.8,
        "leaseTerm": 5,
        "transactionType": "New Lease"
    },
    {
        "id": "23",
        "transactionDate": "Oct-20",
        "property": "Asia Square Tower 1",
        "tenantType": "Energy \/ Natural Resources",
        "size": 8500,
        "grossEffectiveRent": 17,
        "leaseTerm": 2,
        "transactionType": "Renewal"
    },
    {
        "id": "24",
        "transactionDate": "Oct-20",
        "property": "Asia Square Tower 2",
        "tenantType": "Financial Services",
        "size": 3000,
        "grossEffectiveRent": 15,
        "leaseTerm": 2,
        "transactionType": "Expansion"
    },
    {
        "id": "25",
        "transactionDate": "Aug-20",
        "property": "Asia Square Tower 1",
        "tenantType": "Financial Services",
        "size": 2000,
        "grossEffectiveRent": 15,
        "leaseTerm": 2,
        "transactionType": "Renewal"
    },
    {
        "id": "26",
        "transactionDate": "Jun-20",
        "property": "Asia Square Tower 1",
        "tenantType": "Financial Services",
        "size": 3500,
        "grossEffectiveRent": 13.2,
        "leaseTerm": 3,
        "transactionType": "New Lease"
    },
    {
        "id": "27",
        "transactionDate": "Jun-20",
        "property": "MBFC T2",
        "tenantType": "Real Estate",
        "size": 20000,
        "grossEffectiveRent": 12.3,
        "leaseTerm": 3,
        "transactionType": "Rent Review"
    },
    {
        "id": "28",
        "transactionDate": "May-20",
        "property": "MBFC T3",
        "tenantType": "Energy \/ Natural Resources",
        "size": 11000,
        "grossEffectiveRent": 12.5,
        "leaseTerm": 3,
        "transactionType": "Rent Review"
    }
]
   export default {
    properties,
};