import ResearchApi from '../api/routes/ResearchApi';

const sendResearchContactForm = async (propertyAssetId: string, body: any) => {
    try {
        return await ResearchApi.sendResearchContactForm(propertyAssetId, body);
    } catch (err) {
        console.log(err);
    }
};
const ResearchService = {
    sendResearchContactForm,
};
export default ResearchService;
