import { FunctionComponent, useState, useEffect, useContext, useCallback } from 'react';
import Favorite from '../../services/Favorite';
import LookUpContext from '../../context/state/LookUpState';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { toast } from 'react-toastify';
import { HeartTwoTone, HeartOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import FormattedAmount from '../shared/FormattedAmount';
import MainLoader from './MainLoader';
import PropertyAsserts from '../../interfaces/property/PropertyAsserts';
import building from '../../assets/images/building.png';
interface PropertyCardProps {
    property: PropertyAsserts;
    showFavorite: boolean;
    className?: string;
    handleOnClick?: () => void;
}

const PropertyCard: FunctionComponent<PropertyCardProps> = ({
    property,
    showFavorite,
    className,
    handleOnClick,
}: PropertyCardProps) => {
    const { t } = useTranslation(['f', 'b']),
        [isFavorite, setIsFavorite] = useState<boolean>(false),
        [isLoading, setIsLoading] = useState<boolean>(true),
        { getContent } = useContext(LookUpContext);

    const checkIsFavorite = useCallback(async () => {
        setIsLoading(true);
        const favorite = await Favorite.fetchIsInFavorite(property.id);
        setIsFavorite(favorite);
        setIsLoading(false);
    }, [property.id]);

    const addToFavorite = async (event: any) => {
        setIsLoading(true);
        event.stopPropagation();
        try {
            const response = await Favorite.addToFavorite(property.id);
            if (response.status === 200) {
                setIsFavorite(true);
            }
            setIsLoading(false);
        } catch (error: any) {
            toast(error);
            setIsLoading(false);
        }
    };
    const removeFromFavorite = async (event: any) => {
        setIsLoading(true);
        event.stopPropagation();
        try {
            const response = await Favorite.deleteFromFavorite(property.id);
            if (response.status === 200) {
                setIsFavorite(false);
            }
            setIsLoading(false);
        } catch (error: any) {
            toast(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkIsFavorite();
    }, [checkIsFavorite]);

    const propertyLandTenure = property.landTenureId ? 
        getContent('LandTenure').find((element) => element.id === property.landTenureId) :
        null;

    return (
        <div
            className={`row-clear property-card__container property-card__container--homepage ${className}`}
            onClick={handleOnClick}
        >
            <figure className="property-card__image">
                <img src={property.imageUrl ? property.imageUrl : building} alt="Property" />
            </figure>
            <div className="property-card__title">
                <div className="d-flex property-card__favorite justify-content-space-between align-items-center">
                    <h3 className="main-text property-card__name">{property.propertyName}</h3>
                    {showFavorite ? (
                        <div>
                            <AuthenticatedTemplate>
                                {!isLoading ? (
                                    isFavorite ? (
                                        <Tooltip title="Remove from favorite">
                                            <Button
                                                shape="circle"
                                                onClick={removeFromFavorite}
                                                icon={<HeartTwoTone twoToneColor="#fd615a" />}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip title="Add to favorite">
                                            <Button shape="circle" onClick={addToFavorite} icon={<HeartOutlined />} />
                                        </Tooltip>
                                    )
                                ) : (
                                    <MainLoader size={32} type="regular" />
                                )}
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <></>
                            </UnauthenticatedTemplate>
                        </div>
                    ) : null}
                </div>
                <p className="property-card__address">{property.map.address}</p>
            </div>
            <div className="">
                <div className="row-clear property-card__element-list">
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.yearOfCompletion2')}</p>
                        <p className="property-card__element-value">{property.yearOfCompletion}</p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.landTenure')}</p>
                        <p className="property-card__element-value">
                            {propertyLandTenure ? propertyLandTenure['name'] : ''}
                        </p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.siteAreaSqft')}</p>
                        <p className="property-card__element-value">
                            <FormattedAmount value={property.landArea} />
                        </p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t(`f:property.modal.nla`)}</p>
                        <p className="property-card__element-value">
                            <FormattedAmount value={property.netLeasableArea} />
                        </p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.carparkLots')}</p>
                        <p className="property-card__element-value">
                            <FormattedAmount value={property.carparkLots} />
                        </p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.sectors')}</p>
                        <p className="property-card__element-value">
                            {property.sectorNames
                                .map((sector: string) => {
                                    return sector;
                                })
                                .join(', ')}
                        </p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t(`f:property.modal.majorTenants`)}</p>
                        <p className="property-card__element-value">{property?.majorTenants}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyCard;
