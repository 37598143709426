import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm, Space, Table, Checkbox, Input, AutoComplete, Select, Row, Col, Spin } from 'antd';
import AddUser from './AddUser';
import AddUserPermission from './AddUserPermission';
import CompanyService from '../../../services/Admin/Company';
import { RoleOptions } from '../../../constants/roles';

const { Option } = Select;

const UserList: FunctionComponent = () => {
    let { id: companyId } = useParams<any>();

    const [loading, setLoading] = useState<boolean>(true);
    const [autocompleteUsers, setAutocompleteUsers] = useState<Array<any>>([]);
    const [adduserModalVisible, setAddUserModalVisible] = useState<boolean>(false);
    const [userTable, setUserTable] = useState<any>({
        currentPageNumber: 1,
        users: [],
    });
    const [totalUserCount, setTotalUserCount] = useState(0);
    const [roleModal, setRoleModal] = useState<any>({
        visible: false,
        confirmMessage: '',
        userId: 0,
        newRoleId: -1,
    });
    const [permissionModal, setPermissionModal] = useState<any>({
        userId: 0,
        visible: false,
    });
    const { t } = useTranslation(['f']);
    const userPageSize = 20;

    useEffect(() => {
        fetchUsersInCompany(companyId, 0);
    }, []);

    async function handleAddUser(userId) {
        if (userId == 0) return;
        await CompanyService.addUsersToCompany(companyId, [userId]);
        setAutocompleteUsers([]);
        fetchUsersInCompany(companyId, userTable.currentPageNumber - 1);
    }

    function fetchUsersInCompany(companyId, pageIdx: number) {
        setLoading(true);
        CompanyService.fetchCompanyUsers(companyId, pageIdx, userPageSize).then((resp) => {
            setLoading(false);
            if (resp && resp.items) {
                setUserTable({
                    currentPageNumber: pageIdx + 1,
                    users: [...resp.items],
                });
                setTotalUserCount(resp.totalCount);
            }
        });
    }

    async function handleActive(id: any) {}

    async function handleRemoveUser(userId) {
        await CompanyService.removeUsersFromCompany(companyId, [userId]);
        fetchUsersInCompany(companyId, userTable.currentPageNumber - 1);
    }

    const openAddUserModal = () => {
        setAddUserModalVisible(true);
    };

    function getRoleName(roleId) {
        return roleId == 1 ? 'Admin' : roleId == 0 ? 'Member' : 'Manager';
    }

    function handleUsersPageChange(pageNumber, pageSize) {
        fetchUsersInCompany(userTable.companyId, pageNumber - 1);
    }

    async function userAdded() {
        setAddUserModalVisible(false);
        fetchUsersInCompany(companyId, 0);
    }

    async function cancelAddUserModal() {
        setAddUserModalVisible(false);
    }

    async function searchUsers(e) {
        let keyword = e.target.value;

        if (keyword.length >= 3) {
            const users = await CompanyService.searchUsersNotInCompany(companyId, keyword);
            const userOptions = users.map((u) => ({
                id: u.id,
                fullName: u.fullName,
                value: u.email,
                label: (
                    <div>
                        <Popconfirm title="Are you sure ?" onConfirm={() => handleAddUser(u.id)}>
                            <Button type="primary" style={{ marginRight: 15 }}>
                                Add
                            </Button>
                        </Popconfirm>
                        {u.email}
                    </div>
                ),
            }));

            if (userOptions.length == 0) {
                userOptions.push({
                    id: 0,
                    label: 'Not found users',
                    value: '',
                });
            }

            setAutocompleteUsers([...userOptions]);
        }
    }

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    const userColumns = [
        {
            title: 'User Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Role',
            key: 'role',
            render: (_, record) =>
                record.role != 3 ? (
                    <Space size="middle">
                        <Select
                            defaultValue={record.role}
                            style={{ width: 130 }}
                            value={record.role}
                            onSelect={(value) => {
                                if (value != record.role) {
                                    setRoleModal({
                                        visible: true,
                                        userId: record.id,
                                        newRoleId: value,
                                        confirmMessage: `Do you want to assign role from ${getRoleName(
                                            record.role
                                        )} to ${getRoleName(value)} for ${record.email} ?`,
                                    });
                                }
                            }}
                            getPopupContainer={handleContainer}
                        >
                            {Object.keys(RoleOptions)
                                .map(Number)
                                .map((key) => (
                                    <Option value={key}>{t(`f:userRoles.${key}`)}</Option>
                                ))}
                        </Select>
                    </Space>
                ) : (
                    <Space size="middle">
                        <Select
                            defaultValue={record.role}
                            style={{ width: 130 }}
                            disabled
                            getPopupContainer={handleContainer}
                        >
                            <Option value={3}>Super Admin</Option>
                        </Select>
                    </Space>
                ),
        },
        {
            title: 'Active',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm title="Are you sure ?" onConfirm={() => handleActive(record.id)}>
                        <Checkbox checked={record.isActive}></Checkbox>
                    </Popconfirm>
                </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm title="Are you sure ?" onConfirm={() => handleRemoveUser(record.id)}>
                        <Button danger={true}>Remove</Button>
                    </Popconfirm>
                    <Button onClick={() => setPermissionModal({ userId: record.id, visible: true })}>Permission</Button>
                </Space>
            ),
        },
    ];

    return (
        <>
            <br />
            <Row>
                <Col span={8}></Col>
                <Col span={8}>
                    <AutoComplete
                        onBlur={() => setAutocompleteUsers([])}
                        open={autocompleteUsers.length > 0}
                        onKeyUp={searchUsers}
                        dropdownMatchSelectWidth={500}
                        style={{
                            width: '90%',
                            marginBottom: 15,
                        }}
                        notFoundContent="Not Found Users"
                        options={autocompleteUsers}
                    >
                        <Input.Search
                            size="large"
                            placeholder="input at least 3 characters to find and re-add removed users"
                        />
                    </AutoComplete>
                </Col>
                <Col span={8}>
                    <Button
                        onClick={openAddUserModal}
                        type="primary"
                        style={{
                            marginTop: 6,
                        }}
                    >
                        Add user
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Spin tip="Loading..." spinning={loading}>
                        <Table
                            rowClassName={() => 'editable-row'}
                            bordered
                            dataSource={userTable.users}
                            columns={userColumns}
                            pagination={{
                                onChange: handleUsersPageChange,
                                total: totalUserCount,
                                pageSize: userPageSize,
                            }}
                        />
                    </Spin>
                </Col>
            </Row>
            <AddUser
                companyId={companyId}
                isModalOpen={adduserModalVisible}
                onFinish={userAdded}
                onCancel={cancelAddUserModal}
            ></AddUser>
            <AddUserPermission
                userId={permissionModal.userId}
                companyId={companyId}
                isModalOpen={permissionModal.visible}
                onCancel={() => setPermissionModal({ userId: -1, visible: false })}
            ></AddUserPermission>
        </>
    );
};

export default UserList;
