import { FunctionComponent, MouseEventHandler, useCallback } from 'react';

interface RadioBtnsProps {
    text: string;
    value: string;
    handler: (val: string) => void;
    activComponent: string;
}

const RadioBtns: FunctionComponent<RadioBtnsProps> = ({ activComponent, value, handler, text }: RadioBtnsProps) => {
    const handleOnChange: MouseEventHandler<HTMLLabelElement> = useCallback(
        (e) => {
            handler(value);
        },
        [handler, value]
    );

    return (
        <>
            <input
                type="radio"
                id={value}
                name="option"
                value={value}
                className="radio-button"
                checked={activComponent === value}
                readOnly
            />
            <label htmlFor="rent" className="radio-button__layout ml-5" onClick={handleOnChange}>
                {text}
            </label>
        </>
    );
};
export default RadioBtns;
