import * as Yup from 'yup';

export const AddTeamUserSchema = Yup.object().shape({
    address: Yup.string().required('f:validation.required'),
    company: Yup.string().required('f:validation.required'),
    email: Yup.string().email('f:validation:emailAddress').required('f:validation.required'),
    name: Yup.string().required('f:validation.required'),
    phone: Yup.string().required('f:validation.required'),
    roleType: Yup.number().required('f:validation.required'),
});
