import api from "..";
import { ToggleCompanyStatusEnum } from "../../constants/toggleCompanyStatus";

const postCompanyNote = (propertyAssetId: string | number, companyId: string | number, note: string) => {
    return api.post(`/PropertyAssetCompany/${propertyAssetId}/company/${companyId}/comment`, { content: note });
}

const fetchAllCompanyNotes = (propertyAssetId: string | number, companyId: string | number) => {
    return api.get(`/PropertyAssetCompany/${propertyAssetId}/company/${companyId}/comment`);
}

const fetchInvestorUsersOfCompany = async (propertyAssetId: string | number, companyId: string | number) => {
    return api.get(`/PropertyAssetCompany/user/company/${companyId}/property/${propertyAssetId}`);
}

const fetchCompanyInfo = async (propertyAssetId: string | number, companyId: string | number) => {
    return api.get(`/PropertyAssetCompany/${propertyAssetId}/company/${companyId}`);
}

const toggleCompanyStatus = (propertyAssetId: string, companyId: string | number, status: ToggleCompanyStatusEnum) => {
    const data = {
        propertyAssetId,
        companyId,
        status
    }
    return api.patch(`/PropertyAssetCompany/toggle-status/company`, data);
};

const deleteCompany = (propertyAssetId: string | number, companyId: string | number) => {
    return api.delete(`/PropertyAssetCompany/${propertyAssetId}/company/${companyId}`);
}

const PropertyAssetCompanyApi = {
    postCompanyNote,
    fetchAllCompanyNotes,
    fetchInvestorUsersOfCompany,
    fetchCompanyInfo,
    toggleCompanyStatus,
    deleteCompany
}

export default PropertyAssetCompanyApi;