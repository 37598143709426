import { FunctionComponent, useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ProjectTeam from '../../services/ProjectTeam';
import DataRoomService from '../../services/DataRoom';
import {
    NodePermission,
    UserPermissionItem,
    OptionsUser,
    NavigationStrings,
    defaultPermissions,
} from '../../interfaces/dataRoom/usersWithPermissions';
import UserTable from '../../pages/ViewProperty/components/dataRoom/partials/UserTable';
import FeatureState from '../../context/state/FeatureState';

interface Props {
    nodeId: string;
    isModalOpen: boolean;
}

//Admin

const ShareWithModal: FunctionComponent<Props> = (props) => {
    const { nodeId } = props;

    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [permissions] = useState<Array<NodePermission>>(defaultPermissions),
        [usersWithPermissions, setUsersWithPermissions] = useState<Array<UserPermissionItem>>([]),
        [optionsUsers, setOptionsUsers] = useState<Array<OptionsUser>>([]),
        [currentActiveNavigation, setCurrentActiveNavigation] = useState<string>(NavigationStrings.user),
        [optionsInvestors, setOptionsInvestors] = useState<Array<OptionsUser>>([]);

    const fetchUsersInProjectTeam = useCallback(async () => {
        try {
            const projectTeam = await ProjectTeam.fetchProjectTeam(`${propertyAssetId}`);

            const mapped = projectTeam.map((x) => {
                return {
                    id: x.user.id,
                    name: x.user.fullName,
                    email: x.user.email,
                    companyName: x.user.company.name,
                };
            });

            const myTeam = await ProjectTeam.fetchMyteam(`${propertyAssetId}`);
            myTeam?.map((x) => {
                const item = {
                    id: x.user.id,
                    name: x.user.fullName,
                    email: x.user.email,
                    companyName: x.user.company.name,
                };
                mapped.push(item);
            });

            setOptionsUsers(mapped);
        } catch (error: any) {
            toast(t(`f:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const fetchInvestors = useCallback(async () => {
        try {
            const investors = await ProjectTeam.fetchInvestors(`${propertyAssetId}`);

            const mapped = investors.map((x) => {
                const obj: OptionsUser = {
                    id: x.companyId,
                    name: x.companyName,
                    icon: '',
                    email: '',
                    permission: '',
                    role: 0,
                    companyName: '',
                };
                return obj;
            });

            setOptionsInvestors(mapped);
        } catch (error: any) {
            if (error && error.response) toast(t(`b:${error.response.data}`));
        }
    }, [propertyAssetId, t]);

    const fetchUsersWithPermissions = useCallback(async () => {
        try {
            const data = await DataRoomService.fetchUsersWithPermissions(nodeId);

            setUsersWithPermissions([...data.directPermissions]);
        } catch (error: any) {
            toast(t(`f:${error.response.data.errorMessage}`));
        }
    }, [nodeId, t]);

    useEffect(() => {
        fetchInvestors();
        fetchUsersInProjectTeam();
    }, [fetchInvestors, fetchUsersInProjectTeam]);

    useEffect(() => {
        if (props.isModalOpen) fetchUsersWithPermissions();
    }, [optionsUsers, nodeId, props.isModalOpen]);

    const handleClick = useCallback(
        async (addOrDelete: boolean, id: string, permission: number) => {
            try {
                if (addOrDelete) {
                    await DataRoomService.addUserPermission(id, nodeId, permission);
                } else {
                    await DataRoomService.removeAccessForFolder(id, nodeId);
                }
                fetchUsersWithPermissions();
            } catch (error: any) {
                toast(t(`f:${error.response.data.errorMessage}`));
            }
        },
        [t, nodeId, fetchUsersWithPermissions]
    );

    const navigationMenu = useMemo(() => {
        return [
            { name: t('f:share-with-modal.user'), value: NavigationStrings.user },
            { name: t('f:share-with-modal.company'), value: NavigationStrings.user },
        ];
    }, [t]);

    const renderMainContent = useCallback(() => {
        switch (currentActiveNavigation) {
            case NavigationStrings.COMPANY:
                return (
                    <UserTable
                        usersList={optionsUsers}
                        permissions={permissions}
                        usersWithPermissions={usersWithPermissions}
                        handleClick={handleClick}
                    />
                );

            default:
                return (
                    <UserTable
                        usersList={optionsUsers}
                        permissions={permissions}
                        usersWithPermissions={usersWithPermissions}
                        handleClick={handleClick}
                    />
                );
        }
    }, [optionsUsers, currentActiveNavigation, usersWithPermissions, handleClick, permissions]);

    return (
        <>
            <div className="navigation-area d-flex justify-content-center mb-20">
                <div className="navigation-area__main d-flex">
                    {navigationMenu.map((elem) => {
                        return (
                            <h2
                                className={`templates-main-title pointer ${
                                    elem.value === currentActiveNavigation ? 'navigation-area__underline' : ''
                                }`}
                                onClick={() => {
                                    setCurrentActiveNavigation(elem.value);
                                }}
                            >
                                {elem.name}
                            </h2>
                        );
                    })}
                </div>
            </div>
            <div className="invite-area">{renderMainContent()}</div>
        </>
    );
};
export default ShareWithModal;
