export const COUNTIES_WITH_CITIES = {
    'Australia': [
        "Adelaide",
        "Adelaide Hills",
        "Ainslie, Canberra",
        "Albany",
        "Albury",
        "Alexandrina",
        "Alice Springs",
        "Alpine",
        "Amaroo",
        "Armadale, Perth",
        "Armidale Regional",
        "Ashburton",
        "Augusta-Margaret River",
        "Ballarat",
        "Ballina",
        "Banana",
        "Banyule, Melbourne",
        "Barossa",
        "Barton, Canberra",
        "Bass Coast",
        "Bassendean, Perth",
        "Bathurst Regional",
        "Baw Baw (S)",
        "Bayside, Melbourne",
        "Bayswater, Perth",
        "Bega Valley",
        "Belconnen",
        "Belmont, Perth",
        "Berri and Barmera",
        "Berrigan",
        "Berrimah, Darwin",
        "Blacktown, Sydney",
        "Blue Mountains",
        "Bonner",
        "Boroondara, Melbourne",
        "Botanic Ridge",
        "Braddon, Canberra",
        "Brighton",
        "Brimbank, Melbourne",
        "Brisbane",
        "Broken Hill",
        "Brookton",
        "Broome",
        "Bruce",
        "Bulla",
        "Bunbury",
        "Bundaberg",
        "Burdekin",
        "Burnie",
        "Burnside, Adelaide",
        "Burwood, Sydney",
        "Busselton",
        "Byron",
        "Cairns",
        "Calwell",
        "Cambridge, Perth",
        "Camden, Sydney",
        "Campaspe",
        "Campbell, Canberra",
        "Campbelltown, Adelaide",
        "Campbelltown, Sydney",
        "Canada Bay, Sydney",
        "Canning, Perth",
        "Canterbury-Bankstown, Sydney",
        "Capel",
        "Cardinia",
        "Cardinia, Melbourne",
        "Carnarvon",
        "Carrathool",
        "Casey",
        "Casey, Melbourne",
        "Cassowary Coast",
        "Ceduna",
        "Central Coast (C) (NSW)",
        "Central Goldfields",
        "Central Highlands (R) (Qld)",
        "Cessnock",
        "Charles Sturt, Adelaide",
        "Charters Towers",
        "Chisholm",
        "Chittering",
        "Circular Head",
        "City of Melbourne, Melbourne",
        "City, Canberra",
        "Clare and Gilbert Valleys",
        "Claremont, Perth",
        "Clarence",
        "Clarence Valley",
        "Clyde North",
        "Cockburn, Perth",
        "Coffs Harbour",
        "Colac-Otway",
        "Collie",
        "Conder",
        "Cook",
        "Coombs",
        "Copper Coast",
        "Corangamite",
        "Cottesloe, Perth",
        "Cowra",
        "Cranbourne South",
        "Cumberland, Sydney",
        "Curtin",
        "Dardanup",
        "Darebin, Melbourne",
        "Darwin",
        "Deakin, Canberra",
        "Derrimut, Melbourne",
        "Devonport",
        "Dickson, Canberra",
        "Donnybrook-Balingup",
        "Douglas",
        "East Arm",
        "East Fremantle, Perth",
        "East Gippsland",
        "East Pilbara",
        "Edward River",
        "Esperance",
        "Eurobodalla",
        "Fairfield, Sydney",
        "Farrer",
        "Federation",
        "Flinders Ranges",
        "Forbes",
        "Forrest, Canberra",
        "Frankston, Melbourne",
        "Fraser Coast",
        "Fremantle, Perth",
        "Fyshwick, Canberra",
        "Gannawarra",
        "Gawler, Adelaide",
        "Georges River, Sydney",
        "Ghan",
        "Gladstone",
        "Glamorgan/Spring Bay",
        "Glen Eira, Melbourne",
        "Glenelg",
        "Glenorchy",
        "Gold Coast",
        "Goondiwindi",
        "Gosnells, Perth",
        "Goulburn Mulwaree",
        "Grant",
        "Greater Bendigo",
        "Greater Dandenong, Melbourne",
        "Greater Geelong",
        "Greater Geraldton",
        "Greater Shepparton",
        "Greenway",
        "Griffith",
        "Griffith, Canberra",
        "Gundagai",
        "Gungahlin",
        "Gunnedah",
        "Gympie",
        "Harrison",
        "Harvey",
        "Hawker (ACT)",
        "Hawkesbury",
        "Hepburn",
        "Hilltops",
        "Hinchinbrook",
        "Hobart (C)",
        "Hobsons Bay, Melbourne",
        "Holdfast Bay, Adelaide",
        "Hornsby, Sydney",
        "Horsham",
        "Hume",
        "Hume, Canberra",
        "Hume, Melbourne",
        "Hunters Hill, Sydney",
        "Inner West, Sydney",
        "Inverell",
        "Ipswich, Brisbane",
        "Irwin",
        "Isaac",
        "Isabella Plains",
        "Joondalup, Perth",
        "Kalamunda, Perth",
        "Kaleen",
        "Kalgoorlie/Boulder",
        "Kambah",
        "Kangaroo Island",
        "Karratha",
        "Katherine",
        "Kempsey",
        "Kentish",
        "Kiama",
        "King Island",
        "Kingborough",
        "Kingston, Canberra",
        "Kingston, Melbourne",
        "Knox, Melbourne",
        "Ku-ring-gai, Sydney",
        "Kwinana",
        "Lake Macquarie",
        "Lane Cove, Sydney",
        "Latrobe (C) (Vic.)",
        "Launceston",
        "Lawson",
        "Leeton",
        "Light",
        "Lismore",
        "Litchfield",
        "Lithgow",
        "Liverpool, Sydney",
        "Livingstone",
        "Lockyer Valley",
        "Logan, Brisbane",
        "Loxton Waikerie",
        "Lyneham, Canberra",
        "Lyons",
        "Macedon Ranges",
        "Macgregor",
        "Mackay",
        "Macquarie",
        "Main Ridge",
        "Maitland",
        "Majura, Canberra",
        "Mallala",
        "Mambourin",
        "Mandurah",
        "Manningham, Melbourne",
        "Mareeba",
        "Maribyrnong, Melbourne",
        "Marion, Adelaide",
        "Maroondah, Melbourne",
        "Marysville",
        "Meander Valley",
        "Melbourne",
        "Melton",
        "Melton, Melbourne",
        "Melville, Perth",
        "Mickleham",
        "Mid Murray",
        "Mid-Coast",
        "Mid-Western Regional",
        "Mildura",
        "Mitcham, Adelaide",
        "Mitchell (ACT)",
        "Mitchell (S)",
        "Moira",
        "Molonglo",
        "Monash, Melbourne",
        "Moonee Valley, Melbourne",
        "Moorabool",
        "Moree Plains",
        "Moreland, Melbourne",
        "Moreton Bay, Brisbane",
        "Mornington Peninsula, Melbourne",
        "Mosman Park, Perth",
        "Mosman, Sydney",
        "Mount Alexander",
        "Mount Barker",
        "Mount Gambier",
        "Mount Isa",
        "Moyne",
        "Mundaring, Perth",
        "Murray",
        "Murray Bridge",
        "Muswellbrook",
        "Nambucca",
        "Naracoorte and Lucindale",
        "Narrabri",
        "Narrabundah, Canberra",
        "Narrogin",
        "Nedlands, Perth",
        "Newcastle",
        "Ngunnawal",
        "Niddrie, Melbourne",
        "Nillumbik",
        "Nillumbik, Melbourne",
        "Noosa",
        "North Sydney, Sydney",
        "Northam",
        "Northern Beaches, Sydney",
        "Northern Midlands",
        "Norwood Payneham St Peters, Adelaide",
        "Officer",
        "Old Calperum",
        "Onkaparinga, Adelaide",
        "Orange",
        "Page",
        "Palmerston (C)",
        "Parkes",
        "Parkes, Canberra",
        "Parramatta, Sydney",
        "Penrith, Sydney",
        "Peppermint Grove, Perth",
        "Perth",
        "Petermann",
        "Phillip",
        "Pinelands",
        "Playford, Adelaide",
        "Plumpton",
        "Port Adelaide Enfield, Adelaide",
        "Port Augusta",
        "Port Hedland",
        "Port Lincoln",
        "Port Macquarie-Hastings",
        "Port Phillip, Melbourne",
        "Port Pirie City and Dists",
        "Port Stephens",
        "Prospect, Adelaide",
        "Queanbeyan",
        "Queanbeyan-Palerang Regional",
        "Randwick, Sydney",
        "Ravenhall",
        "Redland, Brisbane",
        "Reid, Canberra",
        "Renmark",
        "Renmark West",
        "Richmond Valley",
        "Rockbank",
        "Rockdale, Sydney",
        "Rockhampton",
        "Rockingham",
        "Roper Gulf",
        "Ryde, Sydney",
        "Salisbury, Adelaide",
        "Scenic Rim",
        "Serpentine-Jarrahdale",
        "Shark Bay",
        "Shellharbour",
        "Shoalhaven",
        "Singleton",
        "Snowy Monaro Regional",
        "Snowy Valleys",
        "Somerset",
        "Sorell",
        "South Burnett",
        "South Gippsland",
        "South Perth, Perth",
        "Southern Downs",
        "Southern Grampians",
        "St Kilda, Melbourne",
        "Stirling, Perth",
        "Stonnington, Melbourne",
        "Strathbogie",
        "Strathfield, Sydney",
        "Subiaco, Perth",
        "Sunshine Coast",
        "Surf Coast",
        "Sutherland Shire, Sydney",
        "Swan Hill",
        "Swan, Perth",
        "Sydney",
        "Symonston, Canberra",
        "Tablelands",
        "Tamworth Regional",
        "Tea Tree Gully, Adelaide",
        "Tenterfield",
        "The Hills Shire, Sydney",
        "Throsby",
        "Toowoomba",
        "Torres",
        "Townsville",
        "Truganina, Melbourne",
        "Turner, Canberra",
        "Tweed",
        "Unley, Adelaide",
        "Upper Hunter Shire",
        "Victor Harbor",
        "Victoria Park, Perth",
        "Vincent, Perth",
        "Wagga Wagga",
        "Walkerville, Adelaide",
        "Wangaratta",
        "Wanneroo, Perth",
        "Wanniassa",
        "Waratah/Wynyard",
        "Warrnambool",
        "Warrumbungle Shire",
        "Watson, Canberra",
        "Wattle Range",
        "Waverley, Sydney",
        "Wellington",
        "Werribee South",
        "West Coast",
        "West Tamar",
        "West Torrens, Adelaide",
        "West Wimmera",
        "Western Downs",
        "Western Plains Regional",
        "Weston",
        "Whitehorse, Melbourne",
        "Whitsunday",
        "Whittlesea",
        "Whittlesea, Melbourne",
        "Willoughby, Sydney",
        "Wingecarribee",
        "Winnellie, Darwin",
        "Wishart",
        "Wodonga",
        "Wollert",
        "Wollondilly",
        "Wollongong",
        "Wongan-Ballidu",
        "Woollahra, Sydney",
        "Wright",
        "Wyndham",
        "Wyndham, Melbourne",
        "Wyndham-East Kimberley",
        "Yarra Ranges",
        "Yarra Ranges, Melbourne",
        "Yarra, Melbourne",
        "Yass Valley",
        "York",
        "Yulara"
    ],
    'Bangladesh': [
        "Chittagong",
        "Dhaka",
        "Magura",
        "Narayanganj",
        "Rangamati"
    ],
    'Bhutan': ["Paro"],
    'Cambodia': [
        "Batdambang",
        "Bavet",
        "Kampong Cham",
        "Kampong Chhnang",
        "Kampong Seu",
        "Kandal",
        "Phnom Penh",
        "Siem Reap",
        "Sihanoukville"
    ],
    'China': [
        "Aksu",
        "Alashan",
        "Altay",
        "Ankang",
        "Anqing",
        "Anshan",
        "Anshun",
        "Anyang",
        "Baise",
        "Baoding",
        "Baoji",
        "Baoshan",
        "Baoting",
        "Baotou",
        "Bayannao'er",
        "Bazhong",
        "Beihai",
        "Beijing",
        "Bengbu",
        "Benxi",
        "Bijie",
        "Binzhou",
        "Bohu",
        "Bozhou",
        "Cangzhou",
        "Changchun",
        "Changde",
        "Changji Hui",
        "Changjiang Li",
        "Changsha",
        "Changxing County",
        "Changzhi",
        "Changzhou",
        "Chaoyang",
        "Chaozhou",
        "Chengde",
        "Chengdu",
        "Chengmai",
        "Chenzhou",
        "Chifeng",
        "Chizhou",
        "Chongqing",
        "Chongzuo",
        "Chuxiong Yi",
        "Chuzhou",
        "Dali",
        "Dalian",
        "Dandong",
        "Danzhou",
        "Daqing",
        "Datong",
        "Dazhou",
        "Dehong Dai",
        "Deyang",
        "Dezhou",
        "Ding'an",
        "Dingxi",
        "Dongfang",
        "Dongguan",
        "Dongying",
        "Enshi",
        "Ezhou",
        "Fangchenggang",
        "Foshan",
        "Fushun",
        "Fuxin",
        "Fuyang",
        "Fuzhou",
        "Ganzhou",
        "Ganzi Tibetan",
        "Guang'an",
        "Guangyuan",
        "Guangzhou",
        "Guigang",
        "Guilin",
        "Guiyang",
        "Hai dong",
        "Haibei Prefecture",
        "Haikou",
        "Hainan Tibetan",
        "Haixi Mongolian-Tibetan",
        "Hakusan",
        "Handan",
        "Hangzhou",
        "Hanzhong",
        "Harbin",
        "Hebi",
        "Hechi",
        "Hefei",
        "Hegang",
        "Heihe",
        "Hengshui",
        "Hengyang",
        "Heyuan",
        "Heze",
        "Hezhou",
        "Hing'an",
        "Hohhot",
        "Honghe",
        "Hotan",
        "Huai'an",
        "Huaibei",
        "Huaihua",
        "Huainan",
        "Huanggang",
        "Huangliu",
        "Huangshan",
        "Huangshi",
        "Huizhou",
        "Huludao",
        "Hulunbeir",
        "Huzhou",
        "Ili Kazak",
        "Ji County",
        "Jiamusi",
        "Ji'an",
        "Jiangmen",
        "Jiaozuo",
        "Jiaxing",
        "Jiayuguan",
        "Jieyang",
        "Jilin",
        "Jinan",
        "Jincheng",
        "Jingdezhen",
        "Jinghong",
        "Jingmen",
        "Jingzhou",
        "Jinhua",
        "Jining",
        "Jinzhong",
        "Jinzhou",
        "Jishou",
        "Jiujiang",
        "Jiuquan",
        "Jixi",
        "Kaifeng",
        "Kashi",
        "Kirgiz",
        "Kunming",
        "Kuytun",
        "Laibin",
        "Laiwu",
        "Langfang",
        "Lanzhou",
        "Leshan",
        "Lhasa",
        "Liangshan Yi",
        "Lianyungang",
        "Liaocheng",
        "Liaoyang",
        "Liaoyuan",
        "Lijiang",
        "Lincang",
        "Linfen",
        "Lingao",
        "Lingshui",
        "Linxia Hui",
        "Linyi",
        "Lishui",
        "Liupanshui",
        "Liuzhou",
        "Longyan",
        "Loudi",
        "Lu'an",
        "Luliang",
        "Luohe",
        "Luoyang",
        "Luzhou",
        "Maanshan",
        "Maoming",
        "Meishan",
        "Meizhou",
        "Mianyang",
        "Mudanjiang",
        "Nanchang",
        "Nanchong",
        "Nanjing",
        "Nanning",
        "Nanping",
        "Nantong",
        "Nanyang",
        "Neijiang",
        "Ningbo",
        "Ningde",
        "Ordos",
        "Panjin",
        "Panzhihua",
        "Pingdingshan",
        "Pingliang",
        "Pingxiang",
        "Puer",
        "Putian",
        "Puyang",
        "Qiandongnan",
        "Qianjiang",
        "Qidong",
        "Qingdao",
        "Qingyang",
        "Qingyuan",
        "Qinhuangdao",
        "Qinzhou",
        "Qionghai",
        "Qiqihar",
        "Quanzhou",
        "Qujing",
        "Quzhou",
        "Rizhao",
        "Sanmenxia",
        "Sanming",
        "Sanya",
        "Shanghai",
        "Shangluo",
        "Shangqiu",
        "Shangrao",
        "Shannan",
        "Shantou",
        "Shanwei",
        "Shaoguan",
        "Shaoxing",
        "Shaoyang",
        "Shennongjia",
        "Shenyang",
        "Shenzhen",
        "Shihezi",
        "Shijiazhuang",
        "Shiyan",
        "Shizuishan",
        "Shuozhou",
        "Silver",
        "Siping",
        "Songyuan",
        "Suihua",
        "Suining",
        "Suizhou",
        "Suqian",
        "Suzhou",
        "Tacheng",
        "Tai'an",
        "Taiyuan",
        "Taizhou",
        "Tangshan",
        "Tianjin",
        "Tianmen",
        "Tianshui",
        "Tieling",
        "Tongchuan",
        "Tonghua",
        "Tongliao",
        "Tongling",
        "Tongren",
        "Tunchang",
        "Ulanqab",
        "Urumqi",
        "Wanning",
        "Weifang",
        "Weihai",
        "Weinan",
        "Wenchang",
        "Wenshan Zhuang",
        "Wenzhou",
        "Wuhai",
        "Wuhan",
        "Wuhu",
        "Wujiaqu",
        "Wuxi",
        "Wuzhishan",
        "Wuzhong",
        "Wuzhou",
        "Xiamen",
        "Xian",
        "Xiangtan",
        "Xiangyang",
        "Xianning",
        "Xiantao",
        "Xianyang",
        "Xiaogan",
        "Xilin Gol",
        "Xingtai",
        "Xingyi",
        "Xining",
        "Xinxiang",
        "Xinyang",
        "Xinyu",
        "Xinzhou",
        "Xuancheng",
        "Xuchang",
        "Xuzhou",
        "Ya'an",
        "Yan'an",
        "Yancheng",
        "Yangjiang",
        "Yangquan",
        "Yangzhou",
        "Yantai",
        "Yibin",
        "Yichang",
        "Yichun",
        "Yinchuan",
        "Yingkou",
        "Yingtan",
        "Yiyang",
        "Yongzhou",
        "Yueyang",
        "Yulin",
        "Yuncheng",
        "Yunfu",
        "Yuxi",
        "Zaozhuang",
        "Zhangjiajie",
        "Zhangjiakou",
        "Zhangzhou",
        "Zhanjiang",
        "Zhaoqing",
        "Zhaotong",
        "Zhengzhou",
        "Zhenjiang",
        "Zhongshan",
        "Zhoukou",
        "Zhoushan",
        "Zhuhai",
        "Zhumadian",
        "Zhuzhou",
        "Zibo",
        "Zigong",
        "Ziyang",
        "Zunyi"
    ],
    'Fiji': [],
    'French Polynesia': ["Punaauia"],
    'Guam': [
        "Dededo",
        "Piti",
        "Tamuning",
        "Yigo",
        "Yona"
    ],
    'Hong Kong, SAR, China': ["Hong Kong"],
    'India': [
        "Agra",
        "Ajmer",
        "Alibag",
        "Alwar",
        "Ambattur",
        "Amritsar",
        "Asansol",
        "Bangalore",
        "Bawal",
        "Bharatpur",
        "Bhavnagar",
        "Bhiwandi",
        "Bhopal",
        "Bikaner",
        "Chandigarh",
        "Chengalpattu",
        "Chennai",
        "Chirala",
        "Chotila",
        "Coimbatore",
        "Dadri",
        "Darjiling",
        "Dehradun",
        "Devanahalli",
        "Dhandhuka",
        "Dhar",
        "Dharwad",
        "Dod Ballapur",
        "Dongargaon",
        "Ernakulam",
        "Faridabad",
        "Gandhidham",
        "Gandhinagar",
        "Ghatkesar",
        "Ghaziabad",
        "Gundlupet",
        "Gurgaon",
        "Gwalior",
        "Haldia",
        "Haora",
        "Hapur",
        "Hindupur",
        "Hugli",
        "Hyderabad",
        "Indore",
        "Jaipur",
        "Jaisalmer",
        "Jalandhar",
        "Jalna",
        "Jalpaiguri",
        "Jammu",
        "Jatani",
        "Jhajjar",
        "Jhunjhunun",
        "Jodhpur",
        "Junagadh",
        "Kanakapura",
        "Kandaghat",
        "Karjat",
        "Khandala",
        "Khanna",
        "Khurja",
        "Kichha",
        "Kochi",
        "Kodaikanal",
        "Kolar",
        "Kolkata",
        "Kopargaon",
        "Krishnagiri",
        "Lucknow",
        "Ludhiana",
        "Madikeri",
        "Malkajgiri",
        "Mangalagiri",
        "Mangalore",
        "Medak",
        "Mumbai",
        "Mysore",
        "Nagpur",
        "Nashik",
        "Nelamangala",
        "Palghar",
        "Panchkula",
        "Panvel",
        "Paonta Sahib",
        "Pardi",
        "Ponneri",
        "Port Blair",
        "Pune",
        "Raipur",
        "Rajkot",
        "Rewari",
        "Rohtak",
        "Sanand",
        "Sawai Madhopur",
        "Shamirpet",
        "Solan",
        "Sonipat",
        "Sriperumbudur",
        "Surat",
        "Taoru",
        "Thane",
        "Thiruvananthapuram",
        "Thrissur",
        "Tiruppur",
        "Tumkur",
        "Udaipur",
        "Ujjain",
        "Uran",
        "Vadodara",
        "Varanasi",
        "Vasai",
        "Visakhapatnam"
    ],
    'Indonesia': [
        "Badung",
        "Bandarlampung",
        "Bandung",
        "Batam",
        "Batu",
        "Bekasi",
        "Belitung",
        "Binjai",
        "Bintan",
        "Bogor",
        "Cilegon",
        "Denpasar",
        "Depok",
        "Gianyar",
        "Gresik",
        "Jakarta",
        "Karang Asem",
        "Karawang",
        "Kendari",
        "Kupang",
        "Lampung Tengah",
        "Madiun",
        "Magelang",
        "Makasar",
        "Malang",
        "Manado",
        "Medan",
        "Minahasa",
        "Mojokerto",
        "Palembang",
        "Pekanbaru",
        "Purwakarta",
        "Semarang",
        "Serang",
        "Situbondo",
        "Sleman",
        "Sukabumi",
        "Sukoharjo",
        "Sumbawa",
        "Surabaya",
        "Surakarta",
        "Tabanan",
        "Tangerang",
        "Tangerang Selatan",
        "Temanggung",
        "Yogyakarta"
    ],
    'Japan': [
        "Abashiri-shi",
        "Abiko-shi",
        "Achi-mura",
        "Adachi-ku",
        "Ageo-shi",
        "Aikawa-machi",
        "Aisai-shi",
        "Aisho-cho",
        "Aizubange-machi",
        "Aizuwakamatsu-shi",
        "Akabira-shi",
        "Akaigawa-mura",
        "Akashi-shi",
        "Akiruno-shi",
        "Aki-shi",
        "Akishima-shi",
        "Akita-shi",
        "Amagasaki-shi",
        "Ami-machi",
        "Anan-shi",
        "Ando-cho",
        "Aomori-shi",
        "Arakawa-ku",
        "Aridagawa-cho",
        "Asahikawa-shi",
        "Asahi-mura",
        "Asaka-shi",
        "Asakura-shi",
        "Ashibetsu-shi",
        "Ashikaga-shi",
        "Ashiya-machi",
        "Ashiya-shi",
        "Aso-shi",
        "Atami-shi",
        "Atsugi-shi",
        "Awaji-shi",
        "Awara-shi",
        "Ayase-shi",
        "Bando-shi",
        "Beppu-shi",
        "Bunkyo-ku",
        "Chatan-cho",
        "Chiba-shi Chuo-ku",
        "Chiba-shi Hanamigawa-ku",
        "Chiba-shi Inage-ku",
        "Chiba-shi Midori-ku",
        "Chiba-shi Mihama-ku",
        "Chiba-shi Wakaba-ku",
        "Chichibu-shi",
        "Chigasaki-shi",
        "Chikugo-shi",
        "Chikuma-shi",
        "Chikusei-shi",
        "Chikushino-shi",
        "Chino-shi",
        "Chiryu-shi",
        "Chita-shi",
        "Chitose-shi",
        "Chiyoda-ku",
        "Chiyoda-machi",
        "Chofu-shi",
        "Chonan-machi",
        "Choshi-shi",
        "Chuo-ku",
        "Chuo-shi",
        "Daisen-shi",
        "Daito-shi",
        "Date-shi",
        "Dazaifu-shi",
        "Ebetsu-shi",
        "Ebina-shi",
        "Echizen-cho",
        "Echizen-shi",
        "Edogawa-ku",
        "Ena-shi",
        "Eniwa-shi",
        "Fuchu-shi",
        "Fuefuki-shi",
        "Fujieda-shi",
        "Fujiidera-shi",
        "Fujikawa-cho",
        "Fujikawaguchiko-machi",
        "Fujimino-shi",
        "Fujimi-shi",
        "Fujinomiya-shi",
        "Fujioka-shi",
        "Fujisawa-cho",
        "Fujisawa-shi",
        "Fuji-shi",
        "Fujiyoshida-shi",
        "Fukagawa-shi",
        "Fukaya-shi",
        "Fukuchiyama-shi",
        "Fukui-shi",
        "Fukuoka-shi Chuo-ku",
        "Fukuoka-shi Hakata-ku",
        "Fukuoka-shi Higashi-ku",
        "Fukuoka-shi Jonan-ku",
        "Fukuoka-shi Minami-ku",
        "Fukuoka-shi Nishi-ku",
        "Fukuoka-shi Sawara-ku",
        "Fukuroi-shi",
        "Fukusaki-cho",
        "Fukushima-shi",
        "Fukutsu-shi",
        "Fukuyama-shi",
        "Funabashi-shi",
        "Funahashi-mura",
        "Futtsu-shi",
        "Gero-shi",
        "Gifu-shi",
        "Ginowan-shi",
        "Gobo-shi",
        "Goka-machi",
        "Gose-shi",
        "Goshogawara-shi",
        "Gotemba-shi",
        "Gyoda-shi",
        "Habikino-shi",
        "Hachimantai-shi",
        "Hachinohe-shi",
        "Hachioji-shi",
        "Hadano-shi",
        "Haga-machi",
        "Hakodate-shi",
        "Hakone-machi",
        "Hakuba-mura",
        "Hakusan-shi",
        "Hamamatsu-shi Hamakita-ku",
        "Hamamatsu-shi Higashi-ku",
        "Hamamatsu-shi Kita-ku",
        "Hamamatsu-shi Naka-ku",
        "Hamamatsu-shi Nishi-ku",
        "Hamura-shi",
        "Hanamaki-shi",
        "Handa-shi",
        "Hannan-shi",
        "Hanno-shi",
        "Hanyu-shi",
        "Harima-cho",
        "Hashima-shi",
        "Hashimoto-shi",
        "Hasuda-shi",
        "Hatoyama-machi",
        "Hatsukaichi-shi",
        "Hayama-machi",
        "Hayashima-cho",
        "Hazu-cho",
        "Hekinan-shi",
        "Hidaka-shi",
        "Higashihiroshima-shi",
        "Higashikagawa-shi",
        "Higashikagura-cho",
        "Higashikurume-shi",
        "Higashimatsushima-shi",
        "Higashimatsuyama-shi",
        "Higashimurayama-shi",
        "Higashine-shi",
        "Higashiosaka-shi",
        "Higashiura-cho",
        "Higashiyamato-shi",
        "Hiji-machi",
        "Hikari-shi",
        "Hikawa-cho",
        "Hikone-shi",
        "Himeji-shi",
        "Himi-shi",
        "Hino-shi",
        "Hirakata-shi",
        "Hirakawa-shi",
        "Hirao-cho",
        "Hiratsuka-shi",
        "Hirokawa-machi",
        "Hirosaki-shi",
        "Hiroshima-shi Aki-ku",
        "Hiroshima-shi Asakita-ku",
        "Hiroshima-shi Asaminami-ku",
        "Hiroshima-shi Higashi-ku",
        "Hiroshima-shi Minami-ku",
        "Hiroshima-shi Naka-ku",
        "Hiroshima-shi Nishi-ku",
        "Hiroshima-shi Saeki-ku",
        "Hisayama-machi",
        "Hitachinaka-shi",
        "Hitachiomiya-shi",
        "Hitachi-shi",
        "Hodatsushimizu-cho",
        "Hofu-shi",
        "Hokuto-shi",
        "Honjo-shi",
        "Hyuga-shi",
        "Ibaraki-machi",
        "Ibaraki-shi",
        "Ibusuki-shi",
        "Ichihara-shi",
        "Ichikawamisato-cho",
        "Ichikawa-shi",
        "Ichinomiya-shi",
        "Ichinoseki-shi",
        "Iga-shi",
        "Iida-shi",
        "Iiyama-shi",
        "Iizuka-shi",
        "Ikeda-shi",
        "Imabari-shi",
        "Imari-shi",
        "Imizu-shi",
        "Inabe-shi",
        "Inagawa-cho",
        "Inagi-shi",
        "Ina-machi",
        "Inami-cho",
        "Ina-shi",
        "Inashiki-shi",
        "Inazawa-shi",
        "Inuyama-shi",
        "Inzai-shi",
        "Iruma-shi",
        "Isahaya-shi",
        "Isehara-shi",
        "Isesaki-shi",
        "Ise-shi",
        "Ishigaki-shi",
        "Ishii-cho",
        "Ishikari-shi",
        "Ishikawa-machi",
        "Ishinomaki-shi",
        "Ishioka-shi",
        "Itabashi-ku",
        "Itakura-machi",
        "Itami-shi",
        "Itoman-shi",
        "Ito-shi",
        "Itoshima-shi",
        "Iwaki-shi",
        "Iwakuni-shi",
        "Iwamizawa-shi",
        "Iwanuma-shi",
        "Iwata-shi",
        "Iyo-shi",
        "Izumiotsu-shi",
        "Izumisano-shi",
        "Izumi-shi",
        "Izunokuni-shi",
        "Izu-shi",
        "Joetsu-shi",
        "Joso-shi",
        "Joyo-shi",
        "Kadoma-shi",
        "Kaga-shi",
        "Kagoshima-shi",
        "Kahoku-shi",
        "Kai-shi",
        "Kaita-cho",
        "Kaizuka-shi",
        "Kakamigahara-shi",
        "Kakegawa-shi",
        "Kakogawa-shi",
        "Kakuda-shi",
        "Kamagaya-shi",
        "Kamaishi-shi",
        "Kamakura-shi",
        "Kama-shi",
        "Kameyama-shi",
        "Kamikawa-cho",
        "Kami-machi",
        "Kaminokawa-machi",
        "Kamisato-machi",
        "Kamisunagawa-cho",
        "Kamisu-shi",
        "Kammaki-cho",
        "Kanazawa-shi",
        "Kanda-machi",
        "Kanegasaki-cho",
        "Kanonji-shi",
        "Kanuma-shi",
        "Kanzaki-shi",
        "Kariya-shi",
        "Karuizawa-machi",
        "Kasama-shi",
        "Kasaoka-shi",
        "Kashihara-shi",
        "Kashima-machi",
        "Kashima-shi",
        "Kashiwara-shi",
        "Kashiwa-shi",
        "Kashiwazaki-shi",
        "Kasugai-shi",
        "Kasuga-shi",
        "Kasukabe-shi",
        "Kasuya-machi",
        "Katagami-shi",
        "Katano-shi",
        "Kato-shi",
        "Katsushika-ku",
        "Katsuyama-shi",
        "Kawaba-mura",
        "Kawachinagano-shi",
        "Kawagoe-shi",
        "Kawaguchi-shi",
        "Kawajima-machi",
        "Kawanishi-shi",
        "Kawasaki-shi Asao-ku",
        "Kawasaki-shi Kawasaki-ku",
        "Kawasaki-shi Miyamae-ku",
        "Kawasaki-shi Nakahara-ku",
        "Kawasaki-shi Saiwai-ku",
        "Kawasaki-shi Takatsu-ku",
        "Kawasaki-shi Tama-ku",
        "Kazo-shi",
        "Kazuno-shi",
        "Kikuchi-shi",
        "Kikugawa-shi",
        "Kikuyo-machi",
        "Kimitsu-shi",
        "Kin-cho",
        "Kinokawa-shi",
        "Kira-cho",
        "Kirishima-shi",
        "Kiryu-shi",
        "Kisarazu-shi",
        "Kishiwada-shi",
        "Kisosaki-cho",
        "Kitagata-cho",
        "Kitahiroshima-shi",
        "Kitaibaraki-shi",
        "Kitakami-shi",
        "Kita-ku",
        "Kitakyushu-shi Kokurakita-ku",
        "Kitakyushu-shi Kokuraminami-ku",
        "Kitakyushu-shi Moji-ku",
        "Kitakyushu-shi Tobata-ku",
        "Kitakyushu-shi Wakamatsu-ku",
        "Kitakyushu-shi Yahatahigashi-ku",
        "Kitakyushu-shi Yahatanishi-ku",
        "Kitami-shi",
        "Kitamoto-shi",
        "Kitanagoya-shi",
        "Kitashiobara-mura",
        "Kitsuki-shi",
        "Kiyama-cho",
        "Kiyosu-shi",
        "Kizugawa-shi",
        "Kobayashi-shi",
        "Kobe-shi Chuo-ku",
        "Kobe-shi Higashinada-ku",
        "Kobe-shi Hyogo-ku",
        "Kobe-shi Kita-ku",
        "Kobe-shi Nada-ku",
        "Kobe-shi Nagata-ku",
        "Kobe-shi Nishi-ku",
        "Kobe-shi Suma-ku",
        "Kobe-shi Tarumi-ku",
        "Kochi-shi",
        "Kodaira-shi",
        "Kofu-shi",
        "Koganei-shi",
        "Koga-shi",
        "Koka-shi",
        "Kokonoe-machi",
        "Kokubunji-shi",
        "Komae-shi",
        "Komagane-shi",
        "Komaki-shi",
        "Komatsu-shi",
        "Komatsushima-shi",
        "Komoro-shi",
        "Konan-shi",
        "Konosu-shi",
        "Kori-machi",
        "Koriyama-shi",
        "Koryo-cho",
        "Kosai-shi",
        "Koshigaya-shi",
        "Koshi-shi",
        "Koto-ku",
        "Kudamatsu-shi",
        "Kuji-shi",
        "Kuki-shi",
        "Kumagaya-shi",
        "Kumamoto-shi",
        "Kumiyama-cho",
        "Kunigami-son",
        "Kunitachi-shi",
        "Kurashiki-shi",
        "Kurate-machi",
        "Kure-shi",
        "Kurihara-shi",
        "Kuriyama-cho",
        "Kurobe-shi",
        "Kurume-shi",
        "Kusatsu-shi",
        "Kushimoto-cho",
        "Kushiro-cho",
        "Kushiro-shi",
        "Kusu-machi",
        "Kutchan-cho",
        "Kuwana-shi",
        "Kyonan-machi",
        "Kyotanabe-shi",
        "Kyoto-shi Fushimi-ku",
        "Kyoto-shi Higashiyama-ku",
        "Kyoto-shi Kamigyo-ku",
        "Kyoto-shi Kita-ku",
        "Kyoto-shi Minami-ku",
        "Kyoto-shi Nakagyo-ku",
        "Kyoto-shi Nishikyo-ku",
        "Kyoto-shi Sakyo-ku",
        "Kyoto-shi Shimogyo-ku",
        "Kyoto-shi Ukyo-ku",
        "Kyoto-shi Yamashina-ku",
        "Machida-shi",
        "Maebashi-shi",
        "Maibara-shi",
        "Maizuru-shi",
        "Makinohara-shi",
        "Makubetsu-cho",
        "Maniwa-shi",
        "Marugame-shi",
        "Masaki-cho",
        "Mashiko-machi",
        "Matsubara-shi",
        "Matsubushi-machi",
        "Matsuda-machi",
        "Matsudo-shi",
        "Matsue-shi",
        "Matsumoto-shi",
        "Matsusaka-shi",
        "Matsushige-cho",
        "Matsushima-machi",
        "Matsuyama-shi",
        "Meguro-ku",
        "Meiwa-cho",
        "Meiwa-machi",
        "Mibu-machi",
        "Mifune-machi",
        "Mihara-shi",
        "Mikasa-shi",
        "Miki-shi",
        "Minamiarupusu-shi",
        "Minamikyushu-shi",
        "Minamisoma-shi",
        "Minano-machi",
        "Minato-ku",
        "Mine-shi",
        "Minoh-shi",
        "Minokamo-shi",
        "Mino-shi",
        "Misato-machi",
        "Misato-shi",
        "Misawa-shi",
        "Mishima-shi",
        "Mitaka-shi",
        "Mito-shi",
        "Mitoyo-shi",
        "Mitsuke-shi",
        "Miura-shi",
        "Miyakojima-shi",
        "Miyakonojo-shi",
        "Miyako-shi",
        "Miyama-shi",
        "Miyawaka-shi",
        "Miyazaki-shi",
        "Miyazu-shi",
        "Miyoshi-machi",
        "Miyoshi-shi",
        "Miyota-machi",
        "Mizuho-machi",
        "Mizumaki-machi",
        "Mizunami-shi",
        "Mobara-shi",
        "Moka-shi",
        "Moriguchi-shi",
        "Morioka-shi",
        "Moriyama-shi",
        "Moriya-shi",
        "Moroyama-machi",
        "Motobu-cho",
        "Motomiya-shi",
        "Motosu-shi",
        "Muko-shi",
        "Munakata-shi",
        "Murakami-shi",
        "Murata-machi",
        "Muroran-shi",
        "Musashimurayama-shi",
        "Musashino-shi",
        "Myoko-shi",
        "Nabari-shi",
        "Nachikatsuura-cho",
        "Nagahama-shi",
        "Nagai-shi",
        "Nagaizumi-cho",
        "Nagakute-cho",
        "Nagano-shi",
        "Nagaokakyo-shi",
        "Nagaoka-shi",
        "Nagareyama-shi",
        "Nagasaki-shi",
        "Nagato-shi",
        "Nago-shi",
        "Nagoya-shi Atsuta-ku",
        "Nagoya-shi Chikusa-ku",
        "Nagoya-shi Higashi-ku",
        "Nagoya-shi Kita-ku",
        "Nagoya-shi Meito-ku",
        "Nagoya-shi Midori-ku",
        "Nagoya-shi Minami-ku",
        "Nagoya-shi Minato-ku",
        "Nagoya-shi Mizuho-ku",
        "Nagoya-shi Moriyama-ku",
        "Nagoya-shi Nakagawa-ku",
        "Nagoya-shi Naka-ku",
        "Nagoya-shi Nakamura-ku",
        "Nagoya-shi Nishi-ku",
        "Nagoya-shi Showa-ku",
        "Nagoya-shi Tempaku-ku",
        "Naha-shi",
        "Nakagawa-machi",
        "Nakai-machi",
        "Nakano-ku",
        "Nakanoto-machi",
        "Naka-shi",
        "Nakatsugawa-shi",
        "Namegawa-machi",
        "Namie-machi",
        "Nanjo-shi",
        "Nanto-shi",
        "Nanyo-shi",
        "Nara-shi",
        "Narashino-shi",
        "Narita-shi",
        "Nasukarasuyama-shi",
        "Nasu-machi",
        "Nasushiobara-shi",
        "Natori-shi",
        "Nayoro-shi",
        "Nemuro-shi",
        "Nerima-ku",
        "Neyagawa-shi",
        "Nihonmatsu-shi",
        "Niigata-shi Chuo-ku",
        "Niigata-shi Higashi-ku",
        "Niigata-shi Nishi-ku",
        "Niihama-shi",
        "Niiza-shi",
        "Nikaho-shi",
        "Nikko-shi",
        "Ninohe-shi",
        "Ninomiya-machi",
        "Niseko-cho",
        "Nishigo-mura",
        "Nishihara-cho",
        "Nishinomiya-shi",
        "Nishio-shi",
        "Nishitokyo-shi",
        "Nishiwaki-shi",
        "Noboribetsu-shi",
        "Noda-shi",
        "Nogata-shi",
        "Nogi-machi",
        "Noheji-machi",
        "Nomi-shi",
        "Nonoichi-machi",
        "Noshiro-shi",
        "Noto-cho",
        "Numazu-shi",
        "Nyuzen-machi",
        "Obama-shi",
        "Obihiro-shi",
        "Obu-shi",
        "Odate-shi",
        "Odawara-shi",
        "Ofunato-shi",
        "Ogaki-shi",
        "Oga-shi",
        "Ogawara-machi",
        "Ogi-shi",
        "Ogori-shi",
        "Oguchi-cho",
        "Ohtawara-shi",
        "Oi-machi",
        "Oirase-cho",
        "Oiso-machi",
        "Oita-shi",
        "Oizumi-machi",
        "Ojiya-shi",
        "Okayama-shi Higashi-ku",
        "Okayama-shi Kita-ku",
        "Okayama-shi Naka-ku",
        "Okaya-shi",
        "Okazaki-shi",
        "Okegawa-shi",
        "Oki-machi",
        "Okinawa-shi",
        "Okuma-machi",
        "Omachi-shi",
        "Ome-shi",
        "Omihachiman-shi",
        "Omitama-shi",
        "Omura-shi",
        "Omuta-shi",
        "Onna-son",
        "Onojo-shi",
        "Onomichi-shi",
        "Ono-shi",
        "Ora-machi",
        "Osakasayama-shi",
        "Osaka-shi Abeno-ku",
        "Osaka-shi Asahi-ku",
        "Osaka-shi Chuo-ku",
        "Osaka-shi Fukushima-ku",
        "Osaka-shi Higashinari-ku",
        "Osaka-shi Higashisumiyoshi-ku",
        "Osaka-shi Higashiyodogawa-ku",
        "Osaka-shi Hirano-ku",
        "Osaka-shi Ikuno-ku",
        "Osaka-shi Joto-ku",
        "Osaka-shi Kita-ku",
        "Osaka-shi Konohana-ku",
        "Osaka-shi Minato-ku",
        "Osaka-shi Miyakojima-ku",
        "Osaka-shi Naniwa-ku",
        "Osaka-shi Nishi-ku",
        "Osaka-shi Nishinari-ku",
        "Osaka-shi Nishiyodogawa-ku",
        "Osaka-shi Suminoe-ku",
        "Osaka-shi Sumiyoshi-ku",
        "Osaka-shi Taisho-ku",
        "Osaka-shi Tennoji-ku",
        "Osaka-shi Tsurumi-ku",
        "Osaka-shi Yodogawa-ku",
        "Osaki-shi",
        "Osato-cho",
        "Oshu-shi",
        "Ota-ku",
        "Otaru-shi",
        "Ota-shi",
        "Otofuke-cho",
        "Otsuki-shi",
        "Otsu-shi",
        "Owariasahi-shi",
        "Oyabe-shi",
        "Oyama-cho",
        "Oyama-shi",
        "Oyodo-cho",
        "Ozu-machi",
        "Ozu-shi",
        "Ranzan-machi",
        "Rifu-cho",
        "Rikuzentakata-shi",
        "Rishiri-cho",
        "Rumoi-shi",
        "Ryugasaki-shi",
        "Ryuo-cho",
        "Sabae-shi",
        "Sagae-shi",
        "Sagamihara-shi Chuo-ku",
        "Sagamihara-shi Midori-ku",
        "Sagamihara-shi Minami-ku",
        "Saga-shi",
        "Saijo-shi",
        "Saitama-shi Chuo-ku",
        "Saitama-shi Iwatsuki-ku",
        "Saitama-shi Kita-ku",
        "Saitama-shi Midori-ku",
        "Saitama-shi Minami-ku",
        "Saitama-shi Minuma-ku",
        "Saitama-shi Nishi-ku",
        "Saitama-shi Omiya-ku",
        "Saitama-shi Sakura-ku",
        "Saitama-shi Urawa-ku",
        "Saka-cho",
        "Sakado-shi",
        "Sakaide-shi",
        "Sakai-machi",
        "Sakai-shi",
        "Sakai-shi Higashi-ku",
        "Sakai-shi Kita-ku",
        "Sakai-shi Mihara-ku",
        "Sakai-shi Minami-ku",
        "Sakai-shi Naka-ku",
        "Sakai-shi Nishi-ku",
        "Sakai-shi Sakai-ku",
        "Sakaki-machi",
        "Sakata-shi",
        "Sakuragawa-shi",
        "Sakurai-shi",
        "Sakura-shi",
        "Saku-shi",
        "Samukawa-machi",
        "Sanda-shi",
        "Sango-cho",
        "Sanjo-shi",
        "Sannohe-machi",
        "Sano-shi",
        "Sanuki-shi",
        "Sanyoonoda-shi",
        "Sapporo-shi Atsubetsu-ku",
        "Sapporo-shi Chuo-ku",
        "Sapporo-shi Higashi-ku",
        "Sapporo-shi Kita-ku",
        "Sapporo-shi Kiyota-ku",
        "Sapporo-shi Minami-ku",
        "Sapporo-shi Nishi-ku",
        "Sapporo-shi Shiroishi-ku",
        "Sapporo-shi Teine-ku",
        "Sapporo-shi Toyohira-ku",
        "Sasebo-shi",
        "Satsumasendai-shi",
        "Satte-shi",
        "Sayama-shi",
        "Saza-cho",
        "Seika-cho",
        "Seiro-machi",
        "Seki-shi",
        "Semboku-shi",
        "Sendai-shi Aoba-ku",
        "Sendai-shi Izumi-ku",
        "Sendai-shi Miyagino-ku",
        "Sendai-shi Taihaku-ku",
        "Sendai-shi Wakabayashi-ku",
        "Sera-cho",
        "Setagaya-ku",
        "Seto-shi",
        "Settsu-shi",
        "Shibata-machi",
        "Shibata-shi",
        "Shibayama-machi",
        "Shibukawa-shi",
        "Shibuya-ku",
        "Shichinohe-machi",
        "Shika-machi",
        "Shiki-shi",
        "Shimada-shi",
        "Shimamoto-cho",
        "Shimanto-shi",
        "Shima-shi",
        "Shime-machi",
        "Shimonoseki-shi",
        "Shimotsuke-shi",
        "Shimotsuma-shi",
        "Shimukappu-mura",
        "Shinagawa-ku",
        "Shinano-machi",
        "Shingu-machi",
        "Shingu-shi",
        "Shinhidaka-cho",
        "Shinjuku-ku",
        "Shinonsen-cho",
        "Shiojiri-shi",
        "Shirahama-cho",
        "Shirakawa-shi",
        "Shiraoka-machi",
        "Shiroi-shi",
        "Shiroishi-shi",
        "Shiwa-cho",
        "Shizuoka-shi Aoi-ku",
        "Shizuoka-shi Shimizu-ku",
        "Shizuoka-shi Suruga-ku",
        "Shunan-shi",
        "Sobetsu-cho",
        "Sodegaura-shi",
        "Soja-shi",
        "Soka-shi",
        "Soma-shi",
        "Sosa-shi",
        "Sue-machi",
        "Suginami-ku",
        "Sugito-machi",
        "Suita-shi",
        "Sukagawa-shi",
        "Sumida-ku",
        "Sunagawa-shi",
        "Susaki-shi",
        "Susami-cho",
        "Susono-shi",
        "Suwa-shi",
        "Suzaka-shi",
        "Suzuka-shi",
        "Tabuse-cho",
        "Tachikawa-shi",
        "Tagajo-shi",
        "Tagawa-shi",
        "Taito-ku",
        "Taiwa-cho",
        "Tajimi-shi",
        "Tajiri-cho",
        "Takahagi-shi",
        "Takahama-shi",
        "Takaishi-shi",
        "Takamatsu-shi",
        "Takaoka-shi",
        "Takarazuka-shi",
        "Takasago-shi",
        "Takasaki-shi",
        "Takatsuki-shi",
        "Takayama-shi",
        "Takehara-shi",
        "Taketomi-cho",
        "Takikawa-shi",
        "Takizawa-mura",
        "Taku-shi",
        "Tama",
        "Tamura-shi",
        "Tanabe-shi",
        "Tarui-cho",
        "Tatebayashi-shi",
        "Tateyama-machi",
        "Tateyama-shi",
        "Tatsuno-shi",
        "Tendo-shi",
        "Teshikaga-cho",
        "Toba-shi",
        "Tochigi-shi",
        "Toda-shi",
        "Togitsu-cho",
        "Togo-cho",
        "Tohoku-machi",
        "Tokai-mura",
        "Tokai-shi",
        "Tokigawa-machi",
        "Toki-shi",
        "Tokoname-shi",
        "Tokorozawa-shi",
        "Tokushima-shi",
        "Tomakomai-shi",
        "Tomigusuku-shi",
        "Tomioka-machi",
        "Tomisato-shi",
        "Tomi-shi",
        "Tomiya-machi",
        "Tonami-shi",
        "Tono-shi",
        "Toride-shi",
        "Toshima-ku",
        "Tosu-shi",
        "Tottori-shi",
        "Toyako-cho",
        "Toyama-shi",
        "Toyoake-shi",
        "Toyohashi-shi",
        "Toyokawa-shi",
        "Toyonaka-shi",
        "Toyooka-shi",
        "Toyota-shi",
        "Toyoyama-cho",
        "Tsubame-shi",
        "Tsubata-machi",
        "Tsuchiura-shi",
        "Tsukubamirai-shi",
        "Tsukuba-shi",
        "Tsukumi-shi",
        "Tsuruga-shi",
        "Tsurugashima-shi",
        "Tsuruoka-shi",
        "Tsuru-shi",
        "Tsu-shi",
        "Ube-shi",
        "Ueda-shi",
        "Ugo-machi",
        "Uji-shi",
        "Ukiha-shi",
        "Uki-shi",
        "Umi-machi",
        "Unzen-shi",
        "Uonuma-shi",
        "Uozu-shi",
        "Urasoe-shi",
        "Urayasu-shi",
        "Uruma-shi",
        "Uto-shi",
        "Utsunomiya-shi",
        "Uwajima-shi",
        "Wakayama-shi",
        "Wakkanai-shi",
        "Wako-shi",
        "Warabi-shi",
        "Yabuki-machi",
        "Yachimata-shi",
        "Yachiyo-shi",
        "Yahaba-cho",
        "Yaita-shi",
        "Yakumo-cho",
        "Yamagata-shi",
        "Yamaguchi-shi",
        "Yamanouchi-machi",
        "Yamatokoriyama-shi",
        "Yamato-shi",
        "Yamatotakada-shi",
        "Yame-shi",
        "Yanagawa-shi",
        "Yanai-shi",
        "Yao-shi",
        "Yashio-shi",
        "Yasu-shi",
        "Yatomi-shi",
        "Yatsushiro-shi",
        "Yawatahama-shi",
        "Yokkaichi-shi",
        "Yokohama-shi Aoba-ku",
        "Yokohama-shi Asahi-ku",
        "Yokohama-shi Hodogaya-ku",
        "Yokohama-shi Isogo-ku",
        "Yokohama-shi Izumi-ku",
        "Yokohama-shi Kanagawa-ku",
        "Yokohama-shi Kanazawa-ku",
        "Yokohama-shi Kohoku-ku",
        "Yokohama-shi Konan-ku",
        "Yokohama-shi Midori-ku",
        "Yokohama-shi Minami-ku",
        "Yokohama-shi Naka-ku",
        "Yokohama-shi Nishi-ku",
        "Yokohama-shi Sakae-ku",
        "Yokohama-shi Seya-ku",
        "Yokohama-shi Totsuka-ku",
        "Yokohama-shi Tsurumi-ku",
        "Yokohama-shi Tsuzuki-ku",
        "Yokosuka-shi",
        "Yokote-shi",
        "Yomitan-son",
        "Yonago-shi",
        "Yonezawa-shi",
        "Yorii-machi",
        "Yoro-cho",
        "Yoshikawa-shi",
        "Yoshimi-machi",
        "Yoshinogawa-shi",
        "Yotsukaido-shi",
        "Yuasa-cho",
        "Yubari-shi",
        "Yufu-shi",
        "Yugawara-machi",
        "Yuki-shi",
        "Yukuhashi-shi",
        "Yurihonjo-shi",
        "Yuzawa-machi",
        "Yuzawa-shi",
        "Zama-shi",
        "Zao-machi",
        "Zushi-shi"
    ],
    'Kazakhstan': [
        "Aktau",
        "Almaty",
        "Astana",
        "Atyrau"
    ],
    'Kyrgyzstan': ["Bishkek"],
    'Laos': [
        "Louangphrabang",
        "Ventiane"
    ],
    'Macao, SAR, China': [
        "Chongqing",
        "Dongtai",
        "Guangzhou",
        "Jiangmen",
        "Kunming",
        "Lanzhou",
        "Shanghai",
        "Yinchuan",
        "Zengcheng"
    ],
    'Malaysia': [
        "Baling",
        "Bandar Baharu",
        "Barat Daya",
        "Batang Padang",
        "Batu Pahat",
        "Bentong",
        "Bintulu",
        "Cameron Highlands",
        "Chukai",
        "Dalat",
        "Daro",
        "George Town",
        "Gua Musang",
        "Hulu Langat",
        "Hulu Perak",
        "Jerantut",
        "Johor Bahru",
        "Kampar",
        "Kinta",
        "Klang",
        "Kluang",
        "Kota Bharu",
        "Kota Kinabalu",
        "Kota Setar",
        "Kota Tinggi",
        "Kuala Kangsar",
        "Kuala Langat",
        "Kuala Lumpur",
        "Kuala Muda",
        "Kuala Pilah",
        "Kuantan",
        "Kubang Pasu",
        "Kuching",
        "Kulai",
        "Kulim",
        "Labuan",
        "Lahad Datu",
        "Langkawi",
        "Lipis",
        "Manjung",
        "Maran",
        "Melaka Tengah",
        "Miri",
        "Muar",
        "Padang Terap",
        "Papar",
        "Pasir Mas",
        "Pekan",
        "Pendang",
        "Perak Tengah",
        "Perlis",
        "Petaling",
        "Pontian",
        "Port Dickson",
        "Putrajaya",
        "Rembau",
        "Rompin",
        "Sabak Bernam",
        "Samarahan",
        "Sandakan",
        "Sarikei",
        "Segamat",
        "Selangor",
        "Selatan",
        "Sepang",
        "Seremban",
        "Taiping",
        "Tampin",
        "Tanah Merah",
        "Tangkak",
        "Tatau",
        "Tawau",
        "Temerloh",
        "Tengah",
        "Terengganu",
        "Utara",
        "Yan"
    ],
    'Maldives': [],
    'Mongolia': ["Ulaanbaatar"],
    'Myanmar': [
        "Hlegu",
        "Htantabin",
        "Kyauktan",
        "Mandalay",
        "Natogyi",
        "Thanlyin",
        "Yangon"
    ],
    'New Zealand': [
        "Alicetown",
        "Amberley",
        "Aongatete",
        "Arataki",
        "Arthurs Point",
        "Auckland",
        "Avalon",
        "Balgownie",
        "Bethlehem",
        "Bledisloe Park",
        "Blenheim",
        "Bluff Hill",
        "Bronte",
        "Caledonian",
        "Camberley",
        "Caversham",
        "Christchurch",
        "Cooks Gardens",
        "Cromwell",
        "Drury",
        "Elsdon-Takapuwahia",
        "Fairy Springs",
        "Fernhill",
        "Gate Pa",
        "Gisborne",
        "Glen Avon",
        "Glenholme",
        "Gleniti",
        "Gracefield",
        "Grampians",
        "Gravatt",
        "Greenmeadows",
        "Greerton",
        "Gulf Harbour",
        "Hamilton",
        "Hastings",
        "Hawera",
        "Heretaunga-Silverstream",
        "High St-Stuart St",
        "Hingaia",
        "Horotiu",
        "Huapai",
        "Huntly",
        "Hunua",
        "Hutt",
        "Isel Park",
        "Islands-Thames-Coromandel District",
        "Jacks Point",
        "Judea",
        "Kaiapoi",
        "Kaimai",
        "Kairanga",
        "Karamu",
        "Kelvin Grove",
        "Kelvin Heights",
        "Kerikeri",
        "Kingseat",
        "Kingsley-Chatham",
        "Kingston South",
        "Kuirau",
        "Lake Cameron",
        "Lake Hayes",
        "Lansdowne",
        "Leamington",
        "Lehmans",
        "Levin",
        "Mahora",
        "Maidstone",
        "Malvern",
        "Mandeville",
        "Mangakakahi",
        "Mangatawhiri",
        "Marlborough Sounds Terrestrial",
        "Marsden Point-Ruakaka",
        "Marsland Hill",
        "Masterton",
        "Matukituki",
        "Meeanee",
        "Milson",
        "Moanataiari",
        "Moera",
        "Morningside",
        "Mt Maunganui",
        "Naenae",
        "Nelson Airport",
        "Nelson Park",
        "New Plymouth",
        "Ngahinapouri",
        "North Dunedin",
        "Okauia",
        "Omaka",
        "Omanu",
        "Onekawa",
        "Opaheke",
        "Orewa",
        "Orini",
        "Otakaro Park",
        "Outer Wakatipu",
        "Paerata-Cape Hill",
        "Palm Beach",
        "Palm Springs",
        "Palmerston North",
        "Papaeoia",
        "Papakowhai",
        "Papakura",
        "Paraite",
        "Parkside",
        "Petone",
        "Poets Block",
        "Pokeno",
        "Porirua",
        "Prebbleton",
        "Pukekohe",
        "Pyes Pa",
        "Queenstown",
        "Rangiora",
        "Raumanga",
        "Raurimu",
        "Red Beach",
        "Riverlands",
        "Riverside",
        "Rolleston",
        "Roslyn",
        "Runciman",
        "Saxton",
        "Silverdale",
        "South Dunedin",
        "Southbrook",
        "Stanmore Bay",
        "Stuart St-Frederick St",
        "Sulphur Point",
        "Taieri",
        "Taita",
        "Takanini",
        "Tauhara",
        "Tauhoa-Puhoi",
        "Taupo",
        "Tauranga",
        "Tauranga South",
        "Te Awamutu",
        "Te Kaha",
        "Te Mata",
        "Te Maunga",
        "Te Rerenga",
        "Te Reti",
        "Tikipunga",
        "Tokoroa",
        "Trafalgar",
        "Trentham",
        "Upper Hutt",
        "Victoria",
        "Vipond",
        "Wade Heads",
        "Waiotira-Springfield",
        "Wairakei-Aratiatia",
        "Waiwhetu",
        "Wakatipu Basin",
        "Wanaka",
        "Warkworth",
        "Washington",
        "Weiti River",
        "Wellington",
        "West End",
        "West Invercargill",
        "West Melton",
        "Whaka",
        "Whakatu",
        "Whangarei",
        "Whau Valley",
        "Woolwich"
    ],
    'Northern Mariana Islands': [
        "Afetnas",
        "Garapan",
        "Ginalangan (Chudan)",
        "Papago",
        "San Jose (Tinian Municipality)",
        "San Roque",
        "Sayan Gigani",
        "Susupe"
    ],
    'Palau': [],
    'Papua New Guinea': ["Kewabi", "Port Moresby"],
    'Philippines': [
        "Angeles City",
        "Bacolod City",
        "Bacolor",
        "Baguio City",
        "Batangas City",
        "Bauan",
        "Cabanatuan City",
        "Cagayan de Oro City",
        "Calamba",
        "Calamba City",
        "Caloocan City",
        "Candon City",
        "Carmona",
        "Cebu City",
        "Danao City",
        "Dasmarinas",
        "Davao City",
        "Dumaguete City",
        "General Santos City",
        "General Trias",
        "Hermosa",
        "Iloilo City",
        "Kalookan City",
        "Lapu-Lapu City",
        "Lapu-Lapu City (Opon)",
        "Las Pinas",
        "Lian",
        "Lipa City",
        "Mabalacat City",
        "Magalang",
        "Makati",
        "Makati City",
        "Malolos City",
        "Mandaluyong",
        "Mandaue City",
        "Manila",
        "Marikina",
        "Masinloc",
        "Muntinlupa",
        "Nasugbu",
        "Panglao",
        "Paranaque",
        "Pasay City",
        "Pasig",
        "Pasig City",
        "Plaridel",
        "Puerto Princesa City",
        "Quezon City",
        "Rosario",
        "San Fernando City",
        "San Jose del Monte",
        "San Jose del Monte City",
        "San Rafael",
        "Santa Rosa",
        "Santa Rosa City",
        "Santo Tomas",
        "Tagaytay City",
        "Taguig",
        "Taguig City",
        "Tagum City",
        "Talisay City",
        "Tanay",
        "Tarlac City"
    ],
    'Samoa': [],
    'Singapore': ["Singapore"],
    'Solomon Islands': [],
    'South Korea': [
        "Andong",
        "Ansan",
        "Anseong",
        "Anyang",
        "Asan",
        "Boeun-gun",
        "Boryeong",
        "Buan-gun",
        "Bucheon",
        "Busan",
        "Buyeo-gun",
        "Changnyeong-gun",
        "Changwon",
        "Cheonan",
        "Cheongju",
        "Cheongwon-gun",
        "Chilgok-gun",
        "Chuncheon",
        "Chungju",
        "Daegu",
        "Daejeon",
        "Damyang-gun",
        "Dangjin",
        "Danyang-gun",
        "Donghae",
        "Eumseong-gun",
        "Ganghwa-gun",
        "Gangneung",
        "Gapyeong-gun",
        "Geoje",
        "Geumsan-gun",
        "Gimcheon",
        "Gimhae",
        "Gimje",
        "Gimpo",
        "Goesan-gun",
        "Gongju",
        "Goseong-gun",
        "Goyang",
        "Gumi",
        "Gunpo",
        "Gunsan",
        "Guri",
        "Gwacheon",
        "Gwangju",
        "Gwangmyeong",
        "Gwangyang",
        "Gyeongju",
        "Gyeongsan",
        "Haman-gun",
        "Hampyeong-gun",
        "Hanam",
        "Hongcheon-gun",
        "Hongseong-gun",
        "Hwaseong",
        "Icheon",
        "Iksan",
        "Incheon",
        "Jangseong-gun",
        "Jeju",
        "Jeongeup",
        "Jeongseon-gun",
        "Jeonju",
        "Jeungpyeong-gun",
        "Jincheon-gun",
        "Jinju",
        "Masan",
        "Miryang",
        "Mokpo",
        "Muan-gun",
        "Muju-gun",
        "Mungyeong",
        "Naju",
        "Namyangju",
        "Nonsan",
        "Okcheon-gun",
        "Osan",
        "Paju",
        "Pocheon",
        "Pohang",
        "Pyeongtaek",
        "Sacheon",
        "Samcheok",
        "Sangju",
        "Sejong",
        "Seogwipo",
        "Seongnam",
        "Seosan",
        "Seoul",
        "Siheung",
        "Sokcho",
        "Suncheon",
        "Suwon",
        "Taean-gun",
        "Taebaek",
        "Tongyeong",
        "Uijeongbu",
        "Uiryeong-gun",
        "Uiwang",
        "Ulsan",
        "Wanju-gun",
        "Wonju",
        "Yangju",
        "Yangpyeong-gun",
        "Yangsan",
        "Yangyang-gun",
        "Yeoju-gun",
        "Yeongam-gun",
        "Yeongcheon",
        "Yeonggwang-gun",
        "Yeonsu-gu",
        "Yeosu",
        "Yesan-gun",
        "Yongin"
    ],
    'Sri Lanka': [
        "Balapitiya",
        "Benthota",
        "Beruwala",
        "Colombo",
        "Dambulla",
        "Dehiwala-Mount Lavinia",
        "Dikwella",
        "Ekala",
        "Galle",
        "Kalutara",
        "Katunayake",
        "Matale",
        "Moratuwa",
        "Pasikuda",
        "Seethawaka",
        "Sri Jayawardanapura Kotte",
        "Tangalla",
        "Tissamaharama",
        "Unawatuna",
        "Weligama",
        "Wennappuwa"
    ],
    'Taiwan': [
        "Changhua",
        "Chiayi City",
        "Chiayi County",
        "Douliu",
        "Hsinchu City",
        "Hsinchu County",
        "Hualien",
        "Kaohsiung",
        "Keelung",
        "Magong",
        "Miaoli",
        "Nantou",
        "New Taipei City",
        "Pingtung",
        "Puzi",
        "Taibao",
        "Taichung",
        "Tainan",
        "Taipei",
        "Taitung",
        "Taoyuan",
        "Toufen",
        "Yilan",
        "Yuanlin",
        "Yunlin",
        "Zhubei"
    ],
    'Thailand': [
        "Aranyaprathet",
        "Ban Bueng",
        "Ban Chang",
        "Ban Khai",
        "Bang Bo",
        "Bang Bua Thong",
        "Bang Kruai",
        "Bang Lamung",
        "Bang Nam Priao",
        "Bang Pa-in",
        "Bang Pakong",
        "Bang Phli",
        "Bang Sao Thong",
        "Bangkok",
        "Bueng Sam Phan",
        "Cha-am",
        "Hang Dong",
        "Hat Yai",
        "Hua Hin",
        "Kabin Buri",
        "Kaeng Krachan",
        "Kamphaeng Saen",
        "Kathu",
        "Khlong Luang",
        "Klaeng",
        "Ko Lanta",
        "Ko Samui",
        "Ko Yao",
        "Lam Luk Ka",
        "Lat Lum Kaeo",
        "Mae Sot",
        "Mueang Chachoengsao",
        "Mueang Chiang Mai",
        "Mueang Chiang Rai",
        "Mueang Chon Buri",
        "Mueang Kamphaeng Phet",
        "Mueang Khon Kaen",
        "Mueang Krabi",
        "Mueang Nakhon Nayok",
        "Mueang Nakhon Phanom",
        "Mueang Nakhon Ratchasima",
        "Mueang Nonthaburi",
        "Mueang Pathum Thani",
        "Mueang Phuket",
        "Mueang Rayong",
        "Mueang Samut Prakan",
        "Mueang Samut Sakhon",
        "Mueang Songkhla",
        "Mueang Surat Thani",
        "Mueang Trang",
        "Mueang Udon Thani",
        "Nakhon Luang",
        "Nong Khae",
        "Nong Yai",
        "Nuea Khlong",
        "Pak Chong",
        "Pak Kret",
        "Phan Thong",
        "Phatthana Nikhom",
        "Phra Pradaeng",
        "Phra Samut Chedi",
        "Phutthamonthon",
        "Plaeng Yao",
        "Pluak Daeng",
        "Pran Buri",
        "Sai Noi",
        "Sam Khok",
        "Sattahip",
        "Si Maha Phot",
        "Si Racha",
        "Singhanakhon",
        "Takua Pa",
        "Takua Thung",
        "Thai Mueang",
        "Thalang",
        "Thanyaburi",
        "Uthai",
        "Wang Noi"
    ],
    'Timor-Leste': ["Dili"],
    'Tonga': [],
    'Uzbekistan': ["Tashkent"],
    'Vanuatu': [],
    'Vietnam': [
        "Bac Giang",
        "Bac Ninh",
        "Ben Cat",
        "Bien Hoa",
        "Binh Xuyen",
        "Buon Ma Thuot",
        "Cam Ranh",
        "Can Duoc",
        "Can Giuoc",
        "Can Tho",
        "Chau Doc",
        "Con Dao",
        "Da Lat",
        "Da Nang",
        "Dau Tieng",
        "Dien Ban",
        "Dong Hoi",
        "Duc Hoa",
        "Duy Tien",
        "Go Dau",
        "Ha Long",
        "Haiphong",
        "Ham Thuan Nam",
        "Hanoi",
        "Ho Chi Minh City",
        "Hoi An",
        "Hue",
        "Long Thanh",
        "Long Xuyen",
        "Mang Yang",
        "Nam Sach",
        "Nha Trang",
        "Nhon Trach",
        "Nui Thanh",
        "Phan Thiet",
        "Phu Quoc",
        "Quang Ngai",
        "Que Vo",
        "Qui Nhon",
        "Sa Pa",
        "Soc Son",
        "Tan Thanh",
        "Tan Uyen",
        "Thai Thuy",
        "Thong Nhat",
        "Thu Dau Mot",
        "Thuan An",
        "Thuy Nguyen",
        "Trang Bang",
        "Tu Son",
        "Viet Yen",
        "Vinh",
        "Vung Tau",
        "Xuyen Moc",
        "Yen Hung",
        "Yen Phong"
    ]
}