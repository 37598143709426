import { FunctionComponent } from 'react';
import { useMemo } from 'react';
import StackingFloor from './StackingFloor';
import StackingLegend from './StackingLegend';
import StackingCell from './StackingCell';
import { useTranslation } from 'react-i18next';
import styles from './StackingPlanChart.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Empty } from 'antd';

interface Props {
    data: any;
    className: string;
}

const LIGHT_COLOR: string = '#EFEFEF';
const DARK_COLOR: string = '#1C4050';

const StackingPlanChart: FunctionComponent<Props> = ({ data, className }) => {
    const { t } = useTranslation();

    const colorByCategories = useMemo(() => {
        let categoryColors: Array<StackingCell> = [new StackingCell('#C6CBD4', LIGHT_COLOR)]; //MGTM
        categoryColors.push(new StackingCell('#B6DAD5', DARK_COLOR)); //VACANT
        categoryColors.push(new StackingCell('#C6CBD4', LIGHT_COLOR)); //2018
        categoryColors.push(new StackingCell('#A1ABBD', LIGHT_COLOR));
        categoryColors.push(new StackingCell('#4E5665', LIGHT_COLOR));
        categoryColors.push(new StackingCell('#789F9F', LIGHT_COLOR));
        categoryColors.push(new StackingCell('#0F242F', LIGHT_COLOR));
        categoryColors.push(new StackingCell('#1C4050', LIGHT_COLOR));
        categoryColors.push(new StackingCell('#4F6467', LIGHT_COLOR)); //2024+

        return categoryColors;
    }, [data?.categories]);

    const renderChart = () => {
        return (
            <div className={`${styles.root} ${className}`}>
                <div className={styles.floors}>
                    {data.floors.map((floor, index) => (
                        <StackingFloor
                            data={floor}
                            key={`floor-${index}`}
                            className={styles.floor}
                            colors={colorByCategories}
                            categories={data.categories}
                            currency={data.currency}
                            unitOfMeasure={data.unitOfMeasure}
                        />
                    ))}
                    <StackingLegend data={data.categories} colors={colorByCategories} className="" />
                </div>
            </div>
        );
    };

    return (
        <div className="stacking-plan__wrap">
            <div className="stacking-plan-chart">
                {data?.categories && data?.categories.length > 0 ? (
                    renderChart()
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        </div>
    );
};

export default StackingPlanChart;
