import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import data from "./data/leasingComparables";
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { checkHeight } from '../../../../utils/checkHeight';

interface Props {
}

const LeasingComparablesTable: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']);
    const [pageSize, setPageSize] = useState<number>(50);

    const tableLayout: GridColumns = [
        {
            field: 'transactionDate',
            headerName: 'TRANSACTION DATE',
            flex: 1,
        },
        {
            field: 'property',
            headerName: 'PROPERTY',
            width: 150
        },
        {
            field: 'tenantType',
            headerName: 'TENANT TYPE',
            flex: 1,
        },
        {
            field: 'size',
            headerName: 'APPROX. SIZE (SQ FT)',
            flex: 1,
        },
        {
            field: 'grossEffectiveRent',
            headerName: 'GROSS EFFECTIVE RENT (S$ PSF PM)',
            flex: 1,
        },
        {
            field: 'leaseTerm',
            headerName: 'LEASE TERM (YEARS)',
            flex: 1,
        },
        {
            field: 'transactionType',
            headerName: 'TRANSACTION TYPE',
            flex: 1,
        },
    ];

    const onPageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
    }

    return (
        <section className="mt-40">
            <div className="content-text">
                <h1 className="heading">Leasing Comparables</h1>
            </div>
            <div style={{ height: checkHeight(data.properties.slice(0, pageSize)), width: '100%' }} className="mb-100">
                <DataGrid rows={data.properties} columns={tableLayout} pageSize={pageSize} onPageSizeChange={onPageSizeChange} disableSelectionOnClick />
            </div>
        </section>

    );
};

export default LeasingComparablesTable;
