import * as Yup from 'yup';

export const AddPropertyShema = Yup.object().shape({
    propertyName: Yup.string().min(1, 'Too Short!').max(255, 'Too Long!').required('f:validation.required'),
    yearOfCompletion: Yup.number().typeError('f:validation.number'),
    landTenureId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer'),
    currencyId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer')
        .required('f:validation.required'),
    saleTypeId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer')
        .required('f:validation.required'),
    unitOfMeasurementId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer')
        .required('f:validation.required'),
    landAreaUnitOfMeasureId: Yup.number().positive('f:validation.positive').integer('f:validation.integer'),
    landArea: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    netLeasableAreaUnitOfMeasureId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer'),
    netLeasableArea: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    grossLeasableAreaUnitOfMeasureId: Yup.number().positive('f:validation.positive').integer('f:validation.integer'),
    grossLeasableArea: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    passingRentUnitOfMeasureId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer'),
    passingRentCurrencyId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer'),
    passingRent: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    netOperatingIncome: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    capitalisationRate: Yup.number().typeError('f:validation.number').positive('f:validation.positive'),
    carparkLots: Yup.number().typeError('f:validation.number'),
    map: Yup.object().shape({
        address: Yup.string().required('f:validation.required'),
        long: Yup.number().typeError('f:validation.number'),
        lat: Yup.number().typeError('f:validation.number'),
        countryId: Yup.number()
        .typeError('f:validation.number')
        .positive('f:validation.positive')
        .integer('f:validation.integer')
        .required('f:validation.required'),
    }),
});
