import { FunctionComponent, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MainLoader from '../../components/shared/MainLoader';
import TransactionDataState from '../../context/state/TransactionDataState';
import RouteParams from '../../interfaces/general/Route';
import TransactionTable from './components/TransactionTable';

const TransactionDetails: FunctionComponent = () => {
    const { id } = useParams<RouteParams>(),
        { transactionData, isLoading,
            fetchTransactionData, setTransactionData } = useContext(TransactionDataState);

    useEffect(() => {
        if (id) {
            fetchTransactionData(id);
        }
        else {
            setTransactionData(null);
        }
    }, [id]);

    if (!transactionData) {
        return null;
    }

    if (isLoading) {
        return (
            <div className="container mb-100">
                <MainLoader type="regular" mb="mb-100" mt="mt-100" />
            </div>
        );
    }

    return (
        <div className="container__data">
            <div className="mt-40 property-tabs__styled">
                <div className="research dashboard-body mx-50">
                    <TransactionTable />
                </div>
            </div>
        </div>
    );
};
export default TransactionDetails;
