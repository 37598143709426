import { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import building from '../../../assets/images/building.png';
import MainButton from '../../../components/shared/MainButton';
import FeatureState from '../../../context/state/FeatureState';
import LookUpState from '../../../context/state/LookUpState';
import useBoolean from '../../../hooks/use-boolean';
import ExecuteNdaService from '../../../services/ExecuteNdaService';
import ListOpportunityService from '../../../services/ListOpportunityService';
import PropertyService from '../../../services/Property';
import HeroDealTimeline from '../components/partials/HeroDealTimeline';
import ListOpportunityBtn from '../components/partials/ListOpportunityBtn';
import SignNDAModal from '../components/partials/SignNDAModal';
import UnlistOpportunityModal from '../components/partials/UnlistOpportunityModal';
import { isMobile } from 'react-device-detect';

const ViewPropertyHero: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { getContent } = useContext(LookUpState),
        { getFeature, propertySummary, fetchPropertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        history = useHistory(),
        [isExecuteModalOpen, isExecuteModalOpenActions] = useBoolean(),
        [isUnlistOpportunityModalOpen, setIsUnlistOpportunityModalOpen] = useBoolean(),
        [isSignBtnDisabled, setIsSignBtnDisabledActions] = useBoolean();

    const displaySctors = useCallback(() => {
        const sectors: Array<string> = [];

        propertySummary.sectors.forEach((sector) => {
            getContent('Sector').forEach((elem) => {
                if (elem.id === sector) {
                    sectors.push(elem.name);
                }
            });
        });

        return sectors;
    }, [getContent, propertySummary.sectors]);

    const checkForStatus = useCallback(async () => {
        try {
            const status = await ExecuteNdaService.fetchStatusOfNDAPrices(`${propertyAssetId}`);
            status ? setIsSignBtnDisabledActions.setFalse() : setIsSignBtnDisabledActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t, propertyAssetId, setIsSignBtnDisabledActions]);

    const unlistOpportunity = useCallback(async () => {
        try {
            await ListOpportunityService.unlistOpportunity(`${propertyAssetId}`);
            fetchPropertySummary();
            setIsUnlistOpportunityModalOpen.setFalse();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const onUnlistModalClosing = () => {
        setIsUnlistOpportunityModalOpen.setFalse();
    };

    const shareProperty = useCallback(async () => {
        try {
            await PropertyService.shereProperty(`${propertyAssetId}`);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    useEffect(() => {
        if (getFeature('ExecuteNda')) {
            checkForStatus();
        }
    }, [getFeature, checkForStatus]);
    return (
        <div className="property-hero">
            <figure className="image-summary-container">
                <img
                    className="image-summary"
                    src={propertySummary.imageUrl ? propertySummary.imageUrl : building}
                    alt="property"
                />
            </figure>
            <div className="property-info__wrap d-flex align-items-center">
                <div className="property-info property-info--flex">
                    <h2 className="property-info__name property-info__name--mt">{propertySummary.name}</h2>
                    <p className="property-info__address">{propertySummary.address}</p>
                    <div className="row property-info__list property-info__list--no-max-w">
                        <div className="col-xs-12 row property-info__item">
                            <p className={`${isMobile ? 'col-xs-4' : 'col-xs-2'} property-info__element`}>{t('f:property.sectors')}</p>
                            <p className={`${isMobile ? 'col-xs-8' : 'col-xs-10'} property-info__value`}>
                                {displaySctors()
                                    .map((elem) => elem)
                                    .join(', ')}
                            </p>
                        </div>
                        <div className="col-xs-12 row property-info__item">
                            <p className={`${isMobile ? 'col-xs-4' : 'col-xs-2'} property-info__element`}>{t('f:property.saleType')}</p>
                            <p className={`${isMobile ? 'col-xs-8' : 'col-xs-10'} property-info__value`}>{propertySummary.saleType}</p>
                        </div>
                        {propertySummary.isPortfolio ? 
                        (
                            <div className="col-xs-12 row property-info__item">
                                <p className={`${isMobile ? 'col-xs-4' : 'col-xs-2'} property-info__element`}>{t('f:property.noOfAssets')}</p>
                                <p className={`${isMobile ? 'col-xs-8' : 'col-xs-10'} property-info__value`}>{propertySummary.numberOfAssets}</p>
                            </div>
                        ) :
                        (
                            <div className="col-xs-12 row property-info__item">
                                <p className={`${isMobile ? 'col-xs-4' : 'col-xs-2'} property-info__element`}>{t('f:property.noOfBuildings')}</p>
                                <p className={`${isMobile ? 'col-xs-8' : 'col-xs-10'} property-info__value`}>{propertySummary.numberOfBuildings}</p>
                            </div>
                        )}
                        {propertySummary.brokers.length && (
                            <div className="col-xs-12 row property-info__item">
                                <p className={`${isMobile ? 'col-xs-4' : 'col-xs-2'} property-info__element`}>{t('f:property.brokers')}</p>
                                <p className={`${isMobile ? 'col-xs-8' : 'col-xs-10'} property-info__value`}>
                                    <span>{propertySummary.brokers.map((broker) => broker).join(', ')}</span>
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="property-info__buttons d-flex ">
                        <HeroDealTimeline />
                    </div>
                    <div className="row property-info__buttons d-flex gap-20">
                        {/* Temp removed */}
                        {false && (
                            <>
                                {getFeature('ListOportunity') && (
                                    <MainButton
                                        layout="primary"
                                        size="main"
                                        buttonHandler={() => {
                                            history.push(`/property/${externalId}/list-opportunity/privacy`);
                                        }}
                                        buttonText={t('f:button.listOpportunity')}
                                    />
                                )}

                                {getFeature('OpportunityUpdate') && propertySummary.isListed && (
                                    <button
                                        className="button-main button-primary mb-10"
                                        onClick={() => setIsUnlistOpportunityModalOpen.setTrue()}
                                    >
                                        {t(`f:button.unlistOpportunity`)}
                                    </button>
                                )}
                            </>
                        )}

                        {getFeature('ExecuteNda') && (
                            <>
                                <p className="signNDAModal__required">{t('f:property.ndaRequired')}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <SignNDAModal isModalOpened={isExecuteModalOpen} setIsModalOpened={isExecuteModalOpenActions} />
            <UnlistOpportunityModal
                isModalOpened={isUnlistOpportunityModalOpen}
                handleFinish={unlistOpportunity}
                handleClosing={onUnlistModalClosing}
            />
            <ListOpportunityBtn
                isListed={propertySummary.isListed}
                isPublic={propertySummary.isPublic}
                isNdaRequired={propertySummary.isNdaRequired}
            />
        </div>
    );
};
export default ViewPropertyHero;
