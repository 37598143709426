import DataRoom from '../api/routes/DataRoom';

const fetchDataRoom = async (id: string) => {
    const { data } = await DataRoom.fetchDataRoom(id);

    return data;
};

const fetchUsersWithPermissions = async (nodeId: string) => {
    const { data } = await DataRoom.fetchUsersWithPermissions(nodeId);
    return data;
};

const searchDataRoom = async (id: string, keyword: string) => {
    const { data } = await DataRoom.searchDataRoom(id, keyword);
    return data;
};

const addFolderToDataRoom = async (propertyId: string, name: string, parentId: string | null) => {
    const { data } = await DataRoom.addFolderToDataRoom(propertyId, name, parentId);
    return data;
};

const renameFolderInDataRoom = async (id: string, name: string) => {
    const { data } = await DataRoom.renameFolderInDataRoom(id, name);
    return data;
};

const removeFromDataRoom = async (id: string) => {
    const { data } = await DataRoom.removeFromDataRoom(id);
    return data;
};

const uploadFile = async (options: any, inputData: any) => {
    const { data } = await DataRoom.uploadFile(options, inputData);
    return data;
};

const addUserPermission = async (userId: string, nodeId: string, permission: number) => {
    const { data } = await DataRoom.addUserPermission(userId, nodeId, permission);
    return data;
};

const removeUserPermission = async (userId: string, nodeId: string, permission: number) => {
    const { data } = await DataRoom.removeUserPermission(userId, nodeId, permission);
    return data;
};

const removeAccessForFolder = async (userId: string, nodeId: string) => {
    const { data } = await DataRoom.removeAccessForFolder(userId, nodeId);
    return data;
};

const DataRoomService = {
    fetchDataRoom,
    searchDataRoom,
    addFolderToDataRoom,
    renameFolderInDataRoom,
    removeFromDataRoom,
    uploadFile,
    fetchUsersWithPermissions,
    addUserPermission,
    removeUserPermission,
    removeAccessForFolder,
};

export default DataRoomService;
