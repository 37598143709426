import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface PreviewButtonsProps {
    buttonBgColor: string;
    buttonTextColor: string;
    buttonBorderColor: string;
}

const PreviewButtons: FunctionComponent<PreviewButtonsProps> = ({
    buttonBgColor,
    buttonTextColor,
    buttonBorderColor,
}: PreviewButtonsProps) => {
    const { t } = useTranslation(['f', 'b']),
        [mainButtonStyle, setMainButtonStyle] = useState({
            color: buttonTextColor,
            backgroundColor: buttonBgColor,
            borderColor: buttonBorderColor,
        }),
        [smallButtonStyle, setSmallButtonStyle] = useState({
            color: buttonTextColor,
            backgroundColor: buttonBgColor,
            borderColor: buttonBorderColor,
        });

    useEffect(() => {
        setMainButtonStyle({
            color: buttonTextColor,
            backgroundColor: buttonBgColor,
            borderColor: buttonBorderColor,
        });
        setSmallButtonStyle({ color: buttonTextColor, backgroundColor: buttonBgColor, borderColor: buttonBorderColor });
    }, [buttonBgColor, buttonTextColor, buttonBorderColor]);

    return (
        <div className="col-xs-12-clear">
            <div className="col-xs-12 ml-50 mt-20">
                <button
                    style={mainButtonStyle}
                    className="button-primary button-main"
                    onMouseEnter={() => {
                        setMainButtonStyle({
                            color: buttonBgColor,
                            backgroundColor: '#fff',
                            borderColor: buttonBgColor,
                        });
                    }}
                    onMouseLeave={() => {
                        setMainButtonStyle({
                            color: buttonTextColor,
                            backgroundColor: buttonBgColor,
                            borderColor: buttonBorderColor,
                        });
                    }}
                >
                    {t('f:theme-manager.prevButtonMain')}
                </button>
                <button
                    style={smallButtonStyle}
                    className="button-secondary button-small teams-button ml-20"
                    onMouseEnter={() => {
                        setSmallButtonStyle({
                            color: buttonBgColor,
                            backgroundColor: '#fff',
                            borderColor: '#fff',
                        });
                    }}
                    onMouseLeave={() => {
                        setSmallButtonStyle({
                            color: buttonTextColor,
                            backgroundColor: buttonBgColor,
                            borderColor: buttonBorderColor,
                        });
                    }}
                >
                    {t('f:theme-manager.prevButtonSmall')}
                </button>
            </div>
        </div>
    );
};

export default PreviewButtons;
