import { FunctionComponent } from "react";
import NumberFormat from "react-number-format";

interface TransactionTableItemProps {
    title: string;
    value: any;
    type?: 'string' | 'number'
}

const TransactionTableItem: FunctionComponent<TransactionTableItemProps> = ({
    title,
    value,
    type = 'string'
}) => {
    const renderValue = () => {
        if (type === 'string') {
            return value;
        }

        return <NumberFormat
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={0}
            value={value} />
    }
    return value ?
        (
            <li>
                <p>{title}</p><span>{renderValue()}</span>
            </li>
        ) : <></>
}

export default TransactionTableItem;