export interface CompanyDetailsI {
    name: string;
    type: string;
    industry: string;
    speciality: string;
    address: string;
    city: string;
    postalCode: string;
    contactName: string;
    email: string;
    phoneNumber: string;
    registrationNumber: string;
    isActive: boolean;
    isSole: boolean;
    isVerified: boolean;
    countryId: string;
    countryName: string;
    domains: Array<string>;
    ownerId: string;
    ownerEmail: string;
    imageUrl: string;
    externalId: string;
}

export const initCompany = {
    name: '',
    type: '',
    industry: '',
    speciality: '',
    address: '',
    city: '',
    postalCode: '',
    contactName: '',
    email: '',
    phoneNumber: '',
    registrationNumber: '',
    isActive: false,
    isSole: false,
    isVerified: false,
    countryId: '',
    countryName: '',
    domains: [],
    ownerId: '',
    ownerEmail: '',
    imageUrl: '',
    externalId: '',
};

export interface PropertyDetailsI {
    imageUrl: string;
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    address: string;
    department: string;
    position: string;
}

export const initUser: PropertyDetailsI = {
    imageUrl: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    department: '',
    position: '',
};
