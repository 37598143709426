export const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
    }, {});
};

export const groupByName = (array, key, id) => {
    return array.reduce((result, currentValue) => {
        (result[currentValue[key][id]] = result[currentValue[key][id]] || []).push(currentValue);
        return result;
    }, {});
};
// groupByName(allUsers, 'company', 'name')
