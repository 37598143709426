import NewestUser from '../user/NewestUser';
import { Stages } from '../property/Stages';

export interface IMarketingTimeline {
    stageId: string;
    stage: number;
    text: string;
    startDate: string;
    closeDate: string;
}

export interface IBidingStructure {
    ndaType: number;
    hasListOpportunity: boolean;
    startingPrice: number;
    biddingDocumentTypeIds: Array<number>;
    customNdaUrl: string;
    bidTresholdAmount: number;
    deadlineExtensionInDays: number;
}

export interface IUsersApproval {
    user: NewestUser;
    approved: string;
}

export interface IListingType {
    listingTypeStatus: number;
    permissionOnBehlfOfTheSeller: boolean;
}

export const initalListingType = {
    listingTypeStatus: 0,
    permissionOnBehlfOfTheSeller: true,
};

interface LOInvestor {
    id: number;
    name: string;
    type: string;
    industry: string;
    speciality: string;
    address: string;
    city: string;
    postalCode: string;
    contactName: string;
    email: string;
    phoneNumber: string;
    registrationNumber: string;
    isVerified: boolean;
    isActive: boolean;
    isSole: boolean;
    ownerId: string;
}

export interface IOverviewLO {
    listingType: IListingType;
    marketingStatuses: Array<Stages>;
    bidingStructure: IBidingStructure;
    investors: Array<LOInvestor>;
}

export interface CreateListOpportunityRequest {
    investorEmails: Array<string>;
}