import { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InvestorCompany } from '../../../../../../interfaces/investor/CompanyWithInvestor';
import TreeDotsInvestors from '../../partials/TreeDotsInvestors';
import MainTableBodyCell from './MainTableBodyCell';
import { TableTypes } from '../../../../../../constants/wizardSteps';
import MainCheckbox from '../../../../../../components/shared/MainCheckbox';
import useBoolean from '../../../../../../hooks/use-boolean';
import LOWizardState from '../../../../../../context/state/LOWizardState';
import DropdownUserTableInSingleItem from './DropdownUserTableInSingleItem';
import InvestorService from '../../../../../../services/InvestorsService';
import { toast } from 'react-toastify';
import FeatureState from '../../../../../../context/state/FeatureState';

interface SingleItemProps {
    element: InvestorCompany;
    childTableId: number | null;
    setChildTableId: (prev: number | null) => void;
    type: TableTypes.INVESTORS_TAB | TableTypes.LIST_OPPORTUNITY;
    refetch: () => void;
}

const SingleItem: FunctionComponent<SingleItemProps> = ({
    element,
    childTableId,
    setChildTableId,
    type,
    refetch,
}: SingleItemProps) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        { selectedUsersId, setSelectedUsersId, isAllUsersSelected } = useContext(LOWizardState),
        [isCompanyUsersSelected, setIsCompanyUsersSelectedActions] = useBoolean();

    const handleOnClick = useCallback(() => {
        element.id === childTableId ? setChildTableId(null) : setChildTableId(element.id);
    }, [setChildTableId, element.id, childTableId]);
    const removeInvestors = useCallback(async () => {
        // try {
        //     await InvestorService.removeUserFromInvestor(
        //         `${propertyAssetId}`,
        //         element.users.map((el) => el.email)
        //     );
        //     refetch();
        // } catch (e: any) {
        //     toast(t(`b:${e.response.data.errorMessage}`));
        // }
    }, [t, propertyAssetId]);

    const sendEmails = useCallback(async () => {
        // try {
        //     const allEmails = element.users.map((el) => el.email);

        //     await InvestorService.sendEmails(`${propertyAssetId}`, allEmails);
        //     refetch();
        // } catch (e: any) {
        //     toast(t(`b:${e.response.data.errorMessage}`));
        // }
    }, [t, propertyAssetId]);

    const toggleNDA = useCallback(
        async (id) => {
            // try {
            //     await InvestorService.toggleNDAstatusCompany(`${propertyAssetId}`, id);
            //     refetch();
            // } catch (e: any) {
            //     toast(t(`b:${e.response.data.errorMessage}`));
            // }
        },
        [propertyAssetId]
    );

    const arrow = () => {
        return (
            <svg
                style={{ height: '16px', width: '16px', marginRight: '5px' }}
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                onClick={handleOnClick}
                className={`investors-table__arrow ${
                    childTableId === element.id ? 'investors-table__arrow--down' : ''
                }`}
            >
                <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" fill="#e0e0e0"></path>
            </svg>
        );
    };

    useEffect(() => {
        isAllUsersSelected ? setIsCompanyUsersSelectedActions.setTrue() : setIsCompanyUsersSelectedActions.setFalse();
    }, [isAllUsersSelected]);

    return (
        <>
            <div className="d-flex investors-table__row investors-table__row--body">
                <MainTableBodyCell
                    text={
                        <>
                            {type === TableTypes.LIST_OPPORTUNITY && (
                                <MainCheckbox
                                    onClick={() => {
                                        isCompanyUsersSelected
                                            ? (() => {
                                                  let emails = [...selectedUsersId];
                                                //   element.users.forEach((user) => {
                                                //       if (emails.includes(user['email'])) {
                                                //           emails = emails.filter((email) => email !== user['email']);
                                                //       }
                                                //   });

                                                  setSelectedUsersId(emails);

                                                  setIsCompanyUsersSelectedActions.setFalse();
                                              })()
                                            : (() => {
                                                  const newArray = [...selectedUsersId];
                                                //   element.users.forEach((elem) => {
                                                //       if (!selectedUsersId.includes(elem['email'])) {
                                                //           newArray.push(elem['email']);
                                                //       }
                                                //   });
                                                  setSelectedUsersId(newArray);
                                                  setIsCompanyUsersSelectedActions.setTrue();
                                              })();
                                    }}
                                    checked={isCompanyUsersSelected}
                                />
                            )}

                            {arrow()}
                            {element.name}
                        </>
                    }
                />
                {/* <MainTableBodyCell text={element.ndaStatus ? t('f:teams.signed') : t('f:teams.notSigned')} />
                <MainTableBodyCell text={element.bidStatus ? t('f:teams.bid') : t('f:teams.noOffer')} /> */}
                {type === TableTypes.INVESTORS_TAB && (
                    <MainTableBodyCell
                        type="actions"
                        text={
                            <TreeDotsInvestors
                                id={element.id}
                                ndaStatus={0}
                                deleteHandler={removeInvestors}
                                sendEmail={sendEmails}
                                isNDAShown={false}
                                handleSignNda={toggleNDA}
                            />
                        }
                    />
                )}
            </div>
            {childTableId === element.id && (
                <DropdownUserTableInSingleItem id={element.id} type={type} users={[]} refetch={refetch} />
            )}
        </>
    );
};
export default SingleItem;
