import api from '../index';

const createPortfolio = (data: any) => {
    return api.post(`/portfolio`, data);
};

const updatePortfolio = (portfolioId: string, body: any) => {
    return api.put(`/portfolio/${portfolioId}`, body);
};

const fetchAssetsOfPortfolio = (portfolioId: string, searchText: string, currentPage: number, pageSize: number) => {
    return api.get(
        `/portfolio/${portfolioId}?SearchText=${searchText}&PageIdx=${currentPage}&ItemsPerPage=${pageSize}`
    );
};

const fetchAssetsOfPortfolioForTable = (portfolioId: string) => {
    return api.get(`/portfolio/${portfolioId}`);
};

const fetchPortfolioInformation = (portfolioId: string) => {
    return api.get(`/portfolio/${portfolioId}/brief`);
};

const enableSubsetBidding = (portfolioId: string) => {
    return api.put(`/portfolio/${portfolioId}/enablesubsetbidding`);
};

const disableSubsetBidding = (portfolioId: string) => {
    return api.put(`/portfolio/${portfolioId}/disablesubsetbidding`);
};

const PortfolioApi = {
    updatePortfolio,
    enableSubsetBidding,
    disableSubsetBidding,
    createPortfolio,
    fetchAssetsOfPortfolioForTable,
    fetchAssetsOfPortfolio,
    fetchPortfolioInformation,
};
export default PortfolioApi;
