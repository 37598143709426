import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BuildingDirectoryService from '../../services/BuildingDirectoryService';
import { toast } from 'react-toastify';
import BuildingDirectoryInfo from './models/buildingDirectoryInfo';
import lookupIcon from '../../assets/images/lookup-icon.png';
import buildingIcon from '../../assets/images/building-icon.png';
import { useHistory, useParams } from 'react-router-dom';
import RouteParams from '../../interfaces/general/Route';
import SearchMenuPanel from './components/SearchMenuPanel';
import FilterMenuPanel from './components/FilterMenuPanel';
import IPropertyInfo from '../../components/shared/PropertiesMap/iPropertyInfo';
import PropertiesMap from '../../components/shared/PropertiesMap/PropertiesMap';

const MapFullHeight = 'calc(100vh)';

const SearchingMap: FunctionComponent = ({ children }) => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        { id } = useParams<RouteParams>(),
        [selectedMenu, setSelectedMenu] = useState<number>(0),
        [mapHeight, setMapHeight] = useState<string>(MapFullHeight),
        [buildingDirectories, setBuildingDirectories] = useState<Array<BuildingDirectoryInfo>>([]);

    const fetchAllBuildingDirectories = useCallback(async () => {
        try {
            const buildingDirectories = await BuildingDirectoryService.fetchBuildingDirectories();

            setBuildingDirectories(buildingDirectories);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    useEffect(() => {
        fetchAllBuildingDirectories();
    }, []);

    useEffect(() => {
        setMapHeight(id ? 'calc(50vh - 30px)' : MapFullHeight);
    }, [id]);

    const handleBuildingClicked = (property: IPropertyInfo) => {
        history.push(`/building-directory/${property.id}`);
    };

    return (
        <div className="property-hero searching-map">
            <figure>
                <div className="background-cover">
                    <div className='navigation-icon'>
                        <img src={buildingIcon} onClick={() => setSelectedMenu(0)} />
                        <hr />
                        <img src={lookupIcon} onClick={() => setSelectedMenu(1)} />
                    </div>
                    <div className='search-content'>
                        <div className='search-panel'>
                            <div style={{ display: selectedMenu === 0 ? 'block' : 'none' }}>
                                <FilterMenuPanel />
                            </div>
                            <div className='search-menu' style={{ display: selectedMenu === 1 ? 'block' : 'none' }}>
                                <SearchMenuPanel onBuildingClicked={handleBuildingClicked} />
                            </div>
                        </div>
                        <div className="map-panel">
                            <PropertiesMap type={BuildingDirectoryInfo} height={mapHeight} markerId={id} properties={buildingDirectories} onMarkerClicked={handleBuildingClicked} />
                            {children}
                        </div>
                    </div>
                </div>
            </figure>
        </div>
    );
};

export default SearchingMap;
