import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import LookUpState from './state/LookUpState';
import CountryModel from './../api/models/CountryModel';
import PropertyLookup from '../services/PropertyLookup';
import CountryService from '../services/Country';
import LookUpPersistData from '../interfaces/property/LookUpPersistData';
import MainLoader from '../components/shared/MainLoader';

interface Props {
    children: React.ReactNode;
}

const LookUpContext: FunctionComponent<Props> = ({ children }) => {
    const [lookUps, setLookUps] = useState<any>({}),
        [countries, setCountries] = useState<Array<CountryModel>>([]),
        [isLoading, setIsLoading] = useState<boolean>(false);

    const getContent = (name: string) => {
        return lookUps[name];
    };

    const fetchIndividualLookup = useCallback(async (name: string) => {
        return PropertyLookup.getLookup(name);
    }, []);

    const fetchSingle = useCallback(
        async (promises: any, names: Array<string>, countries: Array<CountryModel>) => {
            let obj = lookUps;

            Promise.all(promises).then((values) => {
                values.forEach((value: any, i) => {
                    obj[names[i]] = value;
                });

                setLookUps(obj);
                setIsLoading(true);

                localStorage.setItem(
                    'createLookUps',
                    JSON.stringify({ createDate: new Date(), items: obj, countries: countries })
                );
            });
        },
        [lookUps]
    );

    const fetchLookups = useCallback(async () => {
        const response = await PropertyLookup.getAllLookupTypes();
        const _countries = await CountryService.fetchCountries();

        let promises: any = [];

        response.forEach((elem: string) => {
            promises.push(fetchIndividualLookup(elem));
        });

        fetchSingle(promises, response, _countries);
    }, [fetchIndividualLookup, fetchSingle]);

    const setData = (data: LookUpPersistData) => {
        setLookUps(data.items);
        setCountries(data.countries);
        setIsLoading(true);
    };

    const checkIfLookUpNeed = useCallback(
        (data: LookUpPersistData) => {
            const createDate: number = new Date(data.createDate).getTime();
            const now: number = new Date().getTime();

            const dataAge = Math.round((now - createDate) / (1000 * 24));
            const tooOld = dataAge >= 86400;

            tooOld ? fetchLookups() : setData(data);
        },
        [fetchLookups]
    );

    useEffect(() => {
        const data = localStorage.getItem('createLookUps');
        data ? checkIfLookUpNeed(JSON.parse(data)) : fetchLookups();
    }, []);

    return (
        <>
            {isLoading ? (
                <LookUpState.Provider
                    value={{
                        getContent: getContent,
                        countries: countries,
                    }}
                >
                    {children}
                </LookUpState.Provider>
            ) : (
                <MainLoader type="main" />
            )}
        </>
    );
};

export default LookUpContext;
