import { FunctionComponent } from 'react';
import CapitaSpringAnalysis from '../../../../assets/images/dashboard/CapitaSpringAnalysis.png';
const AssetData: FunctionComponent<any> = (props) => {

    return (
        <section className="module-asset-data  mt-40">
            <div className="content-text">
                <h1 className="heading">CapitaSpring</h1>
                <div className="block-article">
                    <h3>Located in the heart of Singapore’s financial district</h3>
                    <p>
                        Located in the heart of Singapore’s financial district, the 998,000 sqft, tech-integrated and verdurous skyscraper which includes the ‘office of the future’, a serviced residence and retail components, transforms the site of a former car park complex built in the 1980s.
                    <br />
                        <br />
                    Rising to 280m, on par with the tallest buildings in the heart of Singapore’s Central Business District, the integrated development offers premium Grade A office space, a 299-unit Citadines serviced residence to be managed by The Ascott Limited and ancillary retail space. The tower is set to make a distinctive mark on the Singapore skyline: the building’s exterior façade consists of vertical elements that are pulled apart to allow glimpses into the green oases blooming from the base, core and rooftop. A dynamic interplay of orthogonal lines and lush greenery presents itself in the contrasting textures of steel and glass, interweaved with tropical vegetation.

                    </p>
                </div>
            </div>
            <img className="bg" src={CapitaSpringAnalysis}></img>
        </section>
    );
};

export default AssetData;
