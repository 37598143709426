import { FunctionComponent } from 'react';
import BuildingDirectoryInfo from '../models/buildingDirectoryInfo';

interface Props {
    buildingDirectory: BuildingDirectoryInfo;
    onClicked?: (buildingDirectory: BuildingDirectoryInfo) => void;
}

const BuildingDirectoryCard: FunctionComponent<Props> = ({ buildingDirectory, onClicked }) => {
    const joinTwoString = (string1, string2) => {
        if (!string1 && !string2) {
            return '';
        }

        if (string1 && string2) {
            return `${string1}, ${string2}`;
        }

        if (string1) {
            return string1;
        }

        if (string2) {
            return string2;
        }
    }

    return (
        <div className="building-card" onClick={() => onClicked && onClicked(buildingDirectory)}>
            {buildingDirectory.photo && <img className="photo" src={`${process.env.REACT_APP_BUILDING_DIRECTORY_BLOB_URL}/${buildingDirectory.id}/${buildingDirectory.photo}`} />}
            <div className="name">{buildingDirectory.propertyName}</div>
            <div className="address">{buildingDirectory.propertyAddress}</div>
            <hr />
            <div>
                <span className="title">Year Of Completion</span>
                <span className="value">{buildingDirectory.yearBuilt}</span>
            </div>
            <div>
                <span className="title">Land Tenure</span>
                <span className="value">{buildingDirectory.yearBuilt}</span>
            </div>
            <div>
                <span className="title">Site Area (sqft)</span>
                <span className="value">{buildingDirectory.landArea}</span>
            </div>
            <div>
                <span className="title">NLA (sqft)</span>
                <span className="value">{buildingDirectory.nla}</span>
            </div>
            <div>
                <span className="title">Carpark Lots</span>
                <span className="value">{buildingDirectory.parkingPlaces}</span>
            </div>
            <div>
                <span className="title">Selector</span>
                <span className="value">{joinTwoString(buildingDirectory.primarySector, buildingDirectory.secondarySector)}</span>
            </div>
            <div>
                <span className="title">Major Tenants</span>
                <span className="value">{buildingDirectory.tenancy}</span>
            </div>
        </div>
    );
};

export default BuildingDirectoryCard;
