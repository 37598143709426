import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import { DataRoomItemSchema } from '../../../../validations/dataRoom/DataRoomItem';
import DataRoomService from '../../../../services/DataRoom';
import ModalCloseButton from '../../../../components/shared/ModalCloseButton';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';

interface Props {
    isModalOpened: boolean;
    setIsModalOpened: (boolean: boolean) => void;
    onSaved: (id: string, name: string) => void;
    id: string | undefined | null;
    initialName: string | undefined | null;
    propertyId: string;
    parentId: string | null;
    title: string;
}

const AddEditItemModal: FunctionComponent<Props> = ({
    isModalOpened,
    setIsModalOpened,
    onSaved,
    id,
    initialName,
    propertyId,
    parentId,
    title,
}) => {
    const { t } = useTranslation(['f', 'b']);

    const onCancel = useCallback(() => {
        setIsModalOpened(false);
    }, [setIsModalOpened]);

    useEffect(() => {
        if (isModalOpened) {
        }
    }, [isModalOpened]);

    const closeModalHandler = (setFieldValue: any, resetForm: any) => {
        setFieldValue('name', '');
        setIsModalOpened(false);
        resetForm();
    };

    const hasErrors = (errors: any, name: string, touched: any) => {
        if (errors && errors[name] && touched[name]) {
            return <span className="error-text">{t(`${errors[name]}`, { name: t(`f:dataRoom.${name}`) })}</span>;
        }
    };

    return (
        <ModalWrapper
            className="confirm-delete"
            isModalOpened={isModalOpened}
            isCloseModalShown={false}
            isFooterShown={false}
            closeModalHandler={onCancel}
            title={title}
        >
            <Formik
                initialValues={{
                    name: initialName,
                }}
                validationSchema={DataRoomItemSchema}
                enableReinitialize
                onSubmit={async (values: any, { setFieldValue, resetForm }) => {
                    if (!id) {
                        const data = await DataRoomService.addFolderToDataRoom(propertyId, values.name, parentId);
                        onSaved(data.id, values.name);
                    } else {
                        await DataRoomService.renameFolderInDataRoom(id, values.name);
                        onSaved(id, values.name);
                    }

                    closeModalHandler(setFieldValue, resetForm);
                }}
            >
                {({ setFieldValue, resetForm, errors, touched, validateForm, values, submitForm }) => (
                    <Form>
                        <ModalCloseButton handler={onCancel} />

                        <div className="col-md-12 form-group">
                            <label htmlFor="name">{t('f:dataRoom.name')}</label>
                            <Field id="name" name="name" placeholder={t('f:dataRoom.name')} />
                            {hasErrors(errors, 'name', touched)}
                        </div>

                        <div className="col-md-12 m-auto mt-50">
                            <button
                                className="button-main button-primary d-block m-auto"
                                type="submit"
                                onClick={() => {
                                    validateForm();
                                }}
                            >
                                {t('f:button.save')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    );
};
export default AddEditItemModal;
