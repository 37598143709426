import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabsContentNavigation from '../../../components/shared/TabsContentNavigation';
import FeatureState from '../../../context/state/FeatureState';
import Templates from './PropertySummary/Templates';
import InformationMemorandum from './PropertySummary/InformationMemorandum';
import MainButton from '../../../components/shared/MainButton';
import { useHistory } from 'react-router-dom';
import { PropertyAssetFeatures } from '../../../constants/propertyAssetFeatures';
import { isMobile } from 'react-device-detect';

enum NavEnums {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
}
const PropertySummary: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        { propertySummary, getFeature, checkDealRoomFeature } = useContext(FeatureState),
        { externalId } = propertySummary,
        [radioBtns, setRadioBtns] = useState<Array<any>>([]),
        [activComponent, setActivComponent] = useState<string>('');

    const handleChangeActivComponent = (value: string) => setActivComponent(value);

    useEffect(() => {
        if (getFeature('PageSummaryInformationMemorandum') &&
            checkDealRoomFeature(PropertyAssetFeatures.PageSummaryInformationMemorandum)) {
            radioBtns.push({
                handler: handleChangeActivComponent,
                text: t(`f:propertySummary.informationMemorandum`),
                value: NavEnums.SECOND,
            });

            setActivComponent(prevActiveComponent => prevActiveComponent ? prevActiveComponent : NavEnums.SECOND);
        }

        if (getFeature('PageSummaryPropertySummary') &&
            checkDealRoomFeature(PropertyAssetFeatures.PageSummaryPropertySummary)) {
            radioBtns.push({
                handler: handleChangeActivComponent,
                text: t(`f:propertySummary.propertySummary`),
                value: NavEnums.FIRST,
            });

            setActivComponent(prevActiveComponent => prevActiveComponent ? prevActiveComponent : NavEnums.FIRST);
        }
    }, [])

    const displayComponent = () => {
        switch (activComponent) {
            case NavEnums.SECOND:
                return <Templates pageType={'propertyassetpagesummary'} />;

            default:
                return <InformationMemorandum />;
        }
    };

    return (
        <>
            <TabsContentNavigation
                radioBtns={radioBtns}
                activComponent={activComponent}
                element={
                    <>
                        {activComponent === NavEnums.FIRST ? (
                            <div className={isMobile ? 'd-flex' : 'd-flex mr-80'}>
                                {getFeature('Write') && (
                                    <MainButton
                                        layout="primary"
                                        size="main"
                                        classAdditions="ml-auto mr-10"
                                        buttonHandler={() => {
                                            history.push(
                                                !propertySummary.isPortfolio
                                                    ? `/property/${externalId}/edit`
                                                    : `/property/${externalId}/edit/portfolio`
                                            );
                                        }}
                                        buttonText={t('f:button.editOpportunity')}
                                    />
                                )}
                            </div>
                        ) : null}
                    </>
                }
            />
            <div className="col-xs-12-clear">{displayComponent()}</div>
        </>
    );
};
export default PropertySummary;
