import Company from '../api/routes/CompanyApi';

const fetchCompanies = async () => {
    try {
        const { data } = await Company.fetchCompanies();
        return data;
    } catch (err) {
        console.log(err);
    }
};

const fetchCompanyUsers = async (companyId: string) => {
    try {
        const { data } = await Company.fetchCompanyUsers(companyId);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const fetchAllUsers = async () => {
    try {
        const { data } = await Company.fetchAllUsers();
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const fetchUsersFromMyCompany = async () => {
    try {
        const { data } = await Company.fetchUsersFromMyCompany();
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const CompanyService = {
    fetchCompanies,
    fetchAllUsers,
    fetchCompanyUsers,
    fetchUsersFromMyCompany,
};

export default CompanyService;
