import { BaseTransformer } from './BaseTransformer';
import PropertyModel from '../models/PropertyModel';

class PropertyTransformer extends BaseTransformer {
    constructor() {
        super();
        this.transformSingle = this.transformSingle.bind(this);
        this.transformMultiple = this.transformMultiple.bind(this);
	}
    
	transformSingle(data = {}) {
        const property = new PropertyModel(data);
        return property;
	}
    
	transformMultiple(data = []) {
        const properties = data?.map(item => new PropertyModel(item));
        return properties;
	}
}

export default new PropertyTransformer()