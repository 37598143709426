import PropertyAssetMap from '../../../../../components/shared/PropertyAssetMap';
import BaseComponent from './BaseComponent';
import { useContext, useEffect, useState } from 'react';
import FeatureState from '../../../../../context/state/FeatureState';

const MapTemplate = (props: any) => {
    const { propertySummary } = useContext(FeatureState),
        [location, setLocation] = useState<any>(
            propertySummary.locations && propertySummary.locations.length > 0
                ? {
                      long: propertySummary.locations[0].longitude,
                      lat: propertySummary.locations[0].latitude,
                      address: propertySummary.locations[0].address,
                  }
                : {
                      long: 0,
                      lat: 0,
                      address: '',
                  }
        );

    useEffect(() => {
        if (propertySummary?.locations && propertySummary.locations.length > 0)
            setLocation({
                long: propertySummary.locations[0].longitude,
                lat: propertySummary.locations[0].latitude,
                address: propertySummary.locations[0].address,
            });
    }, [propertySummary]);

    return (
        <div className="mt-50" style={{ height: '500px' }}>
            {propertySummary && propertySummary.locations && propertySummary.locations.length > 0 && (
                <PropertyAssetMap
                    locations={[]}
                    location={location}
                    shouldCluster={false}
                    showPopup={true}
                    showOnlyAddress={true}
                    allowInteraction={false}
                />
            )}
            <div className="col-xs-12-clear">
                {props.template && (
                    <BaseComponent
                        template={props.template}
                        isEditMode={props.isEditMode}
                        pageType={props.pageType}
                        onRemove={props.onRemove}
                        isMap={true}
                    />
                )}
            </div>
        </div>
    );
};

export default MapTemplate;
