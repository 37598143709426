import { FunctionComponent } from 'react';
import Overview from './Overview';
import OverviewContext from '../../context/OverviewContext';
import CheckAuthorization from '../../pages/StatusPages/CheckAuthorization';

const OverviewCheckUp: FunctionComponent = () => {
    return (
        <CheckAuthorization>
            <OverviewContext>
                <Overview />
            </OverviewContext>
        </CheckAuthorization>
    );
};
export default OverviewCheckUp;
