import React from 'react';
import TradeProbability from '../../pages/TradeProbability/models/tradeProbability';

export type TradeProbabilityStateType = {
    fetchTradeProbability: (_: string) => Promise<void> | undefined;
    setTradeProbability: (_: any) => void;
    tradeProbability: TradeProbability | undefined;
    isLoading: boolean;
};

export default React.createContext<TradeProbabilityStateType>({
    fetchTradeProbability: (_: string) => undefined,
    setTradeProbability: (_: any) => undefined,
    tradeProbability: undefined,
    isLoading: false,
});
