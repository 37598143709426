import { DataGrid } from '@material-ui/data-grid';
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { checkHeight } from '../../../../../utils/checkHeight';
import {
    ProjectTeamMemberUser,
    initalProjectTeamMemberUser,
    ProjetTeamTypes,
} from '../../../../../interfaces/ProjectTeam/ProjectTeamMeber';
import useBoolean from '../../../../../hooks/use-boolean';
import ProjectTeam from '../../../../../services/ProjectTeam';
import UserDetailsModal from './UserDetailsModal';
import CompanyButtonTemplate from '../../../../../interfaces/property/CompanyButtonTemplate';
import FilterRadioButton from '../../../../../components/shared/FilterRadioButton';
import MainLoader from '../../../../../components/shared/MainLoader';
import MainTreeDots from '../../../../../components/shared/MainTreeDots';
import ModalConfirmDelete from '../../../../../components/shared/ModalConfirmDelete';
import { toast } from 'react-toastify';
import AddUserModal from './AddUserModal';
import { initalValuesUser, UserModal } from '../../../../../interfaces/ProjectTeam/ProjectTeamMeber';
import FeatureState from '../../../../../context/state/FeatureState';
import { isMobile } from 'react-device-detect';
import UserCard from '../partials/UserCard';
import { TeamsModeViewEnum } from '../../ProjectTeamTab';

interface UserDataTableProps {
    usersArray: Array<ProjectTeamMemberUser>;
    refetch: () => void;
    companySelected: string;
    companies: Array<CompanyButtonTemplate>;
    setCompanySelected: (prev: string) => void;
    type: ProjetTeamTypes.TEAMMEMBER | ProjetTeamTypes.USER;
    modeView?: TeamsModeViewEnum;
}

const UserDataTable: FunctionComponent<UserDataTableProps> = ({
    usersArray,
    companySelected,
    companies,
    setCompanySelected,
    refetch,
    type,
    modeView = TeamsModeViewEnum.LIST
}: UserDataTableProps) => {
    const [isLoad, setIsLoadActions] = useBoolean(),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [isUserDetailsModalOpened, isUserDetailsModalOpenedActions] = useBoolean(),
        [isTeamMemberModalOpened, isTeamMemberModalOpenedActions] = useBoolean(),
        [isDeleteTeamMemberModalOpened, isDeleteTeamMemberModalOpenedActions] = useBoolean(),
        [teamRoles, setTeamRoles] = useState<Object>({}),
        [details, setDetails] = useState<ProjectTeamMemberUser>(initalProjectTeamMemberUser),
        [editUserDetails, setEditUserDetails] = useState<UserModal>(initalValuesUser),
        { getFeature } = useContext(FeatureState),
        { t } = useTranslation(['f', 'b']);

    const fileMenu = (item: ProjectTeamMemberUser, noMargin: boolean = false) => {
        const actions = [
            {
                text: t('f:teams.userDetails'),
                handler: () => {
                    setDetails(item);
                    isUserDetailsModalOpenedActions.setTrue();
                },
            },
            {
                text: t('f:button.edit'),
                handler: () => {
                    const mapTo: UserModal = {
                        name: item.fullName,
                        address: item.address,
                        company: item.company,
                        email: item.email,
                        phone: item.phoneNumber,
                        roleType:
                            item.role === 10 || item.role === 11 || item.role === 12
                                ? `${item.role - 10}`
                                : `${item.role}`,
                        editor: item.role === 10 || item.role === 11 || item.role === 12 ? true : false,
                    };

                    setEditUserDetails(mapTo);
                    isTeamMemberModalOpenedActions.setTrue();
                },
            },
            {
                text: t('f:button.delete'),
                handler: () => {
                    setDetails(item);
                    isDeleteTeamMemberModalOpenedActions.setTrue();
                },
            },
        ];

        return (
            <MainTreeDots
                actionsMenu={getFeature('ProjectTeamEdit') ? actions : actions.slice(0, actions.length - 2)}
                noMargin={noMargin}
            />
        );
    };

    const tableLayout = [
        {
            field: 'fullName',
            headerName: t('f:teams.name'),
            flex: 1,
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },

        {
            field: 'company.name',
            headerName: t('f:teams.company'),
            flex: 1,
            renderCell: (props) => props.row.company,
        },

        {
            field: 'email',
            headerName: t('f:teams.email'),
            sorter: (a: any, b: any) => a.email.localeCompare(b.email),
            flex: 1,
        },
        {
            field: 'phoneNumber',
            headerName: t('f:teams.phoneNumber'),
            flex: 1,
        },
        {
            field: 'role',
            headerName: t('f:teams.role'),
            sorter: (a: any, b: any) => a.role.localeCompare(b.role),
            renderCell: (props) => t(`f:teams.roleTypeObjects.${teamRoles[props.row.role]}`),
            flex: 1,
        },

        {
            field: '',
            headerName: 'Actions',
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (props) => fileMenu(props.row),
        },
    ];

    const featchRoles = useCallback(async () => {
        try {
            const data = await ProjectTeam.fetchTeamRoles();
            setTeamRoles(data);
            setIsLoadActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    const handleFinishDelete = useCallback(async () => {
        try {
            await ProjectTeam.deleteTeamMember(`${propertyAssetId}`, details ? details?.id : '');
            refetch();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t, propertyAssetId, details, refetch]);

    const displayContent = () => {
        if (!isMobile) {
            if (modeView === TeamsModeViewEnum.LIST) {
                return (
                    <div style={{ width: '100%' }} className="mb-100">
                        <DataGrid rows={usersArray} columns={tableLayout} pageSize={50} disableSelectionOnClick autoHeight/>
                    </div>
                )
            }

            return (
                <div className='user-cards d-flex flex-direction-row f-wrap gap-5'>
                    {usersArray.map(user => (<UserCard user={user} classAdditions="wrap-cards" actions={fileMenu(user, true)} />))}
                </div>
            )
        }

        return (
            <div className='user-cards d-flex flex-direction-column'>
                {usersArray.map(user => (<UserCard user={user} />))}
            </div>
        )
    }

    useEffect(() => {
        featchRoles();
    }, [featchRoles]);

    return (
        <>
            <div className="d-flex mb-20 f-wrap gap-10">
                {companies.map((company) => {
                    return (
                        <FilterRadioButton
                            id={company.name}
                            key={company.name}
                            current={companySelected}
                            onClickHandler={setCompanySelected}
                            text={company.name}
                        />
                    );
                })}
            </div>
            {isLoad ? (displayContent()) : (<MainLoader type="regular" />)}
            <UserDetailsModal
                isUserDetailsModalOpened={isUserDetailsModalOpened}
                setIsUserDetailsModalOpened={isUserDetailsModalOpenedActions.toggle}
                worker={details}
                roles={teamRoles}
            />

            <AddUserModal
                isModalOpened={isTeamMemberModalOpened}
                setIsModalOpened={isTeamMemberModalOpenedActions}
                type={type}
                refetch={refetch}
                isEditMode={true}
                editUser={editUserDetails}
            />
            <ModalConfirmDelete
                isModalOpened={isDeleteTeamMemberModalOpened}
                setIsModalOpenedActions={isDeleteTeamMemberModalOpenedActions}
                text={t('f:teams.deleteUser')}
                handleFinish={handleFinishDelete}
            />
        </>
    );
};
export default UserDataTable;
