import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import varibales from '../../../assets/styles/general/_variable.module.scss';

interface ImageContainerProps {
    src: string;
    name: string;
    setFieldValue: (name: string, val: any) => void;
}

const ImageContainer: FunctionComponent<ImageContainerProps> = ({ src, setFieldValue, name }) => {
    const [fileType, setFileType] = useState<string>('png');
    const handleRemove = useCallback(() => {
        setFieldValue(name, '');
    }, [name, setFieldValue]);

    useEffect(() => {
        const parts = src.split('.');
        setFileType(parts[parts.length - 1]);
    }, [src]);

    return (
        <div
            className="d-flex flex-direction-column"
            style={{ backgroundColor: name === 'lightLogo' ? 'rgba(1,1,1,0.1)' : '' }}
        >
            <FontAwesomeIcon className="pointer ml-auto" icon={faTimes} color={varibales.red} onClick={handleRemove} />
            {fileType === 'mp4' ? (
                <video src={src} autoPlay loop muted />
            ) : (
                <img src={src} className="newsletter-builder__def-image" alt="property" />
            )}
        </div>
    );
};

export default ImageContainer;
