import PropertyAssetCompanyApi from "../api/routes/PropertyAssetCompanyApi";
import { ToggleCompanyStatusEnum } from "../constants/toggleCompanyStatus";

const postCompanyNote = async (propertyAssetId: string | number, companyId: string | number, note: string) => {
    await PropertyAssetCompanyApi.postCompanyNote(propertyAssetId, companyId, note);
}

const fetchAllCompanyNotes = async (propertyAssetId: string | number, companyId: string | number) => {
    const { data } = await PropertyAssetCompanyApi.fetchAllCompanyNotes(propertyAssetId, companyId);
    return data;
}

const fetchInvestorUsersOfCompany = async (propertyAssetId: string | number, companyId: string | number) => {
    const { data } = await PropertyAssetCompanyApi.fetchInvestorUsersOfCompany(propertyAssetId, companyId);
    return data;
}

const fetchCompanyInfo = async (propertyAssetId: string | number, companyId: string | number) => {
    const { data } = await PropertyAssetCompanyApi.fetchCompanyInfo(propertyAssetId, companyId);
    return data;
}

const toggleCompanyStatus = async (propertyAssetId: string, companyId: string | number, status: ToggleCompanyStatusEnum) => {
    const { data } = await PropertyAssetCompanyApi.toggleCompanyStatus(propertyAssetId, companyId, status);
    return data;
};

const deleteCompany = async (propertyAssetId: string | number, companyId: string | number) => {
    await PropertyAssetCompanyApi.deleteCompany(propertyAssetId, companyId);
}

const PropertyAssetCompanyService = {
    postCompanyNote,
    fetchAllCompanyNotes,
    fetchInvestorUsersOfCompany,
    fetchCompanyInfo,
    toggleCompanyStatus,
    deleteCompany
}

export default PropertyAssetCompanyService;