import { FunctionComponent, useCallback, useContext } from 'react';
import RadioBtns from '../../../../components/shared/RadioBtns';
import LOWizardState from '../../../../context/state/LOWizardState';

interface ListingTypeSingleProps {
    type: string;
    title: string;
    info: string;
}

const ListingTypeSingle: FunctionComponent<ListingTypeSingleProps> = ({
    title,
    info,
    type,
}: ListingTypeSingleProps) => {
    const { currentListingType, setCurrentListingType } = useContext(LOWizardState);

    const handleOnClick = useCallback(
        (val: string) => {
            setCurrentListingType({ ...currentListingType, listingTypeStatus: Number(val) });
        },
        [setCurrentListingType, currentListingType]
    );

    return (
        <div className="d-flex listing-type__form mt-40" key={type}>
            <RadioBtns
                text=""
                activComponent={`${currentListingType.listingTypeStatus}`}
                value={type}
                handler={handleOnClick}
            />

            <div className="d-flex flex-direction-column">
                <label htmlFor="hide" className="listing-type__form-label">
                    {title}
                </label>
                <p className="text-rubik-16">{info}</p>
            </div>
        </div>
    );
};
export default ListingTypeSingle;
