import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import UserService from '../../../services/User';
import { toast } from 'react-toastify';
import { ValidationUpdateUserForm } from '../../../validations/UserWizard/UpdateUser';
import User from '../../../interfaces/user/user';
import { Button, Upload } from 'antd';
import MainLoader from '../../../components/shared/MainLoader';

interface Props {
    cancelEverything: () => void;
    returnToPreviousPage: () => void;
    goToNextPage: () => void;
}

export const UserDetails: FunctionComponent<Props> = ({ cancelEverything, goToNextPage }) => {
    const { t } = useTranslation(['f', 'b']),
        [isLoading, setIsLoading] = useState<boolean>(true),
        [user, setUser] = useState<User>({
            id: '',
            firstName: '',
            lastName: '',
            position: '',
            phone: '',
            email: '',
            department: '',
            address: '',
            countryId: '',
            imageUrl: '',
        });

    const customRequest = async (options: any) => {
        try {
            const data = new FormData();
            data.append('Image', options.file);
            const resp = await UserService.uploadImage(options, data);
            const _user = { ...user };
            _user.imageUrl = resp;
            setUser(_user);

            toast.success(t(`f:bid.uploadFile`));
        } catch (error) {
            toast(t(`f:bid.failedToUploadFile`));
        }
    };

    const fetchMyInfo = useCallback(async () => {
        try {
            setIsLoading(true);
            const myInfo = await UserService.getMyUserInfo();

            setUser(myInfo);
            setIsLoading(false);
        } catch (error: any) {
            toast(error.response.data.error.errorMessage);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchMyInfo();
    }, [fetchMyInfo]);

    return (
        <div className="marketing wizard-container-steps">
            <p className="listing-type__help">
                Having trouble? <span className="listing-type__help-link">{t('f:userWizard.userDetails.getHelp')}</span>
            </p>

            {!isLoading ? (
                <Formik
                    initialValues={user}
                    enableReinitialize={true}
                    validationSchema={ValidationUpdateUserForm}
                    onSubmit={async (values: User) => {
                        await UserService.updateMe(values);

                        if (values.imageUrl) {
                            await UserService.userPoriflePicture(values.id, values.imageUrl);
                        }

                        goToNextPage();
                        return;
                    }}
                >
                    {({ values, errors, setFieldValue, touched, resetForm, submitForm }) => (
                        <Form>
                            <div className="meeting__form-group d-flex">
                                <div className="col-md-4 form-group ">
                                    <label htmlFor="text">{t('f:userWizard.userDetails.firstName')}</label>
                                    <Field
                                        id="text"
                                        name="firstName"
                                        placeholder={t('f:userWizard.userDetails.firstName')}
                                    />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label htmlFor="text">{t('f:userWizard.userDetails.lastName')}</label>
                                    <Field
                                        id="text"
                                        name="lastName"
                                        placeholder={t('f:userWizard.userDetails.lastName')}
                                    />
                                </div>
                            </div>
                            <div className="meeting__form-group d-flex">
                                <div className="col-md-4 form-group ">
                                    <label htmlFor="text">{t('f:userWizard.userDetails.email')}</label>
                                    <Field id="text" name="email" placeholder={t('f:userWizard.userDetails.email')} />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label htmlFor="text">{t('f:userWizard.userDetails.phone')}</label>
                                    <Field id="text" name="phone" placeholder={t('f:userWizard.userDetails.phone')} />
                                </div>
                            </div>
                            <div className="meeting__form-group d-flex">
                                <div className="col-md-4 form-group ">
                                    <label htmlFor="text">{t('f:userWizard.userDetails.position')}</label>
                                    <Field
                                        id="text"
                                        name="position"
                                        placeholder={t('f:userWizard.userDetails.position')}
                                    />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label htmlFor="text">{t('f:userWizard.userDetails.department')}</label>
                                    <Field
                                        id="text"
                                        name="department"
                                        placeholder={t('f:userWizard.userDetails.department')}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12-clear mt-30">
                                <div className="meeting__form-group d-flex">
                                    <div className="col-md-4 form-group ">
                                        <div className="col-xs-12-clear">
                                            <label htmlFor="text">{t('f:userWizard.userDetails.image')}</label>
                                            {user.imageUrl ? (
                                                <img
                                                    src={user.imageUrl}
                                                    className="newsletter-builder__def-image"
                                                    alt="property"
                                                />
                                            ) : null}
                                            <Upload
                                                name="imageUrl"
                                                action={process.env.REACT_APP_API_URL + `/user/image`}
                                                customRequest={(option) => {
                                                    customRequest(option);
                                                }}
                                                listType="text"
                                                className="add-property-image-builder__upload add-property-image-builder__upload--sm"
                                                showUploadList={false}
                                            >
                                                <Button className="mt-0 mb-20">
                                                    <p className="col-xs-12-clear listing-type__help-link">
                                                        {t('f:button.upload')}
                                                    </p>
                                                </Button>
                                            </Upload>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                                <button
                                    className="button-main button-secondary d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                        cancelEverything();
                                    }}
                                    type="submit"
                                >
                                    {t('f:button.cancel')}
                                </button>

                                <button
                                    className="button-main button-primary mr-25"
                                    onClick={() => {
                                        submitForm();
                                    }}
                                >
                                    {t('f:button.next')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <MainLoader type="regular" />
            )}
        </div>
    );
};
