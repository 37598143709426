import { FunctionComponent, MouseEventHandler } from 'react';

interface MainCheckboxProps {
    checked: boolean;
    onClick: MouseEventHandler<HTMLDivElement>;
}

const MainCheckbox: FunctionComponent<MainCheckboxProps> = ({ checked, onClick }: MainCheckboxProps) => {
    return (
        <div
            className={`ant-select-item ant-select-item-option d-flex ant-select-item-option-active${
                checked ? ' ant-select-item-option-selected' : ''
            }`}
            onClick={onClick}
        >
            <div className="ant-select-item-option-content">
                <div className="select-checkbox"></div>
            </div>
        </div>
    );
};

export default MainCheckbox;
