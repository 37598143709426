import { Tabs } from 'antd';
import { FunctionComponent } from 'react';
import arrowRight from '../../assets/images/arrow-right.svg';

const { TabPane } = Tabs;

interface Tab {
    id: number;
    name: string;
    component?: any;
    subtabs?: any;
}

interface Props {
    tabs: Tab[];
    activeTab?: string;
    activeSubtab?: any;
}

const WizardWithSidebar: FunctionComponent<Props> = (props) => {
    return (
        <div className="wizard-container">
            <Tabs defaultActiveKey={props.activeTab} tabPosition="left" activeKey={props.activeTab}>
                {props.tabs.map((tab) => (
                    <TabPane
                        tab={
                            <div onClick={() => {}}>
                                <div className="d-flex justify-content-space-between px-30">
                                    <p>
                                        <span>{tab.name}</span>
                                    </p>
                                </div>
                                <div className="row">
                                    {props.activeTab &&
                                        tab.subtabs &&
                                        parseInt(props.activeTab) === tab.id &&
                                        tab.subtabs.map((subtab: any) => (
                                            <div className="col-xs-12 d-flex justify-content-space-between mt-20 subtab">
                                                <p
                                                    className={`${
                                                        props.activeSubtab.step == subtab.step ? '' : 'color-gray'
                                                    } subtab-name`}
                                                >
                                                    {subtab.name}
                                                </p>
                                                <figure>
                                                    <img
                                                        src={arrowRight}
                                                        alt="Arrow back"
                                                        className={
                                                            props.activeSubtab.step == subtab.step ? 'd-flex' : 'd-none'
                                                        }
                                                    />
                                                </figure>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        }
                        key={tab.id}
                    >
                        {props.activeSubtab ? props.activeSubtab.component : tab.component}
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default WizardWithSidebar;
