import { FunctionComponent, useCallback, useContext } from 'react';
import darkLogo from '../../assets/images/logo.png';
import lightLogo from '../../assets/images/logo-w.png';
import ThemeState from '../../context/state/ThemeState';
import { useHistory } from 'react-router-dom';

interface LogoProps {
    light?: Boolean;
}

const Logo: FunctionComponent<LogoProps> = ({ light }) => {
    const { currentTheme } = useContext(ThemeState),
        history = useHistory();
    const goHome = useCallback(() => {
        history.push('/');
    }, [history]);

    return (
        <div className="logo" onClick={goHome}>
            <img
                src={
                    light
                        ? currentTheme.logo
                            ? currentTheme.lightLogo
                            : lightLogo
                        : currentTheme.logo
                        ? currentTheme.logo
                        : darkLogo
                }
                alt="Logo"
            />
        </div>
    );
};
export default Logo;
