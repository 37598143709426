import { Form, Formik } from 'formik';
import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../components/shared/MainButton';
import MainLoader from '../../../components/shared/MainLoader';
import MainTextInput from '../../../components/shared/MainTextInput';
import { DEFAULTTHEME } from '../../../constants/theme';
import ThemeState from '../../../context/state/ThemeState';
import { FormikTheme } from '../../../interfaces/theme';
import ColorPicker from '../partials/ColorPicker';
import PreviewBars from '../partials/PreviewBars';
import PreviewButtons from '../partials/PreviewButtons';
import UploadContainer from '../partials/UploadContainer';

const CreateOrEditTheme: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        {
            createOrUpdateNewTheme,
            selectedThemeForSaveAndEdit,
            themeList,
            isEditMode,
            isEditModeActions,
            isThemeListLoading,
            previewTheme,
        } = useContext(ThemeState);

    const findName = useCallback(() => {
        const obj = themeList.find((el) => el.id === selectedThemeForSaveAndEdit);
        return obj ? obj.name : '';
    }, [selectedThemeForSaveAndEdit, themeList]);

    const init: FormikTheme = {
        name: isEditMode ? findName() : '',
        logo: isEditMode ? previewTheme.logo : '',
        lightLogo: isEditMode ? previewTheme.lightLogo : '',
        loader: isEditMode ? previewTheme.loader : '',
        '--main-bg-color': isEditMode ? previewTheme.colors['--main-bg-color'] : DEFAULTTHEME.colors['--main-bg-color'],
        '--main-text': isEditMode ? previewTheme.colors['--main-text'] : DEFAULTTHEME.colors['--main-text'],
        '--buton-border-color': isEditMode
            ? previewTheme.colors['--buton-border-color']
            : DEFAULTTHEME.colors['--buton-border-color'],
        '--asset-bar-text': isEditMode
            ? previewTheme.colors['--asset-bar-text']
            : DEFAULTTHEME.colors['--asset-bar-text'],
    };

    const removeNameFromColors = () => {
        const { name, logo, loader, lightLogo, ...rest } = init;
        return rest;
    };

    const handleSubmitNew = useCallback(
        async (values: FormikTheme) => {
            createOrUpdateNewTheme(values);
        },
        [createOrUpdateNewTheme]
    );

    return (
        <div className="col-xs-12-clear mb-100 px-30">
            {isThemeListLoading ? (
                <Formik initialValues={init} enableReinitialize={true} onSubmit={handleSubmitNew}>
                    {({ values, submitForm, setFieldValue }) => (
                        <div className="col-xs-12-clear">
                            <div className="col-xs-3-clear">
                                <div className="col-xs-12-clear">
                                    <Form className="row-flex-clear">
                                        <div className="col-xs-12 theme-manager-page__pr-31">
                                            <MainTextInput
                                                label={t(`f:theme-manager.name`)}
                                                placeholder={t(`f:theme-manager.enterName`)}
                                                name="name"
                                                touched={{}}
                                                errors={{}}
                                            />
                                        </div>
                                        {Object.entries(removeNameFromColors()).map(([key, val]) => (
                                            <ColorPicker
                                                values={values}
                                                name={key}
                                                setFieldValue={setFieldValue}
                                                key={key}
                                            />
                                        ))}
                                        <UploadContainer values={values} name="logo" setFieldValue={setFieldValue} />
                                        <UploadContainer
                                            values={values}
                                            name="lightLogo"
                                            setFieldValue={setFieldValue}
                                        />
                                        <UploadContainer values={values} name="loader" setFieldValue={setFieldValue} />
                                    </Form>
                                </div>
                                <div className="col-xs-12-clear d-flex gap-20 align-item-center ml-30 mt-20">
                                    <MainButton
                                        layout="primary"
                                        size="main"
                                        buttonText={t('f:button.submit')}
                                        buttonHandler={submitForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-8-clear">
                                <PreviewButtons
                                    buttonBgColor={values['--main-bg-color']}
                                    buttonTextColor={values['--main-text']}
                                    buttonBorderColor={values['--buton-border-color']}
                                />
                                <PreviewBars
                                    assetBarsSelectedUnderline={values['--main-bg-underline']}
                                    assetBarsUnderline={values['--main-bg-underline']}
                                    assetBarsText={values['--asset-bar-text']}
                                />
                            </div>
                            <div className="col-xs-1-clear">
                                {isEditMode && (
                                    <MainButton
                                        classAdditions="mr-30"
                                        layout="primary"
                                        size="small"
                                        buttonText={t('f:button.cancel')}
                                        buttonHandler={() => {
                                            isEditModeActions.setFalse();
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </Formik>
            ) : (
                <MainLoader type="regular" mt="mt-100" />
            )}
        </div>
    );
};
export default CreateOrEditTheme;
