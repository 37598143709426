import { FunctionComponent } from 'react';

interface MainTableBodyCellProps {
    text: JSX.Element | string;
    type?: 'actions' | 'checkbox';
}

const MainTableBodyCell: FunctionComponent<MainTableBodyCellProps> = ({ text, type }: MainTableBodyCellProps) => (
    <div
        className={`investors-table__row-single ${
            type ? `investors-table__row-single--${type}` : 'investors-table__row-single--body'
        }`}
    >
        {text}
    </div>
);
export default MainTableBodyCell;
