import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SlideInMenu from '../partials/SlideInMenu';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import useBoolean from '../../hooks/use-boolean';
import Burger from '../shared/Burger';
import Logo from '../shared/Logo';
import { isMobile } from 'react-device-detect';
import useCheckUserLogged from '../../hooks/useCheckUserLogged';

const HALF_URLS = [
    'bid',
    'template',
    'list-opportunity',
    'edit',
    'bidding-structure',
    'user-wizard',
    'schedule',
    'deal-line',
];

const MainMenu = () => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory();

    return (
        <nav className="d-flex">
            <AuthenticatedTemplate>
                <div className="navigation__lg-menu">
                    <button
                        onClick={() => {
                            history.push('/overview');
                        }}
                        className="navigation__top-links"
                    >
                        {t('f:menu.transaction')}
                    </button>
                    <span className="navigation__line ml-30"></span>
                    <button
                        onClick={() => {
                            history.push('/data-analytics');
                        }}
                        className="navigation__top-links"
                    >
                        {t('f:menu.dataAnalytics')}
                    </button>
                </div>
            </AuthenticatedTemplate>
        </nav>
    );
};

const Header = () => {
    // Add pages for light header
    const [isShown, setIsShownActions] = useBoolean(),
        [hasShadow, setHasShadowActions] = useBoolean(true),
        [firstHalf, setFistHalf] = useState<Array<string>>([
            '/user-wizard/userDetails',
            '/user-wizard/companyDetails',
            '/user-wizard/preferences',
            '/property/add',
            '/property/add-single',
            '/property/add-portfolio',
            '/account-settings/company-details',
            '/account-settings/personal-details',
            '/account-settings/privacy&concent',
            '/account-settings/security',
        ]),
        location = useLocation(),
        pages = ['/', '/data-link-or-transaction'],
        isLogged = useCheckUserLogged();

    function isLightHeader(): Boolean {
        return pages.some((page) => location.pathname === page);
    }

    function isFirstHakfLightHeader(): Boolean {
        return firstHalf.some((page) => location.pathname === page);
    }

    useEffect(() => {
        const params = location.pathname.split('/');
        const id = params[2];
        const half = [...firstHalf];

        if (Number(id)) {
            if (HALF_URLS.includes(params[3])) {
                half.push(`${params.map((el) => el).join('/')}`);
            }
        }

        setFistHalf(half);
        if (Number(id)) {
            location.pathname === `/property/${id}` ? setHasShadowActions.setFalse() : setHasShadowActions.setTrue();
        }
    }, [location.pathname]);

    const showMenuIcon = () => {
        if (isMobile && !isLogged) {
            return <></>
        }

        return (!isShown && (
            <Burger
                handleOnClick={() => {
                    setIsShownActions.setTrue();
                }}
                classNameMain={isFirstHakfLightHeader() ? 'burger--light' : ''}
            />
        ))
    }

    return (
        <header id="header">
            <div
                className={`row-flex-clear navigation__container ${
                    isLightHeader() ? 'light' : hasShadow ? 'shadow' : ''
                }`}
            >
                {showMenuIcon()}
                <Logo light={isLightHeader() || isFirstHakfLightHeader()} />

                <MainMenu />
            </div>
            <SlideInMenu
                setIsShown={setIsShownActions}
                isShown={isShown}
                lightLogo={isLightHeader() || isFirstHakfLightHeader()}
            />
        </header>
    );
};

export default Header;
