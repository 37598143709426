import { Radio } from 'antd';
import { FunctionComponent } from 'react';

interface Props {
    label?: string;
    selected?: any;
    onSelected: any;
    items: Array<any>;
    isHorizontal: boolean;
}

const RadioList: FunctionComponent<Props> = ({ selected, onSelected, items, isHorizontal }) => {

    const itemSelected = e => {
        onSelected(e.target.value);
    };

    const verticalList = () => {

        return <div className="list-maps">
            {
                items.map(item =>
                    <div className="item">
                        <input
                            type="radio"
                            id={item.key}
                            name="option"
                            value={item.key}
                            onChange={itemSelected}
                            checked={item.key === selected.key}

                        />
                        <label htmlFor={item.key}>{item.value} </label>
                    </div>
                )
            }
        </div>
    }

    const horizontalList = () => {

        return <form className="radio-list d-flex">
            {
                items.map(item =>
                    <div className="radio-list-button d-flex">
                        <input
                            type="radio"
                            id={item.key}
                            name="option"
                            value={item.key}
                            onChange={itemSelected}
                            checked={item.key === selected.key}

                        />
                        <label htmlFor={item.key}>{item.value} </label>
                    </div>
                )
            }
        </form>
    }

    return (
        <>
            {isHorizontal ? horizontalList() : verticalList()}
        </>
    );
};

export default RadioList;