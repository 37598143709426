import { CancelToken } from 'axios';
import BuildingDirectoryApi from '../api/routes/BuildingDirectoryApi';
import { SearchTransactionDataModel } from '../pages/TransactionData/models/search-transaction-data.model';

const fecthAllTransactionData = async (cancelToken: CancelToken, filterParams?: SearchTransactionDataModel) => {
    const { data } = await BuildingDirectoryApi.fetchAllTransactionData(cancelToken, filterParams);

    return data;
}

const fetchTransactionDataById = async (id: string) => {
    const { data } = await BuildingDirectoryApi.fetchTransactionDataById(id);

    return data;
}

const TransactionDataService = {
    fecthAllTransactionData,
    fetchTransactionDataById
}

export default TransactionDataService;