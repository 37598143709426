import api from '../index';

const fetchReportConfig = (reportId: string) => {
    return api.get(`/powerBi/config/`+ reportId);
};

const PowerBiApi = {
    fetchReportConfig
};

export default PowerBiApi;
