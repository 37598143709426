import { isBoolean, isNumber, isString } from 'lodash';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { numberWithCommas } from '../../utils/numberWithCommas';

interface Props {
    tableData: any;
    centered?: string;
}

const CustomTable: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']);

    const checkValue = (value: any) => {
        if (isBoolean(value)) {
            return value ? 'yes' : 'no';
        } else if (isNumber(value)) {
            return numberWithCommas(value);
        } else if (isString(value)) {
            return value;
        } else {
            return '';
        }
    };

    return (
        <table className={`transpose-table__wrap ${props.centered} main-text`}>
            <tbody>
                {props.tableData?.map((data: any, index: number) => {
                    console.log(index + 1, data.value);

                    return (
                        <tr key={index}>
                            <td className="table-index">{index + 1}</td>
                            <td>{t(`b:property.${data.name}`)}</td>
                            <td>{`${checkValue(data.value)}`}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default CustomTable;
