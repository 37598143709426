import { FunctionComponent, useEffect } from 'react';
import { loginRequest } from '../../../auth/authConfig';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { headerText } from '../../../constants/HomePage';
import { useTranslation } from 'react-i18next';

const Header: FunctionComponent = () => {
    const { instance } = useMsal(),
        { t } = useTranslation(['f', 'b']);

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    };

    useEffect(() => {
        var i = 0;
        const div: any = document.getElementById('roll-me');
        const h1 = document.createElement('h1');
        h1.classList.add('home-page-header__rolling-text');

        function myLoop() {
            div.innerHTML = '';
            h1.innerText = '';
            h1.innerText = `${headerText[i]}`;
            div.append(h1);
            setTimeout(function () {
                i++;
                if (i === headerText.length - 1) {
                    i = 0;
                    myLoop();
                } else {
                    myLoop();
                }
            }, 8000);
        }
        window.addEventListener('load', myLoop);
        return () => window.removeEventListener('load', myLoop);
    }, []);

    return (
        <div className="home-page-header__main d-flex flex-direction-column justify-content-center align-items-center">
            <div className="home-page-header__rolling-text-container" id="roll-me"></div>
            <UnauthenticatedTemplate>
                <button className="home-page-header__btn mt-100" onClick={handleLogin}>
                    {t('f:button.login')}
                </button>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default Header;
