import PropertyAssetFeature from '../api/routes/PropertyAssetFeatureApi';
import { PropertyToggleStatusBody } from '../interfaces/property/PropertyAsserts';

const fetchAssetFeature = async (id: string): Promise<any> => {
    const response = await PropertyAssetFeature.fetchPropertyAssetFeature(id);
    return response.data;
};

const fetchAssetHero = async (id: string): Promise<any> => {
    const response = await PropertyAssetFeature.fetchAssetHero(id);
    return response.data;
};

const fetchAssetHeroGUI = async (id: string) => {
    const { data } = await PropertyAssetFeature.fetchAssetHeroGUI(id);
    return data;
};

const fetchAllFeatures = async () => {
    const { data } = await PropertyAssetFeature.fetchAllFeatures();
    return data;
};

const toggleStatus = async (propertyAssetId: string, body: PropertyToggleStatusBody) => {
    const { data } = await PropertyAssetFeature.toggleStatus(propertyAssetId, body);
    return data;
};

const fetchDealRoomFeatures = async (propertyAssetId: number | string) => {
    const { data } = await PropertyAssetFeature.fetchDealRoomFeatures(propertyAssetId);
    return data;
}

const updateDealRoomFeatures = async (propertyAssetId: number | string, features: Array<number>) => {
    await PropertyAssetFeature.updateDealRoomFeatures(propertyAssetId, features);
}

const PropertyAssetFeatureService = {
    fetchAssetFeature,
    fetchAssetHero,
    fetchAllFeatures,
    toggleStatus,
    fetchAssetHeroGUI,
    fetchDealRoomFeatures,
    updateDealRoomFeatures
};
export default PropertyAssetFeatureService;
