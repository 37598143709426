import { FunctionComponent, useContext } from 'react';
import BuildingDirectoryState from '../../../context/state/BuildingDirectoryState';

const BuildingInfoGallery: FunctionComponent = () => {
    const { buildingDirectory } = useContext(BuildingDirectoryState);
    const data = buildingDirectory!;

    return (
        <section className="module-intro mt-40 gallery">
            <div className="gallery">
                <h1 className="heading">Photo Gallery</h1>
                {data.photoReferenceIds?.map(photo => (
                    <img key={photo} className='info-photo' src={`${process.env.REACT_APP_BUILDING_DIRECTORY_BLOB_URL}/${data.id}/${photo}`} />
                ))}
            </div>
        </section>
    );
};

export default BuildingInfoGallery;
