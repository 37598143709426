import { FunctionComponent, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import useBoolean from '../../../../../hooks/use-boolean';
import ModalConfirmDelete from '../../../../../components/shared/ModalConfirmDelete';

interface TreeDotsInvestorsProps {
    deleteHandler: () => void;
    sendEmail: () => void;
    id: number | string;
    isNDAShown?: boolean;
    handleSignNda?: (id: string) => void;
    ndaStatus: number;
}
const TreeDotsInvestors: FunctionComponent<TreeDotsInvestorsProps> = ({
    id,
    deleteHandler,
    isNDAShown,
    sendEmail,
    handleSignNda,
    ndaStatus
}) => {
    const { t } = useTranslation(['f', 'b']),
        [isRemoveModalOpen, isRemoveModalOpenActions] = useBoolean(),
        [isThreeDotClicked, setIsThreeDotClickedActions] = useBoolean();

    const handleOnDeleteClick = useCallback(() => {
        isRemoveModalOpenActions.setTrue();
    }, [isRemoveModalOpenActions]);

    const handleOnConfirmDeleteClick = useCallback(() => {
        deleteHandler();
        isRemoveModalOpenActions.setFalse();
    }, [isRemoveModalOpenActions, deleteHandler, id]);

    const handleSendEmailClick = useCallback(() => {
        sendEmail();
    }, [sendEmail]);

    const handleTogleTreeDotsMenu = useCallback(() => {
        setIsThreeDotClickedActions.toggle();
    }, [setIsThreeDotClickedActions]);

    const handleMouseLeave = useCallback(() => {
        setIsThreeDotClickedActions.setFalse();
    }, [setIsThreeDotClickedActions]);

    const content = (
        <div className="d-flex flex-direction-column" onMouseLeave={handleMouseLeave}>
            <span style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }} onClick={handleOnDeleteClick}>
                {t('f:button.remove')}
            </span>
            <span style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }} onClick={handleSendEmailClick}>
                {t('f:button.sendEmail')}
            </span>
            {isNDAShown && (
                <span
                    style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                    onClick={() => {
                        if (handleSignNda) handleSignNda(`${id}`);
                    }}
                >
                    {ndaStatus  === 0 ? 'NDA Signed' : 'NDA Not Signed'}
                </span>
            )}
        </div>
    );

    return (
        <div className="">
            <FontAwesomeIcon
                className="mxl-10"
                style={{ color: '#a1abbd', cursor: 'pointer' }}
                icon={faEllipsisH}
                size="lg"
                onClick={handleTogleTreeDotsMenu}
            ></FontAwesomeIcon>
            <div style={{ marginLeft: '20px' }}>
                <Popover content={content} placement="bottom" trigger="click" visible={isThreeDotClicked}></Popover>
            </div>

            <ModalConfirmDelete
                isModalOpened={isRemoveModalOpen}
                setIsModalOpenedActions={isRemoveModalOpenActions}
                text={t('f:property.confirmSectionDelete')}
                handleFinish={handleOnConfirmDeleteClick}
            />
        </div>
    );
};

export default TreeDotsInvestors;
