import * as Yup from 'yup';

export const validatePieChartSchema = Yup.object().shape({
    title: Yup.string().required('Title is Required'),
    sectors: Yup.array()
        .of(
            Yup.object().shape({
                sector: Yup.string().required('Name is required'),
                size: Yup.number().required('Please enter size'),
            })
        )
        .min(1, 'sectors is >= 1'),
});
