import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import DataAnalyticPageBuilderService from '../../../services/DataAnalyticPageBuilderService';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageSectors } from '../../../interfaces/DataAnalytics/Pages';
import Sectors from '../components/Sectors';

const Overview = (props) => {
    const location = useLocation(),
        { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        [sectors, setSectors] = useState<Array<PageSectors>>([]);
    let activTab = location.hash ? location.hash.replace('#', '') : '1';

    const removeHandler = useCallback(
        async (pageId, id) => {
            try {
                await DataAnalyticPageBuilderService.deleteSection(`${pageId}`, `${id}`);
                const new_array = [...sectors];
                setSectors(new_array.filter((elem) => elem.id !== id));
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [t, sectors]
    );
    const editHandler = useCallback(
        (pageId, id, pageSectionDataId) => {
            history.push(`template/chart/${pageId}/add-content/left-heading-chart/chart-${id}:${pageSectionDataId}`);
        },
        [history]
    );

    const fetchSectorsForPage = useCallback(async () => {
        try {
            const _sectors = await DataAnalyticPageBuilderService.featchSections(activTab);
            setSectors(_sectors);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [activTab, t]);

    useEffect(() => {
        fetchSectorsForPage();
    }, [fetchSectorsForPage]);
    return (
        <div className="data-analytic__overview">
            {sectors.map((elem: PageSectors) => (
                <Sectors
                    {...elem}
                    removeHandler={() => {
                        removeHandler(elem.pageId, elem.id);
                    }}
                    editHandler={() => {
                        editHandler(elem.pageId, elem.id, elem.pageSectionDataId);
                    }}
                />
            ))}
        </div>
    );
};

export default Overview;
