import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faStopwatch, faServer } from '@fortawesome/free-solid-svg-icons';
import TwoPartComponent from './TwoPartComponent';
import gif from '../../../assets/images/GIF2.gif';
import { useEffect } from 'react';

const DataLink = () => {
    useEffect(() => {
        const showUp = () => {
            const first = document.querySelector(`.dlt-data-link__cards--no-bg`);
            const second = document.querySelector(`.dlt-data-link__cards--grid`);
            const firstContent = document.querySelectorAll(`.dlt-data-link__single-card--animate`);
            const secondContent = document.querySelectorAll(`.dlt-data-link__single-card--fixed`);

            let firstImagePosition = first?.getBoundingClientRect().top;
            let secondImagePosition = second?.getBoundingClientRect().top;

            let screenPosition = window.innerHeight / 1;
            let screenPositionLater = window.innerHeight / 1.5;

            if (firstImagePosition && firstImagePosition < screenPosition) {
                let i = 0;
                const myLoop = () => {
                    setTimeout(() => {
                        firstContent[i].classList.add('dlt-data-link__single-card--animate--show');
                        i++;
                        if (i < firstContent.length) {
                            myLoop();
                        }
                    }, 500);
                };
                myLoop();
            }

            if (secondImagePosition && secondImagePosition < screenPositionLater) {
                let i = 0;
                const myLoop = () => {
                    setTimeout(() => {
                        secondContent[i].classList.add('dlt-data-link__single-card--fixed--show');
                        i++;
                        if (i < secondContent.length) {
                            myLoop();
                        }
                    }, 500);
                };
                myLoop();
            }
        };

        window.addEventListener('scroll', showUp);
        return () => window.removeEventListener('scroll', showUp);
    }, []);

    return (
        <div className="dlt-data-link">
            <TwoPartComponent
                className="mx-50"
                firstPart={
                    <div className="dlt-data-link__main">
                        <h1 className="dlt-data-link__title">
                            Virgate gives you the information you need to make data-driven decisions confidently
                        </h1>
                        <p className="dlt-data-link__subtitle mt-100">
                            Asia's first dedicated analytics and data portal for institutional grade, commercial real
                            estate.
                        </p>
                        <p className="dlt-data-link__subtitle mt-20">
                            Unlock the full potential of your subscription data, by merging your data subscriptions
                            Virgate can provide you live building level insight. Combine the knowledge of all your data
                            sources to produce the best analytics in the industry. All the data vendors such as Real
                            Capital Analytics, Oxford Economics, REIS and many many others, are all great data vendors
                            in their own right.
                        </p>

                        <p className="dlt-data-link__subtitle mt-20 mb-20">
                            It’s not an either-or situation, all providers have their niche and value add elements, but
                            they don’t speak to each other to provide the client maximum advantage.{' '}
                        </p>
                        <p className="dlt-data-link__subtitle">
                            We find a way to connect external and internal data that will deliver maximum advantage to
                            our clients.
                        </p>
                    </div>
                }
                secondPart={
                    <div className="dlt-data-link__secondary d-flex align-items-center">
                        <img src={gif} alt="fist Gif" />
                    </div>
                }
            />

            <div className="dlt-data-link__cards d-flex mt-80">
                <div className="dlt-data-link__single-card d-flex flex-direction-column dlt-data-link__single-card--animate">
                    <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon={faStopwatch} className="dlt-data-link__single-card-icon" />
                    </div>
                    <h1 className="dlt-data-link__single-card-title mt-40">Real Time</h1>
                    <p className="dlt-data-link__single-card-subtitle">
                        Get fast answers to your underwriting assumptions, with consolidated and relevant data at the
                        click of a button.
                    </p>
                </div>
                <div className="dlt-data-link__single-card d-flex flex-direction-column dlt-data-link__single-card--animate">
                    <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon={faServer} className="dlt-data-link__single-card-icon" />
                    </div>
                    <h1 className="dlt-data-link__single-card-title mt-40">Standardisation</h1>
                    <p className="dlt-data-link__single-card-subtitle">
                        Gain data insights in a single format, no more having to manipulate data sets. All the
                        information you need is at your fingertips as and when you need it.
                    </p>
                </div>
                <div className="dlt-data-link__single-card d-flex flex-direction-column dlt-data-link__single-card--animate">
                    <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon={faChartLine} className="dlt-data-link__single-card-icon" />
                    </div>
                    <h1 className="dlt-data-link__single-card-title mt-40">Market Insights</h1>
                    <p className="dlt-data-link__single-card-subtitle">
                        We aggregate data from a variety of sources, so you can make the best decisions when buying and
                        selling commercial real estate.
                    </p>
                </div>
            </div>

            <div className="dlt-data-link__cards dlt-data-link__cards--grid dlt-data-link__cards--no-bg">
                <div className="dlt-data-link__single-card dlt-data-link__single-card--fixed d-flex flex-direction-column">
                    <h1 className="dlt-data-link__title">All the data you need at your fingertips </h1>
                    <p className="dlt-data-link__subtitle mt-100">
                        Virgate puts everything you need to know at your fingertips. Merge your entire data universe and
                        apply advanced data analytics and machine learning to gain the very best insights you need to
                        make data driven decisions confidently. We will do the heavy lifting, so you can empower your
                        team to further identify opportunities.
                    </p>
                </div>
                <div className="dlt-data-link__single-card dlt-data-link__single-card--fixed d-flex flex-direction-column">
                    <h1 className="dlt-data-link__title">An edge on your competitors</h1>
                    <p className="dlt-data-link__subtitle mt-100">
                        Gain deeper understanding of the market and link previously unknown variables to your
                        underwriting analysis. Build new powerful predictive scenario modeling to make faster and more
                        informed decisions. Virgate enables you to maximise the value of your subscription data ensuring
                        everything you’ll ever need is right there when you want it..
                    </p>
                </div>
                <div className="dlt-data-link__single-card dlt-data-link__single-card--fixed d-flex flex-direction-column">
                    <h1 className="dlt-data-link__title">Leverage internal data </h1>
                    <p className="dlt-data-link__subtitle mt-100">
                        Integrating the internal data from your organization will provide you with comparables and
                        insights that you may otherwise overlook. Organise, utilize and maximise the value of your data
                        in combination with external data to give yourself an edge on your competition. With Virgate you
                        will source off-market deals, unmask owners, research portfolios, identify key value drivers
                        across sectors and geographies, and perform deep analysis on market performance
                    </p>
                </div>
                <div className="dlt-data-link__single-card dlt-data-link__single-card--fixed d-flex flex-direction-column">
                    <h1 className="dlt-data-link__title">Take back control of your data</h1>
                    <p className="dlt-data-link__subtitle mt-100">
                        Leverage your connected data for automated reporting, performance monitoring, and benchmarking.
                        Reduce dependencies on manual workflows by streamlining operations and increasing scalability.
                        Become more nimble.
                    </p>
                </div>
            </div>
        </div>
    );
};
export default DataLink;
