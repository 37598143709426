import * as amcharts5 from '@amcharts/amcharts5';
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from '@amcharts/amcharts5/xy';
import { Col, Row, Table } from 'antd';
import { FunctionComponent, useLayoutEffect } from "react";
import { IUserAccessProperty } from "../../../../../interfaces/googleAnalytics/UserAccessProperty";

interface ITopUsersChartProps {
    dataSource: Array<IUserAccessProperty>;
}

const TopUsersChart: FunctionComponent<ITopUsersChartProps> = ({
    dataSource = []
}) => {
    const columnChartDivId = "columnChartDiv";
    const columns = [
        {
            title: 'User',
            dataIndex: 'fullName',
            key: 'fullName',
        },
        {
            title: 'Number of Access',
            dataIndex: 'numOfAccess',
            key: 'numOfAccess',
        },
    ];
    useLayoutEffect(() => {
        if (dataSource.length) {
            let root = amcharts5.Root.new(columnChartDivId);
            root.setThemes([
                am5themes_Animated.new(root)
            ]);

            let chart = root.container.children.push(am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                pinchZoomX: true
            }));

            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineY.set("visible", false);

            let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
            xRenderer.labels.template.setAll({
                centerY: amcharts5.p50,
                centerX: amcharts5.p100,
                paddingRight: 15
            });

            let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
                maxDeviation: 0.3,
                categoryField: "name",
                renderer: xRenderer,
                tooltip: amcharts5.Tooltip.new(root, {}),
            }) as any);

            let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
                maxDeviation: 0.3,
                renderer: am5xy.AxisRendererY.new(root, {}),
                maxPrecision: 0,
                min: 0
            }) as any);

            let series = chart.series.push(am5xy.ColumnSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                sequencedInterpolation: true,
                categoryXField: "name",
                tooltip: amcharts5.Tooltip.new(root, {
                    labelText: "{valueY}"
                })
            }) as any);

            series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
            series.columns.template.adapters.add("fill", function (fill, target) {
                if (!chart || !chart.get("colors")) {
                    return undefined;
                }

                return chart.get("colors")!.getIndex(series.columns.indexOf(target));
            });

            series.columns.template.adapters.add("stroke", function (stroke, target) {
                if (!chart || !chart.get("colors")) {
                    return undefined;
                }

                return chart.get("colors")!.getIndex(series.columns.indexOf(target));
            });

            let data = dataSource.map(item => ({
                name: item.fullName,
                value: item.numOfAccess
            }));

            xAxis.data.setAll(data);
            series.data.setAll(data);

            series.appear(1000);
            chart.appear(1000, 100);
            return () => root.dispose();
        }
    }, [dataSource])

    return (
        <div className='mt-50'>
            <p className='heading ml-20'>Top Users</p>
            <Row>
                <Col span={8}>
                    <Table columns={columns} dataSource={dataSource} pagination={false} />
                </Col>
                <Col span={16}>
                    <div id={columnChartDivId} style={{ width: '100%', height: '500px' }}></div>
                </Col>
            </Row>
        </div>
    );
}

export default TopUsersChart;