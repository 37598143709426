import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import SimpleImageSlider from 'react-simple-image-slider';
import TreeDotsComponent from './TreeDotsComponent';
import ModalConfirmDelete from '../../../../../components/shared/ModalConfirmDelete';
import useBoolean from '../../../../../hooks/use-boolean';
import FeatureState from '../../../../../context/state/FeatureState';

interface Image {
    url: '';
}

const GalleryTemplate = (props: any) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        [isThreeDotClicked, setIsThreeDotClicked] = useState<boolean>(false),
        [isModalOpened, setIsModalOpenedActions] = useBoolean(false);
    const history = useHistory();
    const data = JSON.parse(props.template.content);
    const title = data?.title;
    const images = data?.images;
    const imagesForSlider = images?.map((image: Image, index: number) => {
        return { url: `${image.url}` };
    });

    const templateEditHandler = () => {
        history.push(
            `/property/${externalId}/template/${props.pageType}/add-content/${props.template.pageSectionType}/${props.template.id}`
        );
    };

    const deleteHandler = useCallback(() => {
        props.onRemove(props.template.id);
        setIsModalOpenedActions.setFalse();
    }, [setIsModalOpenedActions, props]);

    return (
        <div className="row-clear gallery-template">
            {props.isEditMode && (
                <div className="col-xs-12">
                    <TreeDotsComponent
                        isThreeDotClicked={isThreeDotClicked}
                        setIsThreeDotClicked={setIsThreeDotClicked}
                        setIsModalOpened={setIsModalOpenedActions}
                        templateEditHandler={templateEditHandler}
                    />
                    <ModalConfirmDelete
                        text={t('f:property.confirmSectionDelete')}
                        isModalOpened={isModalOpened}
                        setIsModalOpenedActions={setIsModalOpenedActions}
                        handleFinish={deleteHandler}
                    />
                </div>
            )}
            {title && title.length > 0 && <h1 className="heading">{title}</h1>}
            <div className="row gallery-template__main mb-40">
                {images?.length > 0 &&
                    images?.map((image: Image, index: number) => {
                        return (
                            <div className="col-md-4 main-text" key={index}>
                                <figure>
                                    <img src={image.url} alt="Property summary single" />
                                </figure>
                            </div>
                        );
                    })}
            </div>
            <div className="row gallery-template__slider mb-40">
                {imagesForSlider && (
                    <SimpleImageSlider
                        showBullets={false}
                        showNavs={true}
                        width={320}
                        height={473}
                        images={imagesForSlider}
                        bgColor={'white'}
                    />
                )}
            </div>
        </div>
    );
};

export default GalleryTemplate;
