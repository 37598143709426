import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MainLoader from '../../../../../../components/shared/MainLoader';
import useBoolean from '../../../../../../hooks/use-boolean';
import PropertyAssetPageTemplateService from '../../../../../../services/PropertyAssetPageTemplate';

interface Detail {
    name: string;
    labelSuffix: string;
    value: string;
}
interface SingleAssetDetailsProps {
    propertyAssetId: string;
}
const SingleAssetDetails: FunctionComponent<SingleAssetDetailsProps> = ({ propertyAssetId }) => {
    const { t } = useTranslation(['f', 'b']),
        [details, setDetails] = useState<Array<Detail>>([]),
        [isLoading, isLoadingActions] = useBoolean();

    const propertyAsset = useCallback(async () => {
        try {
            const pageInfo = await PropertyAssetPageTemplateService.fetchPageInfo(
                'propertyassetpagesummary',
                propertyAssetId
            );
            const removeEmpty: Array<Detail> = [];
            pageInfo.forEach((elem) => {
                if (elem.value) removeEmpty.push(elem);
            });

            setDetails(removeEmpty);
            isLoadingActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t, propertyAssetId]);

    useEffect(() => {
        propertyAsset();
    }, [propertyAsset]);
    return (
        <div className="col-xs-12">
            {isLoading ? (
                <table className={`col-xs-12-clear transpose-table__wrap main-text mt-20 mb-20`}>
                    <tbody>
                        {details?.map((data: Detail, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{t(`b:property.${data.name}`)}{data.labelSuffix? ` ${data.labelSuffix}`: ''}</td>
                                    <td>{!data.value ? '' : data.value}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <MainLoader type="regular" mt="mt-100" mb="mb-100" />
            )}
        </div>
    );
};
export default SingleAssetDetails;
