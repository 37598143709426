export default class StackingCell {
    color: string;
    textColor: string;

    constructor(color:string, textColor:string ) {
        
        this.color = color;
        this.textColor =  textColor;
    
    }
}