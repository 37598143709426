import { FunctionComponent, useContext } from 'react';
import LogoPreview from './LogoPreview';
import LoaderPreview from './LoaderPreview';
import PreviewBars from './PreviewBars';
import PreviewButtons from './PreviewButtons';
import ThemeState from '../../../context/state/ThemeState';
import { DEFAULTTHEME } from '../../../constants/theme';

const PreviewContainer: FunctionComponent = () => {
    const { previewTheme } = useContext(ThemeState);
    return (
        <div className="col-xs-9-clear">
            <PreviewButtons
                buttonBgColor={previewTheme.colors['--main-bg-color']}
                buttonTextColor={previewTheme.colors['--main-text']}
                buttonBorderColor={previewTheme.colors['--buton-border-color']}
            />
            <PreviewBars
                assetBarsSelectedUnderline={previewTheme.colors['--main-bg-underline']}
                assetBarsUnderline={previewTheme.colors['--main-bg-underline']}
                assetBarsText={previewTheme.colors['--asset-bar-text']}
            />
            <LoaderPreview url={previewTheme.loader ? previewTheme.loader : DEFAULTTHEME.loader} type="regular" />

            <LogoPreview url={previewTheme.lightLogo ? previewTheme.lightLogo : DEFAULTTHEME.lightLogo} />
            <LogoPreview url={previewTheme.logo ? previewTheme.logo : DEFAULTTHEME.logo} />
        </div>
    );
};

export default PreviewContainer;
