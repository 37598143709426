import Form from 'antd/lib/form/Form';
import { Field, Formik } from 'formik';
import { FunctionComponent, useEffect, useState, useContext, useCallback } from 'react';
import SingleSelect from '../../../components/shared/SingleSelect';
import Company from '../../../interfaces/property/Company';
import BidInformationInterface from '../../../interfaces/property/BidInformations';
import { useTranslation } from 'react-i18next';
import BidService from '../../../services/BidService';
import NavigateState from '../../../context/state/NavigateState';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import LookUpContext from '../../../context/state/LookUpState';
import { BidCompanyValidation } from '../../../validations/bidWizzard/BidCompanyValidation';
import MainHeading from '../../../components/shared/MainHeading';
import MainLoader from '../../../components/shared/MainLoader';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';

interface Props {
    goToVentureCompanies: (id: string) => void;
}
interface RouteParams {
    id: string;
    biddingId?: string;
}

const CompanyDetails: FunctionComponent<Props> = ({ goToVentureCompanies }) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        { biddingId } = useParams<RouteParams>(),
        { countries } = useContext(LookUpContext),
        [biddingInformations, setBiddingInformations] = useState<BidInformationInterface>(),
        history = useHistory(),
        [isCompanyLoading, setisCompanyLoading] = useState(true),
        [isBiddingInformationLoading, setIsBiddingInformationLoading] = useState(false),
        [isSaving, setIsSaving] = useState(false),
        [myCompany, setMyCompany] = useState<Company>(),
        { cancelEverything } = useContext(NavigateState);

    const onSubmit = async (values: BidInformationInterface) => {
        let bidId = biddingId;
        if (!bidId) {
            bidId = await createNewBid(values);
        } else {
            await updateCompanyInformation(values, bidId);
        }

        if (bidId) {
            history.push(`bid-wizard/${externalId}/company-details/${bidId}`);
            //todo based on logic for portfolio
            // goToSelectBidInformations(bidId);
            goToVentureCompanies(bidId);
        }
    };

    const createNewBid = async (values: BidInformationInterface) => {
        try {
            setIsSaving(true);
            const newBid = await BidService.createBidding(`${propertyAssetId}`, values);
            setIsSaving(false);
            return newBid;
        } catch (error: any) {
            toast(t(`b:property.${error.response.data.errorMessage}`));
            setIsSaving(false);
        }
    };

    const updateCompanyInformation = async (values: BidInformationInterface, bidId: string) => {
        try {
            setIsSaving(true);
            await BidService.editCompanyInformation(`${propertyAssetId}`, bidId, values);
            setIsSaving(false);
        } catch (error: any) {
            setIsSaving(false);
            toast(t(`b:property.${error.response.data.errorMessage}`));
        }
    };

    const fetchMyCompany = useCallback(async () => {
        try {
            setisCompanyLoading(true);
            const myCompany = await BidService.fetchMyCompany();
            setMyCompany(myCompany);
            setisCompanyLoading(false);
        } catch (error: any) {
            toast.error(error.response);
        }
    }, []);

    const fetchBiddingInformations = useCallback(() => {
        if (biddingId) {
            setIsBiddingInformationLoading(true);
            BidService.fetchBiddingInformations(`${propertyAssetId}`, biddingId).then((bidding) => {
                setBiddingInformations(bidding);
                setIsBiddingInformationLoading(false);
            });
        }
    }, [propertyAssetId, biddingId]);

    useEffect(() => {
        fetchMyCompany();
    }, [fetchMyCompany]);

    useEffect(() => {
        if (myCompany) fetchBiddingInformations();
    }, [myCompany, fetchBiddingInformations]);

    return (
        <div className="company-details-page">
            <MainHeading text={t('f:bid.bidCompany')} />

            {!isCompanyLoading && !isBiddingInformationLoading ? (
                <div className="company-details-page__main">
                    <Formik
                        initialValues={{
                            companyName: myCompany ? myCompany.name : '',
                            spvName: biddingInformations ? biddingInformations.spvName : '',
                            spvCountryId: biddingInformations ? biddingInformations.spvCountryId : null,
                            spvAddress: biddingInformations ? biddingInformations.spvAddress : '',
                            spvPhoneNumber: biddingInformations ? biddingInformations.spvPhoneNumber : '',
                            spvRegistrationNumber: biddingInformations ? biddingInformations.spvRegistrationNumber : '',
                        }}
                        enableReinitialize={true}
                        validationSchema={BidCompanyValidation}
                        onSubmit={async (values: any) => {
                            await onSubmit(values);
                        }}
                    >
                        {({ errors, touched, setFieldValue, submitForm, isValid, dirty, values }) => (
                            <Form>
                                <div className="row-flex-clear">
                                    <label className="col-xs-12-clear mt-30">
                                        {t('f:bid.company-details-form.companyName')}
                                    </label>
                                    <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                        <Field
                                            id="text"
                                            type="text"
                                            disabled={true}
                                            name="companyName"
                                            placeholder={t('f:bid.company-details-form.entityName')}
                                        />
                                    </div>
                                </div>
                                <MainHeading text={t('f:bid.purchaseEntity')} addClasses="mt-40" />
                                <div className="row-clear">
                                    <div className="col-xs-12 col-md-6 col-xxl-4 form-group  form-group bid-info-page__form-cont mt-40">
                                        <label htmlFor="spvName">{`${t(
                                            'f:bid.company-details-form.entityName'
                                        )}*`}</label>
                                        <Field
                                            id="spvName"
                                            name="spvName"
                                            placeholder={t('f:bid.company-details-form.entityName')}
                                        />
                                        {errors.spvName && touched.spvName ? (
                                            <span className="bid-info-page__error-mess">
                                                {t(errors.spvName, {
                                                    name: t('f:bid.company-details-form.entityName'),
                                                })}
                                            </span>
                                        ) : null}
                                    </div>
                                    <div className="col-xs-12 col-md-6 col-xxl-4 form-group  form-group bid-info-page__form-cont mt-40">
                                        <SingleSelect
                                            label={`${t('f:bid.company-details-form.registeredCountry')}*`}
                                            placeholder={t('f:bid.company-details-form.registeredCountry')}
                                            selected={
                                                biddingInformations ? biddingInformations.spvCountryId : undefined
                                            }
                                            options={countries}
                                            onSelected={(value: any) => setFieldValue('spvCountryId', value)}
                                        />

                                        {errors && errors.spvCountryId && touched.spvCountryId ? (
                                            <span className="bid-info-page__error-mess">
                                                {t(errors.spvCountryId, {
                                                    name: t('f:bid.company-details-form.registeredCountry'),
                                                })}
                                            </span>
                                        ) : null}
                                    </div>

                                    <div className="col-xs-12 col-md-6 col-xxl-4 form-group  form-group bid-info-page__form-cont mt-40">
                                        <label htmlFor="spvAddress">{t('f:bid.company-details-form.address')}</label>
                                        <Field
                                            id="spvAddress"
                                            name="spvAddress"
                                            placeholder={t('f:bid.company-details-form.address')}
                                        />
                                    </div>

                                    <div className="col-xs-12 col-md-6 col-xxl-4 form-group  form-group bid-info-page__form-cont mt-40">
                                        <label htmlFor="spvPhoneNumber">
                                            {t('f:bid.company-details-form.phoneNumber')}
                                        </label>
                                        <Field
                                            id="spvPhoneNumber"
                                            name="spvPhoneNumber"
                                            placeholder={t('f:bid.company-details-form.phoneNumber')}
                                        />
                                    </div>

                                    <div className="col-xs-12 col-md-6 col-xxl-4 form-group  form-group bid-info-page__form-cont mt-40">
                                        <label htmlFor="spvRegistrationNumber">
                                            {t('f:bid.company-details-form.registerationNumber')}
                                        </label>
                                        <Field
                                            id="spvRegistrationNumber"
                                            name="spvRegistrationNumber"
                                            placeholder={t('f:bid.company-details-form.registerationNumber')}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                                    <Button
                                        onClick={() => {
                                            cancelEverything(externalId, PROPERTY_TABS.TRANSACTION.name);
                                        }}
                                        className="button-main button-secondary d-flex justify-content-center align-items-center"
                                    >
                                        {t('f:button.cancel')}
                                    </Button>

                                    <Button
                                        className={`button-main button-primary mr-25${
                                            !(isValid && dirty) ? ' disabled' : ''
                                        }`}
                                        type="primary"
                                        loading={isSaving}
                                        onClick={submitForm}
                                    >
                                        {t('f:button.next')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <MainLoader type="regular" />
            )}
        </div>
    );
};
export default CompanyDetails;
