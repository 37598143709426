import * as atlas from 'azure-maps-control';
import { memo, useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server'
import { FunctionComponent } from 'react';
import MapPropertyPopup from './MapPropertyPopup';
import IPropertyInfo from './iPropertyInfo';
import { PropertySectors } from './propertySectors';
import usePrevious from '../../../hooks/usePrevious';

(window as any).atlas = atlas;

interface Props {
    type: any,
    height: string;
    markerId: string;
    properties?: IPropertyInfo[];
    onMarkerClicked: (property: IPropertyInfo) => void;
}

const options = {
    authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: process.env.REACT_APP_AZURE_MAP_KEY,
    },
    center: [120, 6],
    zoom: 2.5,
    renderWorldCopies: true,
    showFeedbackLink: false,
    showLogo: false,
    view: 'Auto',
};

const dataSourceOptions = {
    cluster: true,
    clusterRadius: 45,
    clusterMaxZoom: 15,
};

function activator<T extends IPropertyInfo>(type: { new(): T; }, data: any): T {
    var property = new type();
    property.setData(data, 'null');

    return property;
}

const PropertiesMap: FunctionComponent<Props> = ({
    type,
    height,
    markerId,
    properties,
    onMarkerClicked,
}) => {
    let selectedMarker: atlas.HtmlMarker;
    const popup = new atlas.Popup({
        pixelOffset: [170, 0],
        closeButton: false,
        showPointer: false
    });

    const prevProperties = usePrevious(properties);
    const propertiesRef = useRef(properties);
    const [datasource, setDatasource] = useState<atlas.source.DataSource>();
    const [mapId, setMapId] = useState<string>();
    const [map, setMap] = useState<atlas.Map>();
    const mapRef = useRef(map);
    const [markerLayer, setMarkerLayer] = useState<any>();
    const [isJustClickOnMarker, setIsJustClickOnMarker] = useState<boolean>(false);

    const markerCallback = (_, position, properties) => {
        if (properties.cluster) {
            return new atlas.HtmlMarker({
                position: position,
                htmlContent: `<div class="map-cluster-marker">${properties.point_count_abbreviated}</div>`
            });
        }

        let color: string;
        switch (properties.primarySector) {
            case PropertySectors.office:
                color = '#fade5f';
                break;
            case PropertySectors.retail:
                color = '#acc451';
                break;
            case PropertySectors.hotel:
                color = '#419dcb';
                break;
            case PropertySectors.school:
                color = '#955d99';
                break;
            default:
                color = '#f0963e';
                break;
        }

        if (window.location.pathname.includes(properties.id)) {
            selectedMarker = new atlas.HtmlMarker({
                color,
                position
            });
            return Promise.resolve(selectedMarker);
        }

        return Promise.resolve(new atlas.HtmlMarker({
            color,
            position,
            htmlContent: `<div class="map-marker" style="background:${color}"/>`
        }));
    }

    const markerHovered = ({ target }) => {
        if (target.properties.cluster) {
            return;
        }

        const property = activator(type, target.properties);
        const content = renderToString(<MapPropertyPopup property={property} />);

        popup.setOptions({
            content,
            position: target.getOptions().position
        });

        popup.open(mapRef.current);
    }

    const hidePopup = () => {
        popup.close();
    }

    const unsetSelectedMarker = (newMarker) => {
        if (selectedMarker) {
            const markerOptions = selectedMarker.getOptions();
            selectedMarker.setOptions({
                ...markerOptions,
                htmlContent: `<div class="map-marker" style="background:${markerOptions.color}"/>`
            });
        }

        selectedMarker = newMarker;
    }

    const markerClicked = ({ target }) => {
        setIsJustClickOnMarker(true);

        if (target.properties.cluster) {
            const myMap = map || mapRef.current;
            const currentZoom = myMap!.getCamera().zoom || options.zoom;

            myMap!.setCamera({
                zoom: currentZoom + 2,
                center: target.options.position
            });

            return;
        }

        onMarkerClicked(target.properties);
        unsetSelectedMarker(target);
        target.setOptions({
            ...target.getOptions(),
            htmlContent: null
        })
    };

    const zoomToSelectedMarker = (useRef = false) => {
        const myMap = useRef ? mapRef.current! : map!;
        const myProperties = useRef ? propertiesRef.current : properties;

        if (!myProperties) {
            return;
        }

        var property = myProperties.find(x => x.id === markerId);
        if (!property) {
            return;
        }

        myMap.setCamera({
            zoom: 16,
            center: [property.lon, property.lat]
        });
    }

    const setupMarkerLayer = (myDatasource: atlas.source.DataSource, useRef = false) => {
        const myMap = useRef ? mapRef.current! : map!;
        const myProperties = useRef ? propertiesRef.current : properties;

        const layer = new (atlas.layer as any).HtmlMarkerLayer(myDatasource, mapId, { markerCallback });
        const events: any = myMap.events;

        events.add('mouseover', layer, markerHovered);
        events.add('mouseout', layer, hidePopup);
        events.add('click', layer, markerClicked);

        myMap.layers.add(layer);
        setMarkerLayer(layer);

        if (!myProperties || myProperties!.length === 0) {
            return;
        }

        myDatasource.add(new atlas.data.FeatureCollection(
            myProperties!.map(x => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [x.lon, x.lat]
                },
                properties: x
            } as any))
        ));

        zoomToSelectedMarker(useRef);
    }

    useEffect(() => {
        setMapId(`PropertiesMap${Math.random()}`);
    }, []);

    useEffect(() => {
        if (!mapId) {
            return;
        }

        const myDatasource = new atlas.source.DataSource(mapId, dataSourceOptions);
        setDatasource(myDatasource);

        const myMap = new atlas.Map(mapId, options);
        myMap.events.add('ready', () => {
            myMap.controls.add([
                new atlas.control.ZoomControl(),
                new atlas.control.CompassControl(),
                new atlas.control.PitchControl(),
                new atlas.control.StyleControl()
            ], {
                position: atlas.ControlPosition.TopRight,
            });

            myMap.sources.add(myDatasource);
            
            if (propertiesRef.current) {
                setupMarkerLayer(myDatasource, true);
            }
        });

        setMap(myMap);
        mapRef.current = myMap;
    }, [mapId]);

    useEffect(() => {
        if (isJustClickOnMarker) {
            setIsJustClickOnMarker(false);
            return;
        }

        if (!map || !properties) {
            return;
        }

        if (!markerId) {
            unsetSelectedMarker(null);
            return;
        }

        zoomToSelectedMarker();
    }, [markerId]);

    useEffect(() => {
        if (!map || !properties) {
            return;
        }

        if (prevProperties) {
            datasource!.clear();

            if (markerLayer) {
                map.layers.remove(markerLayer);
            }
        }

        if (!mapId || !map?.sources.getById(mapId!)) {
            propertiesRef.current = properties;
            return;
        }

        setupMarkerLayer(datasource!);
    }, [properties]);

    return (
        <div style={{ height, transition: 'height 0.75s' }}>
            <div id={mapId} style={{ height: '100%' }} />
        </div>
    );
};

export default memo(PropertiesMap);
