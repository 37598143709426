import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { CloseSquareOutlined, CaretDownOutlined } from '@ant-design/icons';
import buildingIcon from '../../../assets/images/building-icon.png';
import BuildingDirectoryInfo from '../models/buildingDirectoryInfo';
import BuildingDirectoryService from '../../../services/BuildingDirectoryService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import BuildingDirectoryCard from './BuildingDirectoryCard';

interface Props {
    onBuildingClicked: (building: BuildingDirectoryInfo) => void;
}

const SearchMenuPanel: FunctionComponent<Props> = ({ onBuildingClicked }) => {
    const { t } = useTranslation(['f', 'b']),
        [isShowTipBox, setIsShowTipBox] = useState<boolean>(true),
        [selectedCountry, setSelectedCountry] = useState<string>(),
        [keyword, setKeyword] = useState<string>(),
        [countries, setCountries] = useState<Array<string>>([]),
        [buildingDirectories, setBuildingDirectories] = useState<Array<BuildingDirectoryInfo>>([]);

    const fetchCountries = useCallback(async () => {
        try {
            const countries = await BuildingDirectoryService.fetchCountries();

            setCountries(countries);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    const fetchBuildingDirectories = useCallback(async (address?: string, lon?: number, lat?: number) => {
        try {
            const buildingDirectories = await BuildingDirectoryService.fetchBuildingDirectories(address, selectedCountry, lon, lat);

            setBuildingDirectories(buildingDirectories);
            setIsShowTipBox(false);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [selectedCountry]);

    useEffect(() => {
        fetchCountries();
    }, []);

    const onSearch = () => {
        let lon: number;
        let lat: number;

        var split = keyword?.split(' ');
        if (split?.length === 2) {
            lon = parseFloat(split[0]);
            lat = parseFloat(split[1]);

            if (lon !== NaN && lat !== NaN) {
                fetchBuildingDirectories(undefined, lon, lat);
                return;
            }
        }

        fetchBuildingDirectories(keyword || undefined);
    }

    const onInputKeyUp = (event) => {
        setKeyword(event.target.value.trim());

        if (event.key === 'Enter') {
            onSearch();
        }
    }

    return (
        <>
            <div className='search-title'>Search our Building Directory</div>
            <div className='search-description'>You can search property by address, coordinate and country.</div>
            <hr />
            <div className='bar'>
                <Select
                    showSearch
                    allowClear
                    suffixIcon={(<CaretDownOutlined />)}
                    className='country-select'
                    placeholder='Country...'
                    optionFilterProp='children'
                    onClear={() => setSelectedCountry(undefined)}
                    onSelect={v => setSelectedCountry(v)}
                    filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {countries.map(country => (
                        <Select.Option key={country} value={country}>{country}</Select.Option>
                    ))}
                </Select>
                <input className='searchbar' type='text' onSubmit={onSearch} onKeyUp={onInputKeyUp} placeholder="coordinate search example: longitude latitude" />
                <span className='searchicon' onClick={onSearch}><svg focusable='false' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'></path></svg></span>
            </div>

            <div className='search-result'>
                {buildingDirectories?.map(building => (
                    <BuildingDirectoryCard key={building.id} buildingDirectory={building} onClicked={onBuildingClicked} />
                ))}
            </div>

            {!isShowTipBox ? null : (
                <div className='tip-box'>
                    <div className='close-icon' onClick={() => setIsShowTipBox(false)}>
                        <CloseSquareOutlined />
                    </div>
                    <img className='image' src={buildingIcon} />
                    <div className='text'>
                        Welcome to the Virgate building directory. You are in the building specific section of our platform please search a spacific address. For market analytics plese click on the 'markets' tab.
                    </div>
                </div>
            )}
        </>
    );
};

export default SearchMenuPanel;
