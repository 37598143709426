import WizardWithSidebar from '../../components/shared/WizardWithSidebar';
import ChooseLayout from './components/ChooseLayout';
import { CreateEditTemplate } from './components/CreateEditTemplate';
import { Permissions } from './components/Permissions';
import { FunctionComponent, useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import RouteParams from '../../interfaces/property/CreatePropertySummaryRouteParams';
import PropertyAssetPageTemplates from '../../services/PropertyAssetPageTemplate';
import { wizardSteps } from '../../constants/wizardSteps';
import _ from 'lodash';
import DataAnalyticPageBuilderService from '../../services/DataAnalyticPageBuilderService';
import { toast } from 'react-toastify';
import TemplateContextState from '../../context/state/TemplateContextState';
import { chartTypes } from '../../constants/chartTypes';
import { findChartByName } from '../../constants/chartTypes';
import CheckAuthorization from '../StatusPages/CheckAuthorization';
import useBoolean from '../../hooks/use-boolean';
import ModalImportLoading from '../../components/shared/ModalImportLoading';
import FeatureState from '../../context/state/FeatureState';

const getTitleData = (data: any) => {
    const temp = document.createElement('div');
    temp.innerHTML = data;
    const title: any = temp.firstChild?.firstChild;

    return title.nodeValue;
};

export const TemplateWizard: FunctionComponent<any> = () => {
    const { t } = useTranslation(['f', 'b']),
        {
            chartData,
            chartTitle,
            setChart,
            setChartTitle,
            lines,
            newChartData,
            chartType,
            setChartType,
            newChartSource,
            chartDataRdy,
            newChartTitle,
            setChartSource,
            isForEdit,
            isForEditActions,
            chartSource,
        } = useContext(TemplateContextState),
        [title, setTitle] = useState<string>(
            localStorage.getItem('titleContent') ? getTitleData(localStorage.getItem('titleContent')) : ''
        ),
        [imageUrl, setImageUrl] = useState<string>(''),
        [disableDoubleClick, disableDoubleClickActions] = useBoolean();

    const [content, setContent] = useState<any>(localStorage.getItem('content') ? localStorage.getItem('content') : '');
    const [permission, setPermission] = useState({
        sortOrder: 1,
        afterNdaExecuted: false,
    });
    const history = useHistory();
    const { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        params = useParams<RouteParams>();

    const fetchTemplate = useCallback(async () => {
        if (params.propertyAssetPageSummaryId) {
            //chart?14:5
            const paramsData = params.propertyAssetPageSummaryId.split('-');

            if (paramsData[0] === 'chart') {
                //section ID and SectionDataID
                const mainInfo = paramsData[1].split(':');
                isForEditActions.setTrue();

                const _data = await DataAnalyticPageBuilderService.featchSectionsData(
                    `${propertyAssetId}`,
                    `${mainInfo[0]}`,
                    `${mainInfo[1]}`
                );

                const convertJson = await JSON.parse(_data.jsonData);
                setContent(convertJson.content);

                setTitle(getTitleData(convertJson.title));
                setChartTitle(convertJson.chartTitle);
                setChartSource(_data.source);
                setChart(convertJson.chart);
                setChartType(findChartByName(_data.dataTypeFormat));
            } else {
                const templateContent = await PropertyAssetPageTemplates.fetchTemplate(
                    params.pageType,
                    `${propertyAssetId}`,
                    params.propertyAssetPageSummaryId
                );
                if (
                    params.templateKey === 'right-heading-editor' ||
                    params.templateKey === 'left-heading-editor' ||
                    params.templateKey === 'single-image' ||
                    params.templateKey === 'geo-map' ||
                    params.templateKey === 'video'
                ) {
                    let div = document.createElement('div');
                    div.innerHTML = templateContent.content;

                    const titleElement = div.querySelector('.templates-main-title');

                    div.childNodes.forEach((el: any) => {
                        if (el.nodeName === 'FIGURE') {
                            setImageUrl(el.firstChild.src);
                            div.removeChild(el);
                        }
                    });

                    if (titleElement) {
                        setTitle(titleElement.innerHTML);
                        div.removeChild(titleElement);
                    }

                    setContent(div.innerHTML);
                } else {
                    setContent(templateContent.content);
                }
                setPermission({
                    sortOrder: templateContent.sortOrder,
                    afterNdaExecuted: templateContent.afterNdaExecuted,
                });
            }
        }
    }, []);

    useEffect(() => {
        fetchTemplate();
    }, [fetchTemplate]);

    const slectedTemplate = (value: number) => {
        isShownActions.setFalse();

        history.push(`/property/${externalId}/template/${params.pageType}/add-content/${value}`);
    };

    const setTemplateContent = (value: string) => {
        setContent(value);
        localStorage.setItem('content', value);
    };

    const setTitleContent = () => {
        if (title !== '') {
            const content = `<h2 class="templates-main-title mb-40 mt-10 markdown__fon-pading">${title}</h2>`;
            localStorage.setItem('titleContent', content);
        }
    };

    const contentAdded = () => {
        if (params.templateKey === 'right-heading-editor' || params.templateKey === 'left-heading-editor') {
            if (!imageUrl) {
                toast.error(t('f:error.requierImage'));
                return;
            }
        }
        setTitleContent();
        if (checkIsItChart()) {
            if (!chartDataRdy) {
                toast.error('Save chart data!');
                return;
            }
        }
        isShownActions.setFalse();

        if (params.propertyAssetPageSummaryId) {
            history.push(
                `/property/${externalId}/template/${params.pageType}/permissions/${params.templateKey}/${params.propertyAssetPageSummaryId}`
            );
        } else {
            history.push(`/property/${externalId}/template/${params.pageType}/permissions/${params.templateKey}`);
        }
    };

    const addTemplate = useCallback(
        async (data: any) => {
            if (disableDoubleClick) return;

            try {
                if (params.propertyAssetPageSummaryId) {
                    await PropertyAssetPageTemplates.updateTemplate(params.pageType, data);
                } else {
                    await PropertyAssetPageTemplates.createTemplate(params.pageType, data);
                }
                let activeTab = params.pageType === 'propertyassetresearch' ? '#research' : '#property-summary';
                history.push(`/property/${externalId}` + activeTab);
                localStorage.removeItem('content');
                localStorage.removeItem('imageContent');
                localStorage.removeItem('titleContent');
                disableDoubleClickActions.setFalse();
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
                disableDoubleClickActions.setFalse();
            }
        },
        [disableDoubleClick]
    );

    const prepareData = (data: any) => {
        let content = '';
        if (
            params.templateKey === 'right-heading-editor' ||
            params.templateKey === 'left-heading-editor' ||
            params.templateKey === 'single-image' ||
            params.templateKey === 'geo-map' ||
            params.templateKey === 'video'
        ) {
            if (params.templateKey === 'single-image' || params.templateKey === 'video') {
                content = `${localStorage.getItem('titleContent') ? localStorage.getItem('titleContent') : ''}${
                    localStorage.getItem('imageContent') ? localStorage.getItem('imageContent') : ''
                }${localStorage.getItem('content') ? localStorage.getItem('content') : ''}`;
            } else {
                content = `${localStorage.getItem('imageContent') ? localStorage.getItem('imageContent') : ''}${
                    localStorage.getItem('titleContent') ? localStorage.getItem('titleContent') : ''
                }${localStorage.getItem('content') ? localStorage.getItem('content') : ''}`;
            }
        } else {
            content = `${localStorage.getItem('titleContent') ? localStorage.getItem('titleContent') : ''}${
                localStorage.getItem('content') ? localStorage.getItem('content') : ''
            }`;
        }

        let template: any = {
            propertyAssetId: `${propertyAssetId}`,
            sortOrder: data.sortOrder,
            pageSectionType: params.templateKey,
            content: content,
            afterNdaExecuted: data.afterNdaExecuted,
        };

        if (params.propertyAssetPageSummaryId) {
            template.id = params.propertyAssetPageSummaryId;
        }

        return template;
    };

    const checkIsItChart = () => {
        return params.templateKey === 'right-heading-chart' || params.templateKey === 'left-heading-chart'
            ? true
            : false;
    };

    const createSectionAndData = async (data: any) => {
        const main = {
            title: localStorage.getItem('titleContent') ? localStorage.getItem('titleContent') : '',
            content: localStorage.getItem('content') ? localStorage.getItem('content') : '',
            chartTitle: chartTitle,
            lines: lines,
            chart: chartData,
        };
        try {
            if (isForEdit) {
                main.chart = newChartData;
                main.chartTitle = newChartTitle;
                const paramsData = params.propertyAssetPageSummaryId.split('-');

                //section ID and SectionDataID
                const mainInfo = paramsData[1].split(':');
                const dataBody = {
                    id: mainInfo[1],
                    jsonData: JSON.stringify(main),
                    pageSectionId: mainInfo[0],
                    source: newChartSource,
                    dataTypeFormat: _.find(chartTypes, { id: chartType })?.name,
                    dataSourceType: 1,
                };

                await DataAnalyticPageBuilderService.editSectionData(
                    `${propertyAssetId}`,
                    mainInfo[0],
                    mainInfo[1],
                    dataBody
                );
            } else {
                const body = {
                    pageId: `${propertyAssetId}`,
                    pageSectionType: 'Chart',
                    content:
                        params.templateKey === 'right-heading-chart' ? 'right-heading-chart' : 'left-heading-chart',
                    sortOrder: data.sortOrder,
                };

                const _sectionId = await DataAnalyticPageBuilderService.createSection(`${propertyAssetId}`, body);

                const dataBody = {
                    pageId: `${propertyAssetId}`,
                    pageSectionId: _sectionId,
                    jsonData: JSON.stringify(main),
                    source: chartSource,
                    dataTypeFormat: _.find(chartTypes, { id: chartType })?.name,
                    dataSourceType: 1,
                };

                await DataAnalyticPageBuilderService.createSectionData(`${propertyAssetId}`, _sectionId, dataBody);
            }
            localStorage.removeItem('content');
            localStorage.removeItem('titleContent');
            history.push('/data-analytics');
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    const formCompleted = (value: any) => {
        if (checkIsItChart()) {
            createSectionAndData(value);
        } else {
            disableDoubleClickActions.setTrue();
            addTemplate(prepareData(value));
        }
    };

    const tabs = [
        {
            id: wizardSteps.first,
            name: t('f:propertySummary.layoutSelection'),
            component: <ChooseLayout onSelect={slectedTemplate} pageType={params.pageType} />,
            step: 'select-layout',
        },
        {
            id: wizardSteps.second,
            name: t('f:propertySummary.addContent'),
            component: (
                <CreateEditTemplate
                    title={title}
                    setTitle={setTitle}
                    setImageUrl={setImageUrl}
                    imageUrl={imageUrl}
                    setContent={setTemplateContent}
                    contentAdded={contentAdded}
                    contentState={{ state: content, setter: setContent }}
                />
            ),
            step: 'add-content',
        },
        {
            id: wizardSteps.third,
            name: t('f:propertySummary.permissions'),
            component: (
                <Permissions
                    setPermisions={formCompleted}
                    permissionState={{ state: permission, setter: setPermission }}
                    propertyAssetId={`${propertyAssetId}`}
                />
            ),
            step: 'permissions',
        },
    ];

    const [isShown, isShownActions] = useBoolean();

    useEffect(() => {
        const activeStep: any = _.find(tabs, { step: params.stepName });
        setActiveTab(activeStep.id.toString());
        isShownActions.setTrue();
    }, [params.stepName]);

    // const activeStep: any = _.find(tabs, { step: params.stepName });
    const [activeTab, setActiveTab] = useState(wizardSteps.first.toString());

    return (
        <>
            <CheckAuthorization>
                {isShown && <WizardWithSidebar tabs={tabs} activeTab={activeTab} />}
            </CheckAuthorization>
            <ModalImportLoading text={t(`f:loaders.uploading`)} isModalOpened={disableDoubleClick} />
        </>
    );
};
