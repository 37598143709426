import IPropertyInfo from "../../../components/shared/PropertiesMap/iPropertyInfo";

export default class BuildingDirectoryInfo implements IPropertyInfo {
    id: string = '';
    lat: number = 0;
    lon: number = 0;
    nla: number | null = null;

    private ps: string | null = null;
    get primarySector(): string | null {
        return this.ps;
    }

    private pn: string | null = null;
    get propertyName(): string | null {
        return this.pn;
    }

    private pa: string | null = null;
    get propertyAddress(): string | null {
        return this.pa;
    }

    private yb: number | null = null;
    get yearBuilt(): number | null {
        return this.yb;
    }

    private lt: string | null = null;
    get landTenure(): string | null {
        return this.lt;
    }

    private la: number | null = null;
    get landArea(): number | null {
        return this.la;
    }

    private pp: number | null = null;
    get parkingPlaces(): number | null {
        return this.pp;
    }

    private ss: string | null = null;
    get secondarySector(): string | null {
        return this.ss;
    }

    private te: string | null = null;
    get tenancy(): string | null {
        return this.te;
    }

    private pic: string | null = null;
    get photo(): string | null {
        return this.pic;
    }

    constructor(data?: any) {
        if (data) {
            this.setData(data);
        }
    }

    setData(data: any, nullString?: string | undefined): void {
        for (const key in this) {
            this[key] = nullString && data[key] === nullString ? null : data[key];
        }
    }
}