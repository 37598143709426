import api from '../../index';

const fetchCompanyCountries = (companyId: number) => {
    return api.get(`admin/page-permission/company-country/${companyId}`);
}

const fetchCompanyPermission = (companyId: number) => {
    return api.get(`admin/page-permission/company/${companyId}`);
}

const fetchUserPermission = (userId: string) => {
    return api.get(`admin/page-permission/user/${userId}`);
}

const updateUserPermission = (body: any) => {
    console.log(body);
    return api.put(`admin/page-permission/user`, body);
}

const updateCompanyPermission = (body: any) => {
    console.log(body);
    return api.put(`admin/page-permission/company`, body);
}

export default {
    fetchCompanyCountries,
    fetchCompanyPermission,
    fetchUserPermission,
    updateUserPermission,
    updateCompanyPermission
}