import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { getToken } from '../../auth/UserService';
import { MainThemeData } from '../../interfaces/theme';
const api = axios.create({
    baseURL: process.env.REACT_APP_THEME_API_URL,
    headers: {
        common: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    },
});
const BLOB = axios.create({
    baseURL: process.env.REACT_APP_BLOB_THEME_API_URL,
    headers: {
        common: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    },
});

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = getToken();

    if (token && token !== '') {
        config.headers.common = {
            ...config.headers.common,
            Authorization: `Bearer ${token}`,
        };
    }

    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[response error] [${JSON.stringify(error)}]`);

    return Promise.reject(error);
};

api.interceptors.request.use(onRequest, onRequestError);

/*List Themes */
const fetchThemes = (companyId: string) => {
    return api.get(`/theme-list?companyId=${companyId}`);
};

/*Get Activ Company Theme */
const fetchCompanyTheme = () => {
    return api.get('/theme');
};

/*Get Theme by Id */
const fetchThemeById = (themeId: string) => {
    return api.get(`/theme/${themeId}`);
};

/*Create Theme*/
const createTheme = (body: MainThemeData) => {
    return api.post('/theme',
        {
            name: body.name,
            value: JSON.stringify(body.value),
            companyId: body.companyId
        }
    );
};

/*Publish Theme */
const publishTheme = (themeId: string) => {
    return api.put(`/theme/publish/${themeId}`);
};

/*Delete Theme*/
const deleteTheme = (themeId: string) => {
    return api.delete(`/theme/${themeId}`);
};

/*Restore Theme*/
const restoreToDefault = (companyId: string) => {
    return api.post(`/theme/restore/${companyId}`);
};

/* Fetch Blob*/
const fetchBlobTheme = (id: string, uid: string) => {
    return BLOB.get(`/${id}/${uid}`);
};

/* Img Upload */
const uploadImage = (options: any, data: any) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'text/plain',
        },
    };

    return api.post(options.action, data, onRequest(config));
};

/* Change theme */
const updateTheme = (themeId: string, body: MainThemeData) => {
    return api.put(`/theme/${themeId}`, { name: body.name, value: JSON.stringify(body.value) });
};

const ThemeAPI = {
    fetchThemes,
    createTheme,
    fetchThemeById,
    publishTheme,
    fetchCompanyTheme,
    uploadImage,
    fetchBlobTheme,
    deleteTheme,
    updateTheme,
    restoreToDefault,
};

export default ThemeAPI;
