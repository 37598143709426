import { FunctionComponent } from 'react';
import { Button } from 'antd';
import MainButton from './MainButton';
interface CreateDataForEmptySectionProps {
    handler: () => void;
    text: string;
    mainText: string;
    addClassToContainer?: string;
}
const CreateDataForEmptySection: FunctionComponent<CreateDataForEmptySectionProps> = ({
    handler,
    text,
    addClassToContainer,
    mainText,
}: CreateDataForEmptySectionProps) => (
    <div className={`empty-section ${addClassToContainer}`}>
        <div className="d-flex align-items-center justify-content-space-around">
            <span className="empty-section__content" onClick={handler}>
                <Button>
                    <div className="">
                        <MainButton layout="primary" size="main" buttonText={text} classAdditions="empty-section__button"/>
                        <span className="empty-section__main-text">{mainText}</span>
                    </div>
                </Button>
            </span>
        </div>
    </div>
);

export default CreateDataForEmptySection;
