import { FunctionComponent } from 'react';
import ImageContainer from './ImageContainer';
import { Button, Tooltip, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import ThemeService from '../../../services/ThemeService';
import { toast } from 'react-toastify';

interface UploadContainerProps {
    values: any;
    name: string;
    setFieldValue: (name: string, val: any) => void;
}

const UploadContainer: FunctionComponent<UploadContainerProps> = ({
    values,
    setFieldValue,
    name,
}: UploadContainerProps) => {
    const { t } = useTranslation(['f', 'b']);

    const customRequest = async (options: any, setFieldValue, name: string) => {
        try {
            const data = new FormData();
            data.append('file', options.file);
            const resp = await ThemeService.uploadImage(options, data);
            setFieldValue(name, resp);
        } catch (error: any) {
            toast.error(t(`b:${error.response.data.errorMessage}`));
        }
    };
    return (
        <div className="col-xs-12-clear d-flex flex-direction-column gap-10 mb-40">
            <label htmlFor={name} className="ml-40 mt-20">
                {t(`f:theme-manager.${name}`)}
            </label>

            <div className="col-xs-12-clear ml-40">
                {values[name] ? (
                    <ImageContainer src={values[name]} setFieldValue={setFieldValue} name={name} />
                ) : (
                    <Upload
                        name="Image"
                        className="add-property-image-builder__upload"
                        showUploadList={false}
                        action={process.env.REACT_APP_THEME_API_URL + `/theme/asset`}
                        customRequest={(options: any) => {
                            customRequest(options, setFieldValue, name);
                        }}
                    >
                        <Tooltip
                            placement="bottomRight"
                            color="#fd615a"
                            title={t('f:property.photoDimensionRecommendedLogo')}
                        >
                            <Button>
                                <p className="teams-export__icon">{t('f:button.upload')}</p>
                            </Button>
                        </Tooltip>
                    </Upload>
                )}
            </div>
        </div>
    );
};

export default UploadContainer;
