import { FunctionComponent, useContext, useState } from 'react';
import { wizardSteps, BiddingStructureStepNames } from '../../constants/wizardSteps';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';
import WizardWithSidebar from '../../components/shared/WizardWithSidebar';
import BiddingStructure from './components/BiddingStructure';
import CheckAuthorization from '../StatusPages/CheckAuthorization';
import FeatureState from '../../context/state/FeatureState';

interface RouteParams {
    stepName: string;
    id: string;
    edit?: string;
}

const BiddingStructureWizard: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']);
    const params = useParams<RouteParams>(),
        { propertySummary } = useContext(FeatureState),
        history = useHistory();

    const cancelEverything = () => {
        history.push(`/property/${propertySummary.externalId}#transaction`);
    };

    const returnToPreviousPage = () => {
        history.goBack();
    };

    const tabs = [
        {
            id: wizardSteps.first,
            step: BiddingStructureStepNames.SETTINGS,
            name: t('f:listOpportunity.bidSettings'),
            component: (
                <BiddingStructure cancelEverything={cancelEverything} returnToPreviousPage={returnToPreviousPage} />
            ),
        },
    ];

    const findActiveTab = () => {
        let activeTab: any = _.find(tabs, function (tab) {
            if (tab.step) {
                return tab.step === params.stepName;
            }
        });
        if (activeTab) {
            return activeTab.id.toString();
        }
        return wizardSteps.first;
    };

    const [activeTab] = useState(findActiveTab());

    const findActiveSubTab = () => {
        let active: any = _.find(tabs, { id: parseInt(activeTab) });
        if (active && active.subtabs) {
            return _.find(active.subtabs, { step: params.stepName });
        }
        return null;
    };

    const [activeSubtab] = useState<any>(findActiveSubTab());
    return (
        <CheckAuthorization>
            <WizardWithSidebar tabs={tabs} activeTab={activeTab} activeSubtab={activeSubtab} />
        </CheckAuthorization>
    );
};
export default BiddingStructureWizard;
