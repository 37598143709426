export interface InvestorUser {
    fullName: string;
    companyName: string;
    ndaStatus: number;
    bidStatus: number;
    id: string;
}

export interface InitUserI {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
}

export const initUser: InitUserI = {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
};
