import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainTreeDots from '../../../components/shared/MainTreeDots';
import { SubStatusEnum } from '../../../constants/subStatus';
import { Actions } from '../../../interfaces/general/general';
import { OverviewTopCards } from '../../../interfaces/property/OverviewPropertyAssetSearch';
import PropertyAsserts from '../../../interfaces/property/PropertyAsserts';

interface DotsContainerProps {
    property: PropertyAsserts;
    isConfirmDeleteModalOpenActions: Actions;
    canUnlist?: boolean;
    unlistHandler?: () => void;
    overviewType: OverviewTopCards;
    rejectHandler?: () => void;
}
const DotsContainer: FunctionComponent<DotsContainerProps> = ({
    property,
    isConfirmDeleteModalOpenActions,
    unlistHandler = () => {},
    canUnlist = false,
    overviewType,
    rejectHandler = () => {},
}: DotsContainerProps) => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory();
    let actionsForMenu: Array<any>;

    switch (overviewType) {
        case OverviewTopCards.ACQUISITIONS:
            actionsForMenu =
                property.subStatus === SubStatusEnum.Invited
                    ? [
                          {
                              text: t('button.reject'),
                              handler: rejectHandler,
                          },
                      ]
                    : [];
            break;

        default:
            actionsForMenu = [
                {
                    text: t('button.edit'),
                    handler: () => {
                        history.push(`/property/${property.id}/edit`);
                    },
                },
                {
                    text: t('button.delete'),
                    handler: () => {
                        isConfirmDeleteModalOpenActions.setTrue();
                    },
                },
            ];

            if (canUnlist) {
                actionsForMenu = [
                    {
                        text: t('button.unlist'),
                        handler: unlistHandler,
                    },
                    ...actionsForMenu,
                ];
            }
            break;
    }

    return <MainTreeDots actionsMenu={actionsForMenu} />;
};
export default DotsContainer;
