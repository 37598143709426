import { FunctionComponent } from 'react';
import useBoolean from '../../../../hooks/use-boolean';
import { Actions } from '../../../../interfaces/general/general';
import SignNDAModalContent from '../transactionComponents/SignNDAModalContent';
import ModalCloseButton from '../../../../components/shared/ModalCloseButton';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';
interface SignNDAModalProps {
    isModalOpened: boolean;
    setIsModalOpened: Actions;
}

const SignNDAModal: FunctionComponent<SignNDAModalProps> = ({ isModalOpened, setIsModalOpened }) => {
    const [showMainContent, setShowMainContent] = useBoolean();

    const handleClosing = () => {
        setIsModalOpened.setFalse();
        setShowMainContent.setFalse();
        const modal = document.querySelector('.nda-options-modal');
        const content: any = modal?.querySelector('.ant-modal-content');
        if (content) {
            content.style.minHeight = `${500}px`;
            content.style.maxHeight = `${550}px`;
        }
    };

    return (
        <ModalWrapper
            isCloseModalShown={true}
            className="nda-options-modal"
            isFooterShown={false}
            closeModalHandler={handleClosing}
            isModalOpened={isModalOpened}
        >
            <ModalCloseButton handler={handleClosing} />

            <SignNDAModalContent
                setIsModalOpen={setIsModalOpened}
                setShowMainContent={setShowMainContent}
                showMainContent={showMainContent}
            />
        </ModalWrapper>
    );
};
export default SignNDAModal;
