import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import DeallineWizardState from './state/DeallineWizardState';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ListOpportunityService from '../services/ListOpportunityService';
import { IMarketingTimeline } from '../interfaces/listOpporunity/ListOpportunity';
import { useHistory } from 'react-router-dom';
import useBoolean from '../hooks/use-boolean';
import FeatureState from './state/FeatureState';

interface DeallineWizardContextProps {
    children: React.ReactNode;
}

const init = {
    stage: '',
    text: '',
    startDate: '',
    closeDate: '',
};

const DeallineWizardContext: FunctionComponent<DeallineWizardContextProps> = ({
    children,
}: DeallineWizardContextProps) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        [isAddingOrDeletingActionLoading, isAddingOrDeletingActionLoadingActions] = useBoolean(),
        [isAllStagesLoad, isAllStagesLoadActions] = useBoolean(),
        history = useHistory(),
        [allStages, setAllStages] = useState<Array<IMarketingTimeline>>([]),
        [stages, setStages] = useState<Object>({});

    const fetchListOpportunityStage = useCallback(async () => {
        try {
            const stages = await ListOpportunityService.fetchListOpportunityStage();
            setStages(stages);
        } catch (error: any) {
            toast.error(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    const fetchMarketingTimeline = useCallback(async () => {
        try {
            const marketingTimelineStages = await ListOpportunityService.fetchMarketingTimeline(`${propertyAssetId}`);

            setAllStages(marketingTimelineStages.length ? marketingTimelineStages : [init]);
            isAllStagesLoadActions.setTrue();
            isAddingOrDeletingActionLoadingActions.setFalse();
        } catch (error: any) {
            toast.error(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId]);

    const createMarketingTimeline = useCallback(
        async (data: IMarketingTimeline) => {
            try {
                await ListOpportunityService.createMarketingTimeline(`${propertyAssetId}`, data);
            } catch (error: any) {
                toast.error(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [propertyAssetId, t]
    );

    const editMarketingTimeline = useCallback(
        async (data: IMarketingTimeline) => {
            try {
                const { stageId, ...rest } = data;
                await ListOpportunityService.updateStage(`${propertyAssetId}`, stageId, rest);
            } catch (error: any) {
                toast.error(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [propertyAssetId, t]
    );

    const removeMarketingTimeline = useCallback(
        async (stageId: string) => {
            try {
                await ListOpportunityService.removeStage(`${propertyAssetId}`, stageId);
                fetchMarketingTimeline();
            } catch (error: any) {
                toast.error(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [propertyAssetId, t, fetchMarketingTimeline]
    );

    const handleFinishButton = useCallback(
        async (val: Array<IMarketingTimeline>) => {
            try {
                await ListOpportunityService.bulkUpdateMarketingTimeline(`${propertyAssetId}`, val);
                history.push(`/property/${externalId}#property-summary`);
            } catch (error: any) {
                toast.error(t(`b:${error.response.data.errorMessage}`));
            }
            isAddingOrDeletingActionLoadingActions.setFalse();
        },
        [propertyAssetId]
    );

    useEffect(() => {
        fetchListOpportunityStage();
        fetchMarketingTimeline();
    }, [fetchListOpportunityStage, fetchMarketingTimeline]);

    useEffect(() => {}, []);

    return (
        <DeallineWizardState.Provider
            value={{
                stages,
                allStages,
                handleFinishButton,
                removeMarketingTimeline,
                isAddingOrDeletingActionLoading,
                isAddingOrDeletingActionLoadingActions,
                isAllStagesLoadActions,
                isAllStagesLoad,
            }}
        >
            {children}
        </DeallineWizardState.Provider>
    );
};

export default DeallineWizardContext;
