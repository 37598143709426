import { Formik, Field, Form, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import TemplateContextState from '../../../context/state/TemplateContextState';
import { useContext } from 'react';
import { validateLineChartSchema } from '../../../validations/ValidateLineChartSchema';
import { DatePicker } from 'antd';
import { FormikErrorArrayDeep, FormikChartArrayError, FormikErrorTwoArrayDeep } from './FormikError';
import { LineChartInterfaceShort } from '../../../interfaces/chart/ChartInterface';
import { LineInitInterface } from '../../../interfaces/chart/ChartInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import varibales from '../../../assets/styles/general/_variable.module.scss';
import { antDatePickerSwitch } from '../../../constants/chartTypes';

const initDates: Array<string> = ['', ''];
const initLanes: Array<LineChartInterfaceShort> = [
    {
        name: '',
        dots: [
            { date: '', value: 0 },
            { date: '', value: 0 },
        ],
    },
    {
        name: '',
        dots: [
            { date: '', value: 0 },
            { date: '', value: 0 },
        ],
    },
    {
        name: '',
        dots: [
            { date: '', value: 0 },
            { date: '', value: 0 },
        ],
    },
];
const initialValues: LineInitInterface = {
    dates: initDates,
    lines: initLanes,
};

const LineChartForm = () => {
    const { t } = useTranslation(['f', 'b']),
        { handleLineFinish, setChartDataActions, setIsPreviewShow, dateType } = useContext(TemplateContextState);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validateLineChartSchema}
            onSubmit={(values) => {
                handleLineFinish(values.lines);
                setChartDataActions.setTrue();
            }}
            render={({ values, errors, touched, handleReset, setFieldValue }) => {
                return (
                    <Form>
                        <div className="chart__overflow col-xs-12-clear">
                            <table className="chart-table chart__overflow col-xs-12-clear">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className="mb-10 mt-10">{t('f:chart.table.chartLine')} </p>
                                        </td>
                                    </tr>
                                    <FieldArray
                                        name="dates"
                                        render={({ insert, remove, push }) => (
                                            <tr>
                                                <td>
                                                    <label htmlFor="" className="col-xs-12">
                                                        {t('f:chart.table.date')}
                                                    </label>
                                                </td>
                                                {values.dates.length > 0 &&
                                                    values.dates.map((friend, index) => (
                                                        <td>
                                                            <label htmlFor="" className="col-xs-12"></label>
                                                            <div className="mb-20 position-relative">
                                                                <DatePicker
                                                                    picker={antDatePickerSwitch(dateType)}
                                                                    onChange={(
                                                                        value: moment.Moment | null,
                                                                        dateString: string
                                                                    ) => {
                                                                        const new_dates = values.dates;
                                                                        new_dates[index] = dateString;

                                                                        if (values.lines.length) {
                                                                            const new_lines = values.lines;
                                                                            new_lines.map(
                                                                                (
                                                                                    elem: LineChartInterfaceShort,
                                                                                    i: number
                                                                                ) => {
                                                                                    elem.dots[index].date = dateString;
                                                                                }
                                                                            );
                                                                            setFieldValue('lines', new_lines);
                                                                        }

                                                                        setFieldValue('dates', new_dates);
                                                                    }}
                                                                    name={`dates.${index}`}
                                                                />
                                                                <FormikChartArrayError
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    index={index}
                                                                    array="dates"
                                                                />
                                                            </div>
                                                        </td>
                                                    ))}
                                                <td className="d-flex flex-direction-column">
                                                    <FontAwesomeIcon
                                                        className="mt-15 mr-20 ml-20"
                                                        icon={faPlus}
                                                        color={varibales.green}
                                                        onClick={() => {
                                                            const new_dates = values.dates;
                                                            new_dates.push('');
                                                            if (values.lines.length) {
                                                                const new_lines = [...values.lines];
                                                                new_lines.map((el: LineChartInterfaceShort) => {
                                                                    el.dots.push({
                                                                        date: '',
                                                                        value: 0,
                                                                    });
                                                                });
                                                                setFieldValue('lines', new_lines);
                                                            }

                                                            setFieldValue('dates', new_dates);
                                                        }}
                                                    />

                                                    <FontAwesomeIcon
                                                        className="mr-20 ml-20"
                                                        icon={faMinus}
                                                        color={varibales.red}
                                                        onClick={() => {
                                                            if (values.dates.length === 1) return;
                                                            const new_dates = [...values.dates];
                                                            new_dates.pop();
                                                            setFieldValue('dates', new_dates);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    />
                                    <tr>
                                        <td>
                                            <label htmlFor="" className="col-xs-12">
                                                {t('f:chart.table.lineName')}
                                            </label>
                                        </td>

                                        {values.dates.length > 0 &&
                                            values.dates.map((friend, index) => (
                                                <td>
                                                    <label htmlFor="" className="col-xs-12">
                                                        {t('f:chart.table.value')}
                                                    </label>
                                                </td>
                                            ))}
                                    </tr>
                                    <FieldArray
                                        name="lines"
                                        render={({ insert, remove, push }) => (
                                            <>
                                                {values.lines.length > 0 &&
                                                    values.lines.map((friend, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="mb-20 position-relative">
                                                                    <Field
                                                                        className="form-control mb-25"
                                                                        name={`lines.${index}.name`}
                                                                        placeholder={t('f:chart.table.enterValue')}
                                                                        type="name"
                                                                    />
                                                                    <FormikErrorArrayDeep
                                                                        errors={errors}
                                                                        touched={touched}
                                                                        index={index}
                                                                        array="lines"
                                                                        name="name"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <FieldArray
                                                                name="dots"
                                                                render={({ insert, remove, push }) => (
                                                                    <>
                                                                        {[...values.dates].length > 0 &&
                                                                            [...values.dates].map(
                                                                                (friend, dotsIndex) => (
                                                                                    <td>
                                                                                        <div className="mb-20 position-relative">
                                                                                            <Field
                                                                                                className="form-control mb-25"
                                                                                                style={{
                                                                                                    width: '100%',
                                                                                                }}
                                                                                                name={`lines.${index}.dots.${dotsIndex}.value`}
                                                                                                placeholder={t(
                                                                                                    'f:chart.table.enterValue'
                                                                                                )}
                                                                                                type="value"
                                                                                            />
                                                                                            <FormikErrorTwoArrayDeep
                                                                                                errors={errors}
                                                                                                touched={touched}
                                                                                                index={index}
                                                                                                array="lines"
                                                                                                subArray="dots"
                                                                                                subArrayIndex={
                                                                                                    dotsIndex
                                                                                                }
                                                                                                name="value"
                                                                                            />
                                                                                        </div>
                                                                                    </td>
                                                                                )
                                                                            )}
                                                                    </>
                                                                )}
                                                            />
                                                            <td>
                                                                <div className="mb-20 position-relative">
                                                                    <FontAwesomeIcon
                                                                        className=" mr-20 ml-20"
                                                                        icon={faMinus}
                                                                        color={varibales.red}
                                                                        onClick={() => {
                                                                            if (values.lines.length === 1) return;
                                                                            const new_lines = values.lines.filter(
                                                                                (elem, currentIndex) => {
                                                                                    if (currentIndex !== index)
                                                                                        return elem;
                                                                                }
                                                                            );

                                                                            setFieldValue('lines', new_lines);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                <tr>
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        color={varibales.green}
                                                        className="mt-10 mb-10 ml-20"
                                                        onClick={() => {
                                                            const ar = [...values.lines];
                                                            ar.push({
                                                                name: '',
                                                                dots: [],
                                                            });

                                                            ar.map((element: LineChartInterfaceShort, i) => {
                                                                element.name = element.name ? element.name : '';
                                                                values.dates.map((el, i) => {
                                                                    element.dots.push({
                                                                        date: el,
                                                                        value: element.dots[i]
                                                                            ? element.dots[i].value
                                                                            : 0,
                                                                    });
                                                                });
                                                            });

                                                            setFieldValue('lines', ar);
                                                        }}
                                                    />
                                                </tr>
                                            </>
                                        )}
                                    />
                                </tbody>
                            </table>
                        </div>
                        {JSON.stringify(values.lines)}
                        <div className="col-xs-12-clear mt-10 d-flex">
                            <button
                                className="button-primary button-small mr-20"
                                onClick={() => {
                                    setFieldValue('dates', initDates);
                                    setFieldValue('lines', initLanes);
                                }}
                                type="button"
                            >
                                {t('f:button.reset')}
                            </button>
                            <button
                                className="button-primary button-small  mr-20"
                                onClick={() => {
                                    setIsPreviewShow(true);
                                    handleLineFinish(values.lines);
                                    setChartDataActions.setTrue();
                                }}
                            >
                                {t('f:button.preview')}
                            </button>
                            <button className="button-primary button-small" type="submit">
                                {t('f:button.save')}
                            </button>
                        </div>
                    </Form>
                );
            }}
        />
    );
};
export default LineChartForm;
