import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';
interface UnlistOpportunityModalProps {
    isModalOpened: boolean;
    handleFinish: () => void;
    handleClosing: () => void;
}

const UnlistOpportunityModal: FunctionComponent<UnlistOpportunityModalProps> = ({
    isModalOpened,
    handleFinish,
    handleClosing,
}) => {
    const { t } = useTranslation(['f', 'b']);

    return (
        <ModalWrapper
            isModalOpened={isModalOpened}
            className="confirm-delete"
            isCloseModalShown={true}
            isFooterShown={true}
            closeModalHandler={handleClosing}
            primaryButtonHandler={handleFinish}
            primaryButtonText={t('f:button.imSure')}
            secondaryButtonHandler={handleClosing}
            secondaryButtonText={t('f:button.cancel')}
        />
    );
};

export default UnlistOpportunityModal;
