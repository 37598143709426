import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import { loginRequest, signupRequest } from '../../../auth/authConfig';
import homePlanet from '../../../assets/images/home-planet.png';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

const Planet: FunctionComponent = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    };

    const handleSignup = () => {
        instance.loginRedirect(signupRequest);
    };
    return (
        <div className="home-page-planet mt-100">
            <img src={homePlanet} alt="planet" className="home-page-planet__image" />
            <UnauthenticatedTemplate>
                <div className="home-page-planet__content d-flex flex-direction-column align-items-center">
                    <h1 className="home-page-planet__title mb-40">Get started with Virgate</h1>
                    <h1 className="home-page-planet__title home-page-planet__title-sm mb-40">
                        Virgate helps you manage your global real estate portfolio with analytics and data
                    </h1>
                    <div className="home-page-planet__card d-flex mb-10 align-items-center" onClick={handleSignup}>
                        <FontAwesomeIcon icon={faPlus} className="home-page-planet__icon ml-20" />
                        <div className="mr-20">
                            <h2 className="home-page-planet__card-title">Create a new Virgate Account</h2>
                            <h2 className="home-page-planet__card-sub">Get your organisation started with Virgate</h2>
                        </div>
                    </div>
                    <div className="home-page-planet__card d-flex align-items-center" onClick={handleLogin}>
                        <FontAwesomeIcon icon={faSearch} className="home-page-planet__icon ml-20" />
                        <div className="mr-20">
                            <h2 className="home-page-planet__card-title">Already a member? Login</h2>
                            <h2 className="home-page-planet__card-sub">
                                Get analytics and search for opportunities now
                            </h2>
                        </div>
                    </div>
                </div>
            </UnauthenticatedTemplate>
        </div>
    );
};

export default Planet;
