import { Tabs } from "antd";
import { TabPane } from "rc-tabs";
import { FunctionComponent, useCallback, useContext, useEffect, useState } from "react";
import ComingSoon from "../../../../../components/shared/ComingSoon";
import FeatureState from "../../../../../context/state/FeatureState";
import useBoolean from "../../../../../hooks/use-boolean";
import { InvestorCompanyInfo } from "../../../../../interfaces/investor/CompanyWithInvestor";
import { InvestorUser } from "../../../../../interfaces/investor/InvestorUser";
import PropertyAssetCompanyService from "../../../../../services/PropertyAssetCompanyService";
import CompanyOverview from "../components/CompanyOverview";
import CompanyUsers from "../components/CompanyUsers";

interface CompanyInfoTabsProps {
    company: InvestorCompanyInfo;
}

const CompanyInfoTabs: FunctionComponent<CompanyInfoTabsProps> = ({
    company
}) => {
    const { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary;

    const [investorUsers, setInvestorUsers] = useState<Array<InvestorUser>>([]);
    const [isLoadingUsers, setIsLoadingUsers] = useBoolean(false);

    const fetchUsers = useCallback(async () => {
        setIsLoadingUsers.setTrue();
        const data = await PropertyAssetCompanyService.fetchInvestorUsersOfCompany(propertyAssetId, company!.id);
        setInvestorUsers(data);
        setIsLoadingUsers.setFalse();
    }, []);

    useEffect(() => {
        fetchUsers();
    }, []);

    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Overview" key="1">
                <CompanyOverview company={company!} propertyAssetId={propertyAssetId} users={investorUsers} />
            </TabPane>
            <TabPane tab="Charts" key="2" forceRender={false}>
                <ComingSoon />
            </TabPane>
            <TabPane tab="Users" key="3" forceRender={false}>
                <CompanyUsers
                    propertyAssetId={propertyAssetId}
                    company={company!}
                    investorUsers={investorUsers}
                    isLoading={isLoadingUsers}
                    refetch={() => fetchUsers()}
                />
            </TabPane>
        </Tabs>
    )
}

export default CompanyInfoTabs;