import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainButton from '../../../components/shared/MainButton';
import MainLoader from '../../../components/shared/MainLoader';
import { SubStatusEnum, subStatuses, toggleViews } from '../../../constants/subStatus';
import OverviewFilters from './OverviewFilters';
import CardsView from '../partials/CardsView';
import TableView from '../partials/TableView';
import { toast } from 'react-toastify';
import PropertyService from '../../../services/Property';
import PropertyAsserts from '../../../interfaces/property/PropertyAsserts';
import useBoolean from '../../../hooks/use-boolean';
import {
    OverviewPropertyAssetSearch,
    OverviewViewTypes,
    OverviewTopCards,
} from '../../../interfaces/property/OverviewPropertyAssetSearch';
import { default as ConfirmModal } from '../../ViewProperty/components/partials/UnlistOpportunityModal';
import ListOpportunityService from '../../../services/ListOpportunityService';
import InvestorService from '../../../services/InvestorsService';

const initialOverviewFilters: OverviewPropertyAssetSearch = {
    page: 0,
    pageSize: 50,
    countryId: undefined,
    sectorId: undefined,
    subStatus: undefined,
    type: OverviewTopCards.PORTFOLIO,
};

interface OverviewViewProps {
    assets: Array<PropertyAsserts>;
    isLoading: boolean;
    selected: number;
    refetch: (filters: OverviewPropertyAssetSearch) => void;
    setAssets: (assets: Array<PropertyAsserts>) => void;
}

const OverviewView: FunctionComponent<OverviewViewProps> = ({ assets, isLoading, refetch, selected, setAssets }) => {
    const { t } = useTranslation(['f', 'b']),
        [overviewFilters, setOverviewFilters] = useState<OverviewPropertyAssetSearch>(initialOverviewFilters),
        [_, isConfirmDeleteModalOpenActions] = useBoolean(),
        [view, setView] = useState<OverviewViewTypes>(OverviewViewTypes.CARDS),
        [isUnlistOpportunityModalOpen, setIsUnlistOpportunityModalOpen] = useBoolean(),
        [unlistingAssetId, setUnlistingAssetId] = useState<string | null>(null),
        [isRejectInvitationModalOpen, setIsRejectInvitationModalOpen] = useBoolean(),
        [rejectingAssetId, setRejectingAssetId] = useState<string | null>(null),
        history = useHistory();

    const handleDelete = useCallback(
        async (id: string) => {
            try {
                await PropertyService.deleteProperty(id);
                refetch(overviewFilters);
                isConfirmDeleteModalOpenActions.setFalse();
            } catch (error: any) {
                toast(t(`${error.response.data.errorMessage}`));
            }
        },
        [history]
    );

    const onUnlist = (propertyAssetId: string) => {
        setUnlistingAssetId(propertyAssetId);
        setIsUnlistOpportunityModalOpen.setTrue();
    };

    const onUnlistModalClosing = () => {
        setUnlistingAssetId(null);
        setIsUnlistOpportunityModalOpen.setFalse();
    };

    const onConfirmUnlist = async () => {
        if (!unlistingAssetId) {
            return;
        }

        try {
            await ListOpportunityService.unlistOpportunity(unlistingAssetId);
            const unlistedAsset = assets.find((item) => item.id.toString() == unlistingAssetId);
            unlistedAsset!.subStatus = SubStatusEnum.Unlisted;
            unlistedAsset!.canUnlist = false;
            setAssets([...assets]);
            setIsUnlistOpportunityModalOpen.setFalse();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    const onReject = (propertyAssetId: string) => {
        setRejectingAssetId(propertyAssetId);
        setIsRejectInvitationModalOpen.setTrue();
    };

    const onRejectModalClosing = () => {
        setRejectingAssetId(null);
        setIsRejectInvitationModalOpen.setFalse();
    };

    const onConfirmReject = async () => {
        if (!rejectingAssetId) {
            return;
        }

        try {
            await InvestorService.rejectInvitation(rejectingAssetId);
            const rejecteAsset = assets.find((item) => item.id.toString() == rejectingAssetId);
            rejecteAsset!.subStatus = SubStatusEnum.Rejected;
            setAssets([...assets]);
            setIsRejectInvitationModalOpen.setFalse();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    const toggleView = useCallback(() => {
        switch (view) {
            case OverviewViewTypes.CARDS:
                return (
                    <CardsView
                        setPageSize={() => setOverviewFilters({ ...overviewFilters, pageSize: assets.length + 9 })}
                        handleDelete={handleDelete}
                        assets={assets}
                        onUnlist={onUnlist}
                        selected={selected}
                        onReject={onReject}
                    />
                );

            default:
                return (
                    <TableView
                        handleDelete={handleDelete}
                        assets={assets}
                        setPageSize={(pageSize: number) => setOverviewFilters({ ...overviewFilters, pageSize })}
                        setPage={(page: number) => setOverviewFilters({ ...overviewFilters, page })}
                        pageSize={overviewFilters.pageSize}
                        onUnlist={onUnlist}
                        selected={selected}
                        onReject={onReject}
                    />
                );
        }
    }, [view, assets]);

    const onAddProperty = () => history.push('/property/add');

    const displayFilters = useCallback(() => {
        return subStatuses.filter((el) => el.topCardId === selected);
    }, [selected]);

    useEffect(() => {
        if (selected !== overviewFilters.type) {
            setOverviewFilters({
                ...initialOverviewFilters,
                type: selected,
            });
        }
    }, [selected]);

    useEffect(() => {
        refetch(overviewFilters);
    }, [overviewFilters]);

    return (
        <div className="row overview-side-menu__favourites-container d-flex f-wrap mx-20">
            <div className="col-xs-10 d-flex pl-0">
                <OverviewFilters
                    filter={''}
                    selectedSector={overviewFilters.sectorId}
                    selectedCountry={overviewFilters.countryId}
                    onSelectedSector={(e?: string) => {
                        setOverviewFilters({
                            ...overviewFilters,
                            sectorId: e
                                ? overviewFilters.sectorId === parseInt(e)
                                    ? undefined
                                    : parseInt(e)
                                : undefined,
                        });
                    }}
                    onSelectedCountry={(e?: string) => {
                        setOverviewFilters({
                            ...overviewFilters,
                            countryId: e ? parseInt(e) : undefined,
                        });
                    }}
                />
            </div>
            <div className="col-xs-2 overview-add-property-button">
                <div className="row-flex-clear">
                    <label className="col-xs-12-clear">&nbsp;</label>
                    <MainButton
                        buttonText={t('button.addProperty')}
                        buttonHandler={onAddProperty}
                        layout="primary"
                        size="main"
                    />
                </div>
            </div>
            <div className="col-xs-12-clear d-flex mt-20">
                <div className="col-xs-10-clear">
                    <div className="d-flex f-wrap gap-10 h-30">
                        {displayFilters().map((status: any) => (
                            <p
                                key={status.value}
                                className={`d-flex justify-content-center align-items-center ${
                                    status.value === overviewFilters.subStatus ? 'selected' : 'unselected'
                                }`}
                                onClick={() =>
                                    setOverviewFilters({
                                        ...overviewFilters,
                                        subStatus:
                                            overviewFilters.subStatus === status.value ? undefined : status.value,
                                    })
                                }
                            >
                                {t(status.text)}
                            </p>
                        ))}
                    </div>
                </div>
                <div className="col-xs-2-clear d-flex align-items-center">
                    {toggleViews.map((el) => (
                        <p
                            className={`overview-side-menu__view-type ml-20 ${
                                view === el.value ? 'overview-side-menu__view-type--selected' : ''
                            }`}
                            onClick={() => {
                                setOverviewFilters({
                                    ...overviewFilters,
                                    pageSize: el.value === OverviewViewTypes.TABLE ? 50 : 9,
                                });
                                setView(el.value);
                            }}
                        >
                            {t(el.text)}
                        </p>
                    ))}
                </div>
            </div>
            <div className="col-xs-12-clear mt-40">
                {!isLoading ? <>{toggleView()}</> : <MainLoader type="regular" />}
            </div>

            <ConfirmModal
                isModalOpened={isUnlistOpportunityModalOpen}
                handleFinish={() => onConfirmUnlist()}
                handleClosing={onUnlistModalClosing}
            />

            <ConfirmModal
                isModalOpened={isRejectInvitationModalOpen}
                handleFinish={onConfirmReject}
                handleClosing={onRejectModalClosing}
            />
        </div>
    );
};

export default OverviewView;
