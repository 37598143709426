import React, { FunctionComponent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useBoolean from '../hooks/use-boolean';
import BuildingDirectory from '../pages/BuildingDirectory/models/buildingDirectory';
import BuildingDirectoryService from '../services/BuildingDirectoryService';
import BuildingDirectoryState from './state/BuildingDirectoryState';

interface Props {
    children: React.ReactNode;
}

const BuildingDirectoryContext: FunctionComponent<Props> = ({ children }) => {
    const [buildingDirectory, setBuildingDirectory] = useState<BuildingDirectory>(),
        { t } = useTranslation(['f', 'b']),
        [isLoading, IsLoadingActions] = useBoolean();

    const fetchBuildingDirectory = useCallback(
        async (id: string) => {
            IsLoadingActions.setTrue();
            try {
                const response = await BuildingDirectoryService.fetchBuildingDirectory(id);
                setBuildingDirectory(response);
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
            IsLoadingActions.setFalse();
        }, []);

    return (
        <BuildingDirectoryState.Provider
            value={{
                fetchBuildingDirectory,
                setBuildingDirectory,
                buildingDirectory,
                isLoading
            }}
        >
            {children}
        </BuildingDirectoryState.Provider>
    );
};

export default BuildingDirectoryContext;
