import BaseModel from './BaseModel';
import { PropertyStatusEnum } from '../../constants/propertyStatus';

export default class PropertyAssetFeatureModel extends BaseModel {
    companyOwnerExternalId: string;
    companyOwnerId: number;
    name: string;
    address: string;
    assetTypes: any;
    status: PropertyStatusEnum | undefined;
    numberOfAssets: any;
    marketingProcess: string;
    dealType: string;
    allowSubsetBidding: boolean;
    isPortfolio: boolean;
    locations: Array<AssetLocation> = [];
    brokers: any;
    imageUrl: string;
    sectors: Array<number>;
    isListed: boolean;
    isPublic: boolean;
    isNdaRequired: boolean;

    constructor(data: any) {
        super();
        this.companyOwnerExternalId = data.companyOwnerExternalId;
        this.companyOwnerId = data.companyOwnerId;
        this.name = data.name;
        this.address = data.address;
        this.assetTypes = data.assetTypes.length > 0 ? data.assetTypes : '-';
        this.numberOfAssets = data.numberOfAssets === 0 ? '-' : data.numberOfAssets;
        this.marketingProcess = data.marketingProcess ? data.marketingProcess : '-';
        this.dealType = data.isPortfolio ? 'Portfolio' : 'Single Asset';
        this.isPortfolio = data.isPortfolio;
        this.brokers = data.brokers.length > 0 ? data.brokers : [];
        this.imageUrl = data.imageUrl;
        this.allowSubsetBidding = data.allowSubsetBidding;
        this.locations = data.locations?.map((location) => new AssetLocation(location));
        this.sectors = data.sectors.length ? data.sectors : [];
        this.isListed = data.isListed ?? false;
        this.status = data.status;
        this.isPublic = data.isPublic ?? false;
        this.isNdaRequired = data.isNdaRequired ?? false;
    }
}

class AssetLocation {
    propertyAssetId: number;
    address: string;
    name: string;
    latitude: number;
    longitude: number;
    imageUrl: string;

    constructor(data: any) {
        this.propertyAssetId = data.propertyAssetId;
        this.address = data.address;
        this.name = data.name;
        this.latitude = data.latitude;
        this.longitude = data.longitude;
        this.imageUrl = data.imageUrl;
    }
}
