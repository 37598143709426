import PortfolioApi from '../api/routes/PortfolioApi';

const createPortfolio = async (body: any) => {
    const { data } = await PortfolioApi.createPortfolio(body);
    return data;
};

const updatePortfolio = async (portfolioId: string, body: any) => {
    const { data } = await PortfolioApi.updatePortfolio(portfolioId, body);
    return data;
};

const fetchAssetsOfPortfolio = async (
    id: string,
    searchText: string,
    currentPage: number,
    pageSize: number
): Promise<any> => {
    const response = await PortfolioApi.fetchAssetsOfPortfolio(id, searchText, currentPage, pageSize);
    return response.data;
};
const fetchAssetsOfPortfolioForTable = async (id: string): Promise<any> => {
    const response = await PortfolioApi.fetchAssetsOfPortfolioForTable(id);
    return response.data;
};

const fetchPortfolioInformation = async (id: string): Promise<any> => {
    const response = await PortfolioApi.fetchPortfolioInformation(id);
    return response.data;
};

const disableSubsetBidding = async (portfolioId: string) => {
    const { data } = await PortfolioApi.disableSubsetBidding(portfolioId);
    return data;
};

const enableSubsetBidding = async (portfolioId: string) => {
    const { data } = await PortfolioApi.enableSubsetBidding(portfolioId);
    return data;
};
const PortfolioService = {
    updatePortfolio,
    disableSubsetBidding,
    enableSubsetBidding,
    createPortfolio,
    fetchAssetsOfPortfolioForTable,
    fetchAssetsOfPortfolio,
    fetchPortfolioInformation,
};

export default PortfolioService;
