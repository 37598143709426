import { FunctionComponent } from 'react';
import PropertyAsserts from '../../../interfaces/property/PropertyAsserts';
import PropertyCard from '../../../components/shared/PropertyCard';
import DotsContainer from './DotsContainer';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ModalConfirmDelete from '../../../components/shared/ModalConfirmDelete';
import useBoolean from '../../../hooks/use-boolean';

interface CardsViewProps {
    handleDelete: (id: string) => void;
    assets: Array<PropertyAsserts>;
    onUnlist: (propertyAssetId: string) => void;
    setPageSize: () => void;
    selected: number;
    onReject: (propertyAssetId: string) => void;
}

const CardsView: FunctionComponent<CardsViewProps> = ({
    handleDelete,
    assets,
    onUnlist,
    setPageSize,
    selected,
    onReject,
}: CardsViewProps) => {
    const { t } = useTranslation(['f', 'b']),
        [isConfirmDeleteModalOpen, isConfirmDeleteModalOpenActions] = useBoolean(),
        history = useHistory();
    return (
        <div className="col-xs-12-clear">
            <div className="portfolio__main d-flex gap-20">
                {assets.map((el) => (
                    <div className="relative">
                        <PropertyCard
                            showFavorite={false}
                            property={el}
                            className="property-card__container--magin-none"
                            handleOnClick={() => {
                                history.push(`/property/${el.externalId}`);
                            }}
                        />
                        <div className="portfolio__tree-dots-top">
                            <DotsContainer
                                property={el}
                                isConfirmDeleteModalOpenActions={isConfirmDeleteModalOpenActions}
                                canUnlist={el.canUnlist}
                                unlistHandler={() => onUnlist(el.id.toString())}
                                overviewType={selected}
                                rejectHandler={() => onReject(el.id.toString())}
                            />
                            <ModalConfirmDelete
                                text={t('f:property.confirmSectionDelete')}
                                isModalOpened={isConfirmDeleteModalOpen}
                                setIsModalOpenedActions={isConfirmDeleteModalOpenActions}
                                handleFinish={() => {
                                    handleDelete(`${el.id}`);
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
            {assets.length > 9 && (
                <div className="col-xs-12 d-flex align-items-center justify-content-center mt-50">
                    <p className="pointer overview-page-card__number" onClick={setPageSize}>
                        {t('f:exploreDataPage.sideMenu.loadMore')}...
                    </p>
                </div>
            )}
        </div>
    );
};

export default CardsView;
