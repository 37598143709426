import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectTeamMemberUser } from '../../../../../interfaces/ProjectTeam/ProjectTeamMeber';
import UserDetailsInModal from '../partials/UserDetailsInModal';
import ModalWrapper from '../../../../../components/wrapper/ModalWrapper';
interface Props {
    isUserDetailsModalOpened: boolean;
    setIsUserDetailsModalOpened: (prev: boolean) => void;
    worker: ProjectTeamMemberUser;
    roles: Object;
}

const UserDetailsModal: FunctionComponent<Props> = ({
    isUserDetailsModalOpened,
    worker,
    setIsUserDetailsModalOpened,
    roles,
}) => {
    const { t } = useTranslation(['f', 'b']);

    const handleOnCancel = useCallback(() => {
        setIsUserDetailsModalOpened(false);
    }, [setIsUserDetailsModalOpened]);

    const mapWorker = useCallback(() => {
        const { id, ...rest } = worker;
        return rest;
    }, [worker]);

    return (
        <ModalWrapper
            title={t('f:teams.userDetails')}
            className={'person-card__modal-details'}
            isModalOpened={isUserDetailsModalOpened}
            isFooterShown={false}
            isCloseModalShown={true}
            closeModalHandler={handleOnCancel}
        >
            <div className="overview__informations">
                <div className="overview__informations-main overview__informations-main--mt-0 overview__informations-main--no-gap d-flex">
                    {Object.entries(mapWorker()).map(([key, val], i) => {
                        if (key === 'role') {
                            val = t(`f:teams.roleTypeObjects.${roles[val]}`);
                        }
                        return (
                            <UserDetailsInModal title={t(`f:teams.userModalDetails.${key}`)} subtitle={val} key={key} />
                        );
                    })}
                </div>
            </div>
        </ModalWrapper>
    );
};
export default UserDetailsModal;
