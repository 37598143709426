import api from '../index';

const fetchFaqTopics = (id: string) => {
    return api.get(`/faq/${id}`);
};

const fetchTopicQuestions = (topicId: string, propertyAssetId: string) => {
    return api.get(`/faq/${propertyAssetId}/${topicId}/questions`);
};

const fetchAllTopicsQuestions = (propertyAssetId: string, searchText: string) => {
    return api.get(`/faq/${propertyAssetId}/search?SearchText=${searchText}`);
};

const createFaqQuestion = (propertyAssetId: string, topicId: any, body: any, config: any) => {
    return api.post(`/faq/${propertyAssetId}/${topicId}/questions`, body, config);
};

const createFaqTopic = (propertyAssetId: string, body: any) => {
    return api.post(`/faq/${propertyAssetId}`, body);
};

const deleteTopic = (propertyAssetId: string, topicId: string) => {
    return api.delete(`/faq/${propertyAssetId}/${topicId}`);
};

const updateTopic = (propertyAssetId: string, body: any) => {
    return api.put(`/faq/${propertyAssetId}`, body);
};

const deleteQuestion = (propertyAssetId: string, topicId: string, questionId: string) => {
    return api.delete(`/faq/${propertyAssetId}/${topicId}/questions/${questionId}`);
};

const updateQuestion = (propertyAssetId: string, topicId: string, body: any, config: any) => {
    return api.put(`/faq/${propertyAssetId}/${topicId}/questions`, body, config);
};

const FaqApi = {
    fetchFaqTopics,
    fetchTopicQuestions,
    createFaqQuestion,
    createFaqTopic,
    deleteTopic,
    updateTopic,
    deleteQuestion,
    updateQuestion,
    fetchAllTopicsQuestions,
};
export default FaqApi;
