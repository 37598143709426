import { FunctionComponent, useCallback, useContext } from 'react';
import { DatePicker, Space, Select } from 'antd';
import moment from 'moment';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { FormikErrorArrayDeep } from '../../TemplatesWizard/partials/FormikError';
import { Field } from 'formik';
import varibales from '../../../assets/styles/general/_variable.module.scss';
import { IMarketingTimeline } from '../../../interfaces/listOpporunity/ListOpportunity';
import DeallineWizardState from '../../../context/state/DeallineWizardState';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface MarketingTimelineListItemProps {
    values: { marketingStages: Array<IMarketingTimeline> };
    errors: any;
    touched: any;
    setFieldValue: (name: string, value: any) => void;
    push: (obj: any) => void;
}

const MarketingTimelineSingleStage: FunctionComponent<MarketingTimelineListItemProps> = ({
    values,
    errors,
    touched,
    setFieldValue,
    push,
}) => {
    const { t } = useTranslation('f'),
        antIcon = <LoadingOutlined style={{ fontSize: '14px' }} spin />,
        { stages, removeMarketingTimeline, isAddingOrDeletingActionLoadingActions, isAddingOrDeletingActionLoading } =
            useContext(DeallineWizardState),
        { Option } = Select;

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    return (
        <>
            {values.marketingStages.length > 0 &&
                values.marketingStages.map((friend, index) => (
                    <div key={index} className="d-flex gap-10 mb-10">
                        <div className="position-relative flex-1">
                            <Select
                                placeholder={
                                    values.marketingStages[index].stage
                                        ? stages[values.marketingStages[index].stage]
                                        : t(`f:listOpportunity.stages.Select`)
                                }
                                onChange={(e) => setFieldValue(`marketingStages.${index}.stage`, Number(e))}
                                style={{ width: '100%' }}
                                getPopupContainer={handleContainer}
                            >
                                {Object.entries(stages).map((stage: any) => (
                                    <Option value={Number(stage[0])} label={stage[1]} className="d-flex" key={stage[0]}>
                                        {t(`f:listOpportunity.stages.${stage[1]}`)}
                                    </Option>
                                ))}
                            </Select>
                            <FormikErrorArrayDeep
                                errors={errors}
                                touched={touched}
                                index={index}
                                name="stage"
                                array="marketingStages"
                            />
                        </div>

                        <div className="position-relative flex-1">
                            <Field
                                className="form-control"
                                name={`marketingStages.${index}.text`}
                                placeholder={t('f:chart.table.enterName')}
                                type="text"
                                style={{ width: '100%' }}
                            />
                            <FormikErrorArrayDeep
                                errors={errors}
                                touched={touched}
                                index={index}
                                array="marketingStages"
                                name="text"
                            />
                        </div>

                        <div className="position-relative flex-1">
                            <Space direction="vertical" size={10} style={{ width: '100%' }}>
                                <DatePicker
                                    value={
                                        values.marketingStages[index].startDate
                                            ? moment(new Date(values.marketingStages[index].startDate))
                                            : null
                                    }
                                    onChange={(value: any) =>
                                        setFieldValue(`marketingStages.${index}.startDate`, value.format())
                                    }
                                    onOk={(value: any) =>
                                        setFieldValue(`marketingStages.${index}.startDate`, value.format())
                                    }
                                />
                            </Space>
                            <FormikErrorArrayDeep
                                errors={errors}
                                touched={touched}
                                index={index}
                                array="marketingStages"
                                name="startDate"
                            />
                        </div>

                        <div className="position-relative flex-1">
                            <Space direction="vertical" size={10} style={{ width: '100%' }}>
                                <DatePicker
                                    value={
                                        values.marketingStages[index].closeDate
                                            ? moment(new Date(values.marketingStages[index].closeDate))
                                            : null
                                    }
                                    onChange={(value: any) =>
                                        setFieldValue(`marketingStages.${index}.closeDate`, value.format())
                                    }
                                    onOk={(value: any) =>
                                        setFieldValue(`marketingStages.${index}.closeDate`, value.format())
                                    }
                                />
                            </Space>
                            <FormikErrorArrayDeep
                                errors={errors}
                                touched={touched}
                                index={index}
                                array="marketingStages"
                                name="startDate"
                            />
                        </div>

                        <div className="position-relative flex-1 pointer">
                            {isAddingOrDeletingActionLoading ? (
                                <Spin indicator={antIcon} />
                            ) : (
                                <FontAwesomeIcon
                                    className="mr-20 ml-20 mt-10"
                                    icon={faMinus}
                                    color={varibales.red}
                                    onClick={() => {
                                        isAddingOrDeletingActionLoadingActions.setTrue();
                                        values.marketingStages[index]['stageId']
                                            ? (() => {
                                                  removeMarketingTimeline(values.marketingStages[index].stageId);
                                              })()
                                            : (() => {
                                                  const new_marketingStages = values.marketingStages.filter(
                                                      (elem, currentIndex) => currentIndex !== index
                                                  );

                                                  setFieldValue('marketingStages', new_marketingStages);
                                                  isAddingOrDeletingActionLoadingActions.setFalse();
                                              })();
                                    }}
                                />
                            )}
                        </div>
                    </div>
                ))}
            <FontAwesomeIcon
                icon={faPlus}
                color={varibales.green}
                className="mt-10 mb-10 ml-20 pointer"
                onClick={() =>
                    push({
                        stage: '',
                        text: '',
                        startDate: '',
                        closeDate: '',
                    })
                }
            />
        </>
    );
};
export default MarketingTimelineSingleStage;
