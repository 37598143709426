import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../components/shared/MainButton';
import SingleSelect from '../../../components/shared/SingleSelect';
import ThemeState from '../../../context/state/ThemeState';

const PublishTheme: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { setMainThemeId, mainThemeId, themeList, publishTheme } = useContext(ThemeState);

    const mapRecords: any = useCallback(() => {
        return themeList.map((el) => {
            return { id: `${el.id}`, name: el.name };
        });
    }, [themeList]);

    const handleOnClickPublishTheme = useCallback(() => {
        publishTheme(mainThemeId);
    }, [mainThemeId, publishTheme]);

    return (
        <div className="col-xs-12">
            <div className="col-xs-4 px-30">
                <div className="col-xs-12">
                    <SingleSelect
                        label={`${t('f:theme-manager.websiteTheme')}`}
                        placeholder={t('f:theme-manager.selectWebsiteTheme')}
                        options={mapRecords()}
                        onSelected={setMainThemeId}
                        selected={mainThemeId}
                    />
                </div>
                <div className="d-flex gap-20 col-xs-12 mt-15">
                    <MainButton
                        layout="primary"
                        type="submit"
                        size="main"
                        buttonText={`${t('f:theme-manager.publish')}`}
                        buttonHandler={handleOnClickPublishTheme}
                    />
                </div>
            </div>
        </div>
    );
};

export default PublishTheme;
