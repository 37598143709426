import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import { FunctionComponent, useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import CompanyCard from '../../../components/shared/CompanyCard';
import { Select } from 'antd';
import Company from '../../../services/Company';
import InviteInvestor from '../../../components/shared/InviteInvestor';
import NavigateState from '../../../context/state/NavigateState';
import BidService from '../../../services/BidService';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import LookUpContext from '../../../context/state/LookUpState';
import MainHeading from '../../../components/shared/MainHeading';
import MainLoader from '../../../components/shared/MainLoader';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';

interface Props {
    goToSelectBidInformations: (biddingId: string) => void;
}

interface RouteParams {
    id: string;
    biddingId: string;
}

const VentureCompanies: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        { biddingId } = useParams<RouteParams>(),
        [allInvestors, setAllInvestors] = useState<Array<any>>([]),
        { getContent } = useContext(LookUpContext),
        [optionsCompanies, setOptionsCompanies] = useState<Array<any>>([]),
        { Option } = Select,
        [ventureName, setVentureName] = useState<any>(''),
        [isCompaniesLoaded, setIsCompaniesLoaded] = useState<boolean>(false),
        [ownershipTypes, setOwnershipTypes] = useState<Array<any>>([]),
        [ownershipTypeName, setOwnershipTypeName] = useState<any>(''),
        { cancelEverything, returnToPreviousPage } = useContext(NavigateState);

    const searchCompanes = (event: any) => {
        const filtered: Array<any> = [];
        allInvestors.forEach((option: any) => {
            if (option.id.toString().includes(event.target.value)) {
                filtered.push(option);
            } else if (option.name.includes(event.target.value)) {
                filtered.push(option);
            } else if (option.type.includes(event.target.value)) {
                filtered.push(option);
            }
        });
        setOptionsCompanies(filtered);
    };

    const handleNext = async () => {
        try {
            await BidService.createVentureNameAndType(`${propertyAssetId}`, biddingId, {
                jointVentureName: ventureName,
                ownershipTypeId: ownershipTypeName,
            });
            props.goToSelectBidInformations(biddingId);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    const joinVentureCompanies = async (companyId: string) => {
        await BidService.createJoinVentureCompanies(`${propertyAssetId}`, biddingId, companyId);
    };
    const removeJoinVentureCompanies = async (companyId: number) => {
        await BidService.deleteJoinVentureCompanies(`${propertyAssetId}`, biddingId, `${companyId}`);
    };

    const fetchJointVentureCompanies = useCallback(async () => {
        try {
            const resp = await BidService.fetchBidInfo(`${propertyAssetId}`, biddingId);

            setVentureName(resp.jointVentureName);
            if (resp.ownershipTypeId) {
                setOwnershipTypeName(ownershipTypes.find((elem) => elem.id === resp.ownershipTypeId));
            }
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, biddingId, ownershipTypes, t]);

    const fetchCompanies = useCallback(async () => {
        try {
            const _companies = await Company.fetchCompanies();
            setAllInvestors(_companies);
            setOptionsCompanies(_companies);
            setIsCompaniesLoaded(true);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t]);
    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    useEffect(() => {
        setOwnershipTypes(getContent('OwnershipType'));

        fetchCompanies();
        fetchJointVentureCompanies();
    }, [fetchCompanies, fetchJointVentureCompanies, getContent]);

    return (
        <div className="venture-companies-page">
            <MainHeading text={t('f:bid.bidCompany')} />

            <Formik
                initialValues={{
                    companyName: ventureName ? '' + ventureName : '',
                    companyType: ownershipTypeName ? '' + ownershipTypeName : '',
                }}
                enableReinitialize={true}
                onSubmit={async (values: any) => {}}
            >
                {({ values, setFieldValue, resetForm, submitForm }) => (
                    <Form>
                        <div className="meeting__form-group d-flex">
                            <div className="col-md-4 form-group ">
                                <label htmlFor="text">{t('f:bid.jointVentureName')}</label>
                                <Field
                                    id="text"
                                    onChange={(event: any) => {
                                        setVentureName(event.target.value);
                                    }}
                                    name="companyName"
                                    placeholder={t('f:bid.jointVentureName')}
                                />
                            </div>

                            <div className="col-md-4 form-group">
                                <label htmlFor="text">{t('f:bid.companyType')}</label>

                                <Select
                                    onChange={(e) => setOwnershipTypeName(e)}
                                    placeholder="Select"
                                    getPopupContainer={handleContainer}
                                >
                                    {ownershipTypes?.map((stage: any) => (
                                        <Option value={stage.id} label={stage.name} className="d-flex" key={stage.id}>
                                            {stage.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <MainHeading text={t('f:bid.companies')} />

            <div className="venture-companies-page__companies">
                <div className="marketing__body-search d-flex">
                    <div className="marketing__body-search-top d-flex">
                        <label htmlFor="searchBy" className="marketing__label">
                            {t('f:search.searchAndFind')}
                        </label>
                        <input
                            type="text"
                            name="searchBy"
                            className="marketing__input"
                            placeholder="Title, keyword, address, zip or id ..."
                            onChange={searchCompanes}
                        />
                    </div>
                    <Button className="button-main button-primary marketing__btn">
                        <FontAwesomeIcon icon={['fas', 'search']} size="lg" />
                    </Button>
                </div>
                {isCompaniesLoaded ? (
                    <div className="marketing__companes d-flex">
                        {optionsCompanies.map((elem: any, i: number) => {
                            return (
                                <CompanyCard company={elem} key={i}>
                                    <InviteInvestor
                                        classNames={``}
                                        myInvestors={[]}
                                        id={elem.id}
                                        inviteInvestor={joinVentureCompanies}
                                        removeInvestitor={removeJoinVentureCompanies}
                                    />
                                </CompanyCard>
                            );
                        })}
                    </div>
                ) : (
                    <MainLoader type="regular" />
                )}
            </div>
            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                <p className="previous-page" onClick={() => returnToPreviousPage()}>
                    {t('f:button.previousStep')}
                </p>
                <Button
                    onClick={() => {
                        cancelEverything(externalId, PROPERTY_TABS.TRANSACTION.name);
                    }}
                    className="button-main button-secondary d-flex justify-content-center align-items-center"
                >
                    {t('f:button.cancel')}
                </Button>
                <Button
                    className="button-main button-primary mr-50"
                    onClick={() => {
                        handleNext();
                    }}
                >
                    {t('f:button.next')}
                </Button>
            </div>
        </div>
    );
};
export default VentureCompanies;
