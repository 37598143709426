import { FunctionComponent } from 'react';
interface PublisInfoSingleItemProps {
    title: string;
    text: string;
}
const PublisInfoSingleItem: FunctionComponent<PublisInfoSingleItemProps> = ({
    title,
    text,
}: PublisInfoSingleItemProps) => (
    <div className="overview__informations-single">
        <p className="overview__informations-title">{title}</p>
        <p className="overview__informations-title--subtitle">{text}</p>
    </div>
);

export default PublisInfoSingleItem;
