import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { isMobile } from 'react-device-detect';
import axios from 'axios';

const PreviewWordServiceUrl = 'https://view.officeapps.live.com/op/view.aspx?src=';

const renderDownloadComponent = (url) => (
    <div className='download-button'>
        <div>Please download this file then open it on your phone application.</div>
        <Button href={url} type="primary" icon={<DownloadOutlined />} size="large">Download</Button>
    </div>
);

interface Props {
    fileData: { name: string, url: string, type: string } | undefined
}

async function resolvePopupContent(fileData) {
    switch (fileData.type) {
        case 'doc':
        case 'docx':
            if (isMobile) {
                return renderDownloadComponent(fileData.url);
            }

            return <iframe src={`${PreviewWordServiceUrl}${fileData.url}`} className='word-file' frameBorder='0' />;

        case 'pdf':
            if (isMobile) {
                return renderDownloadComponent(fileData.url);
            }

            const response = await axios.get(fileData.url, { responseType: 'arraybuffer' });
            const base64 = Buffer.from(response.data, 'binary').toString('base64');

            return <iframe src={`data:application/pdf;base64, ${encodeURI(base64)}`} className='pdf-file' frameBorder='0' />;

        case 'gif':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'svg':
        case 'webp':
            return <img src={fileData.url} className='image' />;

        default:
            break;
    }
}

const PreviewFileModal: FunctionComponent<Props> = ({
    fileData
}) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [content, setContent] = useState<ReactNode>(null);

    useEffect(() => {
        if (!fileData) {
            return;
        }

        resolvePopupContent(fileData).then(content => {
            if (content) {
                setContent(content);
                setIsModalVisible(true);
            }
        });
    }, [fileData])

    if (!fileData) {
        return null;
    }

    return (
        <Modal
            centered
            destroyOnClose
            wrapClassName='dataroom-preview-file'
            width={'100%'}
            footer={null}
            title={fileData.name}
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}>
            {content}
        </Modal>
    );
};

export default PreviewFileModal;
