import { FunctionComponent, useState } from 'react';
import FaqThreeDotMenuTopic from '../faqComponents/FaqThreeDotMenuTopic';

interface Props {
    topicName: string;
    deleteTopicHandler: Function;
    openEditableModal: Function;
    tab: any;
}

const Topic: FunctionComponent<Props> = (props) => {
    const [isThreeDotClicked, setIsThreeDotClicked] = useState<boolean>(false);

    return (
        <div
            className="d-flex topic-header"
            onMouseLeave={() => {
                setIsThreeDotClicked(false);
            }}
        >
            <p className="faqs-tabs-tab-name">
                <label htmlFor="rent" className="radio-button__layout">
                    {props.tab.name}
                </label>
            </p>

            <FaqThreeDotMenuTopic
                topicName={props.tab.name}
                deleteTopicHandler={props.deleteTopicHandler}
                openEditableModal={props.openEditableModal}
                isThreeDotClicked={isThreeDotClicked}
                setIsThreeDotClicked={setIsThreeDotClicked}
                topicId={props.tab.id}
            />
        </div>
    );
};

export default Topic;
