import { BaseTransformer } from './BaseTransformer';
import PropertyAssetFeatureModel from '../models/PropertyAssetFeatureModel';

class PropertyAssetFeatureTransformer extends BaseTransformer {
    constructor() {
        super();
        this.transformSingle = this.transformSingle.bind(this);
        this.transformMultiple = this.transformMultiple.bind(this);
    }

    transformSingle(data = {}) {
        const feature = new PropertyAssetFeatureModel(data);
        return feature;
    }

    transformMultiple(data = []) {
        const features = data?.map((item) => new PropertyAssetFeatureModel(item));
        return features;
    }
}

export default new PropertyAssetFeatureTransformer();
