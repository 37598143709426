import * as React from 'react';
import { FunctionComponent } from 'react';
import NavigateState from './state/NavigateState';
import { useHistory } from 'react-router';
import { BidWizardStepNames } from '../constants/wizardSteps';

interface Props {
    children: React.ReactNode;
}

const NavigateContext: FunctionComponent<Props> = ({ children }) => {
    const history = useHistory();
    const cancelEverything = (propertyAssetId: string, tab: string) => {
        history.push(`/property/${propertyAssetId}#${tab}`);
    };

    const returnToPreviousPage = () => {
        history.goBack();
    };

    const returnTobBidDetailsPage = (propertyAssetId: string, biddingId: string) => {
        history.push(`/property/${propertyAssetId}/bid/${BidWizardStepNames.BID_INFORMATIONS}/${biddingId}`);
    };

    const returnToCompanyDetailsPage = (propertyAssetId: string, biddingId: string) => {
        history.push(`/property/${propertyAssetId}/bid/${BidWizardStepNames.COMPANY_DETAILS}/${biddingId}`);
    };

    const returnToFinanceDetails = (propertyAssetId: string, biddingId: string) => {
        history.push(`/property/${propertyAssetId}/bid/${BidWizardStepNames.FINANCE_DETAILS}/${biddingId}`);
    };

    return (
        <NavigateState.Provider
            value={{
                cancelEverything: cancelEverything,
                returnToPreviousPage: returnToPreviousPage,
                returnTobBidDetailsPage: returnTobBidDetailsPage,
                returnToCompanyDetailsPage: returnToCompanyDetailsPage,
                returnToFinanceDetails: returnToFinanceDetails,
            }}
        >
            {children}
        </NavigateState.Provider>
    );
};

export default NavigateContext;
