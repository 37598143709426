import { FunctionComponent, useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import BidService from '../../../services/BidService';
import _ from 'lodash';
import NavigateState from '../../../context/state/NavigateState';
import { toast } from 'react-toastify';
import LookUpContext from '../../../context/state/LookUpState';
import Lookup from '../../../interfaces/property/Lookup';
import Company from '../../../interfaces/property/Company';
import BidInformationInterface from '../../../interfaces/property/BidInformations';
import ConfirmSubmit from './ConfirmSubmit';
import { Button } from 'antd';
import FormattedAmount from '../../../components/shared/FormattedAmount';
import MainHeading from '../../../components/shared/MainHeading';
import MainLoader from '../../../components/shared/MainLoader';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';

interface RouteParams {
    id: string;
    biddingId: string;
}

interface Props {
    goToNext: (biddingId: string) => void;
    shouldSubmit: boolean;
}

const BidOverview: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f']),
        [companies, setMyCompany] = useState<Company>(),
        [groupedById, setGroupedById] = useState<any>(),
        [isModalOpened, setIsModalOpened] = useState<boolean>(false),
        [isLoading, setIsLoading] = useState(true),
        [biddingInformations, setBiddingInformations] = useState<BidInformationInterface>(),
        {
            returnToCompanyDetailsPage,
            returnToFinanceDetails,
            returnToPreviousPage,
            cancelEverything,
            returnTobBidDetailsPage,
        } = useContext(NavigateState),
        { getContent } = useContext(LookUpContext),
        [documentTypes, setDocumentTypes] = useState<Array<Lookup>>([]),
        [transitionType, setTransitionType] = useState<Array<Lookup>>(),
        [currencyType, setCurrencyType] = useState<Array<Lookup>>(),
        [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(false),
        [aqType, setAqType] = useState<Array<Lookup>>(),
        [offerType, setOfferType] = useState<Array<Lookup>>(),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        { biddingId } = useParams<RouteParams>();

    const fetchMyCompany = useCallback(async () => {
        try {
            const myCompany = await BidService.fetchMyCompany();
            setMyCompany(myCompany);
            setIsCompanyLoading(true);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t]);

    const checkOfferType = useCallback(
        (id: number) => {
            let result: any = _.find(getContent('OfferType'), { id: id });
            setOfferType(result.name);
        },
        [getContent]
    );

    const checkCurrencyType = useCallback(
        (id: number) => {
            let result: any = _.find(getContent('Currency'), { id: id });
            setCurrencyType(result.name);
        },
        [getContent]
    );

    const checkTransitionType = useCallback(
        (id: number) => {
            const result: any = _.find(getContent('TransactionType'), { id: id });
            setTransitionType(result.name);
        },
        [getContent]
    );

    const checkAQType = useCallback(
        (id: number) => {
            let result: any = _.find(getContent('AcquisitionType'), { id: id });
            setAqType(result.name);
        },
        [getContent]
    );

    const fetchBiddingInformations = useCallback(async () => {
        try {
            const _info = await BidService.fetchBiddingInformations(`${propertyAssetId}`, biddingId);
            setBiddingInformations(_info);
            checkOfferType(_info.offerTypeId);
            checkCurrencyType(_info.currencyId);
            checkTransitionType(_info.transactionTypeId);
            checkAQType(_info.acquisitionTypeId);
            setIsLoading(false);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, biddingId, t, checkAQType, checkCurrencyType, checkTransitionType, checkOfferType]);

    const fetchAllDocuments = useCallback(async () => {
        try {
            const resp = await BidService.fetchallBiddingDocuments(`${propertyAssetId}`, biddingId);
            const groups = resp.reduce((groups: any, item: any) => {
                const group = groups[item.biddingDocumentTypeId] || [];
                group.push(item);
                groups[item.biddingDocumentTypeId] = group;
                return groups;
            }, {});

            setGroupedById(groups);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, biddingId, t]);

    const getName = (id: any, arr: any) => {
        let str = '';

        arr.forEach((elem: any) => {
            if (elem.id === parseInt(id)) {
                str = elem.name;
            }
        });

        return str;
    };

    const onNextHandler = () => {
        props.goToNext(biddingId);
    };

    const SubmitBid = async () => {
        try {
            await BidService.createPlaceBid(`${propertyAssetId}`, biddingId);
            props.goToNext(biddingId);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    useEffect(() => {
        setDocumentTypes(getContent('BiddingDocumentType'));
        fetchAllDocuments();
    }, [getContent, fetchAllDocuments]);

    useEffect(() => {
        fetchMyCompany();
        fetchBiddingInformations();
    }, [fetchBiddingInformations, fetchMyCompany]);

    return (
        <div className="bid-overview-page">
            <MainHeading text={t('f:bid.overView')} />

            {!isLoading ? (
                <>
                    <div className="bid-overview-page__company mt-20">
                        <div className="col-xs-7 bid-overview-page__company-main">
                            <div className="bid-overview-page__hedding d-flex align-items-center justify-content-space-between">
                                <p className="person-card__element-name">{t('f:bid.overViewDetails.companyDetails')}</p>
                                <button
                                    type="button"
                                    className="overview__edit"
                                    onClick={() => {
                                        returnToCompanyDetailsPage(externalId, biddingId);
                                    }}
                                >
                                    {t(`f:button.edit`)}
                                </button>
                            </div>
                            <div className="col-xs-12 d-flex justify-content-space-between mt-40">
                                {isCompanyLoading ? (
                                    <>
                                        <div className="d-flex flex-direction-column">
                                            <div className="person-card__element">
                                                <p className="person-card__element-name">
                                                    {t('f:bid.overViewDetails.companyDetailsInfo.contactPerson')}
                                                </p>
                                                <p className="person-card__element-value">
                                                    {companies && companies.contactName}
                                                </p>
                                            </div>
                                            <div className="person-card__element">
                                                <p className="person-card__element-name">
                                                    {t('f:bid.overViewDetails.companyDetailsInfo.contactEmailAddress')}
                                                </p>
                                                <p className="person-card__element-value">
                                                    {companies && companies.email}
                                                </p>
                                            </div>
                                            <div className="person-card__element">
                                                <p className="person-card__element-name">
                                                    {t('f:bid.overViewDetails.companyDetailsInfo.contactPersonPhone')}
                                                </p>
                                                <p className="person-card__element-value">
                                                    {companies && companies.phoneNumber}
                                                </p>
                                            </div>
                                            <div className="person-card__element">
                                                <p className="person-card__element-name">
                                                    {t(
                                                        'f:bid.overViewDetails.companyDetailsInfo.contactPersonDesignation'
                                                    )}
                                                </p>
                                                <p className="person-card__element-value">
                                                    {companies && companies.name}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-direction-column">
                                            <div className="person-card__element">
                                                <p className="person-card__element-name">
                                                    {t('f:bid.overViewDetails.companyDetailsInfo.companyName')}
                                                </p>
                                                <p className="person-card__element-value">
                                                    {companies && companies.name}
                                                </p>
                                            </div>
                                            <div className=" person-card__element">
                                                <p className="person-card__element-name">
                                                    {t(
                                                        'f:bid.overViewDetails.companyDetailsInfo.companyRegistrationIDGT'
                                                    )}
                                                </p>
                                                <p className="person-card__element-value">
                                                    {companies && companies.name}
                                                </p>
                                            </div>
                                            <div className="person-card__element">
                                                <p className="person-card__element-name">
                                                    {t('f:bid.overViewDetails.companyDetailsInfo.companyAddress')}
                                                </p>
                                                <p className="person-card__element-value">
                                                    {companies && companies.address}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <MainLoader type="regular" />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-5">
                        {groupedById !== undefined
                            ? Object.keys(groupedById).map((elem: any, i: number) => {
                                  return (
                                      <div className="bid-overview-page__company-secondary">
                                          <div className="bid-overview-page__bid-document mb-10">
                                              <div className="bid-overview-page__hedding d-flex align-items-center justify-content-space-between">
                                                  <p className="person-card__element-name">
                                                      {documentTypes ? getName(elem, documentTypes) : ''}
                                                  </p>
                                                  <button
                                                      type="button"
                                                      className="overview__edit"
                                                      onClick={() => {
                                                          returnToFinanceDetails(externalId, biddingId);
                                                      }}
                                                  >
                                                      {t(`f:button.edit`)}
                                                  </button>
                                              </div>
                                              <ul>
                                                  {groupedById[`${elem}`].map((file: any, i: number) => {
                                                      return (
                                                          <li key={i}>
                                                              <a
                                                                  href={file.url}
                                                                  className="transaction__list-item transaction__list-item--small"
                                                                  download
                                                              >
                                                                  {file.fileName}
                                                              </a>
                                                          </li>
                                                      );
                                                  })}
                                              </ul>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>

                    <div className="col-xs-7 bid-overview-page__bid mt-40">
                        <div className="bid-overview-page__hedding d-flex align-items-center justify-content-space-between">
                            <p className="person-card__element-name">{t('f:bid.overViewDetails.bidDetails')}</p>
                            <button
                                type="button"
                                className="overview__edit"
                                onClick={() => {
                                    returnTobBidDetailsPage(externalId, biddingId);
                                }}
                            >
                                {t(`f:button.edit`)}
                            </button>
                        </div>
                        <div className="col-xs-12 d-flex justify-content-space-between mt-40">
                            <div className="d-flex flex-direction-column">
                                <div className="person-card__element">
                                    <p className="person-card__element-name">{t('f:bid.bidInformations.offerType')}</p>
                                    <p className="person-card__element-value">{offerType ? offerType : ''}</p>
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">{t('f:bid.bidInformations.currency')}</p>
                                    <p className="person-card__element-value">{currencyType ? currencyType : ''}</p>
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">{t('f:bid.bidInformations.nOINumber')}</p>
                                    <p className="person-card__element-value">
                                        {biddingInformations ? <FormattedAmount value={biddingInformations.noi} /> : ''}
                                    </p>
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">
                                        {t('f:bid.bidInformations.totalNetRentableArea')}
                                    </p>
                                    <p className="person-card__element-value">
                                        {biddingInformations ? (
                                            <FormattedAmount value={biddingInformations.totalNetArea} />
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">
                                        {t('f:bid.bidInformations.acquisitionStructure')}
                                    </p>
                                    <p className="person-card__element-value">
                                        {biddingInformations ? biddingInformations.acquisitionStructure : ''}
                                    </p>
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">
                                        {t('f:bid.bidInformations.additionalDeposit')}
                                    </p>
                                    {biddingInformations ? (
                                        <FormattedAmount value={biddingInformations.additionalDeposit} />
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">
                                        {t('f:bid.bidInformations.purchaseandSaleAgreement')}
                                    </p>
                                    <p className="person-card__element-value">
                                        {biddingInformations ? biddingInformations.agreementNumberOfDays : ''}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex flex-direction-column">
                                <div className="person-card__element">
                                    <p className="person-card__element-name">{t('f:bid.bidInformations.bidAmount')}</p>
                                    <p className="person-card__element-value">
                                        {biddingInformations ? (
                                            <FormattedAmount value={biddingInformations.bidAmount} />
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                </div>
                                <div className=" person-card__element">
                                    <p className="person-card__element-name">
                                        {t('f:bid.bidInformations.transactionType')}
                                    </p>
                                    <p className="person-card__element-value">{transitionType ? transitionType : ''}</p>
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">{t('f:bid.bidInformations.baciy')}</p>
                                    <p className="person-card__element-value">
                                        {biddingInformations ? (
                                            <FormattedAmount
                                                value={biddingInformations.buyerAssumedCapRateInitialYield}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">
                                        {t('f:bid.bidInformations.acquisitionPurpose')}
                                    </p>
                                    <p className="person-card__element-value">{aqType ? aqType : ''}</p>
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">
                                        {t('f:bid.bidInformations.initialDeposit')}
                                    </p>
                                    <p className="person-card__element-value">
                                        {biddingInformations ? (
                                            <FormattedAmount value={biddingInformations.initialDeposit} />
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                </div>
                                <div className=" person-card__element">
                                    <p className="person-card__element-name">
                                        {t('f:bid.bidInformations.closingAfterDD')}
                                    </p>
                                    <p className="person-card__element-value">
                                        {biddingInformations ? biddingInformations.closingNumberOfDays : ''}
                                    </p>
                                </div>
                                <div className="person-card__element">
                                    <p className="person-card__element-name">
                                        {t('f:bid.bidInformations.isThisSecondaryFinancing')}
                                    </p>
                                    <p className="person-card__element-value">
                                        {biddingInformations
                                            ? biddingInformations.secondaryFinancing
                                                ? 'Yes'
                                                : 'No'
                                            : ''}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                        <p className="previous-page" onClick={() => returnToPreviousPage()}>
                            {t('f:button.previousStep')}
                        </p>
                        <Button
                            onClick={() => {
                                cancelEverything(externalId, PROPERTY_TABS.TRANSACTION.name);
                            }}
                            className="button-main button-secondary d-flex justify-content-center align-items-center"
                        >
                            {t('f:button.cancel')}
                        </Button>
                        <Button
                            className="button-main button-primary mr-25"
                            onClick={() => {
                                onNextHandler();
                            }}
                        >
                            {props.shouldSubmit ? t('f:button.save') : t('f:button.next')}
                        </Button>

                        {props.shouldSubmit && (
                            <Button
                                className="button-main button-primary mr-25"
                                onClick={() => {
                                    setIsModalOpened(true);
                                }}
                            >
                                {t('f:button.submit')}
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <MainLoader type="regular" />
            )}

            <ConfirmSubmit
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                biddingId={biddingId}
                submit={SubmitBid}
            />
        </div>
    );
};
export default BidOverview;
