import { FunctionComponent } from 'react';

interface LogoPreviewProps {
    url: string;
}

const LogoPreview: FunctionComponent<LogoPreviewProps> = ({ url }) => (
    <div className="col-xs-12">
        <div className="col-xs-3-clear ml-40">
            <div className="logo">
                <img src={url} alt="Logos preview" />
            </div>
        </div>
    </div>
);
export default LogoPreview;
