import { createFromIconfontCN } from '@ant-design/icons';
import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Menu, Tooltip } from 'antd';
import jwtDecode from 'jwt-decode';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { loginRequest, Scopes } from '../../auth/authConfig';
import { getToken, resetToken, setToken } from '../../auth/UserService';
import { Actions } from '../../interfaces/general/general';
import Burger from '../shared/Burger';
import Logo from '../shared/Logo';
import { isMobile } from 'react-device-detect';

interface SlideInMenuProps {
    setIsShown: Actions;
    isShown: boolean;
    lightLogo: Boolean;
}

const SlideInMenu: FunctionComponent<SlideInMenuProps> = ({ setIsShown, isShown, lightLogo }) => {
    const { t } = useTranslation(['f', 'b']),
        { instance, accounts } = useMsal(),
        [name, setName] = useState<string | undefined>(''),
        history = useHistory();

    const IconFont = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
    });

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    };

    const handleSignout = () => {
        history.push('/');
        instance.logout();
        resetToken();
    };

    const fetchToken = async () => {
        let account = accounts.length ? (accounts[0] as AccountInfo) : null;
        if (account) {
            setName(account.name ?? account.username);
            let request: SilentRequest = {
                scopes: Scopes.api,
                account: account,
            };
            try {
                var authenticationResult = await instance.acquireTokenSilent(request);
                setToken(authenticationResult.accessToken);
            } catch (error: any) {
                instance.acquireTokenRedirect(request);
            }
        }
    };

    const isSuperAdmin = useCallback(() => {
        const token = getToken();
        if (token) {
            const decode: any = jwtDecode(token);

            return decode['role'] == '3' ? true : false;
        } else {
            return false;
        }
    }, []);

    useEffect(() => {
        async function fetch() {
            if (accounts && accounts.length > 0) {
                await fetchToken();
            } else {
                // resetToken();
            }
        }

        fetch();
    }, [accounts]);

    return (
        <>
            <div className={`slide-in ${isShown ? 'slide-in--show' : ''} d-flex`}>
                <Menu
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    mode="inline"
                    inlineCollapsed={false}
                    className="slide-in__container d-flex flex-direction-column"
                >
                    <div className="d-flex">
                        <Burger
                            handleOnClick={() => {
                                setIsShown.setFalse();
                            }}
                            classNameMain="burger--slide-in"
                            classNameFirstLine="burger-line--slide-in"
                            classNameSecondLine="burger-line--slide-in"
                        />
                        {!isMobile && <Logo light={lightLogo} />}
                    </div>

                    <AuthenticatedTemplate>
                        <Menu.Item key="1">
                            <div
                                onClick={() => {
                                    history.push('/overview');
                                    setIsShown.setFalse();
                                }}
                            >
                                <span className="slide-in__text ml-40">{t('f:menu.overview')}</span>
                            </div>
                        </Menu.Item>
                    </AuthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <Menu.Item
                            key="2"
                            onClick={() => {
                                history.push('/account-settings/personal-details');
                                setIsShown.setFalse();
                            }}
                        >
                            <Tooltip title={name}>
                                <span className="slide-in__text ml-40">{t('f:menu.account')}</span>
                            </Tooltip>
                        </Menu.Item>
                    </AuthenticatedTemplate>

                    <AuthenticatedTemplate>
                        {isSuperAdmin() && (
                            <Menu.Item key="3">
                                <div
                                    onClick={() => {
                                        history.push('/theme');
                                        setIsShown.setFalse();
                                    }}
                                >
                                    <span className="slide-in__text ml-40">{t('f:menu.theme')}</span>
                                </div>
                            </Menu.Item>
                        )}
                    </AuthenticatedTemplate>

                    <AuthenticatedTemplate>
                        {isSuperAdmin() && (
                            <Menu.Item key="4">
                                <div
                                    onClick={() => {
                                        history.push('/admin-panel/company');
                                        setIsShown.setFalse();
                                    }}
                                >
                                    <span className="slide-in__text ml-40">{t('f:menu.adminPanel')}</span>
                                </div>
                            </Menu.Item>
                        )}
                    </AuthenticatedTemplate>

                    <AuthenticatedTemplate>
                        {isSuperAdmin() && (
                            <Menu.Item key="4">
                                <div
                                    onClick={() => {
                                        history.push('/analytics');
                                        setIsShown.setFalse();
                                    }}
                                >
                                    <span className="slide-in__text ml-40">{t('f:menu.analytics')}</span>
                                </div>
                            </Menu.Item>
                        )}
                    </AuthenticatedTemplate>

                    <Menu.Item key="5" style={{ margin: 'auto 0 30px 0' }}>
                        <UnauthenticatedTemplate>
                            <div onClick={handleLogin}>
                                <span className="slide-in__text ml-20"> {t('f:menu.clientLogin')}</span>
                            </div>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>
                            <div onClick={handleSignout}>
                                <span className="slide-in__text ml-20">{t('f:menu.signout')}</span>
                            </div>
                        </AuthenticatedTemplate>
                    </Menu.Item>
                </Menu>

                <div
                    className={`slide-in__main ${isShown ? 'slide-in__main--bg' : 'slide-in__main--tr'}`}
                    onClick={() => {
                        setIsShown.setFalse();
                    }}
                ></div>
            </div>
        </>
    );
};
export default SlideInMenu;
