import { AxiosRequestConfig } from 'axios';
import api from '../index';
import { defaultTransformers } from '../index';
import PropertyTransformer from '../transformers/PropertyTransformer';
import { OverviewPropertyAssetSearch } from '../../interfaces/property/OverviewPropertyAssetSearch';

const fetchProperty = (id: number) => {
    return api.get(`/PropertyAsset/${id}`, {
        transformResponse: [...defaultTransformers(), PropertyTransformer.transform],
    });
};

const fetchMyProperty = (searchText: string, currentPage: number, pageSize: number) => {
    return api.get(
        `/portfolio/singlepropertyassets?SearchText=${searchText}&PageIdx=${currentPage}&ItemsPerPage=${pageSize}`
    );
};

const fetchMyOverviewProperty = (searchParams: OverviewPropertyAssetSearch) => {
    const config: AxiosRequestConfig = { params: searchParams };
    return api.get('/PropertyAsset/overview', config);
};

const fetchOverviewSummary = () => {
    return api.get('/PropertyAsset/overview/summary');
};

const shereProperty = (propertyAssetId: string) => {
    return api.patch(`/PropertyAsset/${propertyAssetId}/toggleSharing`, { id: propertyAssetId });
};

const createProperty = (data: any) => {
    return api.post('/PropertyAsset', data);
};

const updateProperty = (data: any) => {
    return api.put(`/PropertyAsset`, data);
};
const deleteProperty = (propertyAssetId: string) => {
    return api.delete(`/PropertyAsset/${propertyAssetId}`);
};

const fetchMostRecent = (numberOfAssets: string) => {
    return api.get(`/propertyasset/recentdeals?numberOfAssets=${numberOfAssets}`);
};

const PropertyApi = {
    fetchProperty,
    fetchMyProperty,
    createProperty,
    updateProperty,
    fetchMostRecent,
    fetchMyOverviewProperty,
    deleteProperty,
    fetchOverviewSummary,
    shereProperty,
};

export default PropertyApi;
