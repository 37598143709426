import BaseModel from './BaseModel';

export default class PropertyModel extends BaseModel {
    id: number;
    propertyName: string;
    description: string;
    numberOfBuildings: string;
    numberOfFloors: number;
    numberOfUnitsRooms: number;
    buildingClassId: number;
    buildingStatusId: number;
    yearRenovated: string;
    propertyMarketId: number;
    propertySubMarketId: number;
    landTenureId: number;
    anchorTenants: string;
    buildingGradeId: number;
    landArea: number;
    passingRent: number;
    carparkLots: number;
    majorTenants: string;
    netOperatingIncome: number;
    capitalisationRate: number;
    assetValue: number;
    locationTypes: any;
    greenRatings: any;
    leaseholdStartDate: any;
    leaseholdExpirationDate: any;
    leasehold: any;
    sectors: any;
    mainSectorId?: number;
    subSectors: Array<number> = [];
    owners: any;
    assetTypes: any;
    grossFloorArea: string;
    netLeasableArea: string;
    ownershipTypeId: number;
    partialInterest: string;
    map: any;
    imageUrl: string;
    yearOfCompletion: string;
    landTenure: string;
    currencyId: string;
    saleTypeId: string;
    unitOfMeasurementId: string;
    allowSubsetBidding: boolean;
    ownership: any;

    constructor(data: any) {
        super();

        this.id = data.id ? data.id : '';
        this.propertyName = data.propertyName ? data.propertyName : '';
        this.description = data.description ? data.description : '';
        this.numberOfBuildings = data.numberOfBuildings ? data.numberOfBuildings : '';
        this.numberOfFloors = data.numberOfFloors ? data.numberOfFloors : '';
        this.numberOfUnitsRooms = data.numberOfUnitsRooms ? data.numberOfUnitsRooms : '';
        this.buildingClassId = data.buildingClassId ? data.buildingClassId : '';
        this.buildingStatusId = data.buildingStatusId ? data.buildingStatusId : '';
        this.yearRenovated = data.yearRenovated ? data.yearRenovated : '';
        this.propertyMarketId = data.propertyMarketId ? data.propertyMarketId : '';
        this.propertySubMarketId = data.propertySubMarketId ? data.propertySubMarketId : '';
        this.landTenureId = data.landTenureId ? data.landTenureId : '';
        this.anchorTenants = data.anchorTenants ? data.anchorTenants : '';
        this.buildingGradeId = data.buildingGradeId ? data.buildingGradeId : '';
        this.landArea = data.landArea ? data.landArea : '';
        this.passingRent = data.passingRent ? data.passingRent : '';
        this.carparkLots = data.carparkLots ? data.carparkLots : '';
        this.majorTenants = data.majorTenants ? data.majorTenants : '';
        this.netOperatingIncome = data.netOperatingIncome ? data.netOperatingIncome : '';
        this.capitalisationRate = data.capitalisationRate ? data.capitalisationRate : '';
        this.assetValue = data.assetValue ? data.assetValue : '';
        this.locationTypes = data.locationTypes ? data.locationTypes : '';
        this.greenRatings = data.greenRatings ? data.greenRatings : '';
        this.leaseholdStartDate = data.leaseholdStartDate ? data.leaseholdStartDate : '';
        this.leaseholdExpirationDate = data.leaseholdExpirationDate ? data.leaseholdExpirationDate : '';
        this.leasehold = data.leasehold ? data.leasehold : '';
        this.sectors = data.sectors ? data.sectors : '';
        this.ownership = data.ownership ? data.ownership : '';
        this.assetTypes = data.assetTypes ? data.assetTypes : '';
        this.grossFloorArea = data.grossFloorArea ? data.grossFloorArea : '';
        this.netLeasableArea = data.netLeasableArea ? data.netLeasableArea : '';
        this.ownershipTypeId = data.ownershipTypeId ? data.ownershipTypeId : '';
        this.partialInterest = data.partialInterest ? data.partialInterest : '';
        this.map = data.map ? data.map : '';
        this.imageUrl = data.imageUrl ? data.imageUrl : '';
        this.yearOfCompletion = data.yearOfCompletion ? data.yearOfCompletion : '';
        this.landTenure = '';
        this.currencyId = data.currencyId ? data.currencyId : '';
        this.saleTypeId = data.saleTypeId ? data.saleTypeId : '';
        this.unitOfMeasurementId = data.unitOfMeasurementId ? data.unitOfMeasurementId : '';
        this.owners = data.owners ? data.owners : '';
        this.allowSubsetBidding = data.allowSubsetBidding ? data.allowSubsetBidding : false;
        this.mainSectorId = data.mainSectorId;
        this.subSectors = this.MapSubSectors(data.sectors, data.mainSectorId);
    }

    MapSubSectors = (sectors: any, mainSectorId?: number) => {
        if (!sectors || !Array.isArray(sectors)){
            return []
        }
        
        if (!mainSectorId) {
            return sectors;
        }

        return sectors.filter(item=> item != mainSectorId);
    }
}
