import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import building from '../../assets/images/pricing-building.jpeg';
const Pricing: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']);

    return (
        <div className='pricing'>
            <div className='pricing__hero d-flex justify-content-center'>
                <div className='d-flex flex-direction-column pricing__hero-info'>
                    <h1 className='heading pricing__hero-info-heading'>
                        {t(`f:pricing.simpleStraightForwardPricing`)}
                    </h1>
                    <p className='pricing__hero-info-paragraph mb-20'>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facere rerum explicabo
                        vero sint dignissimos excepturi reiciendis illum perferendis vel neque in eius repellat debitis
                        doloremque voluptates error, iste exercitationem.
                    </p>
                </div>
                <img src={building} className='pricing__hero--image' alt='pricing hero background' />
            </div>

            <div className='pricing__main'>
                <div className='pricing__card d-flex flex-direction-column align-items-center'>
                    <h4 className='heading pricing__card-heading mb-10'>{t('f:pricing.enterprise')}</h4>
                    <p className='pricing__card-paragraph mb-10'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor impedit natus eos? Et cupiditate
                        ad itaque dignissimos, quos eveniet illo?
                    </p>
                    <span className='pricing__card-price mb-10'>
                        $15,000 <span className='pricing__card-price--small'>/user</span>
                    </span>
                    <button className='pricing__card-buy-btn'>{t('f:button.buyNow')}</button>
                </div>
                <div className='pricing__card d-flex flex-direction-column align-items-center'>
                    <h4 className='heading pricing__card-heading mb-10'>{t('f:pricing.successFee')}</h4>
                    <p className='pricing__card-paragraph mb-10'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor impedit natus eos? Et cupiditate
                        ad itaque dignissimos, quos eveniet illo?
                    </p>
                    <span className='pricing__card-price mb-10'>
                        $15,000 <span className='pricing__card-price--small'>/user</span>
                    </span>
                    <button className='pricing__card-buy-btn'>{t('f:button.buyNow')}</button>
                </div>
                <div className='pricing__card d-flex flex-direction-column align-items-center'>
                    <h4 className='heading pricing__card-heading mb-10'>{t('f:pricing.advisory')}</h4>
                    <p className='pricing__card-paragraph mb-10'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor impedit natus eos? Et cupiditate
                        ad itaque dignissimos, quos eveniet illo?
                    </p>
                    <span className='pricing__card-price mb-10'>
                        $15,000 <span className='pricing__card-price--small'>/user</span>
                    </span>
                    <button className='pricing__card-buy-btn'>{t('f:button.buyNow')}</button>
                </div>
            </div>

            <div className='pricing__main pricing__main--listing'>
                <div className='pricing__card__list pricing__card__list--right d-flex flex-direction-column align-items-center'>
                    <p className='mt-20'>{t('f:pricing.discoveryWorkshop')}</p>
                    <p className='mt-10'>{t('f:pricing.uploadDealPipeline')}</p>
                    <p className='mt-10'>{t('f:pricing.tailorYourScreens')}</p>
                    <p className='mt-10'>{t('f:pricing.createYourDashboard')}</p>
                    <p className='mt-10'>{t('f:pricing.organiseYourData')}</p>
                    <p className='mt-10'>{t('f:pricing.managaAnalytics')}</p>
                    <p className='mt-10'>{t('f:pricing.integrateEmail')}</p>
                </div>
                <div className='pricing__card__list pricing__card__list--right d-flex flex-direction-column align-items-center'>
                    <p className='mt-20'>{t('f:pricing.successOnly')}</p>
                    <p className='mt-10'>{t('f:pricing.percentResarch')}</p>
                    <p className='mt-10'>{t('f:pricing.adjustCashflow')}</p>
                    <p className='mt-10'>{t('f:pricing.dataRoom')}</p>
                    <p className='mt-10'>{t('f:pricing.emailCampaigns')}</p>
                </div>
                <div className='pricing__card__list d-flex flex-direction-column align-items-center'>
                    <p className='mt-20 pricing__lineHight'>{t('f:pricing.memberSubscription')}</p>
                </div>
            </div>

            <div className='pricing__checkout mb-100'>
                <p className='heading pricing__checkout--heading mt-80'>{t('f:pricing.everyBusinessPlanIncludes')}</p>
                <div className='pricing__main pricing__main--small-width'>
                    <div className=' d-flex flex-direction-column align-items-center'>
                        <p className='pricing__checkout-single mt-20'>{t('f:pricing.desktopSinc')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.richFilePReview')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.sslAndAt')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.fileLocking')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.versionHistory')}</p>
                    </div>
                    <div className=' d-flex flex-direction-column align-items-center'>
                        <p className='pricing__checkout-single mt-20'>{t('f:pricing.mobileAccess')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.twoAuth')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.fullTextSearch')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.accessStats')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.boxContentAPI')}</p>
                    </div>
                    <div className='d-flex flex-direction-column align-items-center'>
                        <p className='pricing__checkout-single mt-20'>{t('f:pricing.painterApps')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.secureSharing')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.autoExpariton')}</p>
                        <p className='pricing__checkout-single mt-10'>{t('f:pricing.fileLocking')}</p>
                    </div>
                </div>
            </div>

            <div className='pricing__more-cotnainer mb-100'>
                <div className='col-xs-4 pricing__more-info-card'>
                    <h4 className='heading pricing__card-heading mb-10'>{t('f:pricing.enterprise')}</h4>
                    <p className='pricing__card-paragraph pricing__card-paragraph--size'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus, sapiente. Aliquid dolores esse
                        pariatur vel quas sit placeat quibusdam ratione accusamus voluptatum? Iste minus eius commodi
                        possimus facilis provident optio quibusdam consequatur eveniet, illo excepturi sed ipsa libero
                        id assumenda? Provident impedit facilis harum ducimus deserunt error aspernatur hic velit.
                    </p>
                </div>
                <div className='col-xs-4 pricing__more-info-card'>
                    <h4 className='heading pricing__card-heading mb-10'>{t('f:pricing.successFee')}</h4>
                    <p className='pricing__card-paragraph pricing__card-paragraph--size'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus, sapiente. Aliquid dolores esse
                        pariatur vel quas sit placeat quibusdam ratione accusamus voluptatum? Iste minus eius commodi
                        possimus facilis provident optio quibusdam consequatur eveniet, illo excepturi sed ipsa libero
                        id assumenda? Provident impedit facilis harum ducimus deserunt error aspernatur hic velit.
                    </p>
                </div>
                <div className='col-xs-4-clear pricing__more-info-card pricing__more-info-card--center'>
                    <h4 className='heading pricing__card-heading mb-10'>{t('f:pricing.advisory')}</h4>
                    <p className='pricing__card-paragraph pricing__card-paragraph--size'>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Natus, sapiente. Aliquid dolores esse
                        pariatur vel quas sit placeat quibusdam ratione accusamus voluptatum? Iste minus eius commodi
                        possimus facilis provident optio quibusdam consequatur eveniet, illo excepturi sed ipsa libero
                        id assumenda? Provident impedit facilis harum ducimus deserunt error aspernatur hic velit.
                    </p>
                </div>
            </div>
        </div>
    );
};
export default Pricing;
