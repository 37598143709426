import { FunctionComponent, useContext } from 'react';
import TreeDotsForEDIT from '../../pages/ViewProperty/components/transactionComponents/TreeDotsForEDIT';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ListOpportunityService from '../../services/ListOpportunityService';
import TimeLineDot from './TimeLineDot';
import { Stages } from '../../interfaces/property/Stages';
import { DealLineStepNames } from '../../constants/wizardSteps';
import FeatureState from '../../context/state/FeatureState';

interface TimeLineProps {
    showTreeDotsMenu: boolean;
    showChartScale: boolean;
    listOfStages: Array<Stages>;
    currentStateIndex: number | null;
}

const TimeLine: FunctionComponent<TimeLineProps> = ({
    showChartScale,
    showTreeDotsMenu,
    listOfStages,
    currentStateIndex,
}: TimeLineProps) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        [stageNames, setStaggesName] = useState<any>(),
        { Step } = Steps;

    const fetchListOpportunityStage = useCallback(async () => {
        try {
            const stages = await ListOpportunityService.fetchListOpportunityStage();
            setStaggesName(stages);
        } catch (e: any) {
            toast(t(`b:${e.response.data.errorMessage}`));
        }
    }, [t]);

    const checkIfExists = () => {
        let exists = '';
        if (listOfStages.length === 0) {
            exists = 'd-none';
        }

        return exists;
    };

    const customDot = (dot, { status, index }) => (
        <div className="relative">
            <div
                className={`property-hero__timeline-dot property-hero__timeline-dot--main-bg ${
                    currentStateIndex === index ? 'property-hero__timeline-dot--active-bg' : ''
                }`}
            />
        </div>
    );

    useEffect(() => {
        fetchListOpportunityStage();
    }, [fetchListOpportunityStage]);

    return (
        <div className={`deadline mx-50 ${checkIfExists()}`}>
            {showTreeDotsMenu && (
                <div className="col-xs-12-clear mb-30 d-flex align-items-center justify-content-space-between">
                    <p className="heading">{t('f:transaction.dealTimeline')}</p>
                    <TreeDotsForEDIT
                        url={`/property/${propertySummary.externalId}/deal-line/${DealLineStepNames.MARKETING_TIMELINE}`}
                    />
                </div>
            )}

            <Steps
                className="dealine__ant-steps mt-30"
                responsive={true}
                current={listOfStages.length}
                progressDot={customDot}
            >
                {listOfStages.map((elem, i) => {
                    return (
                        <Step
                            key={i}
                            description={
                                <TimeLineDot stageNames={stageNames} stage={elem} isOnPropertySummary={false} />
                            }
                        />
                    );
                })}
            </Steps>
        </div>
    );
};
export default TimeLine;
