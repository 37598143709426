import { FunctionComponent, useEffect, useCallback, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropertyCard from '../../../components/shared/PropertyCard';
import Property from '../../../services/Property';
import PortfolioService from '../../../services/PortfolioService';
import Form from 'antd/lib/form/Form';
import { PortfolioValidation } from '../../../validations/PortfolioValidation';
import { Field, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { Upload, Button, Tooltip } from 'antd';
import FileUploadService from '../../../services/FileUploadService';
import _ from 'lodash';
import ImgCrop from 'antd-img-crop';
import { Pagination } from 'antd';
import MainHeading from '../../../components/shared/MainHeading';
import MainButton from '../../../components/shared/MainButton';
import MainLoader from '../../../components/shared/MainLoader';
import FeatureState from '../../../context/state/FeatureState';

const Portfolio: FunctionComponent<any> = (props) => {
    const { t } = useTranslation(['f']),
        history = useHistory(),
        [listOfProperties, setListOfProperties] = useState<Array<number>>([]),
        { propertySummary } = useContext(FeatureState),
        { id, externalId } = propertySummary,
        [uploadImage, SetUploadImage] = useState<string>(''),
        [name, setName] = useState<string>(''),
        [isLoading, setIsLoading] = useState(true),
        [currentPage, setCurrentPage] = useState<number>(1),
        [pageSize, setPageSize] = useState<number>(12),
        [currentPageMyAssets, setCurrentPageMyAssets] = useState<number>(1),
        [pageSizeMyAssets, setPageSizeMyAssets] = useState<number>(12),
        [optionsProperties, setOptionsProperties] = useState<any>([]),
        [optionsMyProperties, setOptionsMyProperties] = useState<any>([]),
        [searchText, setSearchText] = useState<string>('');

    const fetchAllMyPropertyAssets = useCallback(
        async (searchText: string) => {
            const properties = await Property.fetchMyProperty(searchText, currentPage - 1, pageSize);
            setIsLoading(true);
            _.remove(properties, { isPortfolio: true });
            setOptionsProperties(properties);

            if (id) {
                let portfolioProperties = await PortfolioService.fetchAssetsOfPortfolio(
                    `${id}`,
                    searchText,
                    currentPageMyAssets - 1,
                    pageSizeMyAssets
                );

                let portfolioItems: any = listOfProperties;
                portfolioProperties.items.map((elem) => {
                    portfolioItems.push(elem.id);
                });
                setListOfProperties(portfolioItems);

                setOptionsMyProperties(portfolioProperties);

                let portFolioName = await PortfolioService.fetchPortfolioInformation(`${id}`);
                setName(portFolioName.name);
                SetUploadImage(portFolioName.imageUrl);
            }

            setIsLoading(false);
        },
        [currentPage, currentPageMyAssets]
    );

    const handlePageChange = (page: number, pageSize: any, optionsProperties: any) => {
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handlePageMyAssetsChange = (page: number, pageSize: any, optionsProperties: any) => {
        setCurrentPageMyAssets(page);
        setPageSizeMyAssets(pageSize);
    };

    const updatePortfolio = useCallback(async (body) => {
        await PortfolioService.updatePortfolio(`${id}`, body);
    }, []);

    const customRequest = async (options: any) => {
        try {
            const data = new FormData();
            data.append('Image', options.file);
            const imageUrl = await FileUploadService.uploadFile(options, data);
            SetUploadImage(imageUrl);
        } catch (err) {
            console.log(err);
        }
    };

    const searchPropertyAssets = (event: any) => {
        setSearchText(event.target.value);
        setCurrentPage(1);
        setCurrentPageMyAssets(1);
        fetchAllMyPropertyAssets(event.target.value);
    };

    const onNextHandler = async (name: any, items: any, image: any) => {
        const body = {
            name: name,
            singleAssetIds: items,
            imageUrl: image,
        };

        try {
            if (id) {
                updatePortfolio(body);
                history.push('/property/' + externalId);
            } else {
                const portfolio = await PortfolioService.createPortfolio(body);
                history.push('/property/' + portfolio);
            }
        } catch (error: any) {
            toast(error.respons.data.errorMessage);
        }
    };

    useEffect(() => {
        fetchAllMyPropertyAssets(searchText);
    }, [currentPage, currentPageMyAssets]);

    return (
        <div className="wizard-container-steps portfolio">
            <MainHeading text={t(`f:property.allProperties`)} />

            <form className="portfolio-search ">
                <div className="marketing__body-search-portfolio d-flex">
                    <div className="marketing__body-search-portfolio-top d-flex">
                        <p className="marketing__label">{t(`f:search.searchAndFind`)}</p>
                        <input
                            type="text"
                            name="searchBy"
                            className="marketing__input-portfolio"
                            placeholder={t(`f:search.searchPortfolioPlaceholder`)}
                            onChange={searchPropertyAssets}
                        />
                    </div>
                    <MainButton
                        size="main"
                        layout="primary"
                        disabled={true}
                        classAdditions="marketing__btn"
                        type="submit"
                        buttonText={<FontAwesomeIcon icon={['fas', 'search']} size="lg" />}
                    />
                </div>
            </form>
            <div className="portfolio__footer">
                {!isLoading ? (
                    <Formik
                        initialValues={{
                            portfolioName: name,
                            listOfProperties: listOfProperties,
                            imageUrl: uploadImage,
                        }}
                        enableReinitialize={true}
                        validationSchema={PortfolioValidation}
                        onSubmit={async (values: any) => {
                            onNextHandler(values.portfolioName, listOfProperties, uploadImage);
                        }}
                    >
                        {({ values, setFieldValue, errors, resetForm, submitForm }) => (
                            <Form>
                                <div className="portfolio__form-content">
                                    <div className="portfolio__main d-flex ">
                                        {optionsProperties?.items.map((element) => {
                                            return (
                                                <div className="portfolio__single-element">
                                                    <PropertyCard
                                                        property={element}
                                                        key={element.id}
                                                        showFavorite={false}
                                                    />
                                                    <div className="portfolio__single-element-button">
                                                        {listOfProperties.includes(element.id) ? (
                                                            <MainButton
                                                                size="main"
                                                                buttonText={''}
                                                                layout="primary"
                                                                classAdditions="marketing__plus marketing__plus--green"
                                                                buttonHandler={() => {
                                                                    const new_arr = listOfProperties.filter(
                                                                        (id) => id !== element.id
                                                                    );
                                                                    setListOfProperties(new_arr);
                                                                }}
                                                            />
                                                        ) : (
                                                            <MainButton
                                                                size="main"
                                                                buttonText={<FontAwesomeIcon icon={faPlus} />}
                                                                layout="primary"
                                                                classAdditions="marketing__plus"
                                                                buttonHandler={() => {
                                                                    setListOfProperties([
                                                                        ...listOfProperties,
                                                                        element.id,
                                                                    ]);
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className="pagination">
                                            <Pagination
                                                defaultCurrent={1}
                                                total={optionsProperties.totalCount}
                                                onChange={(page, pageSize) => {
                                                    handlePageChange(page, pageSize, optionsProperties);
                                                }}
                                                current={currentPage}
                                                pageSize={pageSize}
                                                pageSizeOptions={['12']}
                                                className="mt-30"
                                            />
                                        </div>
                                    </div>
                                    {errors.listOfProperties && (
                                        <p className="bid-info-page__error-mess">
                                            {t(`f:portfolioPage.list`)} is reqiered
                                        </p>
                                    )}
                                    {id ? (
                                        <div>
                                            <MainHeading text={t(`f:property.inPortfolio`)} />
                                            <div className="portfolio__main d-flex ">
                                                {optionsMyProperties?.items?.map((element) => {
                                                    return (
                                                        <div className="portfolio__single-element">
                                                            <PropertyCard
                                                                property={element}
                                                                key={element.id}
                                                                showFavorite={false}
                                                            />
                                                            <div className="portfolio__single-element-button">
                                                                {listOfProperties.includes(element.id) ? (
                                                                    <MainButton
                                                                        size="main"
                                                                        classAdditions="marketing__plus marketing__plus--green"
                                                                        buttonHandler={() => {
                                                                            const new_arr = listOfProperties.filter(
                                                                                (id) => id !== element.id
                                                                            );
                                                                            setListOfProperties(new_arr);
                                                                        }}
                                                                        layout="primary"
                                                                        buttonText={''}
                                                                    />
                                                                ) : (
                                                                    <MainButton
                                                                        size="main"
                                                                        classAdditions="marketing__plus"
                                                                        buttonHandler={() => {
                                                                            setListOfProperties([
                                                                                ...listOfProperties,
                                                                                element.id,
                                                                            ]);
                                                                        }}
                                                                        layout="primary"
                                                                        buttonText={<FontAwesomeIcon icon={faPlus} />}
                                                                    />
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                <div className="pagination">
                                                    <Pagination
                                                        defaultCurrent={1}
                                                        total={optionsMyProperties.totalCount}
                                                        onChange={(page, pageSize) => {
                                                            handlePageMyAssetsChange(
                                                                page,
                                                                pageSize,
                                                                optionsMyProperties
                                                            );
                                                        }}
                                                        current={currentPageMyAssets}
                                                        pageSize={pageSizeMyAssets}
                                                        pageSizeOptions={['12']}
                                                        className="mt-30"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div className="d-flex flex-direction-column">
                                        <div className="col-md-4 form-group mb-30 mt-50">
                                            <label htmlFor="text">{t('f:portfolioPage.portfolioName')}</label>
                                            <Field
                                                id="text"
                                                name="portfolioName"
                                                onChange={(elem) => {
                                                    setName(elem.target.value);
                                                }}
                                                placeholder={t('f:portfolioPage.portfolioName')}
                                            />
                                            {errors.portfolioName && (
                                                <p className="bid-info-page__error-mess">
                                                    {t(errors.portfolioName, {
                                                        name: t('f:portfolioPage.name'),
                                                    })}
                                                </p>
                                            )}
                                        </div>
                                        <div className="col-md-6 d-flex flex-direction-column mt-20 mb-30">
                                            <MainHeading text={t('f:portfolioPage.uploadHeader')} />
                                            {uploadImage ? (
                                                <img
                                                    src={uploadImage}
                                                    className="newsletter-builder__def-image"
                                                    alt="property"
                                                />
                                            ) : (
                                                <br />
                                            )}
                                            <ImgCrop rotate aspect={36 / 13} quality={1}>
                                                <Upload
                                                    name="Image"
                                                    className="add-property-image-builder__portfolio"
                                                    showUploadList={false}
                                                    action={process.env.REACT_APP_API_URL + `/propertyasset/image`}
                                                    customRequest={(option) => {
                                                        customRequest(option);
                                                    }}
                                                >
                                                    <Tooltip
                                                        placement="bottomRight"
                                                        color="#fd615a"
                                                        title={t('f:property.photoDimensionRecommended')}
                                                    >
                                                        <Button>
                                                            <p className="teams-export__icon">
                                                                {uploadImage
                                                                    ? t('f:button.edit')
                                                                    : t('f:button.upload')}
                                                            </p>
                                                        </Button>
                                                    </Tooltip>
                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                    </div>
                                    <div className="col-md-12 form-group mb-100"></div>
                                </div>

                                <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                                    <p className="previous-page" onClick={() => history.goBack()}>
                                        {t('f:button.previousStep')}
                                    </p>
                                    <MainButton
                                        size="main"
                                        layout="secondary"
                                        buttonText={t('f:button.cancel')}
                                        buttonHandler={() => {
                                            history.push(`/`);
                                        }}
                                    />
                                    <MainButton
                                        size="main"
                                        layout="primary"
                                        buttonText={t('f:button.next')}
                                        classAdditions="mr-50"
                                        buttonHandler={submitForm}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <MainLoader type="regular" />
                )}
            </div>
        </div>
    );
};
export default Portfolio;
