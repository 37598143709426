import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import CashFlowFaqQuestion from '../../../../interfaces/cashFlow/cashFlowFaqQuestion';
import { AddQuestionSchema } from '../../../../validations/AddQuestion';
import PropertyFaq from '../../../../services/PropertyFaq';
import ModalCloseButton from '../../../../components/shared/ModalCloseButton';
import { Actions } from '../../../../interfaces/general/general';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';

interface Props {
    propertyAssetId: string;
    isModalQuestionOpened: boolean;
    setIsAddEditTOExistingTopicModalOpenedActions: Actions;
    editableQuestion?: any;
    fetchAssetFaq: () => any;
    topicId: any;
}

const AddEditQuestionToExistingTopic: FunctionComponent<Props> = (props) => {
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);
    const [resetInputFile, setResetInputFile] = useState<any>();
    const { t } = useTranslation(['f', 'b']);

    const uploadFileHandler = (event: any) => {
        setUploadedFiles([
            ...uploadedFiles,
            {
                questionIndex: uploadedFiles.length,
                file: event.target.files[0],
            },
        ]);
    };

    const closeModalQuestionHandler = (resetForm: any) => {
        props.setIsAddEditTOExistingTopicModalOpenedActions.setFalse();
        resetForm();
        setResetInputFile(Date.now());
    };

    const onCancelQuestionHanlder = () => {
        props.setIsAddEditTOExistingTopicModalOpenedActions.setFalse();

        setResetInputFile(Date.now());
    };

    /* Modal for adding queston to the topic */
    return (
        <ModalWrapper
            isModalOpened={props.isModalQuestionOpened}
            isCloseModalShown={false}
            closeModalHandler={onCancelQuestionHanlder}
            title={t('f:faq.addQuestion')}
            className="cf-modal"
            isFooterShown={false}
        >
            <Formik
                initialValues={{
                    question: '',
                    answer: '',
                }}
                validationSchema={AddQuestionSchema}
                enableReinitialize
                onSubmit={async (values: CashFlowFaqQuestion, { resetForm }) => {
                    const config = {
                        headers: {
                            'content-type': 'multipart/form-data',
                        },
                    };

                    let formData = new FormData();
                    if (uploadedFiles.length > 0) {
                        formData.append('File', uploadedFiles[uploadedFiles.length - 1].file);
                    }
                    formData.append('Question', values.question);
                    formData.append('Answer', values.answer);
                    await PropertyFaq.createFaqQuestion(props.propertyAssetId, props.topicId, formData, config);
                    props.fetchAssetFaq();

                    props.setIsAddEditTOExistingTopicModalOpenedActions.setFalse();
                    setUploadedFiles([]);
                    resetForm();
                }}
            >
                {({ resetForm, errors, touched }) => (
                    <Form>
                        <ModalCloseButton handler={() => closeModalQuestionHandler(resetForm)} />

                        <div className="form-group">
                            <label htmlFor="question">{t('f:cashFlow.question')}</label>
                            <Field id="question" name="question" placeholder={t('f:cashFlow.topicQuestion')} />
                            {errors.question && touched.question ? (
                                <span className="error-text">
                                    {t(errors.question, { name: t('f:cashFlow.question') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="form-group">
                            <label htmlFor="answer">{t('f:cashFlow.answer')}</label>
                            <Field
                                as="textarea"
                                id="answer"
                                name="answer"
                                placeholder={t('f:cashFlow.answerQuestion')}
                                className="mb-0"
                            />
                            {errors.answer && touched.answer ? (
                                <span className="error-text">{t(errors.answer, { name: t('f:cashFlow.answer') })}</span>
                            ) : null}
                        </div>

                        <input
                            key={resetInputFile}
                            style={{ paddingLeft: '0' }}
                            className="mb-10 mt-20"
                            type="file"
                            name="file"
                            onChange={(event: any) => uploadFileHandler(event)}
                        />

                        <div className="cf-modal__buttons m-auto">
                            <button className="button-main button-primary mxl-30" type="submit">
                                {t('f:button.save')}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    );
};

export default AddEditQuestionToExistingTopic;
