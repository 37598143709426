import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Card } from 'antd';
import { FunctionComponent } from 'react';
import {
    AzureMap,
    AzureMapsProvider,
    IAzureMapOptions,
    AzureMapPopup,
    AzureMapLayerProvider,
    AzureMapDataSourceProvider,
    IAzureMapControls,
    IAzureMapFeature,
    AzureMapFeature,
    AzureSetCameraOptions,
} from 'react-azure-maps';

import { AuthenticationType, ControlOptions, data, MapMouseEvent, PopupOptions, Options } from 'azure-maps-control';
import IMapLocation from '../../interfaces/property/MapLocation';
import _ from 'lodash';

interface Props {
    shouldCluster: boolean;
    allowInteraction: boolean;
    showPopup: boolean;
    showOnlyAddress: boolean;
    locations: IMapLocation[];
    location?: any;
    searchLocations?: any;
    onMapClicked?: (e: any) => void;
}

const defaultCenterPositionSingle = {
    lat: 1.33,
    long: 103,
    zoom: 4,
};

const defaultCenterPositionMultiple = {
    lat: 1.33,
    long: 103,
    zoom: 2,
};

const defaultCameraOption = {
    type: 'ease',
    minZoom: 1,
    maxZoom: 24,
    duration: 200,
    view: 'Auto',
};

const { Meta } = Card;
function mouseOn(e: any) {
    e.map.getCanvas().style.cursor = 'pointer';
}

function mouseLeave(e: any) {
    e.map.getCanvas().style.cursor = '';
}

function clusterClicked(e: any) {
    if (e && e.shapes && e.shapes.length > 0 && e.shapes[0].properties.cluster) {
        //Get the clustered point from the event.
        const cluster = e.shapes[0];

        //Get the cluster expansion zoom level. This is the zoom level at which the cluster starts to break apart.
        e.map.sources
            .getById('BubbleLayer DataSourceProvider')
            .getClusterExpansionZoom(cluster.properties.cluster_id)
            .then(function (zoom: any) {
                //Update the map camera to be centered over the cluster.
                e.map.setCamera({
                    center: cluster.geometry.coordinates,
                    zoom: zoom,
                    type: 'ease',
                    duration: 200,
                });
            });
    }
}

function pinClicked(e: any) {
    console.log('clicked', e);
    if (e && e.shapes && e.shapes.length > 0) {
        //Get the clustered point from the event.
        const cluster = e.shapes[0];
        //todo do action
    }
}

const renderPoint = (location: IMapLocation): IAzureMapFeature => {
    return (
        <AzureMapFeature
            key={location.id}
            id={location.id}
            type='Point'
            coordinate={location.coordinates}
            properties={location}
        />
    );
};

const bubbleLayerOptions = {
    //Scale the size of the clustered bubble based on the number of points inthe cluster.
    radius: [
        'step',
        ['get', 'point_count'],
        20, //Default of 20 pixel radius.
        100,
        30, //If point_count >= 100, radius is 30 pixels.
        750,
        40, //If point_count >= 750, radius is 40 pixels.
    ],

    //Change the color of the cluster based on the value on the point_cluster property of the cluster.
    color: [
        'step',
        ['get', 'point_count'],
        'rgba(0,255,0,0.8)', //Default to green.
        100,
        'rgba(255,255,0,0.8)', //If the point_count >= 100, color is yellow.
        750,
        'rgba(255,0,0,0.8)', //If the point_count >= 100, color is red.
    ],
    strokeWidth: 0,
    filter: ['has', 'point_count'], //Only rendered data points which have a point_count property, which clusters do.
};

const controls: IAzureMapControls[] = [
    {
        controlName: 'ZoomControl',
        options: { position: 'top-right' } as ControlOptions,
    },
];

const PropertyAssetMap: FunctionComponent<Props> = (props) => {
    const [popupOptions, setPopupOptions] = useState<PopupOptions>({});
    const [popupProperties, setPopupProperties] = useState<IMapLocation>();
    const [isVisible, setIsVisible] = useState(false);
    const [cameraOptions, setCameraOptions] = useState<AzureSetCameraOptions>({});
    const [locations, SetLocations] = useState<any>([]);

    const renderPointAddProperty = () => {
        let pinProperties: Options = { address: props.location.address };
        return props.location ? (
            <AzureMapFeature
                properties={pinProperties}
                key={props.location.long}
                id={'0'}
                type='Point'
                coordinate={[props.location.long, props.location.lat]}
            />
        ) : (
            <></>
        );
    };

    const renderPointsSearch = useCallback(
        (locations: any) => {
            let allLocations = _.map(locations, (location, index) => {
                let pinProperties: Options = { address: `${location.propertyName} | ${location.address}` };
                return location ? (
                    <AzureMapFeature
                        properties={pinProperties}
                        key={index}
                        id={`${index}`}
                        type='Point'
                        coordinate={[location.long, location.lat]}
                    />
                ) : (
                    <></>
                );
            });
            return allLocations;
        },
        [locations]
    );

    const [location, setLocation] = useState<any>([]);
    const history = useHistory();

    const memoizedMarkerRender: IAzureMapFeature[] = useMemo(
        (): IAzureMapFeature[] => props.locations.map((marker) => renderPoint(marker)),
        [props.locations]
    );

    const redirectToProperty = (propertyId) => {
        history.push(`/property/${propertyId}`);
    };

    const memoizedMapAddressPopup = useMemo(
        () => (
            <AzureMapPopup
                isVisible={isVisible}
                options={popupOptions}
                popupContent={
                    <div style={wrapperStyles.popupStyles}>
                        <span>{popupProperties?.address}</span>
                    </div>
                }
            />
        ),
        [isVisible, popupProperties]
    );

    const memoizedMapCardPopup = useMemo(
        () => (
            <AzureMapPopup
                isVisible={isVisible}
                options={popupOptions}
                popupContent={
                    <Card
                        hoverable
                        title={popupProperties?.name}
                        style={{ width: 350 }}
                        cover={<img alt={popupProperties?.name} src={popupProperties?.imageUrl} />}>
                        <Meta
                            description={
                                <>
                                    <span>{popupProperties?.address}</span>
                                    <button
                                        className='button-primary'
                                        type='submit'
                                        onClick={() => {
                                            redirectToProperty(popupProperties?.id);
                                        }}>
                                        Deal room
                                    </button>
                                </>
                            }
                        />
                    </Card>
                }
            />
        ),
        [isVisible, popupProperties]
    );

    const option: IAzureMapOptions = useMemo(() => {
        return {
            authOptions: {
                authType: AuthenticationType.subscriptionKey,
                subscriptionKey: process.env.REACT_APP_AZURE_MAP_KEY,
            },
            center: [
                props.location ? props.location.long : defaultCenterPositionSingle.long,
                props.location ? props.location.lat : defaultCenterPositionSingle.lat,
            ],
            zoom: defaultCenterPositionSingle.zoom,
            renderWorldCopies: true,
            showFeedbackLink: false,
            showLogo: false,
            interactive: props.allowInteraction,
            view: 'Auto',
        };
    }, []);

    useEffect(() => {
        if (props.location) {
            const cameraOption = {
                center: [props.location.long, props.location.lat],
                zoom: 10,
            };

            setLocation(() => renderPointAddProperty());

            setCameraOptions(cameraOption);
        }
    }, [props.location]);

    useEffect(() => {
        setCameraOptions(defaultCameraOption);

    }, []);


    useEffect(() => {
        if (props.searchLocations && props.searchLocations.length > 0) {

            var atlasPoints = props.searchLocations.map(x => (new data.Position(x.long, x.lat)));
            const boundingBox = data.BoundingBox.fromPositions(atlasPoints);
            const centerOfBox = data.BoundingBox.getCenter(boundingBox);

            const first = {
                type: 'ease',
                duration: 200,
                view: 'Auto',
            };
            const option = { ...first, bounds: boundingBox, padding: 100, center: centerOfBox };

            setCameraOptions(option);
            SetLocations(() => renderPointsSearch(props.searchLocations));
        }
    }, [props.searchLocations]);

    const mapClicked = useCallback(
        (e: any) => {
            if (props.allowInteraction && props.onMapClicked) {
                props.onMapClicked(e);
            }
        }, []
    );

    return (
        <div style={wrapperStyles.map}>
            <AzureMapsProvider>
                <div style={{ height: '100%' }}>
                    <AzureMap
                        options={option}
                        controls={controls}
                        cameraOptions={cameraOptions}
                        events={{
                            click: mapClicked
                        }}
                    >
                        <AzureMapDataSourceProvider
                            id={'BubbleLayer DataSourceProvider'}
                            options={{
                                //Tell the data source to cluster point data.
                                cluster: props.shouldCluster,

                                //The radius in pixels to cluster points together.
                                clusterRadius: 45,

                                //The maximium zoom level in which clustering occurs.
                                //If you zoom in more than this, all points are rendered as symbols.
                                clusterMaxZoom: 15,
                            }}>
                            <AzureMapLayerProvider
                                id={'BubbleLayer LayerProvider'}
                                options={bubbleLayerOptions}
                                type='BubbleLayer'
                                events={{
                                    mouseenter: mouseOn,
                                    mouseleave: mouseLeave,
                                    click: clusterClicked,
                                }}></AzureMapLayerProvider>
                            <AzureMapLayerProvider
                                id={'BubbleLayer2 LayerProvider'}
                                options={{
                                    iconOptions: {
                                        image: 'none', //Hide the icon image.
                                    },
                                    textOptions: {
                                        textField: ['get', 'point_count_abbreviated'],
                                        offset: [0, 0.4],
                                    },
                                }}
                                type='SymbolLayer'></AzureMapLayerProvider>

                            <AzureMapLayerProvider
                                id={'BubbleLayer3 LayerProvider'}
                                events={{
                                    click: pinClicked,
                                    mousemove: (e: MapMouseEvent) => {
                                        if (e.shapes && e.shapes.length > 0 && props.showPopup) {
                                            const prop: any = e.shapes[0];
                                            setIsVisible(true);
                                            // Set popup options
                                            setPopupOptions({
                                                ...popupOptions,
                                                position: new data.Position(
                                                    prop.data.geometry.coordinates[0],
                                                    prop.data.geometry.coordinates[1]
                                                ),
                                                pixelOffset: [0, -18],
                                            });
                                            if (prop.data.properties)
                                                setPopupProperties({
                                                    ...prop.data.properties,
                                                    data: prop.data,
                                                });
                                        }
                                    },
                                }}
                                options={{
                                    iconOptions: {
                                        image: 'marker-red',
                                    },

                                    filter: ['!', ['has', 'point_count']], //Filter out clustered points from this layer.
                                }}
                                type='SymbolLayer'></AzureMapLayerProvider>
                            {memoizedMarkerRender}
                            {location}
                            {locations}
                        </AzureMapDataSourceProvider>
                        {props.showOnlyAddress ? memoizedMapAddressPopup : memoizedMapCardPopup}
                    </AzureMap>
                </div>
            </AzureMapsProvider>
        </div>
    );
};

export const wrapperStyles = {
    map: {
        height: '500px',
    },
    popupStyles: {
        padding: '20px',
        color: 'black',
    },
};

export default memo(PropertyAssetMap);
