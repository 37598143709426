import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Formik, Field, Form } from 'formik';
import firstOptionNDA from '../../assets/images/firstOptionNDA.png';
import { Tooltip } from 'antd';
import secondOptionNDA from '../../assets/images/secondOptionNDA.png';
import { useHistory } from 'react-router-dom';
import ExecuteNdaService from '../../services/ExecuteNdaService';
import MainHeading from './MainHeading';
import MainButton from './MainButton';
import { Actions } from '../../interfaces/general/general';
import useBoolean from '../../hooks/use-boolean';
import FeatureState from '../../context/state/FeatureState';

interface Props {
    setIsModalOpen: Actions;
    showMainContent: boolean;
    setShowMainContent: Actions;
}

const NdaOptions: FunctionComponent<Props> = ({ setIsModalOpen, setShowMainContent, showMainContent }) => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        [isStandardNDASelected, setIsStandardNDASelected] = useState<boolean>(true),
        [successfullySent, setSuccessfullySent] = useState<boolean>(false),
        { propertySummary } = useContext(FeatureState),
        [isSubmitDisabled, isSubmitDisabledActions] = useBoolean(),
        { id } = propertySummary;

    const toggleModalSize = (max: number, min: number) => {
        const modal = document.querySelector('.nda-options-modal');
        const content: any = modal?.querySelector('.ant-modal-content');
        if (content) {
            content.style.minHeight = `${min}px`;
            content.style.maxHeight = `${max}px`;
        }
    };

    const handleOnCancel = useCallback(() => {
        setIsModalOpen.setFalse();
        setShowMainContent.setFalse();
        // toggleModalSize(500, 550);
    }, [setShowMainContent, setIsModalOpen]);

    const handleEmailSign = useCallback(async () => {
        isSubmitDisabledActions.setTrue();
        try {
            await ExecuteNdaService.createNdaForProperty(`${id}`, { customNda: false });
            setSuccessfullySent(true);
            toast(`${t('f:signNDA.emailSend')}`);
            isSubmitDisabledActions.setFalse();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
        setIsModalOpen.setFalse();
        setShowMainContent.setFalse();
        // toggleModalSize(500, 550);
    }, [id, t, setIsModalOpen, setShowMainContent]);

    const handleOnGetStartedClick = useCallback(async () => {
        const secondOption = () => {
            setShowMainContent.setTrue();
            // toggleModalSize(250, 280);
        };

        isStandardNDASelected ? secondOption() : history.push(`/preview/${id}`);
    }, [history, id, isStandardNDASelected, setShowMainContent]);

    return (
        <>
            {!showMainContent ? (
                <div>
                    <MainHeading text={t('f:button.executeNDA')} />
                    <p className="signNDAModal__subtitle signNDAModal__subtitle--left mt-50">
                        {t('f:signNDA.subtitle')}
                    </p>
                    <Formik initialValues={{}} onSubmit={async (values) => {}}>
                        {({ values }) => (
                            <Form className="signNDAModal__form">
                                <div className="signNDAModal__fields">
                                    <label
                                        className="property__radio property-type__radio property__radio--bigger"
                                        onClick={() => {
                                            setIsStandardNDASelected(false);
                                        }}
                                    >
                                        <Field
                                            type="radio"
                                            checked={!isStandardNDASelected}
                                            style={{ marginRight: 0 }}
                                        />
                                        <div className="property__radio-option">
                                            <div className="d-flex justify-content-space-between">
                                                <figure>
                                                    <img src={secondOptionNDA} alt="Negotiate NDA" />
                                                </figure>
                                                <div className="property__radio-select"></div>
                                            </div>
                                            <h5>{t('f:signNDA.opt2')}</h5>
                                            <p>{t('f:signNDA.opt2text')}</p>
                                        </div>
                                    </label>
                                    <label
                                        className="property__radio property-type__radio property__radio--bigger"
                                        onClick={() => {
                                            setIsStandardNDASelected(true);
                                        }}
                                    >
                                        <Field type="radio" checked={isStandardNDASelected} />
                                        <div className="property__radio-option">
                                            <div className="d-flex justify-content-space-between">
                                                <figure>
                                                    <img src={firstOptionNDA} alt="Standard NDA" />
                                                </figure>
                                                <div className="property__radio-select"></div>
                                            </div>
                                            <h5>{t('f:signNDA.opt1')}</h5>
                                            <p>{t('f:signNDA.opt1text')}</p>
                                        </div>
                                    </label>
                                </div>
                                <div className="signNDAModal__form__buttons">
                                    <MainButton
                                        size="main"
                                        type="submit"
                                        layout="primary"
                                        buttonHandler={handleOnGetStartedClick}
                                        buttonText={t('f:button.getStarted')}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <div className="signNDAModal__huge">
                    <MainHeading text={t('f:signNDA.ExecuteNDAgreement')} addClasses="mb-20" />
                    {successfullySent && (
                        <p className="signNDAModal__subtitle signNDAModal__subtitle--left mt-50">
                            {t('f:signNDA.emailSend')}
                        </p>
                    )}
                    {!successfullySent && (
                        <p className="signNDAModal__subtitle signNDAModal__subtitle--left mt-50">
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece
                            of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock,
                            a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure
                            Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the
                            word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from
                            sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and
                            Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very
                            popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit
                            amet..", comes from a line in section 1.10.32.
                        </p>
                    )}
                    <div className="col-xs-12 d-flex justify-content-space-around mt-40">
                        <MainButton
                            size="main"
                            layout="secondary"
                            type="button"
                            buttonText={t('f:button.cancel')}
                            buttonHandler={handleOnCancel}
                        />

                        <Tooltip placement="bottomRight" color="#fd615a" title={t('f:signNDA.emailWillBeeSend')}>
                            <MainButton
                                size="main"
                                type="submit"
                                layout="primary"
                                buttonText={t('f:signNDA.sendRequest')}
                                disabled={isSubmitDisabled}
                                buttonHandler={handleEmailSign}
                            />
                        </Tooltip>
                    </div>
                </div>
            )}
        </>
    );
};
export default NdaOptions;
