import { useRef, useLayoutEffect, useContext } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import TemplateContextState from '../../../../context/state/TemplateContextState';

am4core.useTheme(am4themes_animated);

const LineRentChart = (props) => {
    const chartRef = useRef<am4charts.XYChart | null>(null),
        { chartData, chartSource } = useContext(TemplateContextState);

    function processOver(hoveredSeries, chart) {
        hoveredSeries.toFront();

        hoveredSeries.segments.each(function (segment) {
            segment.setState('hover');
        });

        hoveredSeries.legendDataItem.marker.setState('default');
        hoveredSeries.legendDataItem.label.setState('default');
        if (chart) {
            chart.series.each(function (series) {
                if (series != hoveredSeries) {
                    series.segments.each(function (segment) {
                        segment.setState('dimmed');
                    });
                    series.bulletsContainer.setState('dimmed');
                    series.legendDataItem.marker.setState('dimmed');
                    series.legendDataItem.label.setState('dimmed');
                }
            });
        }
    }

    function processOut(chart) {
        chart.series.each(function (series) {
            series.segments.each(function (segment) {
                segment.setState('default');
            });

            series.bulletsContainer.setState('default');
            series.legendDataItem.marker.setState('default');
            series.legendDataItem.label.setState('default');
        });
    }

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);

        var chart = am4core.create(
            props.showMainData ? `grossRentDiv${props.mainData.ID}` : 'grossRentDiv',
            am4charts.XYChart
        );

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        const lineData: any = chartData;

        const convert_valies: any = props.showMainData
            ? props.mainData.chart.map((elem) => {
                  const single = { name: elem.name };
                  const dots = elem.dots.map((dot) => {
                      return { date: new Date(dot.date), value: Number(dot.value) };
                  });
                  single['dots'] = dots;
                  return single;
              })
            : lineData.map((elem) => {
                  const single = { name: elem.name };
                  const dots = elem.dots.map((dot) => {
                      return { date: new Date(dot.date), value: Number(dot.value) };
                  });
                  single['dots'] = dots;
                  return single;
              });

        convert_valies.map((element, i) => {
            var chartLine1 = chart.series.push(new am4charts.LineSeries());
            chartLine1.dataFields.valueY = 'value';
            chartLine1.dataFields.dateX = 'date';
            chartLine1.name = element.name;
            chartLine1.strokeWidth = 3;
            chartLine1.bullets.push(new am4charts.CircleBullet());
            chartLine1.fill = am4core.color('#fff');
            chartLine1.tooltipText = `${element.name} {value}`;
            chartLine1.strokeDasharray = '5,4';
            chartLine1.data = element.dots;
        });

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.fullWidthLineX = true;
        chart.cursor.xAxis = dateAxis;
        chart.cursor.lineX.strokeWidth = 0;
        chart.cursor.lineX.fill = am4core.color('#000');
        chart.cursor.lineX.fillOpacity = 0.1;

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div className="d-flex flex-direction-column align-items-center">
            <div
                id={props.showMainData ? `grossRentDiv${props.mainData.ID}` : 'grossRentDiv'}
                style={{ width: '100%', height: '500px' }}
            ></div>
            <label>{props.showMainData ? props.mainData.source : chartSource}</label>
        </div>
    );
};

export default LineRentChart;
