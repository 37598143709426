import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Actions } from '../../../../../interfaces/general/general';
import MainButton from '../../../../../components/shared/MainButton';
import InvestorService from '../../../../../services/InvestorsService';
import { Upload } from 'antd';
import FeatureState from '../../../../../context/state/FeatureState';

interface ManageButtonsProps {
    setIsModalOpened: Actions;
    customRequest: (opt: any) => void;
}

const ManageButtons: FunctionComponent<ManageButtonsProps> = ({
    setIsModalOpened,
    customRequest,
}: ManageButtonsProps) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary;

    const onDownloadExportFile = useCallback(async () => {
        try {
            await InvestorService.exportInvestors(`${propertyAssetId}`);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const handleOnClickOpenModal = useCallback(() => {
        setIsModalOpened.setTrue();
    }, [setIsModalOpened]);

    return (
        <div className="d-flex mb-20 mt-20 justify-content-end mr-80">
            <div className="d-flex ml-5">
                <Upload
                    name="Files"
                    action={process.env.REACT_APP_API_URL + `/investor/excel/${propertyAssetId}`}
                    customRequest={customRequest}
                    multiple
                    showUploadList={false}
                >
                    <MainButton
                        layout="secondary"
                        buttonHandler={() => {}}
                        size="small"
                        buttonText={
                            <>
                                {t('f:button.import')}
                                <FontAwesomeIcon className="teams-export__icon" icon={faUpload} size="sm" />
                            </>
                        }
                        classAdditions="teams-button teams-button--pading-left mr-2"
                    />
                </Upload>
                <a
                    href={InvestorService.templateUrl()}
                    className="button-secondary button-small teams-button teams-button--pading-left mr-10 d-flex justify-content-center align-items-center"
                    download
                >
                    {t('f:button.template')}
                    <FontAwesomeIcon className="teams-export__icon" icon={faDownload} size="sm" />
                </a>
                <MainButton
                    layout="secondary"
                    buttonHandler={onDownloadExportFile}
                    size="small"
                    buttonText={
                        <>
                            {t('f:button.export')}
                            <FontAwesomeIcon className="teams-export__icon" icon={faDownload} size="sm" />
                        </>
                    }
                    classAdditions="teams-button teams-button--pading-left mr-2"
                />
                <MainButton
                    size="small"
                    layout="secondary"
                    buttonHandler={handleOnClickOpenModal}
                    buttonText={
                        <>
                            {t('f:button.addInvestor')}
                            <FontAwesomeIcon className="teams-export__icon" icon={faPlus} size="sm" />
                        </>
                    }
                    classAdditions="teams-button teams-button--pading-left mr-2 d-flex"
                />
            </div>
        </div>
    );
};
export default ManageButtons;
