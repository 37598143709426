import { FunctionComponent } from 'react';
import MainHeading from '../../../../components/shared/MainHeading';
import BarRecordChart from './BRC';

interface AnaliysisBarChartViewProps {
    text: string;
    data: Array<BarData>;
}

interface BarData {
    country: string;
    visits: number;
}

const AnaliysisBarChartView: FunctionComponent<AnaliysisBarChartViewProps> = ({
    data,
    text,
}: AnaliysisBarChartViewProps) => (
    <div className="col-xs-12 mt-40">
        <MainHeading text={text} />
        <BarRecordChart id={text} chart={data} />
    </div>
);
export default AnaliysisBarChartView;
