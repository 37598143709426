import { useEffect, useState, useCallback, useContext, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import BidService from '../../services/BidService';
import FeatureState from '../../context/state/FeatureState';
import TimeLine from './TimeLine';
import { Stages } from '../../interfaces/property/Stages';
import CreateDataForEmptySection from './CreateDataForEmptySection';
import MainLoader from './MainLoader';
import { DealLineStepNames } from '../../constants/wizardSteps';

interface DeadlineProps {
    showChartScale: boolean;
    showTreeDotsMenu: boolean;
    stages?: Array<Stages>;
}

const Deadline: FunctionComponent<DeadlineProps> = ({
    showTreeDotsMenu,
    showChartScale,
    stages,
}: DeadlineProps) => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        { getFeature, propertySummary } = useContext(FeatureState),
        [isLoading, setIsLoading] = useState<boolean>(false),
        { id: propertyAssetId, externalId } = propertySummary,
        [currentStateIndex, setCurrentStateIndex] = useState<number | null>(null),
        [listOfStages, setListOfStages] = useState<Array<Stages>>(stages ? stages : []);

    const featchMarketingTimeline = useCallback(async () => {
        try {
            setIsLoading(true);
            const stages = await BidService.fetchStages(`${propertyAssetId}`);
            setListOfStages(stages);
            if (stages.length) {
                const today = new Date().getTime();
                let isCurrentStage = false;
                stages.forEach((element, i) => {
                    if (
                        new Date(element.startDate).getTime() <= today &&
                        new Date(element.closeDate).getTime() >= today
                    ) {
                        isCurrentStage = true;
                        setCurrentStateIndex(i);
                    }
                });

                if (!isCurrentStage) {
                    const next = stages.find((element, i) => {
                        if (new Date(element.startDate).getTime() > today) {
                            setCurrentStateIndex(i);
                        }
                        return new Date(element.startDate).getTime() > today;
                    });
                    if (!next) setCurrentStateIndex(stages.length - 1);
                }
            }
            setIsLoading(false);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const createDealTimeLine = () => {
        history.push(`/property/${externalId}/deal-line/${DealLineStepNames.MARKETING_TIMELINE}`);
    };

    useEffect(() => {
        if (!stages || !stages.length) {
            featchMarketingTimeline();
        }
    }, [featchMarketingTimeline]);

    return (
        <>
            {!isLoading ? (
                <div className="mt-40 create-deal-timeline">
                    {getFeature('ViewAllBids') && (
                        <div className="col-xs-12">
                            {listOfStages.length === 0 && (
                                <CreateDataForEmptySection
                                    addClassToContainer="mx-50"
                                    handler={createDealTimeLine}
                                    text={t(`f:button.btnDealTimeline`)}
                                    mainText={t(`f:button.dealTimeline`)}
                                />
                            )}
                        </div>
                    )}
                    {listOfStages.length > 0 && (
                        <TimeLine
                            showChartScale={showChartScale}
                            showTreeDotsMenu={showTreeDotsMenu}
                            listOfStages={listOfStages}
                            currentStateIndex={currentStateIndex}
                        />
                    )}
                </div>
            ) : (
                <MainLoader type="regular" mb="mb-100" mt="mt-100" />
            )}
        </>
    );
};
export default Deadline;
