import { Select } from 'antd';
import { FunctionComponent, useCallback } from 'react';

const { Option } = Select;

interface Options {
    id: number;
    name: string;
    icon?: string;
    fullName?: string;
}

interface Props {
    label?: string;
    placeholder: string;
    options: Options[];
    selected?: any;
    onSelected: any;
    allowClear?: boolean;
    onClear?: () => void;
}

const SingleSelect: FunctionComponent<Props> = (props) => {
    const itemSelected = (value: any) => {
        props.onSelected(value);
    };

    const onClear = () => {
        if (!props.allowClear) {
            return;
        }

        props.onClear && props.onClear();
    };

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    return (
        <div className="row-flex-clear">
            <label className="col-xs-12-clear">{props.label}</label>
            <Select
                className="col-xs-12-clear"
                showSearch
                style={{ width: 200 }}
                placeholder={props.placeholder}
                optionLabelProp="label"
                showArrow
                defaultValue={props.selected}
                onSelect={itemSelected}
                value={props.selected}
                filterOption={(input, option) =>
                    option && option.label
                        ? option.label.toLocaleString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                }
                allowClear={props.allowClear}
                onClear={onClear}
                getPopupContainer={handleContainer}
            >
                {props.options.map((option) => (
                    <Option
                        value={option.id}
                        label={option.name ? option.name : option.fullName}
                        className="d-flex"
                        key={option.id}
                    >
                        <div className="select-checkbox"></div>
                        {option.icon && <img src={option.icon} alt="Option" />}
                        {option.name ? option.name : option.fullName}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default SingleSelect;
