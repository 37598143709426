import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import BaseWizardLayout from '../../components/shared/BaseWizardLayout';
import { ACWizardStepNames, wizardSteps } from '../../constants/wizardSteps';
import { WizardParams } from '../../interfaces/wizards';
import CompanyDetails from './CompanyDetails';
import Security from './Security';
import PersonaDetails from './PersonaDetails';
import PrivacyAndConcent from './PrivacyAndConcent';

const tabs = [
    {
        id: wizardSteps.first,
        step: `${ACWizardStepNames.PERSONAL_DETAILS}`,
        component: <PersonaDetails />,
    },
    // {
    //     id: wizardSteps.fifth,
    //     step: `${ACWizardStepNames.COMPANY_DETAILS}`,
    //     component: <CompanyDetails />,
    // },
    // {
    //     id: wizardSteps.third,
    //     step: `${ACWizardStepNames.SECURITY}`,
    //     component: <Security />,
    // },
    // {
    //     id: wizardSteps.fourth,
    //     step: `${ACWizardStepNames.PRIVACY_AND_CONCENT}`,
    //     component: <PrivacyAndConcent />,
    // },
];

const AccountSettings: FunctionComponent = () => {
    const { stepName } = useParams<WizardParams>();
    return <BaseWizardLayout stepName={stepName} tabs={tabs} />;
};
export default AccountSettings;
