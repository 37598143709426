import { useRef, useLayoutEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const overall = [0.0378,
    0.0396,
    0.0410,
    0.0415,
    0.0420,
    0.0398,
    0.0383,
    0.0383,
    0.0373,
    0.0367,
    0.0363,
    0.0362,
    0.0361,
    0.0355,
    0.0355,
    0.0356,
    0.0361,
    0.0362,
    0.0359,
    0.0356,
    0.0356,
    0.0356,
    0.0355,
    0.0355,
    0.0355,
    0.0342,
    0.0307,
    0.0300,
    0.0320,
    0.0344,
    0.0358];

const overallSeriesData: Array<any> =[{
    "date": new Date(2014, 1, 1),
    "value": overall[0]
  }, {
    "date": new Date(2014, 4, 1),
    "value": overall[1]
  }, {
    "date": new Date(2014, 7, 1),
    "value": overall[2]
  }, {
    "date": new Date(2014, 10, 1),
    "value": overall[3]
  },
  {
    "date": new Date(2015, 1, 1),
    "value": overall[4]
  }, {
    "date": new Date(2015, 4, 1),
    "value": overall[5]
  }, {
    "date": new Date(2015, 7, 1),
    "value": overall[6]
  }, {
    "date": new Date(2015, 10, 1),
    "value": overall[7]
  },
  {
    "date": new Date(2016, 1, 1),
    "value": overall[8]
  }, {
    "date": new Date(2016, 4, 1),
    "value": overall[9]
  }, {
    "date": new Date(2016, 7, 1),
    "value": overall[10]
  }, {
    "date": new Date(2016, 10, 1),
    "value": overall[11]
  },{
    "date": new Date(2017, 1, 1),
    "value": overall[12]
  }, {
    "date": new Date(2017, 4, 1),
    "value": overall[13]
  }, {
    "date": new Date(2017, 7, 1),
    "value": overall[14]
  }, {
    "date": new Date(2017, 10, 1),
    "value": overall[15]
  },
  {
    "date": new Date(2018, 1, 1),
    "value": overall[16]
  }, {
    "date": new Date(2018, 4, 1),
    "value": overall[17]
  }, {
    "date": new Date(2018, 7, 1),
    "value": overall[18]
  }, {
    "date": new Date(2018, 10, 1),
    "value": overall[19]
  },
  {
    "date": new Date(2019, 1, 1),
    "value": overall[20]
  }, {
    "date": new Date(2019, 4, 1),
    "value": overall[21]
  }, {
    "date": new Date(2019, 7, 1),
    "value": overall[22]
  }, {
    "date": new Date(2019, 10, 1),
    "value": overall[23]
  },{
    "date": new Date(2020, 1, 1),
    "value": overall[24]
  }, {
    "date": new Date(2020, 4, 1),
    "value": overall[25]
  }, {
    "date": new Date(2020, 7, 1),
    "value": overall[26]
  }, {
    "date": new Date(2020, 10, 1),
    "value": overall[27]
  },
  {
    "date": new Date(2021, 12, 1),
    "value": overall[28]
  }, {
    "date": new Date(2022, 12, 1),
    "value": overall[29]
  }, {
    "date": new Date(2023, 12, 1),
    "value": overall[30]
  }, {
    "date": new Date(2024, 12, 1),
    "value": overall[31]
  }];

const raffles: Array<any> = [0.0352,
    0.0367,
    0.0380,
    0.0386,
    0.0394,
    0.0380,
    0.0365,
    0.0365,
    0.0358,
    0.0348,
    0.0344,
    0.0343,
    0.0341,
    0.0336,
    0.0336,
    0.0337,
    0.0342,
    0.0343,
    0.0340,
    0.0338,
    0.0338,
    0.0338,
    0.0337,
    0.0337,
    0.0336,
    0.0321,
    0.0292,
    0.0285,
    0.0308,
    0.0329,
    0.0346];

const raffleSeriesData: Array<any> =[{
    "date": new Date(2014, 1, 1),
    "value": raffles[0]
  }, {
    "date": new Date(2014, 4, 1),
    "value": raffles[1]
  }, {
    "date": new Date(2014, 7, 1),
    "value": raffles[2]
  }, {
    "date": new Date(2014, 10, 1),
    "value": raffles[3]
  },
  {
    "date": new Date(2015, 1, 1),
    "value": raffles[4]
  }, {
    "date": new Date(2015, 4, 1),
    "value": raffles[5]
  }, {
    "date": new Date(2015, 7, 1),
    "value": raffles[6]
  }, {
    "date": new Date(2015, 10, 1),
    "value": raffles[7]
  },
  {
    "date": new Date(2016, 1, 1),
    "value": raffles[8]
  }, {
    "date": new Date(2016, 4, 1),
    "value": raffles[9]
  }, {
    "date": new Date(2016, 7, 1),
    "value": raffles[10]
  }, {
    "date": new Date(2016, 10, 1),
    "value": raffles[11]
  },{
    "date": new Date(2017, 1, 1),
    "value": raffles[12]
  }, {
    "date": new Date(2017, 4, 1),
    "value": raffles[13]
  }, {
    "date": new Date(2017, 7, 1),
    "value": raffles[14]
  }, {
    "date": new Date(2017, 10, 1),
    "value": raffles[15]
  },
  {
    "date": new Date(2018, 1, 1),
    "value": raffles[16]
  }, {
    "date": new Date(2018, 4, 1),
    "value": raffles[17]
  }, {
    "date": new Date(2018, 7, 1),
    "value": raffles[18]
  }, {
    "date": new Date(2018, 10, 1),
    "value": raffles[19]
  },
  {
    "date": new Date(2019, 1, 1),
    "value": raffles[20]
  }, {
    "date": new Date(2019, 4, 1),
    "value": raffles[21]
  }, {
    "date": new Date(2019, 7, 1),
    "value": raffles[22]
  }, {
    "date": new Date(2019, 10, 1),
    "value": raffles[23]
  },{
    "date": new Date(2020, 1, 1),
    "value": raffles[24]
  }, {
    "date": new Date(2020, 4, 1),
    "value": raffles[25]
  }, {
    "date": new Date(2020, 7, 1),
    "value": raffles[26]
  }, {
    "date": new Date(2020, 10, 1),
    "value": raffles[27]
  },
  {
    "date": new Date(2021, 12, 1),
    "value": raffles[28]
  }, {
    "date": new Date(2022, 12, 1),
    "value": raffles[29]
  }, {
    "date": new Date(2023, 12, 1),
    "value": raffles[30]
  }, {
    "date": new Date(2024, 12, 1),
    "value": raffles[31]
  }];

const shentonWay: Array<any> = [0.0374,
    0.0385,
    0.0389,
    0.0389,
    0.0391,
    0.0381,
    0.0373,
    0.0390,
    0.0389,
    0.0386,
    0.0384,
    0.0383,
    0.0382,
    0.0377,
    0.0376,
    0.0376,
    0.0382,
    0.0379,
    0.0376,
    0.0375,
    0.0375,
    0.0375,
    0.0372,
    0.0372,
    0.0371,
    0.0359,
    0.0318,
    0.0313,
    0.0333,
    0.0360,
    0.0373];

const shentonSeriesData: Array<any> =[{
  "date": new Date(2014, 1, 1),
  "value": shentonWay[0]
}, {
  "date": new Date(2014, 4, 1),
  "value": shentonWay[1]
}, {
  "date": new Date(2014, 7, 1),
  "value": shentonWay[2]
}, {
  "date": new Date(2014, 10, 1),
  "value": shentonWay[3]
},
{
  "date": new Date(2015, 1, 1),
  "value": shentonWay[4]
}, {
  "date": new Date(2015, 4, 1),
  "value": shentonWay[5]
}, {
  "date": new Date(2015, 7, 1),
  "value": shentonWay[6]
}, {
  "date": new Date(2015, 10, 1),
  "value": shentonWay[7]
},
{
  "date": new Date(2016, 1, 1),
  "value": shentonWay[8]
}, {
  "date": new Date(2016, 4, 1),
  "value": shentonWay[9]
}, {
  "date": new Date(2016, 7, 1),
  "value": shentonWay[10]
}, {
  "date": new Date(2016, 10, 1),
  "value": shentonWay[11]
},{
  "date": new Date(2017, 1, 1),
  "value": shentonWay[12]
}, {
  "date": new Date(2017, 4, 1),
  "value": shentonWay[13]
}, {
  "date": new Date(2017, 7, 1),
  "value": shentonWay[14]
}, {
  "date": new Date(2017, 10, 1),
  "value": shentonWay[15]
},
{
  "date": new Date(2018, 1, 1),
  "value": shentonWay[16]
}, {
  "date": new Date(2018, 4, 1),
  "value": shentonWay[17]
}, {
  "date": new Date(2018, 7, 1),
  "value": shentonWay[18]
}, {
  "date": new Date(2018, 10, 1),
  "value": shentonWay[19]
},
{
  "date": new Date(2019, 1, 1),
  "value": shentonWay[20]
}, {
  "date": new Date(2019, 4, 1),
  "value": shentonWay[21]
}, {
  "date": new Date(2019, 7, 1),
  "value": shentonWay[22]
}, {
  "date": new Date(2019, 10, 1),
  "value": shentonWay[23]
},{
  "date": new Date(2020, 1, 1),
  "value": shentonWay[24]
}, {
  "date": new Date(2020, 4, 1),
  "value": shentonWay[25]
}, {
  "date": new Date(2020, 7, 1),
  "value": shentonWay[26]
}, {
  "date": new Date(2020, 10, 1),
  "value": shentonWay[27]
},
{
  "date": new Date(2021, 12, 1),
  "value": shentonWay[28]
}, {
  "date": new Date(2022, 12, 1),
  "value": shentonWay[29]
}, {
  "date": new Date(2023, 12, 1),
  "value": shentonWay[30]
}, {
  "date": new Date(2024, 12, 1),
  "value": shentonWay[31]
}];

const marina =[0.0374,
    0.0391,
    0.0406,
    0.0424,
    0.0435,
    0.0406,
    0.0386,
    0.0394,
    0.0393,
    0.0387,
    0.0385,
    0.0385,
    0.0383,
    0.0378,
    0.0378,
    0.0378,
    0.0383,
    0.0384,
    0.0382,
    0.0378,
    0.0378,
    0.0378,
    0.0377,
    0.0377,
    0.0377,
    0.0360,
    0.0325,
    0.0316,
    0.0336,
    0.0361,
    0.0380];

const marinaData: Array<any> = [{
    "date": new Date(2014, 1, 1),
    "value": marina[0]
  }, {
    "date": new Date(2014, 4, 1),
    "value": marina[1]
  }, {
    "date": new Date(2014, 7, 1),
    "value": marina[2]
  }, {
    "date": new Date(2014, 10, 1),
    "value": marina[3]
  },
  {
    "date": new Date(2015, 1, 1),
    "value": marina[4]
  }, {
    "date": new Date(2015, 4, 1),
    "value": marina[5]
  }, {
    "date": new Date(2015, 7, 1),
    "value": marina[6]
  }, {
    "date": new Date(2015, 10, 1),
    "value": marina[7]
  },
  {
    "date": new Date(2016, 1, 1),
    "value": marina[8]
  }, {
    "date": new Date(2016, 4, 1),
    "value": marina[9]
  }, {
    "date": new Date(2016, 7, 1),
    "value": marina[10]
  }, {
    "date": new Date(2016, 10, 1),
    "value": marina[11]
  },{
    "date": new Date(2017, 1, 1),
    "value": marina[12]
  }, {
    "date": new Date(2017, 4, 1),
    "value": marina[13]
  }, {
    "date": new Date(2017, 7, 1),
    "value": marina[14]
  }, {
    "date": new Date(2017, 10, 1),
    "value": marina[15]
  },
  {
    "date": new Date(2018, 1, 1),
    "value": marina[16]
  }, {
    "date": new Date(2018, 4, 1),
    "value": marina[17]
  }, {
    "date": new Date(2018, 7, 1),
    "value": marina[18]
  }, {
    "date": new Date(2018, 10, 1),
    "value": marina[19]
  },
  {
    "date": new Date(2019, 1, 1),
    "value": marina[20]
  }, {
    "date": new Date(2019, 4, 1),
    "value": marina[21]
  }, {
    "date": new Date(2019, 7, 1),
    "value": marina[22]
  }, {
    "date": new Date(2019, 10, 1),
    "value": marina[23]
  },{
    "date": new Date(2020, 1, 1),
    "value": marina[24]
  }, {
    "date": new Date(2020, 4, 1),
    "value": marina[25]
  }, {
    "date": new Date(2020, 7, 1),
    "value": marina[26]
  }, {
    "date": new Date(2020, 10, 1),
    "value": marina[27]
  },
  {
    "date": new Date(2021, 12, 1),
    "value": marina[28]
  }, {
    "date": new Date(2022, 12, 1),
    "value": marina[29]
  }, {
    "date": new Date(2023, 12, 1),
    "value": marina[30]
  }, {
    "date": new Date(2024, 12, 1),
    "value": marina[31]
  }];

const marinaBay =[0.0415,
    0.0440,
    0.0463,
    0.0467,
    0.0467,
    0.0430,
    0.0405,
    0.0392,
    0.0367,
    0.0360,
    0.0354,
    0.0354,
    0.0352,
    0.0346,
    0.0346,
    0.0348,
    0.0352,
    0.0354,
    0.0351,
    0.0348,
    0.0348,
    0.0348,
    0.0348,
    0.0348,
    0.0348,
    0.0336,
    0.0302,
    0.0295,
    0.0315,
    0.0339,
    0.0350];
    

const marinaBayData = [{
    "date": new Date(2014, 1, 1),
    "value": marinaBay[0]
  }, {
    "date": new Date(2014, 4, 1),
    "value": marinaBay[1]
  }, {
    "date": new Date(2014, 7, 1),
    "value": marinaBay[2]
  }, {
    "date": new Date(2014, 10, 1),
    "value": marinaBay[3]
  },
  {
    "date": new Date(2015, 1, 1),
    "value": marinaBay[4]
  }, {
    "date": new Date(2015, 4, 1),
    "value": marinaBay[5]
  }, {
    "date": new Date(2015, 7, 1),
    "value": marinaBay[6]
  }, {
    "date": new Date(2015, 10, 1),
    "value": marinaBay[7]
  },
  {
    "date": new Date(2016, 1, 1),
    "value": marinaBay[8]
  }, {
    "date": new Date(2016, 4, 1),
    "value": marinaBay[9]
  }, {
    "date": new Date(2016, 7, 1),
    "value": marinaBay[10]
  }, {
    "date": new Date(2016, 10, 1),
    "value": marinaBay[11]
  },{
    "date": new Date(2017, 1, 1),
    "value": marinaBay[12]
  }, {
    "date": new Date(2017, 4, 1),
    "value": marinaBay[13]
  }, {
    "date": new Date(2017, 7, 1),
    "value": marinaBay[14]
  }, {
    "date": new Date(2017, 10, 1),
    "value": marinaBay[15]
  },
  {
    "date": new Date(2018, 1, 1),
    "value": marinaBay[16]
  }, {
    "date": new Date(2018, 4, 1),
    "value": marinaBay[17]
  }, {
    "date": new Date(2018, 7, 1),
    "value": marinaBay[18]
  }, {
    "date": new Date(2018, 10, 1),
    "value": marinaBay[19]
  },
  {
    "date": new Date(2019, 1, 1),
    "value": marinaBay[20]
  }, {
    "date": new Date(2019, 4, 1),
    "value": marinaBay[21]
  }, {
    "date": new Date(2019, 7, 1),
    "value": marinaBay[22]
  }, {
    "date": new Date(2019, 10, 1),
    "value": marinaBay[23]
  },{
    "date": new Date(2020, 1, 1),
    "value": marinaBay[24]
  }, {
    "date": new Date(2020, 4, 1),
    "value": marinaBay[25]
  }, {
    "date": new Date(2020, 7, 1),
    "value": marinaBay[26]
  }, {
    "date": new Date(2020, 10, 1),
    "value": marinaBay[27]
  },
  {
    "date": new Date(2021, 12, 1),
    "value": marinaBay[28]
  }, {
    "date": new Date(2022, 12, 1),
    "value": marinaBay[29]
  }, {
    "date": new Date(2023, 12, 1),
    "value": marinaBay[30]
  }, {
    "date": new Date(2024, 12, 1),
    "value": marinaBay[31]
  }];

am4core.useTheme(am4themes_animated);
const MarketYieldChart = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);

    function processOver(hoveredSeries, chart) {
        hoveredSeries.toFront();

        hoveredSeries.segments.each(function (segment) {
            segment.setState("hover");
        })

        hoveredSeries.legendDataItem.marker.setState("default");
        hoveredSeries.legendDataItem.label.setState("default");
        if (chart) {
            chart.series.each(function (series) {
                if (series != hoveredSeries) {
                    series.segments.each(function (segment) {
                        segment.setState("dimmed");
                    })
                    series.bulletsContainer.setState("dimmed");
                    series.legendDataItem.marker.setState("dimmed");
                    series.legendDataItem.label.setState("dimmed");
                }
            });
        }

    }

    function processOut(chart) {
        chart.series.each(function (series) {
            series.segments.each(function (segment) {
                segment.setState("default");
            })

            series.bulletsContainer.setState("default");
            series.legendDataItem.marker.setState("default");
            series.legendDataItem.label.setState("default");
        });
    }


    useLayoutEffect(() => {
        let chart = am4core.create("marketYieldChartDiv", am4charts.XYChart);

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        chart.dateFormatter.dateFormat = "yyyy-q";
        chart.numberFormatter.numberFormat = "#.##%";

        var overallSeries = chart.series.push(new am4charts.LineSeries());
        overallSeries.dataFields.valueY = "value";
        overallSeries.dataFields.dateX = "date";
        overallSeries.name = "Overall";
        overallSeries.strokeWidth = 3;
        overallSeries.tensionX = 0.8;
        overallSeries.bullets.push(new am4charts.CircleBullet());

        overallSeries.data = overallSeriesData;

        var rafflesSeries = chart.series.push(new am4charts.LineSeries());
        rafflesSeries.dataFields.valueY = "value";
        rafflesSeries.dataFields.dateX = "date";
        rafflesSeries.name = "Raffles";
        rafflesSeries.strokeWidth = 3;
        rafflesSeries.tensionX = 0.8;
        rafflesSeries.bullets.push(new am4charts.CircleBullet());

        rafflesSeries.data = raffleSeriesData;

        var shentonWaySeries = chart.series.push(new am4charts.LineSeries());
        shentonWaySeries.dataFields.valueY = "value";
        shentonWaySeries.dataFields.dateX = "date";
        shentonWaySeries.name = "Shenton Way";
        shentonWaySeries.strokeWidth = 3;
        shentonWaySeries.tensionX = 0.8;
        shentonWaySeries.bullets.push(new am4charts.CircleBullet());
        shentonWaySeries.data = shentonSeriesData;

        var marinaSeries = chart.series.push(new am4charts.LineSeries());
        marinaSeries.dataFields.valueY = "value";
        marinaSeries.dataFields.dateX = "date";
        marinaSeries.name = "Marina";
        marinaSeries.strokeWidth = 3;
        marinaSeries.tensionX = 0.8;
        marinaSeries.bullets.push(new am4charts.CircleBullet());
        marinaSeries.data = marinaData;

        var marinaBaySeries = chart.series.push(new am4charts.LineSeries());
        marinaBaySeries.dataFields.valueY = "value";
        marinaBaySeries.dataFields.dateX = "date";
        marinaBaySeries.name = "Marina Bay";
        marinaBaySeries.strokeWidth = 3;
        marinaBaySeries.tensionX = 0.8;
        marinaBaySeries.bullets.push(new am4charts.CircleBullet());
        marinaBaySeries.data = marinaBayData;

        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.scrollable = true;

        // setTimeout(function() {
        //   chart.legend.markers.getIndex(0).opacity = 0.3;
        // }, 3000)
        chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
        chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

        chart.legend.itemContainers.template.events.on("over", function (event) {
            if (event && event?.target && event?.target?.dataItem)
                processOver(event.target.dataItem.dataContext, chart);
        })

        chart.legend.itemContainers.template.events.on("out", function (event) {
            processOut(chart);
        })

        chartRef.current = chart;

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div id="marketYieldChartDiv" style={{ width: "100%", height: "500px" }}></div>
    );
}

export default MarketYieldChart;
