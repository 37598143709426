import { FunctionComponent } from 'react';
import TradeProbabilityCard from '../../../pages/TradeProbability/components/TradeProbabilityCard';
import TradeProbabilityInfo from '../../../pages/TradeProbability/models/tradeProbabilityInfo';
import BuildingDirectoryInfo from '../../../pages/BuildingDirectory/models/buildingDirectoryInfo';
import IPropertyInfo from './iPropertyInfo';
import BuildingDirectoryCard from '../../../pages/BuildingDirectory/components/BuildingDirectoryCard';

interface Props {
    property: IPropertyInfo;
}

const MapPropertyPopup: FunctionComponent<Props> = ({ property }) => {
    if (property instanceof BuildingDirectoryInfo) {
        return <BuildingDirectoryCard buildingDirectory={property}/>
    }
    
    if (property instanceof TradeProbabilityInfo) {
        return <TradeProbabilityCard tradeProbability={property}/>
    }

    return null;
};

export default MapPropertyPopup;
