import api from '../index';

const sendResearchContactForm = (propertyAssetId: string, body: any) => {
    return api.post(`propertyassetresearch/${propertyAssetId}/contact`, body);
};
const ResearchApi = {
    sendResearchContactForm,
};

export default ResearchApi;
