import React, { FunctionComponent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useBoolean from '../hooks/use-boolean';
import TradeProbability from '../pages/TradeProbability/models/tradeProbability';
import TradeProbabilityState from './state/TradeProbabilityState';
import TradeProbabilityService from '../services/TradeProbabilityService';

interface Props {
    children: React.ReactNode;
}

const TradeProbabilityContext: FunctionComponent<Props> = ({ children }) => {
    const [tradeProbability, setTradeProbability] = useState<TradeProbability>(),
        { t } = useTranslation(['f', 'b']),
        [isLoading, IsLoadingActions] = useBoolean();

    const fetchTradeProbability = useCallback(
        async (id: string) => {
            IsLoadingActions.setTrue();
            try {
                const response = await TradeProbabilityService.fetchTradeProbability(id);
                setTradeProbability(response);
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
            IsLoadingActions.setFalse();
        }, []);

    return (
        <TradeProbabilityState.Provider
            value={{
                fetchTradeProbability,
                setTradeProbability,
                tradeProbability,
                isLoading
            }}
        >
            {children}
        </TradeProbabilityState.Provider>
    );
};

export default TradeProbabilityContext;
