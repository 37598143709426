import { FunctionComponent } from 'react';
import CheckAuthorization from '../StatusPages/CheckAuthorization';
import TradeProbabilityDetails from './TradeProbabilityDetails';
import SearchingMap from './SearchingMap';
import TradeProbabilityContext from '../../context/TradeProbabilityContext';

const TradeProbability: FunctionComponent<any> = () => {
    return (
        <CheckAuthorization>
            <div className="container building-directory">
                <SearchingMap>
                    <TradeProbabilityContext>
                        <TradeProbabilityDetails />
                    </TradeProbabilityContext>
                </SearchingMap>
            </div>
        </CheckAuthorization>
    );
};
export default TradeProbability;
