import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import WizardWithSidebar from '../../components/shared/WizardWithSidebar';
import { DealLineStepNames } from '../../constants/wizardSteps';
import MarketingTimeline from './components/MarketingTimeline';

export const DeadLineSteps: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']);

    const tabs = [
        {
            id: 1,
            name: t('f:listOpportunity.marketingTimeline'),
            step: DealLineStepNames.MARKETING_TIMELINE,
            component: <MarketingTimeline />,
        },
    ];

    return <WizardWithSidebar tabs={tabs} />;
};
export default DeadLineSteps;
