import { FunctionComponent, useState } from 'react';
import TemplateContextState from './state/TemplateContextState';
import {
    PieChartInterface,
    LineChartInterfaceShort,
    BarChartInterfaceShort,
    BarInitInterface,
} from '../interfaces/chart/ChartInterface';
import useBoolean from '../hooks/use-boolean';

interface TemplateContextProps {
    children: React.ReactNode;
}

const TemplateContext: FunctionComponent<TemplateContextProps> = ({ children }: TemplateContextProps) => {
    const [chart, setChart] = useState<Array<LineChartInterfaceShort> | BarInitInterface | Array<PieChartInterface>>(
            []
        ),
        [newChartData, setNewChart] = useState<
            Array<LineChartInterfaceShort> | BarInitInterface | Array<PieChartInterface>
        >([]),
        [isPreviewShow, setIsPreviewShow] = useState<boolean>(false),
        [dateType, setDateType] = useState<number>(1),
        [chartTitle, setChartTitle] = useState<string>(''),
        [newChartTitle, setNewChartTitle] = useState<string>(''),
        [chartDataRdy, setChartDataActions] = useBoolean(),
        [isForEdit, isForEditActions] = useBoolean(),
        [chartType, setChartType] = useState<number | undefined>(undefined),
        [orderBy, setOrderBy] = useState<Array<number>>([0]),
        [chartSource, setChartSource] = useState<string>(''),
        [newChartSource, setNewChartSource] = useState<string>(''),
        [lines, setLines] = useState<Array<string>>([]);

    const handleOnClickAddDate = () => {
        const new_array = [...orderBy];
        new_array.push(new_array.length);

        setOrderBy(new_array);
    };

    const handleOnClickDeleteDate = () => {
        const new_array = [...orderBy];
        new_array.pop();

        setOrderBy(new_array);
    };

    const handlePieFinish = (data: Array<PieChartInterface>) => {
        setChart(data);
        setNewChart(data);
    };

    const handleLineFinish = (data: Array<LineChartInterfaceShort>) => {
        setChart(data);
        setNewChart(data);
    };

    const handleBarFinish = (data: BarInitInterface) => {
        setLines(data.bars.map((el: BarChartInterfaceShort) => el.name));

        setChart(data);
        setNewChart(data);
    };

    return (
        <TemplateContextState.Provider
            value={{
                chartData: chart,
                isPreviewShow: isPreviewShow,
                handleLineFinish: handleLineFinish,
                setIsPreviewShow: setIsPreviewShow,
                handleBarFinish: handleBarFinish,
                setChartTitle: setChartTitle,
                handlePieFinish: handlePieFinish,
                chartTitle: chartTitle,
                handleOnClickAddDate: handleOnClickAddDate,
                handleOnClickDeleteDate: handleOnClickDeleteDate,
                dateType: dateType,
                setDateType: setDateType,
                lines: lines,
                isForEdit,
                isForEditActions,
                orderBy: orderBy,
                chartType,
                setChartType,
                chartSource,
                setChartSource,
                newChartData,
                setChart,
                setNewChart,
                newChartTitle,
                setNewChartTitle,
                newChartSource,
                setNewChartSource,
                chartDataRdy,
                setChartDataActions,
            }}
        >
            {children}
        </TemplateContextState.Provider>
    );
};

export default TemplateContext;
