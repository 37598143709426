import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import PropertyFaq from '../../../../services/PropertyFaq';

import ModalCloseButton from '../../../../components/shared/ModalCloseButton';
import MainButton from '../../../../components/shared/MainButton';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';
import FeatureState from '../../../../context/state/FeatureState';

interface AddEditTopicProps {
    isEditModalOpened: boolean;
    setIsEditModalOpened: (prev: boolean) => void;
    topicName: string;
    topicId: string | undefined;
    fetchTopics: () => void;
    deleteTopicHandler: (topicId: string) => void;
}

const AddEditTopic: FunctionComponent<AddEditTopicProps> = ({
    isEditModalOpened,
    setIsEditModalOpened,
    topicName,
    topicId,
    fetchTopics,
    deleteTopicHandler,
}: AddEditTopicProps) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary;

    const handleOnClose = useCallback(() => {
        setIsEditModalOpened(false);
    }, [setIsEditModalOpened]);

    const handleOnDelete = () => {
        if (topicId) {
            deleteTopicHandler(topicId);
        }
    }

    return (
        <ModalWrapper
            className="cf-modal"
            isCloseModalShown={false}
            isModalOpened={isEditModalOpened}
            title={t('f:faq.editTopic')}
            isFooterShown={false}
            closeModalHandler={handleOnClose}
        >
            <Formik
                initialValues={{
                    topic: topicName,
                }}
                enableReinitialize
                onSubmit={async (values: any, { setSubmitting }: FormikHelpers<any>) => {
                    if (topicId) {
                        await PropertyFaq.updateTopic(`${propertyAssetId}`, { name: values.topic, id: topicId });
                    } else {
                        await PropertyFaq.createFaqTopic(`${propertyAssetId}`, { name: values.topic });
                    }
                    setIsEditModalOpened(false);
                    fetchTopics();
                }}
            >
                <Form>
                    <ModalCloseButton handler={handleOnClose} />

                    <div className="form-group">
                        <label htmlFor="topic">{t('f:cashFlow.topic')}</label>
                        <Field id="topic" name="topic" placeholder={t('f:cashFlow.topicName')} />
                    </div>
                    <div className="d-flex gap-20 cf-modal__buttons m-auto mt-50">
                        <MainButton
                            layout="secondary"
                            buttonText={t('f:button.removeTopic')}
                            buttonHandler={handleOnDelete}
                            size="main"
                        />
                        <MainButton layout="primary" buttonText={t('f:button.save')} type="submit" size="main" />
                    </div>
                </Form>
            </Formik>
        </ModalWrapper>
    );
};

export default AddEditTopic;
