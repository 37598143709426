import { BaseTransformer } from './BaseTransformer'
import PropertyLookupModel from '../models/PropertyLookupModel'

class PropertyLookupTransformer extends BaseTransformer {
    constructor() {
        super();
        this.transformSingle = this.transformSingle.bind(this);
        this.transformMultiple = this.transformMultiple.bind(this);
	}
    
      transformSingle(data = {}) {
        const lookup = new PropertyLookupModel(data);
        return lookup;
	}
    
      transformMultiple(data = []) {
        const lookups = data?.map(item => new PropertyLookupModel(item));
        return lookups;
	}
}

export default new PropertyLookupTransformer()

