import api from '../index';

const featchSettings = () => {
    return api.get(`/data-analytic-page-builder/setting`);
};

const createPage = (body: any) => {
    return api.post('/data-analytic-page-builder/pages', body);
};

const editPage = (body: any) => {
    return api.put('/data-analytic-page-builder/pages', body);
};

const fetchPages = () => {
    return api.get('/data-analytic-page-builder/pages');
};

const deletePage = (pageId: string) => {
    return api.delete(`/data-analytic-page-builder/pages/${pageId}`);
};

const editPageStatusActive = (pageId: string) => {
    return api.put(`/data-analytic-page-builder/pages/${pageId}/activate`);
};

const editPageStatusDeactive = (pageId: string) => {
    return api.put(`/data-analytic-page-builder​/pages​/${pageId}​/deactivate`);
};

const createSection = (pageId: string, body: any) => {
    return api.post(`/data-analytic-page-builder/pages/${pageId}/page-sections`, body);
};
const editSection = (pageId: string, body: any) => {
    return api.put(`/data-analytic-page-builder/pages/${pageId}/page-sections`, body);
};
const featchSections = (pageId: string) => {
    return api.get(`/data-analytic-page-builder/pages/${pageId}/page-sections`);
};
const deleteSection = (pageId: string, sectionId: string) => {
    return api.delete(`/data-analytic-page-builder/pages/${pageId}/page-sections/${sectionId}`);
};

const createSectionData = (pageId: string, pageSectionId: string, body: any) => {
    return api.post(`/data-analytic-page-builder/pages/${pageId}/page-sections/${pageSectionId}/data`, body);
};
const editSectionData = (pageId: string, pageSectionId: string, dataId: string, body: any) => {
    return api.put(`/data-analytic-page-builder/pages/${pageId}/page-sections/${pageSectionId}/data/${dataId}`, body);
};
const featchSectionsData = (pageId: string, pageSectionId: string, dataId: string) => {
    return api.get(`/data-analytic-page-builder/pages/${pageId}/page-sections/${pageSectionId}/data/${dataId}`);
};
const deleteSectionData = (pageId: string, pageSectionId: string, dataId: string) => {
    return api.delete(`/data-analytic-page-builder/pages/${pageId}/page-sections/${pageSectionId}/data/${dataId}`);
};

const DataAnalyticPageBuilderApi = {
    createPage,
    editPage,
    fetchPages,
    deletePage,
    editPageStatusActive,
    editPageStatusDeactive,
    createSection,
    editSection,
    featchSections,
    deleteSection,
    createSectionData,
    deleteSectionData,
    editSectionData,
    featchSectionsData,
    featchSettings,
};

export default DataAnalyticPageBuilderApi;
