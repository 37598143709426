import { FunctionComponent, useState } from 'react';
import RadioList from '../../../../components/shared/RadioList';

const items = [
    {
        key: 'map_1',
        value: 'Major Commercial Buildings in Singapore',
        description: 'Location of all Major Commercial Buildings in Singapore ',
        url: 'https://data.gov.sg/dataset/heritage-trees/resource/07df4d4c-274e-4b3a-af1d-7f79e43800d4/view/36d40023-2329-4a1f-9ea7-b49a680bc227',
    },
    {
        key: 'map_2',
        value: 'Number of Commercial Offices Constructed ',
        url: 'https://data.gov.sg/dataset/flats-constructed/resource/4af42bbf-1ac0-46bf-9318-6efa6aa475a7/view/227d6df7-bff5-4974-b61c-11106652a249',
        description: 'Trend of the Commerical Offices Constructed in Singapore',
    },
    {
        key: 'map_3',
        value: 'Total Land Area of Singapore',
        url: 'https://data.gov.sg/dataset/total-land-area-of-singapore/resource/f4bbfac9-c3ed-4f71-9b9a-238517b214ef/view/06c9e9c5-84af-4220-8f1c-3698d6ee727c',
        description: 'Trend of the Growth of the Land Area in Singapore ',
    },
    {
        key: 'map_4',
        value: 'Number of Tenant Applications by Office Type',
        url: 'https://data.gov.sg/dataset/number-of-resale-applications-registered-by-flat-type-quarterly/resource/144dbc6b-6113-4fa4-a583-c471eafce539/view/43c48137-373d-4769-996d-c07963c245c0',
        description: 'Trend of the Growth of the Number of Tenant Applications by Office Type in Singapore',
    },
    {
        key: 'map_5',
        value: 'Approval of Land Lots',
        url: 'https://data.gov.sg/dataset/corporate-performance-approval-of-land-lots/resource/18a0acf4-b487-400a-a552-ca489ab5f724/view/f7c51c8e-009f-4a4f-a2bf-8e2b97d400da',
        description: 'Trend of the Approval of Land Lots in Singapore ',
    },
    {
        key: 'map_6',
        value: 'Private Commercial Property Rental Index ',
        url: 'https://data.gov.sg/dataset/rental-index-by-type-quarterly/resource/83fea7ab-d033-4898-a6fc-0e12d73fe416/view/2af01789-09a1-47f3-b3f3-83355bbfcc79',
        description: 'Trend of the Private Commercial Property Rental Index in Singapore',
    },
];

const Maps: FunctionComponent<any> = (props) => {
    const [selected, setSelected] = useState<any>(items[0]);

    const itemSelected = (value: any) => {
        let selectedItem = items.filter((x) => x.key === value)[0];
        setSelected(selectedItem);
    };

    return (
        <section className="module-iframe-map">
            <div className="block-contain-map">
                <div className="block-menu-checkbox">
                    <h3>Maps </h3>
                    <div className="list-maps">
                        <RadioList onSelected={itemSelected} selected={selected} items={items} isHorizontal={false} />
                    </div>
                </div>
                <div className="map-container">
                    <div className="map">{selected && <iframe src={selected.url} frameBorder="0"></iframe>}</div>
                    <div className="map-info">
                        <div className="name">{selected?.value}</div>
                        <div className="desc">{selected?.description}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Maps;
