import documentApi from '../documents';
import DataNode from '../../interfaces/dataRoom/dataNode';
import { UsersWithPermissions } from '../../interfaces/dataRoom/usersWithPermissions';

const fetchDataRoom = (id: string) => {
    return documentApi.get<Array<DataNode>>(`/TreeStructure/${id}`);
};

const fetchUsersWithPermissions = (nodeId: string) => {
    return documentApi.get<UsersWithPermissions>(`/Permission/Users?nodeId=${nodeId}`);
};

const addUserPermission = (userId: string, nodeId: string, permission: number) => {
    //will delete permission and set again
    const body = {
        userId: userId,
        nodeId: nodeId,
        permission: permission,
    };
    return documentApi.put<any>(`/Permission`, body);
};

const removeUserPermission = (userId: string, nodeId: string, permission: number) => {
    const body = {
        userId: userId,
        nodeId: nodeId,
        permission: permission,
    };
    return documentApi.delete<any>(`/Permission`, { data: body });
};

const removeAccessForFolder = (userId: string, nodeId: string) => {
    const body = {
        userId: userId,
        nodeId: nodeId,
    };
    return documentApi.delete<any>(`/PermissionAll`, { data: body });
};
const searchDataRoom = (id: string, keyword: string) => {
    return documentApi.get<Array<DataNode>>(`/Search/${id}?keyword=${keyword}`);
};

const addFolderToDataRoom = (id: string, newName: string, parentNodeId: string | null) => {
    const body = {
        name: newName,
        referenceId: id,
        parentId: parentNodeId,
    };

    return documentApi.post<any>(`/Folder`, body);
};

const renameFolderInDataRoom = (id: string, name: string) => {
    const body = {
        newName: name,
        folderId: id,
    };

    return documentApi.put<any>(`/Folder`, body);
};

const removeFromDataRoom = (id: string) => {
    return documentApi.delete<any>(`/Nodes/${id}`);
};

const uploadFile = (options: any, data: any) => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'text/plain',
        },
    };

    return documentApi.post(options.action, data, config);
};

const DataRoomApi = {
    fetchDataRoom,
    addUserPermission,
    addFolderToDataRoom,
    removeAccessForFolder,
    fetchUsersWithPermissions,
    uploadFile,
    removeFromDataRoom,
    removeUserPermission,
    searchDataRoom,
    renameFolderInDataRoom,
};

export default DataRoomApi;
