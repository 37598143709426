import moment from 'moment';
import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MainCheckbox from '../../../../../../components/shared/MainCheckbox';
import { TableTypes } from '../../../../../../constants/wizardSteps';
import FeatureState from '../../../../../../context/state/FeatureState';
import LOWizardState from '../../../../../../context/state/LOWizardState';
import { CompanyDropdownUser, EmailHistory } from '../../../../../../interfaces/investor/CompanyWithInvestor';
import InvestorService from '../../../../../../services/InvestorsService';
import TreeDotsInvestors from '../../partials/TreeDotsInvestors';
import MainTableBodyCell from './MainTableBodyCell';
import MainTableHeadCell from './MainTableHeadCell';

interface DropdownUserTableInSingleItemProps {
    users: Array<CompanyDropdownUser>;
    type: TableTypes.INVESTORS_TAB | TableTypes.LIST_OPPORTUNITY;
    id: number;
    refetch: () => void;
}

const DropdownUserTableInSingleItem: FunctionComponent<DropdownUserTableInSingleItemProps> = ({
    users,
    type,
    id,
    refetch,
}) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        { selectedUsersId, setSelectedUsersId } = useContext(LOWizardState);

    const displayEmailHistory = useCallback(
        (emails: Array<EmailHistory>) => {
            return emails.length
                ? `${emails[0].isSuccess ? t('f:teams.yes') : t('f:teams.no')} - ${moment(
                      new Date(emails[0].dateSent)
                  ).calendar()}`
                : t('f:teams.no');
        },
        [t]
    );

    const removeInvestorsSingleUser = useCallback(
        async (email: string) => {
            try {
                await InvestorService.removeUserFromInvestor(`${propertyAssetId}`, [email]);
                refetch();
            } catch (e: any) {
                toast(t(`b:${e.response.data.errorMessage}`));
            }
        },
        [t, propertyAssetId]
    );

    const sendSingleEmail = useCallback(
        async (email: string) => {
            try {
                await InvestorService.sendEmails(`${propertyAssetId}`, [email]);

                refetch();
            } catch (e: any) {
                toast(t(`b:${e.response.data.errorMessage}`));
            }
        },
        [t, propertyAssetId]
    );

    const toggleNDA = useCallback(
        async (id) => {
            try {
                await InvestorService.toggleNDAstatusUser(`${propertyAssetId}`, id);
                refetch();
            } catch (e: any) {
                toast(t(`b:${e.response.data.errorMessage}`));
            }
        },
        [propertyAssetId]
    );

    return (
        <div className={`investors-table__addition-table isTableShown mr-20`}>
            <div className="investors-table__head">
                <div className="d-flex investors-table__row investors-table__row--small investors-table__row--bg">
                    <div className="d-flex investors-table__row-single investors-table__row-single--line-height">
                        <div className="investors-table__separate-17" />
                        {t('f:teams.name')}
                    </div>

                    <div className="d-flex investors-table__row-single investors-table__row-single--line-height">
                        <div className="investors-table__h-line" />
                        {t('f:teams.email')}
                    </div>

                    <div className="d-flex investors-table__row-single investors-table__row-single--line-height">
                        <div className="investors-table__h-line" />
                        {t('f:teams.emailSent')}
                    </div>

                    <MainTableHeadCell text={t('f:teams.actions')} isActions={true} />
                </div>
            </div>
            {users.map((elem, i: number) => (
                <div
                    className="d-flex investors-table__row investors-table__row--small investors-table__row--body"
                    key={i}
                >
                    <div className="investors-table__main-p investors-table__row-single investors-table__row-single--body">
                        {type === TableTypes.LIST_OPPORTUNITY && (
                            <MainCheckbox
                                onClick={() => {
                                    if (selectedUsersId.includes(elem['email'])) {
                                        const newArray = [
                                            ...selectedUsersId.filter((email) => email !== elem['email']),
                                        ];

                                        setSelectedUsersId(newArray);
                                    } else {
                                        const newArray = [...selectedUsersId, elem.email];

                                        setSelectedUsersId(newArray);
                                    }
                                }}
                                checked={selectedUsersId.includes(elem['email'])}
                            />
                        )}
                        {elem.fullName}
                    </div>

                    <div className="investors-table__main-p investors-table__row-single investors-table__row-single--body">
                        {elem.email}
                    </div>

                    <div className="investors-table__main-p investors-table__row-single investors-table__row-single--body">
                        {displayEmailHistory(elem.invitationHistories)}
                    </div>
                    <MainTableBodyCell
                        type="actions"
                        text={
                            <TreeDotsInvestors
                                sendEmail={() => {
                                    sendSingleEmail(elem.email);
                                }}
                                id={elem.id}
                                ndaStatus={elem.ndaStatus}
                                deleteHandler={() => {
                                    removeInvestorsSingleUser(elem.email);
                                }}
                                handleSignNda={toggleNDA}
                                isNDAShown={true}
                            />
                        }
                    />
                </div>
            ))}
        </div>
    );
};
export default DropdownUserTableInSingleItem;
