import { useCallback } from 'react';
import { FunctionComponent } from 'react';
import StackingCell from './StackingCell';
import styles from './StackingFloor.module.scss';
import { Tooltip, Button } from 'antd';

interface Props {
    currency: string;
    unitOfMeasure: string;
    data: any;
    categories: Array<any>;
    className: string;
    colors: Array<StackingCell>;
}

const StackingFloor: FunctionComponent<Props> = ({ data, colors, currency, unitOfMeasure, className, categories }) => {
    const getRatioPercentage = useCallback(
        (value) => {
            const result = Math.round((value / data.totalArea) * 100);

            return `${result}%`;
        },
        [data.totalArea]
    );

    const formatNumber = useCallback(
        (number) => (number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''),
        []
    );

    const getStyle = (unit, index) => {
        const colorIdx = categories.findIndex((x) => x.name === unit.category);
        const bckColor = colorIdx < colors.length ? colors[colorIdx]?.color : 'red';
        const fontColor = colorIdx < colors.length ? colors[colorIdx]?.textColor : 'red';

        let style = {
            backgroundColor: bckColor,
            color: fontColor,
        };

        if (index < data.units.length - 1)
            return {
                ...style,
                maxWidth: getRatioPercentage(unit.floorArea),
            };
        else return style;
    };

    return (
        <div className={`${styles.root} ${className}`}>
            <div className={`${styles.index} ${styles.floor}`} title={data.floor}>
                <span className={`${styles.truncateText} stacking-plan-chart__text--title`}>{data.floor}</span>
            </div>
            <div className={styles.rooms}>
                {data.units.map((unit, index) => {
                    const title = `${unit.tenantName} | ${formatNumber(unit.floorArea)} ${unitOfMeasure}`;
                    const subTitle = `Passing Rent ${currency} ${formatNumber(unit.amount)} /${unitOfMeasure}`;

                    return (
                        <Tooltip placement="bottom" color="#fd615a" title={`${title} | ${subTitle}`}>
                            <div key={`room-${index}`} style={getStyle(unit, index)} className={styles.room}>
                                <span className={`${styles.truncateText} stacking-plan-chart__text`}>{title}</span>
                                {!unit.isVacant ? (
                                    <span
                                        className={`${styles.truncateText} stacking-plan-chart__text`}
                                        style={{ fontSize: '10px' }}
                                    >
                                        {subTitle}
                                    </span>
                                ) : null}
                            </div>
                        </Tooltip>
                    );
                })}
            </div>
            <div className={`${styles.index} ${styles.area}`} title={formatNumber(data.totalArea)}>
                <span className={`${styles.truncateText} stacking-plan-chart__text--title`}>
                    {formatNumber(data.totalArea)}
                </span>
            </div>
        </div>
    );
};
export default StackingFloor;
