export const headerText = [
    `Welcome to Virgate`,
    `We put all the data you need at your fingertips`,
    `We are an analytics and transaction platform that values data`,
    `We create market leading algorithms that leverage large data`,
    `We are focused on marketing commercial real estate transactions`,
    `We reinvent interactions between humans and data`,
    `We enhance everyone's ability to understand complex data`,
    `We create new languages and codes to translate data into information`,
    `We extract data from servers and put it in the hands of users, where it’s most valuable`,
    `We manage the process end-to-end`,
];
