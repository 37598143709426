import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateDataForEmptySection from '../../../../components/shared/CreateDataForEmptySection';
import FilterRadioButton from '../../../../components/shared/FilterRadioButton';
import MainLoader from '../../../../components/shared/MainLoader';
import FeatureState from '../../../../context/state/FeatureState';
import { Actions } from '../../../../interfaces/general/general';
import { CompanyWithInvestorPagination } from '../../../../interfaces/investor/CompanyWithInvestor';
import { InvestorUser } from '../../../../interfaces/investor/InvestorUser';
import AddInvestorModal from './components/AddInvestorModal';
import InvestorCompaniesTable from './components/InvestorCompaniesTable';
import InvestorsTableUser from './components/InvestorsTableUser';

enum NavEnum {
    COMPANY = 'COMPANY',
    USERS = 'USERS',
}

interface InvestorTabProps {
    isAddInvestrModalOpened: boolean;
    setIsAddInvestorModalOpenedActions: Actions;
    isUsersLoaded: boolean;
    investorUsers: Array<InvestorUser>;
    totalInvestorUsers: number;
    companyPagination: CompanyWithInvestorPagination;
    isCompaniesLoaded: boolean;
    investorsPage: number;
    setInvestorCompaniesPage: (page: number) => void;
    investorUsersPage: number;
    setInvestorUsersPage: (page: number) => void;
    refetch: () => void;
}

const InvestorTab: FunctionComponent<InvestorTabProps> = ({
    isAddInvestrModalOpened,
    setIsAddInvestorModalOpenedActions,
    isUsersLoaded,
    investorUsers,
    totalInvestorUsers,
    companyPagination,
    isCompaniesLoaded,
    investorsPage,
    setInvestorCompaniesPage,
    investorUsersPage,
    setInvestorUsersPage,
    refetch,
}: InvestorTabProps) => {
    const { t } = useTranslation(['f', 'b']),
        [activComponent, setActivComponent] = useState<string>(NavEnum.COMPANY),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary;

    const handleChangeActivComponent = (value: string) => setActivComponent(value);

    const radioBtnsDate = [
        {
            handler: handleChangeActivComponent,
            text: t(`f:teams.companyList`),
            value: NavEnum.COMPANY,
        },

        {
            handler: handleChangeActivComponent,
            text: t(`f:teams.usersList`),
            value: NavEnum.USERS,
        },
    ];

    const displayContent = () => {
        switch (activComponent) {
            case NavEnum.USERS:
                return (
                    <InvestorsTableUser
                        isUsersLoaded={isUsersLoaded}
                        listOfUsers={investorUsers}
                        investorUsersPage={investorUsersPage}
                        totalInvestorUsers={totalInvestorUsers}
                        setInvestorUsersPage={setInvestorUsersPage}
                        refetch={refetch}
                    />
                );

            default:
                return (
                    <InvestorCompaniesTable
                        isCompaniesLoaded={isCompaniesLoaded}
                        listOfCompanies={companyPagination.items}
                        totalItems={companyPagination.totalCount}
                        page={investorsPage}
                        pageSize={50}
                        refetch={refetch}
                        setInvestorCompaniesPage={setInvestorCompaniesPage}
                    />
                );
        }
    };

    const handleOnAddInvestorClick = useCallback(() => {
        setIsAddInvestorModalOpenedActions.setTrue();
    }, [setIsAddInvestorModalOpenedActions]);

    return (
        <div className="teams-table">
            {isCompaniesLoaded && isUsersLoaded ? (
                <>
                    <div className="d-flex justify-content-space-between align-items-center mb-20">
                        {companyPagination.items && companyPagination.items.length ? (
                            <div className="d-flex f-wrap gap-10  col-xs-6-clear">
                                {radioBtnsDate.map((elem) => (
                                    <FilterRadioButton
                                        key={elem.value}
                                        id={elem.value}
                                        text={elem.text}
                                        onClickHandler={elem.handler}
                                        current={activComponent}
                                    />
                                ))}
                            </div>
                        ) : null}
                    </div>
                    {companyPagination.items && companyPagination.items.length ? (
                        <>{displayContent()}</>
                    ) : (
                        <div className="col-xs-12">
                            <CreateDataForEmptySection
                                handler={handleOnAddInvestorClick}
                                text={t(`f:button.btnCreateInvestor`)}
                                mainText={t(`f:button.createInvestor`)}
                            />
                        </div>
                    )}

                    <AddInvestorModal
                        isModalOpened={isAddInvestrModalOpened}
                        setIsModalOpened={setIsAddInvestorModalOpenedActions}
                        propertyAssetId={`${propertyAssetId}`}
                        refetch={refetch}
                    />
                </>
            ) : (
                <MainLoader type="regular" />
            )}
        </div>
    );
};
export default InvestorTab;
