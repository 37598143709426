import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import WizardWithSidebar from '../../components/shared/WizardWithSidebar';
import { wizardSteps, LoWizardStepNames } from '../../constants/wizardSteps';
import Privacy from './components/Privacy';
import DealTimelineAndInvestors from './components/Deal-timeline&investors';
import Aknowledgement from './components/Aknowledgement';
import Publish from './components/Publish';
import Approvals from './components/Approvals';
import NavigateContext from '../../context/NavigateContext';
import LOWizardState from '../../context/state/LOWizardState';
import MainLoader from '../../components/shared/MainLoader';

interface RouteParams {
    stepName: string;
    id: string;
}

const Wizard: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { usersWhoCanApprove, isStepChanged, setIsStepChangedActions } = useContext(LOWizardState),
        { stepName } = useParams<RouteParams>();

    const [tabs, setTabs] = useState([
        {
            id: wizardSteps.first,
            step: LoWizardStepNames.PRIVACY,
            name: t('f:listOpportunity.privacy'),
            component: <Privacy />,
        },
        {
            id: wizardSteps.second,
            step: LoWizardStepNames.DEAL_TIMELINE_AND_INVESTORS,
            name: t('f:listOpportunity.deal-timeline&investors'),
            component: <DealTimelineAndInvestors />,
        },

        {
            id: wizardSteps.third,
            step: LoWizardStepNames.PAYMENT_ACKNOWLEDGEMENT,
            name: t('f:listOpportunity.paymentAknowledgement'),
            component: <Aknowledgement />,
        },
        {
            id: wizardSteps.fourth,
            step: LoWizardStepNames.PUBLISH,
            name: t('f:listOpportunity.publish'),
            component: <Publish />,
        },
        {
            id: wizardSteps.fifth,
            step: LoWizardStepNames.APPROVALS,
            name: t('f:listOpportunity.approvals'),
            component: <Approvals />,
        },
    ]);

    const findActiveTab = useCallback(
        (step) => {
            let activeTab: any = _.find(tabs, function (tab) {
                if (tab.step) {
                    return tab.step === step;
                }
            });
            if (activeTab) {
                return activeTab.id.toString();
            }
            return wizardSteps.first;
        },
        [tabs]
    );

    const [activeTab, setActiveTab] = useState(findActiveTab(stepName));

    useEffect(() => {
        usersWhoCanApprove.length ? setTabs((t) => t.slice(0, 6)) : setTabs((t) => t.slice(0, 5));
    }, [usersWhoCanApprove.length]);

    setIsStepChangedActions.setTrue();

    useEffect(() => {
        setActiveTab(findActiveTab(stepName));
    }, [stepName, findActiveTab]);

    return (
        <NavigateContext>
            {isStepChanged ? <WizardWithSidebar tabs={tabs} activeTab={activeTab} /> : <MainLoader type="main" />}
        </NavigateContext>
    );
};
export default Wizard;
