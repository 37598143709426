import api from '../index';
import BiddingInterface from '../../interfaces/property/BiddingInterface';

const fetchMyCompany = () => {
    return api.get(`/company/mycompany`);
};

const createBidding = (propertyAssetId: string, body: any) => {
    return api.post(`/bidding/${propertyAssetId}`, body);
};

const editBidInformation = (propertyAssetId: string, biddingId: string, body: any) => {
    return api.patch(`/bidding/${propertyAssetId}/${biddingId}`, body);
};

const editCompanyInformation = (propertyAssetId: string, biddingId: string, body: any) => {
    return api.patch(`/bidding/company/${propertyAssetId}/${biddingId}`, body);
};

const fetchBiddingInformations = (propertyAssetId: string, biddingId: string) => {
    return api.get(`/bidding/${propertyAssetId}/${biddingId}`);
};

const createJoinVentureCompanies = (propertyAssetId: string, biddingId: string, companyId: string) => {
    return api.post(`/bidding/${propertyAssetId}/${biddingId}/companies/${companyId}`);
};

const createBiddingSecundary = (propertyAssetId: string, biddingId: string, body: any) => {
    return api.put(`/bidding/${propertyAssetId}/${biddingId}/secondaryfinancing`, body);
};

const createVentureNameAndType = (propertyAssetId: string, biddingId: string, body: any) => {
    return api.put(`/bidding/${propertyAssetId}/${biddingId}/jointventurename`, body);
};

const fetchBidInfo = (propertyAssetId: string, biddingId: string) => {
    return api.get(`/bidding/${propertyAssetId}/${biddingId}`);
};

const fetchMyBiddings = (propertyAssetId: string) => {
    return api.get(`/bidding/${propertyAssetId}/mybiddings`);
};

const fetchallBiddingDocuments = (propertyAssetId: string, biddingId: string) => {
    return api.get(`/bidding/${propertyAssetId}/${biddingId}/documents`);
};

const fetchPropertyAsseryStage = (propertyAssetId: string) => {
    return api.get(`/bidding/${propertyAssetId}/stage`);
};

const fetchBiddingStatuses = () => {
    return api.get(`/bidding/enum/biddingstatus`);
};

const fetchTypesOfDocuments = (propertyAssetId: string) => {
    return api.get(`/bidding/${propertyAssetId}/documenttypes`);
};

const createPlaceBid = (propertyAssetId: string, biddingId: string) => {
    return api.put(`/bidding/${propertyAssetId}/${biddingId}/place`);
};

const fetchStages = (propertyAssetId: string) => {
    return api.get(`/bidding/${propertyAssetId}/stages`);
};

const deleteJoinVentureCompanies = (propertyAssetId: string, biddingId: string, companyId: string) => {
    return api.get(`/bidding/${propertyAssetId}/${biddingId}/companies/${companyId}`);
};

const fetchBriefDeatils = (propertyAssetId: string) => {
    return api.get(`/bidding/${propertyAssetId}/stagebriefdetails`);
};

const retractBid = (propertyAssetId: string, biddingId: string) => {
    return api.post(`/bidding/${propertyAssetId}/${biddingId}/retract`);
};

const removeBid = (propertyAssetId: string, biddingId: string) => {
    return api.delete(`/bidding/${propertyAssetId}/${biddingId}`);
};

const updateAllowedAssets = (propertyAssetId: string, biddingId: string, body: BiddingInterface) => {
    return api.patch(`/bidding/${propertyAssetId}/${biddingId}`, body);
};

const BidApi = {
    fetchMyCompany,
    removeBid,
    retractBid,
    fetchBidInfo,
    createBiddingSecundary,
    fetchPropertyAsseryStage,
    fetchBriefDeatils,
    fetchBiddingStatuses,
    fetchTypesOfDocuments,
    updateAllowedAssets,
    fetchStages,
    deleteJoinVentureCompanies,
    createBidding,
    createVentureNameAndType,
    fetchallBiddingDocuments,
    fetchMyBiddings,
    createJoinVentureCompanies,
    editBidInformation,
    fetchBiddingInformations,
    createPlaceBid,
    editCompanyInformation,
};
export default BidApi;
