import { FunctionComponent, useContext } from 'react';
import Tabs from '../../../components/shared/Tabs';
import FeatureState from '../../../context/state/FeatureState';
import useCheckUserLogged from '../../../hooks/useCheckUserLogged';
import NavBar from '../../../interfaces/property/NavTabs';
import TreeDotsTabsSelection from './TreeDotsTabsSelection';
import { isMobile } from 'react-device-detect';

export interface ViewPropertyTabsProps {
    tabs: Array<NavBar>;
    tabSelected: (value: any) => void;
    activTab: string;
}

const ViewPropertyTabs: FunctionComponent<ViewPropertyTabsProps> = ({ tabs, tabSelected, activTab }) => {
    const isLogged = useCheckUserLogged();
    const { getFeature, } = useContext(FeatureState);

    return (
        <div className="main-container relative">
            <div className="container__data">
                <div className="property-tabs property-tabs__styled">
                    <Tabs tabs={tabs} onTabSelected={tabSelected} activeKey={activTab} defaultActiveKey={activTab} />
                </div>
            </div>
            {isLogged && !isMobile && getFeature('DealRoomFeaturesManagement') &&
                <div className="property__tree-dots">
                    <TreeDotsTabsSelection />
                </div>
            }
        </div>
    );
};

export default ViewPropertyTabs;
