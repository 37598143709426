import { FunctionComponent, useCallback, useContext } from 'react';
import { Form, Formik } from 'formik';
import { CashFlowRecordSchema } from '../../../../validations/CashFlowRecord';
import { CashFlowInit, fieldsToDisplay, CashFlowI } from '../../../../interfaces/cashFlow/CashFlow';
import AddEditRecordModalTextInput from './partials/AddEditRecordModalTextInput';
import AddEditRecordModalSelectDateInput from './partials/AddEditRecordModalSelectDateInput';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../components/shared/MainButton';
import PropertyAssetCashFlow from '../../../../services/PropertyAssetCashFlow';

import { Actions } from '../../../../interfaces/general/general';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';
import FeatureState from '../../../../context/state/FeatureState';

interface AddEditRecordModalProps {
    type: 'add' | 'edit';
    isModalOpened: boolean;
    setIsModalOpened: Actions;
    refetch: () => void;
    recordToEdit?: CashFlowI;
}

const AddEditRecordModal: FunctionComponent<AddEditRecordModalProps> = ({
    type,
    isModalOpened,
    setIsModalOpened,
    refetch,
    recordToEdit,
}: AddEditRecordModalProps) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary;

    const onCancelHanlder = useCallback(() => {
        setIsModalOpened.setFalse();
    }, [setIsModalOpened]);

    const checkType = (
        elem: string,
        values: CashFlowI,
        errors: any,
        touched: any,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    ) => {
        switch (elem) {
            case 'leaseEndDate':
                return (
                    <AddEditRecordModalSelectDateInput
                        name={elem}
                        touched={touched}
                        errors={errors}
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                );
            case 'leaseStartDate':
                return (
                    <AddEditRecordModalSelectDateInput
                        name={elem}
                        touched={touched}
                        errors={errors}
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                );

            default:
                return (
                    <AddEditRecordModalTextInput
                        setFieldValue={setFieldValue}
                        name={elem}
                        touched={touched}
                        errors={errors}
                        values={values}
                    />
                );
        }
    };

    const displayFields = (
        values: CashFlowI,
        errors: any,
        touched: any,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    ) => {
        return fieldsToDisplay.map((elem) => {
            return (
                <div className="col-xs-6 form-group" key={elem}>
                    {checkType(elem, values, errors, touched, setFieldValue)}
                </div>
            );
        });
    };

    const finishHandler = async (values, { resetForm }) => {
        switch (type) {
            case 'edit':
                await PropertyAssetCashFlow.updateAssetCashFlowRow(`${propertyAssetId}`, values);
                break;

            default:
                const newObj = {
                    name: values.name,
                    area: values.area,
                    leaseStartDate: values.leaseStartDate,
                    leaseEndDate: values.leaseEndDate,
                    passingRent: values.passingRent,
                    amount: values.amount,
                    floor: values.floor,
                    customReviewType: values.customReviewType,
                    leaseType: values.leaseType,
                    monthsRentFree: values.monthsRentFree,
                    unitReference: values.unitReference,
                    customLeaseCategory: values.customLeaseCategory,
                    unitStatus: values.unitStatus,
                    recoveries: values.recoveries,
                    tenantIncentive: values.tenantIncentive,
                };

                await PropertyAssetCashFlow.addRecord(`${propertyAssetId}`, newObj);
                break;
        }
        refetch();
        resetForm();
        onCancelHanlder();
    };

    return (
        <ModalWrapper
            isCloseModalShown={true}
            className="cf-modal"
            isModalOpened={isModalOpened}
            closeModalHandler={onCancelHanlder}
            title={t(`f:cashFlow.${type}`)}
            isFooterShown={false}
        >
            <Formik
                initialValues={recordToEdit ? recordToEdit : CashFlowInit}
                enableReinitialize
                validationSchema={CashFlowRecordSchema}
                validateOnMount
                onSubmit={finishHandler}
            >
                {({ values, setFieldValue, resetForm, errors, touched }) => (
                    <Form
                        onChange={(e) => {
                            if (e.target['name'] === 'area' || e.target['name'] === 'passingRent') {
                                setFieldValue(
                                    'amount',
                                    e.target['name'] === 'area'
                                        ? values.passingRent * e.target['value']
                                        : values.area * e.target['value']
                                );
                            }
                        }}
                    >
                        <div className="row">{displayFields(values, errors, touched, setFieldValue)}</div>

                        <div className="d-flex justify-content-center mt-20">
                            <MainButton
                                type="reset"
                                buttonText={t('f:button.cancel')}
                                layout="secondary"
                                buttonHandler={onCancelHanlder}
                                size="main"
                            />
                            <MainButton
                                type="submit"
                                buttonText={t('f:button.save')}
                                classAdditions="mxl-30"
                                layout="primary"
                                size="main"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    );
};

export default AddEditRecordModal;
