import { FunctionComponent, useState } from 'react';
import { Field } from 'formik';
import { FormikErrorArrayDeep } from '../../TemplatesWizard/partials/FormikError';
import { useTranslation } from 'react-i18next';

interface InputStakeProps {
    index?: number;
    values: any;
    errors: any;
    touched: any;
    name: string;
    label?: string;
    classes?: string;
}

const InputStake: FunctionComponent<InputStakeProps> = ({
    label,
    values,
    index,
    errors,
    touched,
    name,
    classes,
}: InputStakeProps) => {
    const { t } = useTranslation(['f', 'b']),
        [percent, setPercent] = useState<string>('%');
    return (
        <div className={`${classes} position-relative`}>
            {label && <label htmlFor="capitalisationRate">{t('f:property.capitalisationRate')} (%)</label>}

            <Field
                className="form-control  mb-25"
                name={name}
                placeholder={t('f:chart.table.enterValue')}
                value={`${values}${percent}`}
                type={name}
                onFocus={() => {
                    setPercent('');
                }}
                onBlur={() => {
                    setPercent('%');
                }}
            />
            {index ? (
                <FormikErrorArrayDeep errors={errors} touched={touched} index={index} array="owners" name="share" />
            ) : null}
        </div>
    );
};
export default InputStake;
