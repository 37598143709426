import { graphConfig } from "../auth/authConfig";
import UserApi from '../api/routes/UserApi';

const apiToken: string = "apiToken" ;

 export const getToken = ():string | null => {
    return localStorage.getItem(apiToken);
}

export const setToken = (token: string) => {
    localStorage.setItem(apiToken, token);
}

export const isAuthDisabled = ():boolean => {
    return process.env.DISABLE_AUTH!== undefined &&  process.env.DISABLE_AUTH === 'true';
}

export const resetToken = () => {
    
    localStorage.removeItem(apiToken);
}

export async function callMsGraph() {
    const token = getToken();
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function getAndSetUserDetails(token:string) {
     UserApi.upsertUser(token).then((response) => {
       console.log(response);
      });
   
}

export async function fetchUserInfo() {
    let response = await UserApi.fetchMyInfo()
    return response.data
}

