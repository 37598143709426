import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useBoolean from '../../../../../hooks/use-boolean';
import { InvestorUser } from '../../../../../interfaces/investor/InvestorUser';

interface TreeDotsSingleInvestorProps {
    user: InvestorUser;
    handleSignNda?: (userId: string) => void;
}
const TreeDotsSingleInvestor: FunctionComponent<TreeDotsSingleInvestorProps> = ({
    user,
    handleSignNda,
}) => {
    const { t } = useTranslation(['f', 'b']),
        [isThreeDotClicked, setIsThreeDotClickedActions] = useBoolean();

    const handleToggleTreeDotsMenu = useCallback(() => {
        setIsThreeDotClickedActions.toggle();
    }, [setIsThreeDotClickedActions]);

    const handleMouseLeave = useCallback(() => {
        setIsThreeDotClickedActions.setFalse();
    }, [setIsThreeDotClickedActions]);

    const content = (
        <div className="d-flex flex-direction-column" onMouseLeave={handleMouseLeave}>
            <span
                style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                onClick={() => {
                    if (handleSignNda) handleSignNda(`${user.id}`);
                }}
            >
                {user.ndaStatus  === 0 ? 'NDA Signed' : 'NDA Not Signed'}
            </span>
        </div>
    );

    return (
        <div className="">
            <FontAwesomeIcon
                className="mxl-10"
                style={{ color: '#a1abbd', cursor: 'pointer' }}
                icon={faEllipsisH}
                size="lg"
                onClick={handleToggleTreeDotsMenu}
            ></FontAwesomeIcon>
            <div style={{ marginLeft: '20px' }}>
                <Popover content={content} placement="bottom" trigger="click" visible={isThreeDotClicked}></Popover>
            </div>
        </div>
    );
};

export default TreeDotsSingleInvestor;
