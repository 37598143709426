import * as React from 'react';
import CountryModel from '../../api/models/CountryModel';

export type LookupContextType = {
    getContent: (name: string) => any;
    countries: Array<CountryModel>;
}

export default React.createContext<LookupContextType>({
    getContent: (name: string) => Array,
    countries: []
});
