import { FunctionComponent, useCallback, useEffect, useState } from "react";
import MainHeading from "../../../../../components/shared/MainHeading";
import useBoolean from "../../../../../hooks/use-boolean";
import { InvestorCompany, InvestorCompanyInfo } from "../../../../../interfaces/investor/CompanyWithInvestor";
import { InvestorUser } from "../../../../../interfaces/investor/InvestorUser";
import InvestorService from "../../../../../services/InvestorsService";
import AddInvestorForm from "../partials/AddInvestorForm";
import InvestorsTableUser from "./InvestorsTableUser";

interface CompanyUsersProps {
    propertyAssetId: number | string;
    company: InvestorCompanyInfo;
    investorUsers: Array<InvestorUser>;
    isLoading: boolean;
    refetch: () => void;
}

const CompanyUsers: FunctionComponent<CompanyUsersProps> = ({
    propertyAssetId,
    company,
    investorUsers,
    isLoading,
    refetch
}) => {
    return (
        <div className="mx-20 pt-20">
            <MainHeading text="Add Investor" />
            <AddInvestorForm
                propertyAssetId={propertyAssetId}
                company={company.name}
                disableFields={['companyName']}
                refetch={refetch}
            />

            <MainHeading text="Current Users" addClasses="mt-50" />
            <InvestorsTableUser
                isUsersLoaded={!isLoading}
                listOfUsers={investorUsers}
                investorUsersPage={0}
                totalInvestorUsers={investorUsers.length}
                setInvestorUsersPage={() => { }}
                refetch={() => { }}
                showPagination={false}
                className='mt-50'
            />
        </div>
    );
}

export default CompanyUsers;