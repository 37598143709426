import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import buildingIcon from '../../assets/images/building-icon.png';
import { useHistory, useParams } from 'react-router-dom';
import RouteParams from '../../interfaces/general/Route';
import FilterMenuPanel from './components/FilterMenuPanel';
import TradeProbabilityInfo from './models/tradeProbabilityInfo';
import TradeProbabilityService from '../../services/TradeProbabilityService';
import IPropertyInfo from '../../components/shared/PropertiesMap/iPropertyInfo';
import PropertiesMap from '../../components/shared/PropertiesMap/PropertiesMap';
import MainLoader from '../../components/shared/MainLoader';

const MapFullHeight = 'calc(100vh - 60px)';
const MaxPriceFilter = 500000000;

const SearchingMap: FunctionComponent = ({ children }) => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        { id } = useParams<RouteParams>(),
        [isLoading, setIsLoading] = useState<boolean>(false),
        [selectedMenu, setSelectedMenu] = useState<number>(0),
        [mapHeight, setMapHeight] = useState<string>(MapFullHeight),
        [minPrice, setMinPrice] = useState<number>(0),
        [filterItems, setFilterItems] = useState<Array<TradeProbabilityInfo>>(),
        [tradeProbabilities, setTradeProbabilities] = useState<Array<TradeProbabilityInfo>>();

    const fetchTradeProbabilities = useCallback(async (region: string) => {
        try {
            setIsLoading(true);
            const tradeProbabilities = await TradeProbabilityService.fetchTradeProbabilities(region);

            setFilterItems(tradeProbabilities);
            setTradeProbabilities(tradeProbabilities);

            const prices = tradeProbabilities.map(x => x.price);
            setMinPrice(Math.min(...prices));
            setIsLoading(false);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    useEffect(() => {
        setMapHeight(id ? 'calc(50vh - 30px)' : MapFullHeight);
    }, [id]);

    const handleBuildingClicked = (property: IPropertyInfo) => {
        history.push(`/trade-probability/${property.id}`);
    };

    const updateFilterItems = (filtered) => {
        if (filtered.length === filterItems!.length && filtered.every((value, index) => value === filterItems![index])) {
            return;
        }

        setFilterItems(filtered);
    }

    const handlePriceChanged = ([minValue, maxValue]) => {
        var filtered = tradeProbabilities!.filter(x => x.price >= minValue &&
            (maxValue === MaxPriceFilter || x.price <= maxValue));

        updateFilterItems(filtered);
    }

    const handleYearChanged = (values: number[]) => {
        var filtered = values.length === 0
            ? tradeProbabilities
            : tradeProbabilities!.filter(x => values.includes(x.allPredictions || 0));

        updateFilterItems(filtered);
    };

    const handleRegionChanged = (region: string) => {
        fetchTradeProbabilities(region);
    };

    return (
        <div className="property-hero searching-map">
            <figure>
                <div className="background-cover">
                    <div className='navigation-icon'>
                        <img src={buildingIcon} onClick={() => setSelectedMenu(0)} />
                    </div>
                    <div className='search-content'>
                        <div className='search-panel'>
                            <div style={{ display: selectedMenu == 0 ? 'block' : 'none' }}>
                                <FilterMenuPanel minPrice={minPrice} maxPrice={MaxPriceFilter} tradeProbabilities={tradeProbabilities} onPriceChanged={handlePriceChanged} onYearChanged={handleYearChanged} onRegionChanged={handleRegionChanged} />
                            </div>
                        </div>
                        <div className="map-panel">
                            <PropertiesMap type={TradeProbabilityInfo} height={mapHeight} markerId={id} properties={filterItems} onMarkerClicked={handleBuildingClicked} />
                            {children}
                            {!isLoading ? null : <div className='loading'>
                                <MainLoader type="regular" size={100} />
                            </div>}
                        </div>
                    </div>
                </div>
            </figure>
        </div>
    );
};

export default SearchingMap;
