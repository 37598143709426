import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WizardWithSidebar from '../../components/shared/WizardWithSidebar';
import { SchedulingComponent } from './components/SchedulingComponent';
import varibales from '../../assets/styles/general/_variable.module.scss';
import CheckAuthorization from '../StatusPages/CheckAuthorization';

export const ScheduleWizard: FunctionComponent<any> = () => {
    const { t } = useTranslation(['f', 'b']);

    useEffect(() => {
        let body: any = document.getElementsByTagName('BODY')[0];
        body.style = `background-color: ${varibales.bgColorPrimary}`;
    }, []);

    const tabs = [
        {
            id: 1,
            name: t('f:schedule.scheduling'),
            step: 'scheduling',
            component: <SchedulingComponent />,
        },
    ];

    return (
        <CheckAuthorization>
            <WizardWithSidebar tabs={tabs} />;
        </CheckAuthorization>
    );
};
