import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';

interface InfoCardProps {
    icon: IconProp;
    len: number;
    text: string;
    subText: string;
    id: number;
    handleClick: (prev: number) => void;
    selected: number;
}

const InfoCard = ({ id, icon, len, subText, text, handleClick, selected }: InfoCardProps) => {
    const checkNumber = useCallback((number: number) => {
        return number > 9 ? `${number}` : `0${number}`;
    }, []);

    const handleOnClick = useCallback(() => {
        handleClick(id);
    }, [handleClick, id]);

    return (
        <div
            className={`overview-page-card ${
                selected === id ? 'overview-page-card--selected' : ''
            } d-flex flex-direction-column`}
            key={id}
            onClick={handleOnClick}
        >
            <div className="overview-page-card__logo d-flex justify-content-center align-items-center">
                <FontAwesomeIcon icon={icon} />
            </div>
            <p className="overview-page-card__number">{checkNumber(len)}</p>
            <p className="overview-page-card__main-info">{text}</p>
            <span className="overview-page-card__sub-info">{subText}</span>
        </div>
    );
};
export default InfoCard;
