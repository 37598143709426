import UserApi from '../api/routes/UserApi';

const updateMe = async (body: any) => {
    const { data } = await UserApi.updateMe(body);
    return data;
};

const updateMyCompany = async (body: any) => {
    const { data } = await UserApi.updateMyCompany(body);
    return data;
};

const updateMyCompanyAsSole = async () => {
    const { data } = await UserApi.updateMyCompanyAsSole();
    return data;
};

const getMyUserInfo = async (): Promise<any> => {
    const response = await UserApi.fetchMyInfo();
    return response?.data;
};
const getMyUserPicture = async () => {
    const response = await UserApi.getMyUserPicture();
    return response?.data;
};

const canUpdateCompany = async (): Promise<any> => {
    const response = await UserApi.canUpdateCompany();
    return response?.data;
};

const getMyCompanyInfo = async (): Promise<any> => {
    const response = await UserApi.fetchMyCompanyInfo();
    return response?.data;
};

const uploadImage = async (options: any, body: any) => {
    const { data } = await UserApi.uploadImage(options, body);
    return data;
};

const userPoriflePicture = async (id: string, url: string) => {
    const { data } = await UserApi.userPoriflePicture({ userId: id, imageUrl: url });
    return data;
};
const UserService = {
    updateMe,
    getMyUserInfo,
    getMyCompanyInfo,
    updateMyCompany,
    updateMyCompanyAsSole,
    canUpdateCompany,
    uploadImage,
    userPoriflePicture,
    getMyUserPicture,
};
export default UserService;
