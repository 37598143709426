import { FunctionComponent, useState } from 'react';
import { Form, Input } from 'antd';
import CompanyService from '../../../services/Admin/Company';
import Roles from './../Company/Roles';
import ModalWrapper from '../../../components/wrapper/ModalWrapper';

interface Props {
    onFinish: () => void;
    onCancel: () => void;
    companyId: string;
    isModalOpen: boolean;
}

const defaultValues = {
    firstName: '',
    lastName: '',
    displayName: '',
    roleId: 2,
    email: '',
};

const AddUser: FunctionComponent<Props> = (props) => {
    const [userModel, setUserModel] = useState<any>({ ...defaultValues });

    const SaveUser = async () => {
        const result = await CompanyService.addUserToCompany(props.companyId, userModel);
        setUserModel({ ...defaultValues });
        props.onFinish();
    };

    const Cleanup = () => {
        setUserModel({ ...defaultValues });
        props.onCancel();
    };

    const onRoleSelected = (value) => {
        userModel.roleId = value;
    };

    return (
        <ModalWrapper
            isCloseModalShown={true}
            closeModalHandler={() => {
                Cleanup();
            }}
            isFooterShown={true}
            primaryButtonText="save"
            primaryButtonHandler={() => {
                SaveUser();
            }}
            title="Add user"
            isModalOpened={props.isModalOpen}
        >
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
            >
                <Form.Item
                    label="First Name"
                    name="firstName"
                    style={{ marginTop: 2 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input first name.',
                        },
                    ]}
                >
                    <Input
                        defaultValue={userModel.firstName}
                        onChange={(e) => (userModel.firstName = e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label="Last Name"
                    name="lastName"
                    style={{ marginTop: 2 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input last name.',
                        },
                    ]}
                >
                    <Input defaultValue={userModel.lastName} onChange={(e) => (userModel.lastName = e.target.value)} />
                </Form.Item>
                <Form.Item
                    label="Display Name"
                    name="displayName"
                    style={{ marginTop: 2 }}
                    rules={[
                        {
                            required: true,
                            message: 'Please input display name.',
                        },
                    ]}
                >
                    <Input
                        defaultValue={userModel.displayName}
                        onChange={(e) => (userModel.displayName = e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                        },
                    ]}
                >
                    <Input defaultValue={userModel.email} onChange={(e) => (userModel.email = e.target.value)} />
                </Form.Item>
                <Form.Item
                    label="Role"
                    name="role"
                    rules={[
                        {
                            required: true,
                            message: 'role is required',
                        },
                    ]}
                >
                    <Roles onSelected={onRoleSelected} />
                </Form.Item>
            </Form>
        </ModalWrapper>
    );
};

export default AddUser;
