import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import MainLoader from '../../components/shared/MainLoader';
import { PROPERTY_TABS } from '../../constants/propertyTab';
import { DEFAULT_TABS } from '../../constants/viewProperty';
import FeatureState from '../../context/state/FeatureState';
import useBoolean from '../../hooks/use-boolean';
import NavBar from '../../interfaces/property/NavTabs';
import { filterPropertyAssetFeature } from '../../utils/array';
import ViewPropertyHero from './partials/ViewPropertyHero';
import ViewPropertyTabs from './partials/ViewPropertyTabs';
import { isMobile } from 'react-device-detect';
import ViewPropertyTabsMobile from './partials/ViewPropertyTabsMobile';


const idsAndNames = [
    { id: PROPERTY_TABS.PROPERTY_SUMMARY.id, name: PROPERTY_TABS.PROPERTY_SUMMARY.name, title: 'Property Summary' },
    { id: PROPERTY_TABS.CASH_FLOW.id, name: PROPERTY_TABS.CASH_FLOW.name, title: 'Cash Flow' },
    { id: PROPERTY_TABS.DATAROOM.id, name: PROPERTY_TABS.DATAROOM.name, title: 'Data Room' },
    { id: PROPERTY_TABS.TRANSACTION.id, name: PROPERTY_TABS.TRANSACTION.name, title: 'Transaction' },
    { id: PROPERTY_TABS.RESEARCH.id, name: PROPERTY_TABS.RESEARCH.name, title: 'Research' },
    { id: PROPERTY_TABS.PROJECT_TEAM.id, name: PROPERTY_TABS.PROJECT_TEAM.name, title: 'Project Team' },
    { id: PROPERTY_TABS.FAQ.id, name: PROPERTY_TABS.FAQ.name, title: 'FAQ' },
    { id: PROPERTY_TABS.PERMISSIONS.id, name: PROPERTY_TABS.PERMISSIONS.name, title: 'Permission' },
];

const PropertyTabs: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory(),
        { myFeature, propertySummary } = useContext(FeatureState),
        location = useLocation(),
        [isLoading, setIsLoadingActions] = useBoolean(),
        [activeTabId, setActiveTabId] = useState<string>();
    const findActivTab = (val: number | string, nameRepresentation: boolean) => {
        const tab = idsAndNames.find((el) => el[`${nameRepresentation ? 'id' : 'name'}`] == val);
        return tab ? (nameRepresentation ? tab.name : `${tab.id}`) : nameRepresentation ? 'property-summary' : '10';
    };

    const findActiveTabTitle = (val: number | string) => {
        const tab = idsAndNames.find(item => item.id == val);
        return tab ? tab.title : '';
    }

    let activTab = location.hash ? findActivTab(location.hash.replace('#', ''), false) : '10';
    useEffect(() => {
        setActiveTabId(activTab);
    }, []);

    // Can not be in constats, has problem with components.
    // If componentes is editable, depends on backend. We set it later.
    // Empty Component will be replaced with other components. It created just becouse of tabs.

    const [tabs, setTabs] = useState<Array<NavBar>>([]);

    const setData = useCallback(() => {
        const defaultTabs = DEFAULT_TABS;

        if (propertySummary.isPortfolio) {
            DEFAULT_TABS[0].name = 'portfolioSummary';
        }

        let tabs = filterPropertyAssetFeature(defaultTabs, myFeature);
        if (propertySummary.dealRoomFeatures) {
            tabs = filterPropertyAssetFeature(tabs, propertySummary.dealRoomFeatures);
        }
        setTabs(tabs);

        setIsLoadingActions.setTrue();
    }, [setIsLoadingActions, myFeature, propertySummary.isPortfolio]);

    const tabSelected = (value: any) => {
        setActiveTabId(value);
        history.push({ hash: findActivTab(value, true) });
    };

    useEffect(() => {
        setData();
    }, [setData]);

    return (
        <>
            {isLoading ? (
                <div className="container">
                    <Helmet title={activeTabId ? `${findActiveTabTitle(activeTabId)} - "${propertySummary.name}"` : 'Virgate'}>
                    </Helmet>
                    <ViewPropertyHero />
                    {!isMobile && <ViewPropertyTabs activTab={activTab} tabSelected={tabSelected} tabs={tabs} />}
                    {isMobile && <ViewPropertyTabsMobile activTab={activTab} tabSelected={tabSelected} tabs={tabs} />}
                </div>
            ) : (
                <div className="container mb-100">
                    <MainLoader type="regular" mb="mb-100" mt="mt-100" />
                </div>
            )}
        </>
    );
};
export default PropertyTabs;
