import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import MainHeading from '../../../components/shared/MainHeading';
import { Actions } from '../../../interfaces/general/general';
import ModalImportLoading from '../../../components/shared/ModalImportLoading';
import TextInput from './TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

interface UploadVideoProps {
    isVideoUploaded: boolean;
    isVideoUploading: boolean;
    isVideoUploadingActions: Actions;
    videoUpload: (opt: any) => void;
    videoData: (str: string) => void;
    setImageUrl: (str: string) => void;
    setIsVideoUploaded: (str: boolean) => void;
    propertyAssetId: string;
    imageUrl: string;
}

const UploadVideo: FunctionComponent<UploadVideoProps> = ({
    videoUpload,
    isVideoUploaded,
    propertyAssetId,
    isVideoUploading,
    isVideoUploadingActions,
    imageUrl,
    videoData,
    setImageUrl,
    setIsVideoUploaded,
}: UploadVideoProps) => {
    const { t } = useTranslation(['f', 'b']),
        [newUrl, setNewUrl] = useState<string>('');

    return (
        <div className="col-xs-12 form-group mb-100">
            <MainHeading text={t('f:property.uploadVideo')} addClasses="mb-30" />
            <div className="col-xs-12">
                {imageUrl ? (
                    <div className="col-xs-12 d-flex gap-10">
                        <p>{imageUrl}</p>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="add-property-image-builder__times-icon pointer"
                            onClick={() => {
                                setNewUrl('');
                                setImageUrl('');
                                localStorage.removeItem('imageContent');
                                setIsVideoUploaded(false);
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <div className="col-xs-10-clear d-flex align-items-center gap-10">
                            <TextInput
                                label={t('f:propertySummary.url')}
                                placeholder={t('f:propertySummary.enterUrl')}
                                content={newUrl}
                                setContent={setNewUrl}
                                clear={true}
                            />
                            <FontAwesomeIcon
                                icon={faCheck}
                                className="add-property-image-builder__check-icon pointer"
                                onClick={() => {
                                    videoData(newUrl);
                                }}
                            />
                        </div>
                        <Upload
                            name="Image"
                            className="add-property-image-builder__upload add-property-image-builder__upload--template mt-100"
                            showUploadList={false}
                            action={
                                process.env.REACT_APP_API_URL + `/propertyassetpagesummary/${propertyAssetId}/files`
                            }
                            customRequest={(options: any) => {
                                isVideoUploadingActions.setTrue();
                                videoUpload(options);
                            }}
                        >
                            <Button disabled={isVideoUploading} icon={<UploadOutlined />}>
                                {isVideoUploaded ? (
                                    <span style={{ color: 'green' }}>{t(`f:button.succesfullyUploaded`)} </span>
                                ) : (
                                    t(`f:button.video`)
                                )}
                            </Button>
                        </Upload>
                    </>
                )}
            </div>
            <ModalImportLoading text={t(`f:loaders.uploading`)} isModalOpened={isVideoUploading} />
        </div>
    );
};
export default UploadVideo;
