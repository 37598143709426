import { FunctionComponent, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { RoleOptions } from '../../../constants/roles';

const { Option } = Select;

interface Props {
    onSelected: (value: any) => void;
}

const Roles: FunctionComponent<Props> = ({ onSelected }) => {
    const { t } = useTranslation(['f']);

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    return (
        <Select onSelect={onSelected} getPopupContainer={handleContainer}>
            {Object.keys(RoleOptions)
                .map(Number)
                .map((key) => (
                    <Option value={key}>{t(`f:userRoles.${key}`)}</Option>
                ))}
        </Select>
    );
};

export default memo(Roles);
