import PieChartForm from './PieChartForm';
import BarChartForm from './BarChartForm';
import LineChartForm from './LineChartForm';

interface ChartCategoryProps {
    category: number;
}

const ChartCategory = ({ category }: ChartCategoryProps) => {
    switch (category) {
        case 3:
            return (
                <div className="col-xs-12-clear">
                    <PieChartForm />
                </div>
            );
        case 2:
            return (
                <div className="col-xs-12-clear">
                    <BarChartForm />
                </div>
            );

        default:
            return (
                <div className="col-xs-12-clear">
                    <LineChartForm />
                </div>
            );
    }
};
export default ChartCategory;
