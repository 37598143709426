import api from '../index';

const fetchCompanies = () => {
    return api.get(`/company`);
};

const fetchCompanyUsers = (companyId: string) => {
    return api.get(`/company/${companyId}/users`);
};

const fetchAllUsers = () => {
    return api.get(`/company/users`);
};

const fetchUsersFromMyCompany = () => {
    return api.get(`/company/usersfrommycompany`);
};

const CompanyApi = {
    fetchCompanies,
    fetchAllUsers,
    fetchCompanyUsers,
    fetchUsersFromMyCompany,
};
export default CompanyApi;
