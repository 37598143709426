import _ from 'lodash';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormattedAmount from '../../../../components/shared/FormattedAmount';
import DashboardCard from './data/card';

interface Props {
    elements: DashboardCard;
    isSelected: boolean;
}

const PropertyCardDashboard: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']),
        [isChecked, setIsChecked] = useState<boolean>(props.isSelected);

    const toggle = (e: any) => {
        if (isChecked) {
            setIsChecked(false);
        } else {
            setIsChecked(true);
        }
    };
    return (
        <div className="property-card__hotels mr-20">
            <div className="d-flex" onClick={toggle}>
                <label htmlFor="require" className="d-flex align-items-center mt-30 mb-20 checkbox">
                    <input
                        className="mr-15 select-checkbox"
                        type="checkbox"
                        name="require"
                        id={`dashboard_property${props.elements.id}`}
                        checked={isChecked}
                    />

                    <span>Select property</span>
                </label>
            </div>

            <div className="row-clear property-card__container property-card__container--homepage property-card-dashboard">
                <figure className="property-card__image">
                    <img src={props.elements?.imageUrl} alt="Property" />
                </figure>
                <div className="property-card__title">
                    <div className="d-flex property-card__favorite justify-content-space-between align-items-center">
                        <h3 className="main-text property-card__name">{props.elements?.propertyName}</h3>
                    </div>
                    <p className="property-card__address">{props.elements?.map?.address}</p>
                </div>
                <div className="">
                    <div className="row-clear property-card__element-list">
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Country</p>
                            <p className="property-card__element-value">{props.elements?.country}</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">City</p>
                            <p className="property-card__element-value">{props.elements?.city}</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Land Tenure</p>
                            <p className="property-card__element-value">{props.elements?.landTenure}</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">{t('f:property.sectors')}</p>
                            <p className="property-card__element-value">
                                {props.elements?.sectorNames
                                    ? props.elements.sectorNames
                                          .map((sector: string) => {
                                              return sector;
                                          })
                                          .join(', ')
                                    : ''}
                            </p>
                        </div>

                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">{t('f:property.yearOfCompletion2')}</p>
                            <p className="property-card__element-value">{props.elements?.yearOfCompletion}</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Building Score</p>
                            <p className="property-card__element-value">{props.elements?.buildingScore}</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">{t(`f:property.modal.nla`)}</p>
                            <p className="property-card__element-value">
                                {props.elements && <FormattedAmount value={props.elements.netLeasableArea} />}
                            </p>
                        </div>

                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Typical Floor Plate</p>
                            <p className="property-card__element-value">
                                {props.elements && <FormattedAmount value={props.elements.typicalFloorPlate} />}
                            </p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Occupancy</p>
                            <p className="property-card__element-value">{props.elements?.occupancy} %</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Current NOI</p>
                            <p className="property-card__element-value">
                                {props.elements && <FormattedAmount value={props.elements.currentNoi} />}
                            </p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Passing Rent</p>
                            <p className="property-card__element-value">{props.elements?.passingRent}</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Capitalisation Rate</p>
                            <p className="property-card__element-value">{props.elements?.capitalisationRate} %</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Current Value</p>
                            <p className="property-card__element-value">
                                {props.elements && <FormattedAmount value={props.elements.assetValue} />}
                            </p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Cost Per (NLA/sqft)</p>
                            <p className="property-card__element-value">
                                {props.elements && <FormattedAmount value={props.elements.costPer} />}
                            </p>
                        </div>

                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">{t(`f:property.modal.majorTenants`)}</p>
                            <p className="property-card__element-value">{props.elements?.majorTenants}</p>
                        </div>

                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-header">
                            <label>TRANSACTION DETAILS</label>
                        </div>

                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Transaction Date</p>
                            <p className="property-card__element-value">{props.elements?.transactionDate}</p>
                        </div>

                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Transaction Value</p>
                            <p className="property-card__element-value">
                                {props.elements && <FormattedAmount value={props.elements.transactionValue} />}
                            </p>
                        </div>

                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">NOI</p>
                            <p className="property-card__element-value">
                                {props.elements && <FormattedAmount value={props.elements.noi} />}
                            </p>
                        </div>

                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Capitalisation Rate</p>
                            <p className="property-card__element-value">{props.elements?.newCapitalisationRate} %</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Seller</p>
                            <p className="property-card__element-value">{props.elements?.seller}</p>
                        </div>
                        <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                            <p className="property-card__element-name">Buyer</p>
                            <p className="property-card__element-value">{props.elements?.buyer}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyCardDashboard;
