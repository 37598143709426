import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MainButton from './MainButton';

interface Props {
    inviteInvestor: (prev: string) => any;
    removeInvestitor: (id: number) => any;
    myInvestors: Array<any>;
    id: string;
    classNames: string;
}

const InviteInvestor: FunctionComponent<Props> = ({
    classNames,
    myInvestors,
    id,
    inviteInvestor,
    removeInvestitor,
}) => {
    const [current, setCurrent] = useState<boolean>(true);

    const handleInviteInvestor = useCallback(() => {
        inviteInvestor(id);
    }, [id, inviteInvestor]);
    const handleRemoveInvestor = useCallback(() => {
        removeInvestitor(parseInt(id));
    }, [id, removeInvestitor]);

    useEffect(() => {
        setCurrent(true);
        myInvestors.forEach((elem) => {
            if (elem.id === id) {
                setCurrent(false);
            }
        });
    }, [current, myInvestors]);

    return (
        <div className="marketing__company-group--marg-t">
            <div className={`${classNames} marketing__company-group--butt d-flex`}>
                {current ? (
                    <MainButton
                        size="main"
                        layout="primary"
                        classAdditions="marketing__plus"
                        buttonHandler={handleInviteInvestor}
                        buttonText={<FontAwesomeIcon icon={faPlus} />}
                    />
                ) : (
                    <MainButton
                        size="main"
                        layout="primary"
                        classAdditions="marketing__plus marketing__plus--green"
                        buttonHandler={handleRemoveInvestor}
                        type="button"
                        buttonText={''}
                    />
                )}
            </div>
        </div>
    );
};

export default InviteInvestor;
