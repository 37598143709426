import BidApi from '../api/routes/BidApi';
import BiddingInterface from '../interfaces/property/BiddingInterface';

const removeCommas = (str) => {
    while (str.search(',') >= 0) {
        str = (str + '').replace(',', '');
    }
    return str;
};

const fetchMyCompany = async () => {
    try {
        const { data } = await BidApi.fetchMyCompany();
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const createBidding = async (propertyAssetId: string, body: any) => {
    const response = await BidApi.createBidding(propertyAssetId, body);
    return response.data;
};

const editBidInformation = async (propertyAssetId: string, biddingId: string, body: any) => {
    // body.yield = parseInt(removeCommas(body.yield));
    body.totalNetArea = parseInt(removeCommas(body.totalNetArea));
    body.initialDeposit = parseInt(removeCommas(body.initialDeposit));
    body.bidAmount = parseInt(removeCommas(body.bidAmount));
    body.additionalDeposit = parseInt(removeCommas(body.additionalDeposit));
    body.noi = parseInt(removeCommas(body.noi));
    body.buyerAssumedCapRateInitialYield = parseInt(removeCommas(body.buyerAssumedCapRateInitialYield));

    console.log(body);

    const { data } = await BidApi.editBidInformation(propertyAssetId, biddingId, body);
    return data;
};

const editCompanyInformation = async (propertyAssetId: string, biddingId: string, body: any) => {
    const { data } = await BidApi.editCompanyInformation(propertyAssetId, biddingId, body);
    return data;
};

const fetchBiddingInformations = async (propertyAssetId: string, biddingId: string) => {
    const { data } = await BidApi.fetchBiddingInformations(propertyAssetId, biddingId);
    return data;
};

const createJoinVentureCompanies = async (propertyAssetId: string, biddingId: string, companyId: string) => {
    try {
        const { data } = await BidApi.createJoinVentureCompanies(propertyAssetId, biddingId, companyId);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const createBiddingSecundary = async (propertyAssetId: string, biddingId: string, body: any) => {
    try {
        const { data } = await BidApi.createBiddingSecundary(propertyAssetId, biddingId, body);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const createVentureNameAndType = async (propertyAssetId: string, biddingId: string, body: any) => {
    const { data } = await BidApi.createVentureNameAndType(propertyAssetId, biddingId, body);
    return data;
};

const fetchBidInfo = async (propertyAssetId: string, biddingId: string) => {
    const { data } = await BidApi.fetchBidInfo(propertyAssetId, biddingId);
    return data;
};

const fetchMyBiddings = async (propertyAssetId: string) => {
    const { data } = await BidApi.fetchMyBiddings(propertyAssetId);
    return data;
};

const fetchBiddingStatuses = async () => {
    try {
        const { data } = await BidApi.fetchBiddingStatuses();
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const fetchallBiddingDocuments = async (propertyAssetId: string, biddingId: string) => {
    try {
        const { data } = await BidApi.fetchallBiddingDocuments(propertyAssetId, biddingId);
        return data;
    } catch (err) {
        Promise.reject();
    }
};

const fetchPropertyAsseryStage = async (propertyAssetId: string) => {
    const { data } = await BidApi.fetchPropertyAsseryStage(propertyAssetId);
    return data;
};

const deleteJoinVentureCompanies = async (propertyAssetId: string, biddingId: string, companyId: string) => {
    const { data } = await BidApi.deleteJoinVentureCompanies(propertyAssetId, biddingId, companyId);
    return data;
};

const fetchTypesOfDocuments = async (propertyAssetId: string) => {
    const { data } = await BidApi.fetchTypesOfDocuments(propertyAssetId);
    return data;
};

const createPlaceBid = async (propertyAssetId: string, biddingId: string) => {
    const { data } = await BidApi.createPlaceBid(propertyAssetId, biddingId);
    return data;
};

const fetchStages = async (propertyAssetId: string) => {
    const { data } = await BidApi.fetchStages(propertyAssetId);
    return data;
};

const fetchBriefDeatils = async (propertyAssetId: string) => {
    const { data } = await BidApi.fetchBriefDeatils(propertyAssetId);
    return data;
};

const retractBid = async (propertyAssetId: string, biddingId: string) => {
    const { data } = await BidApi.retractBid(propertyAssetId, biddingId);
    return data;
};

const removeBid = async (propertyAssetId: string, biddingId: string) => {
    const { data } = await BidApi.removeBid(propertyAssetId, biddingId);
    return data;
};

const updateAllowedAssets = async (
    propertyAssetId: string,
    biddingId: string,
    newIds: Array<number>,
    body: BiddingInterface
) => {
    body.subPropertyAssetIds = newIds;
    const { data } = await BidApi.updateAllowedAssets(propertyAssetId, biddingId, body);
    return data;
};

const BidService = {
    createPlaceBid,
    retractBid,
    fetchMyCompany,
    removeBid,
    createBidding,
    fetchBriefDeatils,
    updateAllowedAssets,
    fetchallBiddingDocuments,
    fetchStages,
    fetchBidInfo,
    fetchBiddingStatuses,
    createBiddingSecundary,
    fetchTypesOfDocuments,
    deleteJoinVentureCompanies,
    createVentureNameAndType,
    fetchMyBiddings,
    createJoinVentureCompanies,
    fetchPropertyAssetStage: fetchPropertyAsseryStage,
    editBidInformation,
    fetchBiddingInformations,
    editCompanyInformation,
};
export default BidService;
