import { FunctionComponent } from 'react';
import LOWizardContext from '../../context/LOWizardContext';
import CheckAuthorization from '../StatusPages/CheckAuthorization';
import Wizard from './Wizard';
const ListOpportunity: FunctionComponent = () => {
    return (
        <CheckAuthorization>
            <LOWizardContext>
                <Wizard />
            </LOWizardContext>
        </CheckAuthorization>
    );
};
export default ListOpportunity;
