import { Checkbox, Select } from "antd";
import moment from 'moment';
import { FunctionComponent, useCallback } from "react";
import { ToggleCompanyStatusEnum } from "../../../../../constants/toggleCompanyStatus";
import { InvestorCompanyInfo } from "../../../../../interfaces/investor/CompanyWithInvestor";
import { InvestorUser } from "../../../../../interfaces/investor/InvestorUser";
import PropertyAssetCompanyService from "../../../../../services/PropertyAssetCompanyService";

const { Option } = Select;

interface CompanyInfoProps {
    propertyAssetId: string | number;
    company: InvestorCompanyInfo;
    users: Array<InvestorUser>;
    projectTeam: Array<any>;
}

const CompanyInfo: FunctionComponent<CompanyInfoProps> = ({
    users,
    projectTeam,
    propertyAssetId,
    company
}) => {
    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    const toggleCompanyStatus = useCallback((status: ToggleCompanyStatusEnum) => {
        PropertyAssetCompanyService.toggleCompanyStatus(propertyAssetId.toString(), company.id, status)
    }, []);

    const getCheckboxValues = (): Array<string> => {
        let result: Array<string> = [];
        company.nda && result.push('nda');
        company.bidSubmitted && result.push('bidSubmitted');
        company.revokeAllAccess && result.push('revokeAllAccess');
        company.bulkDownload && result.push('bulkDownload');

        return result;
    }

    return (
        <>
            <Checkbox.Group className="d-flex justify-content-space-evenly" defaultValue={getCheckboxValues()}>
                <Checkbox
                    value={'nda'}
                    defaultChecked={true}
                    className="d-flex flex-direction-column-reverse align-items-center"
                    onChange={(e: any) => toggleCompanyStatus(ToggleCompanyStatusEnum.Nda)}
                >
                    NDA
                </Checkbox>
                <Checkbox
                    defaultChecked={company.bidSubmitted}
                    value={'bidSubmitted'}
                    className="d-flex flex-direction-column-reverse align-items-center"
                    onChange={(e: any) => toggleCompanyStatus(ToggleCompanyStatusEnum.Bid)}
                >
                    Bid Submitted
                </Checkbox>
                <Checkbox
                    defaultChecked={company.revokeAllAccess}
                    value={'revokeAllAccess'}
                    className="d-flex flex-direction-column-reverse align-items-center"
                    onChange={(e: any) => toggleCompanyStatus(ToggleCompanyStatusEnum.RevokeAllAccess)}
                >
                    Revoke All Access
                </Checkbox>
                <Checkbox
                    defaultChecked={company.bulkDownload}
                    value={'bulkDownload'}
                    className="d-flex flex-direction-column-reverse align-items-center"
                    onChange={(e: any) => toggleCompanyStatus(ToggleCompanyStatusEnum.BulkDownload)}
                >
                    Bulk Download
                </Checkbox>
            </Checkbox.Group>
            <div className="company-info-container">
                <div className="d-flex flex-direction-row company-info">
                    <span className="label">Number of Users</span>
                    <span>{users.length}</span>
                </div>
                <div className="d-flex flex-direction-row company-info">
                    <span className="label">Time Accessed</span>
                    <span>0</span>
                </div>
                <div className="d-flex flex-direction-row company-info">
                    <span className="label">Documents Downloaded</span>
                    <span>0</span>
                </div>
                <div className="d-flex flex-direction-row company-info">
                    <span className="label">Last Access</span>
                    <span>{moment().format("DD MMMM, YYYY")}</span>
                </div>
                <div className="d-flex flex-direction-row company-info">
                    <span className="label">Project Team Responsability</span>
                    <Select
                        showSearch
                        className="project-team-dropdown"
                        placeholder={'Project Team User'}
                        optionLabelProp="label"
                        showArrow
                        getPopupContainer={handleContainer}
                    >
                        {projectTeam.map((option, index) => (
                            <Option
                                value={option.user.id}
                                title={option.user.fullName}
                                label={option.user.fullName}
                                className="d-flex"
                                key={index}
                                placeholder={option.user.fullName}
                            >
                                <div className="select-checkbox"></div>
                                {option.user.fullName}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className="d-flex flex-direction-row company-info">
                    <span className="label">Main Contact</span>
                    <Select
                        showSearch
                        className="users-dropdown"
                        placeholder={'Main Contact'}
                        optionLabelProp="label"
                        showArrow
                        getPopupContainer={handleContainer}
                    >
                        {users.map((option, index) => (
                            <Option
                                value={option.id}
                                title={option.fullName}
                                label={option.fullName}
                                className="d-flex"
                                key={index}
                                placeholder={option.fullName}
                            >
                                <div className="select-checkbox"></div>
                                {option.fullName}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
        </>
    );
}

export default CompanyInfo;