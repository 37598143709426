import { useEffect, useRef, useState } from 'react';
import ReportService from '../../../services/ReportService';
import { models, Report, Embed, service, Page } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import useBoolean from '../../../hooks/use-boolean';
import MainLoader from '../../../components/shared/MainLoader';
import ComingSoon from '../../../components/shared/ComingSoon';

const PowerBiReport = (props) => {
    const [report, setReport] = useState<Report>(),
        repo = useRef<any>(),
        [isError, setIsError] = useBoolean(false),
        [isRaportLoad, setIsRaportLoadActions] = useBoolean();

    // Report config useState hook
    // Values for properties like embedUrl, accessToken and settings will be set on click of buttons below
    const [sampleReportConfig, setReportConfig] = useState<models.IReportEmbedConfiguration>({
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: undefined,
    });

    useEffect(() => {
        async function fetchConfig() {
            const config = await ReportService.getReportConfig('913d537b-f360-41dc-93a5-4cbe7bf14d78');
            setReportConfig({
                ...sampleReportConfig,
                embedUrl: config.embedUrl,
                accessToken: config.accessToken,
            });
        }

        fetchConfig();
    }, []);

    // Map of event handlers to be applied to the embedding report
    const eventHandlersMap = new Map([
        [
            'loaded',
            function () {
                console.log('Report has loaded');
                getActivePage(repo.current['_embed'] as Report);
            },
        ],

        [
            'rendered',
            function (e) {
                console.log('Report has rendered');

                getActivePage(repo.current['_embed'] as Report);
                // Update display message
                setMessage('The report is rendered');
            },
        ],
        [
            'error',
            function (event?: service.ICustomEvent<any>) {
                if (event) {
                    setIsError.setTrue();
                    console.error(event.detail);
                }
            },
        ],
    ]);

    // Delete the first visual using powerbi-report-authoring library

    async function getActivePage(powerbiReport: Report): Promise<Page | undefined> {
        const pages = await powerbiReport.getPages();
        // Get the active page
        const activePage = pages.filter(function (page) {
            return page.isActive;
        })[0];

        setIsRaportLoadActions.setTrue();

        return activePage;
    }

    const [displayMessage, setMessage] = useState(``);

    const displayContent = () => {
        if (isError) {
            return <ComingSoon />
        }

        return (
            <>
                <PowerBIEmbed
                    ref={repo}
                    embedConfig={sampleReportConfig}
                    eventHandlers={eventHandlersMap}
                    cssClassName={`report-style-class ${isRaportLoad ? 'report-style-class--show' : ''} `}
                    getEmbeddedComponent={(embedObject: Embed) => {
                        console.log(`Embedded object of type "${embedObject.embedtype}" received`);
                        setReport(embedObject as Report);
                    }}
                />
                {!isRaportLoad && <MainLoader type="regular" mt="mt-200" />}
                <div className="hr"></div>
                <div className="displayMessage">{/* { displayMessage } */}</div>
            </>
        );
    }

    return (
        <div className="data-analytic__overview">
            {displayContent()}
        </div>
    );
};

export default PowerBiReport;
