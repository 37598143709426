import { Configuration, LogLevel } from "@azure/msal-browser";
import { appLocation } from "../utils/util";
const b2cHost: string = process.env.REACT_APP_B2C_HOST || "";
const b2cDomain: string = process.env.REACT_APP_B2C_DOMAIN || "";

const authorityRoot = b2cHost + b2cDomain;

const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
        forgotPassword: "b2c_1_reset",
        signup: "B2C_1A_SIGNUP",
        editProfile: "b2c_1_edit_profile"
    },
    // authorities: {
    //     signUpSignIn: {
    //         authority: authorityRoot + "B2C_1_Signup_SignInDefault",
    //     },
    //     forgotPassword: {
    //         authority: authorityRoot + "b2c_1_reset",
    //     },
    //     editProfile: {
    //         authority: authorityRoot + "b2c_1_edit_profile"
    //     }
    // },
}

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENT_ID || "",
        authority: authorityRoot + b2cPolicies.names.signUpSignIn,
        redirectUri: appLocation + '/overview',
        postLogoutRedirectUri: appLocation,
        knownAuthorities: [authorityRoot + b2cPolicies.names.signUpSignIn],
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {	
                    return;	
                }
                switch (level) {	
                    case LogLevel.Error:	
                        console.error(message);	
                        return;	
                    case LogLevel.Info:	
                        console.info(message);	
                        return;	
                    case LogLevel.Verbose:	
                        console.debug(message);	
                        return;	
                    case LogLevel.Warning:	
                        console.warn(message);	
                        return;	
                    default:
                        return;
                }
            }
        }
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile", process.env.REACT_APP_B2C_SCOPE || ""/*, "demo.read", "demo.write"*/]
};

export const signupRequest = {
    authority: authorityRoot + b2cPolicies.names.signup,
    redirectUri: appLocation + '/SignedUp',
    scopes: ["openid", "profile", process.env.REACT_APP_B2C_SCOPE || ""/*, "demo.read", "demo.write"*/]
};


export const Scopes = {
    api: [process.env.REACT_APP_B2C_SCOPE || ""]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const forgotPasswordRequest = {
    authority: authorityRoot + b2cPolicies.names.forgotPassword,
}

const signupWebhookUrl = appLocation + "";

export const signupUrl = authorityRoot +  "oauth2/v2.0/authorize?p="+ b2cPolicies.names.signup+ "&client_id="+ process.env.REACT_APP_B2C_CLIENT_ID || "" +"&nonce=defaultNonce&redirect_uri="+ encodeURI(signupWebhookUrl) + "&scope=openid&response_type=id_token&prompt=login";


