import { FunctionComponent } from "react";
import { InvestorCompanyNote } from "../../../../../interfaces/investor/InvestorCompanyNote";
import styles from './CompanyNoteList.module.scss';
import Avatar from 'react-avatar'
import { Divider } from "antd";
import moment from 'moment';

interface CompanyNoteListProps {
    data: Array<InvestorCompanyNote>;
}

const CompanyNoteList: FunctionComponent<CompanyNoteListProps> = ({
    data = []
}) => {
    return data.length ?
        (
            <div className={`${styles.noteList} mt-30`}>
                <p className="text-bold mb-20">{`${data.length} comments`}</p>
                {data.map((item, index) => (
                    <>
                        <div className={styles.note} key={index}>
                            <Avatar
                                src={item.userImageUrl}
                                name={item.userName}
                                round={true}
                                size="30"
                                textSizeRatio={2}
                                maxInitials={1}
                            />
                            <div className={styles.noteContentContainer}>
                                <div className={styles.noteContentHeader}>
                                    <p className={`text-bold ${styles.userName} mb-10`}>{item.userName}</p>
                                    <span>{moment(item.date).format("DD MMMM YYYY, hh:mm A")}</span>
                                </div>
                                <p className={styles.noteContent}>{item.content}</p>
                            </div>
                        </div>
                        <Divider className={styles.divider} />
                    </>
                ))}
            </div>
        )
        :
        (<></>)
}

export default CompanyNoteList;