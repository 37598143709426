import { tabs } from '../constants/viewProperty';
import _ from 'lodash';
export const removeFromArray = (array: any, value: number) => {
    return Array.isArray(array) ? array.filter((item) => item !== value) : [];
};

export const addToArray = (array: any, value: number) => {
    return Array.isArray(array) ? [...array, value] : [value];
};

export const filterPropertyAssetFeature = (mainTabs: Array<any>, features: Array<number>) => {
    return _.filter(mainTabs, (tab) => {
        let selectedTab = _.find(tabs, (tabEl) => {
            return tabEl.id == tab.id;
        });

        return !!_.intersection(selectedTab?.featuresIds, features).length;
    });
};
