import { FunctionComponent } from 'react';
import useBoolean from '../hooks/use-boolean';
import DirtyWizardState from './state/DirtyWizardState';

interface DirtyWizardProps {
    children: React.ReactNode;
}

const LOWizardContext: FunctionComponent<DirtyWizardProps> = ({ children }: DirtyWizardProps) => {
    const [isDirty, isDirtyActions] = useBoolean();
    return <DirtyWizardState.Provider value={{ isDirty, isDirtyActions }}>{children}</DirtyWizardState.Provider>;
};

export default LOWizardContext;
