import { Home } from '../pages/Homepage/Home';
import { Switch, Route } from 'react-router-dom';
import ComingSoon from '../components/shared/ComingSoon';
import { NotFound } from '../pages/StatusPages/NotFound';
import CheckAuthorization from '../pages/StatusPages/CheckAuthorization';
import { Unauthorized } from '../pages/StatusPages/Unauthorized';
import { UserWizard } from '../pages/UserWizard/UserWizard';
import SearchProperty from '../pages/Search/SearchProperty';
import ThemeManager from '../pages/ThemeManager';
import LookUpContext from '../context/LookUpContext';
import OverviewCheckUp from '../pages/SideMenuComponents/OverviewCheckUp';
import DocSignFrame from '../pages/DocSign/DocSignFrame';
import DocPreview from '../pages/DocSign/DocPreview';
import CompanyList from '../pages/AdminPanel/Company/CompanyList';
import Pricing from '../pages/Static/Pricing';
import TermsOfUse from '../pages/Static/TermsOfUse';
import Invitations from '../pages/SideMenuComponents/Invitations';
import PrivacyPolicy from '../pages/Static/PrivacyPolicy';
import DataLinkAndTransaction from '../pages/DataLinkAndTransaction/DataLinkAndTransaction';
import ViewExploreData from '../pages/ExploreData';
import TemplateContext from '../context/TemplateContext';
import UserList from '../pages/AdminPanel/User/UserList';
import PermissionService from '../services/Permissions';
import PropertyRoute from './PropertyRoute';
import AccountRoute from './AccountRoute';
import AnalyticsDashboard from '../pages/Analytics';
import BuildingDirectory from '../pages/BuildingDirectory';
import TradeProbability from '../pages/TradeProbability';
import TransactionData from '../pages/TransactionData';

const Routes = () => {
    return (
        <LookUpContext>
            <TemplateContext>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/SignedUp" component={Home} />
                    <Route exact path="/preview/:id" component={DocPreview} />
                    <Route exact path="/pricing" component={Pricing} />
                    <Route exact path="/doc-sign" component={DocSignFrame} />
                    <Route exact path="/terms-of-use" component={TermsOfUse} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/search-property" component={SearchProperty} />
                    <Route exact path="/theme" component={ThemeManager} />
                    <Route exact path="/unauthorized" component={Unauthorized} />
                    <Route exact path="/coming-soon" component={ComingSoon} />
                    <Route
                        exact
                        path="/admin-panel/company"
                        component={() => (
                            <>{PermissionService.canViewAdminPanelMenu() ? <CompanyList /> : <Unauthorized />}</>
                        )}
                    />
                    <Route exact path="/admin-panel/company/theme/:id" component={ThemeManager} />
                    <Route
                        exact
                        path="/admin-panel/users/:id"
                        component={() => (
                            <>{PermissionService.canViewAdminPanelMenu() ? <UserList /> : <Unauthorized />}</>
                        )}
                    />
                    <Route
                        exact
                        path="/analytics"
                        component={AnalyticsDashboard}
                    />
                    <Route exact path="/overview" component={OverviewCheckUp} />
                    <Route exact path="/invitations" component={Invitations} />
                    <Route exact path="/data-link-or-transaction" component={DataLinkAndTransaction} />
                    <Route
                        exact
                        path="/data-analytics"
                        component={() => (
                            <CheckAuthorization>
                                <ViewExploreData />
                            </CheckAuthorization>
                        )}
                    />
                    <Route path="/property">
                        <PropertyRoute />
                    </Route>
                    <Route path="/account-settings">
                        <AccountRoute />
                    </Route>
                    <Route exact path="/building-directory/:id?" component={BuildingDirectory} />
                    <Route exact path="/trade-probability/:id?" component={TradeProbability} />
                    <Route exact path="/transaction-data/:id?" component={TransactionData} />
                    <Route
                        exact
                        path="/user-wizard/:stepName"
                        component={() => (
                            <CheckAuthorization>
                                <UserWizard />
                            </CheckAuthorization>
                        )}
                    />
                    <Route component={NotFound} />
                </Switch>
            </TemplateContext>
        </LookUpContext>
    );
};

export default Routes;
