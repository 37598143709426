import { FunctionComponent } from 'react';
import { Tabs } from 'antd';
import GrossRentChart from './grossRentChart';
import CapitalValueChart from './capitalValueChart';
import MarketYieldChart from './marketYieldChart';
import VacancyChart from './vacancyChart';

const grossRentKey: string = 'GrossRent';
const capitalValueKey: string = 'CapitalValue';
const marketYieldKey: string = 'MarketYield';
const vacancyKey: string = 'Vacancy';

const { TabPane } = Tabs;

const charts = [
    {
        key: grossRentKey,
        value: 'Gross Rent',
        description: 'Heritage Trees',
        component: <GrossRentChart />,
    },
    {
        key: capitalValueKey,
        value: 'Capital Value',
        description: 'Capital Value',
        component: <CapitalValueChart />,
    },
    {
        key: marketYieldKey,
        value: 'Market Yield',
        description: 'Market Yield',
        component: <MarketYieldChart />,

    },
    {
        key: vacancyKey,
        value: 'Vacancy',
        description: 'Vacancy',
        component: <VacancyChart />,
    },
]

const BucketData: FunctionComponent<any> = (props) => {

    return (
        <section className="module-bucket-data mt-40">
             <div className="content-text"> 
              <h1 className="heading">Bucket Analysis based on the selected Properties</h1>
            </div> 
            <Tabs tabPosition={"bottom"}>
                {
                      charts.map((tab: any) => (
                        <TabPane
                            tab={tab.value}             
                            key={tab.key}
                        >
                            {tab.component}
                        </TabPane>
                    ))
                }

            </Tabs>
        </section>
    );
};

export default BucketData;
