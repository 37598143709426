import { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

var marketYield = [
    0.0374, 0.0391, 0.0406, 0.0424, 0.0435, 0.0406, 0.0386, 0.0394, 0.0393, 0.0387, 0.0385, 0.0385, 0.0383, 0.0378,
    0.0378, 0.0378, 0.0383, 0.0384, 0.0382, 0.0378, 0.0378, 0.0378, 0.0377, 0.0377, 0.0377, 0.036, 0.0325, 0.0316,
    0.0336, 0.0361, 0.038,
];

const mySeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: marketYield[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: marketYield[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: marketYield[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: marketYield[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: marketYield[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: marketYield[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: marketYield[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: marketYield[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: marketYield[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: marketYield[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: marketYield[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: marketYield[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: marketYield[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: marketYield[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: marketYield[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: marketYield[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: marketYield[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: marketYield[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: marketYield[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: marketYield[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: marketYield[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: marketYield[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: marketYield[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: marketYield[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: marketYield[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: marketYield[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: marketYield[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: marketYield[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: marketYield[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: marketYield[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: marketYield[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: marketYield[31],
    },
];

am4core.useTheme(am4themes_animated);
const MarketYieldChart = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);

    function processOver(hoveredSeries, chart) {
        hoveredSeries.toFront();

        hoveredSeries.segments.each(function (segment) {
            segment.setState('hover');
        });

        hoveredSeries.legendDataItem.marker.setState('default');
        hoveredSeries.legendDataItem.label.setState('default');
        if (chart) {
            chart.series.each(function (series) {
                if (series != hoveredSeries) {
                    series.segments.each(function (segment) {
                        segment.setState('dimmed');
                    });
                    series.bulletsContainer.setState('dimmed');
                    series.legendDataItem.marker.setState('dimmed');
                    series.legendDataItem.label.setState('dimmed');
                }
            });
        }
    }

    function processOut(chart) {
        chart.series.each(function (series) {
            series.segments.each(function (segment) {
                segment.setState('default');
            });

            series.bulletsContainer.setState('default');
            series.legendDataItem.marker.setState('default');
            series.legendDataItem.label.setState('default');
        });
    }

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);
        let chart = am4core.create('marketYieldChartBucketDataDiv', am4charts.XYChart);

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        chart.dateFormatter.dateFormat = 'yyyy-q';
        chart.numberFormatter.numberFormat = '#.##%';

        var mySeries = chart.series.push(new am4charts.LineSeries());
        mySeries.dataFields.valueY = 'value';
        mySeries.dataFields.dateX = 'date';
        mySeries.name = 'Market Yield';
        mySeries.tooltipText = '{value}';
        mySeries.strokeWidth = 3;
        mySeries.fill = am4core.color('#fff');
        mySeries.tensionX = 0.8;
        mySeries.bullets.push(new am4charts.CircleBullet());
        mySeries.data = mySeriesData;

        var bullet = mySeries.bullets.push(new am4charts.CircleBullet());
        bullet.circle.fill = am4core.color('#fff');

        var bullethover = bullet.states.create('hover');
        bullethover.properties.scale = 2;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'panXY';
        chart.cursor.xAxis = dateAxis;
        chart.cursor.snapToSeries = mySeries;

        chart.legend = new am4charts.Legend();
        chart.legend.position = 'right';
        chart.legend.scrollable = true;

        chart.legend.markers.template.states.create('dimmed').properties.opacity = 0.3;
        chart.legend.labels.template.states.create('dimmed').properties.opacity = 0.3;

        chart.legend.itemContainers.template.events.on('over', function (event) {
            if (event && event?.target && event?.target?.dataItem)
                processOver(event.target.dataItem.dataContext, chart);
        });

        chart.legend.itemContainers.template.events.on('out', function (event) {
            if (event && event?.target && event?.target?.dataItem) processOut(event.target.dataItem.dataContext);
        });

        chartRef.current = chart;

        return () => {
            chart.dispose();
        };
    }, []);

    return <div id="marketYieldChartBucketDataDiv" style={{ width: '100%', height: '500px' }}></div>;
};

export default MarketYieldChart;
