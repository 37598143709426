import PropertySummaryApi from '../api/routes/PropertyAssetPageSummaryApi';

// export const getPropertySummary = async(url:string, id:string): Promise<any> => {
//     try {
//         const { data } = await PropertySummary.fetchPropertyTabTemplates(url,id)
//         return data;
//     } catch(error) {
//         console.log(error)
//     }
// }

const uploadFile = async (propertyAssetId: string, body: any) => {
    const { data } = await PropertySummaryApi.uploadFile(propertyAssetId, body);
    return data;
};

const PropertySummaryService = {
    uploadFile,
};

export default PropertySummaryService;
