import BaseModel from './BaseModel';

export default class CountryModel extends BaseModel {
    id: number;
    name: string;
    isoCode: string;
    phonePrefix: string;
    isActive: boolean;

    constructor(data: any) {
        super();

        this.id = data.id;
        this.name = data.name;
        this.isoCode = data.isoCode;
        this.phonePrefix = data.phonePrefix;
        this.isActive = data.isActive;
    }
}
