import { FunctionComponent } from 'react';
import ShowcaseModel from './models/showcase';
import capitaSpringShowcase from '../../../../assets/images/dashboard/CapitaSprintShowcase.jpg';
import FormattedAmount from '../../../../components/shared/FormattedAmount';

interface Props {
    details: ShowcaseModel;
}
const PropertyShowcase: FunctionComponent<Props> = ({ details }) => {

    return (
        <section className="module-intro mt-40">
            <div className="content-text">
                <div className="button-main button-primary">Export Analysis</div>
                <h1 className="heading">CapitaSpring Property Analysis</h1>
                <ul className="list">
                    <li>
                        <p>Property Name</p><span>{details.propertyName}</span>
                    </li>
                    <li>
                        <p>Address</p><span>{details.map.address}</span>
                    </li>
                    <li>
                        <p>Year of Completion</p><span>{details.yearOfCompletion}</span>
                    </li>
                    <li>
                        <p>Land Tenure</p><span>{details.landTenure}</span>
                    </li>
                    <li>
                        <p>Land Area</p>{details.landArea && <FormattedAmount value={details.landArea} />}
                    </li>
                    <li>
                        <p>Sector (s)</p><span>{details.sectorNames ? details.sectorNames
                                    .map((sector: string) => {
                                        return sector;
                                    })
                                    .join(', ') : ''}</span>
                    </li>
                    <li>
                        <p>Net Leasable Area</p>{details.netLeasableArea && <FormattedAmount value={details.netLeasableArea} />}
                    </li>
                    <li>
                        <p>Gross Leasible Area</p>{details.grossLeasableArea && <FormattedAmount value={details.grossLeasableArea} />}
                    </li>
                    <li>
                        <p>Major Tenants</p><span>{details.majorTenants}</span>
                    </li>
                    <li>
                        <p>Passing Rent</p><span>{details.passingRent}</span>
                    </li>
                    <li>
                        <p>Occupancy</p><span>{details.occupancy}%</span>
                    </li>
                    <li>
                        <p>Net Operating Income (FY 22)</p>{details.noi && <FormattedAmount value={details.noi} />}
                    </li>
                    <li>
                        <p>Capitalisation Rate</p><span>{details.capitalisationRate}%</span>
                    </li>
                    <li>
                        <p>Latest Asset Value</p>{details.assetValue && <FormattedAmount value={details.assetValue} />}
                    </li>
                    <li>
                        <p>Valuation Date</p><span>{details.valuationDate}</span>
                    </li>
                </ul>
                <div className="block-row-circle">
                    <div className="blk-circle big">
                        <div className="circle">96,7</div>
                        <p>Building score</p>
                    </div>
                    <div className="blk-circle">
                        <div className="circle">9,2</div>
                        <p>Walk Score</p>
                    </div>
                    <div className="blk-circle">
                        <div className="circle">8,8</div>
                        <p>ESG</p>
                    </div>
                    <div className="blk-circle">
                        <div className="circle">9,6</div>
                        <p>Transport</p>
                    </div>
                </div>
            </div>
            
            <img className="bg" src={capitaSpringShowcase}/>
        </section>
    );
};

export default PropertyShowcase;
