import BuildingDirectoryApi from '../api/routes/BuildingDirectoryApi';
import TradeProbability from '../pages/TradeProbability/models/tradeProbability';
import TradeProbabilityInfo from '../pages/TradeProbability/models/tradeProbabilityInfo';

const TradeProbabilitiesKey = 'TradeProbabilities';
const CachingTimeInHours = 24;

const GetCachedTime = () => {
    const cachedTime = new Date(localStorage.getItem(`${TradeProbabilitiesKey}Time`)!);
    const msBetweenDates = Math.abs(cachedTime.getTime() - new Date().getTime());
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

    return hoursBetweenDates;
}

const syncDataWithIndexDb = (region: string, data?: TradeProbabilityInfo[]): Promise<TradeProbabilityInfo[]> => {
    return new Promise<TradeProbabilityInfo[]>((resolve, reject) => {
        if (!data) {
            const hoursBetweenDates = GetCachedTime();
            if (hoursBetweenDates > CachingTimeInHours) {
                reject();
                return;
            }
        }

        const idb = window.indexedDB;

        // Open (or create) the database
        const open = idb.open('Virgate', 1);

        // Create the schema
        open.onupgradeneeded = function () {
            const db = open.result;
            const objectStoreExisted = db.objectStoreNames.contains(TradeProbabilitiesKey);

            if (!data && !objectStoreExisted) {
                reject();
            }

            db.createObjectStore(TradeProbabilitiesKey);
        };

        open.onsuccess = function () {
            // Start a new transaction
            const db = open.result;
            const tx = db.transaction(TradeProbabilitiesKey, 'readwrite');
            const store = tx.objectStore(TradeProbabilitiesKey);

            if (data) {
                // Add some data
                store.put(data, region);
                localStorage.setItem(`${TradeProbabilitiesKey}Time`, new Date().toLocaleString());
            } else {
                // Query the data
                const queriedData = store.get(region);

                queriedData.onsuccess = function () {
                    if (queriedData.result) {
                        resolve(queriedData.result);
                    } else {
                        reject();
                    }
                };
            }

            // Close the db when the transaction is done
            tx.oncomplete = function () {
                db.close();
            };
        }
    });
}

const fetchTradeProbabilities = async (region: string, lon?: number, lat?: number): Promise<TradeProbabilityInfo[]> => {
    let tradeProbabilities;

    try {
        tradeProbabilities = await syncDataWithIndexDb(region);
    } catch (error) {
        const response = await BuildingDirectoryApi.fetchTradeProbabilities(region, lon, lat);
        tradeProbabilities = response.data;
        syncDataWithIndexDb(region, tradeProbabilities);
    } finally {
        return tradeProbabilities.map(x => new TradeProbabilityInfo(x));
    }
};

const fetchTradeProbability = async (id: string): Promise<TradeProbability> => {
    const response = await BuildingDirectoryApi.fetchTradeProbability(id);

    return response.data as any;
};

const TradeProbabilityService = {
    fetchTradeProbabilities,
    fetchTradeProbability,
};

export default TradeProbabilityService;
