import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import MainLoader from '../../../../../../components/shared/MainLoader';
import FeatureState from '../../../../../../context/state/FeatureState';
import useBoolean from '../../../../../../hooks/use-boolean';
import PropertyAsserts from '../../../../../../interfaces/property/PropertyAsserts';
import PortfolioService from '../../../../../../services/PortfolioService';
import Single from '../Single';

const PortfolioDetails: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        [isLoading, isLoadingActions] = useBoolean(),
        { propertySummary } = useContext(FeatureState),
        [details, setDetails] = useState<Array<PropertyAsserts>>([]),
        { id: propertyAssetId } = propertySummary;

    const getAllAssetsOfPortfolio = useCallback(async () => {
        try {
            const _assets = await PortfolioService.fetchAssetsOfPortfolioForTable(`${propertyAssetId}`);
            setDetails(_assets.items);
            isLoadingActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    useEffect(() => {
        getAllAssetsOfPortfolio();
    }, [getAllAssetsOfPortfolio]);
    return (
        <>
            {isLoading ? (
                <>
                    {details.map((el) => (
                        <Single propertyAssetId={`${el.id}`} />
                    ))}
                </>
            ) : (
                <MainLoader type="regular" mt="mt-100" mb="mb-100" />
            )}
        </>
    );
};
export default PortfolioDetails;
