import api from '../index';
const fetchIsInFavorite = async (propertyAssetId: number) => {
    return api.get(`/favorite/${propertyAssetId}/check`);
};

const addToFavorite = async (propertyAssetId: number) => {
    return api.post(`/favorite/${propertyAssetId}`);
};

const deleteFromFavorite = async (propertyAssetId: number) => {
    return api.delete(`/favorite/${propertyAssetId}`);
};

const fetchAllFavouritesAssets = async (page: number, items: number) => {
    return api.get(`/favorite/singleassets?pageIdx=${page}&itemsPerPage=${items}`);
};

const fetchAllFavouritesPortfolios = async (page: number, items: number) => {
    return api.get(`/favorite/portfolios?pageIdx=${page}&itemsPerPage=${items}`);
};

const fetchAllDivstemetns = async (page: number, items: number) => {
    return api.get(`/favorite/mydivestments?PageIdx=${page}&ItemsPerPage=${items}`);
};
const fetchAllAcquisitions = async (page: number, items: number) => {
    return api.get(`/favorite/acquisitions?PageIdx=${page}&ItemsPerPage=${items}`);
};
const FavoriteApi = {
    fetchIsInFavorite,
    fetchAllAcquisitions,
    fetchAllFavouritesAssets,
    fetchAllFavouritesPortfolios,
    fetchAllDivstemetns,
    addToFavorite,
    deleteFromFavorite,
};

export default FavoriteApi;
