import { Pagination, Button } from 'antd';
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropertyCard from '../../../components/shared/PropertyCard';
import NavigateState from '../../../context/state/NavigateState';
import ArrayOfPropertyAsserts from '../../../interfaces/property/ArrayOfPropertyAsserts';
import BidService from '../../../services/BidService';
import PortfolioService from '../../../services/PortfolioService';
import BiddingInterface from '../../../interfaces/property/BiddingInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Property from '../../../services/Property';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';
interface Props {
    goToSelectBidInformations: (biddingId: string) => void;
}

interface RouteParams {
    id: string;
    biddingId: string;
}
const NR_ITEMS_PER_PAGE = 9;

const SelectSingleAsset: FunctionComponent<Props> = ({ goToSelectBidInformations }) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        { biddingId } = useParams<RouteParams>(),
        [isAllowSubsetBidding, setAllowSubsetBidding] = useState<boolean>(false),
        [subPropertyAssetIds, setSubPropertyAssetIds] = useState<Array<number>>([]),
        [biddingInfo, setBiddingInfo] = useState<BiddingInterface>({
            biddingId: 0,
            mainPropertyAssetId: 0,
            subPropertyAssetIds: [],
            offerTypeId: 0,
            transactionTypeId: 0,
            acquisitionTypeId: 0,
            currencyId: 0,
            ownershipTypeId: 0,
            jointVentureName: '',
            bidAmount: 0,
            noi: 0,
            buyerAssumedCapRateInitialYield: 0,
            totalNetArea: 0,
            acquisitionStructure: '',
            initialDeposit: 0,
            additionalDeposit: 0,
            closingNumberOfDays: 0,
            dueDilligenceNumberOfDays: 0,
            agreementNumberOfDays: 0,
            comment: '',
        }),
        [allSingleAssetsUnderPortfolio, setAllSingleAssetsUnderPortfolio] = useState<ArrayOfPropertyAsserts>({
            totalCount: 0,
            items: [],
        }),
        [refactoredSubPropertyAssetIds, setRefactoredSubPropertyAssetIds] = useState<Array<any>>([]),
        [pageNumber, setPageNumber] = useState<number>(1),
        [searchString, setSearchString] = useState<string>(''),
        history = useHistory(),
        { cancelEverything, returnToPreviousPage } = useContext(NavigateState);

    const handleNext = async () => {
        try {
            await BidService.updateAllowedAssets(`${propertyAssetId}`, biddingId, subPropertyAssetIds, biddingInfo);
            goToSelectBidInformations(biddingId);
        } catch (error: any) {
            toast(t(`b:${t(`${error.response.data.errorMessage}`)}`));
        }
    };

    const fetchAssetsUnderPortfolio = async () => {
        try {
            const _allSingleAssetsUnderPortfolio = await PortfolioService.fetchAssetsOfPortfolio(
                `${propertyAssetId}`,
                searchString,
                pageNumber - 1,
                NR_ITEMS_PER_PAGE
            );
            setAllSingleAssetsUnderPortfolio(_allSingleAssetsUnderPortfolio);
        } catch (error: any) {
            toast(t(`b:${t(`${error.response.data.errorMessage}`)}`));
        }
    };

    const fetchBiddingDetails = async () => {
        try {
            const _bidDetaios = await BidService.fetchBiddingInformations(`${propertyAssetId}`, biddingId);
            console.log(_bidDetaios);

            setBiddingInfo(_bidDetaios);
            setRefactoredSubPropertyAssetIds(
                _bidDetaios.subPropertyAssetIds.map((elem) => {
                    return { id: elem };
                })
            );
            setSubPropertyAssetIds(_bidDetaios.subPropertyAssetIds);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    const addToListSubPropertyAssetIds = (id: number) => {
        const newArray = [...subPropertyAssetIds];
        newArray.push(id);
        setRefactoredSubPropertyAssetIds(
            newArray.map((elem) => {
                return { id: elem };
            })
        );
        setSubPropertyAssetIds(newArray);
    };

    const removeFromListSubPropertyAssetIds = (id: number) => {
        const newArray = [...subPropertyAssetIds.filter((elem) => elem !== id)];
        setRefactoredSubPropertyAssetIds(
            newArray.map((elem) => {
                return { id: elem };
            })
        );
        setSubPropertyAssetIds(newArray);
    };

    const checkCanBidForSubassets = useCallback(async () => {
        try {
            const property = await Property.fetch(Number(propertyAssetId));
            setAllowSubsetBidding(property.allowSubsetBidding);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    useEffect(() => {
        fetchBiddingDetails();
        fetchAssetsUnderPortfolio();
        checkCanBidForSubassets();
    }, [checkCanBidForSubassets]);

    return (
        <div className="select-single-asset-page">
            <div className="select-single-asset-page__main  d-flex f-wrap">
                {allSingleAssetsUnderPortfolio.items.map((element) => {
                    return (
                        <div
                            className="select-single-asset-page__single-card"
                            style={{ position: 'relative' }}
                            key={element.id}
                        >
                            <PropertyCard
                                property={element}
                                key={element.id}
                                showFavorite
                                className={'property-card__container--full-height'}
                            />
                            {isAllowSubsetBidding && (
                                <>
                                    {subPropertyAssetIds.includes(element.id) ? (
                                        <button
                                            type="button"
                                            className="button-main button-primary marketing__plus marketing__plus--green select-single-asset-page__btn-positon"
                                            onClick={() => {
                                                removeFromListSubPropertyAssetIds(element.id);
                                            }}
                                        />
                                    ) : (
                                        <button
                                            type="button"
                                            className="button-main button-primary marketing__plus marketing__plus--center select-single-asset-page__btn-positon"
                                            onClick={() => {
                                                addToListSubPropertyAssetIds(element.id);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className="col-xs-12-clear d-flex justify-content-center mb-120 mt-50">
                {allSingleAssetsUnderPortfolio?.totalCount ? (
                    <Pagination
                        defaultCurrent={1}
                        pageSize={9}
                        onChange={(e: number) => {
                            setPageNumber(e);
                            fetchAssetsUnderPortfolio();
                        }}
                        total={allSingleAssetsUnderPortfolio?.totalCount}
                    />
                ) : (
                    <h1>No data</h1>
                )}
            </div>

            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                <p className="previous-page" onClick={() => returnToPreviousPage()}>
                    {t('f:button.previousStep')}
                </p>
                <Button
                    onClick={() => {
                        cancelEverything(externalId, PROPERTY_TABS.TRANSACTION.name);
                    }}
                    className="button-main button-secondary d-flex justify-content-center align-items-center"
                >
                    {t('f:button.cancel')}
                </Button>
                <Button
                    className="button-main button-primary mr-25"
                    onClick={() => {
                        handleNext();
                    }}
                >
                    {t('f:button.next')}
                </Button>
            </div>
        </div>
    );
};
export default SelectSingleAsset;
