import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Table } from 'antd';
import moment from 'moment';
import { DataGrid } from '@material-ui/data-grid';
import PropertyAssetDropdown from '../../../../components/shared/PropertyAssetDropdown';
import { checkHeight } from '../../../../utils/checkHeight';
import FormattedAmount from '../../../../components/shared/FormattedAmount';
import AddEditRecordModal from './AddEditRecordModal';
import PropertyAssetCashFlow from '../../../../services/PropertyAssetCashFlow';
import TreeDotsCashFlow from './partials/TreeDotsCashFlow';
import { ChashFlowListItemI, ChashFlowListItemIForTable } from '../../../../interfaces/cashFlow/ChashFlowListItem';
import { toast } from 'react-toastify';
import useBoolean from '../../../../hooks/use-boolean';
import FeatureState from '../../../../context/state/FeatureState';
import { isMobile } from 'react-device-detect';
import CashFlowTableMobile from './partials/CashFlowTableMobile';

interface CashFlowTableProps {
    cashFlowItems: Array<ChashFlowListItemI>;
    refetch: () => void;
    handleDropDownSelect: (event: any) => void;
}

const CashFlowTable: FunctionComponent<CashFlowTableProps> = ({ cashFlowItems, handleDropDownSelect, refetch }) => {
    const [form] = Form.useForm();
    const [isEditModal, setIsEditModalActions] = useBoolean();
    const [recordToEdit, setRecordToEdit] = useState<any>([]);
    const [data, setData] = useState<Array<ChashFlowListItemIForTable>>([]);
    const { t } = useTranslation(['f', 'b']);
    const { propertySummary, getFeature } = useContext(FeatureState);
    const { id: propertyAssetId } = propertySummary;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (cashFlowItems) {
            cashFlowItems.length
                ? setData(
                    cashFlowItems.map((elem) => {
                        const obj: ChashFlowListItemIForTable = { ...elem, id: elem.cashFlowId };
                        return obj;
                    })
                )
                : setData([]);
            setIsLoading(true);
        } else {
            setData([]);
        }
    }, [cashFlowItems]);

    const handleOnEditClick = useCallback(
        (record: ChashFlowListItemI) => {
            setRecordToEdit(record);
            setIsEditModalActions.setTrue();
        },
        [setIsEditModalActions]
    );

    const handleOnDeleteClick = useCallback(
        async (cashflowId: string) => {
            try {
                await PropertyAssetCashFlow.removeCashflowById(`${propertyAssetId}`, cashflowId);
                refetch();
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [propertyAssetId, refetch, t]
    );

    const columnsCashFlow = [
        {
            field: 'floor',
            headerName: 'Floor',
            flex: 1,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            editable: false,
            visible: false,
        },
        {
            field: 'area',
            headerName: 'Area',
            flex: 1,
            editable: false,
            renderCell: (props) => {
                return <FormattedAmount value={props.row.area} />;
            },
        },
        {
            field: 'leaseStartDate',
            headerName: 'Start Date',
            flex: 1,
            editable: false,
            renderCell: (props) => {
                return props.row.leaseStartDate !== null ? moment(new Date(props.row.leaseStartDate)).calendar() : '-';
            },
        },
        {
            field: 'leaseEndDate',
            headerName: 'End Date',
            flex: 1,
            editable: false,
            renderCell: (props) => {
                return props.row.leaseEndDate !== null ? moment(new Date(props.row.leaseEndDate)).calendar() : '-';
            },
        },
        {
            field: 'passingRent',
            headerName: 'Passing Rent',
            flex: 1,
            editable: false,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            editable: false,
            renderCell: (props) => {
                return props.row.amount ? <FormattedAmount value={props.row.amount} /> : '-';
            },
        },
        {
            field: '',
            headerName: 'Actions',
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (props) => (
                <TreeDotsCashFlow
                    record={props.row}
                    editHandler={handleOnEditClick}
                    deleteHandler={handleOnDeleteClick}
                />
            ),
        },
    ];

    return (
        <Form form={form} component={false}>
            <div className="col-xs-12 cash-flow__buttons upload-spinner d-flex justify-content-space-between align-items-center">
                <div className="col-xs-5 d-flex">
                    <div className="col-xs-9">
                        <PropertyAssetDropdown
                            id={`${propertyAssetId}`}
                            handleDropDownSelect={handleDropDownSelect}
                            classForDrop="col-xs-12-clear col-md-12-clear"
                        />
                    </div>
                </div>
            </div>

            {isLoading && (isMobile ?
                <CashFlowTableMobile data={data} /> :
                (
                    <div style={{ height: checkHeight(data), width: '100%' }} className="mb-100">
                        <DataGrid
                            rows={data}
                            columns={
                                getFeature('CashFlowImport')
                                    ? columnsCashFlow
                                    : columnsCashFlow.slice(0, columnsCashFlow.length - 1)
                            }
                            pageSize={50}
                        />
                    </div>
                )
            )}

            <AddEditRecordModal
                type="edit"
                setIsModalOpened={setIsEditModalActions}
                isModalOpened={isEditModal}
                refetch={refetch}
                recordToEdit={recordToEdit}
            />
        </Form>
    );
};

export default CashFlowTable;
