import { useTranslation } from 'react-i18next';
import * as Factory from 'factory.ts';
import { Avatar, Divider, Tooltip, Button, Select, Modal } from 'antd';
import DashboardCard from './data/card';
import capitalMarkets from './data/capitalMarkets';
import _ from 'lodash';
import { FunctionComponent, useState, useEffect, useContext } from 'react';
import PropertyCardDashboard from './propertyCardDashboard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Slider from 'react-slick';
import MultiSelect from '../../../../components/shared/MultiSelect';

function PreviousButton(props) {
    const { className, style, onClick } = props;
    return (
        <Button className={className} style={{ ...style, display: 'block' }} onClick={onClick} icon={<LeftOutlined />}>
            prev
        </Button>
    );
}

function NextButton(props) {
    const { className, style, onClick } = props;
    return (
        <Button className={className} style={{ ...style, display: 'block' }} onClick={onClick} icon={<RightOutlined />}>
            next
        </Button>
    );
}

const CapitalMarkets = (props: any) => {
    const { t } = useTranslation(['f', 'b']);
    const [cities, setCities] = useState<Array<any>>([]);
    const [properties, setProperties] = useState<Array<any>>([]);
    const [assets, setAssets] = useState<Array<any>>([]);
    const [selectedCities, setSelectedCities] = useState<Array<any>>([]);
    const [selectedAssets, setSelectedAssets] = useState<Array<any>>([]);
    const [search, setSearch] = useState<string>('');
    var settings = {
        dots: true,
        // customPaging: function(i) {
        //   return (
        //     <a     style={{width: "100px"}}>
        //     {i}
        //     </a>
        //   );
        // },
        dotsClass: 'slick-dots slick-thumb',
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        // nextArrow: <PreviousButton />,
        // prevArrow: <NextButton />
    };

    useEffect(() => {
        var cities = _.map(_.uniqBy(capitalMarkets.properties, 'city'), 'city');
        setCities(cities.map((item) => ({ id: item, name: item })));
        setAssets(capitalMarkets.properties.map((property) => ({ id: property.id, name: property.propertyName })));
        setProperties(capitalMarkets.properties);
    }, []);

    return (
        <div>
            <section className="module-capital-market">
                <div className="content-text">
                    <h1 className="heading">Capital Markets</h1>
                    <div className="block-select-option row">
                        <div className="col-xs-12 col-md-3 col-xxl-4 form-group">
                            <MultiSelect
                                label={t('f:research.city')}
                                placeholder={t('f:research.selectCity')}
                                options={cities}
                                onSelected={(value: any) => {
                                    setSelectedCities([...selectedCities, value]);
                                }}
                                onDeleted={(value: any) => {
                                    let removeElem = selectedCities.filter((elem) => elem !== value);
                                    setSelectedCities(removeElem);
                                }}
                                selected={Array.isArray(selectedCities) ? selectedCities : []}
                            />
                        </div>

                        <div className="col-xs-12 col-md-3 col-xxl-4 form-group">
                            <MultiSelect
                                label={t('f:research.asset')}
                                placeholder={t('f:research.asset')}
                                options={assets}
                                onSelected={(value: any) => {
                                    setSelectedAssets([...selectedAssets, value]);
                                }}
                                onDeleted={(value: any) => {
                                    let removeElem = selectedAssets.filter((elem) => elem !== value);
                                    setSelectedAssets(removeElem);
                                }}
                                selected={Array.isArray(selectedAssets) ? selectedAssets : []}
                            />
                        </div>

                        <div className="col-xs-12 col-md-3 col-xxl-4 form-group">
                            <label>{t('f:research.searchProperties')}</label>
                            <input
                                type="text"
                                className="container-search__input"
                                placeholder={t('f:research.searchPlaceHolder')}
                                onChange={(e: any) => {
                                    setSearch(e.target.value);
                                }}
                                onBlur={(e) => {}}
                                name="searchInput"
                            />
                        </div>
                    </div>
                </div>
                <div className="capital-container">
                    <div className="list-container-items row"></div>
                </div>
            </section>
            <div className="dashboard-properties mx-50">
                {properties && (
                    <Slider {...settings}>
                        {properties.map((property) => (
                            <PropertyCardDashboard isSelected={true} elements={property} />
                        ))}
                    </Slider>
                )}
            </div>
        </div>
    );
};

export default CapitalMarkets;
