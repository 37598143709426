import Form from 'antd/lib/form/Form';
import { FieldArray, Formik } from 'formik';
import MainButton from '../../../components/shared/MainButton';
import MainLoader from '../../../components/shared/MainLoader';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import DeallineWizardState from '../../../context/state/DeallineWizardState';
import { validateMarketingTimeline } from '../../../validations/ValidateMarketingTimeline';
import MarketingTimelineSingleStage from './MarketingTimelineListItem';
import MainHeading from '../../../components/shared/MainHeading';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import ModalImportLoading from '../../../components/shared/ModalImportLoading';
import FeatureState from '../../../context/state/FeatureState';

const MarketingTimeline = () => {
    const { t } = useTranslation(['f', 'b']),
        {
            isAllStagesLoad,
            allStages,
            handleFinishButton,
            isAddingOrDeletingActionLoadingActions,
            isAddingOrDeletingActionLoading,
        } = useContext(DeallineWizardState),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        history = useHistory();

    const handleOnSubmitForm = async (values) => {
        if (isAddingOrDeletingActionLoading) return;
        isAddingOrDeletingActionLoadingActions.setTrue();
        handleFinishButton(values.marketingStages);
    };

    return (
        <div className="marketing-timeline wizard-container-steps">
            <MainHeading text={t('f:listOpportunity.bidStages')} addClasses="mb-40" />

            {isAllStagesLoad ? (
                <Formik
                    initialValues={{ marketingStages: allStages }}
                    enableReinitialize={true}
                    validationSchema={validateMarketingTimeline}
                    onSubmit={handleOnSubmitForm}
                >
                    {({ values, setFieldValue, submitForm, resetForm, errors, touched, isValid }) => (
                        <Form>
                            <div className="col-xs-12-clear mb-100">
                                <FieldArray
                                    name="marketingStages"
                                    render={({ remove, push }) => (
                                        <MarketingTimelineSingleStage
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            setFieldValue={setFieldValue}
                                            push={push}
                                        />
                                    )}
                                />
                            </div>

                            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                                <MainButton
                                    size="main"
                                    layout="secondary"
                                    buttonText={t('f:button.cancel')}
                                    buttonHandler={() => {
                                        history.push(`/property/${externalId}#${PROPERTY_TABS.PROPERTY_SUMMARY.name}`);
                                    }}
                                />
                                <MainButton
                                    size="main"
                                    layout="primary"
                                    buttonText={t('f:button.finish')}
                                    type="submit"
                                    disabled={!isValid}
                                    buttonHandler={submitForm}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : (
                <div className="col-xs-12">
                    <MainLoader type="regular" />
                </div>
            )}
            <ModalImportLoading text={t(`f:loaders.uploading`)} isModalOpened={isAddingOrDeletingActionLoading} />
        </div>
    );
};

export default MarketingTimeline;
