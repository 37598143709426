import { ChangeEventHandler, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LookUpState from '../../../context/state/LookUpState';
import RouteParams from '../../../interfaces/general/Route';
import { useTranslation } from 'react-i18next';
import { InputNumber, Upload } from 'antd';
import { toast } from 'react-toastify';
import ListOpportunityService from '../../../services/ListOpportunityService';
import MyNumberInput from '../../AddEditProperty/components/MyNumberInput';
import PortfolioService from '../../../services/PortfolioService';
import api from '../../../api';
import MainHeading from '../../../components/shared/MainHeading';
import MainLoader from '../../../components/shared/MainLoader';
import { PROPERTY_TABS } from '../../../constants/propertyTab';
import FeatureState from '../../../context/state/FeatureState';

interface Props {
    returnToPreviousPage: () => void;
    cancelEverything: () => void;
}

const BiddingStructure: FunctionComponent<Props> = ({ returnToPreviousPage, cancelEverything }) => {
    const { getContent } = useContext(LookUpState),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        history = useHistory(),
        [ndaType, setNdaType] = useState<string | null>(null),
        [startingPrice, setStartingPrice] = useState<string>(''),
        [bidTresholdAmount, setBidTresholdAmount] = useState<number | null>(null),
        [allDocumentsType, setAllDocumentTypes] = useState<Array<any>>([]),
        [isLoading, setIsLoading] = useState<boolean>(false),
        [isNdaRequired, setIsNdaRequired] = useState<any | null>(null),
        [allowBuyersToBidOnSingleAssets, setAllowBuyersToBidOnSingleAssets] = useState<boolean>(false),
        [custmNdaUrl, setCustmNdaUrl] = useState<string | null>(''),
        [isNdaRequiredCheckUp, setIsNdaRequiredCheckUp] = useState<boolean>(false),
        [deadlineExtensionInDays, setDeadlineExtensionInDays] = useState<number | null>(null),
        [bidingDocuments, setBidingDocuments] = useState<Array<any>>([]),
        { t } = useTranslation('f');

    const handleClickOnDocType = (value: string) => {
        if (bidingDocuments.includes(parseInt(value))) {
            setBidingDocuments(bidingDocuments.filter((elem) => elem !== parseInt(value)));
            return;
        }
        setBidingDocuments([...bidingDocuments, parseInt(value)]);
    };

    const checkNdaType = (type: string) => {
        switch (`${type}`) {
            case '0':
                setIsNdaRequired('0');
                setNdaType('0');
                break;
            case '1':
                setIsNdaRequired('1');
                setNdaType('1');
                break;
            case '2':
                setIsNdaRequired('1');
                setNdaType('2');
                break;
            default:
                break;
        }
    };

    const onNextHandler = async () => {
        if (isNdaRequired === null) {
            toast.error('Please select is NDA required');
            return;
        }

        if (ndaType === null) {
            toast.error('Please select NDA Type field');
            return;
        }

        if (deadlineExtensionInDays === null) {
            toast.error('Deadline Extension is required');
            return;
        }

        if (bidTresholdAmount === null) {
            toast.error('Bid Treshold Amount is required');
            return;
        }

        if (isNdaRequired === '1' && ndaType === '0') {
            toast.error('Please add custom NDA field');
            return;
        }

        try {
            await ListOpportunityService.createBidingStructure(`${propertyAssetId}`, {
                ndaType,
                biddingDocumentTypeIds: bidingDocuments,
                custmNdaUrl,
                bidTresholdAmount,
                deadlineExtensionInDays,
                startingPrice,
            });

            history.push(`/property/${externalId}#${PROPERTY_TABS.TRANSACTION.name}`);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    const fetchBidingStructure = useCallback(async () => {
        try {
            const biddingStructure = await ListOpportunityService.fetchBidingStructure(`${propertyAssetId}`);

            if (biddingStructure) {
                checkNdaType(`${biddingStructure.ndaType}`);
                setBidingDocuments(biddingStructure.biddingDocumentTypeIds);
                setNdaType(`${biddingStructure.ndaType}`);
                setDeadlineExtensionInDays(biddingStructure.deadlineExtensionInDays);
                setCustmNdaUrl(biddingStructure.customNdaUrl);
                setBidTresholdAmount(biddingStructure.bidTresholdAmount);
                setStartingPrice(biddingStructure.startingPrice);
                setIsLoading(true);
            }
        } catch (error: any) {
            if (error) toast(t(`b:${error.response.data.errorMessage}`));
            setIsLoading(true);
        }
    }, [propertyAssetId, t]);

    const customRequest = (options: any) => {
        const data = new FormData();
        data.append('File', options.file);
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: 'text/plain',
            },
        };
        api.post(options.action, data, config);
    };

    const setIsAllowedSubidding = async () => {
        try {
            allowBuyersToBidOnSingleAssets
                ? await PortfolioService.disableSubsetBidding(`${propertyAssetId}`)
                : await PortfolioService.enableSubsetBidding(`${propertyAssetId}`);
            setAllowBuyersToBidOnSingleAssets(!allowBuyersToBidOnSingleAssets);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    const handleChangeIsRequire: ChangeEventHandler<HTMLInputElement> = (e) => setIsNdaRequired(e.target.value);
    const handleChangeNDAType: ChangeEventHandler<HTMLInputElement> = (e) => setNdaType(e.target.value);

    useEffect(() => {
        if (isNdaRequired === '0') {
            setIsNdaRequiredCheckUp(true);
            setNdaType('0');
        } else {
            setIsNdaRequiredCheckUp(false);
        }
    }, [isNdaRequired]);

    useEffect(() => {
        fetchBidingStructure();
        setAllDocumentTypes(getContent('BiddingDocumentType'));
    }, [getContent, fetchBidingStructure]);

    return (
        <div className="wizard-container-steps">
            <p className="listing-type__help">
                Having trouble? <span className="listing-type__help-link">{t('f:listOpportunity.getHelp')}</span>
            </p>
            <MainHeading text={t('f:listOpportunity.setupBidSettings')} />

            {isLoading ? (
                <>
                    <div className="d-flex f-wrap flex-direction-column">
                        <div className="mt-15 mr-50">
                            <p className="text-airbnb-18">{t('f:listOpportunity.requiredNda')}</p>
                            <div>
                                <label
                                    htmlFor="require"
                                    className="d-flex align-items-center mt-30 mb-20 mr-40 checkbox text-rubik-16 f-wrap"
                                >
                                    <input
                                        className="mr-15 select-checkbox"
                                        type="checkbox"
                                        name="require"
                                        value="1"
                                        checked={isNdaRequired === '1'}
                                        onChange={handleChangeIsRequire}
                                    />
                                    <span className="text-rubik-16">{t('f:listOpportunity.yes')}</span>
                                </label>
                                <label htmlFor="require" className="d-flex align-items-center mt-30 mb-20 checkbox">
                                    <input
                                        className="mr-15 select-checkbox"
                                        type="checkbox"
                                        name="require"
                                        value="0"
                                        checked={isNdaRequired === '0'}
                                        onChange={handleChangeIsRequire}
                                    />
                                    <span className="text-rubik-16">{t('f:listOpportunity.no')}</span>
                                </label>
                            </div>
                        </div>

                        <div className="mt-10">
                            <p className="text-airbnb-18">{t('f:listOpportunity.ndaType')}</p>
                            <div className="d-flex f-wrap">
                                <label
                                    htmlFor="require"
                                    className="d-flex align-items-center mt-30 mb-20 checkbox text-rubik-16 f-wrap"
                                >
                                    <input
                                        className="mr-15 select-checkbox"
                                        type="checkbox"
                                        name="require"
                                        value="1"
                                        checked={ndaType === '1'}
                                        disabled={isNdaRequired === '0'}
                                        onChange={handleChangeNDAType}
                                    />
                                    <span className="text-rubik-16">{t('f:listOpportunity.genericNda')}</span>
                                </label>
                                <label
                                    htmlFor="require"
                                    className="d-flex align-items-center mt-30 mxl-40 mb-20 checkbox"
                                >
                                    <input
                                        className="mr-15 select-checkbox"
                                        type="checkbox"
                                        name="require"
                                        value="0"
                                        checked={ndaType === '0'}
                                        onChange={handleChangeNDAType}
                                    />
                                    <span className="text-rubik-16">{t('f:listOpportunity.no')}</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="mt-20 col-xs-12-clear">
                        <MainHeading text={t('f:listOpportunity.uploadCustomNda')} addClasses="mb-20" />
                        <Upload
                            name="Files"
                            action={process.env.REACT_APP_API_URL + `/listopportunity/${propertyAssetId}/customnda`}
                            customRequest={customRequest}
                            listType="text"
                            disabled={isNdaRequiredCheckUp}
                        >
                            <p className="col-xs-12-clear listing-type__help-link">{t('f:button.upload')}</p>
                        </Upload>
                    </div>
                    <div className="col-xs-12-clear mb-20">
                        <div className="biding__gap d-flex d-flex">
                            <div className="form-group">
                                <p className="text-airbnb-18 mb-10 biding__lable">{t('f:property.startingPrice')}</p>
                                <MyNumberInput
                                    placeholder={t('f:property.startingPrice')}
                                    value={startingPrice}
                                    onValueChange={(val) => setStartingPrice(val.floatValue)}
                                />
                            </div>
                        </div>
                        <div className="biding__gap d-flex d-flex">
                            <div className="form-group">
                                <p className="text-airbnb-18 biding__lable">
                                    {t('f:listOpportunity.deadlineExtensionInDays')}
                                </p>
                                <InputNumber
                                    className="mt-15"
                                    value={deadlineExtensionInDays ? deadlineExtensionInDays : ''}
                                    placeholder={t('f:listOpportunity.deadlineExtensionInDays')}
                                    style={{ width: 250, border: '1px solid #eff3f9', borderRadius: '5px' }}
                                    onChange={(val: any) => {
                                        setDeadlineExtensionInDays(val);
                                    }}
                                />
                            </div>

                            <div className="form-group">
                                <p className="text-airbnb-18">{t('f:listOpportunity.BidTresholdAmount')}</p>
                                <InputNumber
                                    className="mt-15"
                                    value={bidTresholdAmount ? bidTresholdAmount : ''}
                                    placeholder={t('f:listOpportunity.BidTresholdAmount')}
                                    style={{ width: 250, border: '1px solid #eff3f9', borderRadius: '5px' }}
                                    onChange={(val: any) => {
                                        setBidTresholdAmount(val);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12-clear">
                        <MainHeading text={t('f:listOpportunity.bidingDocuments')} />

                        <div className="d-flex biding__type-document">
                            {allDocumentsType?.map((elem: any) => {
                                return (
                                    <label
                                        htmlFor="require"
                                        className="d-flex align-items-center mt-30 checkbox biding__single"
                                    >
                                        <span
                                            onClick={() => {
                                                handleClickOnDocType(elem.id);
                                            }}
                                        >
                                            <input
                                                className="mr-15 select-checkbox"
                                                type="checkbox"
                                                value={elem.id}
                                                checked={bidingDocuments?.includes(elem.id)}
                                            />
                                        </span>
                                        <span className="text-rubik-16">{elem.name}</span>
                                    </label>
                                );
                            })}
                        </div>
                    </div>

                    <div className="col-xs-12-clear mt-40 mb-120">
                        <div className="d-flex biding__type-document">
                            <label
                                htmlFor="require"
                                className="d-flex align-items-center mt-30 checkbox biding__single"
                            >
                                <span
                                    onClick={() => {
                                        setIsAllowedSubidding();
                                    }}
                                >
                                    <input
                                        className="mr-15 select-checkbox"
                                        type="checkbox"
                                        checked={allowBuyersToBidOnSingleAssets}
                                    />
                                </span>
                                <span className="text-rubik-16">{t('f:bid.allowBuyersToBidOnSingleAssets')}</span>
                            </label>
                        </div>
                    </div>
                </>
            ) : (
                <MainLoader type="regular" />
            )}

            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                <p className="previous-page" onClick={() => returnToPreviousPage()}>
                    {t('f:button.previousStep')}
                </p>
                <button
                    onClick={cancelEverything}
                    className="button-main button-secondary d-flex justify-content-center align-items-center"
                    type="button"
                >
                    {t('f:button.cancel')}
                </button>
                <button
                    className="button-main button-primary mr-25"
                    type="submit"
                    onClick={() => {
                        onNextHandler();
                    }}
                >
                    {t('f:button.finish')}
                </button>
            </div>
        </div>
    );
};

export default BiddingStructure;
