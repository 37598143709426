import { PropertyToggleStatusBody } from '../../interfaces/property/PropertyAsserts';
import api from '../index';
import { defaultTransformers } from '../index';
import PropertyAssetFeatureTransformer from '../transformers/PropertyAssetFeatureTransformer';

const fetchPropertyAssetFeature = (id: string) => {
    return api.get(`/propertyassetfeatures/${id}`);
};

const fetchAssetHero = (id: string) => {
    return api.get(`/propertyasset/${id}/mainsummary`, {
        transformResponse: [...defaultTransformers(), PropertyAssetFeatureTransformer.transform],
    });
};

const fetchAssetHeroGUI = (externalId: string) => {
    return api.get(`/propertyasset/byexternal/${externalId}/mainsummary`);
};

const fetchAllFeatures = () => {
    return api.get(`/propertyassetfeatures/enum/features`);
};

const toggleStatus = (propertyAssetId: string, body: PropertyToggleStatusBody) => {
    return api.put(`/PropertyAsset/${propertyAssetId}/toggleStatus`, body);
};

const fetchDealRoomFeatures = (propertyAssetId: number | string) => {
    return api.get(`/propertyassetfeatures/dealRoom/${propertyAssetId}`);
}

const updateDealRoomFeatures = (propertyAssetId: number | string, features: Array<number>) => {
    return api.post(`/propertyassetfeatures/dealRoom/${propertyAssetId}`, features);
}

const PropertyAssetFeatureApi = {
    fetchAllFeatures,
    fetchAssetHero,
    fetchPropertyAssetFeature,
    toggleStatus,
    fetchAssetHeroGUI,
    fetchDealRoomFeatures,
    updateDealRoomFeatures
};
export default PropertyAssetFeatureApi;
