import { FunctionComponent, useEffect, useState } from 'react';
import MainHeading from './MainHeading';

interface LoadingTextProps {
    text: string;
    handler?: () => void;
}

const LoadingText: FunctionComponent<LoadingTextProps> = ({ text, handler }: LoadingTextProps) => {
    const [slicedText, setSlicedText] = useState<string>('....');

    useEffect(() => {
        let i = 0;
        const myLoop = () => {
            setSlicedText(slicedText.slice(0, i));
            setTimeout(function () {
                i++;
                if (i === slicedText.length) {
                    i = 0;
                    myLoop();
                } else {
                    myLoop();
                }
            }, 2000);
        };
        myLoop();
    }, []);
    return <MainHeading text={`${text}${slicedText}`} handler={handler} addClasses="wizard-heading--loading" />;
};

export default LoadingText;
