import { FunctionComponent } from 'react';
interface PublisInfoTitleProps {
    handlerOnClick?: () => void;
    title: string;
    buttonText?: string;
    isTop: boolean;
    isEditable: boolean;
}
const PublisInfoTitle: FunctionComponent<PublisInfoTitleProps> = ({
    title,
    buttonText,
    handlerOnClick,
    isTop,
    isEditable,
}: PublisInfoTitleProps) => (
    <div className={`overview__informations${isTop ? '-top' : ''} d-flex justify-content-space-between`}>
        <span className="overview__span">{title}</span>
        {isEditable && (
            <button type="button" className="overview__edit" onClick={handlerOnClick}>
                {buttonText}
            </button>
        )}
    </div>
);

export default PublisInfoTitle;
