import { FunctionComponent, useState } from 'react';
import FaqThreeDotMenu from '../faqComponents/FaqThreeDotMenu';
import styles from './Question.module.scss';
import { isMobile } from 'react-device-detect';

interface Props {
    index: any;
    question: any;
    toggle: Function;
    isAnswerDropdownOpened: boolean;
    openEditableModal: Function;
    removeQuestionHandler: Function;
}

const Question: FunctionComponent<Props> = (props) => {
    const [isThreeDotClicked, setIsThreeDotClicked] = useState<boolean>(false);

    const arrow = () => {
        const className = props.isAnswerDropdownOpened === props.index ?
            styles.expandedArrowIcon : styles.collapsedArrowIcon;

        return (
            <div onClick={() => props.toggle(props.index)}>
                <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    className={className}
                >
                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                </svg>
            </div>
        )
    }

    return (
        <div
            key={props.index}
            className="questions-content"
            onMouseLeave={() => {
                setIsThreeDotClicked(false);
            }}
        >
            <div className="questions-content__question d-flex justify-content-space-between">
                <p>{props.question.question}</p>
                <div className="d-flex justify-content-space-between">
                    {!isMobile && (
                        <div>
                            <FaqThreeDotMenu
                                question={props.question}
                                removeQuestionHandler={props.removeQuestionHandler}
                                openEditableModal={props.openEditableModal}
                                isThreeDotClicked={isThreeDotClicked}
                                setIsThreeDotClicked={setIsThreeDotClicked}
                            />
                        </div>
                    )}
                    {arrow()}
                </div>
            </div>
            <div className="questions-content__answer">
                {props.isAnswerDropdownOpened === props.index ? (
                    <div>
                        <p className="mb-30">{props.question.answer}</p>
                        <img src={props.question.fileName} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Question;
