import LineRentChart from '../../ViewProperty/components/dashboardComponents/LRC';
import BarRecordChart from '../../ViewProperty/components/dashboardComponents/BRC';
import PieRecordChart from '../../ViewProperty/components/dashboardComponents/PRC';
import { MainDataI } from '../../../interfaces/DataAnalytics/Pages';

interface ChartPreviewProps {
    category: number;
    showMainData: boolean;
    mainData: MainDataI | null;
}

const ChartPreview = ({ category, showMainData, mainData }: ChartPreviewProps) => {
    switch (category) {
        case 3:
            return (
                <div className="col-xs-12-clear">
                    <PieRecordChart showMainData={showMainData} mainData={mainData} />
                </div>
            );

        case 2:
            return (
                <div className="col-xs-12-clear">
                    <BarRecordChart showMainData={showMainData} mainData={mainData} />
                </div>
            );

        default:
            return (
                <div className="col-xs-12-clear">
                    <LineRentChart showMainData={showMainData} mainData={mainData} />
                </div>
            );
    }
};
export default ChartPreview;
