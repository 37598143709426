import { useRef, useLayoutEffect, useContext } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import TemplateContextState from '../../../../context/state/TemplateContextState';
import MainHeading from '../../../../components/shared/MainHeading';

am4core.useTheme(am4themes_animated);

const PieChart = (props) => {
    const chartRef = useRef<am4charts.XYChart | null>(null),
        { chartData, chartTitle, chartSource } = useContext(TemplateContextState);

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);

        var chart: any = am4core.create(
            props.showMainData ? `pieRentChart${props.mainData.ID}` : 'pieRentChart',
            am4charts.PieChart
        );

        chart.data = props.showMainData ? props.mainData.chart : chartData;

        // Add label
        chart.innerRadius = 100;
        var label = chart.seriesContainer.createChild(am4core.Label);
        label.horizontalCenter = 'middle';
        label.verticalCenter = 'middle';
        label.fontSize = 50;

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'size';
        pieSeries.dataFields.category = 'sector';

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div className="d-flex flex-direction-column align-items-center">
            <MainHeading text={props.showMainData ? props.mainData.title : chartTitle} />

            <div
                id={props.showMainData ? `pieRentChart${props.mainData.ID}` : 'pieRentChart'}
                style={{ width: '100%', height: '500px' }}
            ></div>
            <label>{props.showMainData ? props.mainData.source : chartSource}</label>
        </div>
    );
};

export default PieChart;
