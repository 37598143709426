import { FunctionComponent, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'antd';
import useBoolean from '../../hooks/use-boolean';
import { TreeDotsActions } from '../../interfaces/general/general';

interface MainTreeDotsProps {
    actionsMenu: Array<TreeDotsActions>;
    noMargin?: boolean;
}
const MainTreeDots: FunctionComponent<MainTreeDotsProps> = ({
    actionsMenu,
    noMargin = false
}) => {
    const [isThreeDotClicked, setIsThreeDotClickedAction] = useBoolean();

    const handleClose = useCallback(() => {
        setIsThreeDotClickedAction.setFalse();
    }, [setIsThreeDotClickedAction]);

    const handleToggle = useCallback(() => {
        setIsThreeDotClickedAction.toggle();
    }, [setIsThreeDotClickedAction]);

    const content = (
        <div className="d-flex flex-direction-column" onMouseLeave={handleClose}>
            {actionsMenu.map((el, i) => (
                <span
                    style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                    onClick={el.handler}
                    key={i}
                >
                    {el.text}
                </span>
            ))}
        </div>
    );

    return (
        <div className={`${!noMargin ? 'mr-20' : ''}`}>
            <FontAwesomeIcon
                className="mxl-10"
                style={{ color: '#a1abbd', cursor: 'pointer' }}
                icon={faEllipsisH}
                size="lg"
                onClick={handleToggle}
            ></FontAwesomeIcon>
            <div style={{ marginLeft: '20px' }}>
                <Popover content={content} placement="bottom" trigger="click" visible={isThreeDotClicked}></Popover>
            </div>
        </div>
    );
};

export default MainTreeDots;
