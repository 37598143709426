import { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

var energy = [
    9.8, 10.2, 10.5, 10.7, 10.9, 10.5, 10.1, 9.8, 9.5, 9.2, 8.9, 8.8, 8.6, 8.7, 8.9, 9.2, 9.5, 9.7, 9.8, 10.2, 10.4,
    10.5, 10.6, 10.5, 10.5, 10.1, 9.2, 9.1, 9.8, 10.8, 11.6,
];

const energySeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: energy[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: energy[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: energy[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: energy[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: energy[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: energy[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: energy[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: energy[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: energy[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: energy[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: energy[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: energy[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: energy[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: energy[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: energy[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: energy[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: energy[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: energy[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: energy[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: energy[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: energy[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: energy[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: energy[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: energy[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: energy[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: energy[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: energy[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: energy[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: energy[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: energy[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: energy[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: energy[31],
    },
];

const professionalServices: Array<any> = [
    7.8, 8.0, 8.1, 8.1, 8.1, 8.0, 7.8, 8.0, 7.7, 7.6, 7.4, 7.4, 7.3, 7.3, 7.7, 8.0, 8.2, 8.4, 8.5, 8.8, 9.0, 9.4, 9.4,
    9.4, 9.4, 9.2, 8.3, 8.2, 8.8, 9.9, 10.7,
];

const professionalSeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: professionalServices[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: professionalServices[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: professionalServices[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: professionalServices[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: professionalServices[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: professionalServices[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: professionalServices[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: professionalServices[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: professionalServices[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: professionalServices[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: professionalServices[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: professionalServices[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: professionalServices[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: professionalServices[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: professionalServices[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: professionalServices[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: professionalServices[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: professionalServices[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: professionalServices[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: professionalServices[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: professionalServices[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: professionalServices[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: professionalServices[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: professionalServices[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: professionalServices[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: professionalServices[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: professionalServices[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: professionalServices[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: professionalServices[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: professionalServices[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: professionalServices[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: professionalServices[31],
    },
];

const financialServices: Array<any> = [
    9.2, 9.6, 9.9, 10.3, 10.6, 9.9, 9.4, 9.3, 9.2, 8.9, 8.8, 8.7, 8.5, 8.6, 8.9, 9.2, 9.5, 9.8, 9.8, 10.0, 10.4, 10.7,
    10.7, 10.8, 10.8, 10.4, 9.5, 9.3, 10.0, 11.0, 11.9,
];

const financialSeriesData: Array<any> = [
    {
        date: new Date(2014, 1, 1),
        value: financialServices[0],
    },
    {
        date: new Date(2014, 4, 1),
        value: financialServices[1],
    },
    {
        date: new Date(2014, 7, 1),
        value: financialServices[2],
    },
    {
        date: new Date(2014, 10, 1),
        value: financialServices[3],
    },
    {
        date: new Date(2015, 1, 1),
        value: financialServices[4],
    },
    {
        date: new Date(2015, 4, 1),
        value: financialServices[5],
    },
    {
        date: new Date(2015, 7, 1),
        value: financialServices[6],
    },
    {
        date: new Date(2015, 10, 1),
        value: financialServices[7],
    },
    {
        date: new Date(2016, 1, 1),
        value: financialServices[8],
    },
    {
        date: new Date(2016, 4, 1),
        value: financialServices[9],
    },
    {
        date: new Date(2016, 7, 1),
        value: financialServices[10],
    },
    {
        date: new Date(2016, 10, 1),
        value: financialServices[11],
    },
    {
        date: new Date(2017, 1, 1),
        value: financialServices[12],
    },
    {
        date: new Date(2017, 4, 1),
        value: financialServices[13],
    },
    {
        date: new Date(2017, 7, 1),
        value: financialServices[14],
    },
    {
        date: new Date(2017, 10, 1),
        value: financialServices[15],
    },
    {
        date: new Date(2018, 1, 1),
        value: financialServices[16],
    },
    {
        date: new Date(2018, 4, 1),
        value: financialServices[17],
    },
    {
        date: new Date(2018, 7, 1),
        value: financialServices[18],
    },
    {
        date: new Date(2018, 10, 1),
        value: financialServices[19],
    },
    {
        date: new Date(2019, 1, 1),
        value: financialServices[20],
    },
    {
        date: new Date(2019, 4, 1),
        value: financialServices[21],
    },
    {
        date: new Date(2019, 7, 1),
        value: financialServices[22],
    },
    {
        date: new Date(2019, 10, 1),
        value: financialServices[23],
    },
    {
        date: new Date(2020, 1, 1),
        value: financialServices[24],
    },
    {
        date: new Date(2020, 4, 1),
        value: financialServices[25],
    },
    {
        date: new Date(2020, 7, 1),
        value: financialServices[26],
    },
    {
        date: new Date(2020, 10, 1),
        value: financialServices[27],
    },
    {
        date: new Date(2021, 12, 1),
        value: financialServices[28],
    },
    {
        date: new Date(2022, 12, 1),
        value: financialServices[29],
    },
    {
        date: new Date(2023, 12, 1),
        value: financialServices[30],
    },
    {
        date: new Date(2024, 12, 1),
        value: financialServices[31],
    },
];

am4core.useTheme(am4themes_animated);
const GrossRentChart = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);

    function processOver(hoveredSeries, chart) {
        hoveredSeries.toFront();

        hoveredSeries.segments.each(function (segment) {
            segment.setState('hover');
        });

        hoveredSeries.legendDataItem.marker.setState('default');
        hoveredSeries.legendDataItem.label.setState('default');
        if (chart) {
            chart.series.each(function (series) {
                if (series != hoveredSeries) {
                    series.segments.each(function (segment) {
                        segment.setState('dimmed');
                    });
                    series.bulletsContainer.setState('dimmed');
                    series.legendDataItem.marker.setState('dimmed');
                    series.legendDataItem.label.setState('dimmed');
                }
            });
        }
    }

    function processOut(chart) {
        chart.series.each(function (series) {
            series.segments.each(function (segment) {
                segment.setState('default');
            });

            series.bulletsContainer.setState('default');
            series.legendDataItem.marker.setState('default');
            series.legendDataItem.label.setState('default');
        });
    }

    useLayoutEffect(() => {
        let chart = am4core.create('grossRentBucketDataDiv', am4charts.XYChart);

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        chart.dateFormatter.dateFormat = 'yyyy-q';

        var energySeries = chart.series.push(new am4charts.LineSeries());
        energySeries.dataFields.valueY = 'value';
        energySeries.dataFields.dateX = 'date';
        energySeries.name = 'Energy';
        energySeries.strokeWidth = 3;
        energySeries.tensionX = 0.8;
        energySeries.tooltipText = '{name}: [bold]{valueY}[/]';
        energySeries.bullets.push(new am4charts.CircleBullet());
        energySeries.data = energySeriesData;

        var psSeries = chart.series.push(new am4charts.LineSeries());
        psSeries.dataFields.valueY = 'value';
        psSeries.dataFields.dateX = 'date';
        psSeries.name = 'Professional Services';
        psSeries.strokeWidth = 3;
        psSeries.tensionX = 0.8;
        psSeries.tooltipText = '{name}: [bold]{valueY}[/]';
        psSeries.bullets.push(new am4charts.CircleBullet());
        psSeries.data = professionalSeriesData;

        var fsSeries = chart.series.push(new am4charts.LineSeries());
        fsSeries.dataFields.valueY = 'value';
        fsSeries.dataFields.dateX = 'date';
        fsSeries.name = 'Financial Services';
        fsSeries.strokeWidth = 3;
        fsSeries.tensionX = 0.8;
        fsSeries.tooltipText = '{name}: [bold]{valueY}[/]';
        fsSeries.bullets.push(new am4charts.CircleBullet());
        fsSeries.data = financialSeriesData;

        var series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.valueY = 'value';
        series.dataFields.dateX = 'date';
        series.tensionX = 0.8;
        series.strokeWidth = 3;

        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.fill = am4core.color('#fff');
        bullet.circle.strokeWidth = 3;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.fullWidthLineX = true;
        chart.cursor.xAxis = dateAxis;
        chart.cursor.lineX.strokeWidth = 0;
        chart.cursor.lineX.fill = am4core.color('#000');
        chart.cursor.lineX.fillOpacity = 0.1;

        return () => {
            chart.dispose();
        };
    }, []);

    return <div id="grossRentBucketDataDiv" style={{ width: '100%', height: '500px' }}></div>;
};

export default GrossRentChart;
