import React from 'react';
import { TransactionDataInfo } from '../../pages/TransactionData/models/transaction-data-info.model';

export type TransactionDataStateType = {
    fetchTransactionData: (id: string) => void;
    setTransactionData: (_: any) => void;
    transactionData: TransactionDataInfo | undefined;
    isLoading: boolean;
};

export default React.createContext<TransactionDataStateType>({
    fetchTransactionData: (_: string) => { },
    setTransactionData: (_: any) => { },
    transactionData: undefined,
    isLoading: false,
});
