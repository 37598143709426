import { PROPERTY_TABS } from './propertyTab';
import CashFlow from '../pages/ViewProperty/components/CashFlow';
import DataRoom from '../pages/ViewProperty/components/DataRoom';
import Research from '../pages/ViewProperty/components/Research';
import Faq from '../pages/ViewProperty/components/Faq';
import Permissions from '../pages/ViewProperty/components/Permissions';
import PropertySummary from '../pages/ViewProperty/components/PropertySummary';
import ProjectTeamTab from '../pages/ViewProperty/components/ProjectTeamTab';
import Schedule from '../pages/ViewProperty/components/Schedule';
import Newsletter from '../pages/ViewProperty/components/Newsletter';
import DebtProviders from '../pages/ViewProperty/components/DebtProviders';
import Transaction from '../pages/ViewProperty/components/Transaction';
import { PropertyAssetFeatures } from './propertyAssetFeatures';

export const tabs = [
    {
        id: 10,
        featuresIds: [10, 11],
    },
    {
        id: 20,
        featuresIds: [20, 21],
    },
    {
        //Data room
        id: 30,
        featuresIds: [30, 31],
    },
    {
        id: 40,
        featuresIds: [40, 41, 42],
    },
    {
        id: 50,
        featuresIds: [50, 101, 100],
    },
    {
        id: 60,
        featuresIds: [60, 61],
    },
    //projectteam
    {
        id: 70,
        featuresIds: [70, 71, 72],
    },
    //faq
    {
        id: 80,
        featuresIds: [80, 81, 82],
    },
    //investors
    {
        id: 90,
        featuresIds: [90],
    },
    {
        id: 100,
        featuresIds: [120, 121, 122],
    },
    {
        id: 120,
        featuresIds: [110],
    },
];

// propertySummary.isPortfolio

export const DEFAULT_TABS = [
    {
        id: PROPERTY_TABS.PROPERTY_SUMMARY.id,
        icon: '',
        name: `${PROPERTY_TABS.PROPERTY_SUMMARY.name}`,
        component: <PropertySummary />,
    },
    {
        id: PROPERTY_TABS.CASH_FLOW.id,
        icon: '',
        name: `${PROPERTY_TABS.CASH_FLOW.name}`,
        component: <CashFlow />,
    },
    {
        id: PROPERTY_TABS.DATAROOM.id,
        icon: '',
        name: `${PROPERTY_TABS.DATAROOM.name}`,
        component: <DataRoom />,
    },
    /* Debt providers component temporarily hidden
     {
         id: PROPERTY_TABS.DEBTPROVIDER.id,
         icon: '',
         name:`${PROPERTY_TABS.DEBTPROVIDER.name}`),
         component: <DebtProviders />,
     },
     */
    // {
    //     id: PROPERTY_TABS.TRANSACTION.id,
    //     icon: '',
    //     name:`${PROPERTY_TABS.TRANSACTION.name}`),
    //     component: <Transaction />,
    // },
    {
        id: PROPERTY_TABS.RESEARCH.id,
        icon: '',
        name: `${PROPERTY_TABS.RESEARCH.name}`,
        component: <Research />,
    },
    {
        id: PROPERTY_TABS.PROJECT_TEAM.id,
        icon: '',
        name: `${PROPERTY_TABS.PROJECT_TEAM.name}`,
        component: <ProjectTeamTab />,
    },
    {
        id: PROPERTY_TABS.FAQ.id,
        icon: '',
        name: `${PROPERTY_TABS.FAQ.name}`,
        component: <Faq />,
    },
    {
        id: PROPERTY_TABS.PERMISSIONS.id,
        icon: '',
        name: `${PROPERTY_TABS.PERMISSIONS.name}`,
        component: <Permissions />,
    },
    // {
    //     id: 100,
    //     icon: '',
    //     name: 'schedule',
    //     component: <Schedule />,
    // },
    // {
    //     id: 120,
    //     icon: '',
    //     name: 'newsletterBuilder',
    //     component: <Newsletter />,
    // },
];

export const DEFAULT_DEALROOM_FEATURES = [
    {
        id: PROPERTY_TABS.PROPERTY_SUMMARY.id,
        name: `f:property.${PROPERTY_TABS.PROPERTY_SUMMARY.name}`,
    },
    {
        id: PROPERTY_TABS.CASH_FLOW.id,
        name: `f:property.${PROPERTY_TABS.CASH_FLOW.name}`,
    },
    {
        id: PROPERTY_TABS.DATAROOM.id,
        name: `f:property.${PROPERTY_TABS.DATAROOM.name}`,
    },
    {
        id: PROPERTY_TABS.RESEARCH.id,
        name: `f:property.${PROPERTY_TABS.RESEARCH.name}`,
    },
    {
        id: PROPERTY_TABS.PROJECT_TEAM.id,
        name: `f:property.${PROPERTY_TABS.PROJECT_TEAM.name}`,
    },
    {
        id: PROPERTY_TABS.FAQ.id,
        name: `f:property.${PROPERTY_TABS.FAQ.name}`,
    },
    {
        id: PROPERTY_TABS.PERMISSIONS.id,
        name: `f:property.${PROPERTY_TABS.PERMISSIONS.name}`,
    },
    {
        id: PropertyAssetFeatures.PageSummaryInformationMemorandum,
        name: `f:propertySummary.informationMemorandum`
    },
    {
        id: PropertyAssetFeatures.PageSummaryPropertySummary,
        name: `f:propertySummary.propertySummary`
    },
    {
        id: PropertyAssetFeatures.CashFlowStackingPlan,
        name: `f:dealRoomFeature.cashFlowStackingPlan`
    },
    {
        id: PropertyAssetFeatures.CashFlowAnalysis,
        name: `f:dealRoomFeature.cashFlowAnalysis`
    },
    {
        id: PropertyAssetFeatures.ResearchAnalysis,
        name: `f:dealRoomFeature.virgateAnalysis`
    }
]
