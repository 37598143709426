import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload, Button, Tooltip } from 'antd';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import MainHeading from '../../../components/shared/MainHeading';
import MainButton from '../../../components/shared/MainButton';

interface UploadImageProps {
    setIsModalOpen: (prev: boolean) => void;
    setCancel: (prev: boolean) => void;
    imageUrl: string;
    customRequest: (opt: any) => void;
    setImageUrl: (prev: string) => void;
}

const UploadImage: FunctionComponent<UploadImageProps> = ({
    customRequest,
    setIsModalOpen,
    setCancel,
    imageUrl,
    setImageUrl,
}: UploadImageProps) => {
    const { t } = useTranslation(['f', 'b']);
    return (
        <>
            <MainHeading text={t('f:portfolioPage.uploadPhotos')} addClasses="mb-30" />

            {imageUrl && (
                <div style={{ visibility: 'visible' }}>
                    <MainButton
                        size="small"
                        layout="primary"
                        classAdditions="photo__pencil photo__pencil--trash"
                        buttonText={
                            <div className="d-flex gap-10 align-items-center">
                                <span className="photo__pencil-text">{t('f:button.delete')}</span>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                        }
                        buttonHandler={() => {
                            setImageUrl('');
                            setCancel(false);
                            setIsModalOpen(false);
                        }}
                    />
                    <MainButton
                        size="small"
                        layout="primary"
                        classAdditions="photo__pencil photo__pencil--rich-text"
                        buttonText={
                            <div className="d-flex gap-10 align-items-center">
                                <span className="photo__pencil-text">{t('f:button.edit')}</span>
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </div>
                        }
                        buttonHandler={() => {
                            setIsModalOpen(true);
                            setCancel(false);
                        }}
                    />
                </div>
            )}
            {imageUrl ? <img src={imageUrl} className="newsletter-builder__def-image" alt="property" /> : <br />}
            {!imageUrl && (
                <div className="col-xs-12">
                    <Upload
                        name="Image"
                        className="add-property-image-builder__upload add-property-image-builder__upload--template"
                        showUploadList={false}
                        action={process.env.REACT_APP_API_URL + `/propertyasset/image`}
                        customRequest={(options: any) => {
                            customRequest(options);
                        }}
                    >
                        <Tooltip
                            placement="bottomRight"
                            color="#fd615a"
                            title={t('f:property.photoDimensionRecommended')}
                        >
                            <Button>
                                <p className="teams-export__icon">{t('f:button.upload')}</p>
                            </Button>
                        </Tooltip>
                    </Upload>
                </div>
            )}
        </>
    );
};

export default UploadImage;
