import { DataGrid } from '@material-ui/data-grid';
import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ModalConfirmDelete from '../../../components/shared/ModalConfirmDelete';
import { MainStatusEnum } from '../../../constants/mainStatus';
import { SubStatusEnum } from '../../../constants/subStatus';
import LookUpContext from '../../../context/state/LookUpState';
import useBoolean from '../../../hooks/use-boolean';
import { OverviewTopCards } from '../../../interfaces/property/OverviewPropertyAssetSearch';
import PropertyAsserts from '../../../interfaces/property/PropertyAsserts';
import { checkHeight } from '../../../utils/checkHeight';
import DotsContainer from './DotsContainer';

interface TableViewProps {
    handleDelete: (id: string) => void;
    assets: Array<PropertyAsserts>;
    selected: number;
    setPageSize: (pre: number) => void;
    setPage: (pre: number) => void;
    pageSize: number;
    onUnlist: (propertyAssetId: string) => void;
    onReject: (propertyAssetId: string) => void;
}

const TableView: FunctionComponent<TableViewProps> = ({
    handleDelete,
    assets,
    setPageSize,
    setPage,
    pageSize,
    onUnlist,
    selected,
    onReject,
}: TableViewProps) => {
    const { t } = useTranslation(['f', 'b']),
        [assetId, setAssetId] = useState<string>(''),
        [isConfirmDeleteModalOpen, isConfirmDeleteModalOpenActions] = useBoolean(),
        { countries } = useContext(LookUpContext);

    const renderSubStatusCell = ({ value }) => {
        switch (value) {
            case SubStatusEnum.Portfolio:
                return t('f:sub-status.portfolio');
            case SubStatusEnum.Listed:
                return t('f:sub-status.listed');
            case SubStatusEnum.BidsReceived:
                return t('f:sub-status.bidsReceived');
            case SubStatusEnum.Closed:
                return t('f:sub-status.closed');
            case SubStatusEnum.Invited:
                return t('f:sub-status.invited');
            case SubStatusEnum.NdaSigned:
                return t('f:sub-status.ndaSigned');
            case SubStatusEnum.BidSubmitted:
                return t('f:sub-status.bidSubmitted');
            case SubStatusEnum.Unlisted:
                return t('f:sub-status.unlisted');
            case SubStatusEnum.Rejected:
                return t('f:sub-status.rejected');
            default:
                return '';
        }
    };

    const renderMainStatusCell = ({ value }) => {
        switch (value) {
            case MainStatusEnum.Deal:
                return t('f:main-status.deal');
            case MainStatusEnum.Owner:
                return t('f:main-status.owner');
            default:
                return '';
        }
    };

    const getCountryById = (countryId: any) => {
        return countries.find((item) => item.id === countryId);
    };

    const tableLayout = [
        {
            field: 'propertyName',
            headerName: 'Property Name',
            flex: 1,
            sorter: (a: any, b: any) => a.propertyName.localeCompare(b.propertyName),
            renderCell: ({ value, row }) => {
                return (
                    <Link
                        to={{
                            pathname: `/property/${row.externalId}`,
                        }}
                    >
                        {value}
                    </Link>
                );
            },
        },
        {
            field: 'map',
            headerName: 'Country',
            flex: 1,
            valueFormatter: ({ value }) => {
                const { countryId } = value;
                const country = getCountryById(countryId);
                return country ? country.name : '';
            },
            sortComparator: (a: any, b: any, c: any, d: any) => {
                const countryA = getCountryById(a.countryId);
                const countryB = getCountryById(b.countryId);

                if (!countryA && !countryB) {
                    return 0;
                } else if (!countryA && countryB) {
                    return -1;
                } else if (countryA && !countryB) {
                    return 1;
                } else {
                    return countryA!.name.localeCompare(countryB!.name);
                }
            },
        },

        {
            field: 'sectorNames',
            headerName: 'Sector',
            flex: 1,
            valueFormatter: ({ value }) => value.join(', '),
        },
        {
            field: 'mainStatus',
            headerName: 'Main Status',
            flex: 1,
            renderCell: renderMainStatusCell,
            sortable: false,
        },
        {
            field: 'subStatus',
            headerName: 'Sub-Status',
            flex: 1,
            renderCell: renderSubStatusCell,
            sortable: false,
        },
        {
            field: '',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            onclick: (props) => {
                setAssetId(props.row.id);
            },
            renderCell: (props) => (
                <DotsContainer
                    isConfirmDeleteModalOpenActions={isConfirmDeleteModalOpenActions}
                    property={props.row}
                    canUnlist={props.row.canUnlist}
                    unlistHandler={() => onUnlist(props.row.id)}
                    overviewType={selected}
                    rejectHandler={() => onReject(props.row.id)}
                />
            ),
        },
    ];
    const onPageSizeChange = (pageSize: number) => {
        setPageSize(pageSize);
    };

    const onPageChange = (page: number) => {
        setPage(page);
    };

    return (
        <div style={{ height: checkHeight(assets), width: '100%' }} className="mt-30">
            <DataGrid
                rows={assets}
                columns={tableLayout}
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
            />

            <ModalConfirmDelete
                text={t('f:property.confirmSectionDelete')}
                isModalOpened={isConfirmDeleteModalOpen}
                setIsModalOpenedActions={isConfirmDeleteModalOpenActions}
                handleFinish={() => {
                    handleDelete(assetId);
                }}
            />
        </div>
    );
};

export default TableView;
