import { FunctionComponent } from 'react';
import { RadioBtnsI } from '../../interfaces/cashFlow/RadioBtn';
import RadioBtns from './RadioBtns';
import { isMobile } from 'react-device-detect';

interface TabsContentNavigationProps {
    radioBtns: Array<RadioBtnsI>;
    activComponent: string;
    element: JSX.Element | null;
    elementClassAdditions?: string;
}

const TabsContentNavigation: FunctionComponent<TabsContentNavigationProps> = ({
    radioBtns,
    activComponent,
    element,
    elementClassAdditions
}: TabsContentNavigationProps) => {
    const elementClasses = elementClassAdditions ? 
        `${!isMobile ? 'col-xs-6' : 'col-xs-12'} ${elementClassAdditions}` :
        `${!isMobile ? 'col-xs-6' : 'col-xs-12'}`;

    return (
        <div className={`d-flex cash-flow__top-container ${isMobile ? 'flex-direction-column' : 'flex-direction-row'}`}>
            <div className={`col-xs-6 d-flex flex-direction-column justify-content-center mb-20 mt-20 ${!isMobile ? "ml-40" : ""}`}>
                <div className="d-flex flex-direction-column">
                    <form className="cash-flow__radio d-flex">
                        {radioBtns.map((elem) => (
                            <div className="cash-flow__radio-button d-flex align-items-center mr-40" key={elem.value}>
                                <RadioBtns
                                    value={elem.value}
                                    text={elem.text}
                                    handler={elem.handler}
                                    activComponent={activComponent}
                                />
                            </div>
                        ))}
                    </form>
                </div>
            </div>
            <div className={elementClasses}>{element}</div>
        </div>
    );
}

export default TabsContentNavigation;
