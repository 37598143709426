import ThemeAPI from '../api/routes/ThemeApi';
import { FormikTheme } from '../interfaces/theme';

const fetchThemes = async (companyId: string) => {
    const { data } = await ThemeAPI.fetchThemes(companyId);
    return data;
};

const fetchCompanyTheme = async () => {
    const { data } = await ThemeAPI.fetchCompanyTheme();
    return data;
};

const fetchThemeById = async (themeId: string) => {
    const { data } = await ThemeAPI.fetchThemeById(themeId);
    return data;
};

const createTheme = async (companyId: string, body: FormikTheme) => {
    const { name, loader, lightLogo, logo, ...rest } = body;
    const { data } = await ThemeAPI.createTheme({
        name,
        value: { colors: { ...rest, '--main-text-op': `${rest['--main-text']}6e` }, loader, lightLogo, logo },
        companyId,
    });
    return data;
};

const publishTheme = async (themeId: string) => {
    const { data } = await ThemeAPI.publishTheme(themeId);
    return data;
};

const uploadImage = async (options: any, body: any) => {
    const { data } = await ThemeAPI.uploadImage(options, body);
    return data;
};

const fetchBlobTheme = async (id: string, uid: string) => {
    const { data } = await ThemeAPI.fetchBlobTheme(id, uid);
    return data;
};

const deleteTheme = async (themeId: string) => {
    const { data } = await ThemeAPI.deleteTheme(themeId);
    return data;
};
const restoreToDefault = async (companyId: string) => {
    const { data } = await ThemeAPI.restoreToDefault(companyId);
    return data;
};

const updateTheme = async (themeId: string, body: FormikTheme) => {
    const { name, loader, lightLogo, logo, ...rest } = body;
    const { data } = await ThemeAPI.updateTheme(themeId, {
        name,
        value: { colors: { ...rest, '--main-text-op': `${rest['--main-text']}6e` }, loader, lightLogo, logo },
    });
    return data;
};

const ThemeService = {
    fetchThemes,
    fetchCompanyTheme,
    createTheme,
    publishTheme,
    fetchThemeById,
    uploadImage,
    fetchBlobTheme,
    deleteTheme,
    updateTheme,
    restoreToDefault,
};

export default ThemeService;
