import { Card } from "antd";
import { FunctionComponent } from "react";
import { ProjectTeamMemberUser } from "../../../../../interfaces/ProjectTeam/ProjectTeamMeber";

interface UserCardProps {
    user: ProjectTeamMemberUser;
    classAdditions?: string;
    actions?: JSX.Element;
}

const UserCard: FunctionComponent<UserCardProps> = ({
    user,
    classAdditions,
    actions
}) => {
    return (
        <Card title={user.fullName} className={`user ${classAdditions ?? ''}`} extra={actions}>
            <div className="user-property">
                <label>Email</label>
                <p>{user.email}</p>
            </div>

            <div className="user-property">
                <label>Company</label>
                <p>{user.company}</p>
            </div>

            <div className="user-property">
                <label>Phone</label>
                <p>{user.phoneNumber}</p>
            </div>

            {user.alternativePhoneNumber &&
                <div className="user-property">
                    <label>Alternative Phone</label>
                    <p>{user.alternativePhoneNumber}</p>
                </div>
            }

            <div className="user-property">
                <label>Address</label>
                <p>{user.address}</p>
            </div>
        </Card>
    );
}

export default UserCard;