import { Select } from 'antd';
import { FunctionComponent, useCallback } from 'react';

const { Option } = Select;

interface Options {
    id: number | string;
    name: string;
    icon: string;
}

interface Props {
    label: string;
    placeholder: string;
    options: Options[];
    selected?: Array<any>;
    onSelected: any;
    onDeleted: any;
}

const MultiSelect: FunctionComponent<Props> = (props) => {
    // TODO: Maybe we will need more functionality
    const itemSelected = (value: any) => {
        props.onSelected(value);
    };

    // TODO: Maybe we will need more functionality
    const itemDeSelected = (value: any) => {
        props.onDeleted(value);
    };
    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);
    return (
        <div className="row-flex-clear">
            <label className="col-xs-12-clear">{props.label}</label>
            <Select
                className="col-xs-12-clear"
                showSearch
                mode="multiple"
                style={{ width: 200 }}
                placeholder={props.placeholder}
                optionLabelProp="label"
                showArrow
                defaultValue={props.selected}
                onSelect={itemSelected}
                onDeselect={itemDeSelected}
                filterOption={(input, option) =>
                    option && option.label
                        ? option.label.toLocaleString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                }
                getPopupContainer={handleContainer}
            >
                {props.options.map((option) => (
                    <Option value={option.id} label={option.name} className="d-flex" key={option.id}>
                        <div className="select-checkbox"></div>
                        {option.icon && <img src={option.icon} alt="Option" />}
                        {option.name}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default MultiSelect;
