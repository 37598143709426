import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import WizardWithSidebar from '../../components/shared/WizardWithSidebar';
import { wizardSteps } from '../../constants/wizardSteps';
import { UserDetails } from './components/UserDetails';
import { CompanyDetails } from './components/CompanyDetails';
import { Preferences } from './components/Preferences';

import _ from 'lodash';

interface RouteParams {
    stepName: string;
    id: string;
    edit?: string;
}

// const homepage:string =  `/overview`;
const homepage: string = ``;
export const UserWizard: FunctionComponent<any> = () => {
    const { t } = useTranslation(['f', 'b']),
        params = useParams<RouteParams>(),
        history = useHistory();

    const cancelEverything = () => {
        history.push(homepage);
    };

    const finish = () => {
        history.push(homepage);
    };

    const returnToPreviousPage = () => {
        history.goBack();
    };

    const goToUserDetails = () => {
        setActiveTab(wizardSteps.first);
        history.push(`/user-wizard/userDetails`);
    };

    const goToCompanyDetails = () => {
        setActiveTab(wizardSteps.second);
        history.push(`/user-wizard/companyDetails`);
    };

    const goToPreferences = () => {
        setActiveTab(wizardSteps.third);
        history.push(`/user-wizard/preferences`);
    };

    const tabs = [
        {
            id: wizardSteps.first,
            step: 'userDetails',
            name: t('f:userWizard.userDetails.headerTitle'),
            component: (
                <UserDetails
                    cancelEverything={cancelEverything}
                    returnToPreviousPage={returnToPreviousPage}
                    goToNextPage={goToCompanyDetails}
                />
            ),
        },
        {
            id: wizardSteps.second,
            step: 'companyDetails',
            name: t('f:userWizard.companyDetails.headerTitle'),
            component: (
                <CompanyDetails
                    cancelEverything={cancelEverything}
                    returnToPreviousPage={goToUserDetails}
                    goToNextPage={goToPreferences}
                />
            ),
        },

        {
            id: wizardSteps.third,
            step: 'preferences',
            name: t('f:userWizard.preferences.headerTitle'),
            component: (
                <Preferences
                    cancelEverything={cancelEverything}
                    returnToPreviousPage={goToCompanyDetails}
                    goToNextPage={finish}
                />
            ),
        },
    ];

    const findActiveTab = () => {
        let activeTab: any = _.find(tabs, function (tab) {
            if (tab.step) {
                return tab.step === params.stepName;
            }
        });

        if (activeTab) {
            return activeTab.id.toString();
        }
        return wizardSteps.first;
    };

    const [activeTab, setActiveTab] = useState(findActiveTab());

    return <WizardWithSidebar tabs={tabs} activeTab={activeTab} />;
};
