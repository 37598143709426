import { FunctionComponent, useContext } from 'react';
import FormattedAmount from '../../../components/shared/FormattedAmount';
import TradeProbabilityState from '../../../context/state/TradeProbabilityState';

const TradeProbabilityTable: FunctionComponent = () => {
    const { tradeProbability } = useContext(TradeProbabilityState);
    const data = tradeProbability!;

    return (
        <>
            <section className="module-intro">
                <div className="content-text">
                    <h1 className="trade-probability-title">Property Details</h1>
                    <ul className="list">
                        <li>
                            <p>Address</p><span>{data.address}</span>
                        </li>
                        <li>
                            <p>Property Name</p><span>{data.propertyName}</span>
                        </li>
                        <li>
                            <p>City</p><span>{data.city}</span>
                        </li>
                        <li>
                            <p>Country</p><span>{data.country}</span>
                        </li>
                        <li>
                            <p>Portfolio</p><span>{data.portFolio}</span>
                        </li>
                        <li>
                            <p>Number of Buildings Traded</p><span>TBD</span>
                        </li>
                        <li>
                            <p>Latest Sale Date</p><span>{data.lastSalesDate ? new Date(data.lastSalesDate).toLocaleDateString() : ''}</span>
                        </li>
                        <li>
                            <p>CBD</p><span>{data.cbd}</span>
                        </li>
                        <li>
                            <p>Main Type</p><span>{data.mainType}</span>
                        </li>
                        <li>
                            <p>Year Built</p><span>{data.yearBuilt}</span>
                        </li>
                        <li>
                            <p>Year Renovated</p><span>{data.yearRenuExp}</span>
                        </li>
                        <li>
                            <p>Number of Buildings</p><span>{data.numberBldgs}</span>
                        </li>
                    </ul>
                </div>
                <div className="content-text">
                    <div>
                        <h1 className="trade-probability-title">Transaction Details</h1>
                        <ul className="list">
                            <li>
                                <p>Latest Sales Price (USD)</p>{<FormattedAmount value={data.price} />}
                            </li>
                            <li>
                                <p>Deal Bucket</p><span>{data.dealBucket}</span>
                            </li>
                            <li>
                                <p>Current Owner (s)</p><span>{data.buyer}</span>
                            </li>
                            <li>
                                <p>Cross Border</p><span>{data.crossBorder}</span>
                            </li>
                            <li>
                                <p>Buyer Objective</p><span>{data.buyerObjective}</span>
                            </li>
                            <li>
                                <p>Latest Sales Price (LC)</p><span>TBD</span>
                            </li>
                            <li>
                                <p>Local Currency</p><span>{data.localCurrencyCode}</span>
                            </li>
                            <li>
                                <p>Transaction Type</p><span>{data.transType}</span>
                            </li>
                            <li>
                                <p>Transaction Sub Type</p><span>{data.transSubType}</span>
                            </li>
                            <li>
                                <p>Partial Interest Type</p><span>{data.partialInterestType}</span>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h1 className="trade-probability-title">Trade Probability Statistics</h1>
                        <ul className="list">
                            <li>
                                <p>Prediction Class</p><span>{data.predictionClass}</span>
                            </li>
                            <li>
                                <p>Time Since Last Sale (year)</p><span>{data.timeSinceLastSale}</span>
                            </li>
                            <li>
                                <p>Frequency of Trade (year)</p><span>{data.frequencyOfTrade}</span>
                            </li>
                            <li>
                                <p>Average Hold Time City (year)</p><span>{data.avgHoldTimeCity}</span>
                            </li>
                            <li>
                                <p>Cross Border Hold Time City (year)</p><span>{data.cbbpcaht}</span>
                            </li>
                            <li>
                                <p>Joint Venture</p><span>{data.jointVenture}</span>
                            </li>
                            <li>
                                <p>Average JV Hold Time (year)</p><span>{data.ajvnjvhtc}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TradeProbabilityTable;
