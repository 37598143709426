import { Form, Formik } from "formik";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MainButton from "../../../../../components/shared/MainButton";
import MainTextInput from "../../../../../components/shared/MainTextInput";
import { initUser, InitUserI } from "../../../../../interfaces/investor/InvestorUser";
import InvestorService from "../../../../../services/InvestorsService";
import { AddInvestorsModal } from "../../../../../validations/AddInvestorsModal";

interface AddInvestorFormProps {

    propertyAssetId: number | string;
    refetch?: () => void;
    company: string;
    disableFields: Array<string>;
}

const AddInvestorForm: FunctionComponent<AddInvestorFormProps> = ({
    propertyAssetId,
    refetch,
    company,
    disableFields = []
}) => {
    const { t } = useTranslation(['f', 'b']);
    const initValue = {
        ...initUser,
        companyName: company
    }

    const displayFields = (
        errors: any,
        touched: any,

    ) => {
        return Object.entries(initValue).map(([key]) => {
            return (
                <div className="col-xs-6 form-group" key={key}>
                    <MainTextInput
                        name={key}
                        placeholder={t(`f:property.${key}`)}
                        label={t(`f:listOpportunity.newUser.${key}`)}
                        errors={errors}
                        touched={touched}
                        disabled={disableFields.includes(key)}
                        errorFieldName={t(`f:listOpportunity.newUser.${key}`)}
                    />
                </div>
            );
        });
    };

    const finishHandler = async (values: InitUserI, { resetForm }: any) => {
        try {
            await InvestorService.addInvestor(`${propertyAssetId}`, values);
            resetForm();
            refetch && refetch();
        } catch (error: any) {
            toast('Error occured while adding new investor');
        }
    };

    return (
        <Formik
            initialValues={initValue}
            enableReinitialize
            validationSchema={AddInvestorsModal}
            validateOnMount
            onSubmit={finishHandler}
        >
            {({ values, setFieldValue, resetForm, errors, touched }) => (
                <Form className="company-add-investor-form">
                    <div className="row">{displayFields(errors, touched)}</div>

                    <div className="d-flex justify-content-center mt-20">
                        <MainButton
                            type="submit"
                            buttonText={t('f:button.add')}
                            classAdditions="mxl-30"
                            layout="primary"
                            size="main"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default AddInvestorForm;