import api from '../index';

const createScheduleType = (propertyAssetId: string, data: any) => {
    return api.post(`scheduler/${propertyAssetId}/eventtypes`, data);
};

const fetchScheduleType = (propertyAssetId: string, eventTypeId: string) => {
    return api.get(`scheduler/${propertyAssetId}/eventtypes/${eventTypeId}`);
};

const updateScheduleType = (propertyAssetId: string, eventTypeId: string, data: any) => {
    return api.put(`scheduler/${propertyAssetId}/eventtypes/${eventTypeId}`, data);
};

const fetchAllTeamUsers = (propertyAssetId: string) => {
    return api.get(`/scheduler/${propertyAssetId}/users`);
};

const fetchAllEventTypes = (propertyAssetId: string) => {
    return api.get(`/scheduler/${propertyAssetId}/eventtypes`);
};

const deleteScheduleEvent = (propertyAssetId: string, eventTypeId: string) => {
    return api.delete(`scheduler/${propertyAssetId}/eventtypes/${eventTypeId}`);
};

const makeAppointment = (propertyAssetId: string, body: any) => {
    return api.post(`/scheduler/${propertyAssetId}/events`, body);
};

const fetchAllEvents = (propertyAssetId: string) => {
    return api.get(`/scheduler/${propertyAssetId}/events`);
};

const ScheduleApi = {
    createScheduleType,
    fetchScheduleType,
    fetchAllTeamUsers,
    makeAppointment,
    fetchAllEvents,
    deleteScheduleEvent,
    fetchAllEventTypes,
    updateScheduleType,
};
export default ScheduleApi;
