import WizardWithSidebar from '../../components/shared/WizardWithSidebar';
import { FunctionComponent } from 'react';
import CheckAuthorization from '../StatusPages/CheckAuthorization';

interface AddPropertyDetailsProps {
    component: JSX.Element;
    text: string;
}

export const AddPropertyDetails: FunctionComponent<AddPropertyDetailsProps> = ({ component, text }) => {
    const tabs = [
        {
            id: 1,
            name: text,
            component: component,
        },
    ];

    return (
        <CheckAuthorization>
            <WizardWithSidebar tabs={tabs} />
        </CheckAuthorization>
    );
};
