import api from '../index';

const fetchLocations = (keyword: string, countryCodes: Array<string>, pageIdx: number, itemsPerPage: number) => {
    return api.get(
        `/map/search?keyword=${keyword ?? ''}${
            countryCodes.length ? createParamsOfArray('countryCodes', countryCodes) : ''
        }&pageIdx=${pageIdx}&itemsPerPage=${itemsPerPage}`
    );
};

const reverse = (lat: number, lon: number) => {
    return api.get(`/map/reverse?lat=${lat}&lon=${lon}`);
}

const createParamsOfArray = (type: string, numbers: Array<string>) => {
    let string = `&${type}=`;
    numbers
        .map((element: string, index: number) => {
            string += `${element}`;
        })
        .join(',');
    return string;
};
const MapApi = {
    fetchLocations,
    reverse
};

export default MapApi;
