import { FunctionComponent } from 'react';
import BuildingDirectoryContext from '../../context/BuildingDirectoryContext';
import CheckAuthorization from '../StatusPages/CheckAuthorization';
import BuildingDirectoryTabs from './BuildingDirectoryTabs';
import SearchingMap from './SearchingMap';

const BuildingDirectory: FunctionComponent<any> = () => {
    return (
        <CheckAuthorization>
            <div className="building-directory">
                <SearchingMap>
                    <BuildingDirectoryContext>
                        <BuildingDirectoryTabs />
                    </BuildingDirectoryContext>
                </SearchingMap>
            </div>
        </CheckAuthorization>
    );
};
export default BuildingDirectory;
