import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { MainDataI } from '../../../../interfaces/DataAnalytics/Pages';
import DataAnalyticPageBuilderService from '../../../../services/DataAnalyticPageBuilderService';
import _ from 'lodash';
import { chartTypes } from '../../../../constants/chartTypes';
import ChartPreview from '../../../TemplatesWizard/partials/ChartPreview';
import useBoolean from '../../../../hooks/use-boolean';
import Markdown from 'markdown-to-jsx';
import TreeDotsComponent from '../../../ViewProperty/components/PropertySummary/propertyTemplateComponents/TreeDotsComponent';
import MainLoader from '../../../../components/shared/MainLoader';
import ModalConfirmDelete from '../../../../components/shared/ModalConfirmDelete';

interface SectorsProps {
    id: number;
    pageId: number;
    pageSectionDataId: number;
    pageSectionType: string;
    content: string;
    sortOrder: number;
    editHandler: () => void;
    removeHandler: (value: string) => void;
}

const Sectors: FunctionComponent<SectorsProps> = ({
    content,
    id,
    pageId,
    pageSectionDataId,
    pageSectionType,
    sortOrder,
    editHandler,
    removeHandler,
}: SectorsProps) => {
    const { t } = useTranslation(['f', 'b']),
        [chartType, setChartType] = useState<number>(1),
        [textContent, setTextContent] = useState<string>(''),
        [isThreeDotClicked, isThreeDotClickedActions] = useBoolean(),
        [isModalOpened, isModalOpenedActions] = useBoolean(),
        [mainData, setMainData] = useState<MainDataI | null>(null),
        [isDataLoad, setIsDataLoadActions] = useBoolean();
    const fetchData = useCallback(async () => {
        try {
            const _data = await DataAnalyticPageBuilderService.featchSectionsData(
                `${pageId}`,
                `${id}`,
                `${pageSectionDataId}`
            );
            const obj: any = _.find(chartTypes, { name: _data.dataTypeFormat });
            const data = await JSON.parse(_data.jsonData);

            setMainData({
                source: _data.source,
                lines: data.lines,
                title: data.chartTitle,
                chart: data.chart,
                ID: `${pageId}${id}${pageSectionDataId}`,
            });

            setChartType(obj?.id);
            setTextContent(`${data.title}${data.content}`);

            setIsDataLoadActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t, id, pageId, setIsDataLoadActions, pageSectionDataId]);

    const deleteHandler = useCallback(() => {
        removeHandler(`${id}`);
        isModalOpenedActions.setFalse();
    }, [isModalOpenedActions, id, removeHandler]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="data-analytic__data-section d-flex flex-direction-column">
            <div className="col-xs-12-clear" style={{ zIndex: 5 }}>
                <TreeDotsComponent
                    isThreeDotClicked={isThreeDotClicked}
                    setIsThreeDotClicked={isThreeDotClickedActions.toggle}
                    setIsModalOpened={isModalOpenedActions}
                    isMap={false}
                    templateEditHandler={editHandler}
                />
                <ModalConfirmDelete
                    isModalOpened={isModalOpened}
                    setIsModalOpenedActions={isModalOpenedActions}
                    text={t('f:property.confirmSectionDelete')}
                    handleFinish={deleteHandler}
                />
            </div>
            {isDataLoad ? (
                <div
                    className={`data-analytic__chart-data d-flex ${
                        content === 'right-heading-chart' ? '' : 'flex-direction-reverse'
                    }`}
                >
                    <div className="col-xs-6">
                        <div className="content">
                            <Markdown>{textContent}</Markdown>
                        </div>
                    </div>
                    <div className="col-xs-6 d-flex flex-direction-column align-items-center">
                        <ChartPreview category={chartType} showMainData={true} mainData={mainData} />
                    </div>
                </div>
            ) : (
                <MainLoader type="regular" />
            )}
        </div>
    );
};

export default Sectors;
