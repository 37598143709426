import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import FilterRadioButton from '../../../components/shared/FilterRadioButton';
import MainLoader from '../../../components/shared/MainLoader';
import ModalConfirmDelete from '../../../components/shared/ModalConfirmDelete';
import { DEFAULTTHEME } from '../../../constants/theme';
import ThemeState from '../../../context/state/ThemeState';
import useBoolean from '../../../hooks/use-boolean';
import PreviewContainer from '../partials/PreviewContainer';

const ListOfThemes: FunctionComponent = () => {
    const {
            themeList,
            selectedThemeForSaveAndEdit,
            isEditModeActions,
            isThemeListLoading,
            setSelectedThemeForSaveAndEdit,
            deleteTheme,
        } = useContext(ThemeState),
        [themeId, setThemeId] = useState<string>(''),
        history = useHistory(),
        { t } = useTranslation(['f', 'b']),
        [isConfirmModalOpen, isConfirmModalOpenActions] = useBoolean();

    const handleOpenConfirmModal = useCallback((val: string) => {
        setThemeId(val);
        isConfirmModalOpenActions.setTrue();
    }, []);

    const handleFinishDelete = useCallback(async () => {
        try {
            if (themeId) deleteTheme(themeId);
            isConfirmModalOpenActions.setFalse();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t, deleteTheme, themeId]);

    const handleStartEditmode = useCallback((val: string) => {
        setSelectedThemeForSaveAndEdit(val);
        history.push('/theme#create-or-edit');
        isEditModeActions.setTrue();
    }, []);

    return (
        <div className="col-xs-12-clear px-40">
            {isThemeListLoading ? (
                <>
                    <div className="col-xs-3 d-flex flex-direction-column gap-10">
                        {themeList.map((elem) => (
                            <FilterRadioButton
                                id={elem.id}
                                key={elem.id}
                                current={selectedThemeForSaveAndEdit}
                                onClickHandler={setSelectedThemeForSaveAndEdit}
                                text={elem.name}
                                isRemovable={elem.id === DEFAULTTHEME.id ? false : true}
                                onRemoveClick={handleOpenConfirmModal}
                                isEditable={elem.id === DEFAULTTHEME.id ? false : true}
                                onEditClick={handleStartEditmode}
                            />
                        ))}
                    </div>
                    <PreviewContainer />
                </>
            ) : (
                <MainLoader type="regular" />
            )}
            <ModalConfirmDelete
                isModalOpened={isConfirmModalOpen}
                setIsModalOpenedActions={isConfirmModalOpenActions}
                handleFinish={handleFinishDelete}
                text={t('f:theme-manager.confirmDelete')}
            />
        </div>
    );
};
export default ListOfThemes;
