import { useHistory } from 'react-router';
import { useCallback, useContext, useEffect, useState } from 'react';
import CustomTable from '../../../../../components/shared/CustomTable';
import PropertyAssetPageTemplate from '../../../../../services/PropertyAssetPageTemplate';
import TreeDotsComponent from './TreeDotsComponent';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ModalConfirmDelete from '../../../../../components/shared/ModalConfirmDelete';
import useBoolean from '../../../../../hooks/use-boolean';
import FeatureState from '../../../../../context/state/FeatureState';

const TableTemplate = (props: any) => {
    const history = useHistory(),
        { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { externalId, id: propertyAssetId } = propertySummary,
        [isThreeDotClicked, setIsThreeDotClicked] = useState<boolean>(false),
        [isModalOpened, setIsModalOpenedActions] = useBoolean(false);

    const templateEditHandler = () => {
        history.push(
            `/property/${externalId}/template/${props.pageType}/add-content/${props.template.pageSectionType}/${props.template.id}`
        );
    };

    const [tableData, setTableData] = useState<any>();

    const propertyAsset = useCallback(async () => {
        try {
            const pageInfo = await PropertyAssetPageTemplate.fetchPageInfo(props.pageType, `${propertyAssetId}`);
            setTableData(pageInfo);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [props.pageType, propertyAssetId, t]);

    const deleteHandler = useCallback(() => {
        props.onRemove(props.template.id);
        setIsModalOpenedActions.setFalse();
    }, [setIsModalOpenedActions, props]);

    useEffect(() => {
        propertyAsset();
    }, [propertyAsset]);

    return (
        <div className="main-background property-summary-table__wrap">
            {props.isEditMode && (
                <div className="col-xs-12">
                    <TreeDotsComponent
                        isThreeDotClicked={isThreeDotClicked}
                        setIsThreeDotClicked={setIsThreeDotClicked}
                        setIsModalOpened={setIsModalOpenedActions}
                        templateEditHandler={templateEditHandler}
                    />
                    <ModalConfirmDelete
                        text={t('f:property.confirmSectionDelete')}
                        isModalOpened={isModalOpened}
                        setIsModalOpenedActions={setIsModalOpenedActions}
                        handleFinish={deleteHandler}
                    />
                </div>
            )}
            <div className="pb-20">
                <CustomTable tableData={tableData} centered="m-auto" />
            </div>
        </div>
    );
};

export default TableTemplate;
