import Economic from '../pages/ExploreData/Economic';
import Overview from '../pages/ExploreData/Overview';
import MarketInsight from '../pages/ExploreData/Market Insight';
import Spotlight from '../pages/ExploreData/Spotlight';
import Transaction from '../pages/ExploreData/Transaction';
import PowerBiReport from '../pages/ExploreData/Power-Bi';

export const defaultTabs = [
    {
        id: 0,
        icon: '',
        name: 'Overview',
        component: <Overview />,
    },
    {
        id: 0,
        icon: '',
        name: 'Economic',
        component: <Economic />,
    },
    {
        id: 0,
        icon: '',
        name: 'Market Insight',
        component: <MarketInsight />,
    },
    {
        id: 0,
        icon: '',
        name: 'Transaction',
        component: <Transaction />,
    },
    {
        id: 0,
        icon: '',
        name: 'Spotlight',
        component: <Spotlight />,
    },
    {
        id: 0,
        icon: '',
        name: 'PowerI',
        component: <PowerBiReport />,
    },
];
