import * as Yup from 'yup';

export const AddQuestionSchema= Yup.object().shape({
    question: Yup.string()
    .min(1, 'Too Short!')
    .max(255, 'Too Long!')
    .required('f:validation.required'),
    answer: Yup.string()
    .min(1, 'Too Short!')
    .max(255, 'Too Long!')
    .required('f:validation.required'),
    
})     