import FileUploadApi from '../api/routes/FileUploadApi';

const uploadFile = async (options: any, data: any) => {
    try {
        let response = await FileUploadApi.uploadFile(options, data);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};
const FileUploadService = {
    uploadFile,
};

export default FileUploadService;
