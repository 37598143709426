import { FunctionComponent, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { AddInvestorsModal } from '../../../../../validations/AddInvestorsModal';
import ModalCloseButton from '../../../../../components/shared/ModalCloseButton';
import { InitUserI, initUser } from '../../../../../interfaces/investor/InvestorUser';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../../../components/shared/MainButton';
import { Actions } from '../../../../../interfaces/general/general';
import MainTextInput from '../../../../../components/shared/MainTextInput';
import InvestorService from '../../../../../services/InvestorsService';
import { toast } from 'react-toastify';
import ModalWrapper from '../../../../../components/wrapper/ModalWrapper';

interface AddInvestorModalProps {
    isModalOpened: boolean;
    setIsModalOpened: Actions;
    propertyAssetId: string;
    refetch: () => void;
}

const AddInvestorModal: FunctionComponent<AddInvestorModalProps> = ({
    isModalOpened,
    setIsModalOpened,
    propertyAssetId,
    refetch,
}: AddInvestorModalProps) => {
    const { t } = useTranslation(['f', 'b']);

    const onCancelHanlder = useCallback(() => {
        setIsModalOpened.setFalse();
    }, [setIsModalOpened]);

    const displayFields = (
        values: InitUserI,
        errors: any,
        touched: any,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    ) => {
        return Object.entries(initUser).map(([key]) => {
            return (
                <div className="col-xs-6 form-group" key={key}>
                    <MainTextInput
                        name={key}
                        placeholder={t(`f:property.${key}`)}
                        label={t(`f:listOpportunity.newUser.${key}`)}
                        errors={errors}
                        touched={touched}
                        errorFieldName={t(`f:listOpportunity.newUser.${key}`)}
                    />
                </div>
            );
        });
    };

    const finishHandler = async (values, { resetForm }) => {
        try {
            await InvestorService.addInvestor(propertyAssetId, values);
            resetForm();
            onCancelHanlder();
            refetch();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    return (
        <ModalWrapper
            isModalOpened={isModalOpened}
            isFooterShown={false}
            className="cf-modal"
            isCloseModalShown={true}
            closeModalHandler={onCancelHanlder}
            title={t(`f:button.addInvestor`)}
        >
            <Formik
                initialValues={initUser}
                enableReinitialize
                validationSchema={AddInvestorsModal}
                validateOnMount
                onSubmit={finishHandler}
            >
                {({ values, setFieldValue, resetForm, errors, touched }) => (
                    <Form>
                        <ModalCloseButton handler={onCancelHanlder} />

                        <div className="row">{displayFields(values, errors, touched, setFieldValue)}</div>

                        <div className="d-flex justify-content-center mt-20">
                            <MainButton
                                type="reset"
                                buttonText={t('f:button.cancel')}
                                layout="secondary"
                                buttonHandler={onCancelHanlder}
                                size="main"
                            />
                            <MainButton
                                type="submit"
                                buttonText={t('f:button.add')}
                                classAdditions="mxl-30"
                                layout="primary"
                                size="main"
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalWrapper>
    );
};

export default AddInvestorModal;
