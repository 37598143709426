import { Col, Row, Table } from "antd";
import { FunctionComponent } from "react";
import HighlightCountryMap from "../../../../../components/shared/HighlightCountryMap";
import { ICountryAccessProperty } from "../../../../../interfaces/googleAnalytics/CountryAccessProperty";

interface ITopCountriesChartProps {
    dataSource: Array<ICountryAccessProperty>;
}

const TopCountriesChart: FunctionComponent<ITopCountriesChartProps> = ({
    dataSource = []
}) => {
    const columns = [
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: 'Number of Access',
            dataIndex: 'numOfAccess',
            key: 'numOfAccess',
        },
    ];

    const mapSeriesData = dataSource.map(item => ({
        id: item.countryCode,
        name: item.country,
        value: item.numOfAccess,
        latitude: item.latitude,
        longitude: item.longitude
    }));

    return (
        <div className='mt-50'>
            <p className='heading ml-20'>Users over country</p>
            <Row>
                <Col span={8}>
                    <Table columns={columns} dataSource={dataSource} pagination={false} />
                </Col>
                <Col span={16}>
                    <HighlightCountryMap data={mapSeriesData} />
                </Col>
            </Row>
        </div>
    );
};

export default TopCountriesChart;