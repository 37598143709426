export enum PropertyAssetFeatures {
    PageSummary = 10,
    PageSummaryInformationMemorandum = 13,
    PageSummaryPropertySummary = 14,
    CashFlow = 20,
    CashFlowStackingPlan = 22,
    CashFlowAnalysis = 23,
    DataRoom = 30,
    Transaction = 50,
    Research = 60,
    ResearchAnalysis = 62,
    ProjectTeam = 70,
    Faq = 80,
    Permission = 90
}