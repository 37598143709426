import { FunctionComponent, MouseEventHandler } from 'react';

interface ModalCloseButtonProps {
    handler: MouseEventHandler<HTMLSpanElement>;
}

const ModalCloseButton: FunctionComponent<ModalCloseButtonProps> = ({ handler }: ModalCloseButtonProps) => (
    <span className="cf-close d-block" onClick={handler}>
        +
    </span>
);
export default ModalCloseButton;
