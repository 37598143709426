import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import LOWizardState from './state/LOWizardState';
import ListOpportunityService from '../services/ListOpportunityService';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useBoolean from '../hooks/use-boolean';
import {
    IBidingStructure,
    IUsersApproval,
    initalListingType,
    IListingType,
    IOverviewLO,
    CreateListOpportunityRequest,
} from '../interfaces/listOpporunity/ListOpportunity';
import { LoWizardStepNames } from '../constants/wizardSteps';
import MainLoader from '../components/shared/MainLoader';
import FeatureState from './state/FeatureState';

interface LOWizardContextProps {
    children: React.ReactNode;
}

const LOWizardContext: FunctionComponent<LOWizardContextProps> = ({ children }: LOWizardContextProps) => {
    const { t } = useTranslation(['f']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        [ndaTypes, setNdaTypes] = useState<Object>({}),
        [approvaltype, setApprovaltype] = useState<Object>({}),
        history = useHistory(),
        [isListingTypeLoaded, isListingTypeLoadedActions] = useBoolean(),
        [isSendInvitations, IsSendInvitationsActions] = useBoolean(),
        [isAgreePayment, isAgreePaymentActions] = useBoolean(),
        [isNdasTypesLoaded, isNdasTypesLoadedActions] = useBoolean(),
        [isAllUsersSelected, isAllUsersSelectedActions] = useBoolean(),
        [isStepChanged, setIsStepChangedActions] = useBoolean(),
        [usersWhoCanApprove, setUsersWhoCanApprove] = useState<Array<IUsersApproval>>([]),
        [isListOfUssersWhoCanApproveLoad, isListOfUssersWhoCanApproveLoadActions] = useBoolean(),
        [bidingStructure, setBidingStructure] = useState<IBidingStructure | null>(null),
        [overviewLO, setOverviewLO] = useState<IOverviewLO | null>(null),
        /*Its gona bee strings but for now emails */
        [selectedUsersId, setSelectedUsersId] = useState<Array<string>>([]),
        [isRequired, setIsRequiredActions] = useBoolean(),
        [isListOpportunityWizardFinished, isListOpportunityWizardFinishedActions] = useBoolean(),
        [currentListingType, setCurrentListingType] = useState<IListingType>(initalListingType);

    const fetchListingTypeByPopperty = useCallback(async () => {
        try {
            const listType = await ListOpportunityService.fetchListingTypeByPoppertyId(`${propertyAssetId}`);
            setCurrentListingType(listType);
            isListingTypeLoadedActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const fetchNdaTypes = useCallback(async () => {
        try {
            const nda = await ListOpportunityService.fetchNdaType();
            setNdaTypes(nda);
            isNdasTypesLoadedActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t]);

    const fetchApprovaltype = useCallback(async () => {
        try {
            const approvaltypes = await ListOpportunityService.fetchApprovaltype();
            setApprovaltype(approvaltypes);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [t]);

    const fetchBidingStructure = useCallback(async () => {
        try {
            const bidingStructure = await ListOpportunityService.fetchBidingStructure(`${propertyAssetId}`);
            setBidingStructure(bidingStructure);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const handleIsRequiredSwitch = useCallback(() => {
        setBidingStructure(bidingStructure ? { ...bidingStructure, ndaType: 1 } : null);
        setIsRequiredActions.setTrue();
    }, [setIsRequiredActions, bidingStructure]);

    const changeDestination = useCallback(
        (dest: string) => {
            setIsStepChangedActions.setFalse();
            history.push(`/property/${externalId}/list-opportunity/${dest}`);
        },
        [history, externalId, setIsStepChangedActions]
    );

    const handleBidingStructurAndNextStep = useCallback(async () => {
        try {
            if (bidingStructure?.ndaType === 2) {
                if (!bidingStructure?.customNdaUrl) {
                    toast.error(t(`f:listOpportunity.uploadCustomNda`));
                    return;
                }
            }

            changeDestination(LoWizardStepNames.DEAL_TIMELINE_AND_INVESTORS);
            await ListOpportunityService.createBidingStructure(`${propertyAssetId}`, bidingStructure);
            await ListOpportunityService.createListingType(`${propertyAssetId}`, currentListingType);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t, bidingStructure, changeDestination, currentListingType]);

    const fetchApprovals = useCallback(async () => {
        try {
            const users = await ListOpportunityService.fetchApprovals(`${propertyAssetId}`);
            setUsersWhoCanApprove(users);
            isListOfUssersWhoCanApproveLoadActions.setTrue();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const fetchOverview = useCallback(async () => {
        try {
            const overview = await ListOpportunityService.fetchOverviews(`${propertyAssetId}`);
            setOverviewLO(overview);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    const finishLOWizard = useCallback(async () => {
        try {
            isListOpportunityWizardFinishedActions.setTrue();
            const data: CreateListOpportunityRequest = {
                investorEmails: isSendInvitations ? selectedUsersId : [],
            };

            await ListOpportunityService.createListOpportunityFinish(`${propertyAssetId}`, data);

            history.push(`/property/${externalId}`);
            isListOpportunityWizardFinishedActions.setFalse();
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
            isListOpportunityWizardFinishedActions.setFalse();
        }
    }, [externalId, isSendInvitations, selectedUsersId, history, t]);

    const goToApprovalsOrFinish = useCallback(() => {
        usersWhoCanApprove.length ? changeDestination(LoWizardStepNames.APPROVALS) : finishLOWizard();
    }, [usersWhoCanApprove.length, changeDestination, finishLOWizard]);

    const sendEmail = useCallback(
        async (userId: string) => {
            try {
                await ListOpportunityService.sendMailToUserWhoCanApprove(`${propertyAssetId}`, userId);
                fetchApprovals();
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
        },
        [t, propertyAssetId, fetchApprovals]
    );

    useEffect(() => {
        fetchOverview();
    }, [fetchOverview]);

    useEffect(() => {
        fetchListingTypeByPopperty();
    }, [fetchListingTypeByPopperty]);

    useEffect(() => {
        fetchNdaTypes();
    }, [fetchNdaTypes]);

    useEffect(() => {
        fetchBidingStructure();
    }, [fetchBidingStructure]);

    useEffect(() => {
        fetchApprovals();
    }, [fetchApprovals]);

    useEffect(() => {
        fetchApprovaltype();
    }, [fetchApprovaltype]);

    useEffect(() => {
        if (bidingStructure)
            bidingStructure.ndaType === 0 ? setIsRequiredActions.setFalse() : setIsRequiredActions.setTrue();
    }, [bidingStructure, bidingStructure?.ndaType, setIsRequiredActions]);

    return (
        <>
            {isListOfUssersWhoCanApproveLoad ? (
                <LOWizardState.Provider
                    value={{
                        bidingStructure,
                        isNdasTypesLoaded,
                        ndaTypes,
                        isListingTypeLoaded,
                        currentListingType,
                        setCurrentListingType,
                        setBidingStructure,
                        isRequired,
                        setIsRequiredActions,
                        handleIsRequiredSwitch,
                        handleBidingStructurAndNextStep,
                        changeDestination,
                        usersWhoCanApprove,
                        overviewLO,
                        goToApprovalsOrFinish,
                        isStepChanged,
                        setIsStepChangedActions,
                        sendEmail,
                        approvaltype,
                        isAgreePayment,
                        isAgreePaymentActions,

                        isSendInvitations,
                        IsSendInvitationsActions,
                        selectedUsersId,
                        setSelectedUsersId,
                        isAllUsersSelected,
                        isAllUsersSelectedActions,
                        isListOpportunityWizardFinished,
                    }}
                >
                    {children}
                </LOWizardState.Provider>
            ) : (
                <MainLoader type="main" />
            )}
        </>
    );
};

export default LOWizardContext;
