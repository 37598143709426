import React from 'react';
import { DEFAULTTHEME, THEME_COLORS } from '../../constants/theme';
import { Actions } from '../../interfaces/general/general';
import { FormikTheme, MainTheme, MainThemeListSingleItem, ThemeValueContent } from '../../interfaces/theme';
const action = {
    setTrue: () => {},
    setFalse: () => {},
    toggle: (value?: boolean | undefined) => {},
};

export interface ThemeStateType {
    currentTheme: MainTheme;
    setCurrentTheme: (val: MainTheme) => void;
    previewTheme: ThemeValueContent;
    createOrUpdateNewTheme: (val: FormikTheme) => void;
    selectedThemeForSaveAndEdit: string;
    setSelectedThemeForSaveAndEdit: (prev: string) => void;
    setMainThemeId: (prev: string) => void;
    mainThemeId: string;
    publishTheme: (prev: string) => void;
    deleteTheme: (id: string) => void;
    themeList: Array<MainThemeListSingleItem>;
    isThemeListLoading: boolean;
    isThemeListLoadingActions: Actions;
    isEditMode: boolean;
    isEditModeActions: Actions;
    fetchActivTheme: () => void;
    companyIdToFilterBy: string;
    setCompanyIdToFilterBy: (prev: string) => void;
    fetchPropertyTheme: (id: string) => void;
}

const initValues = {
    currentTheme: DEFAULTTHEME,
    previewTheme: {
        colors: THEME_COLORS,
        logo: DEFAULTTHEME.logo,
        lightLogo: DEFAULTTHEME.lightLogo,
        loader: DEFAULTTHEME.loader,
    },
    createOrUpdateNewTheme: (val: FormikTheme) => {},
    selectedThemeForSaveAndEdit: DEFAULTTHEME.id,
    setSelectedThemeForSaveAndEdit: (prev: string) => {},
    setMainThemeId: (prev: string) => {},
    mainThemeId: DEFAULTTHEME.id,
    publishTheme: (val: string) => {},
    deleteTheme: (id: string) => {},
    themeList: [],
    isThemeListLoading: false,
    isThemeListLoadingActions: action,
    isEditMode: false,
    isEditModeActions: action,
    fetchActivTheme: () => {},
    setCurrentTheme: (val: MainTheme) => {},
    companyIdToFilterBy: '',
    setCompanyIdToFilterBy: (prev: string) => {},
    fetchPropertyTheme: (id: string) => {},
};
export default React.createContext<ThemeStateType>(initValues);
