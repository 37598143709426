import { Formik, Field, Form, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import TemplateContextState from '../../../context/state/TemplateContextState';
import { useContext } from 'react';
import { ValidateBarChartSchema } from '../../../validations/ValidateBarChartSchema';
import { FormikErrorArrayDeep, FormikChartArrayError, FormikErrorTwoArrayDeep } from './FormikError';
import { BarChartInterfaceShort, BarInitInterface } from '../../../interfaces/chart/ChartInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import varibales from '../../../assets/styles/general/_variable.module.scss';

const initCategory: Array<string> = ['', '', '', '', ''];
const initBars: Array<BarChartInterfaceShort> = [
    {
        name: '',
        lineValues: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
    },
    {
        name: '',
        lineValues: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
    },
    {
        name: '',
        lineValues: [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }],
    },
];
const initialValues: BarInitInterface = {
    category: initCategory,
    bars: initBars,
};

const BarChartForm = () => {
    const { t } = useTranslation(['f', 'b']),
        { handleBarFinish, isForEdit, setChartDataActions, chartData, setIsPreviewShow } =
            useContext(TemplateContextState);

    const pieData: any = chartData;
    return (
        <Formik
            initialValues={isForEdit ? pieData : initialValues}
            validationSchema={ValidateBarChartSchema}
            onSubmit={(values) => {
                handleBarFinish(values);
                setChartDataActions.setTrue();
            }}
            render={({ values, errors, touched, handleReset, setFieldValue }) => {
                return (
                    <Form>
                        <div className="chart__overflow col-xs-12-clear">
                            <table className="chart-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <p className="mb-10 mt-10">{t('f:chart.table.barChart')} </p>
                                        </td>
                                    </tr>
                                    <FieldArray
                                        name="dates"
                                        render={({ insert, remove, push }) => (
                                            <tr>
                                                <td>
                                                    <label htmlFor="" className="col-xs-12">
                                                        {t('f:chart.table.category')}
                                                    </label>
                                                </td>
                                                {values.category.length > 0 &&
                                                    values.category.map((friend, index) => (
                                                        <td>
                                                            <label htmlFor="" className="col-xs-12"></label>
                                                            <div className="mb-20 position-relative">
                                                                <Field
                                                                    className="form-control mb-25"
                                                                    name={`category.${index}`}
                                                                    placeholder={t('f:chart.table.enterValue')}
                                                                />
                                                                <FormikChartArrayError
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    index={index}
                                                                    array="category"
                                                                />
                                                            </div>
                                                        </td>
                                                    ))}
                                                <td className="d-flex flex-direction-column">
                                                    <FontAwesomeIcon
                                                        className="mt-15 mr-20 ml-20"
                                                        icon={faPlus}
                                                        color={varibales.green}
                                                        onClick={() => {
                                                            const new_category = values.category;
                                                            new_category.push('');
                                                            if (values.bars.length) {
                                                                const new_bars = [...values.bars];
                                                                new_bars.map((elem) => {
                                                                    elem.lineValues.push({ value: 0 });
                                                                });
                                                                setFieldValue('bars', new_bars);
                                                            }

                                                            setFieldValue('category', new_category);
                                                        }}
                                                    />

                                                    <FontAwesomeIcon
                                                        className="mr-20 ml-20"
                                                        icon={faMinus}
                                                        color={varibales.red}
                                                        onClick={() => {
                                                            if (values.category.length === 1) return;
                                                            const new_category = [...values.category];
                                                            new_category.pop();
                                                            setFieldValue('category', new_category);
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    />
                                    <tr>
                                        <td>
                                            <label htmlFor="" className="col-xs-12">
                                                {t('f:chart.table.lineName')}
                                            </label>
                                        </td>

                                        {values.category.length > 0 &&
                                            values.category.map((friend, index) => (
                                                <td>
                                                    <label htmlFor="" className="col-xs-12">
                                                        {t('f:chart.table.value')}
                                                    </label>
                                                </td>
                                            ))}
                                    </tr>
                                    <FieldArray
                                        name="lines"
                                        render={({ insert, remove, push }) => (
                                            <>
                                                {values.bars.length > 0 &&
                                                    values.bars.map((friend, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="mb-20 position-relative">
                                                                    <Field
                                                                        className="form-control mb-25"
                                                                        name={`bars.${index}.name`}
                                                                        placeholder={t('f:chart.table.enterValue')}
                                                                        type="name"
                                                                    />
                                                                    <FormikErrorArrayDeep
                                                                        errors={errors}
                                                                        touched={touched}
                                                                        index={index}
                                                                        array="bars"
                                                                        name="name"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <FieldArray
                                                                name="dots"
                                                                render={({ insert, remove, push }) => (
                                                                    <>
                                                                        {[...values.category].length > 0 &&
                                                                            [...values.category].map((friend, ind) => (
                                                                                <td>
                                                                                    <div className="mb-20 position-relative">
                                                                                        <Field
                                                                                            className="form-control mb-25"
                                                                                            style={{ width: '100%' }}
                                                                                            name={`bars.${index}.lineValues.${ind}.value`}
                                                                                            placeholder={t(
                                                                                                'f:chart.table.enterValue'
                                                                                            )}
                                                                                            type="value"
                                                                                        />
                                                                                        <FormikErrorTwoArrayDeep
                                                                                            errors={errors}
                                                                                            touched={touched}
                                                                                            index={index}
                                                                                            array="bars"
                                                                                            subArray="lineValues"
                                                                                            subArrayIndex={ind}
                                                                                            name="value"
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                            ))}
                                                                    </>
                                                                )}
                                                            />
                                                            <td>
                                                                <div className="mb-20 position-relative">
                                                                    <FontAwesomeIcon
                                                                        className=" mr-20 ml-20"
                                                                        icon={faMinus}
                                                                        color={varibales.red}
                                                                        onClick={() => {
                                                                            if (values.bars.length === 1) return;
                                                                            const new_bars = values.bars.filter(
                                                                                (elem, currentIndex) => {
                                                                                    if (currentIndex !== index)
                                                                                        return elem;
                                                                                }
                                                                            );

                                                                            setFieldValue('bars', new_bars);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                <tr>
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        color={varibales.green}
                                                        className="mt-10 mb-10 ml-20"
                                                        onClick={() => {
                                                            const ar = [...values.bars];
                                                            ar.push({
                                                                name: '',
                                                                lineValues: [],
                                                            });

                                                            setFieldValue('bars', ar);
                                                        }}
                                                    />
                                                </tr>
                                            </>
                                        )}
                                    />
                                </tbody>
                            </table>
                        </div>
                        <div className="col-xs-12-clear mt-10 d-flex">
                            <button
                                className="button-primary button-small mr-20"
                                type="button"
                                onClick={() => {
                                    setFieldValue('category', initCategory);
                                    setFieldValue('bars', initBars);
                                }}
                            >
                                {t('f:button.reset')}
                            </button>
                            <button
                                className="button-primary button-small mr-20"
                                onClick={() => {
                                    setIsPreviewShow(true);
                                    setChartDataActions.setTrue();
                                    handleBarFinish(values);
                                }}
                            >
                                {t('f:button.preview')}
                            </button>
                            <button className="button-primary button-small" type="submit">
                                {t('f:button.save')}
                            </button>
                        </div>
                    </Form>
                );
            }}
        />
    );
};
export default BarChartForm;
