import { Formik, Field, Form, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import varibales from '../../../assets/styles/general/_variable.module.scss';
import { FormikErrorArrayDeep, FormikChartFieldError } from './FormikError';
import TemplateContextState from '../../../context/state/TemplateContextState';
import { useContext } from 'react';
import { validatePieChartSchema } from '../../../validations/ValidatePieChartSchema';
import { PieChartInterface, PieInitInterface } from '../../../interfaces/chart/ChartInterface';

const initSectors: Array<PieChartInterface> = [
    {
        sector: '',
        size: 0,
    },
    {
        sector: '',
        size: 0,
    },
    {
        sector: '',
        size: 0,
    },
    {
        sector: '',
        size: 0,
    },
    {
        sector: '',
        size: 0,
    },
];
const initialValues: PieInitInterface = {
    title: '',
    sectors: initSectors,
};

const PieChartForm = () => {
    const { t } = useTranslation(['f', 'b']),
        {
            isForEdit,
            chartData,
            setChartDataActions,
            setNewChartTitle,
            handlePieFinish,
            chartTitle,
            setChartTitle,
            setIsPreviewShow,
        } = useContext(TemplateContextState);
    const pieData: any = chartData;
    const dataToEdit: any = { title: chartTitle, sectors: [...pieData] };

    return (
        <Formik
            initialValues={isForEdit ? dataToEdit : initialValues}
            validationSchema={validatePieChartSchema}
            enableReinitialize
            onSubmit={(values) => {
                handlePieFinish(values.sectors);
                setChartDataActions.setTrue();
            }}
            render={({ values, errors, touched, handleReset, setFieldValue }) => {
                return (
                    <Form>
                        <div className="chart__overflow col-xs-12-clear">
                            <table className="chart-table">
                                <tbody>
                                    <tr>
                                        <div className="mb-20 position-relative">
                                            <td>
                                                <p className="mb-10 mt-10">{t('f:chart.table.title')}</p>
                                            </td>
                                            <td>
                                                <Field
                                                    className="form-control mb-10 mt-10"
                                                    name={`title`}
                                                    placeholder="Enter Title"
                                                    type="text"
                                                    onChange={async (e: React.FormEvent<HTMLInputElement>) => {
                                                        setFieldValue('title', e.currentTarget.value);
                                                        setChartTitle(e.currentTarget.value);
                                                        setNewChartTitle(e.currentTarget.value);
                                                    }}
                                                />
                                                <FormikChartFieldError errors={errors} touched={touched} name="title" />
                                            </td>
                                        </div>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p className="mb-10 mt-10">{t('f:chart.table.name')}</p>
                                        </td>
                                        <td>
                                            <p className="mb-10 mt-10">{t('f:chart.table.value')}</p>
                                        </td>
                                    </tr>
                                    <FieldArray
                                        name="sectors"
                                        render={({ remove, push }) => (
                                            <>
                                                {values.sectors.length > 0 &&
                                                    values.sectors.map((friend, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="mb-20 position-relative">
                                                                    <Field
                                                                        className="form-control"
                                                                        name={`sectors.${index}.sector`}
                                                                        placeholder={t('f:chart.table.enterName')}
                                                                        type="text"
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                    <FormikErrorArrayDeep
                                                                        errors={errors}
                                                                        touched={touched}
                                                                        index={index}
                                                                        name="sector"
                                                                        array="sectors"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="mb-20 position-relative">
                                                                    <Field
                                                                        className="form-control  mb-25"
                                                                        name={`sectors.${index}.size`}
                                                                        placeholder={t('f:chart.table.enterValue')}
                                                                        type="size"
                                                                    />
                                                                    <FormikErrorArrayDeep
                                                                        errors={errors}
                                                                        touched={touched}
                                                                        index={index}
                                                                        array="sectors"
                                                                        name="size"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="mb-20 position-relative">
                                                                    <FontAwesomeIcon
                                                                        className="mr-20 ml-20"
                                                                        icon={faMinus}
                                                                        color={varibales.red}
                                                                        onClick={() => {
                                                                            if (values.sectors.length === 1) return;
                                                                            const new_sectors = values.sectors.filter(
                                                                                (elem, currentIndex) => {
                                                                                    if (currentIndex !== index)
                                                                                        return elem;
                                                                                }
                                                                            );

                                                                            setFieldValue('sectors', new_sectors);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                <tr>
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        color={varibales.green}
                                                        className="mt-10 mb-10 ml-20"
                                                        onClick={() =>
                                                            push({
                                                                sector: '',
                                                                size: 0,
                                                            })
                                                        }
                                                    />
                                                </tr>
                                            </>
                                        )}
                                    />
                                </tbody>
                            </table>
                        </div>
                        <div className="col-xs-12-clear mt-10 d-flex">
                            <button
                                className="button-primary button-small mr-20"
                                type="button"
                                onClick={() => {
                                    setFieldValue('title', '');
                                    setFieldValue('sectors', initSectors);
                                }}
                            >
                                {t('f:button.reset')}
                            </button>
                            <button
                                className="button-primary button-small mr-20"
                                onClick={() => {
                                    setIsPreviewShow(true);
                                    handlePieFinish(values.sectors);
                                    setChartDataActions.setTrue();
                                }}
                            >
                                {t('f:button.preview')}
                            </button>
                            <button className="button-primary button-small" type="submit">
                                {t('f:button.save')}
                            </button>
                        </div>
                    </Form>
                );
            }}
        />
    );
};
export default PieChartForm;
