import api from '../index';
import { defaultTransformers } from '../index';
import PropertyLookupTransformer from '../transformers/PropertyLookupTransformer';

const getAllLookups = () => {
    return api.get('/Lookup');
};

const getLookup = (lookup: string) => {
    return api.get(`/Lookup/${lookup}`, {
        transformResponse: [...defaultTransformers(), PropertyLookupTransformer.transform],
    });
};

const PropertyLookupApi = { getAllLookups, getLookup };
export default PropertyLookupApi;
