import { faTimes, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, useCallback } from 'react';
import varibales from '../../assets/styles/general/_variable.module.scss';

interface FilterRadioButtonProps {
    text: string;
    id: string;
    current: string;
    onClickHandler: (val: string) => void;
    isRemovable?: boolean;
    onRemoveClick?: (val: string) => void;
    isEditable?: boolean;
    onEditClick?: (val: string) => void;
}

const FilterRadioButton: FunctionComponent<FilterRadioButtonProps> = ({
    text,
    id,
    current,
    onClickHandler,
    isRemovable,
    onRemoveClick,
    isEditable,
    onEditClick,
}: FilterRadioButtonProps) => {
    const handleClick = useCallback(() => {
        onClickHandler(id);
    }, [id, onClickHandler]);

    const handleRemove = useCallback(() => {
        if (onRemoveClick) onRemoveClick(id);
    }, [id, onRemoveClick]);

    const handleEdit = useCallback(() => {
        if (onEditClick) onEditClick(id);
    }, [id, onEditClick]);

    return (
        <div className="d-flex gap-10 align-items-center">
            <p
                className={`d-flex justify-content-center align-items-center ${
                    id === current ? 'selected' : 'unselected'
                }`}
                onClick={handleClick}
            >
                {text}
            </p>
            <>
                {isRemovable && (
                    <FontAwesomeIcon className="pointer" icon={faTimes} color={varibales.red} onClick={handleRemove} />
                )}
                {isEditable && (
                    <FontAwesomeIcon className="pointer" icon={faPen} color={varibales.green} onClick={handleEdit} />
                )}
            </>
        </div>
    );
};

export default FilterRadioButton;
