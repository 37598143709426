import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ActiveUsersInDaysChart from '../../../components/shared/ActiveUserInDaysChart';
import { IActiveUsersInDays } from "../../../interfaces/googleAnalytics/ActiveUsersInDays";
import GoogleAnalyticsService from "../../../services/GoogleAnalyticsService";

const AppActiveUsersInDaysChart: FunctionComponent<any> = () => {
    const id = "activeUsersInDaysChart";
    const [activeUsersInDaysData, setData] = useState<Array<IActiveUsersInDays>>([]),
        { t } = useTranslation(['f', 'b']);

    const fetchData = useCallback(async () => {
        try {
            const data = await GoogleAnalyticsService.fetchActiveUsersInDays();
            setData(data);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return <ActiveUsersInDaysChart data={activeUsersInDaysData} />
}

export default AppActiveUsersInDaysChart;