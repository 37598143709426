import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ExecuteNdaService from '../../services/ExecuteNdaService';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import FeatureState from '../../context/state/FeatureState';

const DocPreview: FunctionComponent<any> = (props) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { externalId, id: propertyAssetId } = propertySummary,
        history = useHistory(),
        [docuSignUrl, setDocuSignUrl] = useState<string>('');

    const fetchDocuSign = useCallback(async () => {
        try {
            const src = await ExecuteNdaService.fetchDocuSign(`${propertyAssetId}`);
            setDocuSignUrl(src);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
            history.push(`/property/${externalId}`);
        }
    }, [propertyAssetId, t, externalId, history]);

    useEffect(() => {
        fetchDocuSign();
    }, [fetchDocuSign]);

    return (
        <div className="docu-preview">
            <iframe src={docuSignUrl} className="signNDAModal__frame mt-80" title="DocuSign"></iframe>
        </div>
    );
};
export default DocPreview;
