import api from '../index';

const fetchPropertyBiddings = (propertyAssetId: string) => {
    return api.get(`bidding/${propertyAssetId}`);
};

const approveBid = (propertyAssetId: string, biddingId: any) => {
    return api.put(`bidding/${propertyAssetId}/${biddingId}/sellerapprove`);
};

const rejectBid = (propertyAssetId: string, biddingId: any) => {
    return api.put(`bidding/${propertyAssetId}/${biddingId}/sellerreject`);
};

const fetchApprovals = (propertyAssetId: string, biddingId: any) => {
    return api.get(`/bidding/${propertyAssetId}/${biddingId}/approvals`);
};

const fetchMyPropertyBiddings = (propertyAssetId: string) => {
    return api.get(`/bidding/${propertyAssetId}/mybiddings`);
};

const BiddingApi = {
    fetchPropertyBiddings,
    approveBid,
    fetchApprovals,
    fetchMyPropertyBiddings,
    rejectBid,
};
export default BiddingApi;
