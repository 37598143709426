import React from 'react';
import BuildingDirectory from '../../pages/BuildingDirectory/models/buildingDirectory';

export type BuildingDirectoryStateType = {
    fetchBuildingDirectory: (_: string) => Promise<void> | undefined;
    setBuildingDirectory: (_: any) => void;
    buildingDirectory: BuildingDirectory | undefined;
    isLoading: boolean;
};

export default React.createContext<BuildingDirectoryStateType>({
    fetchBuildingDirectory: (_: string) => undefined,
    setBuildingDirectory: (_: any) => undefined,
    buildingDirectory: undefined,
    isLoading: false,
});
