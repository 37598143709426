import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faHandshake, faBriefcase, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import InfoCard from './components/InfoCard';
import OverviewView from './components/OverviewView';
import { OverviewPropertyAssetSearch } from '../../interfaces/property/OverviewPropertyAssetSearch';
import PropertyService from '../../services/Property';
import { toast } from 'react-toastify';
import useBoolean from '../../hooks/use-boolean';
import { OverviewTopCards } from '../../interfaces/property/OverviewPropertyAssetSearch';
import PropertyAsserts from '../../interfaces/property/PropertyAsserts';
import { OverviewSummary, defaultOverviewSummary } from '../../interfaces/property/OverviewSummary';

const Overview: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        [overviewType, setOverviewType] = useState<number>(OverviewTopCards.PORTFOLIO),
        [assets, setAssets] = useState<Array<PropertyAsserts>>([]),
        [overviewSummary, setOverviewSummary] = useState<OverviewSummary>(defaultOverviewSummary),
        [isLoading, setIsLoading] = useBoolean(false);

    const fetchPropertyAssets = useCallback(
        async (filters: OverviewPropertyAssetSearch) => {
            setIsLoading.setTrue();
            try {
                const data = await PropertyService.fetchMyOverviewProperty(filters);

                setAssets(data.items);

                setIsLoading.setFalse();
            } catch (error: any) {
                toast(t(`${error.response.data.errorMessage}`));
                setIsLoading.setFalse();
            }
        },
        [t]
    );

    const fetchOverviewSummary = useCallback(async () => {
        try {
            const data = await PropertyService.fetchOverviewSummary();
            setOverviewSummary(data);
        } catch (error: any) {
            toast(t(`${error.response.data.errorMessage}`));
            setIsLoading.setFalse();
        }
    }, []);

    const navItems = [
        {
            id: OverviewTopCards.PORTFOLIO,
            len: overviewSummary.portfolios,
            text: t('f:sideMenu.overview.portfolio'),
            subText: t('f:sideMenu.overview.cardPortfolioInfo'),
            icon: faBriefcase,
        },
        {
            id: OverviewTopCards.DIVESTMENTS,
            len: overviewSummary.divestments,
            text: t('f:sideMenu.overview.divestments'),
            subText: t('f:sideMenu.overview.cardDivestementInfo'),
            icon: faDollarSign,
        },
        {
            id: OverviewTopCards.ACQUISITIONS,
            len: overviewSummary.acquisitions,
            text: t('f:sideMenu.overview.acquisitions'),
            subText: t('f:sideMenu.overview.cardAcquistionsInfo'),
            icon: faHandshake,
        },
    ];

    useEffect(() => {
        fetchOverviewSummary();
    }, [fetchOverviewSummary]);

    return (
        <div className="main-container">
            <div className="overview-side-menu mb-50">
                <p className="heading ml-20 mt-40">{t('f:sideMenu.overview.transactionDashboard')}</p>
                <div className="overview-side-menu__infomations mb-30 mt-40">
                    {navItems.map((el) => (
                        <InfoCard
                            len={el.len}
                            text={el.text}
                            subText={el.subText}
                            icon={el.icon}
                            key={el.id}
                            id={el.id}
                            handleClick={setOverviewType}
                            selected={overviewType}
                        />
                    ))}
                </div>
                <OverviewView
                    assets={assets}
                    isLoading={isLoading}
                    refetch={fetchPropertyAssets}
                    selected={overviewType}
                    setAssets={setAssets}
                />
            </div>
        </div>
    );
};
export default Overview;
