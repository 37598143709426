import { FunctionComponent, useCallback } from 'react';
import { Formik, Field, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import addProperty from '../../assets/images/add-property.png';
import cityscape from '../../assets/images/cityscape.svg';
import singleAssets from '../../assets/images/single-assets.svg';
import { useTranslation } from 'react-i18next';
import MainButton from '../../components/shared/MainButton';
import MainHeading from '../../components/shared/MainHeading';

export const AddProperty: FunctionComponent<any> = () => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory();

    const handlePushUrl = useCallback(
        async (values) => {
            history.push(values.isPortfolio ? 'add-portfolio' : 'add-single');
        },
        [history]
    );

    return (
        <div className="d-flex gap-20 col-xs-12-clear">
            <div className="col-xs-6-clear">
                <figure className="margin-0">
                    <img src={addProperty} className="fix-img-to-100-height" alt={t('f:menu.addProperty')} />
                </figure>
            </div>
            <div className="m-auto">
                <MainHeading text={t('f:menu.addProperty')} />
                <p className="secondary-text">{t('f:addProperty.subtitle')}</p>

                <Formik initialValues={{ isPortfolio: false }} enableReinitialize={true} onSubmit={handlePushUrl}>
                    {({ submitForm, setFieldValue, values }) => (
                        <Form className="row-flex-clear">
                            <label
                                className="property__radio property-type__radio"
                                onClick={() => {
                                    setFieldValue('isPortfolio', true);
                                }}
                            >
                                <Field type="radio" name="url" checked={values.isPortfolio} />
                                <div className="property__radio-option">
                                    <div className="d-flex justify-content-space-between">
                                        <figure>
                                            <img src={cityscape} alt="Portfolio" />
                                        </figure>
                                        <div className="property__radio-select"></div>
                                    </div>
                                    <h5>{t('f:portfolio')}</h5>
                                    <p>{t('f:addProperty.selectPortfolio')}</p>
                                </div>
                            </label>
                            <label
                                className="property__radio property-type__radio"
                                onClick={() => {
                                    setFieldValue('isPortfolio', false);
                                }}
                            >
                                <Field type="radio" name="url" value="/add/portfolio" checked={!values.isPortfolio} />
                                <div className="property__radio-option">
                                    <div className="d-flex justify-content-space-between">
                                        <figure>
                                            <img src={singleAssets} alt="Single Asset" />
                                        </figure>
                                        <div className="property__radio-select"></div>
                                    </div>
                                    <h5>{t('f:singleAsset')}</h5>
                                    <p>{t('f:addProperty.selectSingleAsset')}</p>
                                </div>
                            </label>
                            <div className="col-xs-12-clear">
                                <MainButton
                                    size="main"
                                    layout="primary"
                                    buttonHandler={submitForm}
                                    buttonText={t('f:button.getStarted')}
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
