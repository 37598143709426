import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface PreviewBarsProps {
    assetBarsText: string;
    assetBarsUnderline: string;
    assetBarsSelectedUnderline: string;
}

const PreviewBars: FunctionComponent<PreviewBarsProps> = ({
    assetBarsUnderline,
    assetBarsText,
    assetBarsSelectedUnderline,
}: PreviewBarsProps) => {
    const { t } = useTranslation(['f', 'b']);

    return (
        <div className="col-xs-5 ml-50 mt-10">
            <div className="ant-tabs ant-tabs-top">
                <div className="ant-tabs-nav" style={{ borderColor: assetBarsUnderline }}>
                    <div className="col-xs-9-clear">
                        <div className="ant-tabs-nav-wrap">
                            <div className="ant-tabs-nav-list">
                                <div className="ant-tabs-tab ant-tabs-tab-active">
                                    <div className="ant-tabs-tab-btn">
                                        <div>
                                            <p style={{ color: assetBarsSelectedUnderline }}>
                                                {t('f:theme-manager.prevSelected')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ant-tabs-tab-btn">
                                    <div className="mt-10">
                                        <p style={{ color: assetBarsText }}>{t('f:theme-manager.prevUnselected')}</p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                                style={{ left: '0px', width: '106px', backgroundColor: assetBarsSelectedUnderline }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviewBars;
