import InvestorApi from '../api/routes/InvestorApi';
import { ToggleCompanyStatusEnum } from '../constants/toggleCompanyStatus';
import { InitUserI } from '../interfaces/investor/InvestorUser';
import { downloadFile } from '../utils/file';

const getAllCompaniesWithInvestor = async (propertyAssetId: string, page: number = 0, pageSize: number = 20) => {
    const { data } = await InvestorApi.getAllCompaniesWithInvestor(propertyAssetId, page, pageSize);
    if (data.items) {
        data.items = data.items.map((item) => ({ ...item, id: item.id }));
    }
    return data;
};

const getInvestorUsers = async (propertyAssetId: string, page: number = 0, pageSize: number = 20) => {
    const { data } = await InvestorApi.getInvestorUsers(propertyAssetId, page, pageSize);
    return data;
};

const templateUrl = (): string => {
    return process.env.REACT_APP_API_URL + `/investor/template`;
};
const addInvestor = async (propertyAssetId: string, investor: InitUserI) => {
    const body = {
        firstName: investor.firstName,
        lastName: investor.lastName,
        email: investor.email,
        company: investor.companyName,
    };
    await InvestorApi.addInvestor(propertyAssetId, body);
};

const exportInvestors = async (id: string) => {
    const response = await InvestorApi.exportInvestors(id);
    downloadFile(response, 'Investors.xlsx');
};

const rejectInvitation = async (propertyAssetId: string) => {
    await InvestorApi.rejectInvitation(propertyAssetId);
};

const toggleNDAstatusUser = async (propertyAssetId: string, investorId: string) => {
    const { data } = await InvestorApi.toggleNDAstatusUser(propertyAssetId, investorId);
    return data;
};

const removeUserFromInvestor = async (propertyAssetId: string, emails: Array<string>) => {
    await InvestorApi.removeUserFromInvestor(propertyAssetId, { emails });
};

const sendEmails = async (propertyAssetId: string, emails: Array<string>) => {
    await InvestorApi.sendEmails(propertyAssetId, emails);
};

const getAnalysisSummaryStat = async (propertyAssetId: string) => {
    const { data } = await InvestorApi.getAnalysisSummaryStat(propertyAssetId);
    return data;
}

const InvestorService = {
    getAllCompaniesWithInvestor,
    getInvestorUsers,
    templateUrl,
    addInvestor,
    exportInvestors,
    rejectInvitation,
    removeUserFromInvestor,
    toggleNDAstatusUser,
    sendEmails,
    getAnalysisSummaryStat
};

export default InvestorService;
