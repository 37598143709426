import { Checkbox, Collapse, Slider } from 'antd';
import { FunctionComponent, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { PropertySectors } from '../../../components/shared/PropertiesMap/propertySectors';

const tenantOptions = [
    { label: 'Technology', value: 'Technology' },
    { label: 'Banks', value: 'Banks' },
    { label: 'Insurance', value: 'Insurance' },
    { label: 'Consultancies', value: 'Consultancies' },
    { label: 'Trading', value: 'Trading' },
];

const sectorOptions = [
    { label: PropertySectors.office, value: PropertySectors.office },
    { label: PropertySectors.retail, value: PropertySectors.retail },
    { label: PropertySectors.hotel, value: PropertySectors.hotel },
    { label: PropertySectors.school, value: PropertySectors.school },
];

const FilterMenuPanel: FunctionComponent = () => {
    const [priceMin, setPriceMin] = useState<number>(10),
        [priceMax, setPriceMax] = useState<number>(35);

    const onPriceChange = value => {
        setPriceMin(value[0]);
        setPriceMax(value[1]);
    };

    return (
        <>
            <div className='search-name'>Filter our Building Directory</div>
            <div className='search-address'>For market information click on the "market" tab</div>
            <Collapse
                bordered={false}
                defaultActiveKey={['1', '2']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className="filter-collapse"
            >
                <Collapse.Panel header="Tenants by Industry" key="1">
                    <Checkbox.Group className='checkbox-group' options={tenantOptions} defaultValue={['Technology', 'Consultancies']} />

                    <div className='price-title'>Price range</div>
                    <Slider className='price-slider' onAfterChange={onPriceChange} range defaultValue={[10, 35]} min={0} max={50} />
                    <div className='price-value'>
                        <span>{priceMin} psm</span>
                        <span className='max-price'>{priceMax} psm</span>
                    </div>
                </Collapse.Panel>
                <Collapse.Panel header="Properties by sector" key="2">
                    <Checkbox.Group className='checkbox-group sector' options={sectorOptions} defaultValue={[PropertySectors.office]} />
                </Collapse.Panel>
                <Collapse.Panel header="Commute scores" key="3">
                    <p>TBD</p>
                </Collapse.Panel>
            </Collapse>
        </>
    );
};

export default FilterMenuPanel;
