import { CancelToken } from 'axios';
import { SearchTransactionDataModel } from '../../pages/TransactionData/models/search-transaction-data.model';
import buildingDirectory from '../buildingDirectory';

const fetchBuildingDirectories = (address?: string, country?: string, lon?: number, lat?: number) => {
    return buildingDirectory.get<Array<any>>('/BuildingDirectories', { params: { address, country, lon, lat } });
};

const fetchBuildingDirectory = (id: string) => {
    return buildingDirectory.get<Array<any>>(`/BuildingDirectories/${id}`);
};

const fetchCountries = () => {
    return buildingDirectory.get<Array<any>>(`/BuildingDirectories-Countries`);
};

const fetchTradeProbabilities = (region?: string, lon?: number, lat?: number) => {
    return buildingDirectory.get<Array<any>>('/TradeProbabilities', { params: { region, lon, lat } });
};

const fetchTradeProbability = (id: string) => {
    return buildingDirectory.get<Array<any>>(`/TradeProbabilities/${id}`);
};

const fetchAllTransactionData = (cancelToken: CancelToken, filterParams?: SearchTransactionDataModel) => {
    return buildingDirectory.get<Array<any>>(
        `/TransactionData`,
        {
            params: {
                sectors: filterParams?.sectors,
                country: filterParams?.country,
                city: filterParams?.city,
                dateFrom: filterParams?.dateFrom,
                dateTo: filterParams?.dateTo,
                bo: filterParams?.buyerObjective,
                ybf: filterParams?.startYearBuilt,
                ybt: filterParams?.endYearBuilt,
                bcic: filterParams?.buyerCapInvComp,
                pmin: filterParams?.priceMin,
                pmax: filterParams?.priceMax
            },
            cancelToken: cancelToken
        }
    );
}

const fetchTransactionDataById = (id: string | number) => {
    return buildingDirectory.get(`/TransactionData/${id}`);
}

const BuildingDirectoryApi = {
    fetchBuildingDirectories,
    fetchBuildingDirectory,
    fetchCountries,
    fetchTradeProbabilities,
    fetchTradeProbability,
    fetchAllTransactionData,
    fetchTransactionDataById
};

export default BuildingDirectoryApi;
