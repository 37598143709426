import React from 'react';
import { PropertyAssetFeatures } from '../../constants/propertyAssetFeatures';
import { deafultPropertyHero } from '../../constants/propertyHero';
import { PropertyAssetSummary as PropertyAssetSummary } from '../../interfaces/property/PropertyAsserts';

export type FeatureContextType = {
    getFeature: (name: string) => any;
    myFeature: Array<number>;
    propertySummary: PropertyAssetSummary;
    fetchPropertySummary: () => void;
    checkDealRoomFeature: (feature: PropertyAssetFeatures) => boolean;
};

export default React.createContext<FeatureContextType>({
    getFeature: (name: string) => Boolean,
    myFeature: [],
    propertySummary: deafultPropertyHero,
    fetchPropertySummary: () => {},
    checkDealRoomFeature: (feature: PropertyAssetFeatures) => false
});
