import * as React from 'react';
import { Actions } from '../../interfaces/general/general';

export type DirtyWizard = {
    isDirty: boolean;
    isDirtyActions: Actions;
};
const action = {
    setTrue: () => {},
    setFalse: () => {},
    toggle: (value?: boolean | undefined) => {},
};
export default React.createContext<DirtyWizard>({
    isDirty: false,
    isDirtyActions: action,
});
