import { Table } from "antd";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import FormattedAmount from "../../../../../components/shared/FormattedAmount";
import { ChashFlowListItemIForTable } from "../../../../../interfaces/cashFlow/ChashFlowListItem";
import styles from './CashFlowTableMobile.module.scss';

interface CashFlowTableMobileProps {
    data: Array<ChashFlowListItemIForTable>;
}

const CashFlowTableMobile: FunctionComponent<CashFlowTableMobileProps> = ({
    data = []
}) => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<Array<number>>([]);

    const columns = [
        {
            dataIndex: 'floor',
            title: 'Floor',
            key: 'floor',
        },
        {
            dataIndex: 'name',
            title: 'Name',
            key: 'name',
        },
    ];

    const expandedField = [
        {
            key: 'area',
            title: 'Area',
            render: (value) => {
                return <FormattedAmount value={value} />;
            }
        },
        {
            key: 'leaseStartDate',
            title: 'Start Date',
            render: (value) => {
                return value !== null ? moment(new Date(value)).calendar() : '-';
            },
        },
        {
            key: 'leaseEndDate',
            title: 'End Date',
            render: (value) => {
                return value !== null ? moment(new Date(value)).calendar() : '-';
            },
        },
        {
            key: 'passingRent',
            title: 'Passing Rent'
        },
        {
            key: 'amount',
            title: 'Amount',
            render: (value) => {
                return value ? <FormattedAmount value={value} /> : '-';
            },
        },
    ]

    const tableData = data.map(item => ({ ...item, key: item.id }));
    const onExpand = (expanded: boolean, record: ChashFlowListItemIForTable) => {
        let keys: Array<number> = [];
        if (expanded) {
            keys.push(record.id);
        }

        setExpandedRowKeys(keys);
    }

    const expandedRowRender = (record: ChashFlowListItemIForTable) => {
        return (
            <div className={styles.expandedInfo}>
                {expandedField.map(item => (
                    <div className="d-flex flex-direction-row">
                        <b className={styles.expandedTitle}>{item.title}:</b>
                        <span>{item.render ? item.render(record[item.key]) : record[item.key]}</span>
                    </div>)
                )}
            </div>
        )
    }

    const arrow = (expanded: boolean, onClick: (e) => void) => (
        <svg className={expanded ? styles.expandedArrowIcon : styles.collapsedArrowIcon} focusable="false" viewBox="0 0 24 24" onClick={onClick}>
            <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" fill="#e0e0e0"></path>
        </svg>
    );

    const customExpandIcon = (props: any) => {
        const onClick = (e) => {
            props.onExpand(props.record, e);
        }
        return arrow(props.expanded, onClick);
    }

    return (
        <div style={{ height: '100%', width: '100%' }} className="mb-100">
            <Table
                columns={columns}
                dataSource={tableData}
                expandable={{
                    expandedRowKeys: expandedRowKeys,
                    expandedRowRender,
                    rowExpandable: (_) => true,
                    onExpand,
                    expandIcon: customExpandIcon
                }}
                pagination={false}
            />
        </div>
    )
}

export default CashFlowTableMobile;