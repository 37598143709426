import { Field } from 'formik';
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SingleSelect from '../../../../components/shared/SingleSelect';
import LookUpState from '../../../../context/state/LookUpState';
import useBoolean from '../../../../hooks/use-boolean';
import { PropertyDetailsI } from '../../../../interfaces/user/AccountSettings';
import CheckIsDirty from '../../partials/CheckIsDirty';

interface PhoneNumberProps {
    setFieldValue: (name: string, val: any) => void;
    name: string;
    values: PropertyDetailsI;
    setInitalUserDetails: (val: PropertyDetailsI) => void;
}

const PhoneNumber: FunctionComponent<PhoneNumberProps> = ({ setFieldValue, name, values, setInitalUserDetails }) => {
    const { t } = useTranslation('f'),
        { countries } = useContext(LookUpState),
        [phoneNumber, setPhoneNumber] = useState(''),
        [isNumber, IsNumberActions] = useBoolean(true),
        [selected, setSelected] = useState<number>(countries[0].id);

    const showPrefix = useCallback(() => {
        const contry = countries.find((el) => el.id === selected);
        return contry ? contry.phonePrefix : countries[0].phonePrefix;
    }, [selected, countries]);

    useEffect(() => {
        if (values.phoneNumber) {
            const init = values.phoneNumber.indexOf('(');
            const fin = values.phoneNumber.indexOf(')');

            const countryCallNumber = values.phoneNumber.substr(1, init - 1);
            const country = countries.find((el) => el.phonePrefix == countryCallNumber);

            setPhoneNumber(values.phoneNumber.substr(init + 1, fin - init - 1));

            setSelected(country ? country.id : selected);
        }
    }, [values.phoneNumber]);

    const handleCountrySelect = useCallback(
        (val) => {
            const country = countries.find((el) => el.id === val);

            setFieldValue(name, `+${country ? country.phonePrefix : 93} (${phoneNumber})`);
        },
        [countries, phoneNumber, name, setFieldValue]
    );

    const handlePhoneChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (val) => {
            const pattern = /^\d+\.?\d*$/;
            const checked = pattern.test(val.currentTarget.value);
            checked ? IsNumberActions.setTrue() : IsNumberActions.setFalse();

            setFieldValue(name, `+${selected} (${val.currentTarget.value})`);
        },
        [selected, name, setFieldValue]
    );

    return (
        <div className="col-xs-12 ">
            <label style={{ fontSize: '10px' }} htmlFor={name}>
                {t(`f:account-settings.personalDetails.${name}`)}
            </label>
            <div className="col-xs-12-clear d-flex">
                <div className="col-xs-3-clear mr-10">
                    <SingleSelect
                        placeholder={t(`f:account-settings.personalDetailsPlaceholder.${name}`)}
                        options={countries.map((el) => {
                            return { id: el.id, name: el.isoCode };
                        })}
                        onSelected={handleCountrySelect}
                        selected={selected}
                    />
                </div>
                <div className="d-flex align-items-center profile-details__input-container">
                    <p className="profile-details__phone-prefix">+{showPrefix()}</p>
                    <input
                        value={phoneNumber}
                        className="profile-details__phone-input ml-5"
                        onChange={handlePhoneChange}
                    />
                    <CheckIsDirty isDirty={!isNumber} text="Must be a number" />
                </div>
            </div>
        </div>
    );
};
export default PhoneNumber;
