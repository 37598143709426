import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListOfThemes from './components/ListOfThemes';
import CreateOrEditTheme from './components/CreateOrEditTheme';
import PublishTheme from './components/PublishTheme';
import { THEME_TABS } from '../../constants/themeTabs';
import Tabs from '../../components/shared/Tabs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ThemeState from '../../context/state/ThemeState';
import RouteParams from '../../interfaces/property/CreatePropertySummaryRouteParams';
import { getToken } from '../../auth/UserService';
import jwt_decode from 'jwt-decode';

const idsAndNames = [
    { id: THEME_TABS.LIST_OF_THEME.id, name: THEME_TABS.LIST_OF_THEME.name },
    { id: THEME_TABS.CREATE_OR_EDIT.id, name: THEME_TABS.CREATE_OR_EDIT.name },
    { id: THEME_TABS.PUBLISH.id, name: THEME_TABS.PUBLISH.name },
];

const findActivTab = (val: number | string, nameRepresentation: boolean) => {
    const tab = idsAndNames.find((el) => el[`${nameRepresentation ? 'id' : 'name'}`] == val);
    return tab
        ? nameRepresentation
            ? tab.name
            : `${tab.id}`
        : nameRepresentation
        ? THEME_TABS.LIST_OF_THEME.name
        : `${THEME_TABS.LIST_OF_THEME.id}`;
};
const ThemeManager: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { isEditMode, setCompanyIdToFilterBy } = useContext(ThemeState),
        location = useLocation(),
        { id: companyId } = useParams<RouteParams>(),
        history = useHistory(),
        [activTab, setActivTab] = useState<string>(
            location.hash ? findActivTab(location.hash.replace('#', ''), false) : '10'
        );

    const defaultTabs = [
        {
            id: THEME_TABS.LIST_OF_THEME.id,
            icon: '',
            name: `${THEME_TABS.LIST_OF_THEME.name}`,
            component: <ListOfThemes />,
        },
        {
            id: THEME_TABS.CREATE_OR_EDIT.id,
            icon: '',
            name: `${isEditMode ? 'editTheme' : 'createTheme'}`,
            component: <CreateOrEditTheme />,
        },
        {
            id: THEME_TABS.PUBLISH.id,
            icon: '',
            name: `${THEME_TABS.PUBLISH.name}`,
            component: <PublishTheme />,
        },
    ];

    const tabSelected = (value: any) => {
        history.push({ hash: findActivTab(value, true) });
    };

    useEffect(() => {
        setActivTab(location.hash ? findActivTab(location.hash.replace('#', ''), false) : '10');
    }, [location]);

    useEffect(() => {
        const token = getToken();
        const decoded: any = token ? jwt_decode(token) : {};
        companyId ? setCompanyIdToFilterBy(companyId) : setCompanyIdToFilterBy(decoded['companyExternalId']);
    }, [companyId, setCompanyIdToFilterBy]);

    return (
        <div className="main-container">
            <div className="col-xs-12-clear mt-80">
                <div className="property-tabs property-tabs__styled">
                    <Tabs
                        tabs={defaultTabs}
                        onTabSelected={tabSelected}
                        activeKey={activTab}
                        defaultActiveKey={activTab}
                    />
                </div>
            </div>
        </div>
    );
};
export default ThemeManager;
