import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { useHistory } from 'react-router';
import MainHeading from '../../../components/shared/MainHeading';
import ListOpportunityFooterBtns from '../../ListOpportunityWizard/components/partials/ListOpportunityFooterBtns';
import { PROPERTY_TABS } from '../../../constants/propertyTab';

interface Permission {
    sortOrder: number;
    afterNdaExecuted: boolean;
}

interface Props {
    setPermisions: any;
    propertyAssetId: string;
    permissionState: {
        state: Permission;
        setter: React.Dispatch<React.SetStateAction<Permission>>;
    };
}

export const Permissions: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']),
        history = useHistory();
    return (
        <div className="wizard-container-steps">
            <div className="col-xs-12">
                <MainHeading text={t('f:propertySummary.permissions')} />
            </div>
            <Formik
                initialValues={props.permissionState.state}
                enableReinitialize={true}
                onSubmit={async (value) => {
                    props.setPermisions(value);
                }}
            >
                {({ values, submitForm }) => (
                    <Form className="wizard-form permission-form row-flex-clear">
                        <div className="row-clear">
                            <div className="col-md-5 form-group">
                                <label htmlFor="sortOrder">{t('f:propertySummary.positionOnPage')}</label>
                                <Field
                                    id="sortOrder"
                                    name="sortOrder"
                                    placeholder={t('f:propertySummary.enterTheSectionNumber')}
                                />
                            </div>

                            <div className="col-md-12 form-group">
                                <h3>{t('f:propertySummary.security')}</h3>
                                <label className="custom-checkbox">
                                    <Field type="checkbox" name="afterNdaExecuted" />
                                    <span></span>
                                    <p>{t('f:propertySummary.afterNDAExecuted')}</p>
                                </label>
                            </div>
                        </div>

                        <ListOpportunityFooterBtns
                            cancelUrl={`/property/${props.propertyAssetId}#${PROPERTY_TABS.PROPERTY_SUMMARY.name}`}
                            isNotFirst={true}
                            isNotFinish={true}
                            onNextHandler={() => {
                                submitForm();
                            }}
                            returnToPreviousPage={() => {
                                localStorage.removeItem('content');
                                history.push(`/property/${props.propertyAssetId}`);
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
};
