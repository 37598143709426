import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import PropertyAssetCashFlow from '../../../../../services/PropertyAssetCashFlow';
import { toast } from 'react-toastify';
import MainButton from '../../../../../components/shared/MainButton';
import FeatureState from '../../../../../context/state/FeatureState';

interface ManageDataButtonsProps {
    customRequest: (opt: any) => void;
    handleOnClickOpenModal: () => void;
    isUploading: boolean;
}

const ManageDataButtons: FunctionComponent<ManageDataButtonsProps> = ({
    customRequest,
    handleOnClickOpenModal,
    isUploading,
}: ManageDataButtonsProps) => {
    const { propertySummary, getFeature } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary,
        { t } = useTranslation(['f', 'b']);

    const onDownloadExportFile = useCallback(async () => {
        try {
            await PropertyAssetCashFlow.exportCashflow(`${propertyAssetId}`);
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, [propertyAssetId, t]);

    return (
        <>
            <div className="cash-flow__manage-btns d-flex mb-20 mt-20 justify-content-end mr-80">
                {getFeature('CashFlowImport') && (
                    <>
                        {!propertySummary.isPortfolio && (
                            <Upload
                                name="Files"
                                action={
                                    process.env.REACT_APP_API_URL + `/propertyassetcashflow/excel/${propertyAssetId}`
                                }
                                customRequest={customRequest}
                                multiple
                                showUploadList={false}
                            >
                                <Button className="button-secondary button-small teams-button" loading={isUploading}>
                                    {t('f:button.import')}
                                    <FontAwesomeIcon className="teams-export__icon" icon={faUpload} size="sm" />
                                </Button>
                            </Upload>
                        )}
                    </>
                )}
                <div className="d-flex ml-5">
                    {getFeature('CashFlowImport') && (
                        <a
                            href={PropertyAssetCashFlow.templateUrl()}
                            className="button-secondary button-small teams-button teams-button--pading-left mr-10 d-flex justify-content-center align-items-center"
                            download
                        >
                            {t('f:button.template')}
                            <FontAwesomeIcon className="teams-export__icon" icon={faDownload} size="sm" />
                        </a>
                    )}
                    <MainButton
                        layout="secondary"
                        buttonHandler={onDownloadExportFile}
                        size="small"
                        buttonText={
                            <>
                                {t('f:button.export')}
                                <FontAwesomeIcon className="teams-export__icon" icon={faDownload} size="sm" />
                            </>
                        }
                        classAdditions="teams-button teams-button--pading-left mr-2"
                    />
                    {getFeature('CashFlowImport') && (
                        <MainButton
                            size="small"
                            layout="secondary"
                            buttonHandler={handleOnClickOpenModal}
                            buttonText={
                                <>
                                    {t('f:cashFlow.add')}
                                    <FontAwesomeIcon className="teams-export__icon" icon={faPlus} size="sm" />
                                </>
                            }
                            classAdditions="teams-button teams-button--pading-left mr-2 d-flex"
                        />
                    )}
                </div>
            </div>
            {/* <div className="cash-flow__manage-btns-tree-dots mb-20 mt-20 justify-content-end mr-80">
                <TreeDotsMenuManageDataButtons
                    customRequest={customRequest}
                    onDownloadExportFile={onDownloadExportFile}
                    handleOnClickOpenModal={handleOnClickOpenModal}
                />
            </div> */}
        </>
    );
};
export default ManageDataButtons;
