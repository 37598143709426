import { useCallback, useEffect, useState, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ThemeService from '../../../services/ThemeService';

interface CompanyThemeNameProps {
    id: string;
}

const CompanyThemeName: FunctionComponent<CompanyThemeNameProps> = ({ id }) => {
    const { t } = useTranslation(['f']),
        [name, setName] = useState<string>(t('f:theme-manager.default'));

    const fetchTheme = useCallback(async () => {
        try {
            const data = await ThemeService.fetchThemes(id);
            data.forEach((element) => {
                element.isMain ? setName(element.name) : setName(t('f:theme-manager.default'));
            });
        } catch (error: any) {
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    }, []);

    useEffect(() => {
        fetchTheme();
    }, [fetchTheme]);

    return <p>{name}</p>;
};
export default CompanyThemeName;
