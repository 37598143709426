import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Editor from '../../../../components/shared/Editor';
import { useTranslation } from 'react-i18next';
import FileUpload from '../../../../services/FileUploadService';
import { toast } from 'react-toastify';
import Cropper from 'react-cropper';
import UploadImage from '../../partials/UploadImage';
import TextInput from '../../partials/TextInput';
import UploadVideo from '../../partials/UploadVideo';
import ChartContent from '../../partials/ChartContent';
import MainHeading from '../../../../components/shared/MainHeading';
import useBoolean from '../../../../hooks/use-boolean';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';

interface Props {
    setContent: any;
    title: string;
    propertyAssetId: string;
    templateKey: string;
    setImageUrl: (prev: string) => void;
    setTitle: (prev: string) => void;
    imageUrl: string;
    pageType: string;
    contentState: {
        state: string;
        setter: React.Dispatch<React.SetStateAction<string>>;
    };
}

export const RichTextEditor: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']),
        [cancel, setCancel] = useState<boolean>(false),
        [doneCropping, setDoneCropping] = useState<boolean>(false),
        [isVideoUploading, isVideoUploadingActions] = useBoolean(),
        [cropper, setCropper] = useState<any>(),
        [croppedPhoto, setCroppedPhoto] = useState<any>(),
        [isVideoUploaded, setIsVideoUploaded] = useState<boolean>(false),
        [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const checkForClass = useCallback(() => {
        switch (props.templateKey) {
            case 'right-heading-editor':
                return 'image-style-align-left';
            case 'left-heading-editor':
                return 'image-style-align-right';
            default:
                return '';
        }
    }, [props.templateKey]);

    const getCropData = () => {
        setDoneCropping(true);
        if (typeof cropper !== 'undefined') {
            setCroppedPhoto(cropper.getCroppedCanvas().toDataURL());
            setDoneCropping(false);
            setCancel(true);
        }
    };

    const customRequest = async (options: any) => {
        const data = new FormData();
        data.append('Image', options.file);
        try {
            const imageUrl = await FileUpload.uploadFile(options, data);
            props.setImageUrl(imageUrl);
            setIsModalOpen(true);
        } catch (error: any) {
            toast(t(`f:${error.response.data.errorMessage}`));
        }
    };

    const checkTemplateKey = () => {
        return props.templateKey === 'right-heading-editor' ||
            props.templateKey === 'left-heading-editor' ||
            props.templateKey === 'single-image' ||
            props.templateKey === 'video' ||
            props.templateKey === 'right-heading-chart' ||
            props.templateKey === 'left-heading-chart'
            ? true
            : false;
    };

    const checkIsItChart = () => {
        return props.templateKey === 'right-heading-chart' || props.templateKey === 'left-heading-chart' ? true : false;
    };

    const finishCropping = async () => {
        try {
            const data = new FormData();
            data.append('Image', dataURLtoFile(croppedPhoto, 'Image'));

            const imageUrl = await FileUpload.uploadFile(
                { action: process.env.REACT_APP_API_URL + `/propertyasset/image` },
                data
            );

            const content = `<figure class="image ${checkForClass()}"><img src="${imageUrl}"></figure>`;
            localStorage.setItem('imageContent', content);
            props.setImageUrl(imageUrl);
            setIsModalOpen(false);
            setCancel(false);
        } catch (error: any) {
            toast(t(`f:${error.response.data.errorMessage}`));
        }
    };

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };

    const checkCkeditor = () => {
        return props.templateKey === 'single-image' || props.templateKey === 'video' ? true : false;
    };

    const videoData = (url: string) => {
        const content = `<figure class="image ${checkForClass()}"><video controls src="${url}" style="width: 100%;"> </video></figure>`;
        props.setImageUrl(url);
        localStorage.setItem('imageContent', content);
    };
    const videoUpload = async (options: any) => {
        const data = new FormData();
        data.append('Files', options.file);
        try {
            const url = await FileUpload.uploadFile(options, data);
            videoData(url[0]);
            setIsVideoUploaded(true);
            isVideoUploadingActions.setFalse();
        } catch (error: any) {
            setIsVideoUploaded(false);
            toast(t(`b:${error.response.data.errorMessage}`));
        }
    };

    const handleClose = useCallback(() => {
        setIsModalOpen(false);
    }, [setIsModalOpen]);

    useEffect(() => {
        if (props.imageUrl !== '') {
            let content = `<figure class="image ${checkForClass()}"><img src="${props.imageUrl}"></figure>`;
            if (props.templateKey === 'video') {
                content = `<figure class="image ${checkForClass()}"><video controls src="${props.imageUrl}" style="width: 100%;"> </video></figure>`;
            }
            localStorage.setItem('imageContent', content);
        }
    }, [props.imageUrl, checkForClass]);

    return (
        <div className="row-flex-clear rich-text-editor-container">
            <div className="col-xs-12">
                <MainHeading text={t('f:propertySummary.content')} />
            </div>

            <div className="col-xs-12">
                {(checkTemplateKey() || props.templateKey === 'geo-map') && (
                    <TextInput
                        label={t('f:propertySummary.title')}
                        placeholder={t('f:propertySummary.enterTitle')}
                        content={props.title}
                        setContent={props.setTitle}
                    />
                )}
            </div>
            <div className="col-xs-12">
                <div className="col-xs-8">
                    {!checkCkeditor() && (
                        <Editor
                            onChange={props.setContent}
                            propertyAssetId={props.propertyAssetId}
                            templateKey={props.templateKey}
                            pageType={props.pageType}
                            contentState={{ state: props.contentState.state, setter: props.contentState.setter }}
                        />
                    )}
                </div>
            </div>

            {props.templateKey === 'video' ? (
                <UploadVideo
                    videoUpload={videoUpload}
                    isVideoUploaded={isVideoUploaded}
                    propertyAssetId={props.propertyAssetId}
                    isVideoUploading={isVideoUploading}
                    isVideoUploadingActions={isVideoUploadingActions}
                    imageUrl={props.imageUrl}
                    setImageUrl={props.setImageUrl}
                    videoData={videoData}
                    setIsVideoUploaded={setIsVideoUploaded}
                />
            ) : (
                <div className="col-xs-12">
                    {checkTemplateKey() ? (
                        <div className="form-group col-xs-6-clear">
                            {checkIsItChart() ? (
                                <ChartContent />
                            ) : (
                                <UploadImage
                                    customRequest={customRequest}
                                    setIsModalOpen={setIsModalOpen}
                                    setCancel={setCancel}
                                    imageUrl={props.imageUrl}
                                    setImageUrl={props.setImageUrl}
                                />
                            )}
                        </div>
                    ) : null}
                </div>
            )}
            <ModalWrapper
                isFooterShown={false}
                isCloseModalShown={true}
                className="gallery-modal"
                isModalOpened={isModalOpen}
                title={t('f:property.cropImage')}
                closeModalHandler={handleClose}
            >
                <div className="col-xs-12-clear gallery-modal__main mt-50">
                    <Cropper
                        style={{ height: 480, width: 500 }}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={props.imageUrl}
                        viewMode={1}
                        guides={true}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                </div>
                <div className="col-xs-12 d-flex justify-content-center">
                    {cancel ? (
                        <>
                            <button
                                className="col-xs-3 button-main button-primary mr-10"
                                onClick={(e: any) => {
                                    finishCropping();
                                }}
                            >
                                {t('f:button.upload')}
                            </button>
                            <button
                                className="col-xs-3 button-main button-secondary"
                                disabled={doneCropping}
                                onClick={() => {
                                    setCancel(true);
                                }}
                            >
                                {t('f:button.cancel')}
                            </button>
                        </>
                    ) : (
                        <button
                            className="col-xs-3 button-main button-primary"
                            disabled={doneCropping}
                            onClick={getCropData}
                        >
                            {doneCropping ? 'Cropping...' : 'Crop Image'}
                        </button>
                    )}
                </div>
            </ModalWrapper>
        </div>
    );
};
