import { Popover } from 'antd';
import { FunctionComponent, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import FeatureState from '../../../../context/state/FeatureState';

interface Props {
    question: any;
    openEditableModal: Function;
    removeQuestionHandler: Function;
    isThreeDotClicked: boolean;
    setIsThreeDotClicked: (state: boolean) => void;
}

const FaqThreeDotMenu: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']);
    const { getFeature } = useContext(FeatureState);
    const content = (
        <div>
            <span
                style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                onClick={() => {
                    props.setIsThreeDotClicked(false);
                    props.openEditableModal(props.question);
                }}
            >
                {t('f:button.edit')}
            </span>
            <br></br>
            <span
                style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                onClick={() => {
                    props.setIsThreeDotClicked(false);
                    props.removeQuestionHandler(props.question);
                }}
            >
                {t('f:button.remove')}
            </span>
        </div>
    );

    return (
        <div>
            {getFeature('FaqEdit') && (
                <>
                    <FontAwesomeIcon
                        className="mxl-10"
                        style={{ color: '#a1abbd', cursor: 'pointer' }}
                        icon={faEllipsisH}
                        size="lg"
                        onClick={() => {
                            props.isThreeDotClicked
                                ? props.setIsThreeDotClicked(false)
                                : props.setIsThreeDotClicked(true);
                        }}
                    ></FontAwesomeIcon>
                    <div style={{ marginLeft: '20px' }}>
                        <Popover
                            content={content}
                            placement="bottom"
                            trigger="click"
                            visible={props.isThreeDotClicked}
                        ></Popover>
                    </div>
                </>
            )}
        </div>
    );
};

export default FaqThreeDotMenu;
