import { FunctionComponent } from "react";
import ActiveUsersInDaysChart from "../../../../../components/shared/ActiveUserInDaysChart";
import { IActiveUsersInDays } from "../../../../../interfaces/googleAnalytics/ActiveUsersInDays";

interface PropertyActiveUsersInDaysChartProps {
    data: Array<IActiveUsersInDays>;
}

const PropertyActiveUsersInDaysChart: FunctionComponent<PropertyActiveUsersInDaysChartProps> = ({
    data = []
}) => {
    return <ActiveUsersInDaysChart data={data} />
}

export default PropertyActiveUsersInDaysChart;