import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';
import OverviewState from './state/OverviewState';
import Favorite from '../services/Favorite';
import { FavoritesArray } from '../interfaces/contexts/overview';

const initValues: FavoritesArray = {
    totalCount: 0,
    items: [],
};

const NR_ITEMS_PER_PAGE = 9;

interface Props {
    children: React.ReactNode;
}

const OverviewContext: FunctionComponent<Props> = ({ children }) => {
    const [allFavouritesAssests, setAllFavouritesAssets] = useState<FavoritesArray>(initValues),
        [allFavouritesPortfolios, setAllFavouritesPortfolios] = useState<FavoritesArray>(initValues),
        [allDivestments, setAllDivestments] = useState<FavoritesArray>(initValues),
        [allAcquisitions, setAllAcquisitions] = useState<FavoritesArray>(initValues),
        [acquisitionsPage, setAcquisitionsPage] = useState<number>(1),
        [divestmentsPage, setDivestmentsPage] = useState<number>(1),
        [favoritePageAssets, setFavoritePageAssets] = useState<number>(1),
        [favoritePagePortfolios, setFavoritePagePortfolios] = useState<number>(1);

    const fetchAllFavoritesAssets = useCallback(async (page: number) => {
        const favorites = await Favorite.fetchAllFavouritesAssets(page - 1, NR_ITEMS_PER_PAGE);
        setAllFavouritesAssets(favorites);
    }, []);

    const fetchAllFavoritesPortfolios = useCallback(async (page: number) => {
        const favorites = await Favorite.fetchAllFavouritesPortfolios(page - 1, NR_ITEMS_PER_PAGE);
        setAllFavouritesPortfolios(favorites);
    }, []);

    const fetchAllDivstemetns = useCallback(async (page: number) => {
        const divestments = await Favorite.fetchAllDivstemetns(page - 1, NR_ITEMS_PER_PAGE);
        setAllDivestments(divestments);
    }, []);

    const fetchAllAcquisitions = useCallback(async (page: number) => {
        const acquisitions = await Favorite.fetchAllAcquisitions(page - 1, NR_ITEMS_PER_PAGE);
        setAllAcquisitions(acquisitions);
    }, []);

    return (
        <OverviewState.Provider
            value={{
                allFavouritesAssests: allFavouritesAssests,
                allFavouritesPortfolios: allFavouritesPortfolios,
                allDivestments: allDivestments,
                allAcquisitions: allAcquisitions,
                setAcquisitionsPage: setAcquisitionsPage,
                setDivestmentsPage: setDivestmentsPage,
                setFavoritePageAssets: setFavoritePageAssets,
                setFavoritePagePortfolios: setFavoritePagePortfolios,
                fetchAllAcquisitions: fetchAllAcquisitions,
                fetchAllDivstemetns: fetchAllDivstemetns,
                fetchAllFavoritesPortfolios: fetchAllFavoritesPortfolios,
                fetchAllFavoritesAssets: fetchAllFavoritesAssets,
            }}
        >
            {children}
        </OverviewState.Provider>
    );
};

export default OverviewContext;
