import { FunctionComponent, useEffect, useState, useContext, useCallback } from 'react';
import { Formik, Field, Form, FormikHelpers, FieldProps, FieldArray } from 'formik';
import SingleSelect from '../../../components/shared/SingleSelect';
import MultiSelect from '../../../components/shared/MultiSelect';
import Property from '../../../services/Property';
import { useHistory } from 'react-router-dom';
import { Button, DatePicker, Space, Tooltip, Upload } from 'antd';
import moment from 'moment';
import { EditPropertyShema } from '../../../validations/EditProperty';
import PropertyEditValues from '../../../interfaces/property/PropertyEdit';
import { useTranslation } from 'react-i18next';
import { calculateAssetValue } from '../../../utils/calculator';
import FileUpload from '../../../services/FileUploadService';
import LookUpContext from '../../../context/state/LookUpState';
import ImgCrop from 'antd-img-crop';
import { toast } from 'react-toastify';
import MapService from '../../../services/MapService';
import _ from 'lodash';
import PropertyAssetMap from '../../../components/shared/PropertyAssetMap';
import MyNumberInput from './MyNumberInput';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { FormikErrorArrayDeep } from '../../TemplatesWizard/partials/FormikError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import varibales from '../../../assets/styles/general/_variable.module.scss';
import MainHeading from '../../../components/shared/MainHeading';
import MainLoader from '../../../components/shared/MainLoader';
import InputStake from '../partials/InputStake';
import useBoolean from '../../../hooks/use-boolean';
import ListOpportunityFooterBtns from '../../ListOpportunityWizard/components/partials/ListOpportunityFooterBtns';
import ModalImportLoading from '../../../components/shared/ModalImportLoading';
import FeatureState from '../../../context/state/FeatureState';
interface OwnershipType {
    name: string;
    share: number;
}

export const EditBasicInfo: FunctionComponent<any & FieldProps> = ({ props, setFieldValue }) => {
    const { t } = useTranslation(['f', 'b']);
    const { propertySummary } = useContext(FeatureState),
        { getContent, countries } = useContext(LookUpContext),
        [deleteOwners, setDeleteOwners] = useState<Array<number>>([]);
    const history = useHistory();
    const [image, setImage] = useState(''),
        [typedAddress, setTypedAddress] = useState<any>(),
        [options, setOptions] = useState<any>([]),
        [isPropertyEditing, isPropertyEditingActions] = useBoolean(),
        [locationSelected, setLocationSelected] = useState<boolean>(false),
        [formToSend, setFormToSend] = useState<boolean>(false);

    const sectorsOptions = getContent('Sector');
    const [subSectorOptions, setSubSectorOptions] = useState<any>(getContent('Sector'));

    const initialValues = {
        id: 0,
        propertyName: '',
        description: '',
        numberOfBuildings: '',
        numberOfFloors: 0,
        numberOfUnitsRooms: 0,
        buildingClassId: 0,
        buildingStatusId: 0,
        yearRenovated: '',
        propertyMarketId: 0,
        propertySubMarketId: 0,
        currencyId: '',
        unitOfMeasurementId: '',
        yearOfCompletion: '',
        saleTypeId: '',
        landTenureId: 0,
        anchorTenants: '',
        buildingGradeId: 0,
        landArea: 0,
        passingRent: 0,
        carparkLots: 0,
        majorTenants: '',
        netOperatingIncome: 0,
        capitalisationRate: 0,
        grossLeasableArea: 0,
        assetValue: 0,
        locationTypes: [],
        greenRatings: [],
        leaseholdStartDate: '',
        leaseholdExpirationDate: '',
        leasehold: 0,
        sectors: [],
        assetTypes: [],
        grossFloorArea: '',
        owners: [{ name: '', share: 100 }],
        netLeasableArea: '',
        ownershipTypeId: '',
        partialInterest: '',
        assetSubTypeId: '',
        map: {
            long: '',
            lat: '',
            address: '',
            countryId: '',
        },
        imageUrl: '',
        subSectors: [],
    };
    const [property, setProperty] = useState(initialValues);
    const [countryCode, setCountryCode] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);

    const checkStreetNumber = (street: string) => {
        return street ? street : '';
    };
    const searchResult = async (query: string) => {
        try {
            let locations = await MapService.fetchLocations(query, [countryCode], 0, 100);
            let options = _.filter(locations.items, (location: any) => {
                if (location.streetName != null) {
                    return location;
                }
            }).map((location: any) => {
                return {
                    longitude: location.long,
                    latitude: location.lat,
                    country: location.country,
                    city: location.city,
                    streetName: location.streetName,
                    streetNumber: checkStreetNumber(location.streetNumber),
                    fullAddress: location.freeFormAddress
                };
            });
            return options;
        } catch (e) {
            console.log(e);
            return [];
        }
    };

    const handleSearch = useCallback(
        _.debounce(async (value: string) => {
            if (!value) {
                return;
            }

            let result = await searchResult(value);
            setOptions(value ? result : []);
        }, 500),
        [searchResult]
    );

    const customRequest = async (options: any, setFieldValue: any) => {
        const data = new FormData();
        data.append('Image', options.file);
        try {
            const imageUrl = await FileUpload.uploadFile(options, data);
            setImage(imageUrl);
            setFieldValue(`imageUrl`, imageUrl);
        } catch (err) {
            console.log(err);
        }
    };

    const setSubSectorsOptionsByMainSector = (mainSectorId?: number) => {
        if (!mainSectorId) {
            setSubSectorOptions(sectorsOptions);
        }

        const subSectorOptions = sectorsOptions.filter((item) => item.id != mainSectorId);
        setSubSectorOptions(subSectorOptions);
    };

    useEffect(() => {
        const fetchAsset = async () => {
            setIsLoading(true);
            const property = await Property.fetch(parseInt(`${propertySummary.id}`));

            property.owners.length
                ? property.owners.forEach((element) => {
                      element.share = element.share * 100 + 1;
                  })
                : property.owners.push({ name: '', share: 100 });

            setProperty(property);
            setIsLoading(false);
            setImage(property.imageUrl);
            setTypedAddress({ long: property.map.long, lat: property.map.lat, address: property.map.address });
            setSubSectorsOptionsByMainSector(property.mainSectorId);
            if (property.map && property.map.countryId) {
                let country: any = _.find(countries, { id: property.map.countryId });
                setCountryCode(country.isoCode);
            }
        };
        fetchAsset();
    }, [propertySummary.id, getContent]);

    const scrollToErrors = (errors: any) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            toast.error(t(errors[errorKeys[0]], { name: t(`f:property.${errorKeys[0]}`) }), {
                className: 'error-toast',
            });
            const firstError = document.getElementsByName(errorKeys[0])[0];
            if (firstError) firstError.focus();
        }
    };

    const isLeaseHoldSelected = (landTenureId: any) => {
        return landTenureId === 2 || landTenureId === 3;
    };

    const onMapClicked = useCallback(async (e: any, setFieldValue: any) => {
        const position = e.position;
        const lat = position[1];
        const lon = position[0];
        const location = await MapService.reverse(lat, lon);

        if (location) {
            let country = _.find(countries, item => item.isoCode === location.countryCode);
            if (country) {
                setFieldValue('map.countryId', country.id);
                setCountryCode(location.countryCode);
            }

            setFieldValue('map.long', location.long);
            setFieldValue('map.lat', location.lat);

            let address = '';
            if (location.streetName && location.streetNumber) {
                address = `${location.country}, ${location.city}, ${location.streetName}, ${checkStreetNumber(location.streetNumber)}`
            } else {
                address = location.freeFormAddress;
            }

            setFieldValue('map.address', address);
            setTypedAddress({
                long: location.long,
                lat: location.lat,
                address: address,
            });
        }
    }, []);

    return (
        <AuthenticatedTemplate>
            <div className="wizard-container-steps">
                {!isLoading ? (
                    <Formik
                        initialValues={property}
                        enableReinitialize={true}
                        validationSchema={EditPropertyShema}
                        autocomplete="none"
                        validateOnMount
                        onSubmit={async (
                            values: PropertyEditValues,
                            { setSubmitting }: FormikHelpers<PropertyEditValues>
                        ) => {
                            if (formToSend) {
                                isPropertyEditingActions.setTrue();
                                try {
                                    await Property.update({ property: values, deleteOwners: deleteOwners });
                                    history.push(`/property/${propertySummary.externalId}`);
                                    isPropertyEditingActions.setFalse();
                                } catch (error: any) {
                                    toast(t(`b:${error.response.data.errorMessage}`));
                                    isPropertyEditingActions.setFalse();
                                }

                                setFormToSend(false);
                            }
                        }}
                    >
                        {({ errors, touched, values, setFieldValue, validateForm }) => (
                            <Form className="row-clear create-edit-property-form mr-20">
                                <div className="col-md-12 ml-15">
                                    <MainHeading text={t('f:property.dealInformation')} />
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="propertyName">{t('f:property.opportunityName')}*</label>
                                    <Field
                                        id="propertyName"
                                        name="propertyName"
                                        placeholder={t('f:property.enterName')}
                                    />
                                    {errors.propertyName && touched.propertyName ? (
                                        <span className="error-text-absolute">
                                            {t(errors.propertyName, { name: t('f:property.opportunityName') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group" id="saleTypeId">
                                    <SingleSelect
                                        label={t('f:property.saleType')}
                                        placeholder={`${t('f:property.selectSaleType')}*`}
                                        options={getContent('SaleType')}
                                        onSelected={(value: any) => setFieldValue('saleTypeId', value)}
                                        selected={values.saleTypeId ? values.saleTypeId : null}
                                    />
                                    {errors.saleTypeId && touched.saleTypeId ? (
                                        <span className="error-text-absolute">
                                            {t(errors.saleTypeId, { name: t('f:property.saleType') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-lg-10 form-group">
                                    <label htmlFor="description">{t('f:property.description')}</label>
                                    <Field
                                        component="textarea"
                                        id="description"
                                        name="description"
                                        rows={5}
                                        value={values.description}
                                        placeholder={t('f:property.enterDescription')}
                                    />
                                </div>

                                <div className="col-md-12 mt-20 ml-15">
                                    <MainHeading text={t('f:property.general')} />
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group" id="unitOfMeasurementId">
                                    <SingleSelect
                                        label={t('f:property.unitOfMeasuremnt')}
                                        placeholder={t('f:property.selectUnitOfMeasuremnt')}
                                        options={getContent('UnitOfMeasure')}
                                        onSelected={(value: any) => setFieldValue('unitOfMeasurementId', value)}
                                        selected={values.unitOfMeasurementId ? values.unitOfMeasurementId : null}
                                    />
                                    {errors.unitOfMeasurementId && touched.unitOfMeasurementId ? (
                                        <span className="error-text-absolute">
                                            {t(errors.unitOfMeasurementId, { name: t('f:property.unitOfMeasuremnt') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group" id="currencyId">
                                    <SingleSelect
                                        label={`${t('f:property.currency')}*`}
                                        placeholder={t('f:property.selectCurrency')}
                                        options={getContent('Currency')}
                                        onSelected={(value: any) => setFieldValue('currencyId', value)}
                                        selected={values.currencyId ? values.currencyId : null}
                                    />
                                    {errors.currencyId && touched.currencyId ? (
                                        <span className="error-text-absolute">
                                            {t(errors.currencyId, { name: t('f:property.currency') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-md-12 mt-20 ml-15">
                                    <MainHeading text={t('f:property.ownership')} />
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <SingleSelect
                                        label={`${t('f:property.ownershipType')}*`}
                                        placeholder={t('f:property.selectOwnershipType')}
                                        options={getContent('OwnershipType')}
                                        onSelected={(value: any) => setFieldValue('ownershipTypeId', value)}
                                        selected={values.ownershipTypeId ? values.ownershipTypeId : null}
                                    />
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group mt-50">
                                    <label htmlFor="description" className="mt-5">
                                        {t('f:property.comapanyAndStake')}
                                    </label>
                                    <FieldArray
                                        name="owners"
                                        render={({ remove, push }) => (
                                            <>
                                                {values.owners.length > 0 &&
                                                    values.owners.map((friend, index) => (
                                                        <tr key={index} style={{ border: '0' }}>
                                                            <td>
                                                                <div className="mb-20 position-relative">
                                                                    <Field
                                                                        className="form-control"
                                                                        name={`owners.${index}.name`}
                                                                        placeholder={t('f:chart.table.enterName')}
                                                                        type="text"
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                    <FormikErrorArrayDeep
                                                                        errors={errors}
                                                                        touched={touched}
                                                                        index={index}
                                                                        name="name"
                                                                        array="owners"
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <InputStake
                                                                    index={index}
                                                                    values={values.owners[index]['share']}
                                                                    errors={errors}
                                                                    touched={touched}
                                                                    name={`owners.${index}.share`}
                                                                    classes="mb-20"
                                                                />
                                                            </td>

                                                            <td>
                                                                <div className="mb-20 position-relative">
                                                                    <FontAwesomeIcon
                                                                        className="mr-20 ml-20 pointer"
                                                                        icon={faMinus}
                                                                        color={varibales.red}
                                                                        onClick={() => {
                                                                            if (values.owners.length === 1) return;
                                                                            if (values.owners[index]['id']) {
                                                                                setDeleteOwners([
                                                                                    ...deleteOwners,
                                                                                    values.owners[index]['id'],
                                                                                ]);
                                                                            }
                                                                            const newOwners: Array<OwnershipType> = [];
                                                                            values.owners.forEach(
                                                                                (elem, currentIndex) => {
                                                                                    if (currentIndex !== index)
                                                                                        newOwners.push(elem);
                                                                                }
                                                                            );

                                                                            setFieldValue('owners', newOwners);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                <tr style={{ border: '0' }}>
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        color={varibales.green}
                                                        className="mt-10 mb-10 ml-20 pointer"
                                                        onClick={() =>
                                                            push({
                                                                name: '',
                                                                share: 0,
                                                            })
                                                        }
                                                    />
                                                </tr>
                                            </>
                                        )}
                                    />
                                    {errors.owners && touched.owners ? (
                                        <span className="error-text-absolute">{JSON.stringify(errors.owners)}</span>
                                    ) : null}
                                </div>

                                <div className="col-md-12 mt-20 ml-15">
                                    <MainHeading text={t('f:property.propertyInformation')} />
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="yearOfCompletion">{t('f:property.yearBuilt')}</label>
                                    <Field
                                        id="yearOfCompletion"
                                        name="yearOfCompletion"
                                        placeholder={t('f:property.yearBuilt')}
                                    />
                                    {errors.yearOfCompletion && touched.yearOfCompletion ? (
                                        <span className="error-text-absolute">
                                            {t(errors.yearOfCompletion, { name: t('f:property.yearOfCompletion') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="yearRenovated">{t('f:property.yearRenovated')}</label>
                                    <Field
                                        id="yearRenovated"
                                        name="yearRenovated"
                                        placeholder={t('f:property.enterYearRenovated')}
                                    />
                                    {errors.yearRenovated && touched.yearRenovated ? (
                                        <span className="error-text-absolute">
                                            {t(errors.yearRenovated, { name: t('f:property.yearRenovated') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="landArea">{t('f:property.landArea')}</label>
                                    <MyNumberInput
                                        placeholder={t('f:property.enterLandArea')}
                                        value={values.landArea}
                                        onValueChange={(val) => setFieldValue('landArea', val.floatValue)}
                                    />
                                    {errors.landArea && touched.landArea ? (
                                        <span className="error-text-absolute">
                                            {t(errors.landArea, { name: t('f:property.landArea') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <SingleSelect
                                        label={t('f:property.landTenure')}
                                        placeholder={t('f:property.selectLandTenure')}
                                        options={getContent('LandTenure')}
                                        onSelected={(value: any) => {
                                            setFieldValue('landTenureId', value);
                                            if (!isLeaseHoldSelected(value)) {
                                                setFieldValue('leaseholdStartDate', null);
                                                setFieldValue('leaseholdExpirationDate', null);
                                                setFieldValue('leasehold', 0);
                                            }
                                        }}
                                        selected={values.landTenureId ? values.landTenureId : null}
                                    />
                                </div>
                                {isLeaseHoldSelected(values.landTenureId) && (
                                    <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                        <label>{t('f:property.leaseholdStartDate')}</label>
                                        <Space direction="vertical" size={10}>
                                            <DatePicker
                                                value={
                                                    values.leaseholdStartDate
                                                        ? moment(new Date(values.leaseholdStartDate))
                                                        : null
                                                }
                                                onChange={(value: any) => setFieldValue('leaseholdStartDate', value)}
                                                onOk={(value: any) => setFieldValue('leaseholdStartDate', value)}
                                            />
                                        </Space>
                                    </div>
                                )}

                                {isLeaseHoldSelected(values.landTenureId) && (
                                    <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                        <label>{t('f:property.leaseholdExpirationDate')}</label>
                                        <Space direction="vertical" size={10}>
                                            <DatePicker
                                                value={
                                                    values.leaseholdExpirationDate
                                                        ? moment(new Date(values.leaseholdExpirationDate))
                                                        : null
                                                }
                                                onChange={(value: any) =>
                                                    setFieldValue('leaseholdExpirationDate', value)
                                                }
                                                onOk={(value: any) => setFieldValue('leaseholdExpirationDate', value)}
                                            />
                                        </Space>
                                    </div>
                                )}
                                {isLeaseHoldSelected(values.landTenureId) && (
                                    <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                        <label>{t('f:property.leaseholdYears')}</label>
                                        <Field
                                            id="leasehold"
                                            name="leasehold"
                                            placeholder={t('f:property.enterLeasehold')}
                                        />
                                        {errors.leasehold && touched.leasehold ? (
                                            <span className="error-text-absolute">
                                                {t(errors.leasehold, { name: t('f:property.leasehold') })}
                                            </span>
                                        ) : null}
                                    </div>
                                )}

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <SingleSelect
                                        label={`${t('f:property.mainSector')}*`}
                                        placeholder={t('f:property.selectMainSector')}
                                        options={sectorsOptions}
                                        onSelected={(value: any) => {
                                            setFieldValue('mainSectorId', value);
                                            setSubSectorsOptionsByMainSector(value);
                                        }}
                                        selected={values.mainSectorId}
                                    />
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <MultiSelect
                                        label={t('f:property.subSectors')}
                                        placeholder={t('f:property.selectSubSectors')}
                                        options={subSectorOptions}
                                        onSelected={(value: any) =>
                                            setFieldValue('subSectors', [...values.subSectors, value])
                                        }
                                        onDeleted={(value: any) => {
                                            let removeElem = values.subSectors.filter((elem) => elem !== value);
                                            setFieldValue('subSectors', removeElem);
                                        }}
                                        selected={values.subSectors ? values.subSectors : []}
                                    />
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="grossFloorArea">{t('f:property.grossFloorArea')}</label>
                                    <MyNumberInput
                                        placeholder={t('f:property.enterGrossFloorArea')}
                                        value={values.grossFloorArea}
                                        onValueChange={(val) => setFieldValue('grossFloorArea', val.floatValue)}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="netLeasableArea">{t('f:property.netLeasableArea')}</label>
                                    <MyNumberInput
                                        placeholder={t('f:property.enterNetLeasableArea')}
                                        value={values.netLeasableArea}
                                        onValueChange={(val) => setFieldValue('netLeasableArea', val.floatValue)}
                                    />
                                    {errors.netLeasableArea && touched.netLeasableArea ? (
                                        <span className="error-text-absolute">
                                            {t(errors.netLeasableArea, { name: t('f:property.netLeasableArea') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="carparkLots">{t('f:property.carparkLots')}</label>
                                    <MyNumberInput
                                        placeholder={t('f:property.enterCarparkLots')}
                                        value={values.carparkLots}
                                        onValueChange={(val) => setFieldValue('carparkLots', val.floatValue.toString())}
                                    />
                                    {errors.carparkLots && touched.carparkLots ? (
                                        <span className="error-text-absolute">
                                            {t(errors.carparkLots, { name: t('f:property.carparkLots') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="numberOfBuildings">{t('f:property.numberOfBuildings')}</label>
                                    <MyNumberInput
                                        placeholder={t('f:property.enterNumberOfBuildings')}
                                        value={values.numberOfBuildings}
                                        onValueChange={(val) => setFieldValue('numberOfBuildings', val.floatValue)}
                                    />
                                    {errors.numberOfBuildings && touched.numberOfBuildings ? (
                                        <span className="error-text-absolute">
                                            {t(errors.numberOfBuildings, { name: t('f:property.numberOfBuildings') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="numberOfFloors">{t('f:property.numberOfFloors')}</label>

                                    <MyNumberInput
                                        placeholder={t('f:property.enterNumberOfFloors')}
                                        value={values.numberOfFloors}
                                        onValueChange={(val) => setFieldValue('numberOfFloors', val.floatValue)}
                                    />
                                    {errors.numberOfFloors && touched.numberOfFloors ? (
                                        <span className="error-text-absolute">
                                            {t(errors.numberOfFloors, { name: t('f:property.numberOfFloors') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="numberOfUnitsRooms">{t('f:property.numberOfUnits')}</label>

                                    <MyNumberInput
                                        placeholder={t('f:property.enterNumberOfUnits')}
                                        value={values.numberOfUnitsRooms}
                                        onValueChange={(val) =>
                                            setFieldValue('numberOfUnitsRooms', val.floatValue.toString())
                                        }
                                    />
                                    {errors.numberOfUnitsRooms && touched.numberOfUnitsRooms ? (
                                        <span className="error-text-absolute">
                                            {t(errors.numberOfUnitsRooms, { name: t('f:property.numberOfUnits') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <MultiSelect
                                        label={t('f:property.locationType')}
                                        placeholder={t('f:property.selectLocationType')}
                                        options={getContent('LocationType')}
                                        onSelected={(value: any) =>
                                            setFieldValue('locationTypes', [...values.locationTypes, value])
                                        }
                                        onDeleted={(value: any) => {
                                            let removeElem = values.locationTypes.filter((elem) => elem !== value);
                                            setFieldValue('greenRatings', removeElem);
                                        }}
                                        selected={Array.isArray(values.locationTypes) ? values.locationTypes : []}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <SingleSelect
                                        label={t('f:property.buildingClass')}
                                        placeholder={t('f:property.selectBuildingClass')}
                                        options={getContent('BuildingClass')}
                                        onSelected={(value: any) => setFieldValue('buildingClassId', value)}
                                        selected={values.buildingClassId ? values.buildingClassId : null}
                                    />
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <SingleSelect
                                        label={t('f:property.buildingStatus')}
                                        placeholder={t('f:property.selectBuildingStatus')}
                                        options={getContent('BuildingStatus')}
                                        onSelected={(value: any) => setFieldValue('buildingStatusId', value)}
                                        selected={values.buildingStatusId ? values.buildingStatusId : null}
                                    />
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <label htmlFor="majorTenants">{t('f:property.majorTenants')}</label>
                                    <Field
                                        id="majorTenants"
                                        name="majorTenants"
                                        placeholder={t('f:property.enterMajorTenants')}
                                    />
                                    {errors.majorTenants && touched.majorTenants ? (
                                        <span className="error-text-absolute">
                                            {t(errors.majorTenants, { name: t('f:property.majorTenants') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                                    <MultiSelect
                                        label={t('f:property.greenRating')}
                                        placeholder={t('f:property.selectGreenRating')}
                                        options={getContent('GreenRating')}
                                        onSelected={(value: any) =>
                                            setFieldValue('greenRatings', [...values.greenRatings, value])
                                        }
                                        onDeleted={(value: any) => {
                                            let removeElem = values.greenRatings.filter((elem) => elem !== value);
                                            setFieldValue('greenRatings', removeElem);
                                        }}
                                        selected={Array.isArray(values.greenRatings) ? values.greenRatings : []}
                                    />
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group hidden">
                                    <label htmlFor="map.lat">{t('f:property.mapLatitude')}</label>
                                    <Field id="map.lat" name="map.lat" placeholder={t('f:property.enterMapLatitude')} />
                                    {errors.map && errors.map.lat && touched.map ? (
                                        <span className="error-text-absolute">
                                            {t(errors.map.lat, { name: t('f:property.mapLatitude') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group hidden">
                                    <label htmlFor="map.long">{t('f:property.mapLongitude')}</label>
                                    <Field
                                        id="map.long"
                                        name="map.long"
                                        placeholder={t('f:property.enterMapLongitude')}
                                    />
                                    {errors.map && errors.map.long && touched.map ? (
                                        <span className="error-text-absolute">
                                            {t(errors.map.long, { name: t('f:property.mapLongitude') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-md-12 mt-20 ml-15">
                                    <MainHeading text={t('f:property.location')} />
                                </div>

                                <div className="col-md-4 col-xxl-4 form-group">
                                    <SingleSelect
                                        label={`${t('f:property.map.country')}*`}
                                        placeholder={t('f:property.map.country')}
                                        options={countries.filter(country => country.isActive)}
                                        selected={values.map.countryId ? values.map.countryId : null}
                                        onSelected={(value: any) => {
                                            setFieldValue('map.countryId', value);
                                            let obj: any = _.find(countries, { id: value });
                                            setCountryCode(obj.isoCode);
                                        }}
                                    />
                                    {errors.map && errors.map.countryId && touched.map && touched.map.countryId ? (
                                        <span className="error-text-absolute">
                                            {t(errors.map.countryId, { name: t('f:property.map.country') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-md-8 col-xxl-4 form-group">
                                    <label htmlFor="map.address">{`${t('f:property.mapAddress')}*`}</label>
                                    <div className="property__dropdown-container">
                                        <Field
                                            id="map.address"
                                            name="map.address"
                                            placeholder={t('f:property.enterMapAddress')}
                                            onChange={(e: any) => {
                                                setFieldValue('map.address', e.target.value);
                                                handleSearch(e.target.value);
                                                !_.isEqual(e.target.value, typedAddress)
                                                    ? setLocationSelected(false)
                                                    : setLocationSelected(true);
                                            }}
                                            className="col-xs-12"
                                        />
                                        {options.length && !locationSelected ? (
                                            <div className="property__dropdown-main">
                                                <ul className="property__dropdown-list d-flex flex-direction-column">
                                                    {options.map((elem: any, i: number) => {
                                                        return (
                                                            <div
                                                                className="ant-select-item ant-select-item-option ant-select-item-option-content ant-select-item-option-active"
                                                                onClick={(value: any) => {
                                                                    setFieldValue(
                                                                        'map.address',
                                                                        `${elem.country}, ${elem.city}, ${
                                                                            elem.streetName
                                                                        }, ${checkStreetNumber(elem.streetNumber)}`
                                                                    );
                                                                    setFieldValue('map.long', elem.longitude);
                                                                    setFieldValue('map.lat', elem.latitude);
                                                                    setTypedAddress({
                                                                        long: elem.longitude,
                                                                        lat: elem.latitude,
                                                                        address: `${elem.country}, ${elem.city}, ${
                                                                            elem.streetName
                                                                        }, ${checkStreetNumber(elem.streetNumber)}`,
                                                                    });
                                                                    setLocationSelected(true);
                                                                }}
                                                            >
                                                                <div className="ant-select-item-option-content">
                                                                    <div className="select-checkbox"></div>
                                                                    {`${elem.country}, ${elem.city}, ${
                                                                        elem.streetName
                                                                    }, ${checkStreetNumber(elem.streetNumber)}`}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        ) : null}
                                    </div>
                                    {errors.map && errors.map.address && touched.map ? (
                                        <span className="error-text-absolute">
                                            {t(errors.map.address, { name: t('f:property.address') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-md-11 form-group mt-20">
                                    <div className="mt-20 mb-20">
                                        {typedAddress ? (
                                            <PropertyAssetMap
                                                locations={[]}
                                                shouldCluster={false}
                                                showPopup={true}
                                                showOnlyAddress={true}
                                                allowInteraction={true}
                                                location={typedAddress}
                                                onMapClicked={(e:any) => onMapClicked(e, setFieldValue)}
                                            ></PropertyAssetMap>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-12 mt-20 ml-15">
                                    <MainHeading text={t('f:property.financialInformation')} />
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4  form-group">
                                    <label htmlFor="passingRent">{t('f:property.passingRent')}</label>
                                    <MyNumberInput
                                        placeholder={t('f:property.enterPassingRent')}
                                        value={values.passingRent}
                                        onValueChange={(val) => setFieldValue('passingRent', val.floatValue)}
                                    />
                                    {errors.passingRent && touched.passingRent ? (
                                        <span className="error-text-absolute">
                                            {t(errors.passingRent, { name: t('f:property.passingRent') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4  form-group">
                                    <label htmlFor="netOperatingIncome">{t('f:property.netOperatingIncome')}</label>
                                    <MyNumberInput
                                        placeholder={t('f:property.enterNetOperatingIncome')}
                                        value={values.netOperatingIncome}
                                        onValueChange={(val) => {
                                            setFieldValue('netOperatingIncome', val.floatValue);
                                        }}
                                    />

                                    {errors.netOperatingIncome && touched.netOperatingIncome ? (
                                        <span className="error-text-absolute">
                                            {t(errors.netOperatingIncome, { name: t('f:property.netOperatingIncome') })}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-xs-12 col-md-6 col-xxl-4  form-group">
                                    <InputStake
                                        label={`${t('f:property.capitalisationRate')} (%)`}
                                        name="capitalisationRate"
                                        values={values['capitalisationRate']}
                                        errors={errors}
                                        touched={touched}
                                        classes="d-flex flex-direction-column"
                                    />
                                    {/* <label htmlFor="capitalisationRate">{t('f:property.capitalisationRate')} (%)</label>
                                    <Field
                                        id="capitalisationRate"
                                        name="capitalisationRate"
                                        placeholder={t('f:property.enterCapitalisationRate')}
                                        onChange={(e: any) => {
                                            setFieldValue('capitalisationRate', e.target.value);
                                            setAssetValue(
                                                isNaN(calculateAssetValue(values.netOperatingIncome, e.target.value))
                                                    ? ''
                                                    : calculateAssetValue(
                                                          values.netOperatingIncome,
                                                          e.target.value
                                                      ).toString()
                                            );
                                        }}
                                    /> */}
                                    {errors.capitalisationRate && touched.capitalisationRate ? (
                                        <span className="error-text-absolute">
                                            {t(errors.capitalisationRate, { name: t('f:property.capitalisationRate') })}
                                        </span>
                                    ) : null}
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4  form-group">
                                    <label>{t('f:property.assetValue')}</label>
                                    <MyNumberInput
                                        placeholder={t('f:property.enterPassingRent')}
                                        value={values['assetValue']}
                                        onValueChange={(val) => {
                                            setFieldValue('assetValue', val.floatValue);
                                        }}
                                    />
                                </div>

                                <div className="col-xs-12">
                                    <MainHeading text={t('f:property.coverPhoto')} />
                                </div>

                                <div className="col-xs-12 col-md-6 col-xxl-4 form-group mb-100 relative">
                                    {image ? (
                                        <img src={image} className="newsletter-builder__def-image" alt="property" />
                                    ) : (
                                        <br />
                                    )}
                                    <ImgCrop rotate aspect={36 / 13} quality={1}>
                                        <Upload
                                            name="Image"
                                            className="add-property-image-builder__upload"
                                            showUploadList={false}
                                            action={process.env.REACT_APP_API_URL + `/propertyasset/image`}
                                            customRequest={(options: any) => {
                                                customRequest(options, setFieldValue);
                                            }}
                                        >
                                            <Tooltip
                                                placement="bottomRight"
                                                color="#fd615a"
                                                title={t('f:property.photoDimensionRecommended')}
                                            >
                                                <Button>
                                                    <p className="teams-export__icon">{t('f:button.edit')}</p>
                                                </Button>
                                            </Tooltip>
                                        </Upload>
                                    </ImgCrop>
                                </div>
                                <ListOpportunityFooterBtns
                                    isNotFirst={false}
                                    isNotFinish={true}
                                    cancelUrl={`/property/${propertySummary.externalId}`}
                                    onNextHandler={() => {
                                        setFormToSend(true);
                                        validateForm().then(() => scrollToErrors(errors));
                                    }}
                                />
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <MainLoader type="regular" mt="mt-100" />
                )}
                <ModalImportLoading text={t(`f:loaders.editing`)} isModalOpened={isPropertyEditing} />
            </div>
        </AuthenticatedTemplate>
    );
};
