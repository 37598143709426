import { FunctionComponent } from 'react';
import SingleSelect from '../../../components/shared/SingleSelect';
import { useTranslation } from 'react-i18next';
import LookUpState from '../../../context/state/LookUpState';
import { useContext } from 'react';

interface RadioI {
    value: string;
    label: string;
}

interface OverviewFiltersProps {
    onSelectedCountry: (value?: string) => void;
    onSelectedSector: (value?: string) => void;
    radioFilters?: Array<RadioI>;
    setFilter?: (prev: string) => void;
    filter?: string;
    selectedSector?: any;
    selectedCountry?: any;
}

const OverviewFilters: FunctionComponent<OverviewFiltersProps> = ({
    onSelectedCountry,
    selectedCountry,
    radioFilters = [],
    setFilter = () => {},
    filter,
    selectedSector,
    onSelectedSector,
}: OverviewFiltersProps) => {
    const { t } = useTranslation(['f', 'b']),
        { countries, getContent } = useContext(LookUpState);

    return (
        <div className="col-xs-12 d-flex pl-0">
            {radioFilters.map(({ value, label }) => (
                <div className="d-flex listing-type__form mr-20 mt-30 pl-0">
                    <input
                        type="radio"
                        id="team"
                        name="fiter"
                        value={value}
                        checked={filter === value}
                        className="mr-10 mt-3 pl-0"
                        onChange={() => {
                            setFilter(value);
                        }}
                    />
                    <div className="d-flex flex-direction-column">
                        <label htmlFor="hide" className="listing-type__form-label listing-type__form-label--small">
                            {label}
                        </label>
                    </div>
                </div>
            ))}

            <div className="col-xs-4 pl-0">
                <SingleSelect
                    label={`${t('f:exploreDataPage.sideMenu.country')}`}
                    placeholder={t('f:exploreDataPage.sideMenu.selectCountry')}
                    options={countries.filter(country => country.isActive)}
                    onSelected={onSelectedCountry}
                    selected={selectedCountry}
                    allowClear={true}
                    onClear={() => onSelectedCountry(undefined)}
                />
            </div>
            <div className="col-xs-4">
                <SingleSelect
                    label={`${t('f:exploreDataPage.sideMenu.sector')}`}
                    placeholder={t('f:exploreDataPage.sideMenu.selectSector')}
                    options={getContent('Sector')}
                    onSelected={onSelectedSector}
                    selected={selectedSector}
                    allowClear={true}
                    onClear={() => onSelectedSector(undefined)}
                />
            </div>
        </div>
    );
};
export default OverviewFilters;
