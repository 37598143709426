import { MainTheme } from '../interfaces/theme';

export const COLORS = {
    mainColor1: '#efefef',
    mainColor2: '#a1abbd',
    mainColor3: '#1c4050',
    mainColor3Op: '#1c4050f1',
    mainColor3Op70: '#1c405070',
    mainColor3Op50: '#1c40507c',
    mainColor4: '#6e7189',
    mainColor5: '#fd615a',
    mainColor5Op: '#fd615a6e',
    mainColor6: '#f7fdfc',
    mainColor7: '#ffffff',
    mainColor8: '#0f242f',
    mainColor8Op: '#0f242ff3',
    mainColor9: '#c6cbd4',
    mainColor9Op: '#c6cbd4f8',
    mainColor13: '#3ac184',
    mainColor13Op: '#3ac18470',
    mainColor14: '#def3ea',
    mainColor16: '#fec4c2',
    mainColor17: '#54617a',
    mainColor20: '#bfc5d2',
    mainColor22: '#488dff',
    mainColor22Op: '#488dffea',
    mainColor26: '#a7b9f0',
    mainColor27: '#b2b2b2',
    mainColor28: '#6d7278',
    mainColor28Op: '#6d727852',
    mainColor30: '#797c95',
    mainColor35: '#8798ad',
    mainColor43: '#789e9e',
    mainColor46: '#4d6466',
    mainColor47: '#b7d8d6',
    mainColor48: '#8c9097',
    mainColor49: '#959799',
    mainColor50: '#01010103',
};

export const DEFAULTCOLORS = {
    '--mainColor1': COLORS['mainColor1'],
    '--mainColor2': COLORS['mainColor2'],
    '--mainColor3': COLORS['mainColor3'],
    '--mainColor3Op': COLORS['mainColor3Op'],
    '--mainColor3Op70': COLORS['mainColor3Op70'],
    '--mainColor4': COLORS['mainColor4'],
    '--mainColor5': COLORS['mainColor5'],
    '--mainColor5Op': COLORS['mainColor5Op'],
    '--mainColor6': COLORS['mainColor6'],
    '--mainColor7': COLORS['mainColor7'],
    '--mainColor8': COLORS['mainColor8'],
    '--mainColor8Op': COLORS['mainColor8Op'],
    '--mainColor9': COLORS['mainColor9'],
    '--mainColor9Op': COLORS['mainColor9Op'],
    '--mainColor13': COLORS['mainColor13'],
    '--mainColor13Op': COLORS['mainColor13Op'],
    '--mainColor13Op50': COLORS['mainColor13Op50'],
    '--mainColor14': COLORS['mainColor14'],
    '--mainColor16': COLORS['mainColor16'],
    '--mainColor17': COLORS['mainColor27'],
    '--mainColor20': COLORS['mainColor20'],
    '--mainColor22': COLORS['mainColor22'],
    '--mainColor22Op': COLORS['mainColor22Op'],
    '--mainColor26': COLORS['mainColor26'],
    '--mainColor27': COLORS['mainColor27'],
    '--mainColor28': COLORS['mainColor28'],
    '--mainColor28Op': COLORS['mainColor28Op'],
    '--mainColor30': COLORS['mainColor30'],
    '--mainColor35': COLORS['mainColor35'],
    '--mainColor43': COLORS['mainColor43'],
    '--mainColor46': COLORS['mainColor46'],
    '--mainColor47': COLORS['mainColor47'],
    '--mainColor48': COLORS['mainColor48'],
    '--mainColor49': COLORS['mainColor49'],
    '--mainColor50': COLORS['mainColor50'],
};
export const THEME_COLORS = {
    '--main-bg-color': COLORS['mainColor3Op'],
    '--main-text': COLORS['mainColor6'],
    '--main-text-op': `${COLORS['mainColor6']}6e`,
    '--buton-border-color': COLORS['mainColor8'],
    '--asset-bar-text': COLORS['mainColor3'],
};
export const DEFAULTTHEME: MainTheme = {
    name: 'default',
    id: '0',
    colors: THEME_COLORS,
    logo: 'https://virgatedatadev.blob.core.windows.net/themes/6/assets/logo_1647255841.png',
    lightLogo: 'https://virgatedatadev.blob.core.windows.net/themes/6/assets/logo-w_1647255797.png',
    loader: 'https://virgatedatadev.blob.core.windows.net/themes/6/assets/main-loader_1647255678.mp4',
};
