import PropertyApi from '../api/routes/PropertyApi';
import PropertyCreate from '../interfaces/property/PropertyCreate';
import PropertyEdit from '../interfaces/property/PropertyEdit';
import moment from 'moment';
import { OverviewPropertyAssetSearch } from '../interfaces/property/OverviewPropertyAssetSearch';

interface PropertyEditExtended {
    property: PropertyEdit;
    deleteOwners: Array<number>;
}
interface Owner {
    name: string;
    share: number;
}

const fetch = async (id: number): Promise<any> => {
    const { data } = await PropertyApi.fetchProperty(id);
    return data;
};

const create = async (data: PropertyCreate): Promise<any> => {
    try {
        delete data['MapSubSectors'];
        convertEmptyStringtoNull(data);
        data.sectors = data.mainSectorId ? data.subSectors.concat(data.mainSectorId) : data.subSectors;

        const response = await PropertyApi.createProperty(data);

        return response.data;
    } catch (err) {
        return Promise.reject(err);
    }
};

const fetchMyProperty = async (searchText: string, currentPage: number, pageSize: number) => {
    const { data } = await PropertyApi.fetchMyProperty(searchText, currentPage, pageSize);
    return data;
};

const fetchMyOverviewProperty = async (params: OverviewPropertyAssetSearch) => {
    params.page = params.page ?? 0;
    const { data } = await PropertyApi.fetchMyOverviewProperty(params);
    return data;
};

const fetchOverviewSummary = async () => {
    const { data } = await PropertyApi.fetchOverviewSummary();
    return data;
};

const update = async (data: PropertyEditExtended) => {
    delete data.property['MapSubSectors'];
    data.property = convertEmptyStringtoNull(data.property);

    const newOwn: Array<Owner> = [],
        updateOwn: Array<Owner> = [];

    data.property.owners.forEach((element) => {
        element.share = element.share - 1;
        element.share = Number((element.share / 100).toFixed(3));
        if (element['id']) {
            updateOwn.push(element);
        } else {
            newOwn.push(element);
        }
    });

    data.property.sectors = data.property.mainSectorId
        ? data.property.subSectors.concat(data.property.mainSectorId)
        : data.property.subSectors;

    await PropertyApi.updateProperty({
        ...data.property,
        deleteOwners: data.deleteOwners,
        updateOwners: updateOwn,
        newOwners: newOwn,
    });

    return data;
};

function isMoment(obj: any) {
    return obj instanceof moment || (obj != null && obj._isAMomentObject != null);
}

const convertEmptyStringtoNull = (data: any) => {
    for (const [key, value] of Object.entries(data)) {
        if (!value) {
            delete data[key];
        }

        if (typeof value === 'object' && !isMoment(value)) {
            convertEmptyStringtoNull(value);
        }
    }
    return data;
};

const fetchMostRecent = async (numberOfAssets: string) => {
    const { data } = await PropertyApi.fetchMostRecent(numberOfAssets);
    return data;
};

const deleteProperty = async (propertyAssetId: string) => {
    const { data } = await PropertyApi.deleteProperty(propertyAssetId);
    return data;
};

const shereProperty = async (propertyAssetId: string) => {
    const { data } = await PropertyApi.shereProperty(propertyAssetId);
    return data;
};

const PropertyService = {
    create,
    fetch,
    update,
    fetchMyProperty,
    fetchMostRecent,
    fetchMyOverviewProperty,
    fetchOverviewSummary,
    deleteProperty,
    shereProperty,
};

export default PropertyService;
