export interface ProjectTeamMeber {
    addedTime: Date;
    roleInProject: number;
    user: ProjectTeamMemberUser;
}

export interface UserModal {
    name: string;
    address: string;
    company: string;
    email: string;
    phone: string;
    roleType: string;
    editor: boolean;
}

export const initalValuesUser: UserModal = {
    name: '',
    address: '',
    company: '',
    email: '',
    phone: '',
    roleType: '',
    editor: false,
};

export interface ProjectTeamMemberUser {
    address: string;
    alternativePhoneNumber: string;
    company: string;
    email: string;
    fullName: string;
    id: string;
    phoneNumber: string;
    role: number;
    username: string;
}

export enum defaultSelectedCompany {
    ALL = 'All',
}
export enum ProjetTeamTypes {
    TEAMMEMBER = 'TeamMembers',
    USER = 'User',
}

export const initalProjectTeamMemberUser: ProjectTeamMemberUser = {
    id: '',
    username: '',
    email: '',
    fullName: '',
    phoneNumber: '',
    alternativePhoneNumber: '',
    address: '',
    role: 0,
    company: '',
};
