import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyWithInvestorPagination } from '../../../../../interfaces/investor/CompanyWithInvestor';
import SingleItem from './component/SingleItem';
import MainTableHeadCell from './component/MainTableHeadCell';
import { TableTypes } from '../../../../../constants/wizardSteps';
import MainCheckbox from '../../../../../components/shared/MainCheckbox';
import LOWizardState from '../../../../../context/state/LOWizardState';

interface CustomTableProps {
    data: CompanyWithInvestorPagination;
    setInvestorsPage: (prev: number) => void;
    investorsPage: number;
    type: TableTypes.INVESTORS_TAB | TableTypes.LIST_OPPORTUNITY;
    pageSize: number;
    refetch: () => void;
}

const CustomTable: FunctionComponent<CustomTableProps> = ({
    data,
    setInvestorsPage,
    investorsPage,
    type,
    pageSize,
    refetch,
}: CustomTableProps) => {
    const { t } = useTranslation(['f', 'b']),
        { isAllUsersSelected, isAllUsersSelectedActions, setSelectedUsersId } = useContext(LOWizardState),
        [childTableId, setChildTableId] = useState<number | null>(null);

    const arrow = (path: boolean) => (
        <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24">
            {path ? (
                <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" fill="#e0e0e0" />
            ) : (
                <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" fill="#e0e0e0" />
            )}
        </svg>
    );

    const handleSelectAll = useCallback(() => {
        isAllUsersSelectedActions.toggle();
    }, [isAllUsersSelectedActions]);

    useEffect(() => {
        if (isAllUsersSelected) {
            const emails: Array<string> = [];
            // data.items.forEach((el) => {
            //     el.users.forEach((elem) => {
            //         emails.push(elem['email']);
            //     });
            // });
            setSelectedUsersId(emails);
        } else {
            setSelectedUsersId([]);
        }
    }, [isAllUsersSelected, setSelectedUsersId]);

    return (
        <div className="col-xs-12-clear investors-table">
            <div className="investors-table__head">
                <div className="d-flex investors-table__row">
                    {type === TableTypes.LIST_OPPORTUNITY && (
                        <MainCheckbox onClick={handleSelectAll} checked={isAllUsersSelected} />
                    )}
                    <MainTableHeadCell text={t('f:teams.companyColumn')} isFirst={true} />
                    <MainTableHeadCell text={t('f:teams.ndaStatus')} />
                    <MainTableHeadCell text={t('f:teams.bidStatus')} />
                    {type === TableTypes.INVESTORS_TAB && (
                        <MainTableHeadCell text={t('f:teams.actions')} isActions={true} />
                    )}
                </div>
            </div>
            <div className="investors-table__body">
                {data.items.map((elem) => (
                    <div key={elem.id}>
                        <SingleItem
                            element={elem}
                            childTableId={childTableId}
                            setChildTableId={setChildTableId}
                            type={type}
                            refetch={refetch}
                        />
                    </div>
                ))}
            </div>
            <div className="investors-table__paggination d-flex">
                <div className="d-flex investors-table__paggination-main align-items-center ml-auto mt-20">
                    <p className="MuiDataGrid-footerContainer MuiTypography-body2 investors-table__paggination-main-text mr-30">
                        {investorsPage + 1}-{Number(data.totalCount / pageSize).toFixed(0)} of {data.totalCount}
                    </p>
                    <div
                        className="investors-table__paggination-arrow--container"
                        onClick={() => {
                            if (investorsPage === 0) return;
                            setInvestorsPage(investorsPage - 1);
                        }}
                    >
                        <div className="investors-table__paggination-arrow">{arrow(true)}</div>
                    </div>
                    <div
                        className="investors-table__paggination-arrow--container"
                        onClick={() => {
                            if (Number((data.totalCount / pageSize).toFixed(0)) === investorsPage + 1) return;
                            setInvestorsPage(investorsPage + 1);
                        }}
                    >
                        <div className="investors-table__paggination-arrow">{arrow(false)}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomTable;
