import React, { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useBoolean from '../hooks/use-boolean';
import { TransactionDataInfo } from '../pages/TransactionData/models/transaction-data-info.model';
import TransactionDataService from '../services/TransactionDataService';
import TransactionDataState from './state/TransactionDataState';

interface Props {
    children: React.ReactNode;
}

const TransactionDataContext: FunctionComponent<Props> = ({ children }) => {
    const [transactionData, setTransactionData] = useState<TransactionDataInfo>(),
        { t } = useTranslation(['f', 'b']),
        [isLoading, IsLoadingActions] = useBoolean();

    const fetchTransactionData = useCallback(
        async (id: string) => {
            IsLoadingActions.setTrue();
            try {
                const response = await TransactionDataService.fetchTransactionDataById(id);
                setTransactionData(response);
            } catch (error: any) {
                toast(t(`b:${error.response.data.errorMessage}`));
            }
            IsLoadingActions.setFalse();
        }, []);

    return (
        <TransactionDataState.Provider
            value={{
                fetchTransactionData,
                setTransactionData,
                transactionData,
                isLoading
            }}
        >
            {children}
        </TransactionDataState.Provider>
    );
};

export default TransactionDataContext;
