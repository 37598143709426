import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainHeading from '../../../../components/shared/MainHeading';
import { PROPERTY_TABS } from '../../../../constants/propertyTab';
import { LoWizardStepNames } from '../../../../constants/wizardSteps';
import FeatureState from '../../../../context/state/FeatureState';
import LOWizardState from '../../../../context/state/LOWizardState';
import ListOpportunityFooterBtns from '../partials/ListOpportunityFooterBtns';
import UsersWhoCanApproveTable from '../partials/UsersWhoCanApproveTable';

const Approvals: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { changeDestination } = useContext(LOWizardState),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        history = useHistory();

    const handleFinish = useCallback(() => {
        history.push(`/property/${externalId}`);
    }, [history, externalId]);

    const returnToPrev = useCallback(() => {
        changeDestination(LoWizardStepNames.PAYMENT_ACKNOWLEDGEMENT);
    }, [changeDestination]);

    return (
        <div className="wizard-container-steps">
            <MainHeading text={t('f:listOpportunity.publish')} />
            <UsersWhoCanApproveTable />
            <ListOpportunityFooterBtns
                cancelUrl={`/property/${externalId}#${PROPERTY_TABS.PROPERTY_SUMMARY.name}`}
                isNotFirst={true}
                isNotFinish={true}
                onNextHandler={handleFinish}
                returnToPreviousPage={returnToPrev}
            />
        </div>
    );
};

export default Approvals;
