import { FunctionComponent, useContext } from 'react';
import TransactionDataState from '../../../context/state/TransactionDataState';
import TransactionTableItem from './TransactionTableItem';

const TransactionTable: FunctionComponent = () => {
    const { transactionData } = useContext(TransactionDataState);
    const data = transactionData!;

    return (
        <>
            <section className="module-intro transaction-data">
                <div className="content-text">
                    <h1 className="trade-probability-title">Property Details</h1>
                    <ul className="list">
                        <TransactionTableItem title='Property Name' value={data.propertyName} />
                        <TransactionTableItem title='Address' value={data.address} />
                        <TransactionTableItem title='Postcode' value={data.postcode} />
                        <TransactionTableItem title='Country' value={data.country} />
                        <TransactionTableItem title='City' value={data.city} />
                        <TransactionTableItem title='State' value={data.state} />
                        <TransactionTableItem title='CBD' value={data.cbd} />
                        <TransactionTableItem title='Main Sector' value={data.mainSector} />
                        <TransactionTableItem title='Tenancy' value={data.tenancy} />
                        <TransactionTableItem title='Year Built' value={data.yearBuilt} />
                        <TransactionTableItem title='Number of Buildings' value={data.numberBuildings} />
                        <TransactionTableItem title='Number of Floors' value={data.numberFloors} />
                    </ul>
                </div>
                <div className="content-text">
                    <div>
                        <h1 className="trade-probability-title">Transaction Details</h1>
                        <ul className="list">
                            <TransactionTableItem title='Transaction Type' value={data.transactionType} />
                            <TransactionTableItem title='Transaction Sub-Type' value={data.transactionSubType} />
                            <TransactionTableItem title='Buyer Objective' value={data.buyerObjective} />
                            <TransactionTableItem title='Excess Land' value={data.excessLand ? 'TRUE' : 'FALSE'} />
                            <TransactionTableItem title='Portfolio' value={data.portfolio} />
                            <TransactionTableItem title='Prior Sales Date' value={data.priorSalesDate ? new Date(data.priorSalesDate).toLocaleDateString() : ''} />
                            <TransactionTableItem title='Number of Properties' value={data.numberOfProperties} />
                            <TransactionTableItem title='Status' value={data.status} />
                            <TransactionTableItem title='Sale Date' value={data.saleDate ? new Date(data.saleDate).toLocaleDateString() : ''} />
                            <TransactionTableItem title='Occupancy' value={data.occupancy} />
                            <TransactionTableItem title='Asset Value' value={data.assetValue} type='number' />
                            <TransactionTableItem title='Sale Value' value={data.saleValue} type='number' />
                            <TransactionTableItem title='Cost per sqft' value={data.costPerSqFt} type='number' />
                            <TransactionTableItem title='Yield' value={data.yield} type='number' />
                            <TransactionTableItem title='SqFt' value={data.sqFt} type='number' />
                            <TransactionTableItem title='SqMeters' value={data.sqMeters} type='number' />
                        </ul>
                    </div>
                </div>
                <div className="content-text">
                    <div>
                        <h1 className="trade-probability-title">Buyer Details</h1>
                        <ul className="list">
                            <TransactionTableItem title='Buyer Name 1' value={data.buyerName1} />
                            <TransactionTableItem title='Buyer Group 1' value={data.buyerGroup1} />
                            <TransactionTableItem title='Buyer Type 1' value={data.buyerType1} />
                            <TransactionTableItem title='Buyer Country 1' value={data.buyerCountry1} />
                            <TransactionTableItem title='Buyer JV 1' value={data.buyerJV1} />
                            <TransactionTableItem title='Buyer Name 2' value={data.buyerName2} />
                            <TransactionTableItem title='Buyer Group 2' value={data.buyerGroup2} />
                            <TransactionTableItem title='Buyer Type 2' value={data.buyerType2} />
                            <TransactionTableItem title='Buyer Country 2' value={data.buyerCountry2} />
                            <TransactionTableItem title='Buyer JV 2' value={data.buyerJV2} />
                            <TransactionTableItem title='Buyer Name 3' value={data.buyerName3} />
                            <TransactionTableItem title='Buyer Group 3' value={data.buyerGroup3} />
                            <TransactionTableItem title='Buyer Type 3' value={data.buyerType3} />
                            <TransactionTableItem title='Buyer Country 3' value={data.buyerCountry3} />
                            <TransactionTableItem title='Buyer JV 3' value={data.buyerJV3} />
                            <TransactionTableItem title='Buyer Name 4' value={data.buyerName4} />
                            <TransactionTableItem title='Buyer Group 4' value={data.buyerGroup4} />
                            <TransactionTableItem title='Buyer Type 4' value={data.buyerType4} />
                            <TransactionTableItem title='Buyer Country 4' value={data.buyerCountry4} />
                        </ul>
                    </div>
                </div>
                <div className="content-text">
                    <div>
                        <h1 className="trade-probability-title">Seller Details</h1>
                        <ul className="list">
                            <TransactionTableItem title='Seller Name 1' value={data.sellerName1} />
                            <TransactionTableItem title='Seller Group 1' value={data.sellerGroup1} />
                            <TransactionTableItem title='Seller Type 1' value={data.sellerType1} />
                            <TransactionTableItem title='Seller Country 1' value={data.sellerCountry1} />
                            <TransactionTableItem title='Seller JV 1' value={data.sellerJV1} />
                            <TransactionTableItem title='Seller Name 2' value={data.sellerName2} />
                            <TransactionTableItem title='Seller Group 2' value={data.sellerGroup2} />
                            <TransactionTableItem title='Seller Type 2' value={data.sellerType2} />
                            <TransactionTableItem title='Seller Country 2' value={data.sellerCountry2} />
                            <TransactionTableItem title='Seller JV 2' value={data.sellerJV2} />
                            <TransactionTableItem title='Seller Name 3' value={data.sellerName3} />
                            <TransactionTableItem title='Seller Group 3' value={data.sellerGroup3} />
                            <TransactionTableItem title='Seller Type 3' value={data.sellerType3} />
                            <TransactionTableItem title='Seller Country 3' value={data.sellerCountry3} />
                            <TransactionTableItem title='Seller JV 3' value={data.sellerJV3} />
                            <TransactionTableItem title='Seller Name 4' value={data.sellerName4} />
                            <TransactionTableItem title='Seller Group 4' value={data.sellerGroup4} />
                            <TransactionTableItem title='Seller Type 4' value={data.sellerType4} />
                            <TransactionTableItem title='Seller Country 4' value={data.sellerCountry4} />
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TransactionTable;