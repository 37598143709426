import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEventHandler, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface FaqSearchInputProps {
    handleSearchInputChange: ChangeEventHandler<HTMLInputElement>;
}

const FaqSearchInput: FunctionComponent<FaqSearchInputProps> = ({ handleSearchInputChange }) => {
    const { t } = useTranslation(['f', 'b']);
    return (
        <form className="search-form search-form--flex-1 d-flex">
            <input
                type="text"
                placeholder={t('f:faq.anyQuestionsPlaceholder')}
                defaultValue=""
                onChange={handleSearchInputChange}
            />
            <button className="button-main button-primary" type="submit">
                <FontAwesomeIcon icon={['fas', 'search']} size="lg" />
            </button>
        </form>
    );
};
export default FaqSearchInput;
