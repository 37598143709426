import { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const IncomeValidation = (props: any) => {
    const chartRef = useRef<am4charts.XYChart | null>(null);

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);
        // Themes end

        var chart = am4core.create('incomeValidation', am4charts.XYChart);
        // some extra padding for range labels
        chart.paddingBottom = 50;

        chart.cursor = new am4charts.XYCursor();
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.fixRange({ start: 0.1, end: 0.2 });

        // will use this to store colors of the same items
        var colors = {};

        var categoryAxis: any = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataItems.template.text = '{realName}';
        categoryAxis.adapter.add('tooltipText', function (tooltipText, target) {
            return categoryAxis.tooltipDataItem.dataContext.realName;
        });

        var valueAxis: any = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.min = 0;

        // single column series for all data
        var columnSeries = chart.series.push(new am4charts.ColumnSeries());
        columnSeries.columns.template.width = am4core.percent(80);
        columnSeries.tooltipText = '{provider}: {realName}, {valueY}';
        columnSeries.dataFields.categoryX = 'category';
        columnSeries.dataFields.valueY = 'value';

        // second value axis for quantity
        var valueAxis2: any = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis2.renderer.opposite = true;
        valueAxis2.syncWithAxis = valueAxis;
        valueAxis2.tooltip.disabled = true;

        // fill adapter, here we save color value to colors object so that each time the item has the same name, the same color is used
        columnSeries.columns.template.adapter.add('fill', function (fill, target: any) {
            var name = target.dataItem.dataContext.realName;
            if (!colors[name]) {
                colors[name] = chart.colors.next();
            }
            target.stroke = colors[name];
            return colors[name];
        });

        var rangeTemplate = categoryAxis.axisRanges.template;
        rangeTemplate.tick.disabled = false;
        rangeTemplate.tick.location = 0;
        rangeTemplate.tick.strokeOpacity = 0.6;
        rangeTemplate.tick.length = 60;
        rangeTemplate.grid.strokeOpacity = 0.5;
        rangeTemplate.label.tooltip = new am4core.Tooltip();
        rangeTemplate.label.tooltip.dy = -10;
        rangeTemplate.label.cloneTooltip = false;

        ///// DATA
        var chartData: any = [];
        var lineSeriesData = [];

        var data = {
            '9.3': {
                '3.2%': 2675838780,
                '3.3%': 2594752756,
                '3.4%': 2518436499,
                '3.5%': 2446481170,
                '3.6%': 2378523360,
                '3.7%': 2314238945,
            },
            '9.6': {
                '3.2%': 2762156160,
                '3.3%': 2678454458,
                '3.4%': 2599676386,
                '3.5%': 2525399918,
                '3.6%': 2455249920,
                '3.7%': 2388891814,
            },
            '9.9': {
                '3.2%': 2848473540,
                '3.3%': 2762156160,
                '3.4%': 2680916273,
                '3.5%': 2604318665,
                '3.6%': 2531976480,
                '3.7%': 2463544683,
            },
            '10.2': {
                '3.2%': 2934790920,
                '3.3%': 2845857862,
                '3.4%': 2762156160,
                '3.5%': 2683237413,
                '3.6%': 2608703040,
                '3.7%': 2538197552,
            },
            '10.5': {
                '3.2%': 3021108300,
                '3.3%': 2929559564,
                '3.4%': 2843396047,
                '3.5%': 2762156160,
                '3.6%': 2685429600,
                '3.7%': 2612850422,
            },
            '10.8': {
                '3.2%': 3107425680,
                '3.3%': 3013261265,
                '3.4%': 2924635934,
                '3.5%': 2841074907,
                '3.6%': 2762156160,
                '3.7%': 2687503291,
            },
            '11.1': {
                '3.2%': 3193743060,
                '3.3%': 309662967,
                '3.4%': 3005875821,
                '3.5%': 2919993655,
                '3.6%': 2838882720,
                '3.7%': 2762156160,
            },
            '11.4': {
                '3.2%': 3280060440,
                '3.3%': 3180664669,
                '3.4%': 3087115708,
                '3.5%': 2998912402,
                '3.6%': 2915609280,
                '3.7%': 2836809029,
            },
        };

        // process data ant prepare it for the chart
        for (var providerName in data) {
            var providerData = data[providerName];

            // add data of one provider to temp array
            var tempArray: any = [];
            var count = 0;
            // add items
            for (var itemName in providerData) {
                if (itemName != 'quantity') {
                    count++;
                    // we generate unique category for each column (providerName + "_" + itemName) and store realName
                    tempArray.push({
                        category: providerName + '_' + itemName,
                        realName: itemName,
                        value: providerData[itemName],
                        provider: providerName,
                    });
                }
            }

            // add quantity and count to middle data item (line series uses it)
            var lineSeriesDataIndex = Math.floor(count / 2);
            tempArray[lineSeriesDataIndex].quantity = providerData.quantity;
            tempArray[lineSeriesDataIndex].count = count;
            // push to the final data
            am4core.array.each(tempArray, function (item: any) {
                chartData.push(item);
            });

            // create range (the additional label at the bottom)
            var range = categoryAxis.axisRanges.create();
            range.category = tempArray[0].category;
            range.endCategory = tempArray[tempArray.length - 1].category;
            range.label.text = tempArray[0].provider;
            range.label.dy = 30;
            range.label.truncate = true;
            range.label.fontWeight = 'bold';
            range.label.tooltipText = tempArray[0].provider;

            range.label.adapter.add('maxWidth', function (maxWidth, target) {
                var range = target.dataItem;
                var startPosition = categoryAxis.categoryToPosition(range.category, 0);
                var endPosition = categoryAxis.categoryToPosition(range.endCategory, 1);
                var startX = categoryAxis.positionToCoordinate(startPosition);
                var endX = categoryAxis.positionToCoordinate(endPosition);
                return endX - startX;
            });
        }

        chart.data = chartData;

        // last tick
        var range = categoryAxis.axisRanges.create();
        range.category = chart.data[chart.data.length - 1].category;
        range.label.disabled = true;
        range.tick.location = 1;
        range.grid.location = 1;

        return () => {
            chart.dispose();
        };
    }, []);

    return <div id="incomeValidation" style={{ width: '100%', height: '500px' }}></div>;
};

export default IncomeValidation;
