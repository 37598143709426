import capitaSpring from '../../../../../assets/images/dashboard/CapitaSpring.png';
import asiaSquare1 from '../../../../../assets/images/dashboard/asiaSquare1.jpg';
import asiaSquare2 from '../../../../../assets/images/dashboard/AsiaSquare2.jpg';
import oceanFinancialCentre from '../../../../../assets/images/dashboard/OceanFinancialCentre.jpg';
import oUEDowntown from '../../../../../assets/images/dashboard/OUEDowntown.jpg';
import oueBayFront from '../../../../../assets/images/dashboard/oueBayFront.jpg';
import capitaGreen from '../../../../../assets/images/dashboard/capitaGreen.jpg';
import mbfcTower from '../../../../../assets/images/dashboard/mbfcTower.jpg';
import mbfcTower2 from '../../../../../assets/images/dashboard/mbfcTower2.jpg';
import mbfcTower3 from '../../../../../assets/images/dashboard/mbfcTower3.jpg';
import duoTower from '../../../../../assets/images/dashboard/duoTower.jpg';
import frasersTower from '../../../../../assets/images/dashboard/frasersTower.jpg';

const properties = [
    {
        id: 1,
        propertyName: 'Asia Square (Tower 1)',
        imageUrl: asiaSquare1,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.8589646,
            lat: 1.2955848,
            address: '8 Marina View, Singapore, 018960',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2011',
        occupancy: 94,
        currentNoi: 77696000,
        passingRent: 8.7,
        assetValue: 4095056820,
        costPer: 3183,
        sectorNames: ['Office'],
        majorTenants: 'Amazon, British Gas, Citibank, Marsh & McLennan Companies, Royal Bank of Canada, White & Case',
        buildingScore: 96.45,
        netLeasableArea: 1286540,
        transactionDate: '6/6/16',
        transactionValue: 2477148902,
        noi: 79268765,
        typicalFloorPlate: 35000,
        capitalisationRate: 3.1,
        newCapitalisationRate: 3.2,
        interestSold: 100,
        buyer: 'Blackrock',
        seller: 'QIA',
    },
    {
        id: 2,
        propertyName: 'Asia Square (Tower 2)',
        imageUrl: asiaSquare2,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.861257,
            lat: 1.303499,
            address: '12 Marina View, Singapore 018961',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2013',
        occupancy: 96,
        currentNoi: 77696000,
        passingRent: 8.5,
        assetValue: 2428000000,
        costPer: 3061,
        sectorNames: ['Office'],
        majorTenants: 'Allianz, Mizuho Bank, SCOR Services, Swiss Reinsurance, Vodafone, Westpac',
        buildingScore: 96.21,
        netLeasableArea: 793089,
        transactionDate: '1/1/17',
        transactionValue: 1537288461,
        noi: 49193231,
        typicalFloorPlate: 31300,
        capitalisationRate: 3.2,
        newCapitalisationRate: 3.2,
        interestSold: 100,
        buyer: 'CapitaLand Commercial Trust',
        seller: 'Blackrock',
    },
    {
        id: 3,
        propertyName: 'Ocean Financial Centre',
        imageUrl: oceanFinancialCentre,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.8589646,
            lat: 1.2955848,
            address: '10 Collyer Quay',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2011',
        occupancy: 95,
        currentNoi: 73350100,
        passingRent: 9.2,
        assetValue: 2066200000,
        costPer: 2950,
        sectorNames: ['Office'],
        majorTenants: 'BNP Paribas, Trafigura',
        buildingScore: 93.25,
        netLeasableArea: 700505,
        transactionDate: '11/30/2018',
        transactionValue: 1571076500,
        noi: 50274448,
        typicalFloorPlate: 22504,
        capitalisationRate: 3.6,
        newCapitalisationRate: 3.2,
        interestSold: 33,
        buyer: 'Allianz',
        seller: 'Suntec REIT',
    },

    {
        id: 4,
        propertyName: 'OUE Downtown',
        imageUrl: oUEDowntown,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.8589646,
            lat: 1.2955848,
            address: '6 Shenton Way, Singapore',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2018',
        occupancy: 92,
        currentNoi: 39480000,
        passingRent: 9.0,
        assetValue: 940000000,
        costPer: 2120,
        sectorNames: ['Office'],
        majorTenants: 'Moodies',
        buildingScore: 89.76,
        netLeasableArea: 424475,
        transactionDate: '31/12/20',
        transactionValue: 900000000,
        noi: 38700000,
        typicalFloorPlate: 14000,
        capitalisationRate: 4.3,
        newCapitalisationRate: 3.2,
        interestSold: 33,
        buyer: 'Valuation',
        seller: 'Valuation',
    },

    {
        id: 5,
        propertyName: 'OUE Bayfront',
        imageUrl: oueBayFront,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.8589646,
            lat: 1.2955848,
            address: '50 Colliers Quay',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2011',
        occupancy: 100,
        currentNoi: 39480000,
        passingRent: 9.6,
        assetValue: 1271000000,
        costPer: 3179,
        sectorNames: ['Office'],
        majorTenants: 'Allan & Overy',
        buildingScore: 98.54,
        netLeasableArea: 399846,
        transactionDate: '3/31/21',
        transactionValue: 1181000000,
        noi: 38382500,
        typicalFloorPlate: 22214,
        capitalisationRate: 3.6,
        newCapitalisationRate: 3.3,
        interestSold: 50,
        buyer: 'Allianz',
        seller: 'OUE Commercial Trust',
    },

    {
        id: 6,
        propertyName: 'CapitaGreen',
        imageUrl: capitaGreen,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.8589646,
            lat: 1.2955848,
            address: '138 Market Street',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2017',
        occupancy: 94,
        currentNoi: 54774000,
        passingRent: 6.9,
        assetValue: 1691000000,
        costPer: 2298,
        sectorNames: ['Office'],
        majorTenants: 'Mitsubishi',
        buildingScore: 97.65,
        netLeasableArea: 701049,
        transactionDate: '31/12/20',
        transactionValue: 1611000000,
        noi: 53485200,
        typicalFloorPlate: 22000,
        capitalisationRate: 3.4,
        newCapitalisationRate: 3.3,
        buyer: 'Valuation',
        seller: 'Valuation',
    },
    {
        id: 7,
        propertyName: 'MBFC Tower 1',
        imageUrl: mbfcTower,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.8589646,
            lat: 1.2955848,
            address: '12 Marina Blvd',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2012',
        occupancy: 98,
        currentNoi: 56116587,
        passingRent: 9.6,
        assetValue: 1810212473,
        costPer: 3650,
        sectorNames: ['Office'],
        majorTenants: 'Standard Chartered',
        buildingScore: 93.99,
        netLeasableArea: 496000,
        transactionDate: '31/12/20',
        transactionValue: 2864086946,
        noi: 91650782,
        typicalFloorPlate: 20000,
        capitalisationRate: 3.2,
        newCapitalisationRate: 3.3,
        interestSold: 3,
        buyer: 'Valuation',
        seller: 'Valuation',
    },

    // {
    //     id: 8,
    //     propertyName: 'MBFC Tower 1',
    //     imageUrl: mbfcTower,
    //     country: 'Singapore',
    //     city: 'Singapore',
    //     map: {
    //         long: 103.85852,
    //         lat: 1.295285,
    //         address: '12 Marina Blvd'
    //     },
    //     landTenure: 'Leasehold',
    //     sector: 'Office',
    //     yearOfCompletion: '2012',
    //     occupancy: 98,
    //     currentNoi: 56116587,
    //     passingRent: 9.6,
    //     assetValue: 1810212473,
    //     costPer: 3650,
    //     sectorNames: ['Office'],
    //     majorTenants: 'Standard Chartered',
    //     buildingScore: 93.99,
    //     netLeasableArea: 496000,
    //     transactionDate: '31/12/20',
    //     transactionValue: 2864086946,
    //     noi: 91650782,
    //     typicalFloorPlate: 20000,
    //     capitalisationRate: 3.2,
    //     newCapitalisationRate: 3.3,
    //     interestSold: 33,
    //     buyer: 'Valuation',
    //     seller: 'Valuation',

    // },

    {
        id: 8,
        propertyName: 'MBFC Tower 2',
        imageUrl: mbfcTower2,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.85852,
            lat: 1.295285,
            address: '10 Marina Blvd',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2010',
        occupancy: 98,
        currentNoi: 92338163,
        passingRent: 9.8,
        assetValue: 2978650423,
        costPer: 3723,
        sectorNames: ['Office'],
        majorTenants: 'Standard Chartered',
        buildingScore: 94.23,
        netLeasableArea: 800000,
        transactionDate: '31/12/20',
        transactionValue: 2029050455,
        noi: 64929615,
        typicalFloorPlate: 25000,
        capitalisationRate: 3.1,
        newCapitalisationRate: 3.3,
        interestSold: 33,
        buyer: 'Valuation',
        seller: 'Valuation',
    },

    {
        id: 9,
        propertyName: 'MBFC Tower 3',
        imageUrl: mbfcTower3,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.85852,
            lat: 1.295285,
            address: '12 Marina Blvd',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2012',
        occupancy: 100,
        currentNoi: 115910747,
        passingRent: 9.3,
        assetValue: 3739056356,
        costPer: 3595,
        sectorNames: ['Office'],
        majorTenants: 'DBS',
        buildingScore: 92.89,
        netLeasableArea: 1040000,
        transactionDate: '12/6/14',
        transactionValue: 2864086946,
        noi: 91650782,
        typicalFloorPlate: 30000,
        capitalisationRate: 3.1,
        newCapitalisationRate: 3.2,
        interestSold: 33,
        buyer: 'Keppel REIT',
        seller: 'Keppel Land',
    },

    {
        id: 10,
        propertyName: 'Duo Tower',
        imageUrl: duoTower,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.85852,
            lat: 1.295285,
            address: '3 Fraser St',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2016',
        occupancy: 85,
        currentNoi: 36936628,
        passingRent: 8.0,
        assetValue: 1119291744,
        costPer: 2461,
        sectorNames: ['Office'],
        majorTenants: '',
        buildingScore: 84.32,
        netLeasableArea: 454730,
        transactionDate: '07/30/2019',
        transactionValue: 1065992137,
        noi: 36243733,
        typicalFloorPlate: 31000,
        capitalisationRate: 3.4,
        newCapitalisationRate: 3.2,
        interestSold: 100,
        buyer: 'Allianz, Gaw Capital',
        seller: 'Khazanah Nasional, Temasek',
    },

    {
        id: 11,
        propertyName: 'Frasers Tower',
        imageUrl: frasersTower,
        country: 'Singapore',
        city: 'Singapore',
        map: {
            long: 103.85852,
            lat: 1.295285,
            address: '182 Cecil St',
        },
        landTenure: 'Leasehold',
        sector: 'Office',
        yearOfCompletion: '2018',
        occupancy: 93,
        currentNoi: 36936628,
        passingRent: 8.6,
        assetValue: 1501632523,
        costPer: 2831,
        sectorNames: ['Office'],
        majorTenants: 'Microsoft, ',
        buildingScore: 89.32,
        netLeasableArea: 530400,
        transactionDate: '6/28/19',
        transactionValue: 1430126213,
        noi: 39328470,
        typicalFloorPlate: 20000,
        capitalisationRate: 3.4,
        newCapitalisationRate: 3,
        interestSold: 50,
        buyer: 'NPS',
        seller: 'Frasers Property',
    },
];

const showcase = {
    id: 100,
    propertyName: 'CapitaSpring',
    imageUrl: 'https://virgate-development.azurewebsites.net/static/media/building.png',
    map: {
        long: 103.861257,
        lat: 1.303499,
        address: '88 Market Street, Singapore, Singapore, 048940',
    },
    landTenure: 'Leasehold',
    sector: 'Office',
    yearOfCompletion: '2021',
    landArea: 100956,
    currentNoi: 77696000,
    passingRent: 12,
    assetValue: 2302898138,
    grossLeasableArea: 1009560,
    costPer: 3061,
    sectorNames: ['Office', 'Hotel', 'Retail'],
    majorTenants: 'JP Morgan, JLL',
    buildingScore: 96.21,
    netLeasableArea: 807648,
    noi: 75995639,
    occupancy: 75,
    typicalFloorPlate: 31300,
    capitalisationRate: 3.3,
    valuationDate: '2021',
};

export default {
    properties,
    showcase,
};
