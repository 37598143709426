import { FunctionComponent, useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
import { Formik, Field, Form } from 'formik';
import banner from '../../assets/images/bidBanner.png';
import handAndCoin from '../../assets/images/handAndCoin.svg';
import handsAndBag from '../../assets/images/handsAndBag.svg';
import { useTranslation } from 'react-i18next';
import PropertyAssetFeature from '../../services/PropertyAssetFeature';
import { toast } from 'react-toastify';
import BidService from '../../services/BidService';
import PropertyAssetFeture from '../../services/PropertyAssetFeature';
import { Button } from 'antd';
import CheckAuthorization from '../StatusPages/CheckAuthorization';
import FeatureState from '../../context/state/FeatureState';

const LandingPageBidWizard: FunctionComponent = () => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId, externalId } = propertySummary,
        [toggleJointeVenture, setToggleJointeVenture] = useState<boolean>(false),
        [property, setProperty] = useState<any>(),
        [isPortfolio, setIsPortfolio] = useState<boolean>(false),
        [currentBidId, setCurrentBidId] = useState<string>(),
        history = useHistory();

    const checkIsPortfolio = async () => {
        try {
            const property = await PropertyAssetFeture.fetchAssetHero(`${propertyAssetId}`);
            setIsPortfolio(property.isPortfolio);
        } catch (error: any) {
            toast(t(`f:${error.response.data.errorMessage}`));
        }
    };

    const fetchProperty = useCallback(async () => {
        try {
            const property = await PropertyAssetFeature.fetchAssetHero(`${propertyAssetId}`);
            setProperty(property);
        } catch (error: any) {
            toast(error.response);
        }
    }, []);

    const fetchCurrentBid = useCallback(async () => {
        if (property) {
            try {
                const propertyAssetStage = await BidService.fetchPropertyAssetStage(`${propertyAssetId}`);
                if (propertyAssetStage.pendingBiddingId) {
                    setCurrentBidId(propertyAssetStage.pendingBiddingId);
                }
            } catch (error: any) {
                toast(error.response);
            }
        }
    }, [property]);

    const saveToggle = (data: boolean) => {
        setToggleJointeVenture(data);
        if (localStorage.getItem('toggleJointeVenture')) {
            localStorage.removeItem('toggleJointeVenture');
        }
        localStorage.setItem('toggleJointeVenture', data.toString());
    };

    useEffect(() => {
        fetchCurrentBid();
    }, [property]);

    useEffect(() => {
        checkIsPortfolio();
        fetchProperty();
    }, []);

    return (
        <CheckAuthorization>
            <div className="row-flex">
                <div className="col-xs-6">
                    <figure className="margin-0">
                        <img src={banner} className="fix-img-to-100-height" alt={t('f:bid.registertoBid')} />
                    </figure>
                </div>
                <div className="col-xs-6 m-auto">
                    <h1 className="heading">{t('f:bid.registertoBid')}</h1>
                    <p className="secondary-text">{t('f:bid.subtitle')}</p>

                    <Formik
                        initialValues={{}}
                        onSubmit={() => {
                            history.push({
                                pathname: isPortfolio
                                    ? !currentBidId
                                        ? `/portfolio-bid-wizard/${externalId}/company-details`
                                        : `/portfolio-bid-wizard/${externalId}/company-details/${currentBidId}`
                                    : !currentBidId
                                    ? `/property/${externalId}/bid/company-details`
                                    : `/property/${externalId}/bid/company-details/${currentBidId}`,
                                state: {
                                    toggleJointeVenture: toggleJointeVenture,
                                },
                            });
                        }}
                    >
                        {({ submitForm }) => (
                            <Form className="row-flex-clear">
                                <label
                                    className="property__radio property-type__radio property__radio--bigger"
                                    onClick={() => {
                                        saveToggle(true);
                                    }}
                                >
                                    <Field type="radio" name="url" checked={toggleJointeVenture} />
                                    <div className="property__radio-option">
                                        <div className="d-flex justify-content-space-between">
                                            <figure>
                                                <img src={handsAndBag} alt="Single Asset" />
                                            </figure>
                                            <div className="property__radio-select"></div>
                                        </div>
                                        <h5>{t('f:bid.jointVenture')}</h5>
                                        <p>{t('f:bid.selectVenture')}</p>
                                    </div>
                                </label>

                                <label
                                    className="property__radio property-type__radio property__radio--bigger"
                                    onClick={() => {
                                        saveToggle(false);
                                    }}
                                >
                                    <Field type="radio" name="url" checked={!toggleJointeVenture} />
                                    <div className="property__radio-option">
                                        <div className="d-flex justify-content-space-between">
                                            <figure>
                                                <img src={handAndCoin} alt="Single Asset" />
                                            </figure>
                                            <div className="property__radio-select"></div>
                                        </div>
                                        <h5>{t('f:bid.individualPurchase')}</h5>
                                        <p>{t('f:bid.selectPur')}</p>
                                    </div>
                                </label>
                                <div className="col-xs-12-clear">
                                    <Button className="button-main button-primary" onClick={submitForm}>
                                        {t('f:button.getStarted')}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </CheckAuthorization>
    );
};
export default LandingPageBidWizard;
