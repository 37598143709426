import { PropertyAssetFeatures } from "./propertyAssetFeatures";

export const PROPERTY_TABS = {
    PROPERTY_SUMMARY: { id: PropertyAssetFeatures.PageSummary, name: 'property-summary' },
    CASH_FLOW: { id: PropertyAssetFeatures.CashFlow, name: 'cash-flow' },
    DATAROOM: { id: PropertyAssetFeatures.DataRoom, name: 'data-room' },
    TRANSACTION: { id: PropertyAssetFeatures.Transaction, name: 'transaction' },
    RESEARCH: { id: PropertyAssetFeatures.Research, name: 'research' },
    PROJECT_TEAM: { id: PropertyAssetFeatures.ProjectTeam, name: 'project-team' },
    FAQ: { id: PropertyAssetFeatures.Faq, name: 'faq' },
    PERMISSIONS: { id: PropertyAssetFeatures.Permission, name: 'permissions' },
};
