import * as React from 'react';
import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import PropertyAssetFeture from '../../services/PropertyAssetFeature';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import PortfolioService from '../../services/PortfolioService';
import { PropertyUnderPortfolio } from '../../interfaces/property/Portfolio';
import { toast } from 'react-toastify';
const { Option } = Select;

interface Props {
    id: string;
    pageType?: string;
    classForDrop: string;
    handleDropDownSelect: (event: any) => void;
}

const PropertyAssetDropdown: FunctionComponent<Props> = ({ classForDrop, pageType, id, handleDropDownSelect }) => {
    const { t } = useTranslation(['f', 'b']),
        [isShown, setIsShown] = useState<boolean>(false),
        [propertyAssets, setPropertyAssets] = useState<Array<PropertyUnderPortfolio>>([]);

    const fetchAssets = useCallback(async () => {
        try {
            const resp_portfolio = await PortfolioService.fetchPortfolioInformation(id);
            const new_array: Array<PropertyUnderPortfolio> = [
                { id: resp_portfolio.id, name: `${resp_portfolio.name} (portfolio)` },
            ];

            setPropertyAssets(new_array.concat(resp_portfolio.propertyAssets));
        } catch (error: any) {
            toast.error(error.response.data.errorMessage);
        }
    }, [id]);

    const isDropDownShown = useCallback(
        (isPortfolio: boolean) => {
            if (isPortfolio && pageType !== 'propertyassetresearch') {
                setIsShown(true);
            } else {
                setIsShown(false);
            }
        },
        [pageType]
    );

    const fetchIsPortfolio = useCallback(async () => {
        try {
            const property = await PropertyAssetFeture.fetchAssetHero(id);
            isDropDownShown(property.isPortfolio);
            if (property.isPortfolio) {
                fetchAssets();
            }
        } catch (error: any) {
            toast.error(error.response.data.errorMessage);
        }
    }, [id, isDropDownShown, fetchAssets]);

    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    useEffect(() => {
        fetchIsPortfolio();
    }, [fetchIsPortfolio]);

    return (
        <>
            {isShown ? (
                <div className="col-xs-9 ml-20">
                    <div className="property__dropdown d-flex flex-direction-column">
                        <label htmlFor="propertyName">{t('f:portfolioPage.selectProperty')}</label>
                        <Select
                            showSearch
                            className={classForDrop}
                            placeholder={t('f:portfolioPage.selectProperty')}
                            optionLabelProp="label"
                            showArrow
                            onChange={(value) => {
                                handleDropDownSelect(value);
                            }}
                            getPopupContainer={handleContainer}
                        >
                            {propertyAssets.map((option, index) => (
                                <Option
                                    value={option.id}
                                    title={option.name}
                                    label={option.name}
                                    className="d-flex"
                                    key={index}
                                    placeholder={option.name}
                                >
                                    <div className="select-checkbox"></div>
                                    {option.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
export default PropertyAssetDropdown;
