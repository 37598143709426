import PropertyAssetStackingPlan from '../api/routes/PropertyAssetStackingPlan';

const fetchStackingPlan = async (id: string) => {
    const { data } = await PropertyAssetStackingPlan.fetchStackingPlan(id);
    return data;
};
const PropertyAssetStackingPlanService = {
    fetchStackingPlan,
};
export default PropertyAssetStackingPlanService;
