import * as React from 'react';
import { FavoritesArray } from '../../interfaces/contexts/overview';
const initValues: FavoritesArray = {
    totalCount: 0,
    items: [],
};
export default React.createContext({
    allFavouritesAssests: initValues,
    allFavouritesPortfolios: initValues,
    allDivestments: initValues,
    allAcquisitions: initValues,
    setAcquisitionsPage: (prev: number) => {},
    setDivestmentsPage: (prev: number) => {},
    setFavoritePageAssets: (prev: number) => {},
    setFavoritePagePortfolios: (prev: number) => {},
    fetchAllAcquisitions: (prev: number) => {},
    fetchAllDivstemetns: (prev: number) => {},
    fetchAllFavoritesPortfolios: (prev: number) => {},
    fetchAllFavoritesAssets: (prev: number) => {},
});
