import { FunctionComponent } from 'react';
import { Input } from 'antd';

interface TextInputProps {
    content: string;
    setContent: (prev: string) => void;
    label: string;
    placeholder: string;
    clear?: boolean;
}

const TextInput: FunctionComponent<TextInputProps> = ({
    content,
    label,
    placeholder,
    setContent,
    clear,
}: TextInputProps) => (
    <div className={`${clear ? 'col-xs-6-clear' : 'col-xs-6'} form-group mb-30`}>
        <label htmlFor="text">{label}</label>
        <Input
            style={{ borderRadius: '4px' }}
            id="text"
            name="text"
            value={content ? content : undefined}
            placeholder={placeholder}
            onChange={(event: any) => {
                setContent(event.target.value);
            }}
        />
    </div>
);

export default TextInput;
