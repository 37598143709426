import React, { useEffect, useState } from 'react';
import Gallery from 'react-photo-gallery';
import Photo from './Photo';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement((item) => <Photo {...item} />);
const SortableGallery = SortableContainer(({ items }) => (
    <Gallery photos={items} renderImage={(props) => <SortablePhoto {...props} numOfPhotos={items.length} />} />
));

const SortableImageGallery = (props) => {
    const [items, setItems] = useState(props.images);
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItems(arrayMove(items, oldIndex, newIndex));
        props.setImages(arrayMove(items, oldIndex, newIndex));
        props.setIsOrderChanged(true);
    };

    useEffect(() => {
        setItems(props.images);
    }, [props]);

    return (
        <div>
            <SortableGallery items={items} onSortEnd={onSortEnd} axis={'xy'} />
        </div>
    );
};

export default SortableImageGallery;
