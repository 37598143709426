import { DataGrid, GridRowParams } from "@material-ui/data-grid";
import { FunctionComponent, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import MainLoader from "../../../../../components/shared/MainLoader";
import ModalWrapper from "../../../../../components/wrapper/ModalWrapper";
import FeatureState from "../../../../../context/state/FeatureState";
import useBoolean from "../../../../../hooks/use-boolean";
import { InvestorCompany, InvestorCompanyInfo } from "../../../../../interfaces/investor/CompanyWithInvestor";
import PropertyAssetCompanyService from "../../../../../services/PropertyAssetCompanyService";
import { checkHeight } from "../../../../../utils/checkHeight";
import CompanyInfoTabs from "../partials/CompanyInfoTabs";
import TreeDotsSingleCompany from "../partials/TreeDotsSingleCompany";

interface InvestorCompaniesTableProps {
    listOfCompanies: Array<InvestorCompany>;
    isCompaniesLoaded: boolean;
    page: number;
    pageSize: number;
    totalItems: number;
    setInvestorCompaniesPage: (page: number) => void;
    refetch: () => void;
}

const InvestorCompaniesTable: FunctionComponent<InvestorCompaniesTableProps> = ({
    isCompaniesLoaded,
    listOfCompanies,
    page,
    totalItems,
    pageSize,
    setInvestorCompaniesPage,
    refetch
}) => {
    const { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary;
    const [isModalShow, setModalShow] = useBoolean(false),
        [selectedCompany, setSelectedCompany] = useState<InvestorCompanyInfo>();
    const { t } = useTranslation(['f', 'b']);

    const tableLayout = [
        {
            field: 'name',
            headerName: t('f:teams.company'),
            flex: 1,
            sorter: (a: any, b: any) => a.companyName.localeCompare(b.companyName),
        },
        {
            field: 'ndaStatus',
            headerName: t('f:teams.ndaStatus'),
            flex: 1,
            renderCell: (props) => {
                const { row } = props;
                return row.ndaStatus == 0 ? t('f:teams.notSigned') : t('f:teams.signed');
            },
        },
        {
            field: 'bidStatus',
            sorter: (a: any, b: any) => a.companyId - b.companyId,
            headerName: t('f:teams.bidStatus'),
            flex: 1,
            renderCell: (props) => {
                const { row } = props;
                return row.bidSubmitted ? t('f:teams.bid') : t('f:teams.noOffer');
            },
        },
        {
            field: 'actions',
            headerName: t('f:teams.actions'),
            renderCell: (props) => <TreeDotsSingleCompany id={props.row.id} deleteHandler={() => onDeleteCompany(props.row)} />,
            sortable: false
        }
    ];

    const onSelectCompany = useCallback(async (params: GridRowParams) => {
        const data = params.row as InvestorCompany;
        const companyInfo = await PropertyAssetCompanyService.fetchCompanyInfo(propertyAssetId, data.id)
        setSelectedCompany(companyInfo);
        setModalShow.setTrue();
    }, [])

    const onPageChange = (page: number) => {
        setInvestorCompaniesPage(page);
    };

    const onDeleteCompany = useCallback(async (company: InvestorCompany) => {
        await PropertyAssetCompanyService.deleteCompany(propertyAssetId, company.id);
        refetch();
    }, [])

    return <div style={{ height: checkHeight(listOfCompanies), width: '100%' }} className="mb-100">
        {isCompaniesLoaded ? (
            <>
                <DataGrid
                    rows={listOfCompanies}
                    columns={tableLayout}
                    pageSize={pageSize}
                    page={page}
                    paginationMode={'server'}
                    rowCount={totalItems}
                    disableSelectionOnClick
                    onRowDoubleClick={onSelectCompany}
                    onPageChange={onPageChange}
                    rowsPerPageOptions={[50]}
                />
                <ModalWrapper
                    wrapClassName="company-info-modal-wrap"
                    title={selectedCompany ? selectedCompany.name : ''}
                    titleClassName="company-info-modal-title"
                    className="company-info-modal"
                    isFooterShown={false}
                    isModalOpened={isModalShow}
                    isCloseModalShown={true}
                    closeModalHandler={() => {
                        setModalShow.setFalse();
                        setSelectedCompany(undefined);
                    }}
                    destroyOnClose={true}
                    width={'60vw'}
                >
                    <CompanyInfoTabs company={selectedCompany!} />
                </ModalWrapper>
            </>
        ) : (
            <MainLoader type="regular" />
        )}
    </div>
}

export default InvestorCompaniesTable;