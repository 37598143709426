import { FunctionComponent, useState, useEffect, useContext } from 'react';
import building from '../../assets/images/Banner@1x.png';
import LookUpContext from '../../context/state/LookUpState';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

interface Elements {
    id: number;
    imageUrl: string;
    propertyName: string;
    map: {
        long: number;
        lat: number;
        address: string;
    };
    yearOfCompletion: string;
    sectors?: Array<any>;
    landArea: number;
    carparkLots: string;
    majorTenants: string;
    propertySubMarketId: number;
    landTenureId: number;
    netLeasableArea: string;
}

interface Props {
    elements: Elements;
}
const SearchPropertyCard: FunctionComponent<Props> = (props) => {
    const [sectors, setSectors] = useState<Array<string>>([]),
        { t } = useTranslation(['f', 'b']),
        [landTenure, setLandTenure] = useState<any>([]),
        { getContent } = useContext(LookUpContext);

    useEffect(() => {
        const sectors = getContent('Sector');
        let newSectors = props.elements.sectors?.map((sec: any) => {
            let sector: any = _.find(sectors, { id: sec });
            return sector ? sector.name : sec;
        });

        setSectors(newSectors ?? []);

        const landTenures = getContent('LandTenure');

        let landTenure = props.elements.landTenureId
            ? _.find(landTenures, { id: props.elements.landTenureId })
            : { name: '' };
        setLandTenure(landTenure);
    }, []);
    return (
        <div className="row property-card__container">
            <figure className="property-card__image">
                <img src={props.elements?.imageUrl ? props.elements?.imageUrl : building} alt="Property" />
            </figure>
            <div className="col-xs-12">
                <h3 className="main-text property-card__name">{props.elements?.propertyName}</h3>
                <p className="property-card__address">{props.elements?.map.address}</p>
            </div>
            <div className="col-xs-12-clear">
                <div className="row-clear property-card__element-list">
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.yearOfCompletion2')}</p>
                        <p className="property-card__element-value">{props.elements?.yearOfCompletion}</p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.landTenure')}</p>
                        <p className="property-card__element-value">{landTenure?.name}</p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.siteAreaSqft')}</p>
                        <p className="property-card__element-value">{props.elements?.landArea}</p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t(`f:property.modal.nla`)}</p>
                        <p className="property-card__element-value">{props.elements?.netLeasableArea}</p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.carparkLots')}</p>
                        <p className="property-card__element-value">{props.elements?.carparkLots}</p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t('f:property.sectors')}</p>
                        <p className="property-card__element-value">
                            {sectors
                                .map((elem) => {
                                    return elem;
                                })
                                .join(', ')}
                        </p>
                    </div>
                    <div className="col-xs-12-flex-clear justify-content-space-between property-card__element-item">
                        <p className="property-card__element-name">{t(`f:property.modal.majorTenants`)}</p>
                        <p className="property-card__element-value">{props.elements?.majorTenants}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchPropertyCard;
