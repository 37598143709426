import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import { CashFlowI } from '../../../../../interfaces/cashFlow/CashFlow';

interface AddEditRecordModalSelectDateInputProps {
    name: string;
    errors: any;
    touched: any;
    values: CashFlowI;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const AddEditRecordModalSelectDateInput: FunctionComponent<AddEditRecordModalSelectDateInputProps> = ({
    name,
    touched,
    errors,
    values,
    setFieldValue,
}: AddEditRecordModalSelectDateInputProps) => {
    const { t } = useTranslation(['f', 'b']);
    return (
        <>
            <label htmlFor={name}>{t(`f:cashFlow.${name}`)}</label>
            <Space direction="vertical" size={10}>
                <DatePicker
                    value={values[name] ? moment(new Date(values[name])) : null}
                    onChange={(value: any) => setFieldValue(name, value.format('YYYY-MM-DDTHH:mm:ss'))}
                    onOk={(value: any) => setFieldValue(name, value.format('YYYY-MM-DDTHH:mm:ss'))}
                />
            </Space>
            {errors[name] && touched[name] ? (
                <span className="error-text-absolute">{t(errors[name], { name: t(`f:cashFlow.${name}`) })}</span>
            ) : null}
        </>
    );
};
export default AddEditRecordModalSelectDateInput;
