import FavoriteApi from '../api/routes/FavoriteApi';

const fetchIsInFavorite = async (propertyAssetId: number) => {
    const { data } = await FavoriteApi.fetchIsInFavorite(propertyAssetId);
    return data;
};

const fetchAllFavouritesAssets = async (page: number, items: number) => {
    const { data } = await FavoriteApi.fetchAllFavouritesAssets(page, items);
    return data;
};

const fetchAllFavouritesPortfolios = async (page: number, items: number) => {
    const { data } = await FavoriteApi.fetchAllFavouritesPortfolios(page, items);
    return data;
};

const addToFavorite = async (propertyAssetId: number) => {
    const { data } = await FavoriteApi.addToFavorite(propertyAssetId);
    return data;
};

const deleteFromFavorite = async (propertyAssetId: number) => {
    const { data } = await FavoriteApi.deleteFromFavorite(propertyAssetId);
    return data;
};

const fetchAllDivstemetns = async (page: number, items: number) => {
    const { data } = await FavoriteApi.fetchAllDivstemetns(page, items);
    return data;
};

const fetchAllAcquisitions = async (page: number, items: number) => {
    const { data } = await FavoriteApi.fetchAllAcquisitions(page, items);
    return data;
};

const FavoriteService = {
    fetchIsInFavorite,
    fetchAllAcquisitions,
    fetchAllDivstemetns,
    fetchAllFavouritesAssets,
    fetchAllFavouritesPortfolios,
    addToFavorite,
    deleteFromFavorite,
};
export default FavoriteService;
