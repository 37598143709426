import { useCallback } from 'react';
import { FunctionComponent } from 'react';
import StackingCell from './StackingCell';
import styles from './StackingLegend.module.scss';

interface Props {
    data: any;
    className: string;
    colors: Array<StackingCell>;
}

const StackingLegend: FunctionComponent<Props> = ({ data, colors, className }) => {
    const formatNumber = useCallback((number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','), []);

    const getStyle = (index: number) => {
        const bckColor = index < colors.length ? colors[index]?.color : 'red';
        const c = index < colors.length ? colors[index]?.textColor : 'white';

        return {
            backgroundColor: bckColor,
            color: c,
        };
    };

    return (
        <div className={`${styles.root} ${className}`}>
            <div className={styles.categories}>
                {data.map((category, index) => (
                    <div key={category.name} className={styles.category}>
                        <span className={`${styles.name} stacking-plan-chart__text`} style={getStyle(index)}>
                            {category.name}
                        </span>
                        <span className={`${styles.area} stacking-plan-chart__text--title`}>
                            {formatNumber(category.totalArea)}
                        </span>
                        <span className={`${styles.percentage} stacking-plan-chart__text--title`}>
                            {Number(category.percent).toFixed(1)}%
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StackingLegend;
