import { Checkbox, Collapse, Select, Slider } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import FormattedAmount from '../../../components/shared/FormattedAmount';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CaretDownOutlined } from '@ant-design/icons';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import TradeProbabilityInfo from '../models/tradeProbabilityInfo';

const PriceDistribution = 10000000;

const sectorOptions = [
    { label: 'Office', value: 1 },
    { label: 'Residential', value: 2 },
    { label: 'Alternatives', value: 3 },
    { label: 'Retail', value: 4 },
    { label: 'Hospitality', value: 5 },
    { label: 'Healthcare', value: 8 },
    { label: 'Land / Development Site', value: 6 },
    { label: 'Industrial & logistics', value: 7 },
];

const predictionYears = [
    { label: '1-2 years', value: 1 },
    { label: '2-3 years', value: 2 },
    { label: '4-6 years', value: 3 },
];

interface Props {
    minPrice: number,
    maxPrice: number;
    tradeProbabilities?: Array<TradeProbabilityInfo>;
    onPriceChanged: (value: [number, number]) => void;
    onYearChanged: (value: number[]) => void;
    onRegionChanged: (value: string) => void;
}

const regions = [{
    key: 'APAC',
    title: 'APAC (Asia-Pacific)'
}];

const renderPriceChart = (chartId: string, [minPrice, maxPrice], tradeProbabilities: Array<TradeProbabilityInfo>): number => {
    const root = am5.Root.new(chartId);

    root.events.on('frameended', () => {
        var canvas = window.document.getElementById(chartId)?.getElementsByTagName('canvas');
        var item = canvas?.item(1)
        if (item) {
            item.style.display = 'none';
        }
    });

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
        centerX: 24,
        centerY: -34
    }));

    // Create axes
    var xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.grid.template.set('strokeOpacity', 0);
    xRenderer.labels.template.set('visible', false);

    var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: 'range',
        renderer: xRenderer as am5xy.AxisRenderer
    }));

    var yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.grid.template.set('strokeOpacity', 0);
    yRenderer.labels.template.set('visible', false);

    var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        renderer: yRenderer as am5xy.AxisRenderer
    }));

    var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'value',
        categoryXField: 'range',
    }));

    // Set data
    const data: any[] = [];
    for (let index = minPrice; index <= maxPrice; index += PriceDistribution) {
        data.push({ range: `${index}+`, value: 0 });
    }
    data.push({ range: `${maxPrice}+`, value: 0 });

    let sum = 0;
    tradeProbabilities.forEach(t => {
        var index = ((t.price - t.price % PriceDistribution) / PriceDistribution) - 1;

        if (index < 0) {
            index = 0;
        } else if (index >= data.length) {
            index = data.length - 1;
        }

        data[index].value++;
        sum += t.price;
    });


    xAxis.data.setAll(data);
    series.data.setAll(data);

    return Math.round(sum / tradeProbabilities.length);
}

const FilterMenuPanel: FunctionComponent<Props> = ({ minPrice, maxPrice, tradeProbabilities, onPriceChanged, onYearChanged, onRegionChanged }) => {
    const [chartId, setChartId] = useState<string>(),
        [priceMin, setPriceMin] = useState<number>(minPrice),
        [priceMax, setPriceMax] = useState<number>(maxPrice),
        [averagePrice, setAveragePrice] = useState<number>(0),
        [priceRange, setPriceRange] = useState<[number, number]>([0, 0]);

    const handlePriceChanged = value => {
        setPriceRange(value);
        onPriceChanged(value);
    };

    const handleYearChanged = (values: CheckboxValueType[]) => {
        const years = new Set<number>();

        values.forEach(v => {
            switch (v) {
                case 1:
                    years.add(1);
                    years.add(2);
                    break;
                case 2:
                    years.add(2);
                    years.add(3);
                    break;
                case 3:
                    years.add(4);
                    years.add(5);
                    years.add(6);
                    break;
                default:
                    break;
            }
        });

        onYearChanged(Array.from(years));
    };

    useEffect(() => {
        if (!chartId || averagePrice !== 0 || priceRange[0] === 0 || priceRange[1] === 0
            || !tradeProbabilities || tradeProbabilities.length === 0) {
            return;
        }

        var price = renderPriceChart(chartId, priceRange, tradeProbabilities);
        setAveragePrice(price);
    }, [chartId, averagePrice, priceRange, tradeProbabilities]);

    useEffect(() => {
        setPriceMin(minPrice);
        setPriceMax(maxPrice);
        setPriceRange([minPrice, maxPrice]);
    }, [minPrice, maxPrice]);

    useEffect(() => {
        setChartId(`PriceChart${Math.random()}`);
        onRegionChanged(regions[0].key);
    }, []);

    return (
        <>
            <div className='search-name'>Trade Probability Model</div>
            <Collapse
                bordered={false}
                defaultActiveKey={[1, 2, 3, 4]}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                className='filter-collapse'
            >
                <Collapse.Panel header='Sector' key='1'>
                    <Checkbox.Group className='checkbox-group' options={sectorOptions} />
                </Collapse.Panel>
                <Collapse.Panel header='Price' key='2'>
                    <div className='average-price'>The average price is <FormattedAmount value={averagePrice} prefix='USD' /></div>
                    <div className='price-chart' id={chartId} />
                    <Slider
                        range
                        step={1000}
                        className='price-slider'
                        onAfterChange={handlePriceChanged}
                        min={priceMin}
                        max={priceMax}
                        defaultValue={[priceMin, priceMax]}
                        tipFormatter={(v) => <FormattedAmount value={v || 0} suffix={v === maxPrice ? '+' : ''} />} />
                    <div className='price-value'>
                        <FormattedAmount value={priceRange[0]} prefix='USD' />
                        <span className='max-price'>
                            <FormattedAmount value={priceRange[1]} prefix='USD' />
                            {priceRange[1] === maxPrice ? '+' : null}
                        </span>
                    </div>
                </Collapse.Panel>
                <Collapse.Panel header='Prediction Year' key='3'>
                    <Checkbox.Group className='checkbox-group' options={predictionYears} onChange={handleYearChanged} />
                </Collapse.Panel>
                <Collapse.Panel header='Region' key='4'>
                    <Select
                        suffixIcon={(<CaretDownOutlined />)}
                        className='region-select'
                        optionFilterProp='children'
                        defaultValue={regions[0]}
                        onSelect={v => onRegionChanged(v)}
                    >
                        {regions.map(region => (
                            <Select.Option key={region.key} value={region.key}>{region.title}</Select.Option>
                        ))}
                    </Select>
                </Collapse.Panel>
            </Collapse>
        </>
    );
};

export default FilterMenuPanel;
