import propertyLayoutImage from '../assets/images/property-layout-image.png';
import rightHeadingIcon from '../assets/images/layout-icons/right-heading.png';
import leftHeadingIcon from '../assets/images/layout-icons/left-heading.png';
import rightHeadingChart from '../assets/images/layout-icons/right-chart-template.png';
import leftHeadingChart from '../assets/images/layout-icons/left-chart-template.png';
import imageHeadingIcon from '../assets/images/layout-icons/image-heading.png';
import videoHeadingIcon from '../assets/images/layout-icons/video-heading.png';
import textImagesIcon from '../assets/images/layout-icons/text-images.png';
import imagesIcon from '../assets/images/layout-icons/images.png';
import geomap from '../assets/images/layout-icons/geomap.png';
import table from '../assets/images/layout-icons/table.png';
import text from '../assets/images/layout-icons/text.png';

// Id is a string, since Formik has some bug when value is a number
export const layouts = [
    {
        id: '1',
        key: 'right-heading-editor',
        imageUrl: rightHeadingIcon,
    },
    {
        id: '2',
        key: 'left-heading-editor',
        imageUrl: leftHeadingIcon,
    },
    {
        id: '3',
        key: 'single-image',
        imageUrl: imageHeadingIcon,
    },
    {
        id: '4',
        key: 'video',
        imageUrl: videoHeadingIcon,
    },
    {
        id: '5',
        key: 'multiple-text-image',
        imageUrl: textImagesIcon,
    },
    {
        id: '6',
        key: 'gallery',
        imageUrl: imagesIcon,
    },
    {
        id: '7',
        key: 'geo-map',
        imageUrl: geomap,
    },
    {
        id: '8',
        key: 'table',
        imageUrl: table,
    },
    {
        id: '9',
        key: 'text',
        imageUrl: text,
    },
    {
        id: '10',
        key: 'icon-text',
        imageUrl: textImagesIcon,
    },
    {
        id: '11',
        key: 'right-heading-chart',
        imageUrl: rightHeadingChart,
    },
    {
        id: '12',
        key: 'left-heading-chart',
        imageUrl: leftHeadingChart,
    },
];
