import { FunctionComponent, useEffect, useState, useContext, useCallback } from 'react';
import { Formik, Field, Form, FormikHelpers, FieldProps } from 'formik';
import SingleSelect from '../../../components/shared/SingleSelect';
import MultiSelect from '../../../components/shared/MultiSelect';
import Property from '../../../services/Property';
import { AddPropertyShema as AddPropertyValidationSchema } from '../../../validations/AddProperty';
import PropertyCreateValues from '../../../interfaces/property/PropertyCreate';
import { useTranslation } from 'react-i18next';
import { calculateAssetValue } from '../../../utils/calculator';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatMoney } from '../../../constants/localization';
import { Button, Tooltip, Upload } from 'antd';
import FileUpload from '../../../services/FileUploadService';
import LookUpContext from '../../../context/state/LookUpState';
import ImgCrop from 'antd-img-crop';
import PropertyAssetMap from '../../../components/shared/PropertyAssetMap';
import MyNumberInput from './MyNumberInput';
import MapService from '../../../services/MapService';
import _ from 'lodash';
import MainHeading from '../../../components/shared/MainHeading';
import useBoolean from '../../../hooks/use-boolean';
import ListOpportunityFooterBtns from '../../ListOpportunityWizard/components/partials/ListOpportunityFooterBtns';
import ModalImportLoading from '../../../components/shared/ModalImportLoading';

export const AddBasicInfo: FunctionComponent<any & FieldProps> = () => {
    const history = useHistory(),
        { t } = useTranslation(['f', 'b']),
        { getContent, countries } = useContext(LookUpContext),
        [assetValue, setAssetValue] = useState(''),
        [subSectorOptions, setSubSectorOptions] = useState<any>(getContent('Sector')),
        [countryCode, setCountryCode] = useState<string>(''),
        [image, setImage] = useState(''),
        [typedAddress, setTypedAddress] = useState<any>(),
        [options, setOptions] = useState<any>([]),
        [isPropertyAdding, setIsPropertyAddingActions] = useBoolean(),
        [locationSelected, setLocationSelectedActions] = useBoolean();

    const checkStreetNumber = (street: string) => {
        return street ? street : '';
    };

    const sectorsOptions = getContent('Sector');

    const searchResult = useCallback(
        async (query: string) => {
            try {
                let locations = await MapService.fetchLocations(query, [countryCode], 0, 100);
                let options = _.filter(locations.items, (location: any) => {
                    if (location.streetName != null) {
                        return location;
                    }
                }).map((location: any) => {
                    return {
                        longitude: location.long,
                        latitude: location.lat,
                        country: location.country,
                        city: location.city,
                        streetName: location.streetName,
                        streetNumber: checkStreetNumber(location.streetNumber),
                        fullAddress: location.freeFormAddress
                    };
                });
                return options;
            } catch (e) {
                console.log(e);
                return [];
            }
        },
        []
    );

    const handleSearch = useCallback(
        _.debounce(async (value: string) => {
            if (!value) {
                return;
            }

            let result = await searchResult(value);
            setOptions(value ? result : []);
        }, 500),
        [searchResult]
    );

    const scrollToErrors = (errors: any) => {
        const errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
            toast.error(t(errors[errorKeys[0]], { name: t(`f:property.${errorKeys[0]}`) }), {
                className: 'error-toast',
            });
            const firstError = document.getElementsByName(errorKeys[0])[0];
            if (firstError) firstError.focus();
        }
    };

    const customRequest = async (options: any, setFieldValue: any) => {
        const data = new FormData();
        data.append('Image', options.file);
        try {
            const imageUrl = await FileUpload.uploadFile(options, data);
            setImage(imageUrl);
            setFieldValue(`imageUrl`, imageUrl);
        } catch (err) {
            console.log(err);
        }
    };

    const handleOnSubmitAddBasicInfo = useCallback(
        async (values: PropertyCreateValues, { setSubmitting }: FormikHelpers<PropertyCreateValues>) => {
            setIsPropertyAddingActions.setTrue();
            try {
                const data = await Property.create(values);
                history.push(`/property/${data.externalId}`);
                setIsPropertyAddingActions.setFalse();
            } catch (err: any) {
                toast(t(`b:${err.response.data.errorMessage}`));
            }
        },
        [t, history, setIsPropertyAddingActions]
    );

    const onMapClicked = useCallback(async (e: any, setFieldValue: any) => {
        const position = e.position;
        const lat = position[1];
        const lon = position[0];
        const location = await MapService.reverse(lat, lon);

        if (location) {
            let country = _.find(countries, item => item.isoCode === location.countryCode);
            if (country) {
                setFieldValue('map.countryId', country.id);
                setCountryCode(location.countryCode);
            }

            setFieldValue('map.long', location.long);
            setFieldValue('map.lat', location.lat);

            let address = '';
            if (location.streetName && location.streetNumber) {
                address = `${location.country}, ${location.city}, ${location.streetName}, ${checkStreetNumber(location.streetNumber)}`
            } else {
                address = location.freeFormAddress;
            }

            setFieldValue('map.address', address);
            setTypedAddress({
                long: location.long,
                lat: location.lat,
                address: address,
            });
        }
    }, []);

    return (
        <div className="wizard-container-steps">
            <Formik
                autocomplete="off"
                initialValues={{
                    propertyName: '',
                    yearOfCompletion: '',
                    landAreaUnitOfMeasureId: '',
                    landTenureId: null,
                    currencyId: '',
                    unitOfMeasurementId: '',
                    saleTypeId: '',
                    landArea: '',
                    grossFloorArea: '',
                    sectors: [],
                    subSectors: [],
                    netLeasableAreaUnitOfMeasureId: '',
                    netLeasableArea: '',
                    grossLeasableAreaUnitOfMeasureId: '',
                    grossLeasableArea: '',
                    carparkLots: '',
                    majorTenants: '',
                    passingRentUnitOfMeasureId: '',
                    passingRentCurrencyId: '',
                    passingRent: '',
                    netOperatingIncome: '',
                    capitalisationRate: '',
                    map: {
                        long: '',
                        lat: '',
                        address: '',
                        countryId: '',
                    },
                    imageUrl: '',
                }}
                validationSchema={AddPropertyValidationSchema}
                validateOnMount
                onSubmit={handleOnSubmitAddBasicInfo}
            >
                {({ errors, touched, values, setFieldValue, validateForm, dirty, isValid, submitForm }) => (
                    <Form className="row create-edit-property-form mr-20">
                        <div className="col-md-12">
                            <MainHeading text={t('f:property.dealInformation')} />
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group relative">
                            <label htmlFor="propertyName">{t('f:property.opportunityName')}*</label>
                            <Field id="propertyName" name="propertyName" placeholder={t('f:property.enterName')} />
                            {errors.propertyName && touched.propertyName ? (
                                <span className="error-text-absolute">
                                    {t(errors.propertyName, { name: t('f:property.opportunityName') })}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-md-12 mt-20">
                            <MainHeading text={t('f:property.general')} />
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <SingleSelect
                                label={`${t('f:property.unitOfMeasuremnt')}*`}
                                placeholder={t('f:property.selectUnitOfMeasuremnt')}
                                options={getContent('UnitOfMeasure')}
                                onSelected={(value: any) => setFieldValue('unitOfMeasurementId', value)}
                            />
                            {errors.unitOfMeasurementId && touched.unitOfMeasurementId ? (
                                <span className="error-text-absolute">
                                    {t(errors.unitOfMeasurementId, { name: t('f:property.unitOfMeasuremnt') })}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <SingleSelect
                                label={`${t('f:property.currency')}*`}
                                placeholder={t('f:property.selectCurrency')}
                                options={getContent('Currency')}
                                onSelected={(value: any) => setFieldValue('currencyId', value)}
                            />
                            {errors.currencyId && touched.currencyId ? (
                                <span className="error-text-absolute">
                                    {t(errors.currencyId, { name: t('f:property.currency') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-md-12 mt-20">
                            <MainHeading text={t('f:property.location')} />
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group hidden">
                            <label htmlFor="map.lat">{t('f:property.mapLatitude')}</label>
                            <Field id="map.lat" name="map.lat" placeholder={t('f:property.enterMapLatitude')} />
                            {errors.map && errors.map.lat && touched.map ? (
                                <span className="error-text-absolute">
                                    {t(errors.map.lat, { name: t('f:property.mapLatitude') })}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group hidden">
                            <label htmlFor="map.long">{t('f:property.mapLongitude')}</label>
                            <Field id="map.long" name="map.long" placeholder={t('f:property.enterMapLongitude')} />
                            {errors.map && errors.map.long && touched.map ? (
                                <span className="error-text-absolute">
                                    {t(errors.map.long, { name: t('f:property.mapLongitude') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-md-4 col-xxl-4 form-group">
                            <SingleSelect
                                label={`${t('f:property.map.country')}*`}
                                placeholder={t('f:property.map.country')}
                                options={countries.filter(country => country.isActive)}
                                selected={values.map.countryId ? values.map.countryId : null}
                                onSelected={(value: any) => {
                                    setFieldValue('map.countryId', value);
                                    let obj: any = _.find(countries, { id: value });
                                    setCountryCode(obj.isoCode);
                                }}
                            />
                            {errors.map && errors.map.countryId && touched.map && touched.map.countryId ? (
                                <span className="error-text-absolute">
                                    {t(errors.map.countryId, { name: t('f:property.map.country') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-md-8 col-xxl-4 form-group">
                            <label htmlFor="map.address">{`${t('f:property.mapAddress')}*`}</label>
                            <div className="property__dropdown-container">
                                <Field
                                    id="map.address"
                                    name="map.address"
                                    placeholder={t('f:property.enterMapAddress')}
                                    onChange={(e: any) => {
                                        setFieldValue('map.address', e.target.value);
                                        handleSearch(e.target.value);
                                        !_.isEqual(e.target.value, typedAddress)
                                            ? setLocationSelectedActions.setFalse()
                                            : setLocationSelectedActions.setTrue();
                                    }}
                                    className="col-xs-12"
                                />
                                {options.length && !locationSelected ? (
                                    <div className="property__dropdown-main">
                                        <ul className="property__dropdown-list d-flex flex-direction-column">
                                            {options.map((elem: any, i: number) => {
                                                return (
                                                    <div
                                                        className="ant-select-item ant-select-item-option ant-select-item-option-content ant-select-item-option-active"
                                                        onClick={(value: any) => {
                                                            setFieldValue(
                                                                'map.address',
                                                                `${elem.country}, ${elem.city}, ${
                                                                    elem.streetName
                                                                }, ${checkStreetNumber(elem.streetNumber)}`
                                                            );
                                                            setFieldValue('map.long', elem.longitude);
                                                            setFieldValue('map.lat', elem.latitude);
                                                            setTypedAddress({
                                                                long: elem.longitude,
                                                                lat: elem.latitude,
                                                                address: `${elem.country}, ${elem.city}, ${
                                                                    elem.streetName
                                                                }, ${checkStreetNumber(elem.streetNumber)}`,
                                                            });
                                                            setLocationSelectedActions.setTrue();
                                                        }}
                                                    >
                                                        <div className="ant-select-item-option-content">
                                                            <div className="select-checkbox"></div>
                                                            {`${elem.country}, ${elem.city}, ${
                                                                elem.streetName
                                                            }, ${checkStreetNumber(elem.streetNumber)}`}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                            {errors.map && errors.map.address && touched.map ? (
                                <span className="error-text-absolute">
                                    {t(errors.map.address, { name: t('f:property.address') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-md-11 form-group mt-20">
                            <div className="mt-20 mb-20 ">
                                <PropertyAssetMap
                                    locations={[]}
                                    shouldCluster={false}
                                    showPopup={true}
                                    showOnlyAddress={true}
                                    location={typedAddress}
                                    allowInteraction={true}
                                    onMapClicked={(e:any) => onMapClicked(e, setFieldValue)}
                                ></PropertyAssetMap>
                            </div>
                        </div>
                        <div className="col-md-12 mt-20">
                            <MainHeading text={t('f:property.financialInformation')} />
                        </div>

                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label htmlFor="passingRent">{t('f:property.passingRent')}</label>
                            <MyNumberInput
                                placeholder={t('f:property.enterPassingRent')}
                                value={values.passingRent}
                                onValueChange={(val) => setFieldValue('passingRent', val.floatValue)}
                            />
                            {errors.passingRent && touched.passingRent ? (
                                <span className="error-text-absolute">
                                    {t(errors.passingRent, { name: t('f:property.passingRent') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <SingleSelect
                                label={`${t('f:property.saleType')}*`}
                                placeholder={t('f:property.selectSaleType')}
                                options={getContent('SaleType')}
                                onSelected={(value: any) => setFieldValue('saleTypeId', value)}
                            />
                            {errors.saleTypeId && touched.saleTypeId ? (
                                <span className="error-text-absolute">
                                    {t(errors.saleTypeId, { name: t('f:property.saleType') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label htmlFor="netOperatingIncome">{t('f:property.netOperatingIncome')}</label>
                            <MyNumberInput
                                placeholder={t('f:property.enterNetOperatingIncome')}
                                value={values.netOperatingIncome}
                                onValueChange={(val) => {
                                    setFieldValue('netOperatingIncome', val.floatValue);
                                    setAssetValue(
                                        isNaN(calculateAssetValue(val.floatValue, values.capitalisationRate))
                                            ? ''
                                            : formatMoney(
                                                  calculateAssetValue(val.floatValue, values.capitalisationRate)
                                              )
                                    );
                                }}
                            />

                            {errors.netOperatingIncome && touched.netOperatingIncome ? (
                                <span className="error-text-absolute">
                                    {t(errors.netOperatingIncome, { name: t('f:property.netOperatingIncome') })}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label htmlFor="capitalisationRate">{t('f:property.capitalisationRate')} (%)</label>
                            <Field
                                id="capitalisationRate"
                                name="capitalisationRate"
                                placeholder={t('f:property.enterCapitalisationRate')}
                                onChange={(e: any) => {
                                    setFieldValue('capitalisationRate', e.target.value);
                                    setAssetValue(
                                        isNaN(calculateAssetValue(values.netOperatingIncome, e.target.value))
                                            ? ''
                                            : formatMoney(
                                                  calculateAssetValue(values.netOperatingIncome, e.target.value)
                                              )
                                    );
                                }}
                            />
                            {errors.capitalisationRate && touched.capitalisationRate ? (
                                <span className="error-text-absolute">
                                    {t(errors.capitalisationRate, { name: t('f:property.capitalisationRate') })}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label>{t('f:property.assetValue')}</label>
                            <MyNumberInput placeholder={t('f:property.assetValue')} value={assetValue} disabled />
                        </div>

                        <div className="col-md-12 mt-20">
                            <MainHeading text={t('f:property.propertyInformation')} />
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label htmlFor="netLeasableArea">{t('f:property.netLeasableArea')}</label>

                            <MyNumberInput
                                placeholder={t('f:property.enterNetLeasableArea')}
                                value={values.netLeasableArea}
                                onValueChange={(val) => setFieldValue('netLeasableArea', val.floatValue)}
                            />
                            {errors.netLeasableArea && touched.netLeasableArea ? (
                                <span className="error-text-absolute">
                                    {t(errors.netLeasableArea, { name: t('f:property.netLeasableArea') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label htmlFor="grossFloorArea">{t('f:property.grossFloorArea')}</label>

                            <MyNumberInput
                                placeholder={t('f:property.enterGrossFloorArea')}
                                value={values.grossFloorArea}
                                onValueChange={(val) => setFieldValue('grossFloorArea', val.floatValue)}
                            />
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label htmlFor="yearOfCompletion">{t('f:property.yearBuilt')}</label>
                            <Field
                                id="yearOfCompletion"
                                name="yearOfCompletion"
                                placeholder={t('f:property.yearBuilt')}
                            />
                            {errors.yearOfCompletion && touched.yearOfCompletion ? (
                                <span className="error-text-absolute">
                                    {t(errors.yearOfCompletion, { name: t('f:property.yearOfCompletion') })}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label htmlFor="carparkLots">{t('f:property.carparkLots')}</label>
                            <MyNumberInput
                                placeholder={t('f:property.enterCarparkLots')}
                                value={values.carparkLots}
                                onValueChange={(val) => setFieldValue('carparkLots', val.floatValue.toString())}
                            />
                            {errors.carparkLots && touched.carparkLots ? (
                                <span className="error-text-absolute">
                                    {t(errors.carparkLots, { name: t('f:property.carparkLots') })}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label htmlFor="majorTenants">{t('f:property.majorTenants')}</label>
                            <Field
                                id="majorTenants"
                                name="majorTenants"
                                placeholder={t('f:property.enterMajorTenants')}
                            />
                            {errors.majorTenants && touched.majorTenants ? (
                                <span className="error-text-absolute">
                                    {t(errors.majorTenants, { name: t('f:property.majorTenants') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-md-6 col-xxl-4 form-group">
                            <SingleSelect
                                label={`${t('f:property.landTenure')}*`}
                                placeholder={t('f:property.selectLandTenure')}
                                options={getContent('LandTenure')}
                                onSelected={(value: any) => setFieldValue('landTenureId', value)}
                            />
                            {errors.landTenureId && touched.landTenureId ? (
                                <span className="error-text-absolute">
                                    {t(errors.landTenureId, { name: t('f:property.landTenure') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <label htmlFor="landArea">{t('f:property.landArea')}</label>
                            <MyNumberInput
                                placeholder={t('f:property.enterLandArea')}
                                value={values.landArea}
                                onValueChange={(val) => setFieldValue('landArea', val.floatValue)}
                            />
                            {errors.landArea && touched.landArea ? (
                                <span className="error-text-absolute">
                                    {t(errors.landArea, { name: t('f:property.landArea') })}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <SingleSelect
                                label={t('f:property.mainSector')}
                                placeholder={t('f:property.selectMainSector')}
                                options={sectorsOptions}
                                onSelected={(value: any) => {
                                    setFieldValue('mainSectorId', value);
                                    const subSectorOptions = sectorsOptions.filter((item) => item.id != value);
                                    setSubSectorOptions(subSectorOptions);
                                }}
                            />
                            {errors.mainSectorId && touched.mainSectorId ? (
                                <span className="error-text-absolute">
                                    {t(errors.mainSectorId, { name: t('f:property.mainSector') })}
                                </span>
                            ) : null}
                        </div>

                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group">
                            <MultiSelect
                                label={t('f:property.subSectors')}
                                placeholder={t('f:property.selectSubSectors')}
                                options={subSectorOptions}
                                selected={values.subSectors}
                                onSelected={(value: any) => setFieldValue('subSectors', [...values.subSectors, value])}
                                onDeleted={(value: any) => {
                                    let removeElem = values.subSectors.filter((elem) => elem !== value);
                                    setFieldValue('subSectors', removeElem);
                                }}
                            />
                        </div>

                        <div className="col-xs-12">
                            <MainHeading text={t('f:property.coverPhoto')} />
                        </div>
                        <div className="col-xs-12 col-md-6 col-xxl-4 form-group mb-100">
                            {image ? (
                                <img src={image} className="newsletter-builder__def-image" alt="property" />
                            ) : (
                                <br />
                            )}
                            <ImgCrop rotate aspect={36 / 13} quality={1}>
                                <Upload
                                    name="Image"
                                    className="add-property-image-builder__upload"
                                    showUploadList={false}
                                    action={process.env.REACT_APP_API_URL + `/propertyasset/image`}
                                    customRequest={(options: any) => {
                                        customRequest(options, setFieldValue);
                                    }}
                                >
                                    <Tooltip
                                        placement="bottomRight"
                                        color="#fd615a"
                                        title={t('f:property.photoDimensionRecommended')}
                                    >
                                        <Button>
                                            <p className="teams-export__icon">{t('f:button.upload')}</p>
                                        </Button>
                                    </Tooltip>
                                </Upload>
                            </ImgCrop>
                        </div>
                        <ListOpportunityFooterBtns
                            isNotFirst={false}
                            isNotFinish={true}
                            onNextHandlerDisabled={isPropertyAdding}
                            cancelUrl="/"
                            onNextHandler={() => {
                                validateForm().then(() => scrollToErrors(errors));
                            }}
                        />
                    </Form>
                )}
            </Formik>
            <ModalImportLoading text={t(`f:loaders.creating`)} isModalOpened={isPropertyAdding} />
        </div>
    );
};
