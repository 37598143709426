import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropertyAssetPageTemplate from '../../../../services/PropertyAssetPageTemplate';
import CustomTable from '../../../../components/shared/CustomTable';
import MainHeading from '../../../../components/shared/MainHeading';

interface Props {
    setContent: any;
    propertyAssetId: string;
    pageType: string;
    contentState: {
        state: string;
        setter: React.Dispatch<React.SetStateAction<string>>;
    };
}

export const TableTemplate: FunctionComponent<Props> = (props) => {
    const [tableData, setTableData] = useState<any>();

    useEffect(() => {
        const data = props.contentState.state.length
            ? JSON.parse(props.contentState.state.toString())
            : { content: [] };
        setTableData(data.content);
        propertyAsset();
    }, []);

    const propertyAsset = async () => {
        const pageInfo = await PropertyAssetPageTemplate.fetchPageInfo(props.pageType, props.propertyAssetId);
        setTableData(pageInfo);
        props.setContent(JSON.stringify({ tableContent: pageInfo }).toString());
    };

    const { t } = useTranslation(['f', 'b']);

    return (
        <div className="row-flex-clear rich-text-editor-container">
            <div className="col-xs-12">
                <MainHeading text={t('f:propertySummary.content')} />
            </div>
            <div className="col-xs-12">
                <CustomTable tableData={tableData} />
            </div>
        </div>
    );
};
