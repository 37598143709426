import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'markdown-to-jsx';
import FileUpload from '../../../../services/FileUploadService';
import { Upload, Button, Tooltip, Input } from 'antd';
import Cropper from 'react-cropper';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import MainHeading from '../../../../components/shared/MainHeading';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';

interface Props {
    setContent: any;
    propertyAssetId: string;
    templateKey: string;
    pageType: string;
    length: number;
    contentState: {
        state: string;
        setter: React.Dispatch<React.SetStateAction<string>>;
    };
}

export const MultipleTextImage: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation(['f', 'b']);
    const [contentBody, setContentBody] = useState<Array<string>>([]);
    const [indexOfItem, setIndexOfItem] = useState<number>(0);
    const [isActive, setIsActive] = useState(false),
        [cancel, setCancel] = useState<boolean>(false),
        [doneCropping, setDoneCropping] = useState<boolean>(false),
        [cardImageUrl, setCardImageUrl] = useState<string>(''),
        [finishEditing, setFinishEditing] = useState<boolean>(false),
        [cropper, setCropper] = useState<any>(),
        [title, setTitle] = useState<string>(''),
        [cardText, setCardText] = useState<string>(''),
        [cardTitle, setCardTitle] = useState<string>(''),
        [croppedPhoto, setCroppedPhoto] = useState<any>(),
        [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        let data: { content: any; title: any; };
        try {
            data = props.contentState.state.length ?
                JSON.parse(props.contentState.state.toString()) :
                { title: '', content: [] };  
        } catch {
            data = { title: '', content: [] }
        }
        setContentBody(data.content);
        setTitle(data.title);
        props.setContent(JSON.stringify(data));
    }, [props.contentState.state]);

    const saveBlock = () => {
        const _newBlock = `<figure class="image"><img src="${cardImageUrl}"></figure><h2 class="gallery-image-item">${cardTitle}</h2><p>${cardText}</p>`;
        const _newArray = [...(contentBody ? contentBody : [])];
        _newArray.push(_newBlock);
        setContentBody(_newArray);

        setIsActive(!isActive);
        props.setContent(JSON.stringify({ title: title, content: _newArray }).toString());
    };

    const addNewBlock = () => {
        if (title === '') {
            toast(t(`f:button.titleIsRequired`));
            return;
        }
        setCardImageUrl('');
        setCardText('');
        setCardTitle('');
        setIsActive(true);
        setCancel(false);
    };

    const finishCropping = async () => {
        try {
            const data = new FormData();
            data.append('Image', dataURLtoFile(croppedPhoto, 'Image'));

            const imageUrl = await FileUpload.uploadFile(
                { action: process.env.REACT_APP_API_URL + `/propertyasset/image` },
                data
            );

            const content = `<figure class="image"><img src="${imageUrl}"></figure>`;
            localStorage.setItem('imageContent', content);
            setCardImageUrl(imageUrl);
            setIsModalOpen(false);
            setCancel(false);
        } catch (error: any) {
            toast(t(`f:${error.response.data.errorMessage}`));
        }
    };

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };

    const getCropData = () => {
        setDoneCropping(true);
        if (typeof cropper !== 'undefined') {
            setCroppedPhoto(cropper.getCroppedCanvas().toDataURL());
            setDoneCropping(false);
            setCancel(true);
        }
    };

    const customRequest = async (options: any) => {
        const data = new FormData();
        data.append('Image', options.file);
        try {
            const imageUrl = await FileUpload.uploadFile(options, data);
            setCardImageUrl(imageUrl);
            setIsModalOpen(true);
        } catch (error: any) {
            toast(t(`f:${error.response.data.errorMessage}`));
        }
    };

    const finishEditingOfItem = () => {
        setIsActive(false);
        setFinishEditing(false);
        setCancel(false);
        const newArray = [...contentBody];
        newArray[
            indexOfItem
        ] = `<figure class="image"><img src="${cardImageUrl}"></figure><h2 class="gallery-image-item">${cardTitle}</h2><p>${cardText}</p>`;
        setContentBody(newArray);
        props.setContent(JSON.stringify({ title: title, content: newArray }).toString());
    };

    const editBlock = (item: any) => {
        setFinishEditing(true);
        const div = document.createElement('div');
        div.innerHTML = item;
        const img: any = div.getElementsByTagName('figure');
        const text: any = div.getElementsByTagName('p');
        const heading: any = div.getElementsByTagName('h2');

        setCardImageUrl(img ? img['0'].firstChild.src : '');
        setCardText(text ? text['0'].innerText : '');
        setCardTitle(heading['0'] ? heading['0'].innerText : '');

        setIsActive(true);
    };

    const handleClose = useCallback(() => {
        setIsModalOpen(false);
    }, [setIsModalOpen]);

    const onTitleChanged = useCallback((value) => {
        setTitle(value);
        const contentFromStorage = localStorage.getItem('content');
        let data: { content: any; title: any; };
        if (contentFromStorage) {
            try {
                data = JSON.parse(contentFromStorage);
            } catch {
                data = { title: '', content: [] }
            }
        } else {
            data = { title: '', content: [] }
        }

        data.title = value;
        localStorage.setItem('content', JSON.stringify(data));
    }, []);

    return (
        <div className="row-flex-clear rich-text-editor-container mb-100">
            <div className="col-xs-12 ">
                <div className="col-xs-12">
                    <MainHeading text={t('f:propertySummary.content')} />
                </div>
                <div className="col-xs-12 my-20 form-group">
                    <label>{t('f:property.title')}</label>
                    <input
                        name="heading"
                        value={title}
                        placeholder={t('f:property.title')}
                        onChange={(e) => onTitleChanged(e.target.value)}
                    />
                </div>
                <div className="col-xs-12">
                    <div className="row grid-container">
                        {contentBody?.length > 0 &&
                            contentBody?.map((item, index) => {
                                return (
                                    <div key={index} className={`col-md-${24 / props.length} grid-item`}>
                                        <Markdown>{item}</Markdown>
                                        {!isActive && (
                                            <button
                                                className="button-main button-secondary my-20 grid-button"
                                                onClick={() => {
                                                    setIndexOfItem(index);
                                                    editBlock(item);
                                                }}
                                            >
                                                {t('f:button.edit')}
                                            </button>
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>
                {isActive && (
                    <div className="col-xs-12">
                        <div className="col-xs-12">
                            <div className="form-group mb-100 col-xs-6-clear">
                                <MainHeading text={t('f:portfolioPage.uploadPhotos')} addClasses="mb-30" />

                                {cardImageUrl && (
                                    <div style={{ visibility: 'visible' }}>
                                        <FontAwesomeIcon
                                            onClick={() => {
                                                setIsModalOpen(true);
                                            }}
                                            icon={faPencilAlt}
                                            className="photo__pencil photo__pencil--rich-text"
                                        />
                                    </div>
                                )}
                                {cardImageUrl ? (
                                    <img src={cardImageUrl} className="newsletter-builder__def-image" alt="property" />
                                ) : (
                                    <br />
                                )}
                                <div className="col-xs-12">
                                    <Upload
                                        name="Image"
                                        className="add-property-image-builder__upload add-property-image-builder__upload--template"
                                        showUploadList={false}
                                        action={process.env.REACT_APP_API_URL + `/propertyasset/image`}
                                        customRequest={(options: any) => {
                                            customRequest(options);
                                        }}
                                    >
                                        <Tooltip
                                            placement="bottomRight"
                                            color="#fd615a"
                                            title={t('f:property.photoDimensionRecommended')}
                                        >
                                            <Button>
                                                <p className="teams-export__icon">{t('f:button.upload')}</p>
                                            </Button>
                                        </Tooltip>
                                    </Upload>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12">
                            <div className="col-xs-6 form-group mb-30">
                                <label htmlFor="text">{t('f:propertySummary.title')}</label>
                                <Input
                                    style={{ borderRadius: '4px' }}
                                    id="text"
                                    name="cardTitle"
                                    value={cardTitle ? cardTitle : undefined}
                                    placeholder={t('f:propertySummary.enterTitle')}
                                    onChange={(event: any) => {
                                        setCardTitle(event.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12">
                            <div className="col-xs-6 form-group mb-30">
                                <label htmlFor="text">{t('f:propertySummary.enterContent')}</label>
                                <textarea
                                    id="text"
                                    name="cardText"
                                    value={cardText ? cardText : undefined}
                                    placeholder={t('f:propertySummary.enterContent')}
                                    className="add-property-image-builder__textarea"
                                    onChange={(event: any) => {
                                        setCardText(event.target.value);
                                    }}
                                />
                            </div>
                        </div>

                        {!finishEditing && (
                            <button className="button-main button-primary my-20" onClick={saveBlock}>
                                {t('f:button.save')}
                            </button>
                        )}

                        {finishEditing && (
                            <button
                                className="button-main button-secondary my-20 grid-button"
                                onClick={() => {
                                    finishEditingOfItem();
                                }}
                            >
                                {t('f:button.finish')}
                            </button>
                        )}
                    </div>
                )}
                {!isActive && (
                    <div className="col-xs-12">
                        <button className="button-main button-primary" onClick={addNewBlock}>
                            {t('f:button.addNewBlock')}
                        </button>
                    </div>
                )}
            </div>
            <ModalWrapper
                isModalOpened={isModalOpen}
                title={t('f:property.cropImage')}
                className="gallery-modal"
                isCloseModalShown={true}
                closeModalHandler={handleClose}
                isFooterShown={false}
            >
                <div className="col-xs-12-clear gallery-modal__main mt-50">
                    <Cropper
                        style={{ height: 480, width: 500 }}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={cardImageUrl}
                        viewMode={1}
                        guides={true}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                </div>
                <div className="col-xs-12 d-flex justify-content-center">
                    {cancel ? (
                        <>
                            <button
                                className="col-xs-3 button-main button-primary mr-10"
                                onClick={(e: any) => {
                                    finishCropping();
                                }}
                            >
                                {t('f:button.upload')}
                            </button>
                            <button
                                className="col-xs-3 button-main button-secondary"
                                disabled={doneCropping}
                                onClick={() => {
                                    setCancel(true);
                                }}
                            >
                                {t('f:button.cancel')}
                            </button>
                        </>
                    ) : (
                        <button
                            className="col-xs-3 button-main button-primary"
                            disabled={doneCropping}
                            onClick={getCropData}
                        >
                            {doneCropping ? 'Cropping...' : 'Crop Image'}
                        </button>
                    )}
                </div>
            </ModalWrapper>
        </div>
    );
};
