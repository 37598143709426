import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AccountSettings from '../pages/AccountSettings';
const AccountRoute = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/:stepName`}>
                <AccountSettings />
            </Route>
        </Switch>
    );
};

export default AccountRoute;
