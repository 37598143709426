import { DataGrid } from '@material-ui/data-grid';
import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MainLoader from '../../../../../components/shared/MainLoader';
import FeatureState from '../../../../../context/state/FeatureState';
import { InvestorUser } from '../../../../../interfaces/investor/InvestorUser';
import InvestorService from '../../../../../services/InvestorsService';
import { checkHeight } from '../../../../../utils/checkHeight';
import TreeDotsSingleInvestor from '../partials/TreeDotsSingleInvestor';
import { toast } from 'react-toastify';

interface InvestorsTableUserProps {
    listOfUsers: Array<InvestorUser>;
    isUsersLoaded: boolean;
    investorUsersPage: number;
    setInvestorUsersPage: (prev: number) => void;
    totalInvestorUsers: number;
    refetch: () => void;
    showPagination?: boolean;
    className?: string;
}

const InvestorsTableUser: FunctionComponent<InvestorsTableUserProps> = ({
    listOfUsers,
    isUsersLoaded,
    investorUsersPage,
    setInvestorUsersPage,
    totalInvestorUsers,
    refetch,
    showPagination = true,
    className
}: InvestorsTableUserProps) => {
    const { propertySummary } = useContext(FeatureState),
        { id: propertyAssetId } = propertySummary;
    const { t } = useTranslation(['f', 'b']);

    const tableLayout = [
        {
            field: 'fullName',
            headerName: t('f:teams.name'),
            flex: 1,
            sorter: (a: any, b: any) => a.companyName.localeCompare(b.companyName),
        },

        {
            field: 'companyName',
            headerName: t('f:teams.company'),
            flex: 1,
            renderCell: (props) => props.row.companyName,
        },
        {
            field: 'ndaStatus',
            headerName: t('f:teams.ndaStatus'),
            flex: 1,
            renderCell: (props) => (props.row.ndaStatus ? t('f:teams.signed') : t('f:teams.notSigned')),
        },
        {
            field: 'bidStatus',
            sorter: (a: any, b: any) => a.companyId - b.companyId,
            headerName: t('f:teams.bidStatus'),
            flex: 1,
            renderCell: (props) => (props.row.bidStatus ? t('f:teams.bid') : t('f:teams.noOffer')),
        },
        {
            field: 'actions',
            headerName: t('f:teams.actions'),
            renderCell: (props) => <TreeDotsSingleInvestor user={props.row} handleSignNda={toggleNDA}/>,
            sortable: false
        }
    ];

    const toggleNDA = useCallback(
        async (id) => {
            try {
                await InvestorService.toggleNDAstatusUser(`${propertyAssetId}`, id);
                refetch();
            } catch (e: any) {
                toast(t(`b:${e.response.data.errorMessage}`));
            }
        },
        [propertyAssetId]
    );

    const onPageChange = (page: number) => {
        setInvestorUsersPage(page);
    };

    return (
        <div style={{ height: checkHeight(listOfUsers), width: '100%' }} className={className}>
            {isUsersLoaded ? (
                <DataGrid
                    rows={listOfUsers}
                    columns={tableLayout}
                    pageSize={20}
                    page={investorUsersPage}
                    paginationMode={'server'}
                    rowCount={totalInvestorUsers}
                    onPageChange={onPageChange}
                    disableSelectionOnClick
                    hideFooterPagination={!showPagination}
                    hideFooter={!showPagination}
                />
            ) : (
                <MainLoader type="regular" />
            )}
        </div>
    );
};
export default InvestorsTableUser;
