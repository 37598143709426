import { FunctionComponent } from 'react';
import { Dropdown, Menu } from 'antd';
import { NodePermission } from '../../../../../interfaces/dataRoom/usersWithPermissions';
import { useTranslation } from 'react-i18next';

interface TreeDotsDropDownProps {
    permissions: Array<NodePermission>;
    id: string;
    handleClick: (addOrDelete: boolean, id: string, permission: number) => void;
}

const TreeDotsDropDown: FunctionComponent<TreeDotsDropDownProps> = ({
    permissions,
    handleClick,
    id,
}: TreeDotsDropDownProps) => {
    const { t } = useTranslation(['f', 'b']);

    const fileMenu = (id: string) => {
        return (
            <Menu
                onClick={(e: any) => {
                    handleClick(e.key !== 2 ? true : false, id, e.key);
                }}
            >
                {Object.keys(permissions).map((key: any) => {
                    return <Menu.Item key={key}>{t(`f:dataRoom.permissions.${key}`)}</Menu.Item>;
                })}
            </Menu>
        );
    };
    return (
        <Dropdown.Button
            onClick={() => {}}
            className="data-room-permissions-container__user-permissions"
            overlay={fileMenu(id)}
        />
    );
};

export default TreeDotsDropDown;
