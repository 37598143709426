import { FunctionComponent } from 'react';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Actions } from '../../../../../interfaces/general/general';

interface Props {
    setIsThreeDotClicked: (prev: boolean) => void;
    setIsModalOpened: Actions;
    templateEditHandler: () => void;
    isThreeDotClicked: boolean;
    isMap?: boolean;
}

const TreeDotsComponent: FunctionComponent<Props> = ({
    isThreeDotClicked,
    setIsThreeDotClicked,
    setIsModalOpened,
    templateEditHandler,
    isMap,
}) => {
    const { t } = useTranslation(['f', 'b']);

    const content = (
        <div
            onMouseLeave={() => {
                setIsThreeDotClicked(false);
            }}
        >
            <span
                style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                onClick={() => {
                    setIsThreeDotClicked(false);
                    templateEditHandler();
                }}
            >
                {t('f:button.edit')}
            </span>
            <br></br>
            <span
                style={{ color: '#a1abbd', cursor: 'pointer', fontWeight: 'bolder' }}
                onClick={() => {
                    setIsThreeDotClicked(false);
                    setIsModalOpened.setTrue();
                }}
            >
                {t('f:button.remove')}
            </span>
        </div>
    );
    return (
        <div className={`tree-dots ${isMap ? 'tree-dots__map' : ''}`}>
            <>
                <FontAwesomeIcon
                    className="mxl-10"
                    style={{ color: '#a1abbd', cursor: 'pointer' }}
                    icon={faEllipsisH}
                    size="lg"
                    onClick={() => {
                        setIsThreeDotClicked(!isThreeDotClicked);
                    }}
                ></FontAwesomeIcon>
                <div style={{ marginLeft: '20px' }}>
                    <Popover content={content} placement="bottom" trigger="click" visible={isThreeDotClicked}></Popover>
                </div>
            </>
        </div>
    );
};
export default TreeDotsComponent;
