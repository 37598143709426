import ExecuteNdaApi from '../api/routes/ExecuteNdaApi';

const fetchCanSign = async () => {
    const { data } = await ExecuteNdaApi.fetchCanSign();
    return data;
};

const fetchUsersWhoCanSign = async () => {
    const { data } = await ExecuteNdaApi.fetchUsersWhoCanSign();
    return data;
};

const createNdaForProperty = async (propertyAssetId: string, body: any) => {
    const { data } = await ExecuteNdaApi.createNdaForProperty(propertyAssetId, body);
    return data;
};

const requestSignIn = async (propertyAssetId: string, userId: string) => {
    const { data } = await ExecuteNdaApi.requestSignIn(propertyAssetId, userId);
    return data;
};

const fetchCustomNDA = async (propertyAssetId: string) => {
    const { data } = await ExecuteNdaApi.fetchCustomNDA(propertyAssetId);
    return data;
};
const finishPowerForm = async (body: any) => {
    const { data } = await ExecuteNdaApi.finishPowerForm(body);
    return data;
};

const fetchDocuSign = async (propertyAssetId: string) => {
    const { data } = await ExecuteNdaApi.fetchDocuSign(propertyAssetId);
    return data;
};

const fetchHistory = async (propertyAssetId: string, companytId: string) => {
    const { data } = await ExecuteNdaApi.fetchHistory(propertyAssetId, companytId);
    return data;
};

const fetchStatusOfNDAPrices = async (propertyAssetId: string) => {
    const { data } = await ExecuteNdaApi.fetchStatusOfNDAPrices(propertyAssetId);
    return data;
};

const uploadNDA = async (option: any, companyId: string) => {
    const body = new FormData();
    body.append('companyId', companyId);
    body.append('File', option.file);
    const { data } = await ExecuteNdaApi.uploadNDA(option, body);
    return data;
};

const ExecuteNdaService = {
    fetchCanSign,
    requestSignIn,
    finishPowerForm,
    fetchStatusOfNDAPrices,
    fetchCustomNDA,
    uploadNDA,
    fetchHistory,
    fetchDocuSign,
    fetchUsersWhoCanSign,
    createNdaForProperty,
};

export default ExecuteNdaService;
