import DataAnalyticPageBuilderApi from '../api/routes/DataAnalyticPageBuilderApi';

const featchSettings = async () => {
    const { data } = await DataAnalyticPageBuilderApi.featchSettings();
    return data;
};

const createPage = async (body: any) => {
    const { data } = await DataAnalyticPageBuilderApi.createPage(body);
    return data;
};

const editPage = async (body: any) => {
    const { data } = await DataAnalyticPageBuilderApi.editPage(body);
    return data;
};

const fetchPages = async () => {
    const { data } = await DataAnalyticPageBuilderApi.fetchPages();
    return data;
};

const deletePage = async (pageId: string) => {
    const { data } = await DataAnalyticPageBuilderApi.deletePage(pageId);
    return data;
};

const editPageStatusActive = async (pageId: string) => {
    const { data } = await DataAnalyticPageBuilderApi.editPageStatusActive(pageId);
    return data;
};

const editPageStatusDeactive = async (pageId: string) => {
    const { data } = await DataAnalyticPageBuilderApi.editPageStatusDeactive(pageId);
    return data;
};

const createSection = async (pageId: string, body: any) => {
    const { data } = await DataAnalyticPageBuilderApi.createSection(pageId, body);
    return data;
};

const editSection = async (pageId: string, body: any) => {
    const { data } = await DataAnalyticPageBuilderApi.editSection(pageId, body);
    return data;
};

const featchSections = async (pageId: string) => {
    const { data } = await DataAnalyticPageBuilderApi.featchSections(pageId);
    return data;
};

const deleteSection = async (pageId: string, sectionId: string) => {
    const { data } = await DataAnalyticPageBuilderApi.deleteSection(pageId, sectionId);
    return data;
};

const createSectionData = async (pageId: string, pageSectionId: string, body: any) => {
    const { data } = await DataAnalyticPageBuilderApi.createSectionData(pageId, pageSectionId, body);
    return data;
};

const editSectionData = async (pageId: string, pageSectionId: string, dataId: string, body: any) => {
    const { data } = await DataAnalyticPageBuilderApi.editSectionData(pageId, pageSectionId, dataId, body);
    return data;
};

const featchSectionsData = async (pageId: string, pageSectionId: string, dataId: string) => {
    const { data } = await DataAnalyticPageBuilderApi.featchSectionsData(pageId, pageSectionId, dataId);
    return data;
};

const deleteSectionData = async (pageId: string, pageSectionId: string, dataId: string) => {
    const { data } = await DataAnalyticPageBuilderApi.deleteSectionData(pageId, pageSectionId, dataId);
    return data;
};

const DataAnalyticPageBuilderService = {
    featchSettings,
    createPage,
    editPage,
    fetchPages,
    deletePage,
    editPageStatusActive,
    editPageStatusDeactive,
    createSection,
    editSection,
    featchSections,
    deleteSection,
    createSectionData,
    deleteSectionData,
    editSectionData,
    featchSectionsData,
};
export default DataAnalyticPageBuilderService;
