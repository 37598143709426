import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { faFolderPlus, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dropdown, Menu, message, Tree } from 'antd';
import filesize from 'filesize';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { ChangeEventHandler, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import dragDrop from '../../../assets/images/data-room/drag-drop.svg';
import file from '../../../assets/images/data-room/file.svg';
import emprty_folder from '../../../assets/images/empoty-file.svg';
import CreateDataForEmptySection from '../../../components/shared/CreateDataForEmptySection';
import MainLoader from '../../../components/shared/MainLoader';
import ModalConfirmDelete from '../../../components/shared/ModalConfirmDelete';
import PropertyAssetDropdown from '../../../components/shared/PropertyAssetDropdown';
import ShareWithModal from '../../../components/shared/ShareWithModal';
import ModalWrapper from '../../../components/wrapper/ModalWrapper';
import FeatureState from '../../../context/state/FeatureState';
import useBoolean from '../../../hooks/use-boolean';
import DataNode from '../../../interfaces/dataRoom/dataNode';
import DataRoomService from '../../../services/DataRoom';
import DraggerDropFile from '../components/dataRoom/DraggerDropFile';
import AddEditItemModal from './dataRoom/AddEditItemModal';
import DataRoomIcon from './dataRoom/dataRoomIcon';
import PreviewFileModal from './partials/PreviewFileModal';

const { DirectoryTree } = Tree;
const TreeNode = Tree.TreeNode;

enum Action {
    AddFolder = 'AddFolder',
    Edit = 'Edit',
    Delete = 'Delete',
    Download = 'Download',
    Permissions = 'Permissions',
}

const root = 'ROOT';

interface Props {
    editable: boolean;
}
interface FilePreview {
    name: string;
    url: string;
    type: string;
}

interface FileNavigation {
    id: string;
    name: string;
    parentId: string | null;
    nodes: Array<DataNode>;
}

export const DataRoom: FunctionComponent<any> = (props: Props) => {
    const { t } = useTranslation(['f', 'b']),
        [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState<boolean>(false),
        [dragEffect, setDraggActions] = useBoolean(),
        [isDeleteModalOpen, setIsDeleteModalOpenActions] = useBoolean(false),
        [isAddEditOpen, setIsAddEditOpen] = useState<boolean>(false),
        [isAddFolder, setIsAddFolder] = useState<boolean>(false),
        [isLoading, setIsLoadingActions] = useBoolean(),
        [allCheckedFiles, setAllCheckedFiles] = useState<Array<string>>([]),
        [selectedFolderId, setSelectedFolderId] = useState<string>(''),
        [selectedNodeForEdit, setSelectedNodeForEdit] = useState<DataNode | null>(null),
        [propertyId, setPropertyId] = useState<string>(''),
        [isEditable, setIsEditable] = useState<boolean>(false),
        [data, setData] = useState<Array<DataNode>>([]),
        [currentNode, setCurrentNode] = useState<DataNode | null>(null),
        [isUploading, setIsUploading] = useState(false),
        [foldersArray, setFoldersArray] = useState<Array<FileNavigation>>([]),
        [folderNodes, setFolderNodes] = useState<Array<any>>([]),
        [fileToPreview, setfileToPreview] = useState<FilePreview | undefined>(),
        [expandedKeysMine, setExpandedKeysMine] = useState<Array<string>>([]),
        { getFeature } = useContext(FeatureState),
        [isStringForSearchThere, isStringForSearchThereActions] = useBoolean(),
        [fileList, setFileList] = useState<Array<any>>([]),
        [failedUploads, setFailedUploads] = useState<Array<any>>([]),
        [fileName, setFileName] = useState<string | null>(null),
        { propertySummary } = useContext(FeatureState),
        { id: propertyAssetid } = propertySummary,
        [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);

    const modalClosed = () => {
        setIsPermissionsModalOpen(false);
    };

    const fetchData = useCallback(
        async (isFirsLoad: boolean, folderId: string) => {
            setFileList([]);
            if (propertyId) {
                setIsLoadingActions.setFalse();
                const response = await DataRoomService.fetchDataRoom(propertyId);

                isFirsLoad
                    ? (() => {
                        setFolderNodes(response);
                        setFoldersArray([]);
                        setFileName('');
                    })()
                    : findActivFolder(folderId, response, foldersArray);

                setData(response);
                setIsLoadingActions.setTrue();
            }
        },
        [propertyId]
    );

    const findActivFolder = (id: string, data: Array<DataNode>, _foldersArray: Array<FileNavigation>) => {
        id
            ? (() => {
                let nodes: Array<DataNode> = [];

                for (let i = 0; i < data.length; i++) {
                    if (data[i].id === id) {
                        setCurrentNode(data[i]);
                        const new_array = [..._foldersArray];
                        new_array.push({
                            name: data[i].name,
                            id: id,
                            parentId: data[i].parentId,
                            nodes: data[i].nodes,
                        });
                        nodes = data[i].nodes;
                        setFoldersArray(new_array);
                        break;
                    } else {
                        findActivFolder(id, data[i].nodes, _foldersArray);
                    }
                }

                setFolderNodes(nodes);
            })()
            : (() => {
                setFoldersArray([]);
                setFileName('');
                setFolderNodes(data);
            })();
    };

    const customRequest = async (options: any) => {
        setIsUploading(true);
        const data = new FormData();
        data.append('data', options.file);
        data.append('referenceId', propertyId);
        if (selectedFolderId) data.append('folderId', selectedFolderId);

        try {
            const result = await DataRoomService.uploadFile(options, data);
            if (result.isSuccess) {
                options.onSuccess(result.data, options.file);
                setUploadedFiles(prevUploadedFiles => ([...prevUploadedFiles, result.data]));
            } else {
                setFailedUploads([...failedUploads, options]);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setDraggActions.setFalse();
        }
    };

    const handleChange = (info) => {
        setFileList([...info.fileList]);
    };

    const onSelect = (keys: React.Key[], info: any) => {
        setFileName('');

        const id = keys[0].toString();

        setSelectedFolderId(id);

        if (isStringForSearchThere) {
            const nodes: Array<FileNavigation> = [];
            findPathActivFolderById(nodes, id, data);

            while (nodes[nodes.length - 1].parentId !== null) {
                findPathActivFolderById(nodes, nodes[nodes.length - 1].parentId, data);
            }

            const reverse = nodes.reverse();
            setFoldersArray(reverse.slice(0, nodes.length - 1));
            setFolderNodes(reverse[nodes.length - 2].nodes);
            isStringForSearchThereActions.setFalse();
        } else {
            findActivFolder(id, data, foldersArray);
        }
    };

    function handleButtonClick(e) {
        e.stopImmediatePropagation();
        message.info('Click on left button.');
    }

    const handleMenuClick = (e: any, item: DataNode) => {
        if (item.isFolder) setSelectedFolderId(item.id);

        setSelectedNodeForEdit(item);

        if (e.key === Action.Permissions) {
            setIsPermissionsModalOpen(true);
        } else if (e.key === Action.Delete) {
            setIsDeleteModalOpenActions.setTrue();
        } else if (e.key === Action.Edit) {
            setIsAddFolder(false);
            setIsAddEditOpen(true);
        } else if (e.key === Action.AddFolder) {
            setIsAddFolder(true);
            setIsAddEditOpen(true);
        }
    };

    const onSavedAddEdit = (id: string, name: string) => {
        try {
            fetchData(false, selectedFolderId);
        } catch (err: any) {
            toast(t(`b:${err.response.date.errorMessage}`));
        }
    };

    const onDragEnd = ({ event, node }) => {
        try {
        } catch (err) {
            // toast(t(`b:${err.response.date.errorMessage}`));
        }
    };

    const menu = (item: DataNode) => {
        return (
            <Menu
                onClick={(event) => {
                    handleMenuClick(event, item);
                }}
            >
                <Menu.Item key={Action.AddFolder} icon={<PlusOutlined />}>
                    {t(`f:dataRoom.addFolder`)}
                </Menu.Item>
                <Menu.Item key={Action.Edit} icon={<EditOutlined />}>
                    {t(`f:dataRoom.editFolder`)}
                </Menu.Item>
                <Menu.Item key={Action.Delete} icon={<DeleteOutlined />}>
                    {t(`f:dataRoom.deleteFolder`)}
                </Menu.Item>
                <Menu.Item key={Action.Permissions} icon={<UserOutlined />}>
                    {t(`f:dataRoom.permissionsName`)}
                </Menu.Item>
            </Menu>
        );
    };

    const fileMenu = (item: DataNode) => {
        return (
            <Menu
                onClick={(event) => {
                    handleMenuClick(event, item);
                }}
            >
                {isEditable && (
                    <Menu.Item key={Action.Delete} icon={<DeleteOutlined />}>
                        {t(`f:dataRoom.deleteFile`)}
                    </Menu.Item>
                )}
                <Menu.Item icon={<DownloadOutlined />}>
                    <a href={item.url} download className="ant-dropdown-menu-item" style={{ padding: 0 }}>
                        {t(`f:dataRoom.download`)}
                    </a>
                </Menu.Item>
            </Menu>
        );
    };

    const renderItem = (item: DataNode) => {
        return item.isFolder ? renderFolder(item) : renderFile(item);
    };

    const renderUpdateDate = (date: Date) => {
        const updateDate = moment(date);
        return updateDate.isValid() && updateDate.year() > 2000 ? updateDate.format('MMMM Do YYYY') : '';
    };

    const handleOnCheck = (key, mee) => {
        mee.node.key === 'upload'
            ? (() => {
                mee.node.checked
                    ? setAllCheckedFiles([])
                    : setAllCheckedFiles([...folderNodes.map((elem) => elem.id), mee.node.key]);
            })()
            : (() => {
                allCheckedFiles.includes(mee.node.key)
                    ? setAllCheckedFiles(allCheckedFiles.filter((elem) => elem !== mee.node.key))
                    : setAllCheckedFiles([...allCheckedFiles, mee.node.key]);
            })();
    };

    const resolveFileExtension = (fileName: string): string => {
        var slipt = fileName.split('.');

        if (slipt[slipt.length - 1] === fileName) {
            return '';
        }

        return slipt[slipt.length - 1];
    }

    const renderFile = (item: DataNode) => {
        return (
            <div
                className="d-flex justify-content-space-between"
                onClick={() => {
                    setFileName(item.name);
                }}
            >
                <span
                    onDoubleClick={() => {
                        setfileToPreview({ name: item.name, url: item.url, type: resolveFileExtension(item.name) });
                    }}
                >
                    <span>{item.name.length > 30 ? `${item.name.slice(0, 30)}...` : item.name}</span>
                </span>
                {!isMobile &&
                    <div
                        className="d-flex align-items-center"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <span className="mr-10">{item.createdBy}</span>
                        <span className="data-room__first-node-info-size">{filesize(item.size)}</span>
                        <span className="mr-50">{renderUpdateDate(item.createdDate)}</span>
                        <Dropdown.Button
                            className="context-menu"
                            onClick={handleButtonClick}
                            overlay={fileMenu(item)}
                        ></Dropdown.Button>
                    </div>
                }
            </div>
        );
    };

    const renderFolder = (item: DataNode) => {
        return (
            <div className="d-flex justify-content-space-between">
                <span>{item.name.length > 30 ? `${item.name.slice(0, 30)}...` : item.name}</span>
                {!isMobile &&
                    <div
                        className="d-flex align-items-center"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <span className="mr-10">{item.createdBy}</span>
                        <span className="data-room__first-node-info-size">{item.nodes.length}</span>
                        <span className="mr-50">{renderUpdateDate(item.createdDate)}</span>
                        {isEditable && (
                            <Dropdown.Button
                                className="context-menu"
                                onClick={handleButtonClick}
                                overlay={menu(item)}
                            ></Dropdown.Button>
                        )}
                    </div>
                }
            </div>
        );
    };

    const RenderNode = (item: DataNode) => {
        const extension = item.name?.split('.')?.pop();
        return (
            <TreeNode
                isLeaf={!item.isFolder}
                icon={<DataRoomIcon extension={extension} isFolder={item.isFolder} />}
                selectable={item.isFolder}
                title={renderItem(item)}
                key={item.id}
            >
                {item.nodes && renderNodes(item.nodes)}
            </TreeNode>
        );
    };

    const renderNodes = (nodes: Array<DataNode>) => {
        return nodes?.map((item: DataNode) => {
            return RenderNode(item);
        });
    };

    const handleClickAddFile = useCallback(() => {
        setIsAddFolder(true);
        setSelectedNodeForEdit(currentNode);
        setIsAddEditOpen(true);
    }, [currentNode]);
    const renderButtons = () => {
        return (
            <div className="ml-auto d-flex buttons data-room__buttons-container">
                <Button
                    className="button-secondary button-small teams-button"
                    onClick={() => {
                        document.getElementById('file-uploader')?.click();
                    }}
                >
                    {t('f:dataRoom.upload')}
                    <FontAwesomeIcon className="teams-export__icon" icon={faUpload} size="sm" />
                </Button>

                <Button
                    className="button-secondary button-small teams-button "
                    onClick={async () => {
                        for (let i = 0; i < allCheckedFiles.length; i++) {
                            await DataRoomService.removeFromDataRoom(allCheckedFiles[i]);
                        }
                        fetchData(false, selectedFolderId);
                    }}
                >
                    {t('f:dataRoom.delete')}
                    <FontAwesomeIcon className="teams-export__icon" icon={faTrash} size="sm" />
                </Button>
                <Button className="button-secondary button-small teams-button" onClick={handleClickAddFile}>
                    {t('f:dataRoom.addFolder')}
                    <FontAwesomeIcon className="teams-export__icon" icon={faFolderPlus} size="sm" />
                </Button>
            </div>
        );
    };

    const handleDropDownSelect = async (propertyId: string) => {
        setPropertyId(propertyId);
    };

    const findActivFolderByName = (nodes: Array<DataNode>, searchString: string, data: Array<DataNode>) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
                nodes.push(data[i]);
            }
            if (data[i].nodes.length) {
                findActivFolderByName(nodes, searchString, data[i].nodes);
            }
        }
        return nodes;
    };
    const findPathActivFolderById = (nodes: Array<FileNavigation>, id: string | null, data: Array<DataNode>) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === id) {
                nodes.push({ id: data[i].id, name: data[i].name, parentId: data[i].parentId, nodes: data[i].nodes });
                break;
            }
            if (data[i].nodes.length) {
                findPathActivFolderById(nodes, id, data[i].nodes);
            }
        }
    };

    const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.currentTarget.value !== '') {
            const nodes: Array<DataNode> = [];
            const filtered = findActivFolderByName(nodes, event.currentTarget.value, data);
            setFolderNodes(filtered);
            isStringForSearchThereActions.setTrue();
        } else {
            isStringForSearchThereActions.setFalse();
            handleOnClickDataRoom();
        }
    };

    const handleModalFinish = useCallback(async () => {
        if (selectedNodeForEdit) {
            await DataRoomService.removeFromDataRoom(selectedNodeForEdit.id);

            fetchData(false, foldersArray.length ? foldersArray[foldersArray.length - 1].id : '');
            setSelectedFolderId('');
            setSelectedNodeForEdit(null);
        }

        setIsDeleteModalOpenActions.setFalse();
    }, [setIsDeleteModalOpenActions, selectedNodeForEdit]);

    const handleOnClickDataRoom = useCallback(() => {
        setFolderNodes(data);
        setSelectedFolderId('');
        setFileName('');
        setFoldersArray([]);
        setCurrentNode(null);
    }, [data]);

    useEffect(() => {
        fetchData(true, '');
        setIsEditable(getFeature('DataRoomEdit'));
    }, [getFeature, fetchData]);

    useEffect(() => {
        setPropertyId(`${propertyAssetid}`);
    }, [propertyAssetid]);

    useEffect(() => {
        setIsUploading(uploadedFiles.length + failedUploads.length < fileList.length);
    }, [uploadedFiles, failedUploads, fileList.length]);

    useEffect(() => {
        if (!isUploading && uploadedFiles?.length > 0) {
            fetchData(false, selectedFolderId);
        }
    }, [isUploading, fetchData, uploadedFiles?.length]);

    const searchBoxColClassName = isMobile ? "col-xs-12" : "col-xs-6";

    return (
        <>
            <div
                className="wrapper"
                onDragEnter={() => {
                    setDraggActions.setTrue();
                }}
                onMouseLeave={() => {
                    setDraggActions.setFalse();
                }}
            >
                <div className="mx-50 d-flex align-items-center">
                    <div className={searchBoxColClassName}>
                        <div className="search">
                            <Formik initialValues={{ searchInput: '' }} onSubmit={(values) => { }}>
                                {({ handleBlur }) => (
                                    <Form className="search-form d-flex justify-content-center">
                                        <input
                                            type="text"
                                            placeholder={t('f:dataRoom.search')}
                                            onChange={handleSearchInputChange}
                                            onBlur={handleBlur}
                                            name="searchInput"
                                        />
                                        <button className="button-main button-primary" type="submit">
                                            <FontAwesomeIcon icon={['fas', 'search']} size="lg" />
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    {isEditable && !isMobile && renderButtons()}
                </div>
                <div className="col-xs-6 mt-20">
                    <PropertyAssetDropdown
                        id={`${propertyAssetid}`}
                        handleDropDownSelect={handleDropDownSelect}
                        classForDrop="col-xs-12-clear col-md-4-clear"
                    />
                </div>
                <div className="mx-50 data-room">
                    {isLoading ? (
                        data && data.length > 0 ? (
                            <div className="data-room__nodes-container">
                                <p className="ml-20 mt-20 mb-20 data-room__containers-pointers">
                                    <span onClick={handleOnClickDataRoom} className="data-room__pointers">{`${t(
                                        'f:pricing.dataRoom'
                                    )} `}</span>
                                    {foldersArray.map((elem, i) => (
                                        <>
                                            <span
                                                className="data-room__pointers"
                                                onClick={() => {
                                                    const new_array = foldersArray.slice(0, i - 2);
                                                    setFileName('');
                                                    setFoldersArray(new_array);
                                                    findActivFolder(elem.id, data, new_array);
                                                }}
                                            >
                                                {` > ${elem.name}`}
                                            </span>
                                        </>
                                    ))}
                                    {fileName && <span className="data-room__pointers">{` > ${fileName}`}</span>}
                                </p>
                                <DirectoryTree
                                    multiple
                                    checkable={true}
                                    selectable={isEditable}
                                    switcherIcon={<span></span>}
                                    onSelect={onSelect}
                                    onDragEnd={onDragEnd}
                                    expandedKeys={expandedKeysMine}
                                    checkedKeys={allCheckedFiles}
                                    onCheck={handleOnCheck}
                                    className="mb-80"
                                >
                                    <TreeNode
                                        isLeaf={true}
                                        checkable={false}
                                        selectable={false}
                                        key={root}
                                        className="data-room__first-node"
                                        icon={<img src={file} alt="file" className="data-room__icon ml-40" />}
                                        title={
                                            <div className="d-flex justify-content-space-between ml-20">
                                                <span>{t(`f:dataRoom.Name`)}</span>
                                                {!isMobile &&
                                                    <div className="d-flex align-items-center">
                                                        <div className="data-room__first-node-info-owner">
                                                            {t(`f:dataRoom.owner`)}
                                                        </div>
                                                        <div className="data-room__first-node-info-size">
                                                            {t(`f:dataRoom.size`)}
                                                        </div>
                                                        <div className="data-room__first-node-info-date mr-50">
                                                            {t(`f:dataRoom.createdDate`)}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    ></TreeNode>
                                    {renderNodes(folderNodes)}
                                </DirectoryTree>
                            </div>
                        ) : (
                            <div className="col-xs-12">
                                {isEditable ? (
                                    <CreateDataForEmptySection
                                        handler={handleClickAddFile}
                                        text={t(`f:button.btnCreateDataRoom`)}
                                        mainText={t(`f:button.createDataRoom`)}
                                    />
                                ) : (
                                    <div className="d-flex justify-content-center mt-100 mb-100">
                                        <img src={emprty_folder} alt="drag-drop" className="data-room__no-nodes" />
                                    </div>
                                )}
                            </div>
                        )
                    ) : (
                        <MainLoader mt="mt-50" type="regular" />
                    )}

                    {isEditable && (
                        <DraggerDropFile
                            imageUrl={dragDrop}
                            customRequest={customRequest}
                            handleChange={handleChange}
                            fileList={fileList}
                            failedUploads={failedUploads}
                            isUploading={isUploading}
                            uploadedFiles={uploadedFiles}
                            dragEffect={dragEffect}
                            folderNodes={folderNodes}
                        />
                    )}

                    {isEditable && (
                        <div className="modal--container">
                            <ModalWrapper
                                isModalOpened={isPermissionsModalOpen}
                                isCloseModalShown={true}
                                closeModalHandler={modalClosed}
                                title={t('f:dataRoom.shareWithModal.giveAccess')}
                                primaryButtonText={t('f:button.cancel')}
                                primaryButtonHandler={modalClosed}
                                isFooterShown={true}
                                className="share-with-modal"
                            >
                                <ShareWithModal isModalOpen={isPermissionsModalOpen} nodeId={selectedFolderId} />
                            </ModalWrapper>

                            <ModalConfirmDelete
                                isModalOpened={isDeleteModalOpen}
                                text={`${t('f:dataRoom.confirmDelete')} ${selectedNodeForEdit ? (selectedNodeForEdit.isFolder ? 'folder' : 'file') : ''
                                    } ${selectedNodeForEdit ? selectedNodeForEdit.name : ''} ? `}
                                setIsModalOpenedActions={setIsDeleteModalOpenActions}
                                handleFinish={handleModalFinish}
                            />

                            <AddEditItemModal
                                isModalOpened={isAddEditOpen}
                                setIsModalOpened={setIsAddEditOpen}
                                propertyId={propertyId}
                                id={isAddFolder ? null : selectedNodeForEdit?.id}
                                initialName={isAddFolder ? '' : selectedNodeForEdit?.name}
                                parentId={isAddFolder ? selectedFolderId : null}
                                onSaved={onSavedAddEdit}
                                title={isAddFolder ? t(`f:dataRoom.addFolder`) : t(`f:dataRoom.removeFolder`)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <PreviewFileModal fileData={fileToPreview} />
        </>
    );
};

export default DataRoom;
