import { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import Markdown from 'markdown-to-jsx';
import TreeDotsComponent from './TreeDotsComponent';
import useBoolean from '../../../../../hooks/use-boolean';
import ModalConfirmDelete from '../../../../../components/shared/ModalConfirmDelete';
import { useTranslation } from 'react-i18next';
import FeatureState from '../../../../../context/state/FeatureState';

const BaseComponent = (props: any) => {
    const history = useHistory(),
        { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        [isThreeDotClicked, setIsThreeDotClicked] = useState<boolean>(false),
        [isModalOpened, setIsModalOpenedActions] = useBoolean(false);

    const templateEditHandler = () => {
        history.push(
            `/property/${externalId}/template/${props.pageType}/add-content/${props.template.pageSectionType}/${props.template.id}`
        );
    };

    const deleteHandler = useCallback(() => {
        props.onRemove(props.template.id);
        setIsModalOpenedActions.setFalse();
    }, [setIsModalOpenedActions, props]);

    return (
        <div className="row my-50" style={{ position: 'relative' }}>
            {props.isEditMode && (
                <div className="col-xs-12-clear">
                    <TreeDotsComponent
                        isThreeDotClicked={isThreeDotClicked}
                        setIsThreeDotClicked={setIsThreeDotClicked}
                        setIsModalOpened={setIsModalOpenedActions}
                        isMap={props.isMap}
                        templateEditHandler={templateEditHandler}
                    />
                    <ModalConfirmDelete
                        text={t('f:property.confirmSectionDelete')}
                        isModalOpened={isModalOpened}
                        setIsModalOpenedActions={setIsModalOpenedActions}
                        handleFinish={deleteHandler}
                    />
                </div>
            )}
            <div className="col-xs-12-clear">
                <div className="main-text markdown__wrap">
                    <Markdown>{props.template.content}</Markdown>
                </div>
            </div>
        </div>
    );
};

export default BaseComponent;
