import PropertyAssetPageTemplateApi from '../api/routes/PropertyAssetPageTemplateApi';
import moment from 'moment';

const createTemplate = async (url: string, data: any): Promise<any> => {
    try {
        const response = await PropertyAssetPageTemplateApi.createTemplate(url, data);

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const fetchTemplate = async (url: string, propertyAssetId: string, id: string): Promise<any> => {
    try {
        const response = await PropertyAssetPageTemplateApi.fetchTemplate(url, propertyAssetId, id);

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const updateTemplate = async (url: string, data: any): Promise<any> => {
    try {
        const response = await PropertyAssetPageTemplateApi.updateTemplate(url, data);

        return response.data;
    } catch (err) {
        console.log(err);
    }
};

const fetchPageInfo = async (url: string, propertyAssetId: string) => {
    try {
        const { data } = await PropertyAssetPageTemplateApi.fetchPageInfo(url, propertyAssetId);

        data.forEach((item: any) => {
            if (
                item.name === 'PROPERTYFIELD_LEASHOLDSTARTDATE' ||
                item.name === 'PROPERTYFIELD_LEASHOLDEXPIRATIONDATE'
            ) {
                item.value = moment(item.value).format('DD MMM YYYY');
            }
        });

        return data;
    } catch (err) {
        console.log(err);
    }
};

const removeTemplate = async (url: string, data: any, id: string): Promise<any> => {
    try {
        return await PropertyAssetPageTemplateApi.removeTemplate(url, data, id);
    } catch (err) {
        console.log(err);
    }
};

export const fetchPropertyTabTemplates = async (url: string, id: string): Promise<any> => {
    try {
        const { data } = await PropertyAssetPageTemplateApi.fetchPropertyTabTemplates(url, id);
        return data;
    } catch (error) {
        console.log(error);
    }
};

const PropertyAssetPageTemplateService = {
    createTemplate,
    fetchTemplate,
    updateTemplate,
    fetchPageInfo,
    removeTemplate,
    fetchPropertyTabTemplates,
};
export default PropertyAssetPageTemplateService;
