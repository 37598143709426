import * as React from 'react';
import { Actions } from '../../interfaces/general/general';
import { IMarketingTimeline } from '../../interfaces/listOpporunity/ListOpportunity';

export type DeallineWizardStateType = {
    stages: Object;
    allStages: Array<IMarketingTimeline>;
    handleFinishButton: (val: Array<IMarketingTimeline>) => void;
    removeMarketingTimeline: (val: string) => void;
    isAddingOrDeletingActionLoading: boolean;
    isAddingOrDeletingActionLoadingActions: Actions;
    isAllStagesLoad: boolean;
    isAllStagesLoadActions: Actions;
};
const action = {
    setTrue: () => {},
    setFalse: () => {},
    toggle: (value?: boolean | undefined) => {},
};
export default React.createContext<DeallineWizardStateType>({
    stages: {},
    allStages: [],
    handleFinishButton: (val: Array<IMarketingTimeline>) => {},
    removeMarketingTimeline: (val: string) => {},
    isAddingOrDeletingActionLoading: false,
    isAddingOrDeletingActionLoadingActions: action,
    isAllStagesLoad: false,
    isAllStagesLoadActions: action,
});
