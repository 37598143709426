import { TextareaAutosize } from "@material-ui/core";
import TextArea from "antd/lib/input/TextArea";
import { FunctionComponent, useState } from "react";
import styles from './CompanyNoteEditor.module.scss';

interface CompanyNoteEditorProps {
    onPost: (data: string) => void;
}

const CompanyNoteEditor: FunctionComponent<CompanyNoteEditorProps> = (props) => {
    const [data, setData] = useState<string>('');

    return (
        <div className={styles.editor}>
            <TextareaAutosize
                value={data}
                minRows={2}
                placeholder="Add a comment"
                onChange={(e: any) => setData(e.target.value)}
            />
            {/* <textarea 
                rows={2}
                value={data}
                wrap="physical"
                onChange={(e: any) => setData(e.target.value)}
            >
            </textarea> */}
            <div
                className={styles.postBtn}
                onClick={() => {
                    props.onPost(data);
                    setData('');
                }}>
                <button>Post</button>
            </div>
        </div>
    );
}

export default CompanyNoteEditor;