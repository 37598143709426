export const FormikErrorArrayDeep = ({ errors, touched, index, array, name }) => {
    return errors &&
        errors[array] &&
        errors[array][index] &&
        errors[array][index][name] &&
        touched &&
        touched[array] &&
        touched[array][index] &&
        touched[array][index][name] ? (
        <p className="error-text error-text-absolute">{errors[array][index][name]}</p>
    ) : (
        <></>
    );
};

export const FormikErrorTwoArrayDeep = ({ errors, touched, index, array, subArray, subArrayIndex, name }) => {
    return errors &&
        errors[array] &&
        errors[array][index] &&
        errors[array][index][subArray] &&
        errors[array][index][subArray][subArrayIndex] &&
        errors[array][index][subArray][subArrayIndex][name] &&
        touched &&
        touched[array] &&
        touched[array][index] &&
        touched[array][index][subArray] &&
        touched[array][index][subArray][subArrayIndex] &&
        touched[array][index][subArray][subArrayIndex][name] ? (
        <p className="error-text error-text-absolute">{errors[array][index][subArray][subArrayIndex][name]}</p>
    ) : (
        <></>
    );
};

export const FormikChartArrayError = ({ errors, touched, index, array }) => {
    return errors &&
        errors[array] &&
        errors[array][index] &&
        errors[array][index] &&
        touched &&
        touched[array] &&
        touched[array][index] &&
        touched[array][index] ? (
        <p className="error-text error-text-absolute">{errors[array][index]}</p>
    ) : (
        <></>
    );
};

export const FormikChartFieldError = ({ errors, touched, name }) => {
    return errors && errors[name] && touched && touched[name] ? (
        <p className="error-text error-text-absolute">{errors[name]}</p>
    ) : (
        <></>
    );
};
