import ListOpportunityApi from '../api/routes/ListOpportunityApi';
import { CreateListOpportunityRequest } from '../interfaces/listOpporunity/ListOpportunity';

const fetchListingType = async () => {
    const { data } = await ListOpportunityApi.fetchListingType();
    return data;
};

const fetchNdaType = async () => {
    const { data } = await ListOpportunityApi.fetchNdaType();
    return data;
};

const fetchApprovaltype = async () => {
    const { data } = await ListOpportunityApi.fetchApprovaltype();
    return data;
};

const fetchListingTypeByPoppertyId = async (propertyAssetId: string) => {
    const { data } = await ListOpportunityApi.fetchListingTypeByPoppertyId(propertyAssetId);
    return data;
};

const createListingType = async (propertyAssetId: string, body: any) => {
    const { data } = await ListOpportunityApi.createListingType(propertyAssetId, body);
    return data;
};

const fetchMarketingTimeline = async (propertyAssetId: string) => {
    const { data } = await ListOpportunityApi.fetchMarketingTimeline(propertyAssetId);
    return data;
};

const fetchApprovals = async (propertyAssetId: string) => {
    const { data } = await ListOpportunityApi.fetchApprovals(propertyAssetId);
    return data;
};

const fetchRoles = async () => {
    const { data } = await ListOpportunityApi.fetchRoles();
    return data;
};

const createMarketingTimeline = async (propertyAssetId: string, body: any) => {
    return await ListOpportunityApi.createMarketingTimeline(propertyAssetId, body);
};

const bulkUpdateMarketingTimeline = async (propertyAssetId: string, body: any) => {
    return await ListOpportunityApi.bulkUpdateMarketingTimeline(propertyAssetId, body);
};

const fetchBidingStructure = async (propertyAssetId: string) => {
    const { data } = await ListOpportunityApi.fetchBidingStructure(propertyAssetId);
    return data;
};

const createBidingStructure = async (propertyAssetId: string, body: any) => {
    body.ndaType = parseInt(body.ndaType);
    return await ListOpportunityApi.createBidingStructure(propertyAssetId, body);
};

const createInviteForInvestor = async (propertyAssetId: string, companyId: string, _userId: string | null) => {
    let userId = { userId: _userId };
    return await ListOpportunityApi.createInviteForInvestor(propertyAssetId, companyId, userId);
};

const createInviteForUser = async (propertyAssetId: string, body: any) => {
    const { data } = await ListOpportunityApi.createInviteForUser(propertyAssetId, body);
    return data;
};

const createInviteForUserForm = async (propertyAssetId: string, user: any) => {
    const { data } = await ListOpportunityApi.createInviteForUserForm(propertyAssetId, user);
    return data;
};

const fetchListOpportunityStage = async () => {
    const { data } = await ListOpportunityApi.fetchListOpportunityStage();
    return data;
};

const fetchOverviews = async (propertyAssetId: string) => {
    const response = await ListOpportunityApi.fetchListOverview(propertyAssetId);
    return response.data;
};

const fetchProperyInvestors = async (propertyAssetId: string) => {
    const { data } = await ListOpportunityApi.fetchProperyInvestor(propertyAssetId);
    return data;
};

const createCustomNda = async (propertyAssetId: string, file: any) => {
    const { data } = await ListOpportunityApi.createCustomNda(propertyAssetId, file);
    return data;
};

const removeInvestor = async (propertyAssetId: string, companyId: any) => {
    const { data } = await ListOpportunityApi.removeInvestor(propertyAssetId, companyId);
    return data;
};

const removeStage = async (propertyAssetId: string, stageId: any) => {
    const { data } = await ListOpportunityApi.removeStage(propertyAssetId, stageId);
    return data;
};

const updateStage = async (propertyAssetId: string, stageId: any, body: any) => {
    const { data } = await ListOpportunityApi.updateStage(propertyAssetId, stageId, body);
    return data;
};

const createListOpportunityFinish = async (propertyAssetId: string, listOpportunityData: CreateListOpportunityRequest) => {
    const { data } = await ListOpportunityApi.createListOpportunityFinish(propertyAssetId, listOpportunityData);
    return data;
};

const unlistOpportunity = async (propertyAssetId: string) => {
    await ListOpportunityApi.unlistOpportunity(propertyAssetId);
}

const sendMailToUserWhoCanApprove = async (propertyAssetId: string, userId: string) => {
    const { data } = await ListOpportunityApi.sendMailToUserWhoCanApprove(propertyAssetId, userId);
    return data;
};

const ListOpportunityService = {
    fetchListingType,
    createListingType,
    fetchMarketingTimeline,
    createMarketingTimeline,
    bulkUpdateMarketingTimeline,
    fetchApprovals,
    removeInvestor,
    fetchListingTypeByPoppertyId,
    createInviteForInvestor,
    updateStage,
    removeStage,
    createCustomNda,
    createInviteForUser,
    createInviteForUserForm,
    createBidingStructure,
    createListOpportunityFinish,
    fetchBidingStructure,
    fetchOverviews,
    fetchProperyInvestors,
    fetchListOpportunityStage,
    fetchRoles,
    fetchNdaType,
    sendMailToUserWhoCanApprove,
    fetchApprovaltype,
    unlistOpportunity
};
export default ListOpportunityService;
