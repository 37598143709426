import { FunctionComponent } from 'react';
import { Stages } from '../../interfaces/property/Stages';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface TimeLineDotProps {
    stage: Stages;
    stageNames: any;
    isOnPropertySummary: boolean;
}

const TimeLineDot: FunctionComponent<TimeLineDotProps> = ({
    stage,
    stageNames,
    isOnPropertySummary,
}: TimeLineDotProps) => {
    const { t } = useTranslation(['f', 'b']);
    return (
        <div
            className={`d-flex flex-direction-column justify-content-space-center align-items-center ${
                isOnPropertySummary ? '' : 'deadline__stage-container'
            }`}
        >
            <p className="deadline__stage d-flex">
                {stageNames ? `${t(`f:listOpportunity.stages.${stageNames[stage.stage]}`)}` : ''}
            </p>
            <p className="deadline__info">{stage.text ? stage.text : ''}</p>
            <p className="deadline__date">
                {stage.startDate ? `${moment(stage.startDate).format('MMMM Do YYYY')}` : ''}
                <span> - </span>
                {stage.closeDate ? `${moment(stage.closeDate).format('MMMM Do YYYY')}` : ''}
            </p>
        </div>
    );
};

export default TimeLineDot;
