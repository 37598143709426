import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MyTeam from './ProjectTeam/MyTeam';
import ProjectTeamComponent from './ProjectTeam/ProjectTeam';
import FeatureState from '../../../context/state/FeatureState';
import TabsContentNavigation from '../../../components/shared/TabsContentNavigation';
import useBoolean from '../../../hooks/use-boolean';
import MainButton from '../../../components/shared/MainButton';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripHorizontal, faList } from '@fortawesome/free-solid-svg-icons';

enum NavEnums {
    MYTEAM = 'MYTEAM',
    PROJECTTEAM = 'PROJECTTEAM',
}

export enum TeamsModeViewEnum {
    LIST = 'LIST',
    CARD = 'CARD'
}

const ProjectTeamTab = () => {
    const { t } = useTranslation(['f', 'b']),
        [isProjectTeamComponentModalOpen, isProjectTeamComponentmModalOpenActions] = useBoolean(),
        [isMyTeamModalOpen, isMyTeamModalOpenActions] = useBoolean(),
        { getFeature } = useContext(FeatureState),
        [activComponent, setActivComponent] = useState<string>(NavEnums.PROJECTTEAM),
        [companySelected, setCompanySelected] = useState<string>('All'),
        [modeView, setModeView] = useState<TeamsModeViewEnum>(TeamsModeViewEnum.CARD);

    const handleChangeActivComponent = (value: string) => setActivComponent(value);

    const radioBtnsDate = [
        {
            handler: handleChangeActivComponent,
            text: t(`f:teams.projectTeam`),
            value: NavEnums.PROJECTTEAM,
        },
        {
            handler: handleChangeActivComponent,
            text: t(`f:teams.myTeam`),
            value: NavEnums.MYTEAM,
        },
    ];

    const handleActivateModal = useCallback(() => {
        activComponent === NavEnums.MYTEAM
            ? isMyTeamModalOpenActions.setTrue()
            : isProjectTeamComponentmModalOpenActions.setTrue();
    }, [activComponent, isProjectTeamComponentmModalOpenActions, isMyTeamModalOpenActions]);

    const displayComponent = () => {
        switch (activComponent) {
            case NavEnums.PROJECTTEAM:
                return (
                    <div className={isMobile ? 'mx-20 mt-40' : 'mx-50 mt-40'}>
                        <ProjectTeamComponent
                            companySelected={companySelected}
                            setCompanySelected={setCompanySelected}
                            isProjectTeamComponentmModalOpenActions={isProjectTeamComponentmModalOpenActions}
                            isProjectTeamComponentModalOpen={isProjectTeamComponentModalOpen}
                            modeView={modeView}
                        />
                    </div>
                );

            default:
                return (
                    <MyTeam
                        companySelected={companySelected}
                        setCompanySelected={setCompanySelected}
                        isMyTeamModalOpen={isMyTeamModalOpen}
                        isMyTeamModalOpenActions={isMyTeamModalOpenActions}
                    />
                );
        }
    };

    return (
        <div className="teams">
            <TabsContentNavigation
                radioBtns={radioBtnsDate}
                activComponent={activComponent}
                element={
                    <>
                        {!isMobile &&
                            <FontAwesomeIcon
                                icon={faList}
                                style={{ fontSize: '1.5em' }}
                                className={`pointer ${modeView === TeamsModeViewEnum.LIST ? 'active-mode-icon' : ''}`}
                                onClick={() => setModeView(TeamsModeViewEnum.LIST)}
                            />}
                        {!isMobile &&
                            <FontAwesomeIcon
                                icon={faGripHorizontal}
                                style={{ fontSize: '1.5em' }}
                                className={`pointer ${modeView === TeamsModeViewEnum.CARD ? 'active-mode-icon' : ''}`}
                                onClick={() => setModeView(TeamsModeViewEnum.CARD)}
                            />}
                        {getFeature('ProjectTeamEdit') && (
                            <MainButton
                                layout={isMobile ? 'primary' : 'secondary'}
                                buttonHandler={handleActivateModal}
                                buttonText={t('f:button.addUser')}
                                classAdditions={`d-flex teams-button mr-80 ${isMobile ? 'mb-20' : ''}`}
                                size={isMobile ? 'main' : 'small'}
                            />
                        )}
                    </>
                }
                elementClassAdditions='d-flex justify-content-end gap-10 align-items-center'
            />
            {displayComponent()}
        </div>
    );
};
export default ProjectTeamTab;
