import { FunctionComponent } from 'react';

const TradeProbabilityScoreCard: FunctionComponent<any> = (props) => {

    return (
        <section className="module-trade-score bg-dark-green mt-40">
            <div className="content-text">
                <h1 className="heading">Trade Probability Score Card</h1>
                {/* <div className="block-article">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                </div> */}
            </div>
            <div className="list-block-item">
                <div className="item">
                    <div className="block-items">
                        <h4>2017</h4>
                        <p>Acquired </p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>2022-2023</h4>
                        <p>Predicted Sale Date </p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>USD 12 billion</h4>
                        <p>Dry Powder </p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>High</h4>
                        <p>Likelihood of Sale </p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>Yes</h4>
                        <p>Institutional Owner </p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>USD 3.4 billion</h4>
                        <p>Unsatisfied Capital </p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>Institutional</h4>
                        <p>Expected buyer</p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>35%</h4>
                        <p>Value Increase</p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>5</h4>
                        <p>Predicted Number of Bids</p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>15%</h4>
                        <p>Rental Growth</p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>Trophy </h4>
                        <p>Building Grade </p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>3.25 - 3.45</h4>
                        <p>% Gross Cap Rate Range </p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>10.3 - 11.3</h4>
                        <p>Rental Range</p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>93%</h4>
                        <p>Stabilised Occupancy</p>
                    </div>
                </div>
                <div className="item">
                    <div className="block-items">
                        <h4>USD 2.4-2.7 B</h4>
                        <p>Valuation Range </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TradeProbabilityScoreCard;
