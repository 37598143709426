import { FunctionComponent, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import UserService from '../../../services/User';
import { toast } from 'react-toastify';
import { ValidationUpdateCompanyForm } from '../../../validations/UserWizard/UpdateCompany';

interface Props {
    cancelEverything: () => void;
    returnToPreviousPage: () => void;
    goToNextPage: () => void;
}
export const CompanyDetails: FunctionComponent<Props> = ({ cancelEverything, returnToPreviousPage, goToNextPage }) => {
    const { t } = useTranslation(['f', 'b']),
        [company, setCompany] = useState<any>({
            id: 0,
            email: '',
            name: '',
            registrationNumber: '',
            type: '',
            industry: '',
            speciality: '',
            address: '',
            city: '',
            postalCode: '',
            contactName: '',
            phoneNumber: '',
            countryId: null,
            countryName: '',
            domains: [],
            ownerEmail: '',
            isSole: true,
            ownerId: null,
        }),
        [canEdit, setCanEdit] = useState<boolean>(true);

    useEffect(() => {
        fetchCanEdit();
        fetchMyCompanyInfo();
    }, []);

    const setIsSole = (value: boolean) => {
        setCompany({ ...company, isSole: value });
    };

    const saveIsSoleCompany = async () => {
        await UserService.updateMyCompanyAsSole();
        goToNextPage();
    };

    const fetchCanEdit = useCallback(async () => {
        try {
            const canEdit = await UserService.canUpdateCompany();
            setCanEdit(canEdit);
        } catch (error) {
            toast(error.response.data.error.errorMessage);
        }
    }, []);

    const fetchMyCompanyInfo = useCallback(async () => {
        try {
            const myCompanyInfo = await UserService.getMyCompanyInfo();
            setCompany(myCompanyInfo);
        } catch (error) {
            toast(error.response.data.error.errorMessage);
        }
    }, []);

    return (
        <div className="marketing wizard-container-steps">
            <p className="listing-type__help">
                Having trouble? <span className="listing-type__help-link">{t('f:userWizard.userDetails.getHelp')}</span>
            </p>
            {!company.isSole && (
                <Formik
                    initialValues={company}
                    enableReinitialize={true}
                    validationSchema={ValidationUpdateCompanyForm}
                    onSubmit={async (values: any) => {
                        var result = await UserService.updateMyCompany(values);
                        goToNextPage();
                        return;
                    }}
                >
                    {({ values, errors, setFieldValue, resetForm, submitForm }) => (
                        <Form>
                            <div className="meeting__form-group d-flex">
                                <div className="col-md-4 form-group ">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.name')}</label>
                                    <Field id="text" name="name" placeholder={t('f:userWizard.companyDetails.name')} />
                                </div>

                                <div className="col-md-4 form-group ">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.registrationNumber')}</label>
                                    <Field
                                        id="text"
                                        name="registrationNumber"
                                        placeholder={t('f:userWizard.companyDetails.registrationNumber')}
                                    />
                                </div>
                            </div>
                            <div className="meeting__form-group d-flex">
                                <div className="col-md-4 form-group ">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.email')}</label>
                                    <Field
                                        id="text"
                                        name="email"
                                        placeholder={t('f:userWizard.companyDetails.email')}
                                    />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.phoneNumber')}</label>
                                    <Field
                                        id="text"
                                        name="phoneNumber"
                                        placeholder={t('f:userWizard.companyDetails.phoneNumber')}
                                    />
                                </div>
                            </div>
                            <div className="meeting__form-group d-flex">
                                <div className="col-md-4 form-group ">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.address')}</label>
                                    <Field
                                        id="text"
                                        name="address"
                                        placeholder={t('f:userWizard.companyDetails.address')}
                                    />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.city')}</label>
                                    <Field id="text" name="city" placeholder={t('f:userWizard.companyDetails.city')} />
                                </div>
                            </div>
                            <div className="meeting__form-group d-flex">
                                <div className="col-md-4 form-group ">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.postalCode')}</label>
                                    <Field
                                        id="text"
                                        name="postalCode"
                                        placeholder={t('f:userWizard.companyDetails.postalCode')}
                                    />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.country')}</label>
                                    <Field
                                        id="text"
                                        name="countryName"
                                        placeholder={t('f:userWizard.companyDetails.country')}
                                    />
                                </div>
                            </div>
                            <div className="meeting__form-group d-flex">
                                <div className="col-md-4 form-group ">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.type')}</label>
                                    <Field id="text" name="type" placeholder={t('f:userWizard.companyDetails.type')} />
                                </div>

                                <div className="col-md-4 form-group">
                                    <label htmlFor="text">{t('f:userWizard.companyDetails.industry')}</label>
                                    <Field
                                        id="text"
                                        name="industry"
                                        placeholder={t('f:userWizard.companyDetails.industry')}
                                    />
                                </div>
                            </div>

                            <div className="overview__main overview__main--border">
                                <div className="mb-100">
                                    <label
                                        htmlFor="require_1"
                                        className="d-flex align-items-center checkbox text-rubik-16"
                                    >
                                        <div onClick={() => setIsSole(true)}>
                                            <input
                                                className="mr-15 select-checkbox"
                                                type="checkbox"
                                                id="2"
                                                name="require_1"
                                                value="1"
                                                checked={company.isSole}
                                            />
                                        </div>

                                        <span className="text-rubik-16">
                                            {t('f:userWizard.companyDetails.iDontHaveCompany')}
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                                <p
                                    className="previous-page"
                                    onClick={() => {
                                        returnToPreviousPage();
                                    }}
                                >
                                    {t('f:button.previousStep')}
                                </p>
                                <button
                                    className="button-main button-secondary d-flex justify-content-center align-items-center"
                                    onClick={() => {
                                        cancelEverything();
                                    }}
                                    type="submit"
                                >
                                    {t('f:button.cancel')}
                                </button>

                                <button
                                    className="button-main button-primary mr-25"
                                    onClick={() => {
                                        canEdit ? submitForm() : goToNextPage();
                                    }}
                                >
                                    {t('f:button.next')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}

            {company.isSole && (
                <>
                    <div className="overview__main overview__main--border">
                        <div className="mb-100">
                            <label htmlFor="require" className="d-flex align-items-center checkbox text-rubik-16">
                                <div onClick={() => setIsSole(false)}>
                                    <input
                                        className="mr-15 select-checkbox"
                                        type="checkbox"
                                        id="1"
                                        name="require"
                                        value="1"
                                        checked={company.isSole}
                                    />
                                </div>

                                <span className="text-rubik-16">
                                    {t('f:userWizard.companyDetails.iDontHaveCompany')}
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="col-md-12 d-flex justify-content-end align-items-center button-wrap button-container">
                        <p
                            className="previous-page"
                            onClick={() => {
                                returnToPreviousPage();
                            }}
                        >
                            {t('f:button.previousStep')}
                        </p>
                        <button
                            className="button-main button-secondary d-flex justify-content-center align-items-center"
                            onClick={() => {
                                cancelEverything();
                            }}
                            type="submit"
                        >
                            {t('f:button.cancel')}
                        </button>

                        <button
                            className="button-main button-primary mr-25"
                            onClick={() => {
                                saveIsSoleCompany();
                            }}
                        >
                            {t('f:button.next')}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};
