import { Moment } from "moment";
import GoogleAnalyticsApi from "../api/routes/GoogleAnalyticsApi"

const fetchUsersByCountry = async (startDate: Moment, endDate: Moment) => {
    const { data } = await GoogleAnalyticsApi.fetchUsersByCountry(startDate, endDate);
    return data;
}

const fetchTopUsersOfProperty = async (propertyExternalId: string) => {
    const { data } = await GoogleAnalyticsApi.fetchTopUsersOfProperty(propertyExternalId);
    return data;
}

const fetchTopCountriesAccessProperty = async (propertyExternalId: string) => {
    const { data } = await GoogleAnalyticsApi.fetchTopCountriesAccessProperty(propertyExternalId);
    return data;
}

const fetchActiveUsersOfProperty = async (propertyExternalId: string) => {
    const { data } = await GoogleAnalyticsApi.fetchActiveUsersOfProperty(propertyExternalId);
    return data.activeUsers;
}

const fetchActiveUsersInDays = async (propertyExternalId?: string) => {
    const { data } = await GoogleAnalyticsApi.fetchActiveUsersInDays(propertyExternalId);
    return data;
}

const fetchMostViewsProperties = async (startDate: Moment, endDate: Moment) => {
    const { data } = await GoogleAnalyticsApi.fetchMostViewsProperties(startDate, endDate);
    return data;
}

const fetchMostViewTabsOfProperty = async (propertyId: string) => {
    const { data } = await GoogleAnalyticsApi.fetchMostViewTabsOfProperty(propertyId);
    return data;
}

const GoogleAnalyticsService = {
    fetchUsersByCountry,
    fetchTopUsersOfProperty,
    fetchTopCountriesAccessProperty,
    fetchActiveUsersOfProperty,
    fetchActiveUsersInDays,
    fetchMostViewsProperties,
    fetchMostViewTabsOfProperty
};

export default GoogleAnalyticsService;