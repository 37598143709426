import PropertyAsserts, { PropertyAssetSummary, PropertyToggleStatus } from '../interfaces/property/PropertyAsserts';
import { PropertyStatusEnum } from '../constants/propertyStatus';
export const deafultPropertyHero: PropertyAssetSummary = {
    id: 0,
    companyOwnerExternalId: '',
    companyOwnerId: 0,
    name: '',
    address: '',
    assetTypes: [],
    isPortfolio: true,
    numberOfAssets: 0,
    status: PropertyStatusEnum.Draft,
    brokers: [],
    locations: [
        {
            propertyAssetId: 0,
            address: '',
            name: '',
            latitude: 0,
            longitude: 0,
            imageUrl: '',
        },
    ],
    marketingProcess: '',
    imageUrl: '',
    dealType: '',
    sectors: [],
    isListed: false,
    isPublic: false,
    isNdaRequired: false,
    externalId: '',
    saleType: '',
    numberOfBuildings: 0
};

export const deafultProperty: PropertyAsserts = {
    id: 0,
    propertyName: '',
    description: '',
    assetValue: 0,
    occupancy: 0,
    currencyId: 0,
    numberOfBuildings: 0,
    numberOfFloors: 0,
    numberOfUnitsRooms: 0,
    numberOfStories: 0,
    numberOfTenants: 0,
    buildingClassId: 0,
    buildingStatusId: 0,
    yearOfCompletion: '',
    yearRenovated: '',
    yearBuilt: '',
    locationTypes: [],
    excessLand: true,
    greenRatings: [],
    propertyMarketId: 0,
    propertySubMarketId: 0,
    assetTypes: [],
    assetSubTypeId: 0,
    landTenureId: 0,
    leaseholdStartDate: '',
    leaseholdExpirationDate: '',
    leasehold: 0,
    anchorTenants: '',
    buildingGradeId: 0,
    saleTypeId: 0,
    landArea: 0,
    landAreaUnitOfMeasureId: 0,
    unitOfMeasurementId: 0,
    grossFloorArea: 0,
    grossFloorAreaUnitOfMeasureId: 0,
    sectors: [],
    sectorNames: [],
    netLeasableArea: 0,
    netLeasableAreaUnitOfMeasureId: 0,
    grossLeasableArea: 0,
    grossLeasableAreaUnitOfMeasureId: 0,
    carparkLots: '',
    majorTenants: '',
    passingRent: 0,
    passingRentCurrencyId: 0,
    passingRentUnitOfMeasureId: 0,
    netOperatingIncome: 0,
    capitalisationRate: 0,
    ownershipTypeId: 0,
    currentOwner: '',
    saleInterest: '',
    partialInterest: '',
    keepFinancialInformationConfidential: true,
    map: {
        long: 0,
        lat: 0,
        address: '',
        countryId: 0,
    },
    imageUrl: '',
    isPortfolio: true,
    allowSubsetBidding: true,
    createdDate: '',
    updatedDate: '',
    createdById: '',
    status: 0,
    currencyPrefix: '',
    owners: [
        {
            id: 0,
            name: '',
            share: 0,
        },
    ],
    mainStatus: 0,
    subStatus: 0,
    canUnlist: false,
    externalId: '',
};

export const toggleBtnStatus: PropertyToggleStatus = {
    listed: { type: 0, value: false },
    status: { type: 1, value: false },
    NDA: { type: 2, value: false },
};
