const names = ["Asia Square (Tower 1)",
  "Asia Square (Tower 2)",
  "Ocean Financial Centre",
  "OUE Downtown",
  "OUE Bayfront",
  "CapitaGreen",
  "MBFC Tower 1",
  "MBFC Tower 2",
  "MBFC Tower 3",
  "Duo Tower",
  "Frasers Tower",
];

const minPercent = ["3.4",
  "3.2",
  "3.35",
  "3.48",
  "3.4",
  "3.24",
  "3.41",
  "3.3",
  "3.2",
  "3.22",
  "3.26",
];

const maxPercent = ["3.71",
  "3.69",
  "3.68",
  "3.68",
  "3.61",
  "3.57",
  "3.52",
  "3.45",
  "3.42",
  "3.4",
  "3.38",
];


export default {
    names,
    minPercent,
    maxPercent,
};
