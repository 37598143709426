import { FunctionComponent, useState } from 'react';
import IncomeValidation from './IncomeValidation';

const ModelEstimates: FunctionComponent<any> = (props) => {
    return (
        <section className="module-sub-market">
            <div className="content-text">
                <h1 className="heading">Model Estimates</h1>
            </div>
            <IncomeValidation />
        </section>
    );
};

export default ModelEstimates;
