import { Form, Switch } from 'antd';
import { Formik } from 'formik';
import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainButton from '../../../../components/shared/MainButton';
import MainCheckbox from '../../../../components/shared/MainCheckbox';
import MainHeading from '../../../../components/shared/MainHeading';
import MainTextInput from '../../../../components/shared/MainTextInput';
import ModalWrapper from '../../../../components/wrapper/ModalWrapper';
import FeatureState from '../../../../context/state/FeatureState';
import { Actions } from '../../../../interfaces/general/general';
import { PropertyToggleStatus } from '../../../../interfaces/property/PropertyAsserts';

interface ShereUrlModalProps {
    isModalOpen: boolean;
    isModalOpenActions: Actions;
    status: PropertyToggleStatus;
    handleToggle: (name: string, val: any) => void;
}

const ShareUrlModal: FunctionComponent<ShereUrlModalProps> = ({
    handleToggle,
    status,
    isModalOpen,
    isModalOpenActions,
}) => {
    const { t } = useTranslation(['f', 'b']),
        { propertySummary } = useContext(FeatureState),
        { externalId } = propertySummary,
        history = useHistory();

    const onFinish = useCallback(() => {
        isModalOpenActions.setFalse();
    }, [isModalOpenActions]);
    const goToPemissions = useCallback(() => {
        history.push(`/property/${externalId}#permissions`);
        isModalOpenActions.setFalse();
    }, [isModalOpenActions, history, externalId]);

    const copyText = useCallback((values) => {
        navigator.clipboard.writeText(values.url);
    }, []);

    return (
        <div className="d-flex flex-direction-column">
            <Formik initialValues={{ url: window.location.href }} onSubmit={copyText}>
                {({ errors, touched, submitForm }) => (
                    <ModalWrapper
                        isCloseModalShown={false}
                        closeModalHandler={onFinish}
                        isFooterShown={false}
                        isModalOpened={isModalOpen}
                    >
                        <Form className="d-flex flex-direction-column mb-30">
                            <div className="col-xs-12 d-flex flex-direction-column">
                                <MainHeading text={t('f:propertySummary.shareWithYourNetwork')} />
                                <div className="d-flex ml-10 mt-20">
                                    <MainCheckbox
                                        onClick={() => {
                                            handleToggle('NDA', !status.NDA.value);
                                        }}
                                        checked={status.NDA.value}
                                    />
                                    <p className="property-info__share-txt d-flex align-items-center">
                                        <span>{t('f:button.ndaRequired')}</span>
                                    </p>
                                </div>
                                <div className="d-flex mt-20">
                                    <div className="property-info__share property-info__share--scale">
                                        <Switch
                                            className="button-main button-primary d-block property-info__share-btn"
                                            checkedChildren={t('f:button.published')}
                                            unCheckedChildren={t('f:button.unpublished')}
                                            checked={status.listed.value}
                                            defaultChecked={status.listed.value}
                                            onClick={() => {
                                                handleToggle('listed', !status.listed.value);
                                            }}
                                        />
                                    </div>
                                    <div className="property-info__share property-info__share--scale">
                                        <Switch
                                            className="button-main button-primary d-block property-info__share-btn"
                                            checkedChildren={t('f:button.public')}
                                            unCheckedChildren={t('f:button.private')}
                                            checked={status.status.value}
                                            defaultChecked={status.status.value}
                                            onClick={() => {
                                                handleToggle('status', !status.status.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-12 d-flex justify-content-end">
                                    <div className="d-flex">
                                        <MainButton
                                            type="submit"
                                            classAdditions="mt-30"
                                            layout="primary"
                                            size="main"
                                            buttonHandler={goToPemissions}
                                            buttonText={t('f:button.investorList')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 d-flex flex-direction-column mt-30">
                                <MainHeading text={t('f:propertySummary.getLink')} />
                                <div className="col-xs-12-clear">
                                    <div className="col-xs-10-clear">
                                        <MainTextInput
                                            name="url"
                                            placeholder={t('f:property.copyUrl')}
                                            label={t('f:property.url')}
                                            errors={errors}
                                            touched={touched}
                                            errorFieldName="url"
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="col-xs-2 d-flex">
                                        <MainButton
                                            type="submit"
                                            classAdditions="mt-35"
                                            layout="primary"
                                            size="main"
                                            buttonHandler={submitForm}
                                            buttonText={t('f:button.copy')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12">
                                <div className="col-xs-12 d-flex justify-content-end">
                                    <MainButton
                                        type="submit"
                                        classAdditions="mt-30"
                                        layout="primary"
                                        size="main"
                                        buttonHandler={onFinish}
                                        buttonText={t('f:button.done')}
                                    />
                                </div>
                            </div>
                        </Form>
                    </ModalWrapper>
                )}
            </Formik>
        </div>
    );
};

export default ShareUrlModal;
