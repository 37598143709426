import { Modal } from 'antd';
import { FunctionComponent, useCallback } from 'react';
import MainHeading from '../shared/MainHeading';
import ModalCloseButton from '../shared/ModalCloseButton';
import MainButton from '../shared/MainButton';
interface ModalProps {
    isModalOpened: boolean;
    isFooterShown: boolean;
    isCloseModalShown: boolean;
    closeModalHandler: () => void;
    className?: string;
    title?: string;
    primaryButtonText?: string;
    primaryButtonHandler?: () => void;
    secondaryButtonText?: string;
    centered?: boolean;
    secondaryButtonHandler?: () => void;
    destroyOnClose?: boolean;
    titleClassName?: string;
    width?: string | number;
    wrapClassName?: string;
}

const ModalWrapper: FunctionComponent<ModalProps> = ({
    isModalOpened,
    closeModalHandler,
    className,
    title,
    secondaryButtonText,
    primaryButtonText,
    secondaryButtonHandler,
    primaryButtonHandler,
    isCloseModalShown,
    centered,
    isFooterShown,
    children,
    destroyOnClose,
    titleClassName,
    width,
    wrapClassName
}) => {
    const handleContainer = useCallback(() => {
        const theme = document.getElementById('theme');
        return theme ? theme : document.body;
    }, []);

    return (
        <Modal
            wrapClassName={wrapClassName ?? ''}
            className={className}
            visible={isModalOpened}
            footer={null}
            closable={false}
            onCancel={closeModalHandler}
            centered={centered ? centered : true}
            getContainer={handleContainer}
            destroyOnClose={destroyOnClose}
            width={width}
        >
            {title && <MainHeading text={title} addClasses={titleClassName}/>}
            {isCloseModalShown && <ModalCloseButton handler={closeModalHandler} />}

            {children}

            {isFooterShown && (
                <div className="d-flex justify-content-center mt-50 gap-20">
                    {secondaryButtonText && (
                        <MainButton
                            layout="secondary"
                            buttonHandler={secondaryButtonHandler}
                            buttonText={secondaryButtonText}
                            size="main"
                        />
                    )}
                    {primaryButtonText && (
                        <MainButton
                            layout="primary"
                            buttonHandler={primaryButtonHandler}
                            buttonText={primaryButtonText}
                            size="main"
                        />
                    )}
                </div>
            )}
        </Modal>
    );
};

export default ModalWrapper;
