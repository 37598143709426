import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import LOWizardState from '../../../../context/state/LOWizardState';
import { DataGrid } from '@material-ui/data-grid';
import NewestUser from '../../../../interfaces/user/NewestUser';
interface UserForGrid {
    id: string;
    user: NewestUser;
    approved: string;
}
const UsersWhoCanApproveTable: FunctionComponent = () => {
    const { t } = useTranslation(['f']),
        [dataForGrid, setDataForGrid] = useState<Array<UserForGrid>>([]),
        { usersWhoCanApprove, sendEmail, approvaltype } = useContext(LOWizardState);

    const columns = [
        {
            field: 'fullName',
            headerName: t('f:listOpportunity.name'),
            flex: 1,
            renderCell: (props) => props.row.user.fullName,
        },

        {
            field: 'email',
            headerName: t('f:listOpportunity.email'),
            flex: 1,
            renderCell: (props) => props.row.user.email,
        },

        {
            field: 'phoneNumber',
            headerName: t('f:listOpportunity.phoneNumber'),
            flex: 1,
            renderCell: (props) => props.row.user.phoneNumber,
        },

        {
            field: 'approved',
            headerName: t('f:listOpportunity.status'),
            flex: 1,
            renderCell: (props) => t(`f:listOpportunity.emailStatus.${approvaltype[props.row.approved]}`),
        },

        {
            field: '',
            headerName: t('f:listOpportunity.actions'),
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (props) => (
                <Space size="middle">
                    <button
                        className="d-flex align-items-center justify-content-center button-small button-primary"
                        type="submit"
                        onClick={() => {
                            sendEmail(props.row.id);
                        }}
                    >
                        {t('f:button.email')}
                    </button>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        setDataForGrid(
            usersWhoCanApprove.map((elem) => {
                return { ...elem, id: elem.user.id };
            })
        );
    }, [usersWhoCanApprove]);

    return (
        <div className="col-xs-12 mt-40">
            <div style={{ height: 400, width: '100%' }} className="mb-100">
                <DataGrid rows={dataForGrid} columns={columns} pageSize={10} disableSelectionOnClick />
            </div>
        </div>
    );
};

export default UsersWhoCanApproveTable;
