import { useMemo, useCallback, useState } from 'react';
import { Actions } from '../interfaces/general/general';

const useBoolean = (initialState = false): [boolean, Actions] => {
    const [state, setState] = useState(initialState);

    const setTrue = useCallback(() => setState(true), [setState]);
    const setFalse = useCallback(() => setState(false), [setState]);
    const toggle = useCallback(() => setState((s) => !s), [setState]);

    return useMemo(() => [state, { setTrue, setFalse, toggle }], [state, setTrue, setFalse, toggle]);
};

export default useBoolean;
