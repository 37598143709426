import Lookup from '../interfaces/property/Lookup';
export const chartTypes: Array<Lookup> = [
    {
        id: 1,
        name: 'Line Chart',
        sortOrder: 1,
    },
    {
        id: 2,
        name: 'Bar Chart',
        sortOrder: 2,
    },
    {
        id: 3,
        name: 'Pie Chart',
        sortOrder: 3,
    },
];

export const findChartByName = (value: string) => {
    let type = 1;
    chartTypes.find((elem) => {
        if (elem.name === value) {
            type = elem.id;
        }
    });
    return type;
};

export const dateTypes: Array<Lookup> = [
    {
        id: 1,
        name: 'DD/MM/YYYY',
        sortOrder: 1,
    },
    {
        id: 2,
        name: 'MM/YYYY',
        sortOrder: 2,
    },
    {
        id: 3,
        name: 'YYYY',
        sortOrder: 3,
    },
];

export const antDatePickerSwitch = (type: number) => {
    switch (type) {
        case 2:
            return 'month';
        case 3:
            return 'year';

        default:
            return undefined;
    }
};
