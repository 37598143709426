import MainLoader from '../../../../../components/shared/MainLoader';
import Table from '../table';
import { FunctionComponent, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MainCheckbox from '../../../../../components/shared/MainCheckbox';
import { TableTypes } from '../../../../../constants/wizardSteps';
import LOWizardState from '../../../../../context/state/LOWizardState';
import { CompanyWithInvestorPagination } from '../../../../../interfaces/investor/CompanyWithInvestor';

interface InvestorsTableProps {
    companyPagination: CompanyWithInvestorPagination;
    isCompaniesLoaded: boolean;
    setInvestorsPage: (prev: number) => void;
    investorsPage: number;
    type: TableTypes.INVESTORS_TAB | TableTypes.LIST_OPPORTUNITY;
    pageSize: number;
    refetch: () => void;
}

const InvestorsTable: FunctionComponent<InvestorsTableProps> = ({
    companyPagination,
    isCompaniesLoaded,
    setInvestorsPage,
    investorsPage,
    type,
    pageSize,
    refetch,
}: InvestorsTableProps) => {
    const { t } = useTranslation(['f', 'b']),
        { isSendInvitations, IsSendInvitationsActions } = useContext(LOWizardState);

    const handleToggle = useCallback(() => {
        IsSendInvitationsActions.toggle();
    }, [IsSendInvitationsActions]);

    return (
        <div className="mb-100">
            {isCompaniesLoaded ? (
                <>
                    {type === TableTypes.LIST_OPPORTUNITY && (
                        <div className="col-xs-12 d-flex align-items-center">
                            <MainCheckbox checked={isSendInvitations} onClick={handleToggle} />
                            <label htmlFor="checkbox">{t(`f:listOpportunity.sendInvitations`)}</label>
                        </div>
                    )}
                    <Table
                        data={companyPagination}
                        setInvestorsPage={setInvestorsPage}
                        investorsPage={investorsPage}
                        type={type}
                        pageSize={pageSize}
                        refetch={refetch}
                    />
                </>
            ) : (
                <MainLoader type="regular" />
            )}
        </div>
    );
};
export default InvestorsTable;
