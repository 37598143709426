import { FunctionComponent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MainButton from '../../../components/shared/MainButton';
import TabsContentNavigation from '../../../components/shared/TabsContentNavigation';
import { PropertyAssetFeatures } from '../../../constants/propertyAssetFeatures';
import FeatureState from '../../../context/state/FeatureState';
import useBoolean from '../../../hooks/use-boolean';
import DashboardWrapper from '../components/dashboardComponents/dashboardWrapper';
import Templates from './PropertySummary/Templates';
import ContactFormModal from './researchComponents/ContactFormModal';

const tabs = ['templates', 'dashboard'];

const Research: FunctionComponent = () => {
    const [isContactFormOpened, setIsContactFormOpenedActions] = useBoolean(),
        [activeComponent, setActiveComponent] = useState<string>(tabs[0]),
        { getFeature, checkDealRoomFeature } = useContext(FeatureState),
        { t } = useTranslation(['f', 'b']);

    const handleChangeActivComponent = (e: string) => setActiveComponent(e);

    let radioBtnsDate = [
        {
            handler: handleChangeActivComponent,
            text: t(`f:research.optionTemplate`),
            value: tabs[0],
        },
    ];

    if (getFeature('ResearchAnalysis') && checkDealRoomFeature(PropertyAssetFeatures.ResearchAnalysis)) {
        radioBtnsDate.push({
            handler: handleChangeActivComponent,
            text: t(`f:research.optionDashboard`),
            value: tabs[1],
        });
    }

    const displayContent = () => {
        switch (activeComponent) {
            case tabs[1]:
                return <DashboardWrapper />;

            default:
                return <Templates pageType={'propertyassetresearch'} />;
        }
    };
    return (
        <div className="research">
            <ContactFormModal
                isModalOpened={isContactFormOpened}
                setIsModalOpened={setIsContactFormOpenedActions.toggle}
            />

            <TabsContentNavigation
                radioBtns={radioBtnsDate}
                activComponent={activeComponent}
                element={
                    <>
                        {/*Temp Removed*/}
                        {false && (
                            <MainButton
                                layout="secondary"
                                buttonHandler={setIsContactFormOpenedActions.setFalse}
                                buttonText={t('f:button.contactForAdvisory')}
                                classAdditions="m-auto teams-button mr-80"
                                size="small"
                            />
                        )}
                    </>
                }
            />

            {displayContent()}
        </div>
    );
};

export default Research;
