import IPropertyInfo from "../../../components/shared/PropertiesMap/iPropertyInfo";

export default class TradeProbabilityInfo implements IPropertyInfo {
    id: string = '';
    lat: number = 0;
    lon: number = 0;

    private p: number = 0;
    get price(): number {
        return this.p;
    }

    private pn: string | null = null;
    get propertyName(): string | null {
        return this.pn;
    }

    private pa: string | null = null;
    get propertyAddress(): string | null {
        return this.pa;
    }

    private ap: number | null = null;
    get allPredictions(): number | null {
        return this.ap;
    }

    constructor(data?: any) {
        if (data) {
            this.setData(data);
        }
    }

    setData(data: any, nullString?: string | undefined): void {
        for (const key in this) {
            this[key] = nullString && data[key] === nullString ? null : data[key];
        }
    }
}