export class BaseTransformer {
    constructor() {
		this.transform = this.transform.bind(this);
		this.transformSingle = this.transformSingle.bind(this);
		this.transformMultiple = this.transformMultiple.bind(this);
    }
  
    transform(data:any) {
		if (data.errorMessage) {
			return data;
		} else if (data.ModelState) {
			this.modelErrors(data.ModelState);
			return;
		}
  

		if (data) {
			let res = JSON.parse(data)
			if ((Array.isArray(res) || res.length)) {
				return this.transformMultiple(res);
			} else {
				return this.transformSingle(res);
			}
		}
    }
  
    transformSingle(data:any) {
      	throw new Error('You have to implement the method transformSingle!');
    }
  
    transformMultiple(data:any) {
      	throw new Error('You have to implement the method transformMultiple!');
    }
  
    modelErrors(modelErrors:any) {
        console.log('erorr')
    }
}
  