import { DatePicker, Space } from "antd";
import moment, { Moment } from "moment";
import { FunctionComponent, useState } from "react";
import AppActiveUsersInDaysChart from "./components/AppActiveUsersInDaysChart";
import MostViewsPropertiesChart from "./components/MostViewsPropertiesChart";
import UsersByCountryChart from './components/UsersByCountryChart';

const Dashboard: FunctionComponent<any> = () => {
    const dateFormat = "YYYY-MM-DD";
    const [startDate, setStartDate] = useState<Moment>(moment().startOf('year')),
        [endDate, setEndDate] = useState<Moment>(moment());

    return (
        <div className="d-flex flex-direction-column pt-50">
            <Space size={80} className="justify-content-center">
                <DatePicker
                    defaultValue={startDate}
                    placeholder="Start Date"
                    format={dateFormat}
                    size={"large"}
                    onChange={(value: any) => setStartDate(value)}
                    onOk={(value: any) => setStartDate(value)}>
                </DatePicker>
                <DatePicker
                    defaultValue={endDate}
                    placeholder="End Date"
                    format={dateFormat}
                    size={"large"}
                    onChange={(value: any) => setEndDate(value)}
                    onOk={(value: any) => setEndDate(value)}>
                </DatePicker>
            </Space>
            <UsersByCountryChart startDate={startDate} endDate={endDate} />
            <AppActiveUsersInDaysChart />
            <MostViewsPropertiesChart startDate={startDate} endDate={endDate} />
        </div>
    );
}

export default Dashboard;